const getTableRows = (ref, headerValue) => {
  const tableBody = ref?.current?.tBodies[0];
  const rows = Array.from(tableBody.rows);
  const headerCells = Array.from(ref?.current?.tHead.rows[0].cells);
  const headerIndex = headerCells.findIndex((element) => element?.textContent === headerValue);

  return {
    tableBody,
    rows,
    index: headerIndex,
  };
};

export const sortByLetter = (ref, headerValue, isSorted) => {
  const { tableBody, rows, index } = getTableRows(ref, headerValue);

  // Rows are sorted ascending intially, and then they will flip to descending on a second click, and so on... based on the state of the header component.
  rows.sort((tr1, tr2) => {
    const tr1Text = tr1.cells[index].textContent;
    const tr2Text = tr2.cells[index].textContent;
    return isSorted ? tr1Text.localeCompare(tr2Text) : tr2Text.localeCompare(tr1Text);
  });

  // Set the newly sorted rows.
  tableBody.append(...rows);
};

export const sortByNumber = (ref, headerValue, isSorted) => {
  const { tableBody, rows, index } = getTableRows(ref, headerValue);
  rows.sort((tr1, tr2) => {
    const tr1Num = parseInt(tr1.cells[index].textContent.replace(/[^\w]/g, ''));
    const tr2Num = parseInt(tr2.cells[index].textContent.replace(/[^\w]/g, ''));
    return isSorted ? tr1Num - tr2Num : tr2Num - tr1Num;
  });

  tableBody.append(...rows);
};

export const sortActiveProjects = (ref, headerValue, isSorted) => {
  const { tableBody, rows, index } = getTableRows(ref, headerValue);
  rows.sort((tr1, tr2) => {
    const tr1Num = parseInt(tr1.cells[index].lastChild.lastChild.textContent);
    const tr2Num = parseInt(tr2.cells[index].lastChild.lastChild.textContent);
    if (tr1Num === tr2Num) {
      const tr1PastDue = tr1.cells[index].lastChild.firstChild.textContent;
      const tr2PastDue = tr2.cells[index].lastChild.firstChild.textContent;
      return isSorted ? tr1PastDue - tr2PastDue : tr2PastDue - tr1PastDue;
    } else {
      return isSorted ? tr1Num - tr2Num : tr2Num - tr1Num;
    }
  });

  tableBody.append(...rows);
};

export const sortByDate = (ref, headerValue, isSorted) => {
  const { tableBody, rows, index } = getTableRows(ref, headerValue);

  rows.sort((tr1, tr2) => {
    const tr1Date = new Date(tr1.cells[index].textContent);
    const tr2Date = new Date(tr2.cells[index].textContent);
    return isSorted ? tr1Date.getTime() - tr2Date.getTime() : tr2Date.getTime() - tr1Date.getTime();
  });

  tableBody.append(...rows);
};
