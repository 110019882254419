import React, { useState, useContext, useRef } from 'react';
import CreatableReactSelect from 'react-select/creatable';
import ModalConfirm from 'components/Modals/Confirm/index';
import { CREATOR } from 'constants';
import FullContext from 'stores/Full/fullContext';
import { Option } from 'components/CreatableSelect';
import {
  ClearIconContainer,
  DropdownContainer,
  Input,
  Menu,
  SearchableSelectContainer,
  SearchIconContainer,
} from 'components/SearchableSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import useOutsideClickNotifier from 'helpers/hooks/useOutsideClickNotifier';

function CustomCreatableSelect({
  onChange,
  onCreate,
  onDelete,
  onEdit,
  inputValue,
  disabled,
  options,
  onClearValues,
  onBlur,
  onFocus,
  showClearIndicator = true,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [itemForDelete, setItemForDelete] = useState(null);
  const [itemForEdit, setItemForEdit] = useState(null);
  const { roleAtleast } = useContext(FullContext);
  const [open, setOpen] = useState(false);

  const clickRef = useRef(null);

  useOutsideClickNotifier(clickRef, () => {
    if (open && onBlur && !itemForDelete) onBlur();

    setOpen(false);
  });

  const onToggle = () => setOpen((prev) => !prev);

  /**
   * On Focus handler for GroupedSelect
   * @param none
   * @returns void
   */
  const onFocusSelect = () => {
    onToggle();

    if (onFocus) onFocus();
  };

  const handleCreate = async (label) => {
    setIsLoading(true);
    await onCreate(label);
    setOpen(false);
    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    await onDelete(itemForDelete);
    setItemForDelete(null);
    setOpen(true);
    setIsLoading(false);
  };

  const handleEdit = async (newLabel) => {
    setIsLoading(true);
    await onEdit(itemForEdit, newLabel);
    setItemForEdit(null);
    setOpen(false);
    setIsLoading(false);
  };

  const getOptionStyle = (provided) => provided;

  const popoutSelectStyles = {
    control: (provided) => ({
      ...provided,
      margin: 8,
    }),
    menu: () => ({ boxShadow: 'unset' }),
    option: (provided) => getOptionStyle(provided),
  };

  const clearIndicatorStyles = (base) => ({
    ...base,
    cursor: 'pointer',
    borderRight: showClearIndicator && '1px solid var(--playbook-pewter)',
    height: 22,
  });

  const inputSelectStyles = {
    menu: () => ({ display: 'none' }),
    clearIndicator: clearIndicatorStyles,
  };

  const onSelectChange = (value) => {
    onToggle();
    onChange(value);
  };

  return (
    <>
      <SearchableSelectContainer ref={clickRef}>
        <Input
          open={open}
          value={inputValue}
          options={undefined}
          classNamePrefix="select"
          readOnly
          isDisabled={disabled}
          placeholder="None"
          isSearchable={false}
          onFocus={onFocusSelect}
          isClearable
          components={{
            IndicatorSeparator: null,
          }}
          styles={inputSelectStyles}
        />

        {inputValue && showClearIndicator && (
          <ClearIconContainer
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onClearValues();
              setOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </ClearIconContainer>
        )}

        {open && (
          <Menu>
            <DropdownContainer>
              <CreatableReactSelect
                placeholder=""
                options={options}
                backspaceRemovesValue={false}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                isDisabled={isLoading}
                isLoading={isLoading}
                menuIsOpen
                styles={popoutSelectStyles}
                value={inputValue}
                onChange={(value) => {
                  onSelectChange(value);
                  setOpen(false);
                }}
                onCreateOption={handleCreate}
                getNewOptionData={roleAtleast(CREATOR) ? undefined : () => false}
                components={
                  roleAtleast(CREATOR)
                    ? {
                        Option: (optionProps) => (
                          <Option
                            {...optionProps}
                            isEditing={optionProps.data.value === itemForEdit}
                            onEdit={handleEdit}
                            onDelete={setItemForDelete}
                            setItemForEdit={setItemForEdit}
                          />
                        ),
                        DropdownIndicator: null,
                        IndicatorSeparator: null,
                      }
                    : undefined
                }
              />

              <SearchIconContainer>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </SearchIconContainer>
            </DropdownContainer>
          </Menu>
        )}
      </SearchableSelectContainer>

      <ModalConfirm
        title={`Delete client type`}
        message={
          <>
            {`Are you sure you want to delete this client type? Other accounts with this type will no longer have a type.`}
            <br />
            This action cannot be undone.
          </>
        }
        show={itemForDelete !== null}
        onConfirm={handleDelete}
        onHide={() => setItemForDelete(null)}
      />
    </>
  );
}

export default CustomCreatableSelect;
