import * as Yup from 'yup';
import { ERROR_MESSAGES } from 'constants/customFields';
import { arrayFieldsSchema } from 'helpers/schemaHelper';

export const addCustomerSchema = Yup.object().shape({
  accountName: Yup.string(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),

  detailsFields: Yup.array().of(
    Yup.object().shape({
      customFieldSectionId: Yup.string(),
      name: Yup.string(),
      fields: arrayFieldsSchema,
    })
  ),
});
