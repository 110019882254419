import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import * as Api from 'api/Api';
import LoadingInline from 'components/Loading/LoadingInline';
import PageHeader from 'components/PageHeader';
import toast from 'components/toast';
import { getExternalProject, noop } from 'helpers/CommonHelper';
import EmailConnect from 'components/EmailConnect/EmailConnect';
import FullContext from 'stores/Full/fullContext';
import { StyledAvatar, StyledCol, StyledIcon, SaveContainer, DemarginizedDiv } from './Styles';
import FormFields from './FormFields';
import { Formik } from 'formik';
import { IS_SAVING, HAS_SERVER_ERROR } from 'constants';
import { Settings } from 'models';
import { emailPreferences } from './fixtures';
import { EditFieldsLink } from 'views/Customer/Styles';

class Profile extends React.Component {
  constructor(props) {
    super(props);

    // const parsed = queryString.parse(props.location.search);
    // const turnOffEmailNotifications = parsed.action === 'turnoffemailnotifications';

    this.state = {
      userProfile: null,
      googleProfile: null,
      loading: false,
      saving: false,
      avatarLoading: false,
      emailPreferences: [],
      // turnOffEmailNotifications: turnOffEmailNotifications,
    };
  }
  static contextType = FullContext;

  async componentDidMount() {
    this.loadUserProfile();
    await this.loadEmailPreferences();
  }

  loadUserProfile = async () => {
    this.setState({ loading: false });
    let userProfile = await Api.get('Settings/GetUserProfile');
    let googleProfile = null;
    let nylasProfile = null;

    if (userProfile?.googleProfile) ({ googleProfile, ...userProfile } = userProfile);
    if (userProfile?.nylasProfile) ({ nylasProfile, ...userProfile } = userProfile);

    this.setState({ loading: false });
    this.setState({ userProfile, googleProfile, nylasProfile });
  };

  /**
   * Calls the email preference service that retrieves
   * the data from the backend
   * @returns void
   */
  loadEmailPreferences = async () => {
    const response = await Settings.getEmailPreferences();
    this.setState({
      emailPreferences: [...response],
    });
  };

  saveUserProfile = async () => {
    this.setState({ saving: true });
    const data = await Api.post('Settings/UpdateUserProfile', this.state.userProfile);
    this.setState({ saving: false });
    // if (this.state.turnOffEmailNotifications) {
    //   // not calling changeAvatar fixes an infinite saving/loading loop
    //   // redirecting to /profile and removing the action query param causes subsequent saves to function as expected
    //   // todo: find a better way. I tried calling window.history.replaceState but it didn't seem to work
    //   window.location.href = '/profile';
    // } else {
    this.props.changeAvatar(data.avatar);
    toast.saved('Profile saved successfully!');
    // }
  };

  updateAvatar = async (value) => {
    await Settings.updateProfile(this.state.userProfile?.id, { field: 'avatar', value });
    this.props.changeAvatar(value);
  };

  updateUserProfile = async (key, value) => {
    let updatedUserProfile = this.state.userProfile;
    updatedUserProfile[key] = value;

    this.setState({
      userProfile: updatedUserProfile,
    });
  };

  deleteAvatar = async () => {
    this.updateUserProfile('avatar', null);
    await this.updateAvatar(null);
  };

  inputValueChanged = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    // if (name === 'inAppAlerts' && this.state.userProfile.inAppAlertsRequired) return;

    // if (name === 'emailAlerts' && this.state.userProfile.emailAlertsRequired) return;

    this.updateUserProfile(name, value);
  };

  onAvatarDrop = async (event) => {
    this.setState({ avatarLoading: true });
    const response = await Api.postFiles('Settings/UploadAppSettingsLogo', event.target.files, {
      rawResponse: true,
    });
    const data = await response.json();
    if (response.status === 400) {
      toast.error(data);
    } else {
      this.updateUserProfile('avatar', data.filePath);

      if (data.filePath) await this.updateAvatar(data.filePath);
    }

    this.setState({ avatarLoading: false });

    this.fileInput.value = null;
  };

  responseGoogle = async (res) => {
    const { code } = res;
    if (code) {
      const googleProfile = await Api.post('Account/ConnectGmail', { code });
      this.setState({ googleProfile });
    }
  };

  /**
   * Handler that updates the state of the switches under the
   * Email Preferences
   * @param {Event} event - event object upon clicking
   * @param {string} name - the fieldName of the  switch to be updated
   * @param {number} index
   */
  preferenceHandler = async (event, name, index) => {
    const value = event.target.checked;
    await Settings.editEmailPreferences(value, name);
    const _emailPreferences = this.state.emailPreferences.map((config, _index) => {
      if (index === _index) {
        return {
          ...config,
          value,
        };
      }
      return config;
    });

    this.setState({
      emailPreferences: _emailPreferences,
    });
  };

  userProfileDetail(setFieldValue) {
    return (
      <div>
        <Row>
          <Col>
            <h2 className="settings-section-title top mt-3 mb-4">Profile picture</h2>
            <div className="d-flex align-items-center mb-3">
              {this.state.userProfile.avatar ? (
                <StyledAvatar url={this.state.userProfile.avatar} className="avatar-circle" />
              ) : (
                <StyledIcon icon={faUserCircle} className="profile-icon" />
              )}
              <div>
                <input
                  id="avatar-input"
                  hidden
                  type="file"
                  onChange={this.onAvatarDrop}
                  ref={(fileInput) => (this.fileInput = fileInput)}
                />
                <Button
                  onClick={() => this.fileInput.click()}
                  disabled={this.state.avatarLoading}
                  variant="secondary"
                  className="ml-4"
                >
                  {this.state.avatarLoading ? <LoadingInline centered white text="Uploading..." /> : 'Upload'}
                </Button>
                {this.state.userProfile.avatar && !this.state.avatarLoading && (
                  <Button
                    onClick={this.deleteAvatar}
                    variant="danger"
                    className="ml-3 ml-sm-2 mt-2 mt-sm-0 d-block d-sm-inline"
                  >
                    Delete
                  </Button>
                )}
              </div>
            </div>
            <Row className="d-block">
              <StyledCol md={6} maxWidth="480px">
                <div className="section-header">
                  <h2 className="settings-section-title">Information</h2>

                  {!this.context.isExternal && (
                    <EditFieldsLink to="/profile/custom-fields/edit-profile-fields">
                      Edit fields
                    </EditFieldsLink>
                  )}
                </div>
                <DemarginizedDiv>
                  <FormFields
                    userProfile={this.state.userProfile}
                    setSaving={(value) => setFieldValue(IS_SAVING, value)}
                    setHasError={(value) => setFieldValue(HAS_SERVER_ERROR, value)}
                  />
                </DemarginizedDiv>
              </StyledCol>
            </Row>
          </Col>
          {!this.context.isExternal && (
            <Col>
              <h2 className="mt-3 mb-2">Email Preferences</h2>
              <div className="mb-3 pb-1">Edit which system emails you receive for all projects.</div>
              {this.state.emailPreferences?.map((config, index) => {
                const _field = emailPreferences.find((item) => item.fieldName === config.name);
                return (
                  <div className="settings-row" key={`profile-config-${index}`}>
                    {_field.label}
                    <Form.Check
                      type="switch"
                      id={config.name}
                      name={config.name}
                      checked={config.value}
                      onChange={(event) => this.preferenceHandler(event, config.name, index)}
                    />
                  </div>
                );
              })}
              <div className="mt-1">
                Only internal users get these preferences. Account owners (with administrator permission) can
                edit which system emails are sent to internal and external users in Project Settings.
              </div>
            </Col>
          )}
        </Row>
        <Row>
          {!this.context.isExternal && (
            <Col sm={12} className="mb-5 pb-5">
              <EmailConnect
                googleProfile={this.state.googleProfile}
                nylasProfile={this.state.nylasProfile}
                userProfile={this.state.userProfile}
                loadUserProfile={this.loadUserProfile}
                isTrialMode={this.props.isTrialMode}
              />
            </Col>
          )}
        </Row>
      </div>
    );
  }

  render() {
    return (
      <>
        <Formik initialValues={{ isChangesSaving: null, hasServerError: null }} onSubmit={noop}>
          {({ values: { isChangesSaving, hasServerError }, setFieldValue }) => (
            <>
              <PageHeader
                title="Profile"
                loading={this.state.loading}
                border
                subText={
                  <SaveContainer>
                    {!isChangesSaving && typeof isChangesSaving != 'boolean' ? null : Boolean(
                      isChangesSaving
                    ) ? (
                      <span className="saving">Saving...</span>
                    ) : Boolean(hasServerError) ? (
                      <span className="error">Unsaved changes</span>
                    ) : (
                      <span>Changes auto-saved.</span>
                    )}
                  </SaveContainer>
                }
                {...(this.context.isExternal
                  ? {
                    upName: 'Project',
                    upPath: getExternalProject() || '/404',
                  }
                  : {})}
              />
              {this.state.userProfile ? this.userProfileDetail(setFieldValue) : null}
            </>
          )}
        </Formik>
      </>
    );
  }
}

export default Profile;
