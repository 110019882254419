import React, { useState, useEffect, useContext } from 'react';
import {
  GraphContainerStyle,
  GraphWrapperStyle,
  TableTitleStyle,
  LegendSymbolStyle,
  LegendMessageBox,
} from './SharedStyles';
import LineChart from '../../components/Charts/LineChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Api from '../../api/Api';
import { format, sub, lastDayOfMonth } from 'date-fns';
import styled from 'styled-components';
import reportingContext from './context/reportingContext';
import InfoTextBox from 'components/Modals/Shared/InfoTextBox';
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import HeaderFilter from './HeaderFilter';

const LineGraphContainer = styled.div`
  height: 476px;
  display: flex;
  align-items: center;
`;

const RowSpan = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const LegendContainer = styled.div`
  transform: rotate(270deg);
  margin: 0 -40px 80px -24px;
  z-index: 1;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--schedule-sapphire);
`;

const AccountsAndLoss = ({ showTable, setShowTable }) => {
  const [chartData, setChartData] = useState([]);
  const [showLossInfo, setShowLossInfo] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const { activeTimeRangeFilters, activeAccountFilters } = useContext(reportingContext);

  useEffect(() => {
    const getAccountsAndLoss = async (filterOption) => {
      const filterIteration = [...Array.from(Array(filterOption?.value ?? 0).keys())];
      const startDate = sub(new Date(), {
        months: filterIteration.length - 1 + filterOption.discrepancy,
      })
        .toISOString()
        .replaceAll(':', '%3A');
      const endDate = sub(new Date(), {
        months: filterOption.discrepancy,
      })
        .toISOString()
        .replaceAll(':', '%3A');
      const dateRange = dateRangeHandler(filterOption, filterIteration);
      chartDataHandler(dateRange, startDate, endDate, accountFilters);
    };

    const defaultFilter = {
      label: 'Last 12 Months',
      value: 12,
      discrepancy: 0,
      segment: 'months',
    };

    let accountFilters = '';
    if (activeAccountFilters?.length > 0) {
      activeAccountFilters.forEach((filter) => {
        accountFilters += `&clientTypes=${filter}`;
      });
    }

    const selectedFilter =
      activeTimeRangeFilters.length > 0
        ? activeTimeRangeFilters.map((period) => {
            const filterOption = typeof period?.value === 'number' ? period : defaultFilter;
            return filterOption;
          })
        : [defaultFilter];
    getAccountsAndLoss(selectedFilter[0]);

    return () => {
      setChartData([]);
    };
  }, [activeTimeRangeFilters, activeAccountFilters]);

  const dateRangeHandler = (filterOption, filterIteration) => {
    let dateRange = [];
    if (filterOption.segment === 'months') {
      dateRange = filterIteration.map((index) => {
        const dateValue = sub(new Date(), {
          months: filterIteration.length - 1 - index + filterOption.discrepancy,
        });
        const monthName = format(new Date(dateValue), 'MMMM');
        const yearValue = format(new Date(dateValue), 'yy');
        return `${monthName} '${yearValue}`;
      });
    } else {
      dateRange = filterIteration.map((index) => {
        const dateValue = sub(lastDayOfMonth(new Date()), {
          months: filterOption.discrepancy,
          weeks: filterIteration.length - 1 - index,
        });
        return format(new Date(dateValue), 'MMM-dd-yy');
      });
    }
    return dateRange;
  };

  const chartDataHandler = async (dateRange, startDate, endDate, accountFilters) => {
    const response = await Api.get(
      `Report/GetAccountsAndLoss?startDate=${startDate}&endDate=${endDate}${accountFilters}`
    );
    const chartTemplate = [
      {
        id: 'Active Accounts',
        color: 'var(--purple-bright)',
        data: [],
      },
      {
        id: 'Lost Accounts',
        color: 'var(--danger)',
        data: [],
      },
    ];
    response.forEach((item, index) => {
      chartTemplate[0].data = [
        ...chartTemplate[0].data,
        {
          x: dateRange[index],
          y: item.totalActiveCustomers,
        },
      ];
      chartTemplate[1].data = [
        ...chartTemplate[1].data,
        {
          x: dateRange[index],
          y: item.totalLostCustomers,
        },
      ];
    });
    setChartData(chartTemplate);
  };

  return (
    <>
      <GraphContainerStyle className="mr-0">
        <TableTitleStyle onClick={setShowTable}>
          <span className="title d-flex">
            <span className="mr-2">
              <StyledIcon icon={showTable ? faAnglesUp : faAnglesDown} className="mr-2" />
              Accounts
            </span>
            <InfoTextBox notActive={false} width={'411px'} top={0}>
              Total active accounts and the number of accounts lost.
            </InfoTextBox>
          </span>
          {(activeTimeRangeFilters.length > 0 || activeAccountFilters.length > 0) && (
            <HeaderFilter setShowFilters={setShowFilters} showFilters={showFilters} />
          )}
        </TableTitleStyle>
        {showTable && (
          <GraphWrapperStyle>
            <LineGraphContainer>
              <LegendContainer>
                <RowSpan>
                  <LegendSymbolStyle color="var(--purple-bright)" />
                  Active Accounts
                </RowSpan>
                <RowSpan
                  onMouseOver={() => setShowLossInfo(true)}
                  onMouseOut={() => {
                    setShowLossInfo(false);
                  }}
                >
                  <LegendSymbolStyle color="var(--danger)" />
                  Lost Accounts
                </RowSpan>
                {showLossInfo && (
                  <LegendMessageBox top={0} width={411}>
                    Lost accounts are calculated when toggling the account status to 'Inactive'
                  </LegendMessageBox>
                )}
              </LegendContainer>
              <LineChart data={chartData} />
            </LineGraphContainer>
          </GraphWrapperStyle>
        )}
      </GraphContainerStyle>
    </>
  );
};

export default AccountsAndLoss;
