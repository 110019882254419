import { useState, useCallback, useRef, useContext, useEffect } from 'react';
import {
  closestCenter,
  getFirstCollision,
  pointerWithin,
  rectIntersection,
  KeyboardCode,
  closestCorners,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { OBJECT_TYPE_ENUM, DETAILS } from 'constants';

const directions = [KeyboardCode.Down, KeyboardCode.Right, KeyboardCode.Up, KeyboardCode.Left];

export const useDnd = () => {
  const { draggableSections, updateSectionOrder, updateFieldOrder } = useContext(CustomFieldsContext);

  const [items, setItems] = useState(draggableSections);
  const [containers, setContainers] = useState(Object.keys(draggableSections));
  const [activeId, setActiveId] = useState(null);

  const lastOverId = useRef(null);
  const recentlyMovedToNewContainer = useRef(false);

  useEffect(() => {
    setItems(draggableSections);
    setContainers(Object.keys(draggableSections));
  }, [draggableSections]);

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter((container) => container.id in items),
        });
      }

      // Start by finding any intersecting droppable
      const intersections = args.pointerCoordinates ? pointerWithin(args) : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) => container.id !== overId && containerItems.includes(container.id)
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items, lastOverId, recentlyMovedToNewContainer]
  );

  /**
   * Determine what container the item belongs to
   * @param id
   * @returns container
   */
  const findContainer = useCallback(
    (id) => {
      if (id in items) {
        return id;
      }

      return Object.keys(items).find((key) => items[key].includes(id));
    },
    [items]
  );

  /**
   * DND on start handler
   * @param active
   * @returns void
   */
  const handleStart = useCallback(({ active }) => {
    setActiveId(active.id);
  }, []);

  /**
   * DND drag over handler which focuses on
   * dragging items to another container
   * @param active
   * @param over
   * @returns void
   */
  const handleDragOver = useCallback(
    ({ active, over }) => {
      const overId = over?.id;

      if (overId == null || active.id in items) {
        return;
      }

      const overContainer = findContainer(overId);
      const activeContainer = findContainer(active.id);

      if (!overContainer || !activeContainer) {
        return;
      }

      if (activeContainer !== overContainer) {
        setItems((items) => {
          const activeItems = items[activeContainer];
          const overItems = items[overContainer];
          const overIndex = overItems.indexOf(overId);
          const activeIndex = activeItems.indexOf(active.id);

          let newIndex;

          if (overId in items) {
            newIndex = overItems.length + 1;
          } else {
            const isBelowOverItem =
              over &&
              active.rect.current.translated &&
              active.rect.current.translated.top > over.rect.top + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;

            newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
          }

          recentlyMovedToNewContainer.current = true;

          return {
            ...items,
            [activeContainer]: items[activeContainer].filter((item) => item !== active.id),
            [overContainer]: [
              ...items[overContainer].slice(0, newIndex),
              items[activeContainer][activeIndex],
              ...items[overContainer].slice(newIndex, items[overContainer].length),
            ],
          };
        });
      }
    },
    [findContainer, items, recentlyMovedToNewContainer]
  );

  /**
   * DND drag end handler which focuses on
   * dragging items to another container and
   * dragging items to its own container
   * @param active
   * @param over
   * @returns void
   */
  const handleDragEnd = ({ active, over }) => {
    if (active.id in items && over?.id) {
      setContainers((containers) => {
        const activeIndex = containers.indexOf(active.id);
        const overIndex = containers.indexOf(over.id);

        return arrayMove(containers, activeIndex, overIndex);
      });

      const splittedOverId = over.id.split('-');
      const splittedActiveId = active.id.split('-');
      const sectionId = splittedActiveId[0];
      const newSectionSequence = splittedOverId[2];
      updateSectionOrder(sectionId, newSectionSequence);
    }

    const activeContainer = findContainer(active.id);

    if (!activeContainer) {
      setActiveId(null);
      return;
    }

    const overId = over?.id;

    if (overId == null) {
      setActiveId(null);
      return;
    }

    const overContainer = findContainer(overId);

    if (overContainer) {
      const activeIndex = items[activeContainer].indexOf(active.id);
      const overIndex = items[overContainer].indexOf(overId);
      const isField = active.id.split('-')[1] === 'field';

      const tempSections = {
        ...items,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
      };

      if (isField) {
        const newSections = Object.keys(tempSections).map((section) => ({
          sectionName: section,
          fields: tempSections[section],
        }));

        const foundSection = newSections.find((section) =>
          section.fields.find((field) => field === active.id)
        );

        const fieldId = active.id.split('-')[0];
        const sectionId = foundSection?.sectionName.split('-')[0];
        const newIndex = foundSection?.fields.indexOf(active.id);

        updateFieldOrder(fieldId, sectionId, newIndex, DETAILS, OBJECT_TYPE_ENUM.CUSTOMER);
      }

      if (activeIndex !== overIndex) {
        setItems(tempSections);
      }
    }

    setActiveId(null);
  };

  /**
   * DND drag cancel handler
   * @param none
   * @returns void
   */
  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  /**
   * Custom drag and drop coordinate getter
   * @param event
   * @param context
   * @returns x and y point
   */
  const coordinateGetter = (
    event,
    { context: { active, droppableRects, droppableContainers, collisionRect } }
  ) => {
    if (directions.includes(event.code)) {
      event.preventDefault();

      if (!active || !collisionRect) {
        return;
      }

      const filteredContainers = [];

      droppableContainers.getEnabled().forEach((entry) => {
        if (!entry || entry?.disabled) {
          return;
        }

        const rect = droppableRects.get(entry.id);

        if (!rect) {
          return;
        }

        const data = entry.data.current;

        if (data) {
          const { type, children } = data;

          if (type === 'container' && children?.length > 0) {
            if (active.data.current?.type !== 'container') {
              return;
            }
          }
        }

        switch (event.code) {
          case KeyboardCode.Down:
            if (collisionRect.top < rect.top) {
              filteredContainers.push(entry);
            }
            break;
          case KeyboardCode.Up:
            if (collisionRect.top > rect.top) {
              filteredContainers.push(entry);
            }
            break;
          case KeyboardCode.Left:
            if (collisionRect.left >= rect.left + rect.width) {
              filteredContainers.push(entry);
            }
            break;
          case KeyboardCode.Right:
            if (collisionRect.left + collisionRect.width <= rect.left) {
              filteredContainers.push(entry);
            }
            break;
          default:
            break;
        }
      });

      const collisions = closestCorners({
        active,
        collisionRect: collisionRect,
        droppableRects,
        droppableContainers: filteredContainers,
        pointerCoordinates: null,
      });
      const closestId = getFirstCollision(collisions, 'id');

      if (closestId != null) {
        const newDroppable = droppableContainers.get(closestId);
        const newNode = newDroppable?.node.current;
        const newRect = newDroppable?.rect.current;

        if (newNode && newRect) {
          if (newDroppable.data.current?.type === 'container') {
            return {
              x: newRect.left + (newRect.width - collisionRect.width) / 2,
              y: newRect.top + (newRect.height - collisionRect.height) / 2,
            };
          }

          return {
            x: newRect.left,
            y: newRect.top,
          };
        }
      }
    }

    return undefined;
  };

  return {
    items,
    containers,
    handleStart,
    handleDragOver,
    handleDragCancel,
    handleDragEnd,
    coordinateGetter,
    collisionDetectionStrategy,
    lastOverId,
    recentlyMovedToNewContainer,
  };
};
