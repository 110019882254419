import { useState, useEffect } from 'react';
import * as UsersApi from '../../api/users';
import toast from '../../components/toast';

const useOwners = () => {
  const [owners, setOwners] = useState([]);
  const [ownerOptions, setOwnersOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchOwners = async () => {
    setLoading(true);
    try {
      const response = await UsersApi.fetchOwnerOptions();
      setOwners(response);

      if (response?.length) {
        setOwnersOptions(
          response.map((owner) => ({ label: `${owner.firstName} ${owner.lastName}`, value: owner.primaryId }))
        );
      }
    } catch (err) {
      console.log(err);
      toast.error('There was an error fetching owners list');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOwners();
  }, []);

  return {
    owners,
    ownerOptions,
    loading,
  };
};

export default useOwners;
