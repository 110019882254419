import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import RoundedPlusButton from './RoundedPlusButton';

const GroupedItemsLabel = styled.div`
  font-size: 11px;
  font-weight: bold;
  color: var(--action-anchor);
  text-transform: uppercase;
  padding-top: 16px;
  padding-left: 20px;
`;

const DropdownItem = styled(Dropdown.Item)`
  padding: 4px 20px;

  ${(props) =>
    props.isGrouped
      ? `
    &:last-child {
      margin-bottom: 12px;
    }
  `
      : `
   &:last-child {
      margin-bottom: 12px;
    }
     &:first-child {
      margin-top: 12px;
    }
  `}
`;

const PlusButtonToggle = React.forwardRef(({ _, onClick }, ref) => (
  <div ref={ref} onClick={onClick}>
    <RoundedPlusButton />
  </div>
));

/**
 * Rounded button with a plus icon inside that behaves as dropdown
 * @property {object} className styles from parent component
 * @property {array} options dropdown options {label: 'one', value: '1'}
 * @property {function} onSelect callback to execute when selecting an item from dropdown
 * @returns {JSX.Element}
 */
const PlusButtonDropdown = ({ className, options = [], onSelect = () => {} }) => {
  /**
   * Renders Dropdown items when its a grouped dropdown
   * @param {string} label
   * @param {array} options
   * @returns {JSX.Element}
   */
  const renderGroupedItems = (label, options) => (
    <>
      <GroupedItemsLabel>{label}</GroupedItemsLabel>
      {options?.map((subOpt, subIndex) => (
        <DropdownItem key={subIndex} onSelect={() => onSelect(subOpt)} isGrouped>
          {subOpt.label}
        </DropdownItem>
      )) || null}
    </>
  );

  /**
   * Renders regular dropdown items
   * @param {object} option
   * @param {number} index
   * @returns
   */
  const renderItems = (option, index) => (
    <DropdownItem key={index} onSelect={() => onSelect(option)}>
      {option.label || 'Option'}
    </DropdownItem>
  );

  return (
    <Dropdown className={className}>
      <Dropdown.Toggle as={PlusButtonToggle} id="dropdown-basic">
        Dropdown Button
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options.map((opt, index) =>
          opt?.options?.length ? renderGroupedItems(opt.label, opt.options) : renderItems(opt, index)
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PlusButtonDropdown;
