import React from 'react';
import { Form } from 'react-bootstrap';

// Allow empty labels '', hide only if label is undefined
const Checkbox = ({
  label,
  checked,
  onChange
}) => (
  label !== undefined && <div className="mt-4 d-flex">
    <Form.Check
      type="checkbox"
      label={label}
      checked={checked}
      onChange={onChange}
    />
  </div>
);

export default Checkbox;
