const FullReducer = (state, action) => {
  switch (action.type) {
    case 'populate_organization_data':
      return {
        ...state,
        organizationData: action.payload,
      };

    case 'populate_app_settings':
      return {
        ...state,
        appSettings: action.payload,
      };

    case 'set_active_canvas_mode':
      return {
        ...state,
        activeCanvasMode: action.payload,
      };

    case 'populate_user_data':
      return {
        ...state,
        userData: action.payload,
      };

    case 'change_avatar':
      return {
        ...state,
        userData: { ...state.userData, avatar: action.payload },
      };

    case 'set_reminder_state':
      return {
        ...state,
        isReminderUpdated: action.payload,
      };

    case 'set_canvas_state':
      return {
        ...state,
        isCanvasUpdated: action.payload,
      };

    case 'populate_total_accounts':
      return {
        ...state,
        totalAccounts: action.payload,
      };

    case 'update_route': {
      return {
        ...state,
        route: {
          ...state.route,
          to: action.payload,
          from: state.route.to,
        },
      };
    }

    default:
      return state;
  }
};

export default FullReducer;
