import React, { useState, useCallback, useMemo } from 'react';
import { useField, ErrorMessage } from 'formik';
import SearchableSelect from 'components/SearchableSelect';
import { ErrorMessageContainer } from 'views/CustomFields/Account/Styles';
import useUpdateEffect from 'helpers/hooks/useUpdateEffect';
import { FieldLabel } from 'components/Modals/Customers/Styles';

const SearchableDropdown = ({
  name,
  options,
  disabled,
  fieldLabel,
  isRequired,
  searchableProps,
  isDropDownLastItem,
}) => {
  const [, meta, helpers] = useField(name);

  const [value, setValue] = useState(meta.value || meta.initialValue);

  const dropdownObjectError = meta.error ? Object.values(meta.error)[0] : '';

  const initialMultiValue = value ?? [];

  useUpdateEffect(() => {
    setValue(meta.value);
  }, [meta.value]);

  /**
   * On Select Change handler
   * @param args
   * @returns void
   */
  const onSelectChange = useCallback(
    (args) => {
      let dropdownValue;

      !searchableProps?.isMulti
        ? (dropdownValue = {
            value: args.value,
            label: args.label,
            response: !!args?.id ? args?.id : args?.response ? args?.response : '',
          })
        : (dropdownValue = args.map((item) => ({
            value: item.value,
            label: item.label,
            response: !!item?.id ? item?.id : item?.response ? item?.response : '',
          }))).sort((a, b) => a.label.localeCompare(b.label));

      setValue(dropdownValue);

      helpers.setError('');
      helpers.setValue(dropdownValue);
      helpers.setTouched(true);

      if (searchableProps?.handleChange) searchableProps.handleChange(dropdownValue);
    },
    [helpers, searchableProps]
  );

  /**
   * On Select Focus handler
   * @returns void
   */
  const onSelectFocus = useCallback(() => {
    helpers.setTouched(true);

    if (searchableProps?.handleFocus) searchableProps.handleFocus();
  }, [helpers, searchableProps]);

  /**
   * On Select Clear values handler
   * @returns void
   */
  const onClearValues = useCallback(() => {
    helpers.setValue(null);
    setValue(null);

    if (searchableProps?.handleClear) searchableProps.handleClear();
  }, [helpers, searchableProps]);

  /**
   * On Select Blur handler
   * @returns void
   */
  const onSelectBlur = useCallback(() => {
    if (searchableProps?.handleBlur) searchableProps.handleBlur();
  }, [searchableProps]);

  const hasError = useMemo(() => meta.touched && !!meta.error, [meta.error, meta.touched]);

  return (
    <>
      {fieldLabel && (
        <FieldLabel>
          <span>{fieldLabel}</span>
          {isRequired && !hasError && <span>*</span>}
          {hasError && (
            <ErrorMessageContainer className="error-container">
              {typeof meta.error !== 'object' ? (
                <>({<ErrorMessage name={name} />})</>
              ) : (
                <div>({dropdownObjectError})</div>
              )}
            </ErrorMessageContainer>
          )}
        </FieldLabel>
      )}

      <SearchableSelect
        selectValue={value}
        options={options}
        onChange={onSelectChange}
        onFocus={onSelectFocus}
        onBlur={onSelectBlur}
        initialMultiValue={initialMultiValue}
        disabled={disabled}
        onClearValues={onClearValues}
        isDropDownLastItem={isDropDownLastItem}
        {...searchableProps}
      />
    </>
  );
};

export default React.memo(SearchableDropdown);
