import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { faAngleDown, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Dropdown, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import * as CommonHelper from '../../../helpers/CommonHelper';
import * as Api from '../../../api/Api';
import List from '../../List';
import Members from '../../Members';
import Tags from '../../Tags';
import Actions from '../EditTouchpoint/Actions';
import Activity from './Activity';
import Checklist from './Checklist';
import Files from './Files';
import Ideas from './Ideas';
import TouchpointHeader from './TouchpointHeader';
import CustomerComments from './CustomerComments';
import DateChangeState from '../Shared/DateChange/context/dateChangeState';
import EditDateModal from '../Shared/DateChange/components/EditDateModal';
import DisplayDate from './DisplayDate';
import ModalConfirm from '../Confirm/index';
import { HIDE_CONFIRM_COMPLETE_INCOMPLETE_ITEMS } from 'constants/localStorage';
import styled from 'styled-components';
import { Touchpoint, LocalStorage } from 'models';
import FullContext from 'stores/Full/fullContext';
import toast from 'components/toast';
import { FRONT_LINE, EXTERNAL, CONTRIBUTOR, REMOVE_CUSTOMERS } from 'constants';
import ModalMultiButton from '../MultiButton';
import EditActiveStepTags from './EditActiveStepTags';

const Divider = styled.hr`
  margin: 36px -23px 25px -23px;
`;

export default function ModalTouchpoint(
  {
    appSettings,
    show,
    onHide,
    canvasStartDate,
    canvasEndDate,
    canvasTargetEnd,
    dynamicTimeline,
    customerCanvasId,
    customerId,
    touchpoint,
    setCustomerTouchpointStatus,
    onChecklistUpdate,
    members,
    canvasMembers,
    addMembersTouchpoint,
    addMembersTaskItem,
    removeMemberTaskItem,
    removeMember,
    onResourcesUpdated,
    onFilesUpdated,
    onIdeasUpdated,
    canvasMembersById,
    externalsHaveTaskItemPermissions,
    onTouchpointUpdate,
    onMembersUpdate,
    customerComments,
    setNewComments,
    dependentData,
    mergedSteps,
  } = {
      touchpoint: new Touchpoint(),
    }
) {
  /*CustomerFacingTurnOffActions constant has to reflect to the backend's CustomerFacingTurnOffActions enum values*/
  const CustomerFacingTurnOffActions = {
    Nothing: 0,
    KeepCustomers: 1,
    RemoveCustomers: 2,
  };
  const customerTouchpoint = touchpoint;
  const { isEditMode, isViewAsCustomer, isExternal, roleAtleast } = useContext(FullContext);
  const [selectedTab, setSelectedTab] = useState('step');
  const [refreshActivityToggle, setRefreshActivityToggle] = useState(false);
  const [activeTouchpointTasks, setActiveTouchpointTasks] = useState(touchpoint?.tasks);
  const [showEditDateModal, setShowEditDateModal] = useState(false);
  const [completeTouchpointWithoutTask, setCompleteTouchpointWithoutTask] = useState(null);
  const [showConfirm, setShowConfirm] = useState(true);
  const [isConfirmModalDisabled, setConfirmModalDisabled] = useState(false);
  const [showCustomerFacingChange, setShowCustomerFacingChange] = useState(false);
  const [customerFacingCancelled, setCustomerFacingCancelled] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const statuses = CommonHelper.getCustomerTouchpointStatusList();
  const currentStatus = CommonHelper.getCustomerTouchpointStatus(customerTouchpoint?.status);

  useEffect(() => {
    if (touchpoint?.tasks) {
      setActiveTouchpointTasks(touchpoint?.tasks);
    }
  }, [touchpoint?.tasks]);

  useEffect(() => {
    if (customerFacingCancelled) {
      setCustomerFacingCancelled(false);
    }
  }, [customerFacingCancelled]);

  useEffect(() => {
    (async () => {
      if (!isConfirmModalDisabled) {
        const confirmSettings = LocalStorage.get(HIDE_CONFIRM_COMPLETE_INCOMPLETE_ITEMS);
        if (confirmSettings && confirmSettings[customerCanvasId]) {
          setConfirmModalDisabled(true);
        }
      }
    })();
  }, [isConfirmModalDisabled, customerCanvasId]);

  useLayoutEffect(() => {
    const stepEngagement = () => {
      const AccountId = customerId;
      const ProjectId = customerCanvasId;
      const StepId = touchpoint.id;
      return Api.post('Project/StepUserEngagement', {
        AccountId,
        ProjectId,
        StepId,
      });
    };

    stepEngagement();
  }, [customerId, customerCanvasId, touchpoint.id]);

  const dueDate = touchpoint.isScheduledType
    ? isEditMode
      ? touchpoint.endDate
      : touchpoint.scheduledDate || touchpoint.endDate
    : touchpoint.displayDate ?? '';

  function handleTabSelect(selectedTab) {
    history({
      hash: selectedTab,
    });
  }

  useEffect(() => {
    setSelectedTab(location.hash ? location.hash.substring(1) : 'step');
  }, [location.hash]);

  function refreshActivity() {
    setRefreshActivityToggle((prev) => !prev);
  }

  function handleFilesUpdated(...args) {
    onFilesUpdated(...args);
    refreshActivity();
    onTouchpointUpdate();
  }

  function handleResourcesUpdated(...args) {
    onResourcesUpdated(...args);
    refreshActivity();
  }

  const handleReload = () => {
    refreshActivity();
    onTouchpointUpdate();
  };

  const onTaskEdit = (response) => {
    const tasksCopy = [...activeTouchpointTasks];

    // Find the correct task to update, and add the new description
    tasksCopy.forEach((task) => {
      if (task?.id === response?.value?.id) {
        task.description = response?.value?.description;
      }
    });
    refreshActivity();
    setActiveTouchpointTasks([...tasksCopy]);
  };

  const onTaskCreation = (response) => {
    const newTask = {
      completed: false,
      description: response[0]?.description,
      id: response[0]?.id,
      memberIds: [],
      order: response[0]?.order,
    };
    setActiveTouchpointTasks([...activeTouchpointTasks, { ...newTask }]);
    refreshActivity();
    // Have to update the customer canvas state for the updates to stick. Will make this a cleaner operation in the future.
    onTouchpointUpdate();
  };

  const onTaskDeletion = (item) => {
    const tasksToKeep = activeTouchpointTasks.filter((task) => task?.id !== item?.id);
    setActiveTouchpointTasks([...tasksToKeep]);
    refreshActivity();
    onTouchpointUpdate();
  };

  const handleEmailTriggerUpdate = async (changes) => {
    if (changes) {
      await Api.post('Touchpoint/EditCustomerTouchpoint', {
        ...touchpoint,
        ...changes,
      });
      refreshActivity();
      onTouchpointUpdate();
    }
  };

  const onTagsUpdated = async () => {
    refreshActivity();
    onTouchpointUpdate();
  }

  function onUpdate(...args) {
    const { status } = args[0][0];
    if (customerTouchpoint.status !== status) {
      onChecklistUpdate(...args);
    }
    refreshActivity();
  }

  /**
   * Function handler when customer facing is toggled
   * @param {boolean} customerFacing - contains the customer facing trigger
   * @return {void}
   */
  const handleCustomerFacing = async ({ customerFacing }) => {
    const hasExternalUsers = members.filter((item) => item.role === EXTERNAL).length > 0;
    if (!customerFacing && hasExternalUsers) {
      setShowCustomerFacingChange(true);
    } else {
      const payload = {
        ...touchpoint,
        ...{ customerFacing },
      };
      await Touchpoint.toggleCustomerFacing(payload);
      setCustomerFacingCancelled(false);
      setShowCustomerFacingChange(false);
      refreshActivity();
      onTouchpointUpdate();
    }
  };

  /**
   * Function handler to call the API endpoint upon clicking Keep Customers button
   * @return {void}
   */
  const keepCustomersHandler = async () => {
    const payload = {
      ...touchpoint,
      ...{ customerFacing: false, customerFacingTurnOffAction: CustomerFacingTurnOffActions.KeepCustomers },
    };
    await Touchpoint.keepCustomerFacing(payload);
    setCustomerFacingCancelled(false);
    setShowCustomerFacingChange(false);
    refreshActivity();
    onTouchpointUpdate();
  };

  /**
   * Function handler to call the API endpoint upon clicking Remove Customers button
   * @return {void}
   */
  const removeCustomersHandler = async () => {
    const payload = {
      ...touchpoint,
      ...{ customerFacing: false, customerFacingTurnOffAction: CustomerFacingTurnOffActions.RemoveCustomers },
    };
    await Touchpoint.removeCustomerFacing(payload);
    setCustomerFacingCancelled(false);
    setShowCustomerFacingChange(false);
    refreshActivity();
    onTouchpointUpdate();
    onMembersUpdate();
  };

  function StepTab() {
    return (
      <Row className="mr-md-0">
        <Col md={8} className="settings-left-section pb-4">
          <Checklist
            customerTouchpoint={touchpoint}
            onUpdate={onUpdate}
            canvasMembersById={canvasMembersById}
            customerCanvasId={customerCanvasId}
            addMembersTaskItem={addMembersTaskItem}
            removeMemberTaskItem={removeMemberTaskItem}
            externalsHaveTaskItemPermissions={externalsHaveTaskItemPermissions}
            customerId={customerId}
            onTouchpointUpdate={onTouchpointUpdate}
            onTaskEdit={onTaskEdit}
            onTaskCreation={onTaskCreation}
            onTaskDeletion={onTaskDeletion}
            activeTouchpointTasks={activeTouchpointTasks}
          />
          <CustomerComments
            customerTouchpoint={customerTouchpoint}
            mentionUsers={members}
            customerComments={customerComments}
            setNewComments={setNewComments}
          />
        </Col>
        <Col md={4} className="settings-right-section negative-gutter-md-down">
          {(touchpoint.touchpointLinks.length > 0 || touchpoint.touchpointAttachments.length > 0) && (
            <>
              <Files
                title="Resources"
                links={touchpoint.touchpointLinks}
                attachments={touchpoint.touchpointAttachments}
                touchpoint={touchpoint}
                customerTouchpoint={customerTouchpoint}
                canEdit={false}
                canDelete={true}
                onUpdated={handleResourcesUpdated}
                hideFileUpload={appSettings.hideFileUpload}
              />
              <Divider />
            </>
          )}
          <Files
            title="Uploads"
            links={touchpoint.links}
            attachments={touchpoint.attachments}
            touchpoint={touchpoint}
            customerTouchpoint={customerTouchpoint}
            canEdit={true}
            isDraggable={false}
            onUpdated={handleFilesUpdated}
            hideFileUpload={appSettings.hideFileUpload}
          />
        </Col>
      </Row>
    );
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="outline-light" ref={ref} onClick={onClick} size="sm" className="color-action-anchor">
      {children}
    </Button>
  ));

  const confirmModalProps = {
    title: 'Complete Step',
    confirmText: 'Mark tasks and step complete',
    variant: 'primary',
    show: !isConfirmModalDisabled && !!completeTouchpointWithoutTask,
    message: (
      <>
        When marking a step complete, it will also mark all uncompleted tasks in the step complete.
        <br />
      </>
    ),
    messageAlign: 'left',
    checkboxProps: {
      label: 'Do not show again',
      checked: !showConfirm,
      onChange: () => setShowConfirm((val) => !val),
    },
    innerProps: {
      maxWidth: '620px',
      padding: '70px 20px 40px',
    },
    buttonsContainerProps: {
      display: 'flex',
      justifyContent: 'end',
    },
    autoButtonsWidth: true,
    onConfirm: () => {
      setCustomerTouchpointStatus(completeTouchpointWithoutTask);
      setCompleteTouchpointWithoutTask(null);

      if (!showConfirm) {
        const confirmSettings = LocalStorage.get(HIDE_CONFIRM_COMPLETE_INCOMPLETE_ITEMS);
        LocalStorage.set(HIDE_CONFIRM_COMPLETE_INCOMPLETE_ITEMS, {
          ...confirmSettings,
          [touchpoint.customerCanvasId]: true,
        });
        setConfirmModalDisabled(true);
      }
    },
    onHide: () => {
      setCompleteTouchpointWithoutTask(null);
      setShowConfirm(true);
    },
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      className="modal-full"
      contentClassName="overflow-hidden"
      enforceFocus={false}
    >
      <ModalMultiButton
        title={REMOVE_CUSTOMERS.title}
        message={REMOVE_CUSTOMERS.message}
        show={showCustomerFacingChange}
        firstButtonHandler={keepCustomersHandler}
        firstButtonText={REMOVE_CUSTOMERS.firstButton}
        firstButtonVariant="secondary"
        secondButtonHandler={removeCustomersHandler}
        secondButtonText={REMOVE_CUSTOMERS.secondButton}
        secondButtonVariant="danger"
        onHide={() => {
          setCustomerFacingCancelled(true);
          setShowCustomerFacingChange(false);
        }}
      />
      <div className="d-flex justify-content-between align-items-center">
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle}>
            {currentStatus.title}
            <FontAwesomeIcon icon={faAngleDown} className="ml-5" />
          </Dropdown.Toggle>
          <DisplayDate
            touchpoint={touchpoint}
            dueDate={dueDate}
            setShowEditDateModal={setShowEditDateModal}
          />
          <Dropdown.Menu>
            <List Divider={Dropdown.Divider}>
              {statuses.map((status) => (
                <Dropdown.Item
                  onClick={() => {
                    if (isViewAsCustomer) {
                      toast.error('Please exit customer view to do this.');
                      return;
                    }

                    const hasIncompleteTask = activeTouchpointTasks.some((task) => !task?.completed);
                    hasIncompleteTask && !isConfirmModalDisabled && status.value === 'Completed'
                      ? setCompleteTouchpointWithoutTask(status)
                      : setCustomerTouchpointStatus(status);
                  }}
                  key={status.value}
                  className={status === currentStatus ? 'font-weight-bold' : ''}
                >
                  {status.title}
                </Dropdown.Item>
              ))}
            </List>
          </Dropdown.Menu>
        </Dropdown>
        <FontAwesomeIcon icon={faTimes} onClick={onHide} className="close-button clickable" />
      </div>
      {showEditDateModal && !isExternal && (
        <DateChangeState
          touchpoint={touchpoint}
          canvasStartDate={canvasStartDate}
          canvasEndDate={canvasEndDate}
          canvasTargetEnd={canvasTargetEnd}
          updateTouchpoint={onTouchpointUpdate}
          setShowEditDateModal={setShowEditDateModal}
          dependentData={dependentData}
        >
          <EditDateModal
            dynamicTimeline={dynamicTimeline}
            dependentData={dependentData}
            touchpoint={touchpoint}
          />
        </DateChangeState>
      )}

      <div className="mr-4">
        <TouchpointHeader
          touchpoint={touchpoint}
          isCustomerFacing={customerTouchpoint?.customerFacing}
          handleCustomerFacing={handleCustomerFacing}
          isCancelled={customerFacingCancelled}
          handleReload={handleReload}
          mergedSteps={mergedSteps}
        />
        <div className="touchpoint-modal-header mb-4">
          <div className="mrx-5 mb-4x">
            <Members
              title="Members"
              customerCanvasId={customerCanvasId}
              customerId={customerId}
              members={members}
              onAddMembers={addMembersTouchpoint}
              onRemoveMember={removeMember}
              canView={roleAtleast(FRONT_LINE) && !isViewAsCustomer}
              canAdd={roleAtleast(FRONT_LINE) && !isViewAsCustomer}
              scope="touchpoint"
            />
          </div>
          {(touchpoint.touchpointTags.length > 0 || isEditMode === true) && (
            <div className="mb-4x">
              <h2 className="title mb-1-5">Tags</h2>
              {!isEditMode ?
                (<Tags tags={touchpoint.touchpointTags} large={true} />)
                : (<EditActiveStepTags
                  touchpointId={touchpoint.id}
                  touchpointTags={touchpoint.touchpointTags || []}
                  onAdded={onTagsUpdated}
                  onRemoved={onTagsUpdated} />)}
            </div>
          )}
        </div>
      </div>
      <Tabs
        defaultActiveKey="step"
        id="touchpoint-tabs"
        className="mt-3"
        activeKey={selectedTab}
        onSelect={handleTabSelect}
      >
        <Tab eventKey="step" title="Step" className="mt-0">
          <StepTab />
        </Tab>
        {!isExternal && !isViewAsCustomer && (
          <Tab eventKey="activity" title="Activity & Notes">
            <Activity customerTouchpoint={customerTouchpoint} refreshToggle={refreshActivityToggle} />
          </Tab>
        )}
        {!isExternal && !isViewAsCustomer && (
          <Tab eventKey="actions" title="Email">
            {selectedTab === 'actions' && (
              <Actions touchpoint={touchpoint} onSave={handleEmailTriggerUpdate} />
            )}
          </Tab>
        )}
        {appSettings?.employeeIdeas && roleAtleast(CONTRIBUTOR) && !isViewAsCustomer && (
          <Tab eventKey="ideas" title="Ideas">
            <Ideas
              touchpoint={touchpoint}
              touchpointIdeas={touchpoint.touchpointIdeas}
              onIdeasUpdated={onIdeasUpdated}
            />
          </Tab>
        )}
      </Tabs>
      <ModalConfirm {...confirmModalProps} />
    </Modal>
  );
}
