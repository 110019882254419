import Donut from 'components/Charts/Donut';
import React, { useState, useEffect, useContext } from 'react';
import { GraphContainerStyle, GraphWrapperStyle, TableTitleStyle, LegendSymbolStyle } from './SharedStyles';
import * as Api from '../../api/Api';
import * as ServerResponseHandler from '../../api/ServerResponseHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import ReportingContext from './context/reportingContext';
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import HeaderFilter from './HeaderFilter';

const TextEmpStyled = styled.span`
  font-size: 24px;
  font-weight: 700;
  margin: 0 8px 0 16px;
`;

const RowSpan = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: default;
`;

const Separator = styled.div`
  margin: 0 0 0 24px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--schedule-sapphire);
`;

const StatusOfProjects = ({ showTable, setShowTable }) => {
  const { activeAccountFilters } = useContext(ReportingContext);
  const [showFilters, setShowFilters] = useState(false);
  const [statusSummaryData, setStatusSummaryData] = useState([
    {
      id: 'On Time',
      value: 0,
    },
    {
      id: 'Past Due',
      value: 0,
    },
    {
      id: 'No Data',
      value: true,
    },
  ]);
  const [totalProj, setTotalProj] = useState(0);

  useEffect(() => {
    const getProjectSummaryData = async () => {
      let accountFilters = '';
      if (activeAccountFilters?.length > 0) {
        activeAccountFilters.forEach((filter, index) => {
          accountFilters += index === 0 ? `?clientTypes=${filter}` : `&clientTypes=${filter}`;
        });
      }
      const res = await Api.get(`Report/GetProjectStatusSummary${accountFilters}`);
      if (res !== null) {
        setTotalProj(res.pastDueCount + res.onTimeCount);
        setStatusSummaryData([
          {
            id: 'On Time',
            value: res.onTimeCount,
          },
          {
            id: 'Past Due',
            value: res.pastDueCount,
          },
          {
            id: 'No Data',
            value: !res.pastDueCount && !res.onTimeCount,
          },
        ]);
      }
    };
    try {
      getProjectSummaryData();
    } catch (error) {
      return ServerResponseHandler.handleError(error);
    }
  }, [activeAccountFilters]);

  return (
    <>
      <GraphContainerStyle margin="0 16px 0 0" width="480" height={showTable ? '476' : null}>
        <TableTitleStyle onClick={setShowTable}>
          <span className="title">
            <StyledIcon icon={showTable ? faAnglesUp : faAnglesDown} className="mr-2" />
            Status of Active Projects
          </span>
          {activeAccountFilters.length > 0 && (
            <HeaderFilter setShowFilters={setShowFilters} showFilters={showFilters} useTimeFilter={false} />
          )}
        </TableTitleStyle>
        {showTable && (
          <GraphWrapperStyle>
            <p>
              <TextEmpStyled>{totalProj}</TextEmpStyled>
              {totalProj === 1 ? 'Total Project' : 'Total Projects'}
            </p>
            <RowSpan className="justify-content-center">
              <Donut data={statusSummaryData} />
              <Separator>
                <RowSpan>
                  <LegendSymbolStyle color="var(--green-bright)" />
                  <TextEmpStyled>
                    {Math.round(Boolean(totalProj) ? (statusSummaryData[0].value / totalProj) * 100 : 0)}%
                  </TextEmpStyled>{' '}
                  On Time
                </RowSpan>
                <RowSpan>
                  <LegendSymbolStyle color="var(--orange-bright)" />
                  <TextEmpStyled>
                    {Math.round(Boolean(totalProj) ? (statusSummaryData[1].value / totalProj) * 100 : 0)}%
                  </TextEmpStyled>{' '}
                  Past Due
                </RowSpan>
              </Separator>
            </RowSpan>
          </GraphWrapperStyle>
        )}
      </GraphContainerStyle>
    </>
  );
};

export default StatusOfProjects;
