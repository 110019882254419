import styled from 'styled-components';

export const TableHeaderRow = styled.tr`
  padding: 0 1.438rem;

  @media only screen and (min-width: 768px) {
    & > th:first-child {
      padding-left: 8%;
    }
  }
`;

export const TableBodyRow = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.438rem;
  cursor: pointer;
  transition: all 0.3s ease-in;

  &:hover {
    color: initial;
    text-decoration: initial;
    background: var(--table-row-hover);
  }

  svg {
    color: var(--playbook-pewter);
  }
`;

export const StyledImg = styled.img`
  max-width: 293px;
`;
