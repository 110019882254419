import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

const DeleteButton = styled.button`
  border: 0;
  background: none;
  > svg {
    color: ${props => props.disabled ? 'rgba(152, 161, 177, 0.5)' : 'var(--playbook-pewter)'};
  }
`;

/**
 * Renders a trash delete icon button
 * @property {boolean} props.disabled Action object type
 * @property {function} props.onClick Errors in the form
 * @returns {JSX.Element}
 */
const DeleteButtonTrashIcon = ({ onClick = () => {}, disabled }) => {
  return (
    <DeleteButton onClick={onClick} disabled={disabled}>
      <FontAwesomeIcon icon={faTrash} />
    </DeleteButton>
  );
};

export default DeleteButtonTrashIcon;
