import { useState, useEffect, useRef, useContext } from 'react';
import {
  TableWrapperStyle,
  TableTitleStyle,
  TableStyle,
  TableDataStyle,
  NoDataTextStyle,
} from './SharedStyles';
import * as Api from '../../api/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortByDate, sortByLetter, sortByNumber } from './sorting';
import { shortDate } from 'helpers/DateHelper';
import { Link } from 'react-router-dom';
import TableHeader from './TableHeader';
import ReportingContext from './context/reportingContext';
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import HeaderFilter from './HeaderFilter';
import styled from 'styled-components';

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--schedule-sapphire);
`;

export default function IdeasTable() {
  const [ideasData, setIdeasData] = useState([]);
  const [sortedColumn, setSortedColumn] = useState('Date Entered');
  const [showTable, setShowTable] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const { activeTimeRangeFilters, startDate, endDate } = useContext(ReportingContext);
  const table = useRef();

  useEffect(() => {
    const prepareSort = (data) => {
      const sortedData = data.sort(
        (a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
      );
      setIdeasData(sortedData);
    };
    const getTopIdeasData = async () => {
      const res = await Api.get(`Report/GetTopIdeasList?startDate=${startDate}&endDate=${endDate}`);
      prepareSort(res?.items);
    };
    getTopIdeasData();
  }, [activeTimeRangeFilters, endDate, startDate]);

  return (
    <TableWrapperStyle>
      <TableTitleStyle onClick={() => setShowTable(!showTable)}>
        <span className="title">
          <StyledIcon icon={showTable ? faAnglesUp : faAnglesDown} className="mr-2" />
          Ideas
        </span>
        {activeTimeRangeFilters.length > 0 && (
          <HeaderFilter setShowFilters={setShowFilters} showFilters={showFilters} useAccountFilter={false} />
        )}
      </TableTitleStyle>
      {showTable && (
        <TableStyle ref={table}>
          <thead>
            <tr>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByLetter}
                setSortedColumn={setSortedColumn}
              >
                Idea Title
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByDate}
                setSortedColumn={setSortedColumn}
                sortByDefault={true}
              >
                Date Entered
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByLetter}
                setSortedColumn={setSortedColumn}
              >
                Project
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByLetter}
                setSortedColumn={setSortedColumn}
              >
                Author
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByLetter}
                setSortedColumn={setSortedColumn}
              >
                Step
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByNumber}
                setSortedColumn={setSortedColumn}
              >
                Votes
              </TableHeader>
            </tr>
          </thead>
          <tbody>
            {ideasData?.length > 0 ? (
              ideasData.map((item) => (
                <tr key={item?.id}>
                  <TableDataStyle link={true}>
                    {item?.canvasId && item?.touchpointId ? (
                      <Link to={`/templates/${item?.canvasId}/steps/${item?.touchpointId}#ideas`}>
                        {item?.title}
                      </Link>
                    ) : (
                      item?.title
                    )}
                  </TableDataStyle>
                  <TableDataStyle>{shortDate(new Date(item?.dateCreated))}</TableDataStyle>
                  <TableDataStyle>{item?.canvasName}</TableDataStyle>
                  <TableDataStyle>{item?.ownerName}</TableDataStyle>
                  <TableDataStyle>{item?.touchpointName}</TableDataStyle>
                  <TableDataStyle>{item?.votesCount}</TableDataStyle>
                </tr>
              ))
            ) : (
              <tr>
                <td className="w-full">
                  <NoDataTextStyle>No ideas entered within filters</NoDataTextStyle>
                </td>
              </tr>
            )}
          </tbody>
        </TableStyle>
      )}
    </TableWrapperStyle>
  );
}
