import ActivityItem from './ActivityItem';
import InfiniteList from './InfiniteList';

export default function Activity({ customerTouchpoint, refreshToggle }) {
  return (
    <>
      <div className="my-4">
        <InfiniteList
          customerTouchpoint={customerTouchpoint}
          defaultPageSize={10}
          isComments={false}
          refreshToggle={refreshToggle}
          Item={ActivityItem}
        />
      </div>
    </>
  );
}

