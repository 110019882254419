import * as Api from 'api/Api';
import { OBJECT_TYPE_ENUM, REPLACE_OPERATION } from 'constants';

class Users {
  constructor(props) {
    if (props) Object.assign(this, props);
  }

  static async getUsers() {
    const response = await Api.get('User/GetAll');
    return response;
  }

  static async addUser(data) {
    const response = await Api.post('User/CreateUser', data);
    return response;
  }

  static async updateUser(id, data) {
    const { field, value } = data;

    const request = [
      {
        operationType: OBJECT_TYPE_ENUM.PROFILE,
        path: field,
        op: REPLACE_OPERATION,
        value,
      },
    ];

    const response = await Api.patch(`User/PartialUserUpdate`, request, {
      params: { guid: id },
    });

    return response;
  }

  static async getTotalAccounts(id) {
    const response = await Api.get(`Customer/GetCountByOwner?ownerId=${id}`);
    return response;
  }

  static async deleteAndReassignUser(id, newOwnerId = null) {
    const response = await Api.post(`User/DeleteAndReassignOwner`, null, {
      params: {
        id,
        newOwnerId,
      },
    });

    return response;
  }

  static async sendConfirmationEmail(email) {
    const response = await Api.post(`Account/SendConfirmationEmail`, null, {
      params: {
        email,
      },
    });

    return response;
  }
}

export default Users;
