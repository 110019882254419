import InfoTextBox from 'components/Modals/Shared/InfoTextBox';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CustomerContext from 'stores/Customer/customerContext';
import { Body, Container, Header } from './SharedStyles';

const InternalEngagement = ({ canvasId, dateTodayFormat }) => {
  const { summaryData } = useContext(CustomerContext);
  const [activityDate, setActivityDate] = useState(null);
  useEffect(() => {
    const getActivityDate =
      summaryData?.filter((data) => data.id === canvasId)[0]?.engagement?.lastInternalEngagement ?? null;

    setActivityDate(getActivityDate);
  }, [summaryData, canvasId]);

  return (
    <Container>
      <Header>Internal Engagement</Header>
      <Body>
        <Row>
          <Col className="font-weight-bold d-flex">
            Last Activity
            <span className="ml-1">
              <InfoTextBox notActive={false} width={'350px'} top={'-100px'} left={'-105px'} zIndex={1}>
                Internal activity includes notes, comments, completing tasks, and editing step or project
                details or members..
              </InfoTextBox>
            </span>
          </Col>
          <Col className="text-right mr-3">{activityDate ? dateTodayFormat(activityDate) : '--'}</Col>
        </Row>
      </Body>
    </Container>
  );
};

export default InternalEngagement;
