import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Body, Header, Container, GrayText } from './SharedStyles';
import CustomerContext from 'stores/Customer/customerContext';
import { Link } from 'react-router-dom';
import { differenceInBusinessDays, format } from 'date-fns';
import InfoTextBox from 'components/Modals/Shared/InfoTextBox';
import styled from 'styled-components';
import ModalConfirm from 'components/Modals/Confirm';
import { INVALID_MODAL } from 'constants';

const StyledCol = styled(Col)`
  color: ${(props) => (props.isDanger ? 'var(--danger)' : 'var(--champion-charcoal)')};
`;

const DetailContainer = styled.div`
  font-size: 14px;
`;

const StartDateLink = styled(Button)`
  font-size: 1rem;
  padding: 0;
  outline: none;

  &:focus {
    box-shadow: none;
  }
`;

const Overview = ({ canvas }) => {
  const { accountData, summaryData, onShowDateModal } = useContext(CustomerContext);
  const [overviewData, setOverviewData] = useState({});
  const [showInvalidModal, setShowInvalidModal] = useState(false);

  useEffect(() => {
    const overviewData = summaryData?.filter((data) => data.id === canvas.id)[0];
    setOverviewData(overviewData);
  }, [summaryData, canvas.id]);

  const businessDaysHandler = (date) => {
    if (overviewData?.status === 'Complete') {
      return 'Completed';
    }

    return `${Math.abs(differenceInBusinessDays(new Date(date ?? null), new Date()))} ${
      Math.abs(differenceInBusinessDays(new Date(date ?? null), new Date())) === 1
        ? 'business day'
        : 'business days'
    } ${differenceInBusinessDays(new Date(date ?? null), new Date()) < 0 ? 'past due' : ''}`;
  };

  const onClickStartDate = (event, overviewData) => {
    event.preventDefault();
    if (overviewData.completedSteps === 0) {
      onShowDateModal(overviewData);
    } else {
      setShowInvalidModal(true);
    }
  };

  return (
    <>
      <Container>
        <Header>Overview</Header>
        <Body>
          <Row className="mb-3">
            <Col xs={6} md={4} className="font-weight-bold">
              Project Owner
            </Col>
            <Col>
              {Boolean(accountData?.ownerFirstName || accountData?.ownerLastName)
                ? `${accountData?.ownerFirstName} ${accountData?.ownerLastName}`
                : '--'}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={6} md={4} className="font-weight-bold">
              Current Step
            </Col>
            <Col>
              {overviewData?.inProgressSteps?.length > 0 ? (
                overviewData?.inProgressSteps.map((step, index) => {
                  return (
                    <div key={`overview-data-${index}`} className="mb-3">
                      <Link
                        className="font-weight-bold"
                        to={`/accounts/${accountData?.id}/projects/${canvas?.id}/steps/${step?.id}`}
                      >
                        {step?.name}
                      </Link>
                      <DetailContainer>
                        <GrayText>
                          {`(In progress for ${differenceInBusinessDays(
                            new Date(),
                            new Date(step?.startDate)
                          )} business days)`}
                        </GrayText>
                        <div>{`${step?.totalTasks} open tasks`}</div>
                        <div>
                          {step?.assignees?.map((assignee, index) => (
                            <span key={`assignee-id-${index}`}>{`${index >= 1 ? ', ' : ''}${
                              assignee.firstName
                            } ${assignee.lastName}`}</span>
                          ))}
                          {/* Remind Icon functionality reserved for V2 */}
                          {/* <IconContainer role="button">
                            <FontAwesomeIcon icon={faCommentLines} className="ml-2 mr-1" />
                            Remind
                          </IconContainer> */}
                        </div>
                      </DetailContainer>
                    </div>
                  );
                })
              ) : (
                <div>--</div>
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={6} md={4} className="d-flex font-weight-bold">
              Progress
              <span className="ml-1">
                <InfoTextBox notActive={false} width={'411px'} top={0} zIndex={1}>
                  Steps are cards within the project view and tasks are checklist items within a step.
                </InfoTextBox>
              </span>
            </Col>
            <Col>
              <div>
                <span className="mr-1 font-weight-bold">{overviewData?.completedSteps}</span>
                steps completed out of
                <span className="ml-1 font-weight-bold">{overviewData?.totalSteps}</span>
              </div>
              <span className="mr-1 font-weight-bold">{overviewData?.completedTasks}</span>
              tasks completed out of
              <span className="ml-1 font-weight-bold">{overviewData?.totalTasks}</span>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={6} md={4} className="font-weight-bold">
              Forecasted Completion Date
            </Col>
            <StyledCol>
              {format(new Date(overviewData?.forcastedCompletion ?? null), 'MMM d, yyyy')}
              <GrayText>{businessDaysHandler(overviewData?.forcastedCompletion)}</GrayText>
            </StyledCol>
          </Row>
          <Row className="mb-3">
            <Col xs={6} md={4} className="font-weight-bold">
              Target Completion Date
            </Col>
            <Col>
              <div>{format(new Date(overviewData?.endDate ?? null), 'MMM d, yyyy')}</div>
              <GrayText>{businessDaysHandler(overviewData?.endDate)}</GrayText>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={4} className="font-weight-bold">
              Start Date
            </Col>

            <Col>
              <StartDateLink
                variant="link"
                className="font-weight-bold"
                onClick={(event) => onClickStartDate(event, overviewData)}
              >
                {format(new Date(overviewData?.startDate ?? null), 'MMM d, yyyy')}
              </StartDateLink>
            </Col>
          </Row>
        </Body>
      </Container>
      <ModalConfirm
        show={showInvalidModal}
        innerclassname="py-5 pb-3"
        buttonclassname="pt-3"
        title={INVALID_MODAL.TITLE}
        hideCancel
        onHide={() => setShowInvalidModal(false)}
        onConfirm={() => setShowInvalidModal(false)}
        message={INVALID_MODAL.MESSAGE}
        autoButtonsWidth
        variant="primary"
        confirmText={INVALID_MODAL.CONFIRM}
      />
    </>
  );
};

export default Overview;
