import * as yup from 'yup';
import { actionShouldBeHidden } from '../components/Modals/GlobalAutomationRules/utils';

export const AUTOMATION = 'Automation';

export const TRIGGERS = {
  account: 'Customer',
  contact: 'UserTenantCustomer',
};

export const TRIGGER_DISPLAY_VALUE_MAP = {
  [TRIGGERS.account]: 'Account',
  [TRIGGERS.contact]: 'Contact',
};

export const TRIGGER_TYPES = {
  accountCreation: 'AccountCreation',
  accountUpdate: 'AccountUpdate',
  contactCreation: 'ContactCreation',
  contactUpdated: 'ContactUpdated',
  canvasCreation: 'CanvasCreation',
  canvasStatusChange: 'CanvasStatusChange',
};

export const TRIGGER_OPTIONS = [
  {
    label: 'Account',
    value: TRIGGERS.account,
  },
  {
    label: 'Contact',
    value: TRIGGERS.contact,
  },
];

export const TRIGGER_TYPE_OPTIONS_BY_TRIGGER = {
  [TRIGGERS.account]: [
    { label: 'Created', value: TRIGGER_TYPES.accountCreation },
    { label: 'Updated', value: TRIGGER_TYPES.accountUpdate },
  ],
  [TRIGGERS.contact]: [
    { label: 'Created', value: TRIGGER_TYPES.contactCreation },
    { label: 'Updated', value: TRIGGER_TYPES.contactUpdated },
  ],
};

export const SAVE_BUTTON_TYPE = {
  live: 'live',
  draft: 'draft',
};

export const RULES_FORM_FIELDS = {
  name: 'name',
  isLive: 'isLive',
  orderNo: 'orderNo',
  ownerId: 'ownerId',
  conditionLogicalOperator: 'conditionLogicalOperator',
  sourceCategory: 'sourceCategory',
  sourceCategoryType: 'sourceCategoryType',
  triggerType: 'triggerType',
  conditions: 'conditions',
  actions: 'actions',
  saveButtonType: 'saveButtonType',
};

export const CONDITION_TYPE = {
  and: 'AND',
  or: 'OR',
};

export const CONDITION_TYPE_DISPLAY_VALUE_MAP = {
  [CONDITION_TYPE.and]: 'And',
  [CONDITION_TYPE.or]: 'Or',
};

export const CONDITION_TYPE_OPTIONS = [
  { value: CONDITION_TYPE.and, label: CONDITION_TYPE_DISPLAY_VALUE_MAP[CONDITION_TYPE.and] },
  { value: CONDITION_TYPE.or, label: CONDITION_TYPE_DISPLAY_VALUE_MAP[CONDITION_TYPE.or] },
];

export const OPERAND_TYPES = {
  string: 'string',
  numeric: 'numeric',
  datetime: 'datetime',
  boolean: 'boolean',
  func_canvas: 'func_canvas',
};

export const OPERATORS = {
  equals: 'Equals',
  equalsCaseInvariant: 'EqualsCaseInvariant',
  startsWith: 'StartsWith',
  endsWith: 'EndsWith',
  contains: 'Contains',
  isNullOrEmpty: 'IsNullOrEmpty',
  isNotNullOrEmpty: 'IsNotNullOrEmpty',
  lessThan: 'LessThan',
  lessThanOrEqualTo: 'LessThanOrEqualTo',
  greaterThan: 'GreaterThan',
  greaterThanOrEqualTo: 'GreaterThanOrEqualTo',
  isBefore: 'IsBefore',
  isBeforeOrOn: 'IsBeforeOrOn',
  isAfter: 'IsAfter',
  isAfterOrOn: 'IsAfterOrOn',
  isTrue: 'IsTrue',
  isFalse: 'IsFalse',
  hasHadTemplate: 'HasHadTemplate',
  hasNotHadTemplate: 'HasNotHadTemplate',
};

export const LOGICAL_OPERATORS_DISPLAY_VALUES_MAP = {
  [OPERATORS.equals]: 'Equals',
  [OPERATORS.equalsCaseInvariant]: 'Equals Case Invariant',
  [OPERATORS.startsWith]: 'Starts With',
  [OPERATORS.endsWith]: 'Ends With',
  [OPERATORS.contains]: 'Contains',
  [OPERATORS.isNullOrEmpty]: 'Is Null Or Empty',
  [OPERATORS.isNotNullOrEmpty]: 'Is Not Null Or Empty',
  [OPERATORS.lessThan]: 'Less Than',
  [OPERATORS.lessThanOrEqualTo]: 'Less Than Or Equal To',
  [OPERATORS.greaterThan]: 'Greater Than',
  [OPERATORS.greaterThanOrEqualTo]: 'Greater Than Or Equal To',
  [OPERATORS.isBefore]: 'Is Before',
  [OPERATORS.isBeforeOrOn]: 'Is Before Or On',
  [OPERATORS.isAfter]: 'Is After',
  [OPERATORS.isAfterOrOn]: 'Is After Or On',
  [OPERATORS.isTrue]: 'Is True',
  [OPERATORS.isFalse]: 'Is False',
  [OPERATORS.hasHadTemplate]: 'Includes',
  [OPERATORS.hasNotHadTemplate]: 'Does Not Include',
};

export const CONDITION_OPERATORS_BY_TYPE_OPTIONS = {
  [OPERAND_TYPES.string]: [
    { value: OPERATORS.equals, label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.equals] },
    {
      value: OPERATORS.equalsCaseInvariant,
      label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.equalsCaseInvariant],
    },
    { value: OPERATORS.startsWith, label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.startsWith] },
    { value: OPERATORS.endsWith, label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.endsWith] },
    { value: OPERATORS.contains, label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.contains] },
    { value: OPERATORS.isNullOrEmpty, label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.isNullOrEmpty] },
    {
      value: OPERATORS.isNotNullOrEmpty,
      label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.isNotNullOrEmpty],
    },
  ],
  [OPERAND_TYPES.numeric]: [
    { value: OPERATORS.equals, label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.equals] },
    { value: OPERATORS.lessThan, label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.lessThan] },
    {
      value: OPERATORS.lessThanOrEqualTo,
      label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.lessThanOrEqualTo],
    },
    { value: OPERATORS.greaterThan, label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.greaterThan] },
    {
      value: OPERATORS.greaterThanOrEqualTo,
      label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.greaterThanOrEqualTo],
    },
  ],
  [OPERAND_TYPES.datetime]: [
    { value: OPERATORS.equals, label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.equals] },
    { value: OPERATORS.isBefore, label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.isBefore] },
    { value: OPERATORS.isBeforeOrOn, label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.isBeforeOrOn] },
    { value: OPERATORS.isAfter, label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.isAfter] },
    { value: OPERATORS.isAfterOrOn, label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.isAfterOrOn] },
    { value: OPERATORS.isNullOrEmpty, label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.isNullOrEmpty] },
    {
      value: OPERATORS.isNotNullOrEmpty,
      label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.isNotNullOrEmpty],
    },
  ],
  [OPERAND_TYPES.boolean]: [
    { value: OPERATORS.isTrue, label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.isTrue] },
    { value: OPERATORS.isFalse, label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.isFalse] },
  ],
  [OPERAND_TYPES.func_canvas]: [
    {
      value: OPERATORS.hasHadTemplate,
      label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.hasHadTemplate],
    },
    {
      value: OPERATORS.hasNotHadTemplate,
      label: LOGICAL_OPERATORS_DISPLAY_VALUES_MAP[OPERATORS.hasNotHadTemplate],
    },
  ],
};

export const CONDITION_COMMON_TEMPLATE = {
  id: '',
  rightOperand: '',
  orderNo: 0,
  logicalOperator: '',
  conditionType: '',
  leftOperand: '',
  leftOperandType: '',
};

export const LEFT_OPERAND_PATH_NAME = {
  LIST_OF_PROJECTS: 'list_of_projects',
};

export const RULE_ACTIONS = {
  AssignOwner: 'AssignAccountOwner',
  AssignCanvas: 'AssignCanvas',
  AddUserToCustomerCanvas: 'AddUserToCustomerCanvas',
  SetCustomerTouchpointStatus: 'SetCustomerTouchpointStatus',
  SetAccountType: 'SetAccountType',
  SetAccountStatus: 'SetAccountStatus',
  AddContactToProjects: 'AddContactToProjects',
};

export const RULE_ACTIONS_DISPLAY_VALUE_MAP = {
  [RULE_ACTIONS.AssignOwner]: 'Assign owner',
  [RULE_ACTIONS.AssignCanvas]: 'Create new project',
  [RULE_ACTIONS.AddUserToCustomerCanvas]: 'Add users to project',
  [RULE_ACTIONS.SetCustomerTouchpointStatus]: 'Set step status',
  [RULE_ACTIONS.SetAccountType]: 'Set account type',
  [RULE_ACTIONS.SetAccountStatus]: 'Set account status',
  [RULE_ACTIONS.AddContactToProjects]: 'Add contact to all active projects',
};

export const RULE_ACTIONS_BY_TRIGGER_OPTIONS = {
  [TRIGGERS.account]: [
    {
      value: RULE_ACTIONS.AddUserToCustomerCanvas,
      label: RULE_ACTIONS_DISPLAY_VALUE_MAP[RULE_ACTIONS.AddUserToCustomerCanvas],
    },
    {
      value: RULE_ACTIONS.AssignOwner,
      label: RULE_ACTIONS_DISPLAY_VALUE_MAP[RULE_ACTIONS.AssignOwner]
    },
    {
      value: RULE_ACTIONS.AssignCanvas,
      label: RULE_ACTIONS_DISPLAY_VALUE_MAP[RULE_ACTIONS.AssignCanvas]
    },
    {
      value: RULE_ACTIONS.SetAccountStatus,
      label: RULE_ACTIONS_DISPLAY_VALUE_MAP[RULE_ACTIONS.SetAccountStatus],
    },
    {
      value: RULE_ACTIONS.SetAccountType,
      label: RULE_ACTIONS_DISPLAY_VALUE_MAP[RULE_ACTIONS.SetAccountType],
    },
    {
      value: RULE_ACTIONS.SetCustomerTouchpointStatus,
      label: RULE_ACTIONS_DISPLAY_VALUE_MAP[RULE_ACTIONS.SetCustomerTouchpointStatus],
    },
  ],
  [TRIGGERS.contact]: [
    {
      value: RULE_ACTIONS.AddContactToProjects,
      label: RULE_ACTIONS_DISPLAY_VALUE_MAP[RULE_ACTIONS.AddContactToProjects],
    },
  ],
};

export const RULE_ACTION_TEMPLATE = {
  id: '',
  actionName: '',
  actionType: '',
  orderNo: 0,
  actionInput: '',
};

export const ACTIONS_DEPENDENCIES = {
  [RULE_ACTIONS.AssignOwner]: null,
  [RULE_ACTIONS.AssignCanvas]: null,
  [RULE_ACTIONS.AddUserToCustomerCanvas]: [RULE_ACTIONS.AssignCanvas],
  [RULE_ACTIONS.SetCustomerTouchpointStatus]: [RULE_ACTIONS.AssignCanvas],
  [RULE_ACTIONS.SetAccountType]: null,
  [RULE_ACTIONS.SetAccountStatus]: null,
  [RULE_ACTIONS.AddContactToProjects]: null,
};

export const ACTION_INPUT_VALIDATION_SCHEMA_MAP = {
  [RULE_ACTIONS.AssignOwner]: yup.object({
    ownerId: yup.string().required('Required'),
  }),
  [RULE_ACTIONS.AssignCanvas]: yup.object({
    canvasId: yup.number().notOneOf([0], 'Required').typeError('Required').required('Required'),
  }),
  [RULE_ACTIONS.AddUserToCustomerCanvas]: yup.object({
    userIds: yup.array().min(1, 'Required').required('Required'),
  }),
  [RULE_ACTIONS.SetCustomerTouchpointStatus]: yup.object({
    customerTouchpointId: yup.number().notOneOf([0], 'Required').typeError('Required').required('Required'),
    customerTouchpointStatus: yup.string().required('Required'),
  }),
  [RULE_ACTIONS.SetAccountType]: yup.object({
    clientTypeId: yup.number().notOneOf([0], 'Required').typeError('Required').required('Required'),
  }),
  [RULE_ACTIONS.SetAccountStatus]: yup.object({
    status: yup.string().required('Required'),
  }),
  [RULE_ACTIONS.AddContactToProjects]: yup.object({
    selection: yup.string().required('Required'),
  }),
};

export const LIVE_RULE_VALIDATION_SCHEMA = yup.object({
  name: yup.string().required('Required'),
  isLive: yup.boolean(),
  ownerId: yup.number().required(),
  conditionLogicalOperator: yup.string().required(),
  orderNo: yup.number().required(),
  sourceCategory: yup.string().required('Incomplete trigger'),
  triggerType: yup
    .string()
    .required('Incomplete trigger')
    .oneOf(Object.values(TRIGGER_TYPES))
    .typeError('Incomplete trigger'),
  conditions: yup
    .array()
    .of(
      yup.object({
        leftOperand: yup.string().typeError('should be string').required('Required'),
        leftOperandType: yup.string().typeError('should be string').required('Required'),
        rightOperand: yup.lazy((value) => {
          switch (typeof value) {
            case 'string':
              return yup
                .string()
                .typeError('should be string')
                .when(['leftOperandType', 'logicalOperator'], (type, operator, schema) =>
                  [
                    OPERAND_TYPES.string,
                    OPERAND_TYPES.datetime,
                    OPERAND_TYPES.numeric,
                    OPERAND_TYPES.func_canvas,
                  ].includes(type) && !['IsNotNullOrEmpty', 'IsNullOrEmpty'].includes(operator)
                    ? schema.required('required')
                    : schema
                );
            default:
              return yup
                .object()
                .typeError('invalid option')
                .when(['leftOperandType', 'logicalOperator'], (type, operator, schema) =>
                  [
                    OPERAND_TYPES.string,
                    OPERAND_TYPES.datetime,
                    OPERAND_TYPES.numeric,
                    OPERAND_TYPES.func_canvas,
                  ].includes(type) && !['IsNotNullOrEmpty', 'IsNullOrEmpty'].includes(operator)
                    ? schema.required('required')
                    : schema
                );
          }
        }),
        logicalOperator: yup.string().typeError('should be string').required('Required'),
        conditionType: yup.string().typeError('should be string').required('Required'),
        orderNo: yup.number().typeError('should be number').required('Required'),
      })
    )
    .min(1, 'Missing a condition'),
  actions: yup
    .array()
    .of(
      yup.object({
        actionName: yup.string().required('Required'),
        actionType: yup.string().required('Required'),
        orderNo: yup.number().typeError('should be number').required('Required'),
        actionInput: yup
          .object()
          .when('actionType', (type, schema) => ACTION_INPUT_VALIDATION_SCHEMA_MAP[type] || schema),
        actionOutput: yup.string(),
      })
    )
    .min(1, 'Missing an action'),
  notAllowedActionForTriggerType: yup
    .string()
    .test('not_allowed_action_for_trigger_type', 'Action not allowed for selected trigger type', (_, ctx) => {
      const { actions = [], triggerType } = ctx.parent;
      const notAllowedAction = actions.find((act) => actionShouldBeHidden(triggerType, act.actionType));
      const hasNotAllowedActions = Boolean(notAllowedAction);
      return hasNotAllowedActions
        ? ctx.createError({
          message: `"${RULE_ACTIONS_DISPLAY_VALUE_MAP[notAllowedAction.actionType]
            }" action is not allowed for trigger type ${triggerType}`,
          path: 'notAllowedActionForTriggerType',
        })
        : true;
    }),
});

export const ACTIVITY_LOG_AUTOMATION_TYPE = {
  automationStarted: 'AutomationStarted',
  automationError: 'AutomationError',
  automationCompleted: 'AutomationCompleted',
  automationInformation: 'AutomationInformation',
};

export const ACTIVITY_LOG_AUTOMATION_TYPE_DISPLAY_VALUE = {
  [ACTIVITY_LOG_AUTOMATION_TYPE.automationStarted]: 'Started',
  [ACTIVITY_LOG_AUTOMATION_TYPE.automationCompleted]: 'Completed',
  [ACTIVITY_LOG_AUTOMATION_TYPE.automationError]: 'Failed',
  [ACTIVITY_LOG_AUTOMATION_TYPE.automationInformation]: 'Info',
};

export const HIDDEN_ACTIONS_BY_TRIGGER_TYPE = {
  // [TRIGGER_TYPES.accountUpdate]: [
  //   RULE_ACTIONS.AddUserToCustomerCanvas,
  //   RULE_ACTIONS.SetCustomerTouchpointStatus,
  // ],
};
