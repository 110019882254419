import { useState, useEffect } from 'react';
import * as CanvasesApi from '../../api/canvases';
import toast from '../../components/toast';

const useCanvases = () => {
  const [canvases, setCanvases] = useState([]);
  const [canvasesOptions, setCanvasesOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCanvases = async () => {
    setLoading(true);
    try {
      const queryParams = { includeCanUnpublish: true };
      const response = await CanvasesApi.fetchCanvases(queryParams);
      setCanvases(response?.items || []);

      if (response?.items?.length) {
        setCanvasesOptions(response.items.map((canvas) => ({ label: canvas.name, value: canvas.id })));
      }
    } catch (err) {
      console.log(err);
      toast.error('There was an error fetching owners list');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCanvases();
  }, []);

  return {
    canvases,
    canvasesOptions,
    loading,
  };
};

export default useCanvases;
