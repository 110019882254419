import format from 'date-fns/format';

const dateMixin = (Base) => {
  if (Base) {
    return class extends Base {
      shortDateOf(prop) {
        return format(new Date(this[prop]), 'MMM d, yyyy');
      }
    };
  }

  return class {
    shortDateOf(prop) {
      return format(new Date(this[prop]), 'MMM d, yyyy');
    }
  };
};

export default dateMixin;
