import { EXTERNAL } from 'constants';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Api from 'api/Api';
import FullScreenWithBackground from 'components/FullScreenWithBackground';
import TableRow from 'components/TableRow';
import * as CommonHelper from 'helpers/CommonHelper';
import { LoginContainer } from './Styles';

const LoginPassword = ({ selectCompany }) => {
  const location = useLocation();
  const history = useNavigate();
  const params = new URLSearchParams(location.search);

  const [loginState, setLoginState] = useState({
    username: params.get('email'),
    password: '',
    error: null,
    availableAccounts: [],
    availableCustomers: [],
    showSelectAccount: false,
    showSelectCustomer: false,
    userGlobalId: '',
    tenantUuid: '',
    orgName: '',
  });

  useEffect(() => {
    if (selectCompany) {
      setLoginState((prev) => ({ ...prev, showSelectAccount: true }));
      getAccounts();
    }

    if (location.state && location.state.loggedOut) {
      setLoginState((prev) => ({ ...prev, error: 'You have been logged out. Please log in again.' }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setLoginState((prev) => ({ ...prev, [name]: value }));
  };

  const getAccounts = async () => {
    let data;
    try {
      data = await Api.get('User/GetAvailableOrganizations');
    } catch (error) {
      setLoginState((prev) => ({ ...prev, error: error.message, showSelectAccount: false }));
    }
    if (data) {
      CommonHelper.setOrgData(data);
      if (data.items && data.items.length > 1) {
        setLoginState((prev) => ({
          ...prev,
          availableAccounts: CommonHelper.getUniqueDataById(data.items),
          showSelectAccount: true,
        }));

        window.history.replaceState(null, 'Select Company', '/select-company');
      } else {
        setLoginState((prev) => ({
          ...prev,
          tenantUuid: data.activeOrganization.uuid,
          orgName: data.activeOrganization.name,
        }));

        goToDashboard();
      }
    }
  };

  const switchOrganization = async (organizationId) => {
    let response;

    setLoginState((prev) => ({
      ...prev,
      loading: true,
    }));

    try {
      response = await Api.post('User/SwitchOrganization?organizationId=' + organizationId);
    } catch (error) {
      setLoginState((prev) => ({
        ...prev,
        error: error.message,
        loading: false,
      }));
    }
    if (response) {
      let active = loginState.availableAccounts.find((item) => item.id === organizationId);

      setLoginState((prev) => ({
        ...prev,
        tenantUuid: active.uuid,
        orgName: active.name,
      }));

      storeSession(response, false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!loginState.username || !loginState.password) {
      setLoginState((prev) => ({
        ...prev,
        error: 'Please provide both username and password.',
      }));

      return;
    }

    setLoginState((prev) => ({
      ...prev,
      error: null,
      loading: true,
    }));

    let payload = {
      email: loginState.username,
      password: loginState.password,
    };
    let response;
    try {
      response = await Api.post('Authentication/SignIn', payload, {
        history,
      });
    } catch (error) {
      setLoginState((prev) => ({
        ...prev,
        error: error.message,
        loading: false,
      }));
    }
    if (response) {
      if (response.errorMessage) {
        setLoginState((prev) => ({
          ...prev,
          error: response.errorMessage,
          loading: false,
        }));
      } else {
        setLoginState((prev) => ({
          ...prev,
          userGlobalId: response.id,
        }));

        await storeSession(response, true);
      }
    }
  };

  const storeSession = async (data, selectAccount) => {
    setLoginState((prev) => ({
      ...prev,
      loading: true,
    }));

    const { errorMessage, role } = await CommonHelper.loadUserData(data.authToken);

    setLoginState((prev) => ({
      ...prev,
      loading: false,
    }));
    if (errorMessage) {
      setLoginState((prev) => ({
        ...prev,
        error: errorMessage,
      }));
      return;
    }

    if (selectAccount) {
      getAccounts();
      return;
    }

    if (!data?.redirect) {
      goToDashboard(role);
      return;
    }

    const redirectRoute = `/${data?.redirect}`;
    localStorage.setItem('externalProject', redirectRoute);
    history(redirectRoute);
  };

  const goToDashboard = (role) => {
    if (role !== EXTERNAL) {
      // TODO: this will only initialize internal users
      window.pendo.initialize({
        visitor: {
          id: loginState.userGlobalId,
          email: loginState.username,
        },
        account: {
          id: loginState.tenantUuid,
          name: loginState.orgName,
        },
      });
    }

    history('/dashboard');
  };

  if (loginState.showSelectAccount) {
    return (
      <FullScreenWithBackground
        title="Select company"
        maxWidth="508px"
        loading={loginState.loading}
        error={loginState.error}
        showFooter={false}
      >
        {loginState.availableAccounts.map((account, index) => (
          <TableRow onClick={() => switchOrganization(account.id)} key={index} className="modal-small" small>
            <Col>{account.name}</Col>
          </TableRow>
        ))}
      </FullScreenWithBackground>
    );
  }

  return (
    <FullScreenWithBackground
      title="Log in"
      loading={loginState.loading}
      error={loginState.error}
      showFooter={false}
      showBackButton
    >
      <LoginContainer $hasError={!!loginState.error}>
        <form onSubmit={handleSubmit} className="text-center">
          <Form.Group>
            <Form.Control
              required
              readOnly
              value={loginState.username}
              name="username"
              placeholder="Email"
              className="mb-4"
            />

            <Form.Control
              required
              value={loginState.password}
              name="password"
              type="password"
              placeholder="Password"
              autoFocus
              onChange={handleInputChange}
            />
          </Form.Group>

          <Button type="submit" variant="primary" block>
            Submit
          </Button>

          <Link to="/reset-password" className="d-inline-block mt-4 muted font-weight-bold">
            Forgot password?
          </Link>
        </form>
      </LoginContainer>
    </FullScreenWithBackground>
  );
};

export default LoginPassword;
