import { Form } from "react-bootstrap";
import styled from "styled-components";

export const TermsAndPolicyContainer = styled.div`
  font-size: 0.813rem;
  > a {
    color: var(--schedule-sapphire);
  }
`;

export const EmailField = styled(Form.Control)`
  color: var(--playbook-pewter);
`;
