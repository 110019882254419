import * as Yup from 'yup';
import { arrayFieldsSchema } from 'helpers/schemaHelper';
import { ERROR_MESSAGES } from '../../../constants/customFields';

export const editCustomerSchema = Yup.object().shape({
  customerId: Yup.number().required(),

  detailsFields: Yup.array().of(
    Yup.object().shape({
      customFieldSectionId: Yup.string(),
      name: Yup.string(),
      fields: arrayFieldsSchema,
    })
  ),
});

export const addCanvasSchema = Yup.object().shape({
  startDate: Yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED).nullable(),

  projectFields: arrayFieldsSchema,
});
