import React, { useRef, useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGripVertical,
  faPen,
  faEye,
  faEyeSlash,
  faTrash,
  faLockKeyhole,
} from '@fortawesome/pro-solid-svg-icons';
import { Form } from 'react-bootstrap';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FormikContext } from 'formik';
import * as uuid from 'uuid';
import InfoTextBox from 'components/Modals/Shared/InfoTextBox';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import {
  ACTIVE,
  CUSTOM_FIELD_MODES,
  DEFAULT_FIELDS,
  FIELD_TYPES,
  PERMISSIONS,
  CUSTOM_FIELDS_MODAL_VARIATIONS,
  OBJECT_TYPE_ENUM,
  INTERNAL_SOURCE,
} from 'constants';
import { hasPluralize, pluralize } from 'helpers/CommonHelper';
import ModalConfirm from 'components/Modals/Confirm';
import FieldModal from 'components/Modals/CustomFields/Field';
import { currencyList, NAPermission } from 'components/Modals/CustomFields/fixtures';
import { useProfileField } from 'views/CustomFields/Profile/hooks';
import { useReadOnlyField } from '../hooks';
import { getFieldPermission, getFieldType } from '../Details/fixtures';
import { CautionSection, DeleteMessage } from '../Details/Styles';
import { useLocation } from 'react-router-dom';

const EditableField = ({ id }) => {
  const location = useLocation();
  const { handleShow, handleHide, formikBag, showAddFieldModal, queryFieldId, setFieldURLParam } =
    useProfileField();

  const { profile, deleteField, options, toggleHideField, fetchFieldCount } = useContext(CustomFieldsContext);

  const { setNodeRef, listeners, isDragging, transform, transition } = useSortable({
    id,
  });

  const [isFieldHidden, setIsFieldHidden] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fieldCount, setFieldCount] = useState(null);

  const infoRef = useRef(null);
  const visibilityRef = useRef(null);

  const field = useMemo(
    () => profile?.editableFields?.find(({ objectMapId }) => String(objectMapId) === String(id)),
    [profile?.editableFields, id]
  );

  const hasQueryFieldId = useMemo(
    () => queryFieldId && String(queryFieldId) === String(field?.objectMapId),
    [field?.objectMapId, queryFieldId]
  );
  const isReadOnly = field?.syncOption === PERMISSIONS.READ_ONLY;
  const fieldPermission = getFieldPermission(field?.syncOption);
  const fieldType = getFieldType(field?.fieldType);

  const { renderField } = useReadOnlyField({
    type: fieldType,
    permission: fieldPermission,
    label: field?.fieldLabel,
  });

  const populateFieldCount = useCallback(async () => {
    const count = await fetchFieldCount(field?.objectMapId);

    if (count) setFieldCount(count);
  }, [fetchFieldCount, field?.objectMapId]);

  /**
   * Did not include "handleEdit" in the dependency array
   * since it will cause an infinite render
   **/
  useEffect(() => {
    const hashSplit = location.hash.split('?');
    if (hashSplit.length > 1) {
      const query = hashSplit[1].split('=');
      const queryId = query[1];
      const fieldId = id.split('-')[0];
      if (queryId === fieldId) {
        handleEdit();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash, id]);

  /**
   * Did not include "handleEdit" in the dependency array
   * since it will cause an infinite render and the
   * only needed value to be checked is hasQueryFieldId
   **/
  useEffect(() => {
    if (hasQueryFieldId) handleEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasQueryFieldId]);

  useEffect(() => {
    setIsFieldHidden(field?.isHidden);
  }, [field?.isHidden]);

  useEffect(() => {
    if (showDeleteModal) {
      populateFieldCount();
    }
  }, [populateFieldCount, showDeleteModal]);

  /**
   * Renders the delete message component
   * @param none
   * @returns Delete message component
   */
  const renderDeleteMessage = () => {
    return (
      <div>
        <DeleteMessage>Are you sure you want to delete this field?</DeleteMessage>

        <CautionSection>
          <p>Caution</p>
          <ul>
            {fieldCount && fieldCount > 0 && (
              <li>
                {pluralize(fieldCount, 'profile')} {hasPluralize(fieldCount)} data entered in this field that
                will be lost.
              </li>
            )}

            <li>If you’ve used this field as an email merge tag, you’ll need to edit that email template.</li>
          </ul>
        </CautionSection>
      </div>
    );
  };

  /**
   * Flattens the Data array
   * @param data
   * @returns Flattened Data
   */
  const flattenData = (data) => data.map(({ options }) => options).flat();

  /**
   * Finds the dropdown options by value
   * @param data
   * @returns Dropdown element
   */
  const findDropdownValue = (data, item) => data.find(({ response }) => response === item);

  /**
   * Finds the dropdown options by label
   * @param data
   * @returns Dropdown element
   */
  const findDropdownLabel = (data, item) => data.find(({ label }) => label === item);

  const { dataSourceList, fieldTypeList, permissionList, ownerList, typeList } = options;

  /**
   * On Edit Handler
   * @param none
   * @returns void
   */
  const handleEdit = () => {
    if (isFieldHidden) return;

    const {
      objectMapId,
      sourceType,
      customFieldSectionId,
      syncOption,
      fieldLabel,
      fieldType,
      fieldTypeOptions,
      isSortedFieldTypeOptions,
      isProtected,
      isRequired,
      isMergeTag,
    } = field;

    const flatDataSource = flattenData(dataSourceList);
    const getSourceType =
      sourceType !== INTERNAL_SOURCE ? options.externalFieldsLookUp[fieldLabel].source : sourceType;
    const fieldDataSource = findDropdownValue(flatDataSource, getSourceType);
    const formattedFieldType = findDropdownValue(fieldTypeList, fieldType);
    const formattedCurrency = currencyList[0];
    const fieldCurrency = fieldType === FIELD_TYPES.CURRENCY ? formattedCurrency : null;
    const isSyncNA = syncOption === PERMISSIONS.NA;
    const permission = isSyncNA ? { ...NAPermission } : findDropdownValue(permissionList, syncOption);
    const hasParenthesis = fieldLabel.includes('(');
    const formattedExternalValue = fieldLabel.toLowerCase().split(' ').join('-').replace(/\(|\)/g, '');
    const groupedExternalValue = { value: formattedExternalValue, label: fieldLabel };
    const uniqueExternalValue = findDropdownLabel(options?.flattenedExternalFields, fieldLabel);
    const externalField = hasParenthesis ? groupedExternalValue : uniqueExternalValue;
    const isSortedFieldBoolean = typeof isSortedFieldTypeOptions === 'boolean';
    const isSortedFieldValues = isSortedFieldBoolean ? isSortedFieldTypeOptions : false;
    const parsedDropdownValues = fieldTypeOptions ? fieldTypeOptions.map((value) => JSON.parse(value)) : null;
    const hasParsedDropdownValues = !!parsedDropdownValues && parsedDropdownValues.length > 0;
    const dropdownValues = hasParsedDropdownValues ? parsedDropdownValues : [];

    const formattedTypeList = typeList.map((item) => ({
      ...item,
      id: item.response,
    }));

    const typeOptions = [...formattedTypeList, { id: uuid.v4(), value: '' }];

    const fieldValues = [...dropdownValues, { id: uuid.v4(), value: '' }];

    const formattedFieldValues =
      fieldLabel === DEFAULT_FIELDS.OWNER
        ? ownerList
        : fieldLabel === DEFAULT_FIELDS.TYPE
        ? typeOptions
        : fieldValues;

    const values = {
      fieldMode: CUSTOM_FIELD_MODES.EDIT,
      editedFieldId: objectMapId,
      editedSectionId: customFieldSectionId,
      dataSource: fieldDataSource,
      fieldLabel: fieldLabel,
      previousFieldLabel: fieldLabel,
      isProtected: isProtected,
      fieldType: formattedFieldType,
      currency: fieldCurrency,
      externalField,
      permission,
      fieldValues: formattedFieldValues,
      previousExternalField: externalField,
      section: null,
      isSortedFieldValues,
      isRequired: isRequired,
      isMergeTag: isMergeTag,
      showFieldLabel: true,
      showFieldType: true,
      showCheckboxes: true,
      showPermission: true,
      showExternalField: true,
    };

    formikBag.setValues(values);

    !hasQueryFieldId && setFieldURLParam(field?.objectMapId);
    handleShow();
  };

  /**
   * Toggles the delete modal
   * @param none
   * @returns void
   */
  const toggleDeleteModal = (value) => () => setShowDeleteModal(value);

  /**
   * On Field Visibility Handler
   * @param none
   * @returns void
   */
  const handleFieldVisibility = () => {
    setIsFieldHidden((prev) => !prev);
    toggleHideField(field?.objectMapId, !isFieldHidden);
  };

  /**
   * Opens the edit modal if change
   * is clicked
   * @params none
   * @returns void
   */
  const handleLockChange = () => {
    handleEdit();
  };

  const dynamicWidth = isFieldHidden ? '10.25rem' : '26.375rem';

  /**
   * On Delete Handler
   * @param none
   * @returns void
   */
  const handleDelete = () => {
    deleteField(field?.objectMapId, OBJECT_TYPE_ENUM.PROFILE);
    setShowDeleteModal(false);
  };

  const fieldStyle = {
    transition,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : undefined,
  };

  const modalStyle = {
    inner: {
      maxWidth: '35.25rem',
      padding: '2.5rem 1.25rem !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  const isDateTime = {
    [FIELD_TYPES.DATE]: FIELD_TYPES.DATE,
    [FIELD_TYPES.TIME]: FIELD_TYPES.TIME,
    [FIELD_TYPES.DATE_AND_TIME]: FIELD_TYPES.DATE_AND_TIME,
    [FIELD_TYPES.DATE_TIME]: FIELD_TYPES.DATE_TIME,
  };

  return (
    <div
      ref={setNodeRef}
      className={`custom-field ${isReadOnly ? 'read-only' : ''} ${
        field?.isProtected ? 'default-field' : ''
      } ${isDateTime[field?.fieldType] ? 'date-time' : ''}`}
      style={fieldStyle}
    >
      <div className="custom-field__header">
        <div className="grip" {...listeners}>
          <FontAwesomeIcon icon={faGripVertical} />
        </div>
        <Form.Label>{field?.fieldLabel}</Form.Label>

        <div className="custom-field__options">
          {field?.fieldLabel !== ACTIVE && (
            <>
              {isReadOnly && (
                <div className="lock-container">
                  <FontAwesomeIcon
                    icon={faLockKeyhole}
                    className={isFieldHidden ? 'fa-disabled' : 'fa-enabled'}
                    onMouseEnter={() => infoRef.current.showTextBox()}
                    onMouseLeave={() => infoRef.current.hideTextBox()}
                  />

                  <div
                    className="hidden-block"
                    onMouseEnter={() => infoRef.current.showTextBox()}
                    onMouseLeave={() => infoRef.current.hideTextBox()}
                  />
                </div>
              )}

              <InfoTextBox ref={infoRef} isInfoHoverable width="26.375rem" top="1rem" left="-3.188rem">
                Read Only. Cannot be edited in Stagebase.{' '}
                <span className="tooltip-action" onClick={handleLockChange}>
                  Change
                </span>
              </InfoTextBox>

              <div className="icon-container">
                <div className="eye-container">
                  <FontAwesomeIcon
                    icon={isFieldHidden ? faEyeSlash : faEye}
                    onClick={handleFieldVisibility}
                    onMouseEnter={() => visibilityRef.current.showTextBox()}
                    onMouseLeave={() => visibilityRef.current.hideTextBox()}
                    className="fa-enabled"
                  />

                  <div
                    className="hidden-block"
                    onMouseEnter={() => visibilityRef.current.showTextBox()}
                    onMouseLeave={() => visibilityRef.current.hideTextBox()}
                  />
                </div>

                <InfoTextBox
                  ref={visibilityRef}
                  isInfoHoverable
                  width={dynamicWidth}
                  top="1.25rem"
                  left="-3.188rem"
                >
                  {isFieldHidden ? (
                    <>
                      <span className="tooltip-action" onClick={handleFieldVisibility}>
                        Show
                      </span>{' '}
                      this field.
                    </>
                  ) : (
                    <>
                      <span className="tooltip-action" onClick={handleFieldVisibility}>
                        Hide
                      </span>{' '}
                      this field. When hidden, it is still active and can be used for things like mergetags
                      and automation.
                    </>
                  )}
                </InfoTextBox>

                <FontAwesomeIcon
                  icon={faPen}
                  onClick={handleEdit}
                  className={isFieldHidden ? 'fa-disabled' : 'fa-enabled'}
                />

                {!field?.isProtected && (
                  <FontAwesomeIcon icon={faTrash} onClick={toggleDeleteModal(true)} className="fa-enabled" />
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="custom-field__input custom-detail-fields">{renderField()}</div>

      <FormikContext.Provider value={formikBag}>
        <FieldModal
          show={!!showAddFieldModal}
          variation={CUSTOM_FIELDS_MODAL_VARIATIONS.PROFILE}
          onHide={handleHide}
          queryFieldId={queryFieldId}
        />
      </FormikContext.Provider>

      <ModalConfirm
        title="Delete field"
        message={renderDeleteMessage()}
        show={!!showDeleteModal}
        innerProps={modalStyle.inner}
        buttonsContainerProps={modalStyle.buttonContainer}
        autoButtonsWidth
        onConfirm={handleDelete}
        onHide={toggleDeleteModal(false)}
      />
    </div>
  );
};

export default React.memo(EditableField);
