import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function TableRow({ to, children, small, className, onClick, noBorder }) {
  const useLink = !!to;
  const inner = (
    <div className={'table-row' + (small ? ' smaller' : '') + (noBorder ? ' no-border' : '')}>
      <Row>{children}</Row>
    </div>
  );
  let classes = 'table-row-outer text-break' + (className ? ' ' + className : '');
  if (!useLink && !onClick) {
    classes += ' no-hover';
  }
  return (
    <>
      {useLink ? (
        <Link className={classes} to={to} onClick={onClick}>
          {inner}
        </Link>
      ) : (
        <div className={classes} onClick={onClick}>
          {inner}
        </div>
      )}
    </>
  );
}
