import React, { useCallback, useRef, useState, useMemo, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import { faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PERMISSIONS, DEFAULT_FIELDS, FIELD_TYPES, OBJECT_TYPE_ENUM, ACTIVE, ADMIN } from 'constants';
import { Customers, Settings } from 'models';
import { useParser } from 'views/Customers/hooks';
import ClientType from 'components/Modals/Customers/ClientType';
import InfoTextBox from 'components/Modals/Shared/InfoTextBox';
import { debounce } from 'helpers/CommonHelper';
import ModalConfirm from 'components/Modals/Confirm';
import { useEditableField } from '../hooks';
import { useContext } from 'react';
import FullContext from 'stores/Full/fullContext';
import ModalLargePadding, { ModalLargePaddingConfirmButton } from 'components/Modals/LargePadding';

const DetailField = ({ field, name, variation, setSaving, isDropDownLastItem, setHasError }) => {
  const { values, setFieldValue } = useFormikContext();
  const { handleParseRequestProperty, handleParseRequestValues } = useParser();

  const { roleAtleast } = useContext(FullContext);

  const [showModal, setShowModal] = useState(false);
  const [showOwnerModal, setShowOwnerModal] = useState(false);
  const [changeOwner, setChangeOwner] = useState(null);
  const [internalValue, setInternalValue] = useState(null);

  useEffect(() => {
    if (field?.formikValue) setInternalValue(field?.formikValue);
  }, [field?.formikValue]);

  const infoRef = useRef(null);
  const fieldRef = useRef(null);

  const customerId = values?.customerId ?? null;
  const userId = values?.userId ?? null;

  const defaultFields = {
    [DEFAULT_FIELDS.OWNER]: DEFAULT_FIELDS.OWNER,
    [DEFAULT_FIELDS.TYPE]: DEFAULT_FIELDS.TYPE,
  };

  const menuFooterLink =
    variation === OBJECT_TYPE_ENUM.CUSTOMER &&
    roleAtleast(ADMIN) &&
    !defaultFields[field?.fieldLabel] &&
    field?.externalDataField?.isCustomField
      ? `/accounts/custom-fields/edit-account-fields?field=${field.objectMapId}`
      : '';

  const isReadOnly = field?.syncOption === PERMISSIONS.READ_ONLY;

  const handleUpdate = async (value) => {
    const isInvalidDateTime = field?.fieldType === FIELD_TYPES.DATE_AND_TIME && (!value.date || !value.time);

    if (!value && field?.isRequired) return;
    if (isInvalidDateTime) return;

    try {
      setSaving(true);
      setHasError(false);

      const request = {
        field: handleParseRequestProperty(field),
        value: handleParseRequestValues(field, value),
      };

      switch (variation) {
        case OBJECT_TYPE_ENUM.PROFILE:
          await Settings.updateProfile(userId, request);
          setSaving(false);
          break;

        default:
          await Customers.updateCustomer(customerId, request);
          setSaving(false);
          break;
      }
    } catch (error) {
      setSaving(false);
      setHasError(true);
    }
  };

  const handleChange = useRef(debounce(async (value) => await handleUpdate(value), 500));

  const handleUpdateOwner = debounce(async (value, isRemoved) => {
    if (
      (typeof changeOwner !== 'object' || typeof changeOwner !== 'string') &&
      !showOwnerModal &&
      internalValue?.label !== value?.label
    ) {
      setShowOwnerModal(true);
      setChangeOwner(value);
      return;
    }

    try {
      setSaving(true);
      setHasError(false);

      const request = {
        field: handleParseRequestProperty(field),
        value: handleParseRequestValues(field, changeOwner),
      };

      await Customers.updateCustomer(customerId, request, isRemoved);
      setSaving(false);
      setChangeOwner(null);
    } catch (error) {
      setSaving(false);
      setHasError(true);
    }
  }, 500);

  const handleBlur = useCallback(() => {
    if (!field?.formikValue && field?.isRequired) setShowModal(true);
  }, [field?.formikValue, field?.isRequired]);

  const handleClear = async () => {
    if (field?.isRequired) return;

    await handleUpdate('');
  };

  const handleClearOwner = async () => {
    if (field?.isRequired) return;

    await handleUpdateOwner('', undefined);
  };

  const isCustomerOwner =
    field?.fieldLabel === DEFAULT_FIELDS.OWNER && variation === OBJECT_TYPE_ENUM.CUSTOMER;

  const { renderField } = useEditableField({
    field,
    name,
    menuFooterLink,
    disabled: isReadOnly,
    onChange: isCustomerOwner ? (value) => handleUpdateOwner(value, undefined) : handleChange.current,
    onClear: isCustomerOwner ? handleClearOwner : handleClear,
    onBlur: handleBlur,
    isModal: false,
    fieldRef,
    isDropDownLastItem,
  });

  const renderModalMessage = () => (
    <p>
      Please fill in a value for <b>{field?.fieldLabel}</b>.
    </p>
  );

  const handleModalClose = useCallback(() => {
    setFieldValue(name, internalValue);

    setShowModal(false);

    setTimeout(() => {
      !internalValue && fieldRef.current && fieldRef.current.focus();
    }, 500);
  }, [internalValue, name, fieldRef, setFieldValue]);

  const modalStyle = {
    inner: {
      maxWidth: '33.563rem',
      padding: '2.5rem 1.25rem !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  const renderFormField = useMemo(() => {
    return renderField();
  }, [renderField]);

  const isDateTime = {
    [FIELD_TYPES.DATE]: FIELD_TYPES.DATE,
    [FIELD_TYPES.TIME]: FIELD_TYPES.TIME,
    [FIELD_TYPES.DATE_AND_TIME]: FIELD_TYPES.DATE_AND_TIME,
    [FIELD_TYPES.DATE_TIME]: FIELD_TYPES.DATE_TIME,
  };

  const handleRemoveOldOwner = (remove) => {
    if (typeof changeOwner === 'object' || typeof changeOwner === 'string') {
      setShowOwnerModal(false);
      handleUpdateOwner(changeOwner, remove);
    }
  };

  return (
    <div
      className={`custom-field ${isReadOnly ? 'read-only' : ''} ${
        isDateTime[field?.fieldType] ? 'date-time' : ''
      }`}
    >
      <div className="custom-field__header">
        <Form.Label>{field?.fieldLabel}</Form.Label>

        {isReadOnly && field?.fieldLabel !== ACTIVE && (
          <FontAwesomeIcon
            icon={faLockKeyhole}
            className="fa-sm"
            onMouseEnter={() => infoRef.current.showTextBox()}
            onMouseLeave={() => infoRef.current.hideTextBox()}
          />
        )}

        <InfoTextBox ref={infoRef} width="22.375rem" top="1.25rem" left="-3.188rem">
          Read Only. Cannot be edited in Stagebase.
        </InfoTextBox>
      </div>

      <div className="custom-field__input user">
        {field?.fieldLabel === DEFAULT_FIELDS.TYPE && field?.isProtected ? (
          <ClientType
            field={field}
            name={name}
            handleChange={handleChange.current}
            handleClear={handleClear}
            handleBlur={handleBlur}
            disabled={isReadOnly}
            isModal={false}
          />
        ) : (
          renderFormField
        )}
      </div>

      <ModalConfirm
        title="Required field"
        message={renderModalMessage()}
        show={showModal}
        hideCancel
        confirmText="Close"
        variant="primary"
        innerProps={modalStyle.inner}
        buttonsContainerProps={modalStyle.buttonContainer}
        autoButtonsWidth
        onConfirm={handleModalClose}
      />

      <ModalLargePadding
        show={showOwnerModal}
        content={
          <div className="text-center">
            <h1 className="mb-3">Remove user from all steps?</h1>
            <div className="mb-0">
              You are changing the account owner. Do you want to remove the previous user from all steps as
              well?
            </div>
          </div>
        }
        buttons={
          <>
            <ModalLargePaddingConfirmButton
              onClick={() => handleRemoveOldOwner(false)}
              variant="secondary"
              text="No, keep"
            />
            <ModalLargePaddingConfirmButton
              onClick={() => handleRemoveOldOwner(true)}
              variant="danger"
              text="Yes, remove"
            />
          </>
        }
      />
    </div>
  );
};

export default React.memo(DetailField);
