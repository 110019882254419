import { EMAIL_CONFIG_FIELDS, EMAIL_CONFIG_LABELS } from "constants";

export const emailPreferences = [
  {
    fieldName: EMAIL_CONFIG_FIELDS.PROJECT_UPDATE,
    label: EMAIL_CONFIG_LABELS.PROJECT_UPDATES,
    },
  {
    fieldName: EMAIL_CONFIG_FIELDS.PASTDUE,
    label: EMAIL_CONFIG_LABELS.PASTDUES,
  },
  {
    fieldName: EMAIL_CONFIG_FIELDS.ASSIGNED,
    label: EMAIL_CONFIG_LABELS.ASSIGNED,
  },
  {
    fieldName: EMAIL_CONFIG_FIELDS.COMMENT,
    label: EMAIL_CONFIG_LABELS.COMMENTS,
  },
  {
    fieldName: EMAIL_CONFIG_FIELDS.UPLOAD,
    label: EMAIL_CONFIG_LABELS.UPLOAD,
  },
]