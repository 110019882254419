import { useState } from 'react';

import { Modal, Button, Col } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';

import CanvasItem from '../../CanvasItem';
import LoadingInline from '../../Loading/LoadingInline';
import TableRow from 'components/TableRow';
import styled from 'styled-components';

const Container = styled.div`
  & > div > div {
    padding: 0rem;

    @media screen and (max-width: 426px) {
      & > div {
        gap: 0.75rem;
      }
    }
  }
`;

function EditCanvasStartDateModal({ customerCanvas, onHide, onSave }) {
  const [saving, setSaving] = useState(false);
  const [date, setDate] = useState(null);

  const handleSave = async () => {
    setSaving(true);
    try {
      await onSave(customerCanvas.id, customerCanvas.canvasId, customerCanvas.customerId, date);
      onHide();
    } catch (err) {
      console.log(err);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal show={customerCanvas} onHide={onHide}>
      <Modal.Header>
        <h1>Edit start date</h1>
        <div>
          <Button variant="outline-light" onClick={onHide} className="mr-2-5">
            Cancel
          </Button>
          <Button disabled={saving || !date} variant="primary" onClick={handleSave}>
            {saving ? <LoadingInline white text="Saving..." /> : 'Save'}
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <TableRow noBorder>
            <Col xs={12} sm={6}>
              <CanvasItem canvas={customerCanvas} />
            </Col>
            <Col xs={12} sm={6} className="d-md-flex align-items-center justify-content-end">
              <div className="mr-3 text-muted font-weight-bold">Start</div>
              <div className="date-picker">
                <Flatpickr
                  className="form-control reminder-date-input"
                  options={{ altInput: true, static: true, mode: 'single', disableMobile: 'false' }}
                  value={customerCanvas?.startDate}
                  onChange={(dates) => {
                    setDate(dates?.[0]);
                  }}
                ></Flatpickr>
                <FontAwesomeIcon icon={faCalendar} />
              </div>
            </Col>
          </TableRow>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default EditCanvasStartDateModal;
