import { useCallback, useEffect, useState } from 'react';
import { Button, Nav, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { HeaderTitle, Container, SubjectInput } from '../styles';
import { Settings } from 'models';
import EmailEditor from 'components/EmailEditor';
import { TabSelector } from 'views/CustomFields/Account/Styles';
import { EDITOR_LABELS } from 'constants';
import ModalConfirm from 'components/Modals/Confirm';
import LoadingSpinner from 'components/Loading/LoadingSpinner';

const EmailTemplate = ({ id, onHide, isViewMode, title, isTrialMode, handleLoadBillingTab }) => {
  const history = useNavigate();
  const [body, setBody] = useState(null);

  const [initial, setInitial] = useState({
    subject: '',
    content: '',
  });
  const [previewData, setPreviewData] = useState({
    subject: '',
    content: '',
  });
  const [isPreviewMode, setIsPreviewMode] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [canRevert, setCanRevert] = useState(false);
  const [editorTab, setEditorTab] = useState(EDITOR_LABELS.PREVIEW);
  const [revertTemplate, setRevertTemplate] = useState(null);
  const [trialModal, setTrialModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [subjectLine, setSubjectLine] = useState(initial?.subject || '');

  /**
   * Callback that sets the preview content of the email
   */
  const getPreviewTemplate = useCallback(
    async (subject, content) => {
      const preview = await Settings.previewEmailTemplateById(id, subject ?? subjectLine, content ?? body);
      setPreviewData({
        subject: preview?.subject,
        content: preview?.content,
      });
      setLoading(false);
    },
    [id, body, subjectLine]
  );

  /**
   * Callback used to retrieve the default HTML
   * NOTE: ignoring lint issue for getPreviewTemplate as it causes an infinite loop
   * getPreviewTemplate will only be triggered once upon opening the modal
   * and conditionally when clicking the preview tab.
   */
  const getTemplate = useCallback(async () => {
    if (!!id) {
      const _template = await Settings.getEmailTemplateById(id);
      if (!isViewMode) {
        const _revertTemplate = await Settings.revertEmailTemplateById(id);
        setRevertTemplate(_revertTemplate);
      }
      setSubjectLine(_template?.subject);
      setBody(_template?.content);
      setInitial({
        subject: _template?.subject || '',
        content: _template?.content || '',
      });
      getPreviewTemplate(_template?.subject, _template?.content);
      setIsPreviewMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!!id) {
      setLoading(true);
      getTemplate();
    }
  }, [id, getTemplate]);

  useEffect(() => {
    const formatHtmlString = (str) => str?.replace(/(\r\n|\n|\r)/gm, '');

    const formattedBody = formatHtmlString(body);
    const formattedInitialBody = formatHtmlString(initial?.content);
    const formattedRevertBody = formatHtmlString(revertTemplate?.content);
    const formattedSubject = formatHtmlString(subjectLine);
    const formattedInitialSubject = formatHtmlString(initial?.subject);
    const formattedRevertSubject = formatHtmlString(revertTemplate?.subject);

    setHasChanges(formattedBody !== formattedInitialBody || formattedSubject !== formattedInitialSubject);
    setCanRevert(formattedBody !== formattedRevertBody || formattedSubject !== formattedRevertSubject);
  }, [body, subjectLine, initial, revertTemplate]);

  /**
   * Click event handler that reverts back the original template
   * this would affect both subject and content
   */
  const onRevertTemplate = async () => {
    const template = await Settings.revertEmailTemplateById(id);
    setSubjectLine(template?.subject);
    setBody(template?.content);
    if (isPreviewMode) {
      getPreviewTemplate(template?.subject, template?.content);
    }
  };

  /**
   * Click event handler that saves template
   * this would affect both subject and content
   */
  const onSaveTemplate = async () => {
    await Settings.editEmailTemplateById(id, subjectLine, body);
    setHasChanges(false);
    setInitial({
      subject: subjectLine,
      content: body,
    });
  };

  /**
   * Click event handler that previews the template
   */
  const onPreviewTemplate = async () => {
    const template = await Settings.previewEmailTemplateById(id, subjectLine, body);
    setPreviewData({
      subject: template?.subject,
      content: template?.content,
    });
    setEditorTab(EDITOR_LABELS.PREVIEW);
    setIsPreviewMode(true);
  };

  /**
   * Click event handler that edits the template
   */
  const onEditMode = async () => {
    setEditorTab(EDITOR_LABELS.EDIT);
    setIsPreviewMode(false);
    setPreviewData({
      subject: null,
      content: null,
    });
  };

  const trialProps = {
    title: 'Upgrade to Access',
    confirmText: 'Manage Subscription',
    variant: 'primary',
    messageAlign: 'left',
    innerProps: {
      maxWidth: '520px',
      padding: '70px 120px 40px',
    },
    buttonsContainerProps: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  return (
    <Container>
      <ModalConfirm
        show={trialModal}
        message={
          <>
            <p>These email templates can be edited to match your brand and objectives.</p>
            <p>Upgrade your subscription to access this feature.</p>
          </>
        }
        autoButtonsWidth
        onConfirm={() => {
          onHide();
          setTrialModal(false);
          history('/settings#billing');
          handleLoadBillingTab();
        }}
        onHide={() => setTrialModal(false)}
        {...trialProps}
      />
      <div className="d-flex mt-2">
        <HeaderTitle>{title}</HeaderTitle>
        {!isViewMode ? (
          <div className="mb-4">
            <Button
              disabled={!canRevert}
              className="border font-weight-normal mr-3"
              variant="white"
              onClick={onRevertTemplate}
            >
              Revert to Original
            </Button>
            <Button className="border font-weight-normal mr-3" variant="white" onClick={onHide}>
              Cancel
            </Button>
            <Button
              className="font-weight-bold"
              variant="primary"
              onClick={onSaveTemplate}
              disabled={!hasChanges}
            >
              Save
            </Button>
          </div>
        ) : (
          <div className="mb-4">
            <Button className="font-weight-bold" variant="primary" onClick={onHide}>
              Done
            </Button>
          </div>
        )}
      </div>

      <Tab.Container defaultActiveKey={EDITOR_LABELS.PREVIEW} activeKey={editorTab}>
        <Nav variant="tabs">
          <TabSelector>
            <Nav.Item>
              <Nav.Link eventKey={EDITOR_LABELS.PREVIEW} onClick={onPreviewTemplate}>
                Preview
              </Nav.Link>
            </Nav.Item>
            {!isViewMode && (
              <Nav.Item>
                <Nav.Link
                  eventKey={EDITOR_LABELS.EDIT}
                  onClick={() => {
                    isTrialMode ? setTrialModal(true) : onEditMode();
                  }}
                >
                  Edit
                </Nav.Link>
              </Nav.Item>
            )}
          </TabSelector>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey={EDITOR_LABELS.PREVIEW}>
            {loading ? (
              <div className="text-center my-4">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                <div className="my-3 d-flex align-items-center">
                  <h2>Subject</h2>
                  <SubjectInput
                    className="ml-3 w-100"
                    placeholder="Enter subject name..."
                    value={isPreviewMode ? previewData.subject : subjectLine || ''}
                    onChange={(e) => setSubjectLine(e.target.value)}
                    disabled
                  />
                </div>
                <div className="border" dangerouslySetInnerHTML={{ __html: previewData.content }}></div>
              </>
            )}
          </Tab.Pane>
          <Tab.Pane eventKey={EDITOR_LABELS.EDIT}>
            <div className="my-3 d-flex align-items-center">
              <h2>Subject</h2>
              <SubjectInput
                className="ml-3 w-100"
                placeholder="Enter subject name..."
                value={isPreviewMode ? previewData.subject : subjectLine || ''}
                onChange={(e) => setSubjectLine(e.target.value)}
              />
            </div>
            <EmailEditor body={body} setBody={setBody} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Container>
  );
};

export default EmailTemplate;
