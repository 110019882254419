export const previewData = {
  ageDays: 0,
  canvasId: 1,
  canvasName: 'Test Test',
  canvasTags: null,
  canvasTemplateName: 'Customer Onboarding & Training',
  color: 'scope-sky',
  currentJourneyId: 20004,
  currentJourneyName: '',
  currentTouchpointId: 20019,
  currentTouchpointName: 'Step (1)',
  customerId: 0,
  dynamicTimeline: false,
  endDate: '2023-01-20T14:14:08.874Z',
  externalsHaveTaskItemPermissions: false,
  id: 20003,
  order: 3,
  progress: 7,
  projectedDate: '2023-03-10T10:00:07.036Z',
  startDate: '2023-01-19T14:14:07.755Z',
  status: 'Past Due',
  autoadvance: false,
  emailFrequencyUnit: 'Weeks',
  emailFrequencyValue: 1,
  sendEmail: true,
  sendPastDueEmail: false,
  emailSettings: {
    isViewOnly: false,
    projectUpdate: {
      to: 'External project members',
      frequencyUnit: 'Weeks',
      frequencyValue: 1,
      isEnabled: true,
    },
    pastDueStep: {
      for: 'Customer-facing steps',
      to: 'External step members',
      isEnabled: false,
    },
    invitedToProject: {
      to: 'All project members',
      isEnabled: true,
    },
    assignedToStepOrTask: {
      to: 'All project members',
      isEnabled: true,
    },
    comments: {
      to: 'Project owner',
      isEnabled: true,
    },
  },
  externalId: '35a2f712-f82f-45f9-96da-a36635aa735e',
  LastActivity: null,
  Braves: 5562,
  Testing: 'Test',
  Careless: '2023-03-19T19:00:00.000Z',
  BreakItDowns: true,
  projectName: null,
};
