import React from 'react';
import { FORMIK_FIELD_TYPES } from 'constants';
import Input from './Input';
import InputGroup from './InputGroup';

const FormInput = ({
  name,
  inputType = FORMIK_FIELD_TYPES.DEFAULT,
  handleChange,
  handleBlur,
  prepend,
  disabled,
  fieldLabel,
  isRequired,
  fieldRef,
  ...props
}) => {
  switch (inputType) {
    case FORMIK_FIELD_TYPES.INPUT_GROUP:
      return (
        <InputGroup
          ref={fieldRef}
          name={name}
          prepend={prepend}
          handleChange={handleChange}
          handleBlur={handleBlur}
          disabled={disabled}
          fieldLabel={fieldLabel}
          isRequired={isRequired}
          {...props}
        />
      );

    default:
      return (
        <Input
          ref={fieldRef}
          name={name}
          handleChange={handleChange}
          handleBlur={handleBlur}
          disabled={disabled}
          fieldLabel={fieldLabel}
          isRequired={isRequired}
          {...props}
        />
      );
  }
};

export default React.memo(FormInput);
