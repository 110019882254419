import { useContext, useState } from 'react';
import IdeasTable from './reporting/IdeasTable';
import StatusTable from './reporting/StatusTable';
import LostAccountsTable from './reporting/LostAccountsTable';
import PhasePerformanceTable from './reporting/PhasePerformanceTable';
import StepPerformanceTable from './reporting/StepPerformanceTable';
import TemplatePerformanceTable from './reporting/TemplatePerformanceTable';
import StatusOfProjects from './reporting/StatusOfProjects';
import TeamWorkload from './reporting/TeamWorkload';
import AccountsAndLoss from './reporting/AccountsAndLoss';
import RevenueAndLoss from './reporting/RevenueAndLoss';
import styled from 'styled-components';
import TimeRangeFilter from './reporting/TimeRangeFilter';
import AccountFilter from './reporting/AccountFilter';
import ActiveTemplateDropdown from './reporting/ActiveTemplateDropdown';
import { Link } from 'react-router-dom';
import ReportingContext from './reporting/context/reportingContext';

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 23px 20px;
  margin: 0 -23px;
  background: #fff;
  box-shadow: 0px 2px 5px 0px #61616133;
  position: sticky;
  top: 0;
  z-index: 2;
  .title {
    font-size: 24px;
    margin-right: auto;
    display: flex;
    align-items: center;
  }
`;
const FlexSpan = styled.span`
  display: flex;
  @media only screen and (max-width: 480px) {
    display: block;
  }
`;
const ActiveTemplateSelection = styled.div`
  display: flex;
  align-items: baseline;
  & > a {
    margin-left: 20px;
  }
`;

const ReponsiveWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 800px) {
    display: block;
  }
`;

const Reporting = () => {
  const { activeTemplate } = useContext(ReportingContext);
  const [showLineGraph, setShowLineGraph] = useState(true);
  const [showProjectsGraph, setShowProjectsGraph] = useState(true);
  const [showTemplateTable, setShowTemplateTable] = useState(true);
  return (
    <>
      <Header>
        <span className="title">Reporting</span>
        <TimeRangeFilter />
        <AccountFilter />
      </Header>
      <div className="mb-4">
        <FlexSpan>
          <RevenueAndLoss showTable={showLineGraph} setShowTable={() => setShowLineGraph((prev) => !prev)} />
          <AccountsAndLoss showTable={showLineGraph} setShowTable={() => setShowLineGraph((prev) => !prev)} />
        </FlexSpan>
        <LostAccountsTable />
        <TemplatePerformanceTable />
        <ActiveTemplateSelection>
          <ActiveTemplateDropdown />
          {activeTemplate && <Link to={`/templates/${activeTemplate}`}>Go to template</Link>}
        </ActiveTemplateSelection>
        <ReponsiveWrapper>
          <PhasePerformanceTable
            showTable={showTemplateTable}
            setShowTable={() => setShowTemplateTable((prev) => !prev)}
          />
          <StepPerformanceTable
            showTable={showTemplateTable}
            setShowTable={() => setShowTemplateTable((prev) => !prev)}
          />
        </ReponsiveWrapper>
        <FlexSpan>
          <StatusOfProjects
            showTable={showProjectsGraph}
            setShowTable={() => setShowProjectsGraph((prev) => !prev)}
          />
          <TeamWorkload
            showTable={showProjectsGraph}
            setShowTable={() => setShowProjectsGraph((prev) => !prev)}
          />
        </FlexSpan>
        <StatusTable />
        <IdeasTable />
      </div>
    </>
  );
};

export default Reporting;
