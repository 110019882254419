import styled from 'styled-components';

export const SubscriptionContainer = styled.div`
  padding: 2rem 3.781rem 2.438rem 3.781rem;

  p {
    margin-bottom: 0;
  }

  .form-content {
    margin-top: 4.188rem;
  }

  .field-wrapper {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    margin-top: 0.625rem;

    input {
      max-width: 16.25rem;
      text-align: right;
    }
  }

  .error-message-wrapper {
    color: var(--danger);
  }

  .info {
    margin-top: 2rem;
  }
`;
