import * as Api from '../../../api/Api';
import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from '../../toast';
import Tags from '../../Tags';
import ModalConfirm from '../Confirm/index';
import PlusButton from '../../PlusButton';
import { COLORS_HEX } from 'helpers/CommonHelper';
import LoadingInline from '../../Loading/LoadingInline';
import styled from 'styled-components';
import { EmptyTagsContainer } from '../Touchpoint/AddMembers/Styles';
import InfoTextBox from '../Shared/InfoTextBox';

const StyledTag = styled.div`
  background: ${({ background }) => background};
`;

const colors = [
  COLORS_HEX.BLUE,
  COLORS_HEX.PURPLE,
  COLORS_HEX.GREEN,
  COLORS_HEX.RED,
  COLORS_HEX.ORANGE,
  COLORS_HEX.YELLOW,
];

function TagBuilder({ tag, onCancel, onSave, onDelete }) {
  const [text, setText] = useState(tag.text);
  const [color, setColor] = useState(tag.color);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setText(tag.text);
    setColor(tag.color);
  }, [tag]);

  const handleSaveTag = async () => {
    if (text === '') {
      toast.error('Tag must have text');
    } else if (color === null) {
      toast.error('Tag must have color');
    } else {
      setSaving(true);
      await onSave({ ...tag, text, color });
      setSaving(false);
    }
  };

  return (
    <div className="mt-3 tag-builder">
      <div className="d-flex align-items-center mb-2">
        <Form.Control
          className="tag-edit-input"
          placeholder={tag.id ? `Edit global tag "${tag.text}"` : 'Add global tag...'}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </div>
      <div className="d-flex mb-2 justify-content-between">
        {colors.map((c, index) => (
          <StyledTag onClick={() => setColor(c)} key={index} className="tag-selection-color" background={c}>
            {c === color ? <FontAwesomeIcon icon={faCheck} /> : null}
          </StyledTag>
        ))}
      </div>
      <div className="buttons">
        <Button onClick={onCancel} className="mb-2" variant="outline-light">
          Cancel
        </Button>
        {tag.id && (
          <Button onClick={onDelete} className="mb-2" variant="danger">
            Delete
          </Button>
        )}
        <Button disabled={saving} onClick={handleSaveTag} className="mb-2" variant="primary">
          {saving ? <LoadingInline white text="Saving..." /> : 'Save'}
        </Button>
      </div>
    </div>
  );
}

export default function EditTags({
  touchpointId,
  touchpointTags = [],
  onAdded,
  onEdited,
  onDeleted, // Destroy global tag
  onRemoved, // Remove touchpoint tag
}) {
  const [tagForDelete, setTagForDelete] = useState(null);
  const [filteredTags, setFilteredTags] = useState([]);
  const [globalTags, setGlobalTags] = useState([]);
  const [addingTouchpointTags, setAddingTouchpointTags] = useState(false);
  const [focusTag, setFocusTag] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await Api.get('Touchpoint/GetTags');
      setGlobalTags(response.items);
    })();
  }, []);

  useEffect(() => {
    setFilteredTags(touchpointTags.filter((x) => !x.isArchived && (x.tag == null || !x.tag.isArchived)));
  }, [touchpointTags]);

  async function createTouchpointTagFromTag(tag) {
    if (!touchpointTags.find((t) => t.tagId === tag.id)) {
      var response = await Api.post(
        `Touchpoint/CreateTouchpointTagFromTag?touchpointId=${touchpointId}&tagId=${tag.id}`,
        null,
        { rawResponse: true }
      );
      const data = await response.json();
      if (response.status === 400) {
        toast.error(data);
      } else {
        var touchpointTag = {
          id: data.id,
          tagId: data.id,
          text: data.text,
          color: data.color,
          isArchived: false,
          tag: { isArchived: false },
        };
        onAdded(touchpointTag);
      }
      setAddingTouchpointTags(false);
      setFocusTag(null);
    }
  }

  async function confirmDeleteGlobalTag() {
    await Api.post(`Touchpoint/RemoveTag`, tagForDelete.id);
    setGlobalTags(globalTags.filter((item) => item.id !== tagForDelete.id));
    onDeleted(focusTag.id);
    setFocusTag(null);
    setTagForDelete(null);
    setAddingTouchpointTags(false);
  }

  async function deleteTouchpointTag(tag) {
    await Api.post(`Touchpoint/RemoveTag?touchpointId=${touchpointId}`, tag.tagId);
    onRemoved(tag.id, touchpointId);
  }

  const handleCreatingGlobalTag = () => {
    setFocusTag({
      color: null,
      text: '',
    });
  };

  const handleEditingGlobalTag = (tag) => {
    setFocusTag({
      ...tag,
    });
  };

  const handleSaveTag = async (tag) => {
    const hasExistingTag = globalTags.some(
      ({ text }) => String(text).toLowerCase().trim() === String(tag.text).toLowerCase().trim()
    );

    if (hasExistingTag) {
      toast.error(`A tag with the name of "${tag.text}" already exists`);
      return;
    }

    if (tag.id) {
      await Api.post(`Touchpoint/UpdateTag?touchpointId=${touchpointId}&tagId=${tag.id}`, {
        text: tag.text,
        color: tag.color,
      });
      setGlobalTags(globalTags.map((t) => (t.id === tag.id ? tag : t)));
      onEdited(tag);
    } else {
      const res = await Api.post(`Touchpoint/CreateTag?touchpointId=${touchpointId}`, {
        text: tag.text,
        color: tag.color,
      });
      setGlobalTags([...globalTags, res]);
      onAdded({
        id: res.id,
        tagId: res.tagId,
        archived: false,
        text: res.text,
        color: res.color,
        tag: res,
      });
    }
    setFocusTag(null);
    setAddingTouchpointTags(false);
  };

  return (
    <>
      <div className="mb-4">
        <div className="row">
          <div className="col-12">
            <h2 className="title mb-1-5">Tags</h2>
            <div className="d-flex">
              {filteredTags.length > 0 ? (
                <>
                  <Tags
                    tags={filteredTags}
                    large={true}
                    onDelete={deleteTouchpointTag}
                    activeGlobalTagId={focusTag?.id ?? undefined}
                  />

                  {!addingTouchpointTags && (
                    <PlusButton onClick={() => setAddingTouchpointTags(true)} icon={faPencil} />
                  )}
                </>
              ) : (
                <>
                  {!addingTouchpointTags && (
                    <PlusButton onClick={() => setAddingTouchpointTags(true)} icon={faPencil} />
                  )}

                  <div className="tag large d-flex align-items-center empty-tag ml-2">
                    <EmptyTagsContainer>
                      <span>Add tags to speed up assigning users</span>
                      <InfoTextBox notActive={false} width="30.625rem" top="1.825rem" left="-1rem">
                        Add tags like “Customer” or “CSM”. When adding users to the project, you can choose
                        specific tags to assign that user to those steps.
                      </InfoTextBox>
                    </EmptyTagsContainer>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-12 mt-3">
            {addingTouchpointTags && (
              <>
                <h2 className="title mb-1-5">Global Tags</h2>
                <div className="d-flex">
                  {globalTags.length ? (
                    <Tags
                      tags={globalTags}
                      large={true}
                      onClick={createTouchpointTagFromTag}
                      onEdit={handleEditingGlobalTag}
                      activeId={focusTag?.id ?? undefined}
                    />
                  ) : (
                    <div className="tag large d-flex align-items-center empty-tag mr-2">
                      There are no global tags. Click the plus to create one.
                    </div>
                  )}
                  {focusTag == null && <PlusButton onClick={handleCreatingGlobalTag} />}
                </div>
              </>
            )}
          </div>
        </div>
        {focusTag && (
          <TagBuilder
            tag={focusTag}
            onCancel={() => setFocusTag(null)}
            onDelete={() => setTagForDelete(focusTag)}
            onSave={handleSaveTag}
          />
        )}
      </div>
      <ModalConfirm
        title="Delete tag"
        message={
          <>
            Are you sure you want to delete {tagForDelete && tagForDelete.text}?
            <br />
            This action cannot be undone.
          </>
        }
        show={tagForDelete !== null}
        onConfirm={() => {
          confirmDeleteGlobalTag();
        }}
        onHide={() => {
          setTagForDelete(null);
        }}
      />
    </>
  );
}
