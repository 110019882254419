import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const DropDown = styled.button`
  width: ${props => props.width};
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  background: #f6f7f9;
  border: none;
  border-radius: 5px;
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  outline: none;
  position: relative;
  opacity: ${props => props.notActive ? '0.5' : '1'};
`

export const Wrapper = styled.div`
  position: absolute;
  top: 15px;
  left: ${props => props.left};
  width: ${props => props.width};
  max-height: 380px;
  overflow-y: auto;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 4px rgba(0,0,0,.25);
  padding: 8px 10px;
  z-index: 1;
`

export const InlineText = styled.span`
  opacity: ${props => props.notActive ? '0.5' : '1'};
`

export const Entry = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 25px;
  padding-left: 25px;
  &:hover {
    cursor: pointer;
    background-color: rgb(0, 0, 0, 0.1);
  }
`

export const ChevronArrow = styled(FontAwesomeIcon)`
  color: #008be7;
  position: absolute;
  right: ${props => props.right ? props.right : '16px'};
  top: 12px;
`

export const CheckMark = styled(FontAwesomeIcon)`
  position: absolute;
  left: 10px;
  top: ${props => props.top};
`

export const DateHeader = styled.span`
  color: var(--action-anchor);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 20px;
  cursor: ${props => props.hasEditConditions ? 'pointer' : ''};
  .separator {
    margin: 0 5px;
  }
  .scheduleText {
    color: ${props => props.isExternal ? 'var(--action-anchor)' : 'var(--purple-bright)'};
  }
`