import React, { useState } from 'react';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import TableRow from './TableRow';

const RowDetailsCol = styled(Col)`
  padding: 0 0 2rem 0;
  font-size: 14px;
`;

const ExpandableTableRow = ({ children, renderDetails = () => {} }) => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <div>
      <TableRow>{children({ isExpanded, setExpanded })}</TableRow>
      {isExpanded && <RowDetailsCol md={12}>{renderDetails()}</RowDetailsCol>}
    </div>
  );
};

export default ExpandableTableRow;
