import MoreInfoModal from 'components/Modals/Touchpoint/MoreInformation';
import { FormikContext } from 'formik';
import React from 'react';
import { useCustomerCanvas } from './hooks/useCustomerCanvas';

const MoreInfoContainer = ({ show, onHide, canvas }) => {
  const { formikBag } = useCustomerCanvas(canvas);

  return (
    <FormikContext.Provider value={formikBag}>
      <MoreInfoModal show={show} onHide={onHide} />
    </FormikContext.Provider>
  );
};

export default MoreInfoContainer;
