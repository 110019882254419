import { useFormik } from 'formik';
import { noop } from 'helpers/CommonHelper';
import { useState } from 'react';
import { signUpInitialValues } from '../fixtures';
import { signUpSchema } from '../signUpSchema';
import { SignUp } from 'models';
import { LOADING_TITLES, FIELD_TYPES, SIGNUP_ERROR_MESSAGES, COMPANY } from 'constants';
import { useNavigate } from 'react-router-dom';

export const useSignUp = () => {
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [signUpStatus, setSignUpStatus] = useState({
    title: LOADING_TITLES.IN_PROGRESS.title,
    subTitle: LOADING_TITLES.IN_PROGRESS.subTitle,
  });
  const [captchaToken, setCaptchaToken] = useState(null);
  const history = useNavigate();
  // Check the URL query params to pre-populate the initial values in the form
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has('email')) {
    signUpInitialValues.email = searchParams.get('email');
  }
  if (searchParams.has('company')) {
    signUpInitialValues.company = searchParams.get('company');
  }
  if (searchParams.has('firstName')) {
    signUpInitialValues.firstName = searchParams.get('firstName');
  }
  if (searchParams.has('lastName')) {
    signUpInitialValues.lastName = searchParams.get('lastName');
  }

  // This performs a bit of security by obscurity to make it a little harder to abuse the unauthenticated CreateFreeAccount endpoint
  // This will sum the byte values of the chars in the payload to be sent with the POST.
  const generateCode = (obj) => {
    var sum = sumBytes(obj.firstName) + sumBytes(obj.lastName) + sumBytes(obj.email) + sumBytes(obj.company);
    return sum;
  };

  const sumBytes = (obj) => {
    var sum = 0;
    for (var i = 0; i < obj.length; i++) {
      sum += obj.charCodeAt(i);
    }
    return sum;
  };

  const handleSubmit = async (values) => {
    let hasTimedOut = false;
    const { firstName, lastName, email, company } = values;
    const { success } = await checkCaptcha();

    if (success) {
      setIsSigningUp(true);
      const validationMessages = await SignUp.validateForm({ email, company });
      if (validationMessages?.length > 0) {
        navToErrorPage(validationMessages);
      } else {
        const queryParameters = {
          firstName,
          lastName,
          email,
          company,
        };
        const payload = {
          firstName,
          lastName,
          email,
          company,
          code: generateCode(queryParameters),
        };
        let response = null;
        setTimeout(() => {
          if (!response) {
            hasTimedOut = true;
            setSignUpStatus({
              title: LOADING_TITLES.FAILED.title,
              subTitle: LOADING_TITLES.FAILED.subTitle,
            });
          }
        }, 60000);

        const [newTenantResponse] = await Promise.all([
          SignUp.createNewTenant(payload),
          await SignUp.notifyNewTenant(queryParameters),
        ]);

        response = newTenantResponse;

        if (!!response && !hasTimedOut) {
          try {
            const { uuid } = response;

            if (!!uuid) {
              const formattedURI = `/create-account?email=${encodeURIComponent(email)}`;
              history(formattedURI);
            }
          } catch (error) {
            setSignUpStatus({
              title: LOADING_TITLES.FAILED.title,
              subTitle: LOADING_TITLES.FAILED.subTitle,
            });
            console.log(error);
          }
        }
      }
    }
  };

  const navToErrorPage = (validationMessages) => {
    let firstEmailError = null;
    validationMessages.forEach((item) => {
      if (item.field === FIELD_TYPES.EMAIL && !firstEmailError) {
        firstEmailError = item.message;
      }
    });
    const { PROHIBITED_EMAIL, DUPLICATE_EMAIL, DUPLICATE_COMPANY } = SIGNUP_ERROR_MESSAGES;
    switch (firstEmailError) {
      case PROHIBITED_EMAIL:
        setSignUpStatus({
          title: LOADING_TITLES.PROHIBITED.title,
          subTitle: LOADING_TITLES.PROHIBITED.subTitle,
        });
        break;
      case DUPLICATE_EMAIL:
        setSignUpStatus({
          title: LOADING_TITLES.DUPLICATE.title,
          subTitle: LOADING_TITLES.DUPLICATE.subTitle,
        });
        break;
      default:
        const firstCompanyError = validationMessages.find((item) => (item.field = COMPANY));
        if (!!firstCompanyError && firstCompanyError?.message?.includes(DUPLICATE_COMPANY)) {
          setSignUpStatus({
            title: LOADING_TITLES.DUPLICATE.title,
            subTitle: LOADING_TITLES.DUPLICATE.subTitle,
          });
        } else {
          setSignUpStatus({
            title: LOADING_TITLES.FAILED.title,
            subTitle: LOADING_TITLES.FAILED.subTitle,
          });
        }
        break;
    }
  };

  const checkCaptcha = async () => {
    // TODO: Reenable ReCaptcha
    // Temporary change to unblock the issue SB-1879
    /*const isTokenValid =*/ await SignUp.verifyRecaptcha({ token: captchaToken });
    //return isTokenValid;
    return { success: true };
  };

  const formikBag = useFormik({
    initialValues: signUpInitialValues,
    onSubmit: handleSubmit,
    handleChange: noop,
    validationSchema: signUpSchema,
  });

  return {
    formikBag,
    isSigningUp,
    signUpStatus,
    setSignUpStatus,
    setCaptchaToken,
  };
};
