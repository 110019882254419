import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'components/toast';
import { ADMIN } from 'constants';
import { useContext, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import FullContext from 'stores/Full/fullContext';
import styled from 'styled-components';
import * as Api from '../../../api/Api';
import * as DateHelper from '../../../helpers/DateHelper';
import LoadingInline from '../../Loading/LoadingInline';
import { Avatar } from '../Avatar';

const CommentWrapper = styled.div`
  white-space: pre-wrap;
`;

export default function Comment({
  comment,
  customerTouchpointId,
  startEdit,
  finishEdit,
  isEditing,
  handleDelete,
}) {
  const { isViewAsCustomer, isExternal, isContributor, userData, roleAtleast } = useContext(FullContext);
  const [saving, setSaving] = useState(false);
  const [text, setText] = useState(comment.originalText);

  let color = isExternal || isContributor ? '#009FA3' : '#637AF1';

  async function handleSubmitComment() {
    let commentBody = text;
    if (!commentBody.trim()) {
      return;
    }
    setSaving(true);
    const response = await Api.put(
      'Customer/UpdateComment',
      {
        body: commentBody,
      },
      {
        params: {
          customerTouchpointId: customerTouchpointId,
          commentId: comment.id,
        },
      }
    );
    setSaving(false);
    comment.originalText = response.originalText;
    setText(response.originalText);
    finishEdit();
  }

  function handleChange(event) {
    setText(event.target.value);
  }

  function startEditing() {
    if (isViewAsCustomer) {
      toast.error('Please exit customer view to do this.');
      return;
    }

    startEdit(comment);
  }

  function cancelEdit() {
    setText(comment.originalText);
    finishEdit();
  }

  const onDelete = () => {
    if (isViewAsCustomer) {
      toast.error('Please exit customer view to do this.');
      return;
    }

    handleDelete(comment);
  };

  if (isEditing) {
    return (
      <>
        <Form.Control
          autoFocus
          value={text}
          className="mb-2-5"
          as="textarea"
          name="comment"
          rows="3"
          placeholder="Comment"
          disabled={saving}
          onChange={handleChange}
          onFocus={function (e) {
            var val = e.target.value;
            e.target.value = '';
            e.target.value = val;
          }}
        />
        <div className="text-right">
          <Button variant="outline-light" onClick={cancelEdit} className="mr-2-5">
            Cancel
          </Button>
          <Button disabled={!text.trim() || saving} variant="primary" onClick={handleSubmitComment}>
            {saving ? <LoadingInline white text="Saving..." /> : 'Save'}
          </Button>
        </div>
      </>
    );
  } else {
    const dateTime = DateHelper.formatDateTime(new Date(comment.dateCreated));
    const isCommentOwner = userData?.id === comment.userId;

    return (
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <Avatar
            avatar={comment.user.avatar}
            user={comment.user}
            backgroundColor={color}
            className="mr-2-5 flex-shrink-0"
          />
          <div>
            <CommentWrapper className="comment-holder">{text}</CommentWrapper>
            <div className="d-flex align-items-center small-info">
              {comment.user.firstName + ' ' + comment.user.lastName}
              <div className="ml-1 d-flex align-items-center">
                {dateTime.time} <div className="ml-md-1-5">{dateTime.date}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="small-info ml-3 text-nowrap">
          {isCommentOwner && <FontAwesomeIcon icon={faPencil} className="light" onClick={startEditing} />}
          {(isCommentOwner || roleAtleast(ADMIN)) && (
            <FontAwesomeIcon icon={faTrash} className="cursor-pointer ml-3" onClick={onDelete} />
          )}
        </div>
      </div>
    );
  }
}
