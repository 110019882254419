import React, { useState, useCallback, useMemo, useContext } from 'react';
import Select, { components } from 'react-select';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from 'components/Checkbox/Checkbox';
import { fieldSchema as accountSchema, baseFieldSchema } from 'views/CustomFields/Account/validations';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import { ErrorMessageContainer } from 'views/CustomFields/Account/Styles';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import usePrevious from 'helpers/hooks/usePrevious';
import {
  NEW_SECTION,
  NEW_STAGEBASE_FIELD,
  PLACEHOLDERS,
  FIELDS,
  CUSTOM_FIELD_MODES,
  CUSTOM_FIELDS_MODAL_VARIATIONS,
} from 'constants';
import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
} from '../LargePadding';
import { initialFieldValues, defaultSection } from './fixtures';
import {
  CheckboxContainer,
  DropdownContainer,
  FieldContainer,
  LoadingContainer,
  SectionLabel,
} from './Styles';
import External from './External';
import Custom from './Custom';

const FieldModal = ({
  show,
  onHide,
  queryFieldId = null,
  variation = CUSTOM_FIELDS_MODAL_VARIATIONS.CUSTOMER,
}) => {
  const { sectionDropdown, options } = useContext(CustomFieldsContext);

  const { values, submitForm, setFieldValue, errors, setValues } = useFormikContext();

  const [showLoading, setShowLoading] = useState(false);

  const fieldOptions = useMemo(
    () =>
      variation === CUSTOM_FIELDS_MODAL_VARIATIONS.CUSTOMER
        ? [...sectionDropdown, { ...defaultSection }]
        : [...sectionDropdown],
    [sectionDropdown, variation]
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { fieldValues, showSection, showCheckboxes, section, dataSource, fieldMode } = values;

  // Tracks previous value
  const previousDataSource = usePrevious(dataSource);

  /**
   * Drag handler for the Field Values
   * @param event
   * @param move
   * @returns void
   */
  const handleDragEnd = useCallback(
    (event, move) => {
      const { active, over } = event;
      const { index: prevPosition } = active.data.current.sortable;
      const { index: newPosition } = over.data.current.sortable;

      const isDraggable = fieldValues.length > 2;
      const isLastItem = newPosition === fieldValues.length - 1;

      if (!isDraggable || isLastItem) return;

      if (active?.id !== over?.id) move(prevPosition, newPosition);

      setFieldValue(FIELDS.IS_SORTED_FIELD_VALUES, false);
    },
    [fieldValues.length, setFieldValue]
  );

  const onFieldFocus = useCallback(
    (form, key) => () => {
      form.setFieldTouched(key, true);
    },
    []
  );

  const isVariation = (value) => variation === value;

  const isValid = isVariation(CUSTOM_FIELDS_MODAL_VARIATIONS.CUSTOMER)
    ? accountSchema.isValidSync(values)
    : baseFieldSchema.isValidSync(values);

  /**
   * Debounces the process and displays the loading icon
   * if the next field is not yet displayed
   * @param field
   * @returns void
   */
  const onFieldLoad = useCallback(
    (field) => {
      if (values[field]) return;

      setShowLoading(true);

      setTimeout(() => {
        setShowLoading(false);
        setFieldValue(field, true);
      }, 1000);
    },
    [setFieldValue, values]
  );

  /**
   * Checks if the error is a required type
   * @param field
   * @returns boolean
   */
  const isRequiredError = useCallback(
    (field) => errors && Array.isArray(errors) && errors[field]?.includes('required'),
    [errors]
  );

  const renderOptionLabel = useCallback(
    (option) => (
      <div>
        <FontAwesomeIcon icon={option.icon} className="mr-2" />
        <span className="ml-2">{option.label}</span>
      </div>
    ),
    []
  );

  /**
   * Renders the content for the modal
   * @param none
   * @returns Modal Content Component
   */
  const renderContent = useMemo(() => {
    const isEdit = fieldMode === CUSTOM_FIELD_MODES.EDIT;
    const hasFieldModal = !!queryFieldId;

    /**
     * Customized Dropdown group component
     * @param props
     * @returns Dropdown group component
     */
    const DropdownGroup = (props) => {
      return (
        <div>
          <components.Group {...props} />
        </div>
      );
    };

    const sortingContext = {
      sensors,
      handleDragEnd,
    };

    const onDatasourceChange = (args) => {
      setValues({
        ...initialFieldValues,
        dataSource: args,
        showExternalField:
          previousDataSource &&
          previousDataSource.value !== NEW_STAGEBASE_FIELD &&
          args.value !== NEW_STAGEBASE_FIELD,
      });

      if (args.value === NEW_STAGEBASE_FIELD) onFieldLoad(FIELDS.SHOW_FIELD_LABEL);
      if (args.value !== NEW_STAGEBASE_FIELD) onFieldLoad(FIELDS.SHOW_EXTERNAL_FIELD);
    };

    const onSectionChange = (form) => (args) => {
      form.setFieldValue(FIELDS.SECTION, args);
      form.setFieldValue(FIELDS.SECTION_LABEL, '');

      if (args.value !== NEW_SECTION) onFieldLoad(FIELDS.SHOW_CHECKBOXES);
    };

    const onSectionLabelChange = (form) => (event) => {
      const { value } = event.target;

      form.setFieldError(FIELDS.SECTION_LABEL, '');
      form.setFieldValue(FIELDS.SECTION_LABEL, value);
      onFieldLoad(FIELDS.SHOW_CHECKBOXES);
    };

    const onCheckboxChange = (form, field, key) => () => {
      form.setFieldValue(key, !field.value);
    };

    const renderModalLabel = () => {
      if (isVariation(CUSTOM_FIELDS_MODAL_VARIATIONS.CUSTOMER) && !isEdit) {
        return 'Add new accounts details field';
      }

      if (isVariation(CUSTOM_FIELDS_MODAL_VARIATIONS.CONTACTS) && !isEdit) {
        return 'Add new accounts contacts field';
      }

      if (isVariation(CUSTOM_FIELDS_MODAL_VARIATIONS.PROFILE) && !isEdit) {
        return 'Add new profile field';
      }

      if (isVariation(CUSTOM_FIELDS_MODAL_VARIATIONS.PROJECTS) && !isEdit) {
        return 'Add new projects field';
      }

      return 'Edit field';
    };

    return (
      <div>
        <h1 className="mb-3 text-center">{renderModalLabel()}</h1>

        <LoadedField isVisible>
          <DropdownContainer>
            <Form.Label>Data Source</Form.Label>

            <Field name={FIELDS.DATA_SOURCE}>
              {({ field, form }) => (
                <Select
                  value={field.value}
                  classNamePrefix="select"
                  isDisabled={isEdit}
                  placeholder={PLACEHOLDERS.DATASOURCE}
                  isSearchable={false}
                  components={{ DropdownGroup }}
                  onChange={onDatasourceChange}
                  onFocus={onFieldFocus(form, FIELDS.DATA_SOURCE)}
                  getOptionLabel={renderOptionLabel}
                  options={options.dataSourceList}
                />
              )}
            </Field>
          </DropdownContainer>
        </LoadedField>

        <Custom
          onFieldLoad={onFieldLoad}
          LoadedField={LoadedField}
          sortingContext={sortingContext}
          isRequiredError={isRequiredError}
          onFieldFocus={onFieldFocus}
          renderOptionLabel={renderOptionLabel}
          hasFieldModal={hasFieldModal}
          isVariation={isVariation}
        />

        <External
          onFieldLoad={onFieldLoad}
          LoadedField={LoadedField}
          isRequiredError={isRequiredError}
          onFieldFocus={onFieldFocus}
          isVariation={isVariation}
        />

        {(isVariation(CUSTOM_FIELDS_MODAL_VARIATIONS.CUSTOMER) ||
          isVariation(CUSTOM_FIELDS_MODAL_VARIATIONS.CONTACTS)) && (
          <LoadedField isVisible={showSection}>
            <DropdownContainer>
              <Form.Label>Section</Form.Label>
              <Field name={FIELDS.SECTION}>
                {({ field, form }) => (
                  <Select
                    value={field.value}
                    classNamePrefix="select"
                    placeholder={PLACEHOLDERS.SECTION}
                    isSearchable={false}
                    onChange={onSectionChange(form)}
                    onFocus={onFieldFocus(form, FIELDS.SECTION)}
                    options={fieldOptions}
                  />
                )}
              </Field>
            </DropdownContainer>

            {section?.value === NEW_SECTION && (
              <SectionLabel>
                <Field name={FIELDS.SECTION_LABEL}>
                  {({ field, form, ...props }) => (
                    <Form.Control
                      type="text"
                      placeholder={PLACEHOLDERS.SECTION_LABEL}
                      onChange={onSectionLabelChange(form)}
                      onBlur={onFieldFocus(form, FIELDS.SECTION_LABEL)}
                      {...props}
                    />
                  )}
                </Field>

                {!isRequiredError(FIELDS.SECTION_LABEL) && (
                  <ErrorMessageContainer>
                    <ErrorMessage name={FIELDS.SECTION_LABEL} />
                  </ErrorMessageContainer>
                )}
              </SectionLabel>
            )}
          </LoadedField>
        )}

        <LoadedField isVisible={showCheckboxes}>
          {dataSource?.value === NEW_STAGEBASE_FIELD && !!values.isRequiredShown && (
            <CheckboxContainer>
              <Field name={FIELDS.IS_REQUIRED}>
                {({ field, form }) => (
                  <Checkbox
                    checked={field.value}
                    label={PLACEHOLDERS.IS_REQUIRED}
                    name={FIELDS.IS_REQUIRED}
                    onChange={onCheckboxChange(form, field, FIELDS.IS_REQUIRED)}
                  />
                )}
              </Field>
            </CheckboxContainer>
          )}

          <CheckboxContainer>
            <Field name={FIELDS.IS_MERGE_TAG}>
              {({ field, form }) => (
                <Checkbox
                  checked={field.value}
                  label={PLACEHOLDERS.IS_MERGE_TAGS}
                  name={FIELDS.IS_MERGE_TAG}
                  onChange={onCheckboxChange(form, field, FIELDS.IS_MERGE_TAG)}
                />
              )}
            </Field>
          </CheckboxContainer>
        </LoadedField>

        {showLoading && (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        )}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDragEnd, isRequiredError, onFieldLoad, values, sensors, onFieldFocus]);

  /**
   * Renders the buttons for the modal
   * @param none
   * @returns Button Component
   */
  const renderButtons = (
    <>
      <ModalLargePaddingCancelButton onClick={onHide} autoWidth />
      <ModalLargePaddingConfirmButton onClick={submitForm} text="Save" disabled={!isValid} autoWidth />
    </>
  );

  const modalStyle = {
    inner: {
      maxWidth: '35.25rem',
      padding: '2.5rem 1.25rem !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  return (
    <ModalLargePadding
      show={show}
      onHide={onHide}
      innerProps={modalStyle.inner}
      buttonsContainerProps={modalStyle.buttonContainer}
      content={renderContent}
      buttons={renderButtons}
    />
  );
};

export default FieldModal;

const LoadedField = ({ isVisible, children }) => {
  if (!isVisible) return null;

  return <FieldContainer>{children}</FieldContainer>;
};
