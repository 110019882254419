import React, { useContext, useEffect, useLayoutEffect, useState, useCallback } from 'react';
import { shortDate } from 'helpers/DateHelper';
import { CustomerCanvas } from 'models';
import toast from 'components/toast';
import ModalConfirm from '../Modals/Confirm/index';
import DateChangeContext from '../Modals/Shared/DateChange/context/dateChangeContext';
import { DeleteMessage, CautionSection, ToastContainer } from './Styles';

const DeleteStepConfirmation = ({ itemForDelete, onItemDelete, setItemForDelete, mergedSteps }) => {
  const { activeTouchpoint, canvasEndDate, warnings, checkDependentStepStatus } =
    useContext(DateChangeContext);

  const [newEndDate, setNewEndDate] = useState('');

  const { customerCanvasId, id, customerTouchpointDependents, customerTouchpointDependentId, title } =
    activeTouchpoint;

  useEffect(() => {
    checkDependentStepStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemForDelete]);

  const callReviewProjectEndDate = useCallback(async () => {
    const canvasEndDate = await CustomerCanvas.getReviewProjectEndDate(customerCanvasId, id);

    canvasEndDate && setNewEndDate(shortDate(new Date(canvasEndDate)));
  }, [customerCanvasId, id]);

  useLayoutEffect(() => {
    callReviewProjectEndDate();
  }, [callReviewProjectEndDate]);

  const formattedDependents = () => {
    return customerTouchpointDependents.map(({ id, title, durationOptionAmount, durationOption }) => ({
      id,
      name: title,
      durationAmount: durationOptionAmount,
      durationOption:
        durationOptionAmount === 1
          ? durationOption.toLowerCase().substring(0, durationOption.length - 1)
          : durationOption.toLowerCase(),
      newParentTouchpoint: mergedSteps.find((item) => item.id === customerTouchpointDependentId),
    }));
  };

  const displayCautionMessage = () => {
    const isEndDateChanged = newEndDate && shortDate(new Date(canvasEndDate)) !== newEndDate;
    const isListShown = formattedDependents().length > 0 || warnings.length > 0 || isEndDateChanged;

    return (
      <>
        <DeleteMessage>
          Are you sure you want to delete <span>{title}</span>?
        </DeleteMessage>

        {isListShown && (
          <CautionSection>
            <p>Caution</p>

            <ul>
              {formattedDependents().length > 0 &&
                formattedDependents().map((item) => (
                  <li key={item.id}>
                    <span>{item.name}</span> will be due{' '}
                    <span>
                      {item.durationAmount} {item.durationOption} after{' '}
                      {item.newParentTouchpoint?.title ?? 'Start of Project'}
                    </span>
                  </li>
                ))}

              {warnings.length > 0 && (
                <li>
                  <span>{warnings.length}</span> dependent {warnings.length === 1 ? 'step' : 'steps'} will be
                  due earlier
                </li>
              )}

              {isEndDateChanged && (
                <li>
                  Project end date will change from {shortDate(new Date(canvasEndDate))} to{' '}
                  <span>{newEndDate}</span>
                </li>
              )}
            </ul>
          </CautionSection>
        )}
      </>
    );
  };

  const toastMessage = (
    <ToastContainer>
      <DeleteMessage>
        <span>{title}</span> step deleted{' '}
        {warnings.length > 0 &&
          `and ${warnings.length} step ${warnings.length === 1 ? 'date' : 'dates'} updated`}
      </DeleteMessage>

      {/** Uncomment this code once the Undo User story will be implemented
       *   and add the UndoLink style from CanvasCardColumns/Styles.js
       */}
      {/* <UndoLink href="#">undo</UndoLink> */}
    </ToastContainer>
  );

  return (
    <ModalConfirm
      title="Delete step"
      message={displayCautionMessage()}
      show={itemForDelete !== null}
      onConfirm={() => {
        const callbacks = [() => toast.info(toastMessage)];
        onItemDelete(itemForDelete, callbacks);
      }}
      onHide={() => {
        setItemForDelete(null);
      }}
    />
  );
};

export default DeleteStepConfirmation;
