import React, { useEffect, useState } from 'react';
import Select from '../../../../components/Select';
import MultiSelect from '../../../../components/MultiSelect';
import * as Api from '../../../../api/Api';
import useOwners from '../../../../helpers/hooks/useOwners';
import ErrorText from '../ErrorText';
import InputContainer from './ActionInputContainer';
import ActionContainer from './ActionContainer';

const ALL_CONTACTS_VALUE = -99;
const PROJECT_ONLY_VALUE = 'ProjectOnly';
const CUSTOMER_FACING_VALUE = 'CustomerFacing';
const ALL_STEPS_VALUE = 'AllSteps';
const STEPS_TAGS = 'StepsWithTags';

/**
 * Renders action to add user a project/canvas created in a previos action.
 * @property {object} props.action Action object type
 * @property {object} props.errors Errors in the form
 * @property {object} props.touched Indicates if elements in the form were touched
 * @property {object} props.onActionInputChange callback to execute when input changes
 * @returns {JSX.Element}
 */
const AddUserToCustomerCanvasAction = ({
  action = {},
  errors = {},
  touched = {},
  onActionInputChange = () => {},
}) => {
  const { ownerOptions } = useOwners();
  const actionInput = action?.actionInput || {};
  const { userIds = [] } = actionInput;
  const allContactsOpt = { label: 'All contacts', value: ALL_CONTACTS_VALUE };
  const allOptions = [allContactsOpt, ...ownerOptions];
  const selectedTagIds = actionInput.selectedTagIds;
  const selectedAddToOption = actionInput.selection;
  const addToOptions = [{ label: 'Add to project(s) only', value: PROJECT_ONLY_VALUE },
  { label: 'Add to customer facing steps', value: CUSTOMER_FACING_VALUE },
  { label: 'Add to all steps', value: ALL_STEPS_VALUE },
  { label: 'Add to steps with tags', value: STEPS_TAGS}];

  const [tags, setTags] = useState([]);
  const [newInput, setNewInput] = useState({...actionInput});

  useEffect(() => {
    loadTags();
  }, []);

  const loadTags = async () => {
    const response = await Api.get('Project/GetAllTags');
    setTags(response.map(x => ({ label: x.text, value: x.id })));
  }

  const usersOptions = [
    {
      label: 'CUSTOMERS',
      options: [allContactsOpt],
    },
    {
      label: 'INTERNAL',
      options: ownerOptions,
    },
  ];

  const ASSIGN_USER_TO_CANVAS_TEMPLATE = {
    userIds: [],
    AllContacts: false,
  };

  /**
   * Function to handle input change
   * @param {string} opt selected option
   * @returns {void}
   */
  const handleActionChange = (field) => (opt) => {
    const updatedFields = {};

    if (field === 'userIds'){
      updatedFields.userIds = opt?.map((op) => op.value) || [];
      const selectedAllContacts = opt?.some((op) => op.value === ALL_CONTACTS_VALUE);
      updatedFields.AllContacts = Boolean(selectedAllContacts);
    }else if (field === 'addToOption'){
      updatedFields.AllActiveProjects = true;
      updatedFields.selection = PROJECT_ONLY_VALUE;

      const selectedAllSteps = opt?.value === ALL_STEPS_VALUE;
      updatedFields.AddToAllSteps = Boolean(selectedAllSteps);
      if (updatedFields.AddToAllSteps) {
        updatedFields.selection = ALL_STEPS_VALUE;
      }

      const selectedCustomerFacing = opt?.value === CUSTOMER_FACING_VALUE;
      updatedFields.AddToCustomerFacing = Boolean(selectedCustomerFacing);
      if (updatedFields.AddToCustomerFacing) {
        updatedFields.selection = CUSTOMER_FACING_VALUE;
      }

      const selectedStepsWithTags = opt?.value === STEPS_TAGS;
      updatedFields.AddToStepsWithTags = Boolean(selectedStepsWithTags);
      if (updatedFields.AddToStepsWithTags) {
        updatedFields.selection = STEPS_TAGS;
      }
    }
    
    const newInput = {
      ...ASSIGN_USER_TO_CANVAS_TEMPLATE,
      ...actionInput,
      ...updatedFields,
    };
    setNewInput(newInput);
    onActionInputChange(newInput);
  };

  const handleTagsChange = (opt) => {
    const _input = {...newInput, ...{selectedTagIds: opt?.map((op) => op.value) || []}};
    setNewInput(_input);
    onActionInputChange(_input);
  };

  return (
    <ActionContainer>
      <div className="action-label">Add</div>
      <InputContainer>
        <MultiSelect
          placeholder="Select users..."
          containerWidth={250}
          closeMenuOnSelect={false}
          options={usersOptions}
          onChange={handleActionChange('userIds')}
          isSearchable={false}
          isClearable={false}
          value={allOptions.filter((user) => userIds.some((userId) => userId === user.value))}
        />
        {touched.actionInput && errors.userIds ? <ErrorText>{errors.userIds}</ErrorText> : null}
      </InputContainer>
      <InputContainer>
        <Select
          placeholder="Select options..."
          containerWidth={250}
          closeMenuOnSelect
          options={addToOptions}
          onChange={handleActionChange('addToOption')}
          isSearchable={false}
          isClearable={false}
          value={addToOptions.find((option) => option.value === selectedAddToOption)}
        />
        {touched.actionInput && errors.selection ? <ErrorText>{errors.selection}</ErrorText> : null}
      </InputContainer>
      {newInput.selection === STEPS_TAGS &&
      <InputContainer>
        <MultiSelect
          placeholder="Select tags..."
          containerWidth={250}
          closeMenuOnSelect={false}
          options={tags}
          onChange={handleTagsChange}
          isSearchable={false}
          isClearable={false}
          value={tags.filter((option) => selectedTagIds?.some((tagId) => tagId === option.value))}
        />
      </InputContainer>}
    </ActionContainer>
  );
};

export default AddUserToCustomerCanvasAction;
