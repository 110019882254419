import otherHistory from 'history/browser';
import toast from '../components/toast';
import { logOut } from '../helpers/CommonHelper';
import { handleDates } from 'helpers/DateHelper';

const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
  const response = await originalFetch(...args);

  // response interceptor
  const json = () =>
    response
      .clone()
      .json()
      .then((data) => {
        handleDates(data);
        return data;
      });

  response.json = json;
  return response;
};

/**
 * Prevents unnecessary API warning when the returned response body is empty for endpoints like
 * StepUserEngagement and ProjectUserEngagement
 * @param response
 * @returns null or json
 */
const handleResponse = async (response) => {
  return new Promise((resolve) => {
    if (response) {
      response
        .json()
        .then((json) => resolve(json))
        .catch(() => resolve(null));
    } else {
      resolve(null);
    }
  });
};

export function getResponse(response, history) {
  const whitelist = ['/login', '/'];
  if (response.ok) {
    if (response.status === 204) {
      return response;
    }

    return handleResponse(response);
  } else if (response.status === 401) {
    if (!whitelist.includes(window.location.pathname)) {
      const props = false;
      logOut(props);
    }
    if (history) {
      history('/login', { state: { loggedOut: true } });
    } else if (window.location.pathname !== whitelist[0]) {
      window.location.replace('/login');
    }
    return null;
  } else if (response.status === 500) {
    toast.error(
      <div className="server-error-toast">
        <p className="header">Something went wrong</p>
        <p>Please refresh and try again</p>
      </div>,
      {
        position: 'top-center',
        closeButton: false,
        style: {
          paddingInline: 24,
          paddingBlock: 16,
          width: 249,
        },
      }
    );
  } else if (response.status === 404 || response.status === 403) {
    (history || otherHistory)('/404');
    if (!history) {
      window.location.reload();
    }
  } else if (response.status === 400 || response.status === 409 || response.status === 410) {
    return response.json().then((data) => {
      return data;
    });
  } else if (response.status > 400 && response.status < 600) {
    throw new Error(response.statusText);
  }
  return null;
  // TODO: fix error handling to pass errors back as thrown errors, to simplify error handling. user custom UserFriendlyError when a message should be displayed on the front end
  // return response.json().then(data => {
  //     if (data && data.errorMessage) throw new UserFriendlyError(data.errorMessage);
  //     else throw new Error(data);
  // });
}

export function handleError(error) {
  console.error(error);
  toast.error(error.message || "There's a problem connecting to the server.");
  // toast.error("There's a problem connecting to the server.");
  // throw new Error(error);
}

// export class UserFriendlyError extends Error {
//     constructor(userFriendlyMessage, errorMessage) {
//         super(errorMessage ? errorMessage : userFriendlyMessage);
//         this.userFriendlyMessage = userFriendlyMessage;
//     }
// }
