import { post, get } from 'api/Api';
import { v4 as uuidv4 } from 'uuid';

class Integration {
  email = '';
  name = '';
  provider = '';
  state = '';

  constructor(props) {
    if (props) Object.assign(this, props);
  }

  static async authorize(email) {
    const stateParameter = uuidv4();
    sessionStorage.stateParameter = stateParameter;

    const subdomain = window.location.hostname.split('.')[0];
    const redirectUrl = `https://${subdomain}.stagebase.com/profile`;
    const baseUrl = `https://api.nylas.com/oauth/authorize?client_id=${sessionStorage.nylasClientId}&redirect_uri=${redirectUrl}&response_type=code&scopes=email.send&state=${stateParameter}`;
    const finalUrl = email.includes('+') ? baseUrl : `${baseUrl}&login_hint=${email}`;

    window.location.replace(finalUrl);
  }

  static async connect(code) {
    const res = await post('Account/ConnectNylas', { code });

    if (res.includes && res.includes('Error')) throw Error(res);

    return res;
  }

  static disconnect() {
    return get('Account/DisconnectNylas');
  }

  static getIdentifiers() {
    return get('Settings/GetIdentifiers');
  }
}

export default Integration;
