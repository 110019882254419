import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form } from 'react-bootstrap';
import styled from 'styled-components';

export const ActionsColumn = styled(Col)`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  svg {
    margin-right: 1rem;
    cursor: pointer;
    color: var(--playbook-pewter);
  }
`;

export const TableContainer = styled.div`
  margin-top: 1.938rem;
`;

export const HeaderColumn = styled(Col)`
  svg {
    position: relative;
    top: 1px;
    margin-left: 8px;
    color: var(--schedule-sapphire);
  }
  font-weight: normal;
`;

export const Column = styled(Col)`
  display: flex;
`;

export const NameColumn = styled(Column)`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const LastRunColumn = styled(Column)`
  white-space: nowrap;
`;

export const Switch = styled(Form.Check)`
  margin-left: 0.6rem;
`;

export const ExclamationIcon = styled(FontAwesomeIcon)`
  position: relative;
  top: 3px;
  margin-right: 7px;
  font-weight: bold;
  color: var(--danger);
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0;
`;

export const FilterButton = styled.button`
  background-color: transparent;
  font-size: 0.875rem;
  font-weight: 700;
  color: ${(props) => (props.filterHolder ? 'var(--schedule-sapphire)' : 'var(--playbook-pewter)')};
  border: none;
  padding: 1rem;
  margin: 0;
  width: 100%;

  &:hover {
    background-color: var(--read-only);
  }

  &:focus {
    outline: none !important;
    outline-offset: none !important;
  }

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }
`;

export const AutomationContainer = styled.div`
  .page-header {
    margin-bottom: 0;
  }

  .fa-grip-vertical {
    color: var(--playbook-pewter);
  }
`;
