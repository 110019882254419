import React, { useEffect, useCallback, useContext } from 'react';
import {
  DndContext,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import EditableField from 'components/CustomFields/Contacts/EditableField';
import EditableSection from 'components/CustomFields/Contacts/EditableSection';
import LoadingInline from 'components/Loading/LoadingInline';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { AccountBodyContainer } from '../Styles';
import { useContactsDnd } from '../hooks';
import PreviewContactsTable from 'components/CustomFields/Contacts/PreviewContactsTable';
import { FormikContext } from 'formik';
import { useContacts } from 'views/Customer/hooks';

const Contacts = () => {
  const {
    collisionDetectionStrategy,
    containers,
    coordinateGetter,
    handleDragCancel,
    handleDragEnd,
    handleDragOver,
    handleStart,
    items,
    recentlyMovedToNewContainer,
  } = useContactsDnd();

  const { formikBag, handleShow, handleHide, showAddContactModal } = useContacts();

  const { isLoading, isPreviewMode } = useContext(CustomFieldsContext);

  // DND sensors
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items, recentlyMovedToNewContainer]);

  const renderSection = useCallback(
    (containerId) => {
      const _items = Object.keys(items).length > 0 ? items[containerId]?.fields : null;
      return (
        <EditableSection isUndraggable columnCount={1} key={containerId} id={containerId} items={_items}>
          <SortableContext items={_items} strategy={verticalListSortingStrategy}>
            {_items.map((value, index) => (
              <EditableField key={value} id={value} index={index} />
            ))}
          </SortableContext>
        </EditableSection>
      );
    },
    [items]
  );

  return (
    <>
      {!isPreviewMode ? (
        <DndContext
          sensors={sensors}
          collisionDetection={collisionDetectionStrategy}
          measuring={{
            droppable: {
              strategy: MeasuringStrategy.Always,
            },
          }}
          onDragStart={handleStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <AccountBodyContainer hasSectionMargin>
            <SortableContext items={containers} strategy={verticalListSortingStrategy}>
              {isLoading ? (
                <LoadingInline />
              ) : (
                containers.map((containerId) => {
                  return renderSection(containerId);
                })
              )}
            </SortableContext>
          </AccountBodyContainer>
        </DndContext>
      ) : (
        <FormikContext.Provider value={formikBag}>
          <PreviewContactsTable
            handleShow={handleShow}
            handleHide={handleHide}
            showAddContactModal={showAddContactModal}
          />
        </FormikContext.Provider>
      )}
    </>
  );
};

export default Contacts;
