import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useState, useMemo } from 'react';
import { Badge, Dropdown, NavItem, ProgressBar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { navItems } from './fixtures';
import FullContext from 'stores/Full/fullContext';
import { EXTERNAL, SETTINGS_PERMISSIONS, ADMIN, FRONT_LINE, SUPERUSER } from 'constants';
import { AUTOMATION } from 'constants/automationRules';
import { BILLING_PLANS } from 'constants/settings';
import { useContext } from 'react';
import {
  ActiveIcon,
  OptionsContainer,
  ProgressContainer,
  SidebarContainer,
  SidebarItemsContainer,
} from './Styles';
import { useCallback } from 'react';
import { Settings } from 'models';
import {
  faAddressBook,
  faCircleQuestion,
  faCog,
  faEdit,
  faEnvelope,
  faSquareUpRight,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import ModalLargePadding from 'components/Modals/LargePadding';
import { format } from 'date-fns';

const Sidebar = ({
  isGlobalAutomationEnabled,
  unreadNotificationCount,
  hideSidebar,
  isConfirmed,
  isExternal,
}) => {
  const { userData, roleAtleast, appSettings } = useContext(FullContext);

  const [showGuide, setShowGuide] = useState(false);
  const [isEmailNotConnected, setIsEmailNotConnected] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);

  const handleLoadUserData = useCallback(async () => {
    const { nylasProfile } = await Settings.getUserProfile();
    const { stripeData } = await Settings.getAppSettings();
    const { billing } = await Settings.getSettingsPermissions();

    !nylasProfile ? setIsEmailNotConnected(true) : setIsEmailNotConnected(false);

    if (billing !== SETTINGS_PERMISSIONS.READWRITE) return;

    if (!stripeData?.plan) {
      setCurrentPlan(BILLING_PLANS.FREE);
      return;
    }

    const getMappedPlans = {
      [BILLING_PLANS.TIER_ONE]: () => setCurrentPlan(BILLING_PLANS.TIER_ONE),
      [BILLING_PLANS.TIER_TWO]: () => setCurrentPlan(BILLING_PLANS.TIER_TWO),
      [BILLING_PLANS.TIER_THREE]: () => setCurrentPlan(BILLING_PLANS.TIER_THREE),
      [BILLING_PLANS.ENTERPRISE]: () => setCurrentPlan(BILLING_PLANS.ENTERPRISE),
    };

    getMappedPlans[stripeData?.plan]();
  }, []);

  const progressDetails = useMemo(() => {
    const mappedTotals = {
      [BILLING_PLANS.FREE]: 20,
      [BILLING_PLANS.TIER_ONE]: 50,
      [BILLING_PLANS.TIER_TWO]: 200,
      [BILLING_PLANS.TIER_THREE]: 500,
    };

    const endLimit =
      appSettings?.stripeData?.customerCapping?.end <= -1
        ? 20
        : appSettings?.stripeData?.customerCapping?.end;

    const total = endLimit || mappedTotals[currentPlan];
    const percentage = (+appSettings?.activeAccountCount / +total) * 100;

    return {
      total,
      percentage,
      isAutoUpgradeShown: +appSettings?.activeAccountCount > +total,
      isDanger: +appSettings?.activeAccountCount >= +total,
      isShown: !!currentPlan && currentPlan !== BILLING_PLANS.ENTERPRISE,
    };
  }, [appSettings?.activeAccountCount, appSettings?.stripeData?.customerCapping?.end, currentPlan]);

  useEffect(() => {
    handleLoadUserData();
  }, [handleLoadUserData]);

  // badge addon to NavItem
  const badge = (badge) => {
    if (badge) {
      const classes = classNames(badge.class);
      return (
        <Badge className={classes} color={badge.variant}>
          {badge.text}
        </Badge>
      );
    }
  };

  // nav list section title
  const title = (title, key) => {
    const classes = classNames('nav-title', title.class);
    return (
      <li key={key} className={classes}>
        {title.name}
      </li>
    );
  };

  // nav item with nav link
  const navItem = (item, key) => {
    const classes = {
      item: classNames(item.class),
      link: classNames(
        'nav-link',
        item.variant ? `nav-link-${item.variant}` : '',
        item.mobile ? ['d-block', 'd-lg-none'] : ''
      ),
      icon: classNames(item.icon),
    };
    if (item.showUnreadNotificationCount && unreadNotificationCount) {
      item.badge = {
        text: unreadNotificationCount,
        class: 'ml-2 badge-danger badge-pill',
      };
    }
    return navLink(item, key, classes);
  };

  // nav link
  const navLink = (item, key, classes) => {
    const isActiveLink = document.location.href.includes(item.url);

    return (
      <NavItem key={key} className={classes.item}>
        <NavLink
          to={item.url}
          className={classes.link}
          // activeStyle={{ color: 'var(--schedule-sapphire)', fontWeight: 'bold' }}
          style={isActiveLink ? { color: 'var(--schedule-sapphire)', fontWeight: 'bold' } : {}}
          onClick={hideSidebar}
        >
          {isActiveLink ? (
            <ActiveIcon icon={item.icon} fixedWidth />
          ) : (
            <FontAwesomeIcon icon={item.icon} fixedWidth />
          )}
          {item.name}
          {badge(item.badge)}
        </NavLink>
      </NavItem>
    );
  };

  // nav type
  const navType = (item, idx) => (item.url ? navItem(item, idx) : title(item, idx));

  // nav list
  const navList = (items) => {
    let sidebarItems = items;
    if (!isGlobalAutomationEnabled) {
      sidebarItems = items.filter((item) => item.name !== AUTOMATION);
    }
    let role = userData?.role;
    let permissionedItems = [];

    if (role) {
      sidebarItems.forEach((item) => {
        if (!item.roles || item.roles.includes(role)) {
          permissionedItems.push(item);
        }
      });
    }

    return permissionedItems.map((item, index) => navType(item, index));
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div ref={ref} onClick={onClick} className="cursor-pointer">
      {children}
    </div>
  ));

  const userRole = userData?.role;

  const memoizedGuidesList = useMemo(() => {
    const guides = [
      { id: 'quickstart-templates', icon: faEdit, label: 'Build a Template' },
      { id: 'quickstart-accounts', icon: faAddressBook, label: 'Add Accounts' },
      { id: 'quickstart-settings', icon: faCog, label: 'Add Your Brand' },
    ];

    const roles = {
      ADMIN: guides,
      CREATOR: guides.slice(0, -1),
      FRONT_LINE: [guides[1]],
    };

    return roleAtleast(SUPERUSER) ? roles.ADMIN : roles[userRole];
  }, [roleAtleast, userRole]);

  const memoizedScheduledDate = useMemo(
    () =>
      appSettings?.stripeData?.scheduledDate
        ? format(new Date(appSettings?.stripeData?.scheduledDate), 'MMM d, yyyy')
        : '',
    [appSettings?.stripeData?.scheduledDate]
  );

  const showProgressBar = useMemo(() => {
    return progressDetails.total !== -1 && progressDetails.isShown;
  }, [progressDetails.isShown, progressDetails.total]);

  if (userRole === EXTERNAL) return null;

  const modalStyle = {
    inner: {
      maxWidth: 'unset',
      padding: '2.5rem 0 2.5rem 0 !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'var(--light-gray-bg)',
      borderRadius: '0 0 0.813rem 0.813rem',
      padding: '1.875rem 0 2rem 0 !important',
    },
  };

  return (
    <SidebarContainer className="sidebar border-right" $isConfirmed={isConfirmed} $isExternal={isExternal}>
      <nav className="sidebar-nav">
        <SidebarItemsContainer>
          <div>{navList(navItems.items)}</div>

          <div>
            {isEmailNotConnected && (
              <NavItem>
                <NavLink to="/profile" className="nav-link connect-email">
                  <FontAwesomeIcon icon={faEnvelope} className="mt-1" fixedWidth /> Connect Email
                </NavLink>
              </NavItem>
            )}

            <NavItem>
              <span className="nav-link cursor-pointer">
                <Dropdown drop="right">
                  <Dropdown.Toggle as={CustomToggle}>
                    <FontAwesomeIcon icon={faCircleQuestion} className="mt-1" fixedWidth /> Help
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="left">
                    {roleAtleast(FRONT_LINE) && window.pendo && (
                      <Dropdown.Item
                        id={`getting-started-guide-${userRole}`}
                        onClick={() => setShowGuide(true)}
                      >
                        Quickstart Guide
                      </Dropdown.Item>
                    )}
                    {
                    //<Dropdown.Divider />
                    //<Dropdown.Item onClick={() => window.open('https://stagebase.com/docs/', '_blank')}>
                      //Knowledge Base
                    //</Dropdown.Item>
                    }
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => window.open('mailto:support@stagebase.com', '_self')}>
                      Email Us
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </span>
            </NavItem>

            {currentPlan === BILLING_PLANS.FREE && roleAtleast(ADMIN) && (
              <NavItem>
                <NavLink to="/settings#billing" className="nav-link">
                  <FontAwesomeIcon icon={faSquareUpRight} className="mt-1" fixedWidth /> Upgrade
                </NavLink>
              </NavItem>
            )}

            {showProgressBar && (
              <NavItem className="nav-link">
                <div className="d-flex flex-column progress-content">
                  <div
                    className={`d-flex justify-content-between align-items-center ${
                      !progressDetails.isDanger ? 'mb-2' : 'mb-1'
                    }`}
                  >
                    <p>Accounts</p>
                    <p>
                      <span className={progressDetails.isDanger ? 'small-danger' : ''}>
                        {appSettings?.activeAccountCount}
                      </span>{' '}
                      / {progressDetails.total}
                    </p>
                  </div>

                  {progressDetails.isAutoUpgradeShown && (
                    <div className="my-1 d-flex flex-column">
                      <span className="font-weight-bold small-danger text-xs">Automatic Upgrade:</span>
                      <span className="bold small-danger mb-2 text-xs">{memoizedScheduledDate}</span>
                    </div>
                  )}

                  <ProgressContainer $isDanger={progressDetails.isDanger}>
                    <ProgressBar now={+progressDetails.percentage} />
                  </ProgressContainer>
                </div>
              </NavItem>
            )}
          </div>
        </SidebarItemsContainer>
      </nav>

      <ModalLargePadding
        show={showGuide}
        onHide={() => setShowGuide(false)}
        innerProps={modalStyle.inner}
        buttonsContainerProps={modalStyle.buttonContainer}
        content={
          <div>
            <div className="text-center mb-4">
              <h1 className="mb-3 ml-5 d-inline-flex">Quickstart Guide</h1>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => setShowGuide(false)}
                className="close-button clickable float-right fa-xl"
              />
            </div>

            <OptionsContainer>
              {memoizedGuidesList?.map((item) => (
                <div key={item.id} id={item.id} className="guide" onClick={() => setShowGuide(false)}>
                  <FontAwesomeIcon icon={item.icon} />
                  <h4>{item.label}</h4>
                </div>
              ))}
            </OptionsContainer>
          </div>
        }
      />
    </SidebarContainer>
  );
};

export default Sidebar;
