import * as Yup from 'yup';
import {
  TEXT_FIELDS,
  ERROR_MESSAGES,
  NEW_STAGEBASE_FIELD,
  FIELDS,
  FIELD_TYPES,
  CUSTOM_FIELD_MODES,
  SINGLE_DROPDOWN_FIELDS,
  NUMBER_FIELDS,
} from 'constants/customFields';

/**
 * Verifies if the datasource is a new
 * stagebase field
 * @param dataSource
 * @returns Boolean
 */
const isNewStagebaseField = (dataSource) => dataSource && dataSource?.value === NEW_STAGEBASE_FIELD;

export const selectSchema = Yup.object().nullable().shape({
  value: Yup.string().required(),
  label: Yup.string().required(),
});

export const baseSchema = {
  dataSource: selectSchema.required(),

  externalField: Yup.object()
    .nullable()
    .when(FIELDS.DATA_SOURCE, {
      is: (dataSource) => !isNewStagebaseField(dataSource),
      then: selectSchema.required(''),
    }),

  permission: Yup.object()
    .nullable()
    .when(FIELDS.DATA_SOURCE, {
      is: (dataSource) => !isNewStagebaseField(dataSource),
      then: selectSchema.required(),
    }),

  fieldType: Yup.object()
    .nullable()
    .when(FIELDS.DATA_SOURCE, {
      is: (dataSource) => isNewStagebaseField(dataSource),
      then: selectSchema.required(),
    }),

  fieldLabel: Yup.string().when(FIELDS.DATA_SOURCE, {
    is: (dataSource) => isNewStagebaseField(dataSource),
    then: Yup.string().required(''),
  }),

  fieldValues: Yup.array().when([FIELDS.FIELD_TYPE, FIELDS.DATA_SOURCE], {
    is: (fieldType, dataSource) =>
      isNewStagebaseField(dataSource) &&
      fieldType &&
      (fieldType?.value === FIELD_TYPES.SINGLE_DROPDOWN || fieldType?.value === FIELD_TYPES.MULTI_DROPDOWN),
    then: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          value: Yup.string(),
        })
      )
      .test({
        name: FIELDS.LAST_FIELD_TYPE,
        message: ERROR_MESSAGES.EMPTY_FIELD_VALUES,
        test: (data, ctx) => {
          const hasValues = data.every(({ value }, index) => {
            if (index === data.length - 1) return true;

            return !!value;
          });

          if (ctx.parent.fieldMode === CUSTOM_FIELD_MODES.EDIT) return true;

          return data.length > 1 ? hasValues : false;
        },
      }),
  }),

  currency: Yup.object()
    .nullable()
    .when([FIELDS.FIELD_TYPE, FIELDS.DATA_SOURCE], {
      is: (fieldType, dataSource) =>
        isNewStagebaseField(dataSource) && fieldType && fieldType?.value === FIELD_TYPES.CURRENCY,
      then: selectSchema.required(''),
    }),

  fieldMode: Yup.string(),

  editedFieldId: Yup.string().nullable(),

  previousFieldLabel: Yup.string(),

  previousExternalField: Yup.object()
    .nullable()
    .when([FIELDS.FIELD_MODE, FIELDS.DATA_SOURCE], {
      is: (fieldMode, dataSource) =>
        !isNewStagebaseField(dataSource) && fieldMode === CUSTOM_FIELD_MODES.EDIT,
      then: selectSchema,
    }),

  isProtected: Yup.boolean(),

  isSortedFieldValues: Yup.boolean(),

  isRequired: Yup.boolean(),

  isMergeTag: Yup.boolean(),
};

/**
 * Yup configuration for the list of objects
 * containing each field name of the custom field
 * that'll be populated
 */
export const arrayFieldsSchema = Yup.array().of(
  Yup.object().shape({
    isRequired: Yup.boolean().nullable(),

    fieldType: Yup.string().nullable(),

    formikValue: Yup.mixed()
      .nullable()
      .when([FIELDS.FIELD_TYPE, FIELDS.IS_REQUIRED], {
        is: (fieldType, isRequired) => TEXT_FIELDS[fieldType] && isRequired,
        then: Yup.string().trim().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED).nullable(),
      })
      .when([FIELDS.FIELD_TYPE, FIELDS.IS_REQUIRED], {
        is: (fieldType, isRequired) => SINGLE_DROPDOWN_FIELDS[fieldType] && isRequired,
        then: selectSchema.typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
      })
      .when([FIELDS.FIELD_TYPE, FIELDS.IS_REQUIRED], {
        is: (fieldType, isRequired) => NUMBER_FIELDS[fieldType] && isRequired,
        then: Yup.number().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
      })
      .when([FIELDS.FIELD_TYPE, FIELDS.IS_REQUIRED], {
        is: (fieldType, isRequired) => fieldType === FIELD_TYPES.MULTI_DROPDOWN && isRequired,
        then: Yup.array()
          .of(selectSchema.typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED))
          .typeError(ERROR_MESSAGES.REQUIRED),
      })
      .when([FIELDS.FIELD_TYPE, FIELDS.IS_REQUIRED], {
        is: (fieldType, isRequired) => fieldType === FIELD_TYPES.SWITCH && isRequired,
        then: Yup.boolean()
          .typeError(ERROR_MESSAGES.REQUIRED)
          .oneOf([true], ERROR_MESSAGES.REQUIRED)
          .required(ERROR_MESSAGES.REQUIRED),
      })
      .when([FIELDS.FIELD_TYPE, FIELDS.IS_REQUIRED], {
        is: (fieldType, isRequired) => fieldType === FIELD_TYPES.YES_NO && isRequired,
        then: Yup.object()
          .typeError(ERROR_MESSAGES.REQUIRED)
          .shape({
            id: Yup.string().required(ERROR_MESSAGES.REQUIRED),
            label: Yup.string().required(ERROR_MESSAGES.REQUIRED),
          }),
      })
      .when([FIELDS.FIELD_TYPE, FIELDS.IS_REQUIRED], {
        is: (fieldType, isRequired) => fieldType === FIELD_TYPES.DATE_AND_TIME && isRequired,
        then: Yup.object()
          .typeError(ERROR_MESSAGES.REQUIRED)
          .shape({
            date: Yup.string().required(ERROR_MESSAGES.REQUIRED).typeError(''),
            time: Yup.string().required(ERROR_MESSAGES.REQUIRED).typeError(''),
            dateTime: Yup.string().required(ERROR_MESSAGES.REQUIRED).typeError(''),
          }),
      })
      .when([FIELDS.FIELD_TYPE, FIELDS.IS_REQUIRED], {
        is: (fieldType, isRequired) => fieldType === FIELD_TYPES.EMAIL && isRequired,
        then: Yup.string()
          .typeError(ERROR_MESSAGES.REQUIRED)
          .email(ERROR_MESSAGES.EMAIL)
          .required(ERROR_MESSAGES.REQUIRED)
          .nullable(),
      })
      .when([FIELDS.FIELD_TYPE, FIELDS.IS_REQUIRED], {
        is: (fieldType, isRequired) => fieldType === FIELD_TYPES.EMAIL && !isRequired,
        then: Yup.string().email(ERROR_MESSAGES.EMAIL).nullable(),
      })
      .when([FIELDS.FIELD_TYPE, FIELDS.IS_REQUIRED], {
        is: (fieldType, isRequired) => fieldType === FIELD_TYPES.EMAIL && !isRequired,
        then: Yup.string().typeError(ERROR_MESSAGES.REQUIRED).email(ERROR_MESSAGES.EMAIL).nullable(),
      }),
  })
);
