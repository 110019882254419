const AuthReducer = (state, action) => {
  switch (action.type) {
    case 'connect_email':
      return {
        ...state,
        nylasToken: action.payload,
      };

    case 'disconnect_email':
      return {
        ...state,
        nylasToken: null,
      };

    case 'populate_access_token':
      return {
        ...state,
        accessToken: action.payload,
      };

    case 'remove_access_token':
      return {
        ...state,
        accessToken: null,
      };

    default:
      return state;
  }
};

export default AuthReducer;
