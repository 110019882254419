import styled from 'styled-components';

export const AssignModalContainer = styled.div`
  padding: 1.781rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    gap: 2.5rem;
    align-items: center;
    font-size: 1.25rem;
  }

  .tags,
  .assign-all {
    margin-top: 1rem;
  }
`;

export const TitleContainer = styled.div`
  max-width: 18.75rem;
  word-break: break-word;
`;

export const MoreInfoText = styled.span`
  width: max-content;
  color: var(--schedule-sapphire);
`;
