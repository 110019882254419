const SettingsReducer = (state, action) => {
  switch (action.type) {
    case 'get_api_tokens':
      return {
        ...state,
        apiTokenList: action.payload,
      };

    case 'add_api_token':
      return {
        ...state,
        apiTokenList: [...state.apiTokenList, action.payload],
      };

    case 'show_api_token':
      const newTokenList = state.apiTokenList.map((token, tokenKey) =>
        tokenKey === action.payload
          ? {
              ...token,
              isTokenShown: !token.isTokenShown,
            }
          : { ...token }
      );

      return {
        ...state,
        apiTokenList: newTokenList,
      };

    case 'delete_api_token':
      const filteredTokenList = state.apiTokenList.filter(({ id }) => id !== action.payload);

      return {
        ...state,
        apiTokenList: filteredTokenList,
      };

    case 'update_last_used':
      const updatedTokenList = state.apiTokenList.map((token) =>
        token.id === action.payload.id
          ? {
              ...token,
              lastUsed: action.payload.lastUsed,
            }
          : { ...token }
      );

      return {
        ...state,
        apiTokenList: updatedTokenList,
      };

    case 'set_salesforce_enabled':
      return {
        ...state,
        isSalesforceEnabled: action.payload,
      };

    case 'set_hubspot_enabled':
      return {
        ...state,
        isHubspotEnabled: action.payload,
      };

    default:
      return state;
  }
};

export default SettingsReducer;
