import { useCallback, useContext, useEffect, useReducer } from 'react';
import { format } from 'date-fns';
import { Settings } from 'models';
import SettingsReducer from './settingsReducer';
import SettingsContext from './settingsContext';
import FullContext from 'stores/Full/fullContext';
import { ADMIN } from 'constants';

const SettingsState = ({ children }) => {
  const initialState = {
    apiTokenList: [],
    isSalesforceEnabled: false,
    isHubspotEnabled: false,
  };

  const [state, dispatch] = useReducer(SettingsReducer, initialState);
  const { roleAtleast } = useContext(FullContext);

  const isGenerateBtnDisabled = state.apiTokenList.length >= 3;

  const isApiTokenEmpty = state.apiTokenList.length === 0;

  /**
   * Retrieves the API token and stores the data in the apiTokenList
   * @param none
   * @returns Promise<void>
   */
  const fetchApiTokens = useCallback(async () => {
    const response = await Settings.getIntegrationApiTokens();

    const newApiTokens = response.map((item) => ({
      ...item,
      isTokenShown: false,
      lastUsed: item.lastUsed ? format(new Date(item.lastUsed), 'hh:mm aa MMM d, yyyy') : null,
    }));

    getApiTokens(newApiTokens);
  }, []);

  /**
   * Handler for setting the state of salesforceEnabled
   */
  const setSalesforceEnabled = useCallback((data) => {
    dispatch({
      type: 'set_salesforce_enabled',
      payload: data,
    });
  }, []);

  /**
   * Handler for setting the state of hubspot
   */
  const setHubspotEnabled = useCallback((data) => {
    dispatch({
      type: 'set_hubspot_enabled',
      payload: data,
    });
  }, []);

  useEffect(() => {
    if (roleAtleast(ADMIN)) fetchApiTokens();
  }, [fetchApiTokens, roleAtleast]);

  /**
   * Adds new API token and pushes it into the apiTokenList
   * @param none
   * @returns Promise<void>
   */
  const onAddApiToken = async () => {
    if (isGenerateBtnDisabled) return;

    const response = await Settings.addIntegrationApiToken();

    const newApiToken = {
      id: response.id,
      token: response.token,
      lastUsed: response.lastUsed,
      isTokenShown: false,
    };

    addApiToken(newApiToken);
  };

  /**
   * Deletes an existing API token and filters out
   * the existing apiTokenList
   * @param id
   * @returns Promise<void>
   */
  const onDeleteApiToken = async (id) => {
    await Settings.deleteIntegrationApiToken(id);

    deleteApiToken(id);
  };

  /**
   * Updates the Last Used property of the
   * selected API token
   * @param id
   * @returns Promise<void>
   */
  const onToggleLastUsed = async (id) => {
    const response = await Settings.updateLastUsedIntegrationApiToken(id);

    updateLastUsed({
      id: response.id,
      lastUsed: format(new Date(response.lastUsed), 'hh:mm aa MMM d, yyyy'),
    });
  };

  /**
   * Dispatch handler for updating the last used property
   * @param data
   * @returns void
   */
  const updateLastUsed = (data) => {
    dispatch({
      type: 'update_last_used',
      payload: data,
    });
  };

  /**
   * Dispatch handler for getting the API tokens
   * @param data
   * @returns void
   */
  const getApiTokens = (data) => {
    dispatch({
      type: 'get_api_tokens',
      payload: data,
    });
  };

  /**
   * Dispatch handler for adding a new API token
   * @param data
   * @returns void
   */
  const addApiToken = (data) => {
    dispatch({
      type: 'add_api_token',
      payload: data,
    });
  };

  /**
   * Dispatch handler for toggling the token into a
   * hidden or shown state
   * @param index
   * @returns void
   */
  const showApiToken = (index) => {
    dispatch({
      type: 'show_api_token',
      payload: index,
    });
  };

  /**
   * Dispatch handler for deleting an API token
   * @param index
   * @returns void
   */
  const deleteApiToken = (index) => {
    dispatch({
      type: 'delete_api_token',
      payload: index,
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        apiTokenList: state.apiTokenList,
        isSalesforceEnabled: state.isSalesforceEnabled,
        isHubspotEnabled: state.isHubspotEnabled,
        onAddApiToken,
        onDeleteApiToken,
        onToggleLastUsed,
        showApiToken,
        isGenerateBtnDisabled,
        isApiTokenEmpty,
        setSalesforceEnabled,
        setHubspotEnabled,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsState;
