import * as ServerResponseHandler from './ServerResponseHandler';
import { accessToken } from 'helpers/CommonHelper';
import toast from 'components/toast';
const API_URL = process.env.REACT_APP_API_URL;

export function get(endpoint, params = {}, { history } = {}) {
  // return new Promise((resolve, reject) =>
  //   setTimeout(() => {
  //     resolve(makeCall('GET', endpoint, { params, props }));
  //   }, 2000)
  // );
  return makeCall('GET', endpoint, { params, history });
}

export function post(endpoint, payload, { params = {}, rawResponse, history } = {}) {
  // return new Promise((resolve, reject) =>
  //   setTimeout(() => {
  //     reject('foo');
  //   }, 2000)
  // );
  return makeCall('POST', endpoint, { payload, params, rawResponse, history });
}

export function patch(endpoint, payload, { params = {}, rawResponse, history } = {}) {
  return makeCall('PATCH', endpoint, { payload, params, rawResponse, history });
}

export function postFiles(endpoint, files, { rawResponse, history } = {}) {
  return makeCall('POST', endpoint, { files, rawResponse, history });
}

export function put(endpoint, payload, { params = {}, history } = {}) {
  return makeCall('PUT', endpoint, { payload, params, history });
}

export function deleteRequest(endpoint, { history } = {}) {
  return makeCall('DELETE', endpoint, { history });
}

export function getUrl(endpoint) {
  return new URL(API_URL + '/api/' + endpoint);
}

async function makeCall(method, endpoint, { params = {}, payload, files, rawResponse, history }) {
  let url = getUrl(endpoint);
  let headers = files
    ? {}
    : {
        'Content-Type': 'application/json',
      };
  if (accessToken && accessToken !== 'null') {
    headers.Authorization = 'Bearer ' + accessToken;
  }
  let fetchConfig = {
    method: method,
    headers: headers,
    credentials: 'include',
    mode: 'cors',
  };
  if (files) {
    var data = new FormData();
    for (const f of files) {
      data.append(f.name, f);
    }
    fetchConfig.body = data;
  } else if (payload) {
    fetchConfig.body = JSON.stringify(payload);
  }

  Object.keys(params).forEach((key) => {
    if (params[key] != null) {
      if (Array.isArray(params[key])) {
        params[key].forEach((element) => {
          url.searchParams.append(key, element);
        });
      } else {
        url.searchParams.append(key, params[key]);
      }
    }
  });

  let response;
  try {
    response = await fetch(url.toString(), fetchConfig);
  } catch (error) {
    toast.error(
      <div className="server-error-toast">
        <p className="header">Something went wrong</p>
        <p>Please refresh and try again</p>
      </div>,
      {
        position: 'top-center',
        closeButton: false,
        style: {
          paddingInline: 24,
          paddingBlock: 16,
          width: 249,
        },
      }
    );
  }
  if (rawResponse) {
    return response;
  }

  return ServerResponseHandler.getResponse(response, history);
  // if (!rawResponse) {
  //   // TODO: if (error.hasOwnProperty(userFriendlyMessage)) throw new ServerResponseHandler.UserFriendlyError(error.userFriendlyMessage, error.message);
  //   return ServerResponseHandler.handleError(error);
  // }
}
