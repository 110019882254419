import { useContext, useCallback } from 'react';
import { ToggleSwitch } from 'components/Modals/Touchpoint/Styles';
import dateChangeContext from '../context/dateChangeContext';
import { RowSeparator } from '../styles';
import { Touchpoint } from 'models';
import InfoTextBox from '../../InfoTextBox';

const NoDueDate = ({ touchpoint } = { touchpoint: new Touchpoint() }) => {
  const {
    noDueDate,
    updateNoDueDate,
    activeTouchpoint,
  } = useContext(dateChangeContext);
  const hasDependents = touchpoint?.hasDependents || activeTouchpoint?.touchpointDependents?.length;

  const handleChange = useCallback(() =>
    !hasDependents && updateNoDueDate(!noDueDate),
    [hasDependents, noDueDate, updateNoDueDate]
  );

  if (touchpoint?.isComplete || touchpoint?.isScheduledType) return false;

  return (
    <>
      <RowSeparator />
      <ToggleSwitch
        disabled={hasDependents}
        onClick={handleChange}
      >
        <input
          type="checkbox"
          id="noDueDate"
          className="custom-toggle"
          checked={noDueDate}
          disabled={hasDependents}
          onChange={handleChange}
        />
        <label className="custom-label">
          <div className="custom-ball"></div>
        </label>
        <span>No due date. Finish by end of project.</span>
        <InfoTextBox
          notActive={true}
          top="10px"
          left="5px"
          width="412px"
          show
          disableOutsideClick
        >
          <p>
            One or more steps depend on this step for their due
            date. You must remove those dependencies before
            you can delete this step's due date.
          </p>
        </InfoTextBox>
      </ToggleSwitch>
    </>
  );
}

export default NoDueDate;
