import React, { useContext, useEffect, useState } from 'react';
import { FormikContext } from 'formik';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensors,
  useSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import FullContext from 'stores/Full/fullContext';
import EditableField from 'components/CustomFields/Profile/EditableField';
import FieldModal from 'components/Modals/CustomFields/Field';
import PageHeader from 'components/PageHeader';
import { CUSTOM_FIELDS_MODAL_VARIATIONS, PROFILE_SOURCE, OBJECT_TYPE_ENUM } from 'constants';
import { ActionContainer, Divider, InformationSection, PageDetails } from './Styles';
import { useProfileField } from './hooks';
import { capitalizeFirstLetter } from 'helpers/CommonHelper';

const Profile = () => {
  const {
    getAllEnums,
    getAllProfileEditableData,
    getOwnerTypes,
    profile,
    updateFieldOrder,
    isChangesSaving,
    hasServerError,
  } = useContext(CustomFieldsContext);
  const { route } = useContext(FullContext);

  const { formikBag, handleShow, handleHide, showAddFieldModal } = useProfileField();

  const defaultFields = profile?.editableFields.filter(({ isProtected }) => isProtected);

  const [draggableItems, setDraggableItems] = useState([]);

  useEffect(() => {
    const customFields = profile?.editableFields
      .filter(({ isProtected }) => !isProtected)
      .map(({ objectMapId }) => String(objectMapId));

    setDraggableItems(customFields);
  }, [profile?.editableFields]);

  useEffect(() => {
    getAllProfileEditableData();
    getOwnerTypes();
    getAllEnums(OBJECT_TYPE_ENUM.PROFILE);
  }, [getAllEnums, getAllProfileEditableData, getOwnerTypes]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  /**
   * Drag handler for the Field Values
   * @param event
   * @param move
   * @returns void
   */
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const section = profile?.editableFields.find(
        ({ objectMapId }) => String(objectMapId) === String(active.id)
      );

      const sectionId = section?.customFieldSectionId;
      const newFieldIndex = draggableItems.indexOf(over.id);

      updateFieldOrder(active.id, sectionId, newFieldIndex, PROFILE_SOURCE, OBJECT_TYPE_ENUM.PROFILE);

      setDraggableItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const { from, to } = route;
  const splitFrom = from.split('/').filter((value) => !!value);
  const isSameRoute = from === to;

  const headerRoute = {
    upName: isSameRoute ? 'Profile' : capitalizeFirstLetter(splitFrom[0]),
    upPath: isSameRoute ? '/profile' : from,
  };

  const pageHeaderProps = {
    title: 'Edit User Fields',
    upName: headerRoute.upName,
    upPath: headerRoute.upPath,
  };

  return (
    <div id="edit-fields" className="pb-5">
      <PageHeader {...pageHeaderProps} />

      <PageDetails>This will customize fields for all internal users.</PageDetails>

      <InformationSection>
        <div className="header">
          <h3>Information</h3>

          <ActionContainer>
            {!isChangesSaving && typeof isChangesSaving != 'boolean' ? null : Boolean(isChangesSaving) ? (
              <span className="saving">Saving...</span>
            ) : Boolean(hasServerError) ? (
              <span className="error">Unsaved changes</span>
            ) : (
              <span>Changes auto-saved.</span>
            )}

            <Button onClick={handleShow} variant="primary">
              <FontAwesomeIcon icon={faPlus} className="mr-1" /> Add fields
            </Button>
          </ActionContainer>
        </div>

        <div className="default-content">
          {defaultFields?.map(({ objectMapId }) => (
            <EditableField key={objectMapId} id={objectMapId} />
          ))}

          {draggableItems.length > 0 && <Divider />}

          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={draggableItems} strategy={verticalListSortingStrategy}>
              {draggableItems?.map((id) => (
                <EditableField key={id} id={id} />
              ))}
            </SortableContext>
          </DndContext>
        </div>

        <FormikContext.Provider value={formikBag}>
          <FieldModal
            show={!!showAddFieldModal}
            onHide={handleHide}
            variation={CUSTOM_FIELDS_MODAL_VARIATIONS.PROFILE}
          />
        </FormikContext.Provider>
      </InformationSection>
    </div>
  );
};

export default Profile;
