import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Col, ProgressBar } from 'react-bootstrap';
import CanvasItem from '../../components/CanvasItem';
import { ModalAssignCanvas } from '../../components/Modals/Customers/AssignCanvas';
import EditCanvasStartDateModal from '../../components/Modals/Customers/CanvasStartDate';
import TableHeader from '../../components/TableHeader';
import TableRow from '../../components/TableRow';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Summary from './Summary';
import CustomerContext from 'stores/Customer/customerContext';
import FullContext from 'stores/Full/fullContext';

const ProgressBarValue = styled.span`
  color: var(--champion-charcoal);
  font-size: rem(14);
  font-weight: 400;
  margin-right: 8px;
`;

const StatusIndicator = styled.span`
  color: ${(props) => (props.status === 'Past Due' ? 'var(--danger)' : 'var(--champion-charcoal)')};
`;

function CustomerCanvases({ customerId, canvases = [], setCustomer, assignCanvases, setAssignCanvases }) {
  const { summaryData, externalData, canvasForDateEdit, onHideDateModal, callUpdateStartDate } =
    useContext(CustomerContext);
  const { isExternal } = useContext(FullContext);
  const [isExpanded, setIsExpanded] = useState([]);

  useEffect(() => {
    setIsExpanded(() => {
      if (isExternal) {
        return Object.keys(externalData).length
          ? externalData.projects?.map((data) => data.status === 'Active')
          : [];
      }
      return canvases.length ? canvases.map((data) => data.progress !== 100) : [];
    });
  }, [externalData, canvases, isExternal]);

  const getProjectData = (id) => {
    return isExternal
      ? externalData?.projects?.find((data) => data.customerCanvasId === id)
      : summaryData?.filter((data) => data.id === id)[0];
  };

  const getProgress = (id) => {
    const projectData = getProjectData(id);
    const progress = (projectData && projectData[isExternal ? 'progress' : 'completionPercentage']) ?? null;
    return progress;
  };

  const getStatus = (id) => {
    const status = getProjectData(id)?.status;
    return status;
  };

  return (
    <>
      {canvases.length ? (
        <>
          <TableHeader>
            <Col md={4}>Project</Col>
            <Col md={3}>Step In Progress</Col>
            <Col md={2}>Status</Col>
            <Col md={2}>Progress</Col>
            <Col md={1}></Col>
          </TableHeader>
          {canvases?.map((customerCanvas, index) =>
            isExternal ? (
              <Fragment key={customerCanvas.id}>
                <TableRow className="customer-canvas">
                  <Col md={4} className="text-break canvas-content-wrapper canvas-item">
                    <CanvasItem
                      to={`/accounts/${customerId}/projects/${customerCanvas.id}`}
                      enableSubItems={true}
                      canvas={customerCanvas}
                      showSummary={isExpanded[index]}
                      setShowSummary={() =>
                        setIsExpanded((prev) => {
                          return prev.map((state, i) => (index === i ? !state : state));
                        })
                      }
                    ></CanvasItem>
                  </Col>
                  <Col md={3}>
                    {Boolean(customerCanvas?.currentStep) ? (
                      <Link
                        className="font-weight-bold"
                        to={`/accounts/${customerId}/projects/${customerCanvas.id}/steps/${customerCanvas.currentStep.customerTouchpointId}`}
                      >
                        {customerCanvas.currentStep.name}
                      </Link>
                    ) : (
                      '--'
                    )}
                  </Col>
                  <Col md={2}>
                    <StatusIndicator status={getStatus(customerCanvas.id)}>
                      {getStatus(customerCanvas.id)}
                    </StatusIndicator>
                  </Col>
                  <Col md={2} className="d-flex align-items-center">
                    <span className="mr-2 bold small-info">{getProgress(customerCanvas.id)}%</span>
                    <ProgressBar className="flex-fill" now={getProgress(customerCanvas.id)} />
                  </Col>
                </TableRow>
                <Summary canvas={customerCanvas} isExpanded={isExpanded[index]} />
              </Fragment>
            ) : (
              <Fragment key={customerCanvas.id}>
                <TableRow key={customerCanvas.id} className="customer-canvas">
                  <Col md={4} className="text-break canvas-content-wrapper canvas-item">
                    <CanvasItem
                      to={`/accounts/${customerId}/projects/${customerCanvas.id}`}
                      enableSubItems={true}
                      canvas={customerCanvas}
                      showSummary={isExpanded[index]}
                      setShowSummary={() =>
                        setIsExpanded((prev) => {
                          return prev.map((state, i) => (index === i ? !state : state));
                        })
                      }
                    />
                  </Col>
                  <Col md={3}>
                    {Boolean(customerCanvas.currentTouchpointName) ? (
                      <Link
                        className="font-weight-bold"
                        to={`/accounts/${customerId}/projects/${customerCanvas.id}/steps/${customerCanvas.currentTouchpointId}`}
                      >
                        {customerCanvas.currentTouchpointName}
                      </Link>
                    ) : (
                      '--'
                    )}
                  </Col>
                  <Col md={2}>
                    <StatusIndicator status={getStatus(customerCanvas.id)}>
                      {getStatus(customerCanvas.id)}
                    </StatusIndicator>
                  </Col>
                  <Col md={2} className="d-flex align-items-center">
                    <ProgressBarValue>{getProgress(customerCanvas.id)}%</ProgressBarValue>
                    <ProgressBar className="flex-fill" now={getProgress(customerCanvas.id)} />
                  </Col>
                </TableRow>
                <Summary canvas={customerCanvas} isExpanded={isExpanded[index]} />
              </Fragment>
            )
          )}
        </>
      ) : (
        <div className="small-info">No projects</div>
      )}

      <EditCanvasStartDateModal
        customerCanvas={canvasForDateEdit}
        onHide={onHideDateModal(null)}
        onSave={callUpdateStartDate}
      />

      <ModalAssignCanvas
        show={assignCanvases}
        onHide={() => setAssignCanvases(false)}
        customerId={customerId}
        setCustomer={setCustomer}
      />
    </>
  );
}

export default CustomerCanvases;
