import { useEffect, useState } from 'react';
import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
} from '../LargePadding';
import Content from './Content';

export default function ModalMultiButton({
  show,
  onHide,
  title,
  firstButtonHandler,
  firstButtonLoading,
  firstButtonText,
  firstButtonVariant,
  secondButtonHandler,
  secondButtonLoading,
  secondButtonText,
  secondButtonVariant,
  message,
}) {
  const [loading, setLoading] = useState(false);
  const [unmounted, setUnmounted] = useState(false);

  useEffect(() => {
    return () => {
      setUnmounted(true);
    };
  }, []);

  async function handleFirstButton() {
    setLoading(true);
    await firstButtonHandler();
    if (!unmounted) {
      setLoading(false);
    }
  }

  async function handleSecondButton() {
    setLoading(true);
    await secondButtonHandler();
    if (!unmounted) {
      setLoading(false);
    }
  }

  return (
    <ModalLargePadding
      show={show}
      onHide={onHide}
      content={
        <div className="text-center">
          <h1 className="mb-3">{title}</h1>
          <Content message={message} />
        </div>
      }
      buttons={
        <>
          <ModalLargePaddingCancelButton onClick={onHide} autoWidth />

          <ModalLargePaddingConfirmButton
            onClick={handleFirstButton}
            variant={firstButtonVariant}
            loading={firstButtonLoading || loading}
            text={firstButtonText}
            autoWidth
          />

          <ModalLargePaddingConfirmButton
            onClick={handleSecondButton}
            variant={secondButtonVariant}
            loading={secondButtonLoading || loading}
            text={secondButtonText}
            autoWidth
          />
        </>
      }
    />
  );
}
