import { useState, useRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import useOutsideClickNotifier from 'helpers/hooks/useOutsideClickNotifier';
import { forwardRef } from 'react';

const Wrapper = styled.div`
  position: relative;
  text-align: left;
  & > svg {
    opacity: ${(props) => (props.notActive ? 0.5 : 1)};
  }
`;

const TextBox = styled.div`
  color: var(--champion-charcoal);
  font-size: 16px;
  font-weight: 400;
  line-height: 21.82px;
  position: absolute;
  z-index: ${(props) => (props.zIndex >= 0 ? props.zIndex : 1)};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  width: ${(props) => props.width};
  padding: 12px 20px;
  background: #fff;
  border: 1px solid var(--border-gray);
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  white-space: pre-line;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  margin-right: 12px;

  &:hover {
    cursor: help;
  }
`;

function InfoTextBox(props, ref) {
  const {
    children,
    width,
    notActive,
    show = false,
    disableOutsideClick = false,
    isInfoHoverable = false,
    top = '-100px',
    left = '20px',
    zIndex = 1,
  } = props;

  const [showTextBox, setShowTextBox] = useState(show);
  const clickRef = useRef(null);

  useOutsideClickNotifier(clickRef, () => {
    if (disableOutsideClick) return;

    setShowTextBox(false);
  });

  useImperativeHandle(ref, () => ({
    toggleTextBox: () => {
      setShowTextBox((prev) => !prev);
    },
    showTextBox: () => {
      setShowTextBox(true);
    },
    hideTextBox: () => {
      setShowTextBox(false);
    },
  }));

  return (
    <Wrapper ref={clickRef} notActive={notActive}>
      {!ref && (
        <InfoIcon
          icon={faInfoCircle}
          onClick={(event) => {
            event.stopPropagation();
            setShowTextBox(!showTextBox);
          }}
        />
      )}

      {showTextBox && (
        <TextBox
          width={width}
          top={top}
          left={left}
          onMouseEnter={() => isInfoHoverable && setShowTextBox(true)}
          onMouseLeave={() => isInfoHoverable && setShowTextBox(false)}
          zIndex={zIndex}
        >
          {children}
        </TextBox>
      )}
    </Wrapper>
  );
}

export default forwardRef(InfoTextBox);
