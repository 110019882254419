import React, { useContext, useEffect, useState } from 'react';
import { Body, Container, Header } from './SharedStyles';
import InfoTextBox from 'components/Modals/Shared/InfoTextBox';
import styled from 'styled-components';
import CustomerContext from 'stores/Customer/customerContext';
import { Link } from 'react-router-dom';

const EndHeader = styled.div`
  text-align: end;
  display: flex;
  font-size: 14px;
`;

const ToDoSection = ({ canvasId }) => {
  const { externalData } = useContext(CustomerContext);
  const [todoData, setTodoData] = useState(null);

  useEffect(() => {
    const getTodoData = () => {
      if (Object.keys(externalData).length) {
        const data = externalData.projects.find((data) => data.customerCanvasId === canvasId);
        setTodoData(data ?? null);
      }
    };
    getTodoData();
  }, [externalData, canvasId]);

  return (
    <Container className="ml-3">
      <Header className="d-flex">
        <div className="mr-auto">To Do</div>
        <EndHeader>
          <span className="mr-1">Tasks vs Steps</span>
          <InfoTextBox notActive={false} width={'411px'} top={'25px'} left={'-385px'}>
            Steps are cards within the project view and tasks are checklist items within a step.
          </InfoTextBox>
        </EndHeader>
      </Header>
      <Body>
        <div>
          <div className="font-weight-bold">
            {' '}
            {todoData?.externalTodoList?.totalTasksDueNext7Days} Tasks Due In The Next 7 Days
          </div>
          {todoData?.externalTodoList?.totalStepsWithTasksDue > 0 && (
            <div>
              <div>Mark each task complete in the following links.</div>
              {todoData?.externalTodoList?.stepsDue.map((data, index) => (
                <div className="ml-2">
                  {`${index + 1}. `}
                  <strong>
                    <Link
                      to={`/accounts/${externalData?.customerId}/projects/${todoData?.customerCanvasId}/steps/${data.id}`}
                    >
                      {data.name}
                    </Link>
                  </strong>
                </div>
              ))}
            </div>
          )}
          <div>
            <Link to={`/accounts/${externalData?.customerId}/projects/${todoData?.customerCanvasId}`} className='mr-1'>
              <strong>
                View all
              </strong>
            </Link>
            of your{' '}
            {todoData?.externalTodoList?.totalStepsWithTasksDue > 0
              ? `${todoData?.externalTodoList?.totalStepsWithTasksDue} `
              : ''}
            steps in the project canvas
          </div>
        </div>
      </Body>
    </Container>
  );
};

export default ToDoSection;
