import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState, useContext } from 'react';
import { Avatar } from './Modals/Avatar';
import ModalAddMembers from './Modals/Touchpoint/AddMembers/AddMembers';
import ModalEditMembers from './Modals/Touchpoint/EditMembers';
import PropTypes from 'prop-types';
import FullContext from 'stores/Full/fullContext';
import toast from './toast';
import { FormikContext } from 'formik';
import { useContacts } from 'views/Customer/hooks';
import MoreInfoModal from './Modals/Customers/MoreInfoContact';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { CUSTOM_FIELDS_MODAL_VARIATIONS } from 'constants';
import { getFormattedUsersList } from 'helpers/CommonHelper';

function Members({
  title,
  members,
  canView,
  canAdd,
  onAddMembers,
  onRemoveMember,
  showEditMembers,
  onSetShowEditMembers,
  onStartAssign,
  scope,
  customerCanvasId,
  customerId,
  canvasTags,
  rightAlign,
  isExternalInviteEnabled,
}) {
  const { isViewAsCustomer } = useContext(FullContext);
  const { fetchInitialContactsData, fetchInitialProjectsData } = useContext(CustomFieldsContext);

  const { formikBag, showAddContactModal, handleShow, handleHide, setCustomerId } = useContacts();

  const [addMembersInitialized, setAddMembersInitialized] = useState(false);
  const [editMembersInitialized, setEditMembersInitialized] = useState(false);
  const [showingAddMembers, setShowingAddMembers] = useState(false);
  const [showingEditMembers, setShowingEditMembers] = useState(false);
  const [filteredMembers, setFilteredMembers] = useState(members);
  const [hasExcess, setHasExcess] = useState(false);

  useEffect(() => {
    fetchInitialProjectsData();

    if (showAddContactModal) {
      fetchInitialContactsData();
      setCustomerId(customerId);
    }
  }, [showAddContactModal, fetchInitialProjectsData, fetchInitialContactsData, setCustomerId, customerId]);

  // Update local if parent changes
  useEffect(() => {
    setShowingEditMembers(showEditMembers);
    if (showEditMembers) {
      setEditMembersInitialized(true);
    }
  }, [showEditMembers]);

  useEffect(() => {
    if (members && members.length > 0) {
      const formattedUserList = getFormattedUsersList(members);

      setFilteredMembers(formattedUserList);
    }
  }, [members]);

  useEffect(() => {
    const membersWrapper = document.getElementById(`members-${scope}`);
    const membersWrapperHeight = membersWrapper.clientHeight;
    const membersWrapperWidth = membersWrapper.clientWidth;
    const wrapperChildren = membersWrapper.children;
    if (wrapperChildren?.length <= 1) return;

    const avatarHeight = wrapperChildren[0].clientHeight;
    const avatarWidth = wrapperChildren[0].clientWidth;
    const avatarGap = avatarHeight * 0.2;
    const maxWrapperHeight = avatarHeight * 2 + avatarGap;

    if (membersWrapperHeight > maxWrapperHeight) {
      const maxAvatarPerRow = Math.floor(membersWrapperWidth / avatarWidth);
      const membersLen = wrapperChildren.length;
      const ADD_MEMBER_ICON = 1;
      const EXCESS_ICON = 1;
      const MAX_ROW = 2;
      const maxAvatars = maxAvatarPerRow * MAX_ROW - ADD_MEMBER_ICON - EXCESS_ICON;
      const excess = membersLen - ADD_MEMBER_ICON - maxAvatars;

      const _filteredMembers = filteredMembers.length > 0 ? filteredMembers.slice(0, maxAvatars) : [];
      if (!hasExcess) {
        _filteredMembers.push({ initials: `+${excess}` });
        setHasExcess(true);
        setFilteredMembers(_filteredMembers);
      }
    }
  }, [filteredMembers, hasExcess, scope]);

  function showInviteNewMember() {
    setShowingAddMembers(false);
    handleShow();
  }

  async function handleAddMembers(data) {
    await onAddMembers(data);
    setShowingAddMembers(false);
    // so the add members list will get initialized again on next add
    setAddMembersInitialized(false);
  }

  async function handleRemoveMember(data) {
    await onRemoveMember(data);
    setAddMembersInitialized(false);
  }

  function handleStartAssign(data) {
    setShowEditMembers(false);
    onStartAssign(data);
  }

  function setShowEditMembers(show) {
    if (onSetShowEditMembers) {
      // Update parent if needed. Will update locally due to useEffect.
      onSetShowEditMembers(show);
    } else {
      setShowingEditMembers(show);
    }
  }

  const handleClickView = () => {
    setEditMembersInitialized(true);
    setShowEditMembers(true);
  };

  const handleClickAdd = () => {
    if (isViewAsCustomer) {
      toast.error('Please exit customer view to do this.');
      return;
    }

    setShowEditMembers(false);
    setAddMembersInitialized(true);
    setShowingAddMembers(true);
  };

  return (
    <>
      {title && <h2 className="title mb-1-5">{title}</h2>}
      <div id={`members-${scope}`} className={`members-wrapper ${rightAlign ? 'justify-content-end' : ''}`}>
        {filteredMembers?.length > 0 &&
          filteredMembers?.map((member, index) => (
            <Avatar
              key={member?.id || index}
              user={member}
              onClick={canView ? handleClickView : undefined}
              backgroundColor={
                member?.role === 'EXTERNAL' || !!member?.initials
                  ? 'var(--implement-iron)'
                  : 'var(--action-anchor)'
              }
            />
          ))}
        {canAdd && (
          <div className="content">
            <div className="avatar-circle light cursor-pointer" onClick={handleClickAdd}>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
        )}
      </div>
      {addMembersInitialized && (
        <ModalAddMembers
          show={showingAddMembers}
          onHide={() => setShowingAddMembers(false)}
          customerCanvasId={customerCanvasId}
          addMembers={handleAddMembers}
          showInviteNewMember={showInviteNewMember}
          canvasTags={canvasTags}
          members={members}
          scope={scope}
        />
      )}
      {editMembersInitialized && (
        <ModalEditMembers
          show={showingEditMembers}
          onHide={() => setShowEditMembers(false)}
          customerId={customerId}
          customerCanvasId={customerCanvasId}
          members={members}
          showAddMembers={handleClickAdd}
          removeMember={handleRemoveMember}
          startAssign={onStartAssign && handleStartAssign}
          isExternalInviteEnabled={isExternalInviteEnabled}
        />
      )}
      {showAddContactModal && (
        <FormikContext.Provider value={formikBag}>
          <MoreInfoModal
            show={showAddContactModal}
            handleHide={handleHide}
            variation={CUSTOM_FIELDS_MODAL_VARIATIONS.INVITE_NEW}
            addMembers={handleAddMembers}
            canvasTags={canvasTags}
            canAddToAllTouchpoints={scope === 'canvas'}
          />
        </FormikContext.Provider>
      )}
    </>
  );
}

Members.propTypes = {
  title: PropTypes.string,
  scope: PropTypes.oneOf(['canvas', 'touchpoint', 'task']).isRequired,
  canView: PropTypes.bool,
  canAdd: PropTypes.bool,
};

export default Members;
