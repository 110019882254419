import { useContext } from 'react';
import DateChangeContext from '../context/dateChangeContext';
import { DueDateHooks } from '../hooks';
import { Touchpoint } from 'models';
import DueDateHeader from './DueDateHeader';
import DueDateDropdowns from './DueDateDropdowns';
import ScheduledTypeDropdown from './ScheduledTypeDropdown';
import NoDueDate from './NoDueDate';
import DueDateFooter from './DueDateFooter';

// Added default Touchpoint initialization for showing model methods
const DueDate = (props = { touchpoint: new Touchpoint() }) => {
  const { dynamicTimeline, dependentData, touchpoint, onCanvasUpdate } = props;
  const { noDueDate } = useContext(DateChangeContext);
  const {
    isNewForecastedEndDateSame,
    formattedForecastedEndDate,
    formattedNewForecastedEndDate,
    dependentStepTitle,
    showChangedDate,
    didExceed,
    dateSelected,
    handleScheduledDateChange,
  } = DueDateHooks(props);

  return (
    <>
      <DueDateHeader noDueDate={noDueDate} touchpoint={touchpoint} />
      <DueDateDropdowns
        dependentStepTitle={dependentStepTitle}
        dependentData={dependentData}
        touchpoint={touchpoint}
      />
      <div>
        <ScheduledTypeDropdown
          touchpoint={touchpoint}
          dateSelected={dateSelected}
          handleScheduledDateChange={handleScheduledDateChange}
        />
        <NoDueDate touchpoint={touchpoint} />
        <DueDateFooter
          isNewForecastedEndDateSame={isNewForecastedEndDateSame}
          formattedForecastedEndDate={formattedForecastedEndDate}
          formattedNewForecastedEndDate={formattedNewForecastedEndDate}
          dynamicTimeline={dynamicTimeline}
          touchpoint={touchpoint}
          showChangedDate={showChangedDate}
          didExceed={didExceed}
          onCanvasUpdate={onCanvasUpdate}
        />
      </div>
    </>
  );
};

export default DueDate;
