import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as CommonHelper from '../../helpers/CommonHelper';

export default function EditCard({ onItemAdd, type }) {
  const [name, setName] = useState('');

  const handleOnChangeName = (event) => setName(event.target.value);

  const addItem = async () => name && (await onItemAdd(name));

  return (
    <>
      <div className="card-wrapper">
        {type === 'step' && CommonHelper.getIconInfo().icon}
        <div className="cursor-pointer card-content card-width">
          <Form.Control
            className="input-card"
            value={name}
            placeholder={`New ${type === 'stage' ? 'phase' : type}...`}
            onChange={handleOnChangeName}
            onBlur={addItem}
            onKeyPress={(event) => event.key === 'Enter' && addItem()}
            autoFocus
          />
        </div>
      </div>
      {type === 'stage' && (
        <div className="card-wrapper">
          <Button onClick={() => addItem} className="card-width" variant="light">
            <FontAwesomeIcon className="mr-2" icon={faCheck} />
            <span>Confirm</span>
          </Button>
        </div>
      )}
    </>
  );
}
