import React from 'react';
import Select from '../../../../components/Select';
import useOwners from '../../../../helpers/hooks/useOwners';
import ErrorText from '../ErrorText';
import InputContainer from './ActionInputContainer';
import ActionContainer from './ActionContainer';

/**
 * Renders action to assign an owner to the account created/updated
 * @property {object} props.action Action object type
 * @property {object} props.errors Errors in the form
 * @property {object} props.touched Indicates if elements in the form were touched
 * @property {object} props.onActionInputChange callback to execute when input changes
 * @returns {JSX.Element}
 */
const AssignOwnerAction = ({ action = {}, errors = {}, touched = {}, onActionInputChange = () => {} }) => {
  const { ownerOptions } = useOwners();
  const actionInput = action?.actionInput || {};
  const { ownerId } = actionInput;

  const ASSIGN_OWNER_TEMPLATE = {
    ownerId: 0,
  };

  /**
   * Function to handle input change
   * @param {object} opt object with label and value for the selected item
   * @returns {void}
   */
  const handleActionChange = (opt) => {
    const ownerId = opt.value;
    const newInput = {
      ...ASSIGN_OWNER_TEMPLATE,
      ...actionInput,
      ownerId,
    };
    onActionInputChange(newInput);
  };

  return (
    <ActionContainer>
      <div className="action-label">Assign Account Owner</div>
      <InputContainer>
        <Select
          placeholder="Select owner..."
          containerWidth={250}
          options={ownerOptions}
          onChange={handleActionChange}
          value={ownerOptions.find((user) => user.value === ownerId)}
        />
        {touched.actionInput && errors.ownerId ? <ErrorText>{errors.ownerId}</ErrorText> : null}
      </InputContainer>
    </ActionContainer>
  );
};

export default AssignOwnerAction;
