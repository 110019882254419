import styled from 'styled-components';

export const DueDescriptionContainer = styled.div`
  color: ${({ isOrange }) => isOrange ? 'var(--orange-bright)' : 'var(--action-anchor)'};
  font-weight: 700;
  font-size: 11px;
  margin-bottom: ${({ hasBottomMargin }) => hasBottomMargin ? '18px' : 0};
`;

export const TagsContainer = styled.div`
  margin-bottom: ${({ noBottomMargin }) => noBottomMargin ? '-12px' : '13px'};
`;