import React, { useCallback, useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { Modal, Form, Tabs as TabsComponent, Tab, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/pro-solid-svg-icons';
import { useFormik } from 'formik';
import RuleBuilder from './RuleBuilder';
import ActivityLog from './ActivityLog';
import {
  RULES_FORM_FIELDS,
  LIVE_RULE_VALIDATION_SCHEMA,
  SAVE_BUTTON_TYPE,
} from '../../../constants/automationRules';
import toast from '../../toast';
import LoadingInline from '../../Loading/LoadingInline';
import * as AutomationApi from '../../../api/automationRules';
import ErrorText from './ErrorText';
import FullContext from 'stores/Full/fullContext';
import { noop } from 'helpers/CommonHelper';

const CircledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: linear-gradient(90deg, #ff85ca 0%, #fe317d 100%);
  svg {
    color: #ffffff;
  }
`;

const Header = styled(Modal.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem 2rem;
`;

const RuleNameContaier = styled.div`
  display: flex;
  align-items: center;
`;

const RuleNameFormGroup = styled(Form.Group)`
  margin-left: 1.3rem;
  margin-bottom: 0;
  position: relative;
`;

const RuleNameInput = styled(Form.Control)`
  width: 18rem;
`;

const RuleNameText = styled.div`
  font-size: 1.8rem;
  color: var(--champion-charcoal);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;

  ${({ hasError }) =>
    hasError &&
    css`
      width: 18rem;
      height: calc(1.5em + 1rem + 2px);
      padding: 0.1rem 1rem;
      font-size: 1.25rem;
      line-height: 1.5;
      border-radius: 0.3rem;
      border: 1px solid var(--danger);
      padding-right: calc(1.5em + 0.75rem) !important;
      background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23ff4a52%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23ff4a52%27 stroke=%27none%27/%3e%3c/svg%3e');
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    `}

  @media (min-width: 600px) {
    max-width: 18rem;
  }
  @media (min-width: 1024px) {
    max-width: 25rem;
  }
  @media (min-width: 1281px) {
    max-width: 35rem;
  }
`;

const RuleNameFormFeedback = styled(Form.Control.Feedback)`
  position: absolute;
`;

const HeaderRightSideContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  > * {
    margin-right: 1rem;
  }
`;

const LiveToggleContainer = styled.div`
  position: absolute;
  right: 0;
  top: 1rem;
  display: flex;
  font-size: 1rem;
  margin-right: 3rem;
  b {
    margin-right: 10px;
    color: ${(props) => (!props.isValid ? 'var(--playbook-pewter)' : '')};
  }
`;

const Body = styled(Modal.Body)`
  position: relative;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 1rem;
`;

const Tabs = styled(TabsComponent)`
  .nav-link {
    padding: 1rem 2rem;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0;
`;

const BlankName = styled.div`
  color: var(--playbook-pewter);
  font-size: 29px;
`;

const Switch = styled(Form.Check)`
  .custom-control-input:disabled ~ .custom-control-label::before {
    border: solid var(--icon-light-gray) 1px !important;
  }
  .custom-control-input:disabled ~ .custom-control-label::after {
    background-color: var(--icon-light-gray) !important;
  }
  .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: var(--white);
  }
`;

/**
 * Automation Rule Form
 * @property {object} props.show Indicates if modal show be visible
 * @property {object} props.rule Selected rule
 * @property {object} props.loading Indicates if form is loading
 * @property {object} props.onHide callback to execute when modal closes
 * @property {object} props.fetchAutomationRules fetches all rules
 * @returns {JSX.Element}
 */
const AutomationRuleForm = ({
  show = false,
  rule = {},
  loading = false,
  onHide = noop,
  fetchAutomationRules = noop,
}) => {
  const loggedUser = useContext(FullContext);
  const [editName, setEditName] = useState(false);
  const ownerId = loggedUser?.userData?.id;
  const actionsInitialValues = rule?.automationActionResponses?.length
    ? rule.automationActionResponses.map((action) => ({
        id: action.id,
        actionName: action.actionName,
        actionType: action.actionType,
        orderNo: action?.orderNo ? String(action.orderNo) : 1,
        actionInput: action?.actionInput ? JSON.parse(action.actionInput) : {},
        actionOutput: action.actionOutput || '',
      }))
    : [];

  const initialValues = {
    [RULES_FORM_FIELDS.name]: rule.name || '',
    [RULES_FORM_FIELDS.isLive]: rule.isLive,
    [RULES_FORM_FIELDS.ownerId]: rule.ownerId || 0,
    [RULES_FORM_FIELDS.conditionLogicalOperator]: rule.conditionLogicalOperator || 'true',
    [RULES_FORM_FIELDS.orderNo]: rule.orderNo || 1,
    [RULES_FORM_FIELDS.sourceCategory]: rule.sourceCategory || '',
    [RULES_FORM_FIELDS.triggerType]: rule.triggerType,
    [RULES_FORM_FIELDS.conditions]: rule.automationConditionResponses || [],
    [RULES_FORM_FIELDS.actions]: actionsInitialValues,
    [RULES_FORM_FIELDS.saveButtonType]: SAVE_BUTTON_TYPE.live,
    notAllowedActionForTriggerType: '',
  };

  /**
   * Handles rule submition to the API
   * @param {object} values form values
   * @param {object} formikUtils utilities from formik
   * @returns {void}
   */
  const submitRule = useCallback(
    async (values, { resetForm }) => {
      try {
        const payload = {
          name: values.name || `Untitled rule ${Date.now()}`,
          isLive: Boolean(values.isLive),
          orderNo: String(values.orderNo),
          triggerType: values.triggerType,
          conditionLogicalOperator: String(values.conditionLogicalOperator),
          ownerId,
          sourceCategory: values.sourceCategory,
          automationConditionRequestList: values.conditions.map((condition) => ({
            conditionType: condition.conditionType,
            orderNo: String(condition.orderNo),
            leftOperand: condition.leftOperand,
            leftOperandType: condition.leftOperandType,
            rightOperand: condition.rightOperand?.value ?? condition.rightOperand,
            logicalOperator: condition.logicalOperator,
          })),
          automationActionRequestList: values.actions.map((action) => ({
            actionName: action.actionName,
            actionType: action.actionType,
            orderNo: action?.orderNo ? String(action.orderNo) : 1,
            actionInput: action?.actionInput ? JSON.stringify(action.actionInput) : '{}',
            actionOutput: '',
          })),
        };

        if (!!rule?.id) {
          await AutomationApi.updateAutomationRule(rule.id, payload);
        } else {
          await AutomationApi.createAutomationRule(payload);
        }

        fetchAutomationRules();
        onHide();
        resetForm();
      } catch (err) {
        toast.error("Coudn't load automation rules");
      }
    },
    [fetchAutomationRules, onHide, ownerId, rule.id]
  );

  const form = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: submitRule,
    validationSchema: LIVE_RULE_VALIDATION_SCHEMA,
  });

  const {
    values = {},
    errors = {},
    touched = {},
    isSubmitting,
    setSubmitting,
    setFieldValue,
    handleChange,
    handleSubmit,
    resetForm,
  } = form;

  const isFormValid = LIVE_RULE_VALIDATION_SCHEMA.isValidSync(values);
  const canActivateRule = values.isLive || isFormValid;

  const closeModal = () => {
    resetForm();
    onHide();
    setEditName(false);
  };

  const handleSaveRule = () => {
    setFieldValue(RULES_FORM_FIELDS.saveButtonType, SAVE_BUTTON_TYPE.draft);
    handleSubmit();
  };

  const handleSaveDraft = async () => {
    setSubmitting(true);
    await submitRule(values, { resetForm });
    setSubmitting(false);
  };

  useEffect(() => {
    // If rule is not valid, turn off "live" toggle
    if (!isFormValid) {
      setFieldValue(RULES_FORM_FIELDS.isLive, false);
    }
  }, [isFormValid, setFieldValue]);

  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="xl"
      backdrop="static" // avoids closing modal when clicking outside
      aria-labelledby="create-new-rule-modal"
    >
      {loading ? (
        <LoadingContainer>
          <LoadingInline white />
        </LoadingContainer>
      ) : (
        <>
          <Header>
            <RuleNameContaier>
              <CircledIconContainer>
                <FontAwesomeIcon icon={faCogs} size="sm" />
              </CircledIconContainer>
              <RuleNameFormGroup onClick={() => setEditName(true)}>
                {!editName && (
                  <>
                    <RuleNameText
                      title={values.name}
                      hasError={touched[RULES_FORM_FIELDS.name] && errors[RULES_FORM_FIELDS.name]}
                    >
                      {values.name}
                      {!Boolean(values.name) && <BlankName>Name rule...</BlankName>}
                    </RuleNameText>
                    {touched[RULES_FORM_FIELDS.name] && errors[RULES_FORM_FIELDS.name] && (
                      <ErrorText>{errors[RULES_FORM_FIELDS.name]}</ErrorText>
                    )}
                  </>
                )}
                {editName && (
                  <RuleNameInput
                    id={RULES_FORM_FIELDS.name}
                    name={RULES_FORM_FIELDS.name}
                    value={values.name}
                    type="text"
                    size="lg"
                    autoFocus={editName}
                    placeholder="Rule name"
                    onChange={handleChange}
                    onBlur={() => setEditName(false)}
                    isInvalid={touched[RULES_FORM_FIELDS.name] && !!errors[RULES_FORM_FIELDS.name]}
                  />
                )}

                <RuleNameFormFeedback type="invalid">Required</RuleNameFormFeedback>
              </RuleNameFormGroup>
            </RuleNameContaier>
            <HeaderRightSideContainer>
              <Button variant="outline-light" onClick={closeModal}>
                Cancel
              </Button>
              {values[RULES_FORM_FIELDS.saveButtonType] === SAVE_BUTTON_TYPE.live && (
                <Button variant="primary" disabled={isSubmitting} onClick={handleSaveRule}>
                  {isSubmitting ? <LoadingInline white /> : <span>Save</span>}
                </Button>
              )}
              {values[RULES_FORM_FIELDS.saveButtonType] === SAVE_BUTTON_TYPE.draft && (
                <Button variant="primary" disabled={isSubmitting} onClick={handleSaveDraft}>
                  {isSubmitting ? <LoadingInline white /> : <span>Save as draft</span>}
                </Button>
              )}
            </HeaderRightSideContainer>
          </Header>
          <Body>
            <LiveToggleContainer isValid={canActivateRule}>
              <b>Live</b>
              <Switch
                type="switch"
                id={RULES_FORM_FIELDS.isLive}
                name={RULES_FORM_FIELDS.isLive}
                checked={values.isLive}
                onChange={(e) => setFieldValue(RULES_FORM_FIELDS.isLive, e.target.checked)}
                disabled={!canActivateRule}
              />
            </LiveToggleContainer>
            <Tabs defaultActiveKey="rule" id="automation-rule-tabs">
              <Tab eventKey="rule" title="Rule">
                <RuleBuilder rule={rule} form={form} />
              </Tab>
              {Boolean(rule.id) && (
                <Tab eventKey="activity-log" title="Activity Log">
                  <ActivityLog ruleId={rule.id} />
                </Tab>
              )}
            </Tabs>
          </Body>
        </>
      )}
    </Modal>
  );
};

export default AutomationRuleForm;
