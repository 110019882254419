import { useState, useEffect, useRef, useContext } from 'react';
import * as Api from '../../api/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TableWrapperStyle,
  TableTitleStyle,
  TableStyle,
  TableDataStyle,
  TableDataConditional,
  NoDataTextStyle,
  StyledTr,
} from './SharedStyles';
import TableHeader from './TableHeader';
import { sortByLetter, sortByNumber } from './sorting';
import ReportingContext from './context/reportingContext';
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import HeaderFilter from './HeaderFilter';
import styled from 'styled-components';
import { SessionStorage } from 'models';
import { ACTIVE_PHASE } from 'constants/sessionStorage';
import { TextButton } from 'views/Customer/Styles';

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--schedule-sapphire);
`;

const VerticalSlash = styled.span`
  color: var(--action-anchor);
`;

export default function PhasePerformanceTable({ showTable, setShowTable }) {
  const [sortedColumn, setSortedColumn] = useState('Phase');
  const [showFilters, setShowFilters] = useState(false);
  const [phaseData, setPhaseData] = useState([]);
  const {
    activeTemplate,
    activeTemplateTitle,
    selectActivePhase,
    activeTimeRangeFilters,
    activeAccountFilters,
    startDate,
    endDate,
  } = useContext(ReportingContext);
  const table = useRef();

  useEffect(() => {
    let accountFilters = '';
    if (activeAccountFilters?.length > 0) {
      activeAccountFilters.forEach((filter) => {
        accountFilters += `&clientTypes=${filter}`;
      });
    }
    const prepareSort = (data) => {
      const sortedData = data.sort((a, b) => b?.name.localeCompare(a?.name));
      setPhaseData(sortedData);
    };
    const getPhaseData = async () => {
      const res = await Api.get(
        `Report/GetJourneyPerformance?startDate=${startDate}&endDate=${endDate}${accountFilters}${
          activeTemplate && `&canvasIds=${activeTemplate}`
        }`
      );
      prepareSort(res);
    };

    if (activeTemplate) {
      getPhaseData();
    }
  }, [activeAccountFilters, activeTemplate, endDate, startDate, activeTemplateTitle]);

  return (
    <TableWrapperStyle width="half">
      <TableTitleStyle onClick={setShowTable}>
        <span className="title">
          <StyledIcon icon={showTable ? faAnglesUp : faAnglesDown} className="mr-2" />
          Phase Performance by Template
        </span>
        {(activeTimeRangeFilters.length > 0 || activeAccountFilters.length > 0) && (
          <HeaderFilter setShowFilters={setShowFilters} showFilters={showFilters} />
        )}
      </TableTitleStyle>
      {showTable && (
        <TableStyle ref={table}>
          <thead>
            <StyledTr>
              <TableHeader
                sortedColumn={sortedColumn}
                setSortedColumn={setSortedColumn}
                table={table}
                sortByFunction={sortByLetter}
                sortByDefault={true}
                flexBasis={24}
              >
                Phase
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                setSortedColumn={setSortedColumn}
                table={table}
                sortByFunction={sortByNumber}
                flexBasis={28}
              >
                Avg &nbsp;|&nbsp; Median Completion Time
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                setSortedColumn={setSortedColumn}
                table={table}
                sortByFunction={sortByNumber}
                flexBasis={24}
              >
                Template Completion Time
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                setSortedColumn={setSortedColumn}
                table={table}
                sortByFunction={sortByNumber}
                flexBasis={24}
              >
                Dropout Rate
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                setSortedColumn={setSortedColumn}
                table={table}
                sortByFunction={sortByNumber}
                flexBasis={24}
              >
                Action
              </TableHeader>
            </StyledTr>
          </thead>
          <tbody>
            {phaseData?.length > 0 ? (
              phaseData.map((item) => (
                <tr key={item?.id}>
                  <TableDataStyle flexBasis={24} id="phaseLink">
                    {item?.name}
                  </TableDataStyle>
                  <TableDataStyle flexBasis={28}>
                    <TableDataConditional
                      danger={item?.averageActualCompletionTimeInDays > item?.targetCompletionInDays}
                    >
                      {item?.averageActualCompletionTimeInDays}
                    </TableDataConditional>
                    &nbsp;<VerticalSlash>&nbsp;|&nbsp;</VerticalSlash>&nbsp;
                    <TableDataConditional
                      danger={item?.medianActualCompletionTimeInDays > item?.targetCompletionInDays}
                    >
                      {item?.medianActualCompletionTimeInDays}
                    </TableDataConditional>
                    &nbsp;days
                  </TableDataStyle>
                  <TableDataStyle flexBasis={24}>
                    {item?.targetCompletionInDays} {item?.targetCompletionInDays > 1 ? 'days' : 'day'}
                  </TableDataStyle>
                  <TableDataStyle flexBasis={24} danger={+item?.dropOutRate > 0}>
                    {item?.dropOutRate}%
                  </TableDataStyle>
                  <TableDataStyle flexBasis={24} className="font-weight-bold">
                    <TextButton
                      color="var(--schedule-sapphire)"
                      onClick={() => {
                        SessionStorage.set(ACTIVE_PHASE, JSON.stringify(item));
                        selectActivePhase(item);
                      }}
                    >
                      View Step
                    </TextButton>
                  </TableDataStyle>
                </tr>
              ))
            ) : phaseData.length === 0 && activeTemplate !== '' ? (
              <tr>
                <td className="w-full">
                  <NoDataTextStyle>There is no available phase data for this template.</NoDataTextStyle>
                </td>
              </tr>
            ) : (
              <tr>
                <td className="w-full">
                  <NoDataTextStyle>Choose a template above</NoDataTextStyle>
                </td>
              </tr>
            )}
          </tbody>
        </TableStyle>
      )}
    </TableWrapperStyle>
  );
}
