import React, { Component } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import toast from '../../components/toast';
import * as Api from '../../api/Api';
import PageHeader from '../../components/PageHeader';
import SettingsContext from 'stores/Settings/settingsContext';
import ModalConfirm from 'components/Modals/Confirm';
import { DeleteTokenText } from './styles';
import { SETTINGS_PERMISSIONS } from 'constants';
import General from './General';
import Integrations from './Integrations';
import Email from './Email/Email';
import { TabSelector } from 'views/CustomFields/Account/Styles';
import { Settings as SettingsModel } from 'models';
import Billing from './Billing/Billing';

class Settings extends Component {
  static contextType = SettingsContext;
  refreshAppSettings = this.props.refreshAppSettings;

  state = {
    appSettings: null,
    loading: true,
    logoLoading: false,
    saving: false,
    tokenForDelete: null,
    isSalesforceEnabled: this.context.salesforceEnabled,
    isHubspotEnabled: this.context.salesforceEnabled,
    isJiraEnabled: false,
    integration: [],
    permissions: {},
    defaultKey: window.location.hash || (this.props.isAtleastAdmin ? '#general' : '#email'),
    activeKey: window.location.hash || (this.props.isAtleastAdmin ? '#general' : '#email'),
  };

  async componentDidMount() {
    await this.loadAppSettings();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.hasToken !== this.props.hasToken) {
      this.loadAppSettings();
    }
  }

  updateTrainingDependencies = (key, newValue) => {
    if (key === 'trainingComplete' && !newValue) {
      this.updateAppSettings('trainingInAppAlerts', false);
      this.updateAppSettings('trainingEmailAlerts', false);
    }

    if (
      !this.state.appSettings.trainingComplete &&
      (key === 'trainingInAppAlerts' || key === 'trainingEmailAlerts') &&
      newValue
    ) {
      this.updateAppSettings('trainingComplete', true);
    }
  };

  showError(message) {
    toast.error(message);
  }

  saveAppSettings = async () => {
    if (this.state.saving) {
      return;
    }
    this.setState({ saving: true });
    await Api.post('Settings/UpdateAppSettings', this.state.appSettings);
    this.setState({ saving: false });
    toast.saved('Settings saved successfully!');
    this.props.changeBrandImage();
  };

  updateAppSettings = (key, value) => {
    this.setState((prevState) => ({
      appSettings: {
        ...prevState.appSettings,
        [key]: value,
      },
    }));

    if (key === 'trainingComplete' || key === 'trainingInAppAlerts' || key === 'trainingEmailAlerts') {
      this.updateTrainingDependencies(key, value);
    }
  };

  inputValueChanged = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.updateAppSettings(name, value);
  };

  onLogoDrop = async (event) => {
    this.setState({ logoLoading: true });
    const response = await Api.postFiles('Settings/UploadAppSettingsLogo', event.target.files, {
      rawResponse: true,
    });
    const data = await response.json();
    if (response.status === 400) {
      toast.error(data);
    } else {
      this.updateAppSettings('logo', data.filePath);
    }
    this.setState({ logoLoading: false });
    this.fileInput.value = null;
  };

  loadAppSettings = async () => {
    const data = await Api.get('Settings/GetAppSettings');
    this.refreshAppSettings(data);
    const list = [];
    await this.loadPermissions();
    this.setState({ appSettings: data, integration: list, loading: false });
  };

  /**
   * Retrieves the permissions granted according to user's role
   */
  loadPermissions = async () => {
    const data = await SettingsModel.getSettingsPermissions();
    this.getDefaultActiveKey(data);

    this.setState({
      permissions: { ...data },
    });
  };

  autocompleteChanged(event) {
    const target = event.target;
    const value = target.checked;

    let updated = this.state.editCanvas;

    updated['autocomplete'] = value;

    this.setState({
      editCanvas: updated,
    });
  }

  autoadvanceChanged(event) {
    const target = event.target;
    const value = target.checked;

    let updated = this.state.editCanvas;

    updated['autoadvance'] = value;

    this.setState({
      editCanvas: updated,
    });
  }

  /**
   * This handler sets the state of the first non-hidden tab of the settings page
   * @param {Object} permissions - contains the permissions per tab
   */
  getDefaultActiveKey = (permissions) => {
    if (Object.keys(permissions).length > 0) {
      for (const key in permissions) {
        if (permissions[key] !== SETTINGS_PERMISSIONS.NONE) {
          if (window.location.hash) {
            this.setState({
              defaultKey: `#${window.location.hash.substring(1)}`,
              activeKey: `#${window.location.hash.substring(1)}`,
            });
          } else {
            this.setState({
              defaultKey: `#${key}`,
              activeKey: `#${key}`,
            });
          }

          break;
        }
      }
    }
  };

  handleLoadBillingTab = () => {
    this.setState({
      activeKey: '#billing',
    });
  };

  handleTabSelect = (selectedTab) => {
    this.setState({
      activeKey: `${selectedTab}`,
    });
  };

  render() {
    return (
      <>
        <PageHeader title="Settings" loading={this.state.loading} />

        {!this.state.loading && (
          <>
            <Tab.Container
              defaultActiveKey={this.state.defaultKey}
              activeKey={this.state.activeKey}
              onSelect={this.handleTabSelect}
            >
              <Col>
                <Row className="d-block">
                  <Nav variant="tabs">
                    <TabSelector>
                      {this.props.isAtleastAdmin &&
                        this.state.permissions?.general !== SETTINGS_PERMISSIONS.NONE && (
                          <Nav.Item>
                            <Nav.Link eventKey="#general" href="#general">
                              General
                            </Nav.Link>
                          </Nav.Item>
                        )}
                      {this.props.isAtleastAdmin &&
                        this.state.permissions?.integration !== SETTINGS_PERMISSIONS.NONE && (
                          <Nav.Item>
                            <Nav.Link eventKey="#integrations" href="#integrations">
                              Integrations
                            </Nav.Link>
                          </Nav.Item>
                        )}
                      {this.state.permissions?.email !== SETTINGS_PERMISSIONS.NONE && (
                        <Nav.Item>
                          <Nav.Link eventKey="#email" href="#email">
                            Email
                          </Nav.Link>
                        </Nav.Item>
                      )}
                      {this.props.isAtleastAdmin &&
                        this.state.permissions?.billing === SETTINGS_PERMISSIONS.READWRITE && (
                          <Nav.Item>
                            <Nav.Link eventKey="#billing" href="#billing">
                              Billing
                            </Nav.Link>
                          </Nav.Item>
                        )}
                    </TabSelector>
                  </Nav>
                </Row>

                <Row className="d-block">
                  <Tab.Content>
                    <Tab.Pane eventKey="#general">
                      <General
                        appSettings={this.state.appSettings}
                        changeBrandImage={() => this.props.changeBrandImage()}
                      />
                    </Tab.Pane>
                  </Tab.Content>

                  <Tab.Content>
                    <Tab.Pane eventKey="#integrations">
                      <Integrations
                        appSettings={this.state.appSettings}
                        isAtleastAdmin={this.props.isAtleastAdmin}
                        setTokenForDelete={(tokenForDelete) => this.setState({ tokenForDelete })}
                        setIntegrationState={this.setIntegrationState}
                        handleLoadBillingTab={this.handleLoadBillingTab}
                      />
                    </Tab.Pane>
                  </Tab.Content>

                  <Tab.Content>
                    <Tab.Pane eventKey="#email">
                      <Email
                        isAdmin={this.props.isAdmin}
                        appSettings={this.state.appSettings}
                        handleLoadBillingTab={this.handleLoadBillingTab}
                      />
                    </Tab.Pane>
                  </Tab.Content>

                  <Tab.Content>
                    <Tab.Pane eventKey="#billing" className="m-0">
                      <Billing loadAppSettings={this.loadAppSettings} />
                    </Tab.Pane>
                  </Tab.Content>
                </Row>
              </Col>
            </Tab.Container>
            <ModalConfirm
              title="Delete token"
              message={
                <>
                  <DeleteTokenText>Are you sure you want to delete this token?</DeleteTokenText>
                  <DeleteTokenText>Any instance of this token in use will stop functioning.</DeleteTokenText>
                </>
              }
              show={!!this.state.tokenForDelete}
              onConfirm={() => {
                this.context.onDeleteApiToken(this.state.tokenForDelete);
                this.setState({ tokenForDelete: null });
              }}
              onHide={() => {
                this.setState({ tokenForDelete: null });
              }}
            />
          </>
        )}
      </>
    );
  }
}

export default Settings;
