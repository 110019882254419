import React from 'react';
import { useContext } from 'react';
import { Col } from 'react-bootstrap';
import FullContext from 'stores/Full/fullContext';
import styled from 'styled-components';
import PageHeader from '../components/PageHeader';
import TableHeader from '../components/TableHeader';
import TableRow from '../components/TableRow';
import * as CommonHelper from '../helpers/CommonHelper';

const UnreadWrapper = styled.span`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

export default function Notifications({ notifications, readNotification }) {
  const { isExternal } = useContext(FullContext);
  const getNotificationLink = (notification) => {
    if (
      notification.notificationType === 'CommentTag' ||
      notification.notificationType === 'TouchpointDeadline'
    ) {
      return {
        pathname: `/accounts/${notification.customerId}/projects/${notification.customerCanvasId}/steps/${notification.touchpointId}`,
      };
    } else if (
      notification.notificationType === 'CustomerAssigned' ||
      // TODO: Ideally BadTouchpointEmailConfiguration would link to the step like CommentTag, but...
      // CommentTag gets the correct CustomerCanvasId from a source not available in this case.
      notification.notificationType === 'BadTouchpointEmailConfiguration'
    ) {
      return {
        pathname: `/accounts/${notification.customerId}`,
      };
    }
  };

  const NotificationList = () => {
    if (notifications === null) {
      // loading
      return <></>;
    } else if (notifications.length === 0) {
      return <div className="small-info">You have no notifications right now.</div>;
    }
    const anyUnread = notifications.some((item) => item.notificationStatus === 'Unread');
    return (
      <>
        <TableHeader>
          <Col md={2}>Account</Col>
          <Col md={2}>Step</Col>
          <Col md={2}>From</Col>
          <Col md={2}>Type</Col>
          <Col md={4}>Message</Col>
        </TableHeader>

        {notifications.map((item, index) => (
          <TableRow
            key={'notif-dropdown-item-' + index}
            to={getNotificationLink(item)}
            onClick={() => readNotification(item)}
          >
            <Col md={2}>
              {anyUnread && (
                <UnreadWrapper isVisible={item.notificationStatus === 'Unread'} className="dot" />
              )}
              {item.customerName}
            </Col>
            <Col md={2}>{item.touchpointTitle}</Col>
            <Col md={2}>
              {item.notificationType === 'TouchpointDeadline' ? item.customerName : item.senderName}
            </Col>
            <Col md={2}>
              {item.notificationType === 'TouchpointDeadline'
                ? 'Past Due'
                : item.notificationType === 'CustomerAssigned'
                ? 'Assigned'
                : item.notificationType === 'BadTouchpointEmailConfiguration'
                ? 'Error'
                : '@Direct'}
            </Col>
            <Col md={4}>{item.message}</Col>
          </TableRow>
        ))}
      </>
    );
  };

  const pageHeaderProps = {
    title: 'Notifications',
    loading: !notifications,
    upName: isExternal ? 'Project' : 'Dashboard',
    upPath: isExternal ? CommonHelper.getExternalProject() || '/404' : '/dashboard',
  };

  return (
    <>
      <PageHeader {...pageHeaderProps} />
      <NotificationList />
    </>
  );
}
