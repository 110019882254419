import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { shortDate } from 'helpers/DateHelper';
import { getDocumentInfo } from 'helpers/CommonHelper';
import Resource from 'components/Modals/Touchpoint/Resource';
import { NoDataTextStyle, TableDataStyle, TableStyle } from '../reporting/SharedStyles';
import { sortByDate, sortByLetter } from '../reporting/sorting';
import TableHeader from '../reporting/TableHeader';

const CustomerResources = ({ resources, customerId, resourcesEmptyMessage }) => {
  const table = useRef();

  const [sortedColumn, setSortedColumn] = useState('');

  /**
   * Identify the role of the user who uploaded the attachment
   * @param role
   * @returns user role
   */
  const getUserRole = (role) => (role === 'EXTERNAL' ? 'Client Upload' : 'Resource');

  /**
   * Header data values
   */
  const headerData = [
    {
      flexBasis: 20,
      sortByFunction: sortByLetter,
      text: 'Name',
    },
    {
      flexBasis: 15,
      sortByFunction: sortByLetter,
      text: 'Project',
    },
    {
      flexBasis: 15,
      sortByFunction: sortByLetter,
      text: 'Step',
    },
    {
      flexBasis: 5,
      sortByFunction: sortByDate,
      text: 'Date added',
    },
    {
      flexBasis: 10,
      sortByFunction: sortByLetter,
      text: 'Type',
    },
  ];

  /**
   * Table data values
   * @param resource
   * @returns bodyData array
   */
  const bodyData = (resource) => {
    const { attachment, project, step } = resource;

    const resourceIcon =
      attachment?.type === 'Document'
        ? getDocumentInfo(attachment.name)
        : { icon: faLink, className: 'color-icon-light-gray' };

    const stepLink = `/accounts/${customerId}/projects/${project.id}/steps/${step.id}`;

    return [
      {
        link: true,
        flexBasis: 20,
        content: (
          <Resource
            key={`file - ${attachment.name}`}
            iconInfo={resourceIcon}
            name={attachment.name}
            href={attachment.url}
            hasBottomMargin={false}
            linkStyle={{
              color: 'color-sapphire',
              fontWeight: 'font-weight-bold',
            }}
          />
        ),
      },
      {
        flexBasis: 15,
        content: project.name,
      },
      {
        flexBasis: 15,
        link: true,
        content: <Link to={stepLink}>{step.name}</Link>,
      },
      {
        flexBasis: 5,
        content: shortDate(new Date(attachment.dateAdded)),
      },
      {
        flexBasis: 10,
        content: getUserRole(attachment.addedBy.role),
      },
    ];
  };

  return (
    <TableStyle ref={table} type="secondary">
      <thead>
        <tr>
          {headerData.map((item, index) => (
            <TableHeader
              key={index}
              sortedColumn={sortedColumn}
              table={table}
              flexBasis={item.flexBasis}
              sortByFunction={item.sortByFunction}
              setSortedColumn={setSortedColumn}
              type="secondary"
            >
              {item.text}
            </TableHeader>
          ))}
        </tr>
      </thead>

      <tbody>
        {resources && resources?.length > 0 ? (
          resources.map((resource, outerIndex) => (
            <tr key={outerIndex} className="table-row no-hover">
              {bodyData(resource).map((item, innerIndex) => (
                <TableDataStyle
                  key={innerIndex}
                  type="secondary"
                  flexBasis={item.flexBasis}
                  link={item?.link}
                >
                  {item.content}
                </TableDataStyle>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td className="w-full">
              <NoDataTextStyle>{resourcesEmptyMessage}</NoDataTextStyle>
            </td>
          </tr>
        )}
      </tbody>
    </TableStyle>
  );
};

export default CustomerResources;
