const CustomerReducer = (state, action) => {
  switch (action.type) {
    case 'populate_account_data': {
      return {
        ...state,
        accountData: action.payload,
      };
    }

    case 'populate_summary_data': {
      return {
        ...state,
        summaryData: action.payload,
      };
    }

    case 'populate_external_data': {
      return {
        ...state,
        externalData: action.payload,
      };
    }

    case 'update_canvas_for_date_edit': {
      return {
        ...state,
        canvasForDateEdit: action.payload,
      };
    }

    case 'populate_customer_detail': {
      return {
        ...state,
        customerDetail: action.payload,
      };
    }

    case 'populate_customer_contact': {
      return {
        ...state,
        customerContact: action.payload,
      };
    }

    default:
      return state;
  }
};

export default CustomerReducer;
