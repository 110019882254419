import { useEffect, useReducer } from 'react';
import * as Api from 'api/Api';
import { SessionStorage } from 'models';
import ReportingContext from './reportingContext';
import ReportingReducer from './reportingReducer';
import { findDateDifference } from '../helpers';
import {
  ACCOUNT_FILTER,
  ACTIVE_PHASE,
  ACTIVE_TEMPLATE,
  ACTIVE_TEMPLATE_TITLE,
  TIME_FILTER,
} from 'constants/sessionStorage';

const ReportingState = ({ children }) => {
  const initialState = {
    accountFilters: [],
    activeAccountFilters: SessionStorage.get(ACCOUNT_FILTER)?.split(',') ?? [],
    activeTimeRangeFilters: SessionStorage.get(TIME_FILTER) ? [SessionStorage.get('timeFilter', true)] : [],
    activeTemplate: SessionStorage.get(ACTIVE_TEMPLATE) ?? '',
    activeTemplateTitle: SessionStorage.get(ACTIVE_TEMPLATE_TITLE) ?? null,
    activePhase: SessionStorage.get(ACTIVE_PHASE) ? SessionStorage.get(ACTIVE_PHASE, true) : null,
    startDate: new Date(2015, 0, 1).toISOString(),
    endDate: new Date().toISOString(),
  };

  const [state, dispatch] = useReducer(ReportingReducer, initialState);

  useEffect(() => {
    const getFilterData = async () => {
      const res = await Api.get('Customer/GetAllCustomers');
      if (res?.items) {
        let types = new Set();
        for (const item of res.items) {
          if (item.type?.trim()) {
            types.add(item.type);
          }
        }
        dispatch({
          type: 'populate_account_filters',
          payload: [...types],
        });
      }
    };
    getFilterData();
  }, []);

  const addActiveAccountFilter = (filter) => {
    dispatch({
      type: 'add_active_account_filter',
      payload: filter,
    });
  };

  const removeActiveAccountFilter = (filter) => {
    dispatch({
      type: 'remove_active_account_filter',
      payload: filter,
    });
  };
  const addActiveTimeRangeFilter = (filter) => {
    dispatch({
      type: 'add_active_time_range',
      payload: filter,
    });
  };

  const removeActiveTimeRangeFilter = (filter) => {
    dispatch({
      type: 'remove_active_time_range',
      payload: filter,
    });
  };

  const selectActiveTemplateId = (id, name) => {
    dispatch({
      type: 'set_active_template',
      payload: {
        id,
        name,
      },
    });
  };

  const selectActivePhase = ({ name, id }) => {
    dispatch({
      type: 'set_active_phase',
      payload:
        name && id
          ? {
              name,
              id,
            }
          : '',
    });
  };

  const setStartAndEndDates = (filter) => {
    if (filter?.label === 'This Month') {
      const today = new Date();
      dispatch({
        type: 'set_start_and_end_dates',
        payload: {
          start: new Date(today.getFullYear(), today.getMonth(), 1).toISOString(),
          end: new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString(),
        },
      });
    } else if (filter?.label === 'Last Month') {
      const today = new Date();
      dispatch({
        type: 'set_start_and_end_dates',
        payload: {
          start: new Date(today.getFullYear(), today.getMonth() - 1, 1).toISOString(),
          end: new Date(today.getFullYear(), today.getMonth(), 0).toISOString(),
        },
      });
    } else {
      dispatch({
        type: 'set_start_and_end_dates',
        payload: {
          start: findDateDifference(filter),
          end: new Date().toISOString(),
        },
      });
    }
  };

  return (
    <ReportingContext.Provider
      value={{
        accountFilters: state.accountFilters,
        activeAccountFilters: state.activeAccountFilters,
        activeTimeRangeFilters: state.activeTimeRangeFilters,
        activeTemplate: state.activeTemplate,
        activeTemplateTitle: state.activeTemplateTitle,
        activePhase: state.activePhase,
        startDate: state.startDate,
        endDate: state.endDate,
        addActiveAccountFilter,
        removeActiveAccountFilter,
        addActiveTimeRangeFilter,
        removeActiveTimeRangeFilter,
        selectActiveTemplateId,
        selectActivePhase,
        setStartAndEndDates,
      }}
    >
      {children}
    </ReportingContext.Provider>
  );
};

export default ReportingState;
