import React, { Fragment, useMemo, useCallback, useContext, useState, useEffect } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { FormSwitch } from 'components/Formik/FormSwitch';
import { FormDropdown } from 'components/Formik/FormDropdown';
import { USERS_FIELDS, PLACEHOLDERS, FORMIK_FIELD_TYPES, EDIT, SUPERUSER, ADMIN } from 'constants';
import { TextButton } from 'views/Customer/Styles';
import { Users } from 'models';
import { debounce, isDropDownType, noop } from 'helpers/CommonHelper';
import FullContext from 'stores/Full/fullContext';
import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
} from '../LargePadding';
import { FieldContainer } from '../CustomFields/Styles';
import FormField from '../Customers/FormField';
import { roles } from './fixtures';
import ModalConfirm from '../Confirm';
import { ScrollableContainer } from '../Customers/Styles';
import { AssignedContentContainer } from './Styles';

const ModalAddUser = ({ show, onHide, mode, setToDelete, setSaving, setHasError }) => {
  const { values, submitForm, isSubmitting, setFieldValue } = useFormikContext();
  const { userData, roleAtleast } = useContext(FullContext);

  const menuFooterLink = useCallback(
    (field) =>
      roleAtleast(ADMIN) && field?.externalDataField?.isCustomField
        ? `/profile/custom-fields/edit-profile-fields?field=${field.objectMapId}`
        : '',
    [roleAtleast]
  );

  const isLoggedInUser = useMemo(
    () => userData?.email === values?.user?.email,
    [userData?.email, values?.user?.email]
  );

  const [showModal, setShowModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [internalValue, setInternalValue] = useState(null);

  const userId = values?.userId ?? null;

  const isEdit = mode === EDIT;

  useEffect(() => {
    if (values.role) setInternalValue(values.role);
  }, [values.role]);

  useEffect(() => {
    (async () => {
      if (show && values.primaryUserId && isEdit) {
        const total = await Users.getTotalAccounts(String(values.primaryUserId));

        setFieldValue(USERS_FIELDS.TOTAL_ACCOUNTS, total);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, values.primaryUserId, isEdit]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce(async (field, id, value) => {
      try {
        setSaving(true);
        setHasError(false);

        const request = {
          field,
          value: field === USERS_FIELDS.ROLE ? value.response : value,
        };

        await Users.updateUser(id, request);

        setSaving(false);
      } catch (error) {
        setSaving(false);
        setHasError(true);
      }
    }, 500),
    []
  );

  const renderFieldArray = useCallback(
    (name) => (
      <FieldArray
        name={name}
        render={() => (
          <>
            {values[name].map((field, index) => {
              const isDropDown = isDropDownType(field?.fieldType);
              const formikName = `${name}[${index}].formikValue`;
              const _menuFooterLink = menuFooterLink(field);

              return (
                <Fragment key={field.objectMapId}>
                  <FieldContainer key={field.objectMapId}>
                    <FormField
                      menuFooterLink={_menuFooterLink}
                      field={field}
                      name={formikName}
                      isEdit={isEdit}
                      isDropDownLastItem={isDropDown && values[name].length - 1 === index}
                      setSaving={setSaving}
                      setHasError={setHasError}
                    />
                  </FieldContainer>
                </Fragment>
              );
            })}
          </>
        )}
      />
    ),
    [values, isEdit, setSaving, setHasError, menuFooterLink]
  );

  const handleBlur = useCallback(() => {
    if (!values.role && isEdit) setShowModal(true);
  }, [values.role, isEdit]);

  const renderContent = useMemo(() => {
    const roleOptions = isLoggedInUser ? roles : roles.filter(({ value }) => value !== SUPERUSER);

    return (
      <div>
        <h1 className="mb-3 text-center">{isEdit ? 'User' : 'New User'}</h1>
        <ScrollableContainer>
          {renderFieldArray(USERS_FIELDS.DEFAULT_FIELDS)}

          <FieldContainer>
            <FormDropdown
              type={FORMIK_FIELD_TYPES.SEARCHABLE}
              name={USERS_FIELDS.ROLE}
              searchableProps={{
                placeholder: PLACEHOLDERS.ROLE,
                handleChange: isEdit ? (value) => handleChange(USERS_FIELDS.ROLE, userId, value) : noop,
                handleBlur,
              }}
              fieldLabel="Role"
              isRequired
              disabled={isLoggedInUser}
              options={roleOptions}
            />
          </FieldContainer>

          {renderFieldArray(USERS_FIELDS.CUSTOM_FIELDS)}

          <FieldContainer className="mb-3">
            <FormSwitch
              fieldLabel="Default owner"
              name={USERS_FIELDS.IS_DEFAULT_OWNER}
              handleChange={
                isEdit
                  ? (value) => {
                      if (value) {
                        handleChange(USERS_FIELDS.OWNER, userId, value);
                        return;
                      }

                      setShowAssignModal(true);
                    }
                  : noop
              }
            />
          </FieldContainer>
        </ScrollableContainer>
      </div>
    );
  }, [handleBlur, handleChange, isEdit, isLoggedInUser, renderFieldArray, userId]);

  /**
   * Renders the buttons for the modal
   * @param none
   * @returns Button Component
   */
  const renderButtons = isEdit ? (
    <div
      onClick={() => {
        if (!values.isDefaultOwner) {
          setToDelete(values?.user);
          return;
        }

        setFieldValue(USERS_FIELDS.REMOVED_USER, values?.user);
        setShowAssignModal(true);
      }}
    >
      <TextButton color="var(--danger)">Delete user</TextButton>
    </div>
  ) : (
    <>
      <ModalLargePaddingCancelButton onClick={onHide} autoWidth />
      <ModalLargePaddingConfirmButton onClick={submitForm} text="Save" loading={isSubmitting} autoWidth />
    </>
  );

  const modalStyle = {
    inner: {
      maxWidth: 'unset',
      padding: '2rem 0 0 0 !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'var(--light-gray-bg)',
      borderRadius: '0 0 0.813rem 0.813rem',
      padding: '1.875rem 0 2rem 0 !important',
    },
  };

  const requiredModalStyle = {
    inner: {
      maxWidth: '33.563rem',
      padding: '2.5rem 1.25rem !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  const renderModalMessage = () => (
    <p>
      Please fill in a value for <b>Role</b>.
    </p>
  );

  const renderAssignContent = () => (
    <AssignedContentContainer>
      <p>
        {values?.removedUser
          ? `${values?.removedUser?.firstName} is the default account owner. Assign a new default owner before deleting ${values?.removedUser?.firstName}.`
          : `Assign a new default owner before removing ${values.user?.firstName}.`}{' '}
      </p>

      <FormDropdown
        type={FORMIK_FIELD_TYPES.SEARCHABLE}
        name={USERS_FIELDS.ASSIGNED_USER}
        searchableProps={{
          placeholder: 'New Default Owner',
          hasDefaultPlaceholder: true,
        }}
        options={values?.users?.filter(({ id }) => id !== userId)}
      />
    </AssignedContentContainer>
  );

  const handleModalClose = () => {
    setFieldValue(USERS_FIELDS.ROLE, internalValue);

    setShowModal(false);
  };

  const handleAssignUser = () => {
    handleChange(USERS_FIELDS.OWNER, values?.assignedUser?.response, true);

    setFieldValue(USERS_FIELDS.IS_DEFAULT_OWNER, false);
    setFieldValue(USERS_FIELDS.ASSIGNED_USER, null);
    setShowAssignModal(false);
  };

  return (
    <>
      <ModalLargePadding
        show={show}
        onHide={isEdit ? onHide : noop}
        innerProps={modalStyle.inner}
        buttonsContainerProps={modalStyle.buttonContainer}
        content={renderContent}
        buttons={renderButtons}
      />

      <ModalConfirm
        title="Required field"
        message={renderModalMessage()}
        show={!!showModal}
        hideCancel
        confirmText="Close"
        variant="primary"
        innerProps={requiredModalStyle.inner}
        buttonsContainerProps={requiredModalStyle.buttonContainer}
        autoButtonsWidth
        onConfirm={handleModalClose}
        onHide={() => setShowModal(false)}
      />

      <ModalConfirm
        title={values?.removedUser ? 'Default Account Owner' : 'New Default Owner'}
        message={renderAssignContent()}
        show={!!showAssignModal}
        confirmText="Assign"
        variant="primary"
        innerProps={requiredModalStyle.inner}
        buttonsContainerProps={requiredModalStyle.buttonContainer}
        autoButtonsWidth
        disabledConfirm={!values?.assignedUser}
        onConfirm={handleAssignUser}
        onHide={() => {
          setShowAssignModal(false);
          onHide();
        }}
      />
    </>
  );
};

export default ModalAddUser;
