import styled from 'styled-components';

export const Container = styled.div`
  background: var(--standard-snow);
  margin-bottom: 16px;
  filter: drop-shadow(0px 2px 2px var(--playbook-pewter));
`;

export const Header = styled.div`
  font-size: 20px;
  font-weight: 400;
  border-bottom: solid var(--goal-gray) 2px;
  padding: 16px;
`;

export const Body = styled.div`
  font-size: 16px;
  padding: 16px;
`;

export const GrayText = styled.div`
  color: var(--playbook-pewter);
`;
