import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const SectionContainer = styled.section`
  padding-inline: 1rem;
  min-height: 7.813rem;
  position: relative;

  h3 {
    color: var(--playbook-pewter);
  }

  .fa-enabled {
    cursor: pointer;
  }

  .fa-disabled {
    color: var(--goal-gray);
    cursor: not-allowed !important;
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  h3 {
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 19px;
  }

  .section-options {
    margin-left: auto;

    .fa-pen {
      margin-right: 0.875rem;
    }
  }
`;

export const SectionBody = styled.div`
  display: grid;
  ${({ columnCount }) => `
  grid-template-columns: repeat(${columnCount}, 1fr);
  max-width: ${columnCount === 1 ? '50%' : '100%'};
  `}
  row-gap: 2.25rem;
  column-gap: 3rem;
  padding: 0.625rem 0 1.25rem 1rem;

  .custom-field {
    &__header {
      display: flex;
      align-items: center;
      gap: 1rem;

      & > label {
        margin-bottom: 0;
      }
    }

    &__permission {
      font-size: 0.75rem;
      font-weight: 400;
    }

    &__options {
      display: flex;
      align-items: center;
      margin-left: auto;

      .lock-container,
      .eye-container {
        display: flex;
        flex-direction: column;
        position: relative;

        .fa-lock-keyhole.fa-enabled {
          color: var(--implement-iron);
        }
      }

      .hidden-block {
        position: absolute;
        height: 0.875rem;
        width: 0.875rem;
        background: transparent;
        top: 1rem;
      }

      .icon-container {
        display: flex;
        position: relative;
      }

      .tooltip-action {
        font-size: 1rem;
        font-weight: bold;
        color: var(--schedule-sapphire);
        margin-right: 0;
      }

      span {
        cursor: pointer;
      }

      span,
      svg:not(:last-child) {
        margin-right: 0.875rem;
      }

      p {
        margin: 0;

        span {
          font-weight: bold;
        }
      }
    }

    &__input {
      margin: 0.5rem 0 0 1.5rem;

      .react-select--is-disabled {
        svg {
          color: var(--outline);
        }
      }

      textarea:disabled,
      input:disabled {
        background-color: var(--white);
        color: var(--playbook-pewter);
      }
    }
  }

  @media only screen and (max-width: 630px) {
    grid-template-columns: 1fr;
    padding-left: 0.5rem;
  }
`;

export const RadioGroupContainer = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export const FlatPickrContainer = styled.div`
  display: flex;
  position: relative;

  & > .flatpickr-wrapper {
    width: 100%;
  }

  .svg-inline--fa {
    position: absolute;
    pointer-events: none;
    top: 0.625rem;
    right: 0.625rem;
    color: var(--schedule-sapphire);
  }

  .input {
    cursor: pointer;
  }
`;

export const DateTimeContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;

  & > div {
    width: 50%;
  }
`;

export const DeleteMessage = styled.p`
  text-align: center;
`;

export const CautionSection = styled.div`
  text-align: left;

  p {
    color: var(--orange-bright);
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }

  ul {
    padding-inline-start: 1.75rem;
  }
`;

export const GrayIcon = styled(FontAwesomeIcon)`
  color: var(--playbook-pewter);
`;

export const FieldCol = styled(Col)`
  ${({ isDisabled }) => {
    return !isDisabled
      ? `
      textarea: read-only,
      input: read-only {
        cursor: default;
        background-color: var(--white);
        color: var(--champion-charcoal);
      } 
        `
      : '';
  }}

  textarea:focus,
  input:focus {
    border-color: var(--border-gray);
    box-shadow: none;
  }
`;

export const MoreInfoFieldContainer = styled.div`
  &.date-time .form-control:disabled,
  &.date-time .form-control[readonly] {
    background-color: var(--light-gray-bg);
  }

  &.read-only {
    input,
    textarea,
    .select__placeholder,
    .select__single-value {
      color: var(--playbook-pewter);
    }

    input,
    textarea {
      background-color: var(--white);
    }

    .form-check {
      opacity: 30%;
    }

    svg {
      opacity: 30%;
    }
  }

  .is-edit {
    .error-container {
      display: none;
    }
  }
`;
