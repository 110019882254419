import styled from 'styled-components';

const RoundedButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $circle-icon-gray;
  border: none;
`;

export default RoundedButton;
