import { useReducer, useCallback, useEffect, useContext } from 'react';
import * as Api from 'api/Api';
import { userLoggedIn } from 'helpers/RouteHelper';
import { getOrgName, setAccessToken } from 'helpers/CommonHelper';
import AuthReducer from './authReducer';
import AuthContext from './authContext';
import FullContext from 'stores/Full/fullContext';

const AuthState = ({ children }) => {
  const initialState = {
    nylasToken: null,
    accessToken: null,
    apiToken: null,
    tokenExpires: null,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const { fetchUserData } = useContext(FullContext);

  // Temporary checking if the tenant is allowed to view the gmail inbox or not
  const isTenantAllowed = ['MostTesty', 'Google Verify'].includes(getOrgName());

  const populateAccessToken = useCallback((value) => {
    dispatch({
      type: 'populate_access_token',
      payload: value,
    });
  }, []);

  const removeAccessToken = useCallback(() => {
    dispatch({
      type: 'remove_access_token',
    });
  }, []);
  const connectEmail = useCallback((value) => {
    dispatch({
      type: 'connect_email',
      payload: value,
    });
  }, []);

  const disconnectEmail = useCallback(() => {
    dispatch({
      type: 'disconnect_email',
    });
  }, []);

  const getNylasToken = useCallback(async () => {
    const { emailSettings } = await Api.get('Account/GetUserSettings');

    return emailSettings?.nylasAccountToken;
  }, []);

  useEffect(() => {
    if (userLoggedIn(state.accessToken) && isTenantAllowed) {
      (async () => {
        const nylasToken = await getNylasToken();
        nylasToken ? connectEmail(nylasToken) : disconnectEmail();
      })();
    }
  }, [connectEmail, disconnectEmail, getNylasToken, isTenantAllowed, state.accessToken]);

  useEffect(() => {
    if (userLoggedIn(state.accessToken)) {
      fetchUserData();
      setAccessToken(state.accessToken);
    }
  }, [fetchUserData, state.accessToken]);

  return (
    <AuthContext.Provider
      value={{
        nylasToken: state.nylasToken,
        accessToken: state.accessToken,
        isTenantAllowed,
        populateAccessToken,
        connectEmail,
        disconnectEmail,
        getNylasToken,
        removeAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
