import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import styled from 'styled-components';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Api from '../../../api/Api';
import * as CommonHelper from '../../../helpers/CommonHelper';
import Files from '../Touchpoint/Files';
import Ideas from '../Touchpoint/Ideas';
import Actions from './Actions';
import Checklist from './Checklist';
import CustomerFacing from './CustomerFacing';
import EditTags from './EditTags';
import DateChangeState from '../Shared/DateChange/context/dateChangeState';
import EditDateModal from '../Shared/DateChange/components/EditDateModal';
import { DateHeader } from '../Shared/DateChange/styles';
import { CONTRIBUTOR } from 'constants';
import FullContext from 'stores/Full/fullContext';
import useAutosizeTextArea from 'helpers/hooks/useAutosizeTextArea';

const HeaderText = styled.span`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  margin-left: ${(props) => props.marginLeft};
`;

const StyledEdit = styled(FontAwesomeIcon)`
  cursor: pointer;
  font-size: 12px;
  margin-left: 5px;
`;

const DescriptionContainer = styled.div`
  margin-bottom: 1.75rem;
`;

const StatusIconContainer = styled.div`
  margin-right: 0.75rem;
  top: 0.75rem;
  position: relative;
`;

const CustomerFacingContainer = styled.div`
  top: 1.125rem;
  position: relative;
  margin-top: 1.5rem;
`;

export default function ModalEditTouchpoint({
  show,
  onHide,
  touchpoint,
  onFilesUpdated,
  onTagAdded,
  onTagEdited,
  onIdeasUpdated,
  onUpdateTouchpoint,
  onCanvasUpdate,
  getDependables,
  appSettings,
  onDeleteTag,
  onRemoveTag,
  allTouchpoints,
  dependentData,
}) {
  const iconInfo = CommonHelper.getIconInfo(touchpoint);
  const [title, setTitle] = useState(touchpoint.title);
  const [description, setDescription] = useState(touchpoint.description || '');
  const [selectedTab, setSelectedTab] = useState('step');
  const [showEditDateModal, setShowEditDateModal] = useState(false);
  const [dependentStepTitle, setDependentStepTitle] = useState('');
  const history = useNavigate();
  const location = useLocation();
  const [touchpointChanges, setTouchpointChanges] = useState(null);
  const [mergedSteps, setMergedSteps] = useState([]);
  const { roleAtleast } = useContext(FullContext);

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  function handleTabSelect(selectedTab) {
    history({
      hash: selectedTab,
    });
  }

  useAutosizeTextArea(titleRef.current, title);
  useAutosizeTextArea(descriptionRef.current, description);

  useEffect(() => {
    setSelectedTab(location.hash ? location.hash.substring(1) : 'step');
  }, [location.hash]);

  useEffect(() => {
    const flattenedSteps = dependentData.map((item) => item.touchpoints).flat();

    setMergedSteps(flattenedSteps);
  }, [dependentData]);

  useEffect(() => {
    setTitle(touchpoint.title);
  }, [touchpoint.title]);

  useEffect(() => {
    if (touchpoint?.touchpointDependentId) {
      dependentData.forEach((item) => {
        const { touchpoints } = item;
        touchpoints.forEach((step) => {
          if (step?.id === touchpoint?.touchpointDependentId) {
            setDependentStepTitle(step?.title);
          }
        });
      });
    }
  }, [dependentData, touchpoint?.title, touchpoint?.touchpointDependentId]);

  const saveTouchpoint = useCallback(
    async (changes) => {
      // KLUDGE - This is a workaround to solve bug ACD-72. Some touchpoints were not getting mapped
      // by the API and showing up as NULL causing exceptions and 500 responses. This call was
      // sending back the whole touchpoint including the deep structure of dependent touchpoints
      // which is totally unnecessary for editing THIS ONE. So before calling the API, this will
      // make a deep copy of the payload and then we drop the dependents structure.

      let tempTouchpoint = JSON.parse(JSON.stringify(touchpoint));
      tempTouchpoint.touchpointDependents = [];
      const res = await Api.post('Touchpoint/UpdateTouchpoint', {
        ...tempTouchpoint,
        ...changes,
      });
      if (
        changes.durationAdded != null ||
        changes.durationOptionAmount ||
        changes.durationOption ||
        changes.dependencyAdded != null
      ) {
        onCanvasUpdate();
      }
      const shouldReload = !!changes.touchpointDependentId || !!changes.title;
      onUpdateTouchpoint(res, shouldReload);
    },
    [touchpoint, onUpdateTouchpoint, onCanvasUpdate]
  );

  useEffect(() => {
    if (touchpointChanges != null) {
      saveTouchpoint(touchpointChanges);
      setTouchpointChanges(null);
    }
    return () => setTouchpointChanges(null);
  }, [touchpointChanges, saveTouchpoint]);

  const updateTouchpointFields = (fields) => {
    setTouchpointChanges({
      ...touchpointChanges,
      ...fields,
    });
  };

  const updateTasks = (tasks) => {
    onUpdateTouchpoint({
      ...touchpoint,
      tasks,
    });
  };

  async function addTag(newTag) {
    onTagAdded(touchpoint.id, [...(touchpoint.touchpointTags || []), newTag]);
  }

  const handleStepNameUpdate = useRef(
    CommonHelper.debounce((value) => {
      if (!value || value === touchpoint?.title) return;

      updateTouchpointFields({ title: CommonHelper.getUniqueName(mergedSteps, value) });
    }, 500)
  );

  const handleStepDescriptionUpdate = useRef(
    CommonHelper.debounce((value) => {
      if (value === touchpoint?.description) return;

      updateTouchpointFields({ description: value });
    }, 500)
  );

  function stepTab() {
    return (
      <Row className="mr-md-0">
        <Col md={8} className="settings-left-section pb-4">
          <Checklist touchpoint={touchpoint} onUpdated={updateTasks} />
        </Col>
        <Col md={4} className="settings-right-section negative-gutter-md-down">
          <Files
            title="Resources"
            links={touchpoint.touchpointLinks || []}
            attachments={touchpoint.touchpointAttachments || []}
            touchpoint={touchpoint}
            canEdit={true}
            isDraggable={true}
            onUpdated={onFilesUpdated}
            hideFileUpload={false}
          />
        </Col>
      </Row>
    );
  }

  return (
    // enforceFocus=false fixes a bug with tinymce losing focus (specifically the link dialog) inside bootstrap modals
    <Modal size="xl" show={show} onHide={onHide} className="modal-full" enforceFocus={false}>
      <div className="d-flex justify-content-between align-items-center">
        <HeaderText color="var(--playbook-pewter)" fontWeight="bold" fontSize="14px">
          Edit Step
        </HeaderText>
        {touchpoint?.noDueDate && (
          <HeaderText
            color="var(--orange-bright)"
            fontWeight="bold"
            fontSize="12px"
            marginLeft="20px"
            role={'button'}
            onClick={() => setShowEditDateModal(true)}
          >
            DUE BY END OF PROJECT
          </HeaderText>
        )}
        {(touchpoint?.dateToBeScheduled ||
          (dependentStepTitle && touchpoint?.touchpointDependentId) ||
          (!touchpoint?.noDueDate && touchpoint?.touchpointDependentId === null)) && (
          <DateHeader>
            {!touchpoint?.noDueDate && touchpoint?.dateToBeScheduled && 'TO BE SCHEDULED'}
            <>
              {!touchpoint?.noDueDate && touchpoint?.dateToBeScheduled && (
                <span className="separator">|</span>
              )}
              {!touchpoint?.noDueDate && dependentStepTitle && touchpoint?.touchpointDependentId && (
                <span>
                  DUE {touchpoint?.durationOptionAmount === 0 ? 'SAME' : touchpoint?.durationOptionAmount}{' '}
                  {touchpoint?.durationOptionAmount === 1 || touchpoint?.durationOptionAmount === 0
                    ? touchpoint?.durationOption.slice(0, -1)
                    : touchpoint?.durationOption}{' '}
                  {touchpoint?.durationOptionAmount === 0 ? 'AS' : 'AFTER'} {dependentStepTitle}
                </span>
              )}
              {!touchpoint?.noDueDate && touchpoint?.touchpointDependentId === null && (
                <span>
                  DUE {touchpoint?.durationOptionAmount === 0 ? 'SAME' : touchpoint?.durationOptionAmount}{' '}
                  {touchpoint?.durationOptionAmount === 1 || touchpoint?.durationOptionAmount === 0
                    ? touchpoint?.durationOption.slice(0, -1)
                    : touchpoint?.durationOption}{' '}
                  {touchpoint?.durationOptionAmount === 0 ? 'AS' : 'AFTER'} START OF THE PROJECT
                </span>
              )}
            </>
          </DateHeader>
        )}
        <StyledEdit icon={faPencil} onClick={() => setShowEditDateModal(true)} />
        {/* <DisplayDate touchpoint={touchpoint} dueDate={new Date(touchpoint?.displayDate)} setShowEditDateModal={setShowEditDateModal} /> */}
        <FontAwesomeIcon icon={faTimes} onClick={onHide} className="close-button clickable ml-auto" />
      </div>
      {showEditDateModal && (
        <DateChangeState
          touchpoint={touchpoint}
          updateTouchpoint={onUpdateTouchpoint}
          setShowEditDateModal={setShowEditDateModal}
        >
          <EditDateModal onCanvasUpdate={onCanvasUpdate} dependentData={dependentData} />
        </DateChangeState>
      )}

      <div className="mr-4">
        <div className="d-flex justify-content-between flex-column flex-md-row">
          <h1 className="mt-4 mb-1 font-weight-bold d-flex flex-grow-1">
            <StatusIconContainer>{iconInfo.icon}</StatusIconContainer>
            <Form.Control
              ref={titleRef}
              className="resizable-textarea ml-1"
              placeholder="Description..."
              as="textarea"
              value={title}
              onChange={(e) => {
                const { value } = e.target;
                setTitle(value);
                handleStepNameUpdate.current(value);
              }}
            />
          </h1>
          <CustomerFacingContainer>
            <CustomerFacing touchpoint={touchpoint} onUpdated={updateTouchpointFields} />
          </CustomerFacingContainer>
        </div>

        <DescriptionContainer>
          <Form.Control
            ref={descriptionRef}
            className="resizable-textarea"
            placeholder="Enter a step description..."
            as="textarea"
            value={description}
            onChange={(e) => {
              const { value } = e.target;
              setDescription(value);
              handleStepDescriptionUpdate.current(value);
            }}
          />
        </DescriptionContainer>

        <EditTags
          touchpointId={touchpoint.id}
          touchpointTags={touchpoint.touchpointTags || []}
          onAdded={addTag}
          onEdited={onTagEdited}
          onDeleted={onDeleteTag}
          onRemoved={onRemoveTag}
        />
      </div>
      <Tabs
        defaultActiveKey="step"
        id="touchpoint-tabs"
        className="mt-3"
        activeKey={selectedTab}
        onSelect={handleTabSelect}
      >
        <Tab eventKey="step" title="Step" className="mt-0">
          {stepTab()}
        </Tab>
        <Tab eventKey="actions" title="Email">
          {selectedTab === 'actions' && <Actions touchpoint={touchpoint} onSave={saveTouchpoint} />}
        </Tab>
        {appSettings?.employeeIdeas && roleAtleast(CONTRIBUTOR) && (
          <Tab eventKey="ideas" title="Ideas">
            {selectedTab === 'ideas' && (
              <Ideas
                touchpoint={touchpoint}
                touchpointIdeas={touchpoint.touchpointIdeas}
                onIdeasUpdated={onIdeasUpdated}
              />
            )}
          </Tab>
        )}
      </Tabs>
    </Modal>
  );
}
