export const USERS_FIELDS = {
  DEFAULT_FIELDS: 'defaultFields',
  CUSTOM_FIELDS: 'customFields',
  ROLE: 'role',
  IS_DEFAULT_OWNER: 'isDefaultOwner',
  OWNER: 'defaultOwner',
  ASSIGNED_USER: 'assignedUser',
  REMOVED_USER: 'removedUser',
  TOTAL_ACCOUNTS: 'totalAccounts',
};
