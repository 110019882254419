import React, { useCallback } from 'react';
import { FIELD_TYPES, FORMIK_FIELD_TYPES, PLACEHOLDERS } from 'constants';
import { FormInput } from 'components/Formik/FormInput';
import { FormSwitch } from 'components/Formik/FormSwitch';
import { FormRadioGroup } from 'components/Formik/FormRadioGroup';
import { FormPicker } from 'components/Formik/FormPicker';
import { FormDropdown } from 'components/Formik/FormDropdown';
import { timezoneList } from 'components/Modals/Customers/fixtures';
import { noop } from 'helpers/CommonHelper';

export const useEditableField = ({
  name,
  field,
  menuFooterLink,
  onChange = noop,
  onBlur = noop,
  onClear = noop,
  disabled,
  isModal = true,
  fieldRef,
  isDropDownLastItem,
}) => {
  const renderField = useCallback(() => {
    const { fieldType, fieldTypeOptions, fieldLabel, isRequired } = field;
    const renderFieldLabel = !isModal ? null : { fieldLabel };

    switch (fieldType) {
      case FIELD_TYPES.PARAGRAPH:
        return (
          <FormInput
            fieldRef={fieldRef}
            name={name}
            as="textarea"
            placeholder={PLACEHOLDERS.INPUT_TEXT}
            rows={3}
            disabled={disabled}
            handleChange={onChange}
            handleBlur={onBlur}
            isRequired={isRequired}
            {...renderFieldLabel}
          />
        );

      case FIELD_TYPES.YES_NO:
        return (
          <FormRadioGroup
            name={name}
            disabled={disabled}
            handleChange={onChange}
            isRequired={isRequired}
            {...renderFieldLabel}
          />
        );

      case FIELD_TYPES.EMAIL:
        return (
          <FormInput
            fieldRef={fieldRef}
            name={name}
            type="email"
            placeholder={PLACEHOLDERS.EMAIL}
            disabled={disabled}
            handleChange={onChange}
            handleBlur={onBlur}
            isRequired={isRequired}
            {...renderFieldLabel}
          />
        );

      case FIELD_TYPES.LINK:
        return (
          <FormInput
            fieldRef={fieldRef}
            name={name}
            type="text"
            placeholder={PLACEHOLDERS.LINK}
            disabled={disabled}
            handleChange={onChange}
            handleBlur={onBlur}
            isRequired={isRequired}
            {...renderFieldLabel}
          />
        );

      case FIELD_TYPES.SINGLE_DROPDOWN:
        return (
          <FormDropdown
            type={FORMIK_FIELD_TYPES.SEARCHABLE}
            isDropDownLastItem={isDropDownLastItem}
            name={name}
            disabled={disabled}
            isRequired={isRequired}
            searchableProps={{
              placeholder: PLACEHOLDERS.DROPDOWN,
              handleChange: onChange,
              handleBlur: onBlur,
              handleClear: onClear,
              menuFooterLink,
            }}
            options={fieldTypeOptions}
            {...renderFieldLabel}
          />
        );

      case FIELD_TYPES.MULTI_DROPDOWN:
        return (
          <FormDropdown
            type={FORMIK_FIELD_TYPES.SEARCHABLE}
            name={name}
            disabled={disabled}
            options={fieldTypeOptions}
            isRequired={isRequired}
            searchableProps={{
              isMulti: true,
              placeholder: PLACEHOLDERS.DROPDOWN,
              handleChange: onChange,
              handleBlur: onBlur,
              handleClear: onClear,
              menuFooterLink,
            }}
            isDropDownLastItem={isDropDownLastItem}
            {...renderFieldLabel}
          />
        );

      case FIELD_TYPES.TIMEZONE:
        return (
          <FormDropdown
            type={FORMIK_FIELD_TYPES.SEARCHABLE}
            name={name}
            disabled={disabled}
            options={timezoneList}
            isRequired={isRequired}
            searchableProps={{
              placeholder: PLACEHOLDERS.DROPDOWN,
              handleChange: onChange,
              handleBlur: onBlur,
              handleClear: onClear,
            }}
            isDropDownLastItem={isDropDownLastItem}
            {...renderFieldLabel}
          />
        );

      case FIELD_TYPES.NUMBER:
        return (
          <FormInput
            fieldRef={fieldRef}
            name={name}
            type="number"
            placeholder={PLACEHOLDERS.INPUT_NUMBER}
            disabled={disabled}
            handleChange={onChange}
            handleBlur={onBlur}
            isRequired={isRequired}
            {...renderFieldLabel}
          />
        );

      case FIELD_TYPES.CURRENCY:
        return (
          <FormInput
            fieldRef={fieldRef}
            name={name}
            type="number"
            inputType={FORMIK_FIELD_TYPES.INPUT_GROUP}
            prepend={<>$</>}
            placeholder={PLACEHOLDERS.INPUT_CURRENCY}
            disabled={disabled}
            handleChange={onChange}
            handleBlur={onBlur}
            isRequired={isRequired}
            {...renderFieldLabel}
          />
        );

      case FIELD_TYPES.SWITCH:
        return (
          <FormSwitch name={name} handleChange={onChange} isRequired={isRequired} {...renderFieldLabel} />
        );

      case FIELD_TYPES.DATE:
        return (
          <FormPicker
            name={name}
            disabled={disabled}
            handleChange={onChange}
            isRequired={isRequired}
            dateProps={{
              placeholder: PLACEHOLDERS.DATE,
            }}
            {...renderFieldLabel}
          />
        );

      case FIELD_TYPES.TIME:
        return (
          <FormPicker
            name={name}
            type="time"
            disabled={disabled}
            handleChange={onChange}
            isRequired={isRequired}
            timeProps={{
              placeholder: PLACEHOLDERS.TIME,
            }}
            {...renderFieldLabel}
          />
        );

      case FIELD_TYPES.DATE_AND_TIME:
        return (
          <FormPicker
            name={name}
            type="dateTime"
            disabled={disabled}
            handleChange={onChange}
            isRequired={isRequired}
            dateProps={{ placeholder: PLACEHOLDERS.DATE }}
            timeProps={{ placeholder: PLACEHOLDERS.TIME }}
            {...renderFieldLabel}
          />
        );

      default:
        return (
          <FormInput
            fieldRef={fieldRef}
            name={name}
            type="text"
            placeholder={PLACEHOLDERS.INPUT_TEXT}
            disabled={disabled}
            handleChange={onChange}
            handleBlur={onBlur}
            isRequired={isRequired}
            {...renderFieldLabel}
          />
        );
    }
  }, [
    field,
    isModal,
    fieldRef,
    name,
    disabled,
    onChange,
    onBlur,
    onClear,
    menuFooterLink,
    isDropDownLastItem,
  ]);

  return { renderField };
};
