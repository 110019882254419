import { useState } from 'react';
import * as AutomationApi from '../../api/automationRules';

const useFetchAutomationRuleDetails = () => {
  const [rule, setRule] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchAutomationRuleDetails = async (ruleId) => {
    setLoading(true);
    try {
      const rule = await AutomationApi.fetchAutomationRuleDetail(ruleId);
      setRule(rule);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { rule, loading, error, setRule, fetchAutomationRuleDetails };
};

export default useFetchAutomationRuleDetails;
