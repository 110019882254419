import { useContext, useState, useEffect } from 'react';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { StyledTable, TableContainer, TextButton, PhoneStyled } from 'views/Customer/Styles';
import { PROFILE_FIELDS, CONTACTS_FIXED_FIELDS, CONTACT_LABEL } from 'constants';
import { trimStringWithEllipsis, formatPhoneNumber, camelize } from 'helpers/CommonHelper';
import { previewData, defaultFields } from './fixtures/previewData';
import profileIcon from 'img/profile_icon.svg';
import { PLACEHOLDERS } from 'constants';
import PreviewMoreInfo from './PreviewMoreInfo';
import { format } from 'date-fns';

const PreviewContactsTable = ({handleShow, handleHide, showAddContactModal}) => {
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState({});

  const { contacts: contactsState } = useContext(CustomFieldsContext);
  const generalFields = contactsState?.editableSections[0];
  const { FIRST_NAME, LAST_NAME, EMAIL, PHONE } = PROFILE_FIELDS;

  useEffect(() => {
    if (contacts.length === 0) {
      let customFields = {};

      const _customFields = contactsState.editableFields.filter(
        (field) => !defaultFields.includes(field.targetFieldName)
      );

      _customFields.forEach((field) => {
        customFields = {
          ...customFields,
          [camelize(field.targetFieldName)]: PLACEHOLDERS[field.fieldType.toUpperCase()],
        };
      });

      setSelectedContact({ ...previewData, ...customFields });
      setContacts([{ ...previewData, ...customFields }]);
    }
  }, [contacts.length, contactsState.editableFields]);

  const getCustomizedLabel = (fieldLabel) => {
    switch (fieldLabel) {
      case FIRST_NAME:
        return CONTACT_LABEL.NAME;
      case EMAIL:
        return CONTACT_LABEL.EMAIL_PHONE;
      default:
        break;
    }
  };

  const getCustomizedData = (contact, fieldName) => {
    switch (fieldName) {
      case CONTACTS_FIXED_FIELDS.FIRST_NAME:
        return (
          <td>
            <img className="mr-4" src={contact.avatar || profileIcon} alt="contact-avatar" aria-hidden />
            {`${contact[CONTACTS_FIXED_FIELDS.FIRST_NAME]} ${contact[CONTACTS_FIXED_FIELDS.LAST_NAME]}`}
          </td>
        );
      case CONTACTS_FIXED_FIELDS.EMAIL:
        return (
          <td>
            {trimStringWithEllipsis(contact[CONTACTS_FIXED_FIELDS.EMAIL], 30)}
            <PhoneStyled>{formatPhoneNumber(contact[CONTACTS_FIXED_FIELDS.PHONENUMBER]) ?? '--'}</PhoneStyled>
          </td>
        );
      case CONTACTS_FIXED_FIELDS.LAST_ACTIVITY:
        return (
          <td>
            {contact[CONTACTS_FIXED_FIELDS.LAST_ACTIVITY] ? (
              <>
                <div>{format(new Date(contact[CONTACTS_FIXED_FIELDS.LAST_ACTIVITY]), 'MMM d, yyyy hh:mm aa')}</div>
              </>
            ) : (
              '--'
            )}
          </td>
        );
      default:
        break;
    }
  };

  return (
    <>
      <TableContainer>
        {contacts?.length ? (
          <StyledTable>
            <thead>
              <tr>
                {generalFields?.fields?.map(({ fieldLabel }) => {
                  if (fieldLabel !== LAST_NAME && fieldLabel !== PHONE) {
                    return (
                      <td className="border-0 pt-0">
                        {fieldLabel === FIRST_NAME || fieldLabel === EMAIL
                          ? getCustomizedLabel(fieldLabel)
                          : fieldLabel}
                      </td>
                    );
                  } else {
                    return null;
                  }
                })}
                <td className="border-0 pt-0"></td>
              </tr>
            </thead>
            <tbody>
              {contacts?.map((contact, index) => {
                return (
                  <tr key={index}>
                    {generalFields?.fields?.map(({ targetFieldName }) => {
                      const _fieldName = targetFieldName === CONTACTS_FIXED_FIELDS.LAST_ACTIVITY 
                        ? CONTACTS_FIXED_FIELDS.LAST_ACTIVITY
                        : targetFieldName.replace(
                          targetFieldName[0],
                          targetFieldName[0].toLowerCase()
                        );
                      if (
                        _fieldName !== CONTACTS_FIXED_FIELDS.LAST_NAME &&
                        _fieldName !== CONTACTS_FIXED_FIELDS.PHONE
                      ) {
                        return _fieldName === CONTACTS_FIXED_FIELDS.FIRST_NAME ||
                          _fieldName === CONTACTS_FIXED_FIELDS.EMAIL ||
                          _fieldName === CONTACTS_FIXED_FIELDS.LAST_ACTIVITY ? (
                          getCustomizedData(contact, _fieldName)
                        ) : (
                          <td className="py-3">{contact[_fieldName] ?? contact[targetFieldName] ?? '--'}</td>
                        );
                      } else {
                        return null;
                      }
                    })}
                    <td className="py-3 pr-4">
                      <TextButton onClick={() => handleShow(contact)} isUnderlined>
                        {CONTACT_LABEL.MOREINFO}
                      </TextButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
        ) : (
          <div className="small-info">No contacts</div>
        )}

        {showAddContactModal && Object.keys(selectedContact).length > 0 && (
          <PreviewMoreInfo
            show={showAddContactModal}
            handleHide={() => handleHide()}
          />
        )}
      </TableContainer>
    </>
  );
};

export default PreviewContactsTable;
