import { faClose, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { faStar, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DownloadLink from 'components/DownloadLink';
import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Tab, Tabs } from 'react-bootstrap';
import * as Api from '../api/Api';
import * as UsersApi from '../api/users';
//import CardColumns from '../components/CardColumns';
import LoadingInline from '../components/Loading/LoadingInline';
import ModalConfirm from 'components/Modals/Confirm/index';
import ModalEditReminder from '../components/Modals/EditReminder';
import PageHeader from '../components/PageHeader';
import TableHeader from '../components/TableHeader';
import TableRow from '../components/TableRow';
import toast from '../components/toast';
import * as DateHelper from '../helpers/DateHelper';
import { endOfYesterday, addBusinessDays } from 'date-fns';
import CanvasCardColumns from 'components/CanvasCardColumns/CardColumns';
import styled from 'styled-components';
import { LocalStorage } from 'models';
import { DASHBOARD, FRONT_LINE, ROLE } from 'constants';
import { toast as toastCall } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FullContext from 'stores/Full/fullContext';
import { Dashboard as DashboardModel } from 'models';
import { withRouter } from 'helpers/RouteHelper';

const SubTitleWrapper = styled.div`
  white-space: pre-wrap;
`;

const DropdownMenu = styled(Dropdown.Menu)`
  filter: drop-shadow(3px 3px 6px gray);
  margin: -33px 0 0 0;
`;

const CheckMark = styled(FontAwesomeIcon)`
  color: #008be7;
  position: absolute;
  left: 1.5rem;
  top: ${(props) => props.top};
`;

const FilterButton = styled.button`
  background-color: transparent;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => (props.filterHolder ? 'var(--schedule-sapphire)' : 'var(--playbook-pewter)')};
  border: none;
  padding: 16px;
  margin: 0;
  width: 100%;
  &:hover {
    background-color: #e9ecef;
  }
  &:focus {
    outline: none !important;
    outline-offset: none !important;
  }
  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }
`;

const DashboardWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 4px;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  svg {
    font-size: 20px;
    color: white;
  }
`;

const IconContainer = styled.div`
  margin: auto 16px auto 0;
`;

class Dashboard extends React.Component {
  static contextType = FullContext;

  constructor(props) {
    super(props);
    this.toastId = React.createRef();

    this.allOrMyChangedHandler = this.allOrMyChangedHandler.bind(this);
    this.state = {
      loading: false,
      calendarItems: null,
      filteredCalendarItems: null,
      // Key/index as user id, if -1, no assigned users
      userIndexedCalendarItems: {},
      taskItems: [],
      savingReminder: false,
      markingComplete: false,
      selectedTab: null,
      showNewReminderModal: false,
      // so we don't have to load the customers list unless they click the new reminder button
      newReminderModalLoaded: false,
      reminderEdit: null,
      toComplete: null,
      activityLoaded: false,
      //  ACD-50
      users: [],
      //  -1 indicates all users, otherwise the user to filter for (also what used to be 'my')
      selectedUser: undefined,
      showUsers: false,
      areUsersLoaded: false,
      usersSelected: [],
      existingUsersSelected: [],
      reloadReminder: false,
      reloadCanvas: false,
    };
  }

  componentDidMount() {
    const options = {
      toastId: 'tip-toast',
      position: 'bottom-right',
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: 'text-white bg-secondary',
      bodyClassName: 'tip-toast',
      closeButton: (
        <IconContainer>
          <CloseIcon className="fa-xl" icon={faClose} />
        </IconContainer>
      ),
    };
    const Msg = (
      <>
        <b>Tip:</b> You can mark steps complete by hovering on the status circle.
      </>
    );
    // Commenting this out for now to hide the Dashboard notification
    // if (!toastCall.isActive(this.toastId.current)) {
    if (false) {
      this.toastId = toast.tip(Msg, options);
    }

    this.updateSelectedTab();
    if (!this.context.roleAtleast(FRONT_LINE) && this.state.selectedUser === -1) {
      this.setState({ selectedUser: this.context.userData?.id }, () => {
        this.loadSchedule();
        localStorage.setItem('selectedUser', this.state.selectedUser);
      });
    } else {
      this.loadSchedule();
    }
  }

  componentWillUnmount() {
    toastCall.dismiss(this.toastId.current);
  }

  async loadUsers() {
    if (this.context.roleAtleast(FRONT_LINE)) {
      // Endpoint User/GetAll has role restrictions. The current behavior (see componentDidMount)
      // is that SUPERUSER, ADMIN, and CREATOR users will see the full list of all internal users
      // in the filter drop-down. FRONT_LINE users will not see the drop-down.
      const response = await UsersApi.fetchUsers();

      const sortedByFirstName = response.sort((a, b) => a.firstName.localeCompare(b.firstName));

      let result = [...sortedByFirstName];

      const index = result.findIndex(
        (user) => `${user.firstName} ${user.lastName}` === this.context.userData?.fullName
      );

      result.unshift(result?.splice(index, 1)[0]);

      this.setState(
        {
          users: [
            ...result.map((user) => ({
              id: user.primaryId,
              name: `${user.firstName} ${user.lastName}`,
            })),
          ],
        },
        () => {}
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.router.location.hash !== prevProps.router.location.hash) {
      this.updateSelectedTab();
    }
    if (this.props.router.location.search !== prevProps.router.location.search) {
      this.showMarkCompleteConfirmModal();
    }
    if (this.state.usersSelected.length !== prevState.usersSelected.length) {
      this.loadSchedule();
    }
    if (ROLE in this.context.userData && !this.state.areUsersLoaded) {
      this.setState({
        areUsersLoaded: true,
      });
      this.loadUsers();
    }
    if (this.context.isReminderUpdated !== prevState.reloadReminder) {
      this.loadSchedule();
      this.context.setReminderState(false);
    }
    if (this.context.isCanvasUpdated !== prevState.reloadCanvas) {
      this.loadSchedule();
      this.context.setCanvasState(false);
    }
  }

  updateSelectedTab() {
    this.setState(
      {
        selectedTab: this.props.router.location.hash
          ? this.props.router.location.hash.substring(1)
          : 'schedule',
      },
      () => {
        if (this.state.selectedTab === 'activity') {
          this.setState({ activityLoaded: true });
        }
      }
    );
  }

  handleSelect = (selectedTab) => {
    this.setState({ selectedTab });
    this.props.router.navigate({
      hash: selectedTab,
    });
  };

  async loadSchedule() {
    this.setState({ calendarItems: null, filteredCalendarItems: null });
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 70);
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 30);
    const userItems = this.state.usersSelected.map((user) => `userItems=${user}`).join('&');
    const response = await DashboardModel.getCalendarItems(
      userItems,
      startDate.toISOString(),
      endDate.toISOString()
    );

    let state = { calendarItems: response };
    if (response && response.length) {
      const userIndexedCalendarItems = response.reduce((indexedItems, item) => {
        if (item && item.assignedUsers && item.assignedUsers.length) {
          indexedItems = item.assignedUsers.reduce((_indexedItems, userId) => {
            if (_indexedItems[userId]) {
              _indexedItems[userId].push(item);
            } else {
              _indexedItems[userId] = [item];
            }

            return _indexedItems;
          }, indexedItems);
        } else {
          const key = item.calendarItemType === 'TaskItem' ? 'taskItem' : -1;
          if (indexedItems[key]) {
            indexedItems[key].push(item);
          } else {
            indexedItems[key] = [item];
          }
        }

        return indexedItems;
      }, {});

      state = { ...state, userIndexedCalendarItems };
    }

    const userId = this.context.userData?.id;

    const dashboardCache = LocalStorage.get(DASHBOARD);

    // If the dashboardCache is null, has a structure of { usersSelected: [] }
    // or is an array, then the structure will be modified to { [userId]: { usersSelected: [] } }
    if (!dashboardCache || dashboardCache.hasOwnProperty('usersSelected') || Array.isArray(dashboardCache)) {
      LocalStorage.set(DASHBOARD, { [userId]: { usersSelected: [] } });
    }

    let usersSelectedCache = [];

    if (dashboardCache && dashboardCache[userId] && dashboardCache[userId].usersSelected) {
      const { usersSelected } = dashboardCache[userId];

      state = { ...state, usersSelected };
      usersSelectedCache = usersSelected;
    }

    this.setState(state, this.showMarkCompleteConfirmModal);

    this.applyFilterHandler(usersSelectedCache, response);
  }

  allOrMyChangedHandler = (evt) => {
    this.setState({ selectedUser: evt.target.value }, () => {
      this.loadSchedule();
      localStorage.setItem('selectedUser', this.state.selectedUser);
    });
  };

  async addEditReminder(customerId, text, date) {
    const data = {
      customerId: customerId,
      description: text,
      date,
    };
    let toastMessage;
    this.setState({ savingReminder: true });
    if (this.state.reminderEdit) {
      data.id = this.state.reminderEdit.id;
      await Api.put(`Touchpoint/UpdateCustomerTask`, data);
      toastMessage = 'Reminder updated successfully!';
    } else {
      await Api.post('Touchpoint/CreateCustomerTask', data);
      toastMessage = 'Reminder created successfully!';
    }
    this.setState({ savingReminder: false, reminderEdit: null });
    this.loadSchedule();
    toast.saved(toastMessage);
  }

  showNewReminderModal = () => {
    this.setState({ showNewReminderModal: true, newReminderModalLoaded: true, reminderEdit: null });
  };

  newReminderModalOnEdit = async (customerId, title, text, date) => {
    this.setState({ showNewReminderModal: false });
    await this.addEditReminder(customerId, title, text, date);
  };

  newReminderModalOnHide = () => {
    this.setState({ showNewReminderModal: false });
  };

  onEditReminder = (reminder) => {
    this.setState({ showNewReminderModal: true, newReminderModalLoaded: true, reminderEdit: reminder });
  };

  showMarkCompleteConfirmModal() {
    const params = new URLSearchParams(this.props.router.location.search);
    if (params.get('completeTaskId')) {
      const taskId = parseInt(params.get('completeTaskId'));
      const item =
        this.state.filteredCalendarItems &&
        this.state.filteredCalendarItems.find((x) => x.calendarItemType === 'TaskItem' && x.id === taskId);
      if (item) {
        this.setState({ toComplete: item });
      }
    }
  }

  markCompleteModalOnHide = () => {
    this.props.router.navigate('/dashboard');
    this.setState({ toComplete: null });
  };

  markComplete = async () => {
    this.setState({ markingComplete: true });
    await DashboardModel.updateTaskComplete(this.state.toComplete.id, !this.state.toComplete.completed);
    await toast.saved(`Reminder marked ${this.state.toComplete.completed ? 'incomplete' : 'complete'}!`);
    this.setState({ toComplete: null, markingComplete: false });
    this.loadSchedule();
    this.props.router.navigate('/dashboard');
  };

  calendarDetail() {
    const columns = {
      urgent: { title: 'Past Due', emptyString: 'Nothing past due', items: [] },
      today: { title: 'Due today', emptyString: 'Nothing due', items: [] },
      thisWeek: { title: 'Due in a week', emptyString: 'Nothing due', items: [] },
      thisMonth: { title: 'Due in a month', emptyString: 'Nothing due', items: [] },
    };
    const columnOrder = [columns.urgent, columns.today, columns.thisWeek, columns.thisMonth];
    const today = new Date();
    const thisWeek = addBusinessDays(new Date(), 5);
    const thisMonth = addBusinessDays(new Date(), 20);
    this.state.filteredCalendarItems
      .sort((a, b) => a.displayDate - b.displayDate)
      .forEach((item) => {
        const date = DateHelper.getDateFromServerString(
          item?.dateToBeScheduled && item?.dateIsScheduled
            ? item.scheduledDate || item.displayDate
            : item.displayDate
        );
        item.displayDate = new Date(
          item?.dateToBeScheduled && item?.dateIsScheduled
            ? item.scheduledDate || item.displayDate
            : item.displayDate
        );
        item.projectedDate = new Date(item.projectedDate);
        item.scheduledDate = item.scheduledDate ? new Date(item.scheduledDate || item.displayDate) : null;
        item.headerText = item.companyName;
        item.key = item.calendarItemType + item.id;
        item.isMember = true;
        if (item.calendarItemType === 'TaskItem') {
          item.link = { pathname: '/dashboard', search: `?completeTaskId=${item.id}` };
        } else {
          item.link = {
            pathname: `/accounts/${item.customerId}/projects/${item.customerCanvasId}/steps/${item.customerTouchpointId}`,
          };
        }

        // todo: test timezone
        if (item.pastDue && item?.dateToBeScheduled && item?.dateIsScheduled && date <= endOfYesterday()) {
          columns.urgent.items.push(item);
        } else if (today.toDateString() === date.toDateString()) {
          columns.today.items.push(item);
        } else if (date < today) {
          if (item.customerTouchpointStatus !== 'Completed') {
            columns.urgent.items.push(item);
          }
          // } else if (date <= lastDayOfWeek && date >= firstDayOfWeek) {
        } else if (date <= thisWeek) {
          columns.thisWeek.items.push(item);
        } else if (date <= thisMonth) {
          // probably want to change this_month to next_month if its close to the end of the month?
          columns.thisMonth.items.push(item);
        }
      });
    columns.urgent.items.sort((a, b) => b.displayDate - a.displayDate);

    const canvasState = {
      columns: columnOrder,
    };

    return <CanvasCardColumns canvasState={canvasState} onEdit={this.onEditReminder} />;
  }

  scheduleTab() {
    return (
      <>
        <div className="mt-4">
          {this.state.filteredCalendarItems === null ? <LoadingInline /> : this.calendarDetail()}
        </div>
      </>
    );
  }

  activityTab() {
    return (
      <>
        <div className="text-right">
          <DownloadLink
            url={Api.getUrl(
              `Customer/ExportActivities?userActivities=${
                this.state.selectedUser === this.context.userData?.id
              }`
            )}
            className="mb-2"
          >
            <FontAwesomeIcon className="mr-2" icon={faFileExport} />
            Export activity & notes
          </DownloadLink>
        </div>
        <div className="mt-4">
          {this.state.activityLoaded && <ActivityAndNotes usersSelected={this.state.usersSelected} />}
        </div>
      </>
    );
  }

  dashboardTabs() {
    return (
      <Tabs
        defaultActiveKey="schedule"
        id="dashboard-tabs"
        className="extend-to-edge"
        activeKey={this.state.selectedTab}
        onSelect={this.handleSelect}
      >
        <Tab eventKey="schedule" title="Schedule">
          {this.scheduleTab()}
        </Tab>
        <Tab eventKey="activity" title="Recent activity">
          {this.activityTab()}
        </Tab>
      </Tabs>
    );
  }

  toggleUsersSelected = async (id) => {
    const usersSelected = this.state.usersSelected?.includes(id)
      ? this.state.usersSelected?.filter((item) => item !== id)
      : [...this.state.usersSelected, id];

    this.setState({ usersSelected });

    await this.applyFilterHandler(usersSelected);
  };

  onClearFilters = async () => {
    this.setState({ usersSelected: [] });
    await this.applyFilterHandler([]);
  };

  applyFilterHandler = (usersSelected, initialCalendarItems = []) => {
    const {
      state: { userIndexedCalendarItems = {}, calendarItems },
    } = this;
    const mappedCalendarItems = calendarItems || initialCalendarItems;
    const filteredCalendarItemIds = [];
    let filteredCalendarItems = usersSelected?.length
      ? usersSelected
          .map((userId) =>
            userIndexedCalendarItems[userId] && userIndexedCalendarItems[userId].length
              ? userIndexedCalendarItems[userId].filter(({ id }) => {
                  if (!filteredCalendarItemIds.includes(id)) {
                    filteredCalendarItemIds.push(id);

                    return true;
                  }

                  return false;
                })
              : []
          )
          .flat()
      : mappedCalendarItems;

    if (userIndexedCalendarItems['taskItem'] && usersSelected && usersSelected.length) {
      filteredCalendarItems = filteredCalendarItems.concat(userIndexedCalendarItems['taskItem']);
    }

    this.setState(
      { filteredCalendarItems, existingUsersSelected: usersSelected },
      this.showMarkCompleteConfirmModal
    );

    const userId = this.context.userData?.id;
    const dashboardCache = LocalStorage.get(DASHBOARD);

    if (dashboardCache[userId]) {
      dashboardCache[userId].usersSelected = usersSelected;
      LocalStorage.set(DASHBOARD, dashboardCache);
    } else {
      LocalStorage.set(DASHBOARD, { ...dashboardCache, [userId]: { usersSelected: [] } });
    }
  };

  render() {
    const form = this.context.roleAtleast(FRONT_LINE) ? (
      <DropdownPersist
        title="Dropdown"
        showUsers={(isOpen, isRootClosed) => {
          this.setState((prev) => {
            return {
              showUsers: !prev.showUsers,
              usersSelected: !isOpen && isRootClosed ? prev.existingUsersSelected : prev.usersSelected,
            };
          });
        }}
      >
        <Dropdown.Toggle variant="light" className="filter">
          Filter{' '}
          {this.state?.existingUsersSelected?.length ? `(${this.state?.existingUsersSelected?.length})` : ''}
        </Dropdown.Toggle>
        <DropdownMenu align="center">
          {this.state.users.length > 0 && (
            <Dropdown.Header className="filter-header mt-2-5">OWNER</Dropdown.Header>
          )}
          {this.state.users.map((item) => (
            <Dropdown.Item
              className="pl-48 py-2"
              key={item.id}
              eventKey={'status' + item.id}
              onClick={() => this.toggleUsersSelected(item.id)}
            >
              <div className="d-flex align-items-center">
                {this.state.usersSelected?.includes(item.id) && <CheckMark icon={faCheck} />}
                {item.name === this.context.userData?.fullName ? 'Me' : item.name}
              </div>
            </Dropdown.Item>
          ))}
          <Dropdown.Divider className="mx-4" />
          <div className="d-flex">
            <FilterButton
              disabled={!this.state.usersSelected?.length}
              filterHolder={this.state.usersSelected?.length > 0}
              onClick={() => this.onClearFilters()}
            >
              Clear All
            </FilterButton>
          </div>
        </DropdownMenu>
      </DropdownPersist>
    ) : null;

    return (
      <>
        <DashboardWrapper>
          <PageHeader title="Dashboard"></PageHeader>
          <div className="d-flex justify-content-end align-self-center">
            {form}
            <Button
              data-testid="btn-new-reminder"
              className="ml-3"
              onClick={this.showNewReminderModal}
              disabled={this.state.savingReminder}
            >
              {this.state.savingReminder ? (
                <LoadingInline centered white text="Saving..." />
              ) : (
                <>
                  <FontAwesomeIcon icon={faStar} className="mr-2" />
                  New reminder
                </>
              )}
            </Button>
          </div>
        </DashboardWrapper>
        {this.dashboardTabs()}
        {(this.state.newReminderModalLoaded || this.state.showNewReminderModal) && (
          <ModalEditReminder
            reminder={this.state.reminderEdit}
            show={this.state.showNewReminderModal}
            justMyCustomers={this.state.selectedUser === this.context.userData?.id}
            onEdit={this.newReminderModalOnEdit}
            onHide={this.newReminderModalOnHide}
          />
        )}
        <ModalConfirm
          title={this.state.toComplete?.completed ? 'Mark incomplete' : 'Mark complete'}
          message={`Mark this reminder as ${this.state.toComplete?.completed ? 'incomplete' : 'complete'}?`}
          show={this.state.toComplete !== null}
          onConfirm={this.markComplete}
          onHide={this.markCompleteModalOnHide}
          confirmLoading={this.state.markingComplete}
          confirmText={this.state.toComplete?.completed ? 'Mark incomplete' : 'Mark complete'}
          variant="primary"
        />
      </>
    );
  }
}

function ActivityAndNotes({ usersSelected }) {
  const [activities, setActivities] = useState(null);

  useEffect(() => {
    async function loadActivity() {
      setActivities(null);
      const userItems = usersSelected.map((user) => `userItems=${user}`).join('&');
      const response = await Api.get(`Customer/GetAllNotesAndActivities?${userItems}`);
      setActivities(response.value.items);
    }
    loadActivity();
  }, [usersSelected]);

  if (activities === null) {
    // loading
    return <LoadingInline />;
  } else if (activities.length === 0) {
    return <div className="small-info">No activity right now.</div>;
  }

  return (
    <>
      <TableHeader>
        <Col md={6}>Activity/Note</Col>
        <Col md={3}>Step</Col>
        <Col md={3}>Time</Col>
      </TableHeader>

      {activities.map((item, index) => {
        const date = item.dateCreated;
        const dateTime = DateHelper.formatDateTime(date);
        let to;
        if (item.customerCanvasId) {
          to = `/accounts/${item.customerId}/projects/${item.customerCanvasId}/steps/${item.customerTouchpointId}`;
        } else {
          to = `/accounts/${item.customerId}#notes`;
        }
        return (
          <TableRow key={index} to={to}>
            <Col md={6}>
              <div className="font-weight-bold">{item.customer}</div>
              <SubTitleWrapper
                className="color-completed"
                dangerouslySetInnerHTML={{ __html: item?.text?.replace('color: blue', '') ?? '' }}
              />
            </Col>
            <Col md={3} className={item.title ? '' : 'color-completed'}>
              {item.title || 'General'}
            </Col>
            <Col md={3} className="d-flex align-items-center date mt-1 mt-md-0">
              {dateTime.time}
              <div className="ml-1-5">{dateTime.date}</div>
            </Col>
          </TableRow>
        );
      })}
    </>
  );
}

const DropdownPersist = (props) => {
  const { showUsers, ...rest } = props;

  const [open, setOpen] = useState(false);
  const onToggle = (isOpen, ev, metadata) => {
    if (metadata.source === 'click' || metadata.source === 'rootClose') {
      showUsers(isOpen, metadata.source === 'rootClose');
    }

    if (metadata.source === 'select') {
      setOpen(true);
      return;
    }
    setOpen(isOpen);
  };

  return <Dropdown show={open} onToggle={onToggle} {...rest}></Dropdown>;
};

export default withRouter(Dashboard);
