import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import Select from 'components/Select';
import { v4 as uuidv4 } from 'uuid';
import PlusButtonDropdown from './PlusButtonDropdown';
import RuleBuilderSection from './RuleBuilderSection';
import {
  TRIGGER_OPTIONS,
  TRIGGER_DISPLAY_VALUE_MAP,
  RULES_FORM_FIELDS,
  CONDITION_COMMON_TEMPLATE,
  CONDITION_TYPE,
  RULE_ACTION_TEMPLATE,
  TRIGGER_TYPE_OPTIONS_BY_TRIGGER,
  OPERAND_TYPES,
} from '../../../constants/automationRules';
import DeleteButtonTrashIcon from '../../DeleteButtonTrashIcon';
import RuleCondition from './RuleCondition';
import RuleAction from './RuleAction';
import ErrorText from './ErrorText';
import { getActionsOptions } from './utils';
import useLeftOperandsByCategory from '../../../helpers/hooks/useLeftOperandOptions';
import useCanvases from 'helpers/hooks/useCanvases';
import LoadingSpinner from 'components/Loading/LoadingSpinner';

const MainContainer = styled.div`
  margin-top: 2.5rem;
`;

const CreateRuleContainer = styled.div`
  border: solid var(--goal-gray) 1px;
  min-height: 150px;
  border-radius: 8px;
  padding: 1rem 1.5rem;
`;

const BlankRuleSection = styled.div`
  color: var(--playbook-pewter);
  padding-bottom: 3.75rem;
  margin-bottom: 1rem;
  border-bottom: solid var(--border-gray) 1px;
`;

const BlankRuleTitle = styled.div`
  font-weight: bold;
`;

const BlankRuleContainer = styled.div`
  margin-top: 1rem;
`;

const BlankRuleAddTrigger = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-left: 1rem;
    color: var(--champion-charcoal);
  }
  button.rounded-plus-button {
    background-color: var(--schedule-sapphire);
  }
  button.rounded-plus-button > svg {
    color: white;
  }
`;

const ItemsContainer = styled.div`
  padding-top: 1rem;
`;

const TriggerRuleContainer = styled(ItemsContainer)`
  display: flex;
  justify-content: space-between;
  height: 50px;
`;

const TriggerRuleItem = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  svg {
    opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};
  }
`;

const AllConditionsTrueContainer = styled.div`
  display: flex;
  > span {
    margin-right: 1rem;
  }
`;

/**
 * Component to build a rule
 * @property {object} props.rule selected rule
 * @returns {JSX.Element}
 */
const RuleBuilder = ({ rule, form = {} }) => {
  const { errors, touched, values: formValues, setFieldValue, setValues: setFormValues } = form;
  const trigger = formValues[RULES_FORM_FIELDS.sourceCategory];
  const triggerType = formValues[RULES_FORM_FIELDS.triggerType];
  const [allConditionsTrue, setAllConditionsTrue] = useState(true);
  const hasTrigger = Boolean(trigger);
  const conditions = formValues[RULES_FORM_FIELDS.conditions];
  const actions = formValues[RULES_FORM_FIELDS.actions];
  const [actionsOptions, setActionsOptions] = useState(getActionsOptions(trigger, triggerType, actions));
  const triggerTypeOptions = TRIGGER_TYPE_OPTIONS_BY_TRIGGER[trigger];
  const { leftOperandOptions, leftOperands } = useLeftOperandsByCategory(trigger);
  const { canvasesOptions } = useCanvases();

  useEffect(() => {
    setActionsOptions(getActionsOptions(trigger, triggerType, actions));
  }, [trigger, triggerType, actions]);

  useEffect(() => {
    const isAllConditionsTrue = conditions.every(({ conditionType }) => conditionType === CONDITION_TYPE.and);

    isAllConditionsTrue ? setAllConditionsTrue(true) : setAllConditionsTrue(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Looks for a conditions template and displays it in the screen
   * @returns {void}
   */
  const addConditionTemplate = useCallback(
    (opt) => {
      const orderNo = conditions && conditions.length ? conditions.length + 1 : 1;
      const conditionLogicalOperator = allConditionsTrue ? CONDITION_TYPE.and : CONDITION_TYPE.or;
      const leftOperand = opt.value;
      const leftOperandType = opt.leftOperand.valueType;

      setFieldValue(RULES_FORM_FIELDS.conditions, [
        ...conditions,
        {
          ...CONDITION_COMMON_TEMPLATE,
          id: uuidv4(),
          orderNo,
          conditionType: conditionLogicalOperator,
          leftOperand,
          leftOperandType,
        },
      ]);
    },
    [allConditionsTrue, setFieldValue, conditions]
  );

  /**
   * Looks for a actions template and displays it in the screen
   * @returns {void}
   */
  const addActionTemplate = useCallback(
    (opt) => {
      const orderNo = actions && actions.length ? actions.length + 1 : 1;
      const newActions = [
        ...actions,
        {
          ...RULE_ACTION_TEMPLATE,
          id: uuidv4(),
          orderNo,
          actionName: opt.label,
          actionType: opt.value,
        },
      ];
      setFieldValue(RULES_FORM_FIELDS.actions, newActions);
      setActionsOptions(getActionsOptions(trigger, triggerType, newActions));
    },
    [actions, setFieldValue, trigger, triggerType]
  );

  /**
   * Updates selected trigger when select changes
   * @param {object} opt selected trigger option
   * @returns {void}
   */
  const handleTriggerSelect = useCallback(
    (opt) => {
      setFieldValue(RULES_FORM_FIELDS.sourceCategory, opt.value);
      setActionsOptions(getActionsOptions(opt.value, triggerType, actions));
    },
    [actions, triggerType, setFieldValue]
  );

  /**
   * Updates selected trigger when select changes
   * @param {object} option selected trigger option
   * @returns {void}
   */
  const handleTriggerTypeChange = (option) => {
    setFieldValue(RULES_FORM_FIELDS.triggerType, option.value);
    setActionsOptions(getActionsOptions(trigger, option.value, actions));
  };

  /**
   * Handles trigger deletion
   * @returns {void}
   */
  const handleTriggerDelete = useCallback(() => {
    setFormValues({
      ...formValues,
      [RULES_FORM_FIELDS.sourceCategory]: '',
      [RULES_FORM_FIELDS.sourceCategoryType]: '',
      [RULES_FORM_FIELDS.conditions]: [],
      [RULES_FORM_FIELDS.actions]: [],
    });
  }, [formValues, setFormValues]);

  /**
   * Handle conditional logical operator changes
   * @param {object} event javascript event
   * @returns {void}
   */
  const handleAllConditionTrueChange = (e) => {
    const value = e.target.checked;
    const conditionType = value ? CONDITION_TYPE.and : CONDITION_TYPE.or;
    const mappedConditions = conditions.map((condition) => ({ ...condition, conditionType }));
    setFieldValue(RULES_FORM_FIELDS.conditions, mappedConditions);
    setAllConditionsTrue(value);
  };

  /**
   * Handle action deletion
   * @returns {void}
   */
  const handleActionDelete = useCallback(
    (remainingActionsAfterDeleting) => {
      setActionsOptions(getActionsOptions(trigger, triggerType, remainingActionsAfterDeleting));
    },
    [trigger, triggerType]
  );

  /**
   * Renders conditional logical operator toggle
   * @returns {JSX.Element}
   */
  const renderAllConditionsTrue = () =>
    conditions && conditions.length > 1 ? (
      <AllConditionsTrueContainer>
        <span>Require all conditions to be true</span>
        <Form.Check
          id="all-conditions-to-be-true"
          name="all-conditions-to-be-true"
          type="switch"
          checked={allConditionsTrue}
          onChange={handleAllConditionTrueChange}
        />
      </AllConditionsTrueContainer>
    ) : null;

  return (
    <MainContainer>
      <CreateRuleContainer>
        {!hasTrigger && (
          <BlankRuleSection>
            <BlankRuleTitle>Triggers</BlankRuleTitle>
            <BlankRuleContainer>
              <BlankRuleAddTrigger>
                <PlusButtonDropdown options={TRIGGER_OPTIONS} onSelect={handleTriggerSelect} />
                <span>Add Trigger...</span>
              </BlankRuleAddTrigger>
              <ErrorText>
                {touched[RULES_FORM_FIELDS.sourceCategory] && errors[RULES_FORM_FIELDS.sourceCategory]}
              </ErrorText>
            </BlankRuleContainer>
          </BlankRuleSection>
        )}
        {hasTrigger && (
          <RuleBuilderSection title="Triggers" showAddButton={false} className="mb-4" withSeparator>
            <TriggerRuleContainer>
              <TriggerRuleItem isDisabled={rule?.id}>
                <div className="mr-2">
                  {TRIGGER_DISPLAY_VALUE_MAP[formValues[RULES_FORM_FIELDS.sourceCategory]]}
                </div>
                <div>
                  <Select
                    id={RULES_FORM_FIELDS.triggerType}
                    name={RULES_FORM_FIELDS.triggerType}
                    isDisabled={rule?.id}
                    value={triggerTypeOptions.find((opt) => opt.value === formValues.triggerType)}
                    options={triggerTypeOptions}
                    onChange={handleTriggerTypeChange}
                  />
                  <ErrorText>
                    {touched[RULES_FORM_FIELDS.triggerType] && errors[RULES_FORM_FIELDS.triggerType]}
                  </ErrorText>
                </div>
              </TriggerRuleItem>
              {!rule?.id && <DeleteButtonTrashIcon onClick={handleTriggerDelete} />}
            </TriggerRuleContainer>
          </RuleBuilderSection>
        )}
        <RuleBuilderSection
          className="mb-4"
          title="Conditions"
          addButtonOptions={leftOperandOptions}
          addButtonLabel="Add a condition"
          onButtonClick={addConditionTemplate}
          renderHeaderRightSide={renderAllConditionsTrue}
          withSeparator
          isBlank={!Boolean(conditions?.length)}
          showAddButton={hasTrigger}
          validationError={
            touched[RULES_FORM_FIELDS.conditions] &&
            typeof errors[RULES_FORM_FIELDS.conditions] === 'string' &&
            errors[RULES_FORM_FIELDS.conditions]
          }
        >
          {Boolean(conditions.length) && !!canvasesOptions.length ? (
            <ItemsContainer>
              {conditions.map((condition, index) => {
                const rightOperandOptionValue =
                  condition.leftOperandType === OPERAND_TYPES.func_canvas
                    ? canvasesOptions.find((item) => item.value === Number(condition.rightOperand)) ??
                      condition.rightOperand
                    : condition.rightOperand;
                const _condition = {
                  ...condition,
                  rightOperand: rightOperandOptionValue,
                };
                return (
                  <RuleCondition
                    key={condition.id}
                    condition={_condition}
                    conditionIndex={index}
                    form={form}
                    leftOperandOptions={leftOperandOptions}
                    leftOperands={leftOperands}
                  />
                );
              })}
            </ItemsContainer>
          ) : (
            <LoadingSpinner className="mt-3" />
          )}
        </RuleBuilderSection>
        <RuleBuilderSection
          title="Actions"
          addButtonOptions={actionsOptions}
          addButtonLabel="Add an action"
          onButtonClick={addActionTemplate}
          isBlank={!Boolean(actions?.length)}
          showAddButton={actionsOptions?.length && hasTrigger}
          validationError={
            (touched[RULES_FORM_FIELDS.actions] &&
              typeof errors[RULES_FORM_FIELDS.actions] === 'string' &&
              errors[RULES_FORM_FIELDS.actions]) ||
            (touched.notAllowedActionForTriggerType && errors.notAllowedActionForTriggerType)
          }
        >
          {Boolean(actions.length) && (
            <ItemsContainer totalItems={actions.length}>
              {actions.map((action, index) => (
                <RuleAction
                  key={action.id}
                  action={action}
                  actionIndex={index}
                  form={form}
                  onDelete={handleActionDelete}
                />
              ))}
            </ItemsContainer>
          )}
        </RuleBuilderSection>
      </CreateRuleContainer>
    </MainContainer>
  );
};

export default RuleBuilder;
