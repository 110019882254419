import React, { useCallback, useMemo, useState } from 'react';
import { useField, ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';
import { ErrorMessageContainer } from 'views/CustomFields/Account/Styles';
import useUpdateEffect from 'helpers/hooks/useUpdateEffect';
import { FieldLabel } from 'components/Modals/Customers/Styles';
import { INPUT_TYPES } from 'constants';

const Input = (
  {
    name,
    type = INPUT_TYPES.TEXT,
    handleChange,
    handleBlur,
    disabled,
    fieldLabel,
    isRequired,
    isFocused,
    placeholder,
    maxLength,
    ...props
  },
  ref
) => {
  const [, meta, helpers] = useField(name);

  const [value, setValue] = useState(meta.value || meta.initialValue);

  useUpdateEffect(() => {
    setValue(meta.value);
  }, [meta.value]);

  const hasError = useMemo(() => meta.touched && !!meta.error, [meta.error, meta.touched]);

  /**
   * On Input Change handler
   * @params event
   * @returns void
   */
  const onInputChange = useCallback(
    (event) => {
      const { value: inputValue } = event.target;

      setValue(inputValue);

      helpers.setError('');
      helpers.setValue(inputValue);

      if (handleChange) handleChange(inputValue);
    },
    [helpers, handleChange]
  );

  const onFocus = useCallback(() => {
    helpers.setTouched(true);
  }, [helpers]);

  /**
   * On Input Blur handler
   * @returns void
   */
  const onInputBlur = () => {
    onFocus();

    if (handleBlur) handleBlur();
    if (meta.touched) helpers.setTouched(false);
  };

  /**
   * On Input Wheel handler
   * @returns void
   */
  const onInputWheel = (event) => type === INPUT_TYPES.NUMBER && event.target.blur();

  const onInput = useCallback(
    (event) => {
      if (maxLength)
        event.target.value = Math.max(0, parseInt(event.target.value)).toString().slice(0, maxLength);
    },
    [maxLength]
  );

  return (
    <>
      {fieldLabel && (
        <FieldLabel>
          <span>{fieldLabel}</span>
          {isRequired && !hasError && <span>*</span>}
          {hasError && (
            <ErrorMessageContainer className="error-container">
              (<ErrorMessage name={name} />)
            </ErrorMessageContainer>
          )}
        </FieldLabel>
      )}

      <Form.Control
        ref={ref}
        type={type}
        value={value ?? ''}
        readOnly={disabled}
        onChange={onInputChange}
        onFocus={onFocus}
        onBlur={onInputBlur}
        onWheel={onInputWheel}
        onInput={onInput}
        placeholder={meta.touched ? placeholder : ''}
        {...props}
      />
    </>
  );
};

export default React.forwardRef(Input);
