import React from 'react';
import Select from '../../../../components/Select';
import useCanvases from '../../../../helpers/hooks/useCanvases';
import ErrorText from '../ErrorText';
import InputContainer from './ActionInputContainer';
import ActionContainer from './ActionContainer';

/**
 * Renders action to add a new project/canvas
 * @property {object} props.action Action object type
 * @property {object} props.errors Errors in the form
 * @property {object} props.touched Indicates if elements in the form were touched
 * @property {object} props.onActionInputChange callback to execute when input changes
 * @returns {JSX.Element}
 */
const AssignCanvasAction = ({ action = {}, errors = {}, touched = {}, onActionInputChange = () => {} }) => {
  const { canvasesOptions } = useCanvases();
  const actionInput = action?.actionInput || {};
  const { canvasId } = actionInput;

  const ASSIGN_CANVAS_TEMPLATE = {
    canvasId: 0,
  };

  /**
   * Function to handle input change
   * @param {object} opt object with label and value for the selected item
   * @returns {void}
   */
  const handleActionChange = (opt) => {
    const canvasId = opt.value;
    const newInput = {
      ...ASSIGN_CANVAS_TEMPLATE,
      ...actionInput,
      canvasId,
    };
    onActionInputChange(newInput);
  };


  return (
    <ActionContainer>
      <div className="action-label">Create New Project</div>
      <InputContainer>
        <Select
          placeholder="Select template..."
          containerWidth={250}
          options={canvasesOptions.sort((a, b) => a.label.localeCompare(b.label))}
          onChange={handleActionChange}
          value={canvasesOptions.find((canvas) => canvas.value === canvasId)}
        />
        {touched.actionInput && errors.canvasId ? <ErrorText>{errors.canvasId}</ErrorText> : null}
      </InputContainer>
    </ActionContainer>
  );
};

export default AssignCanvasAction;
