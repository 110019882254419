import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
} from 'components/Modals/LargePadding';
import { useFormikContext } from 'formik';
import React, { useMemo, useState, useContext } from 'react';
import { PaymentInformationContainer, RecurringContainer, SubscriptionContainer } from './Styles';
import { Button, Form } from 'react-bootstrap';
import ModalPlans from '../Plans';
import { subscriptionSchema } from 'views/Settings/Billing/validations';
import { planDetails } from 'views/Settings/fixtures';
import { BILLING_PLANS } from 'constants';
import { noop } from 'helpers/CommonHelper';
import FullContext from 'stores/Full/fullContext';
import { FormInput } from 'components/Formik/FormInput';

const Subscription = ({ onHide, show, formattedProductList, planOptions, currentPlan }) => {
  const { submitForm, isSubmitting, values, setFieldTouched, setFieldValue, dirty } = useFormikContext();

  const { selectedPlan, recurring } = values;

  const { appSettings } = useContext(FullContext);

  const [showPlanModal, setShowPlanModal] = useState(false);

  const isValid = subscriptionSchema.isValidSync(values) && dirty;
  const isFreeTier = currentPlan === BILLING_PLANS.FREE;

  const foundProduct = useMemo(
    () => formattedProductList?.find(({ name }) => name === selectedPlan?.label),
    [formattedProductList, selectedPlan?.label]
  );

  const filteredPlanDetails = planDetails.filter((item) => item.title !== BILLING_PLANS.FREE);

  const planOptionsWithLimit = planOptions.map((option, index) => {
    const mappedTotals = {
      [BILLING_PLANS.TIER_ONE]: 50,
      [BILLING_PLANS.TIER_TWO]: 200,
      [BILLING_PLANS.TIER_THREE]: 500,
    };

    return {
      ...option,
      limit: filteredPlanDetails[index].limit,
      maxCap: mappedTotals[option.label],
    };
  });

  const isAnnual = useMemo(() => recurring?.name === 'year', [recurring?.name]);

  const totalAmount = useMemo(
    () => (recurring?.salesTax ? recurring?.monthlyPrice + recurring?.salesTax : recurring?.monthlyPrice),
    [recurring?.monthlyPrice, recurring?.salesTax]
  );

  const renderContent = (
    <SubscriptionContainer>
      <h1 className="mb-4 text-center">Select Subscription Options</h1>

      <div className="mb-3 plans">
        {planOptionsWithLimit.map((item, index) => {
          const isCurrentPlan = currentPlan === item.label;
          const isEnterprise = item.label === BILLING_PLANS.ENTERPRISE;
          const isSelectedPlan = selectedPlan && selectedPlan?.label === item?.label;
          const isDisabled = appSettings?.activeAccountCount > item?.maxCap || isEnterprise;

          const handleClickPlan = () => {
            if (isDisabled) return;

            setFieldValue('selectedPlan', item);
            setFieldValue('recurring', null);
            setFieldTouched('selectedPlan', true);
          };

          return (
            <div className={`plan ${isSelectedPlan ? 'active' : ''}`} key={index} onClick={handleClickPlan}>
              <h4>{item.label}</h4>
              <p>Unlimited users</p>

              <p className="limit">
                <strong>{item.limit}</strong>
              </p>

              {isCurrentPlan && (
                <Button onClick={noop} variant="secondary" size="sm">
                  Current plan
                </Button>
              )}

              {isEnterprise && (
                <Button
                  onClick={() => window.open('mailto:support@stagebase.com', '_self')}
                  variant="primary"
                  size="sm"
                >
                  Contact us
                </Button>
              )}
            </div>
          );
        })}
      </div>

      {foundProduct && (
        <RecurringContainer>
          {foundProduct.prices
            .sort((a, b) => b.recurring?.interval.localeCompare(a.recurring?.interval))
            .map(({ id, recurring: productRecurring, unitAmount, salesTax }) => {
              const interval = productRecurring?.interval;
              const isMonthly = interval === 'month';
              const amount = unitAmount / 100;
              const tax = salesTax ? salesTax / 100 : 0;

              const monthly = `$${unitAmount / 100 || 0}`;
              const yearly = `$${Math.trunc(unitAmount / 100 / 12) || 0}`;

              const price = isMonthly ? monthly : yearly;

              const handleClickInterval = () => {
                setFieldValue('recurring', {
                  name: interval,
                  priceId: id,
                  monthlyPrice: amount,
                  salesTax: tax,
                });

                setFieldValue('totalPrice', amount);
              };

              const isActive = recurring?.priceId === id;

              return (
                <div
                  key={id}
                  className={`interval ${isActive ? 'active' : ''}`}
                  onClick={handleClickInterval}
                >
                  <h4>{isMonthly ? 'Billed monthly' : 'Billed annually'}</h4>
                  <p>
                    {price} <span>/month</span>
                  </p>
                </div>
              );
            })}
        </RecurringContainer>
      )}

      <PaymentInformationContainer>
        <div className="payment">
          <p>
            {isAnnual ? 'Annual Payment' : 'Monthly Payment'} - $
            {recurring?.monthlyPrice?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || 0.0}
          </p>

          {!!recurring?.salesTax && (
            <p>
              Sales Tax - $
              {recurring?.salesTax?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || 0.0}
            </p>
          )}

          <p>
            <strong>{isAnnual ? 'Annual Total' : 'Monthly Total'}</strong> -{' '}
            <strong>
              $
              {totalAmount?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || 0.0}
            </strong>
          </p>
        </div>

        {!!appSettings?.allDiscountCode && isFreeTier && (
          <div>
            <Form.Label>Discount Code</Form.Label>
            <FormInput name="coupon" />
          </div>
        )}
      </PaymentInformationContainer>
    </SubscriptionContainer>
  );

  const renderButtons = (
    <>
      <ModalLargePaddingCancelButton onClick={onHide} autoWidth />
      <ModalLargePaddingConfirmButton
        onClick={submitForm}
        loading={isSubmitting}
        disabled={!isValid}
        text="Update"
        autoWidth
      />
    </>
  );

  const modalStyle = {
    inner: {
      maxWidth: 'unset',
      padding: '0 !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'var(--light-gray-bg)',
      borderRadius: '0 0 0.813rem 0.813rem',
      padding: '1.875rem 0 2rem 0 !important',
    },
  };

  return (
    <>
      <ModalLargePadding
        size="lg"
        show={show}
        innerProps={modalStyle.inner}
        buttonsContainerProps={modalStyle.buttonContainer}
        content={renderContent}
        buttons={renderButtons}
      />

      <ModalPlans
        showModal={showPlanModal}
        onHide={() => setShowPlanModal(false)}
        formattedProductList={formattedProductList}
      />
    </>
  );
};

export default Subscription;
