import React, { lazy, Suspense } from 'react';
import { Collapse } from 'react-bootstrap';
import Overview from './Overview';
import InternalEngagement from './InternalEngagement';
import CustomerEngagement from './CustomerEngagement';
import styled from 'styled-components';
import { format, subDays } from 'date-fns';
import ToDoSection from './ToDo';
import { useContext } from 'react';
import FullContext from 'stores/Full/fullContext';

const RowGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ firstWidth }) => firstWidth ?? 'auto'} auto;
  @media screen and (max-width: 700px) {
    grid-template-columns: auto;
  }
`;

const ColumnGrid = styled.div`
  display: grid;
  margin: 0 0 0 24px;

  @media screen and (max-width: 700px) {
    grid-template-rows: auto;
    margin: 0;
  }
`;

const Summary = ({ isExpanded, canvas }) => {
  const { isExternal } = useContext(FullContext);

  const dateTodayFormat = (date) => {
    const dateToday = date ? new Date(date) : new Date();
    const dateLastWeek = subDays(new Date(), 7);
    if (dateLastWeek > dateToday) {
      return format(dateToday, 'MMMM dd, yyyy');
    } else {
      return format(new Date(), 'MMM dd, yyyy') === format(dateToday, 'MMM dd, yyyy')
        ? `Today, ${format(dateToday, 'h:mm aaa')}`
        : format(dateToday, 'EEEE, h:mm aaa');
    }
  };

  const RemainingTasks = lazy(() => import('./RemainingTasks'));

  const InternalView = () => (
    <div>
      <RowGrid firstWidth={'60%'}>
        <Overview canvas={canvas} />
        <ColumnGrid>
          <InternalEngagement canvasId={canvas.id} dateTodayFormat={dateTodayFormat} />
          <CustomerEngagement canvasId={canvas.id} dateTodayFormat={dateTodayFormat} />
        </ColumnGrid>
      </RowGrid>
      <Suspense fallback={null}>
        <RemainingTasks canvasId={canvas.id} />
      </Suspense>
    </div>
  );

  const ExternalView = () => (
    <div>
      <RowGrid firstWidth={'50%'}>
        <Suspense fallback={null}>
          <RemainingTasks canvasId={canvas.id} />
        </Suspense>
        <ToDoSection canvasId={canvas.id} />
      </RowGrid>
    </div>
  );

  return (
    <>
      {isExternal ? (
        <Collapse in={isExpanded ?? true}>
          <div>
            <ExternalView />
          </div>
        </Collapse>
      ) : (
        <Collapse in={isExpanded ?? true}>
          <div>
            <InternalView />
          </div>
        </Collapse>
      )}
    </>
  );
};

export default Summary;
