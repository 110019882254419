import React from 'react';
import styled from 'styled-components';
import { RULE_ACTIONS, RULES_FORM_FIELDS, ACTIONS_DEPENDENCIES } from '../../../constants/automationRules';
import DeleteButtonTrashIcon from 'components/DeleteButtonTrashIcon';
import AssignOwnerAction from './ActionsTemplates/AssignOwnerAction';
import AssignCanvasAction from './ActionsTemplates/AssignCanvasAction';
import AddUserToCustomerCanvasAction from './ActionsTemplates/AddUserToCustomerCanvasAction';
import AddContactToProjectsAction from './ActionsTemplates/AddContactToProjectsAction';
import SetCustomerTouchpointStatusAction from './ActionsTemplates/SetCustomerTouchpointStatusAction';
import SetAccountTypeAction from './ActionsTemplates/SetAccountTypeAction';
import SetAccountStatusAction from './ActionsTemplates/SetAccountStatusAction';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.2rem;
  height: 42px;
`;

const ActionComponentContainer = styled.div`
  display: flex;
  align-items: center;
`;

const OrderContainer = styled.div`
  color: var(--playbook-pewter);
  margin-right: 1rem;
  font-weight: bold;
`;

const ACTION_COMPONENT_MAP = {
  [RULE_ACTIONS.AssignOwner]: AssignOwnerAction,
  [RULE_ACTIONS.AssignCanvas]: AssignCanvasAction,
  [RULE_ACTIONS.AddUserToCustomerCanvas]: AddUserToCustomerCanvasAction,
  [RULE_ACTIONS.SetCustomerTouchpointStatus]: SetCustomerTouchpointStatusAction,
  [RULE_ACTIONS.SetAccountType]: SetAccountTypeAction,
  [RULE_ACTIONS.SetAccountStatus]: SetAccountStatusAction,
  [RULE_ACTIONS.AddContactToProjects]: AddContactToProjectsAction,
};

const fallbackComponent = () => null;

/**
 * Renders a single action
 * @property {object} props.action styles from parent component
 * @property {number} props.actionIndex dropdown options {label: 'one', value: '1'}
 * @property {object} props.form formik utils
 * @property {function} props.onDelete callback to execute when deleting an action
 * @returns {JSX.Element}
 */
const RuleAction = ({ action = {}, actionIndex, onDelete = () => { }, form = {} }) => {
  const { setFieldValue, values: formValues, errors, touched } = form;
  const ActionComponent = ACTION_COMPONENT_MAP[action?.actionType] || fallbackComponent;

  const validationErrors =
    Array.isArray(errors[RULES_FORM_FIELDS.actions]) &&
      errors?.[RULES_FORM_FIELDS.actions]?.[actionIndex]?.actionInput
      ? errors[RULES_FORM_FIELDS.actions][actionIndex]?.actionInput
      : [];

  const touchedFields =
    Array.isArray(touched[RULES_FORM_FIELDS.actions]) && touched?.[RULES_FORM_FIELDS.actions]?.[actionIndex]
      ? touched[RULES_FORM_FIELDS.actions][actionIndex]
      : [];

  /**
   * Updates actions list
   * @param {array} actions
   * @returns {void}
   */
  const updateActionsField = (actions) => setFieldValue(RULES_FORM_FIELDS.actions, actions);

  /**
   * Re-order actions
   * @param {array} actions
   * @returns {void}
   */
  const reOrderActions = (actions) => actions.map((action, index) => ({ ...action, orderNo: index + 1 }));

  /**
   * Handles action deletion
   * @returns {void}
   */
  const handleDeleteAction = () => {
    const newActions = [...formValues[RULES_FORM_FIELDS.actions]];
    newActions.splice(actionIndex, 1);

    updateActionsField(reOrderActions(newActions));
    onDelete(newActions);
  };

  /**
   * Handles an action input change
   * actions object has this shape { actionName: '', actionType: '' ,..., actionInput: { canvasId: 123 } }
   * so this function is to update the list of actions after changing the actionInput property inside the action object
   * @param {object} actionInput
   * @returns {void}
   */
  const handleActionInputChange = (actionInput) => {
    const newActions = [...formValues[RULES_FORM_FIELDS.actions]];
    newActions[actionIndex] = { ...action, actionInput };
    updateActionsField(newActions);
  };

  /**
   * If at least one action in the screen depend on current action, disable delete button
   * @returns {void}
   */
  const shouldDisabledDeleteButton = () => {
    const actionsThatDependOnCurrentAction = Object.keys(ACTIONS_DEPENDENCIES).filter((actionKey) =>
      ACTIONS_DEPENDENCIES[actionKey]?.some((actionDependency) => actionDependency === action.actionType)
    );
    // if found actions that depend on this current action, then button should be disabled
    return actionsThatDependOnCurrentAction?.length
      ? formValues[RULES_FORM_FIELDS.actions].some((action) =>
        actionsThatDependOnCurrentAction.includes(action.actionType)
      )
      : false;
  };

  return (
    <Container>
      <ActionComponentContainer>
        <OrderContainer>{action.orderNo}.</OrderContainer>
        <ActionComponent
          action={action}
          onActionInputChange={handleActionInputChange}
          errors={validationErrors}
          touched={touchedFields}
          formValues={formValues}
        />
      </ActionComponentContainer>
      <DeleteButtonTrashIcon onClick={handleDeleteAction} disabled={shouldDisabledDeleteButton()} />
    </Container>
  );
};

export default RuleAction;
