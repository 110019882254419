import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BILLING_PLANS } from 'constants';
import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { highlights, mappedPlanName, pricingPlanList } from 'views/Settings/fixtures';
import { ModalContainer } from './Styles';

const Plans = ({ showModal, onHide, formattedProductList }) => {
  const getHightlight = (planName, feature) => {
    if (planName === BILLING_PLANS.TEAMS && highlights.teams.includes(feature)) {
      return 'pricing-plan__features-teams';
    }

    if (planName === BILLING_PLANS.INTEGRATIONS && highlights.integrations.includes(feature)) {
      return 'pricing-plan__features-integrations';
    }

    return '';
  };

  const memoizedList = useMemo(
    () =>
      pricingPlanList.map((plan) => ({
        ...plan,
        price: formattedProductList.find((item) => item.name === plan.name)?.price || plan.price,
      })),
    [formattedProductList]
  );

  return (
    <Modal size="xl" show={showModal} onHide={onHide} contentClassName="overflow-hidden" enforceFocus={false}>
      <ModalContainer>
        <div className="pricing-modal-header">
          <h2>Subscription Plans</h2>

          <FontAwesomeIcon icon={faXmark} onClick={onHide} />
        </div>

        <div className="pricing-plans">
          {memoizedList.map((plan, index) => {
            return (
              <div className="pricing-plan" key={index}>
                <h4 className={mappedPlanName[plan?.name]}>{plan?.name}</h4>
                <p className="pricing-plan__info">{plan?.subInfo}</p>

                <hr />

                <ul className="pricing-plan__features">
                  {plan?.features.map((feature, index) => {
                    return (
                      <li key={index} className={getHightlight(plan?.name, feature)}>
                        {feature}
                      </li>
                    );
                  })}
                </ul>

                <h4>{plan?.price}</h4>

                {plan?.billing && <p>{plan?.billing}</p>}
              </div>
            );
          })}
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default Plans;
