import { useState, useEffect, useContext, useMemo } from 'react';
import { shortDate } from 'helpers/DateHelper';
import { CustomerCanvas } from 'models';
import dateChangeContext from '../context/dateChangeContext';

const DueDateHooks = ({
  dependentData,
  dynamicTimeline,
  touchpoint,
}) => {
  const {
    activeTouchpoint,
    dateToBeScheduled,
    calculatedDate,
    canvasEndDate,
    dependentStep,
    durationOption,
    durationAmount: durationOptionAmount,
    isDateModified,
    scheduledDate,
    updateScheduledDate,
    updateIsScheduled,
    canvasTargetEnd,
    noDueDate,
    warnings,
    canvasStartDate
  } = useContext(dateChangeContext);
  const [dependentStepTitle, setDependentStepTitle] = useState('');
  const [showChangedDate, setShowChangedDate] = useState(false);
  const [didExceed, setDidExceed] = useState(false);
  const [dateSelected, setDateSelected] = useState(
    activeTouchpoint.status === 'Completed' ? new Date(activeTouchpoint.dateTimeCompleted) : null
  );
  const [formattedNewForecastedEndDate, setFormattedNewForecastedEndDate] = useState();
  const formattedForecastedEndDate = shortDate(new Date(touchpoint?.isScheduledType ? canvasEndDate : canvasTargetEnd));

  const isNewForecastedEndDateSame = useMemo(() =>
    formattedForecastedEndDate === formattedNewForecastedEndDate,
    [formattedNewForecastedEndDate, formattedForecastedEndDate]);

  useEffect(() => {
    const getFormatted = async () => {
      if (!touchpoint || (touchpoint.isScheduledType && !scheduledDate)) return '';

      try {
        const result = await CustomerCanvas.getLookaheadDates({
          customerCanvasId: touchpoint.customerCanvasId,
          customerTouchpointId: touchpoint.id,
          scheduledDate: touchpoint.scheduledDate,
          dateIsScheduled: touchpoint.dateIsScheduled,
          durationOption: touchpoint.durationOption,
          durationOptionAmount: touchpoint.durationOptionAmount,
          customerTouchpointDependentId: touchpoint.customerTouchpointDependentId
        });

        const formattedDateRes = shortDate(touchpoint.isScheduledType ? result.projectedDate : result.endDate);
        setFormattedNewForecastedEndDate(formattedDateRes);
      } catch (e) {
        console.log(e)
      }
    };

    getFormatted();
  }, [warnings, scheduledDate, durationOption, durationOptionAmount, touchpoint]);

  // This makes the correct title show for the dependent step dropdown/modal
  useEffect(() => {
    if (dependentStep) {
      dependentData.forEach((item) => {
        const { touchpoints } = item;
        touchpoints.forEach((touchpoint) => {
          if (touchpoint?.id === dependentStep) {
            setDependentStepTitle(touchpoint?.title);
          }
        });
      });
    }
  }, [dependentData, dependentStep]);

  useEffect(() => {
    // Anytime the dates change, check to see if it matches the currently set date for the step.
    const isNotEqualDate = (val, val2) => new Date(val2).toLocaleDateString() !== new Date(val).toLocaleDateString();
    if (calculatedDate && !activeTouchpoint?.dateToBeScheduled) {
      setShowChangedDate(
        isNotEqualDate(
          calculatedDate,
          noDueDate
            ? canvasTargetEnd
            : activeTouchpoint?.dateTimeCompleted || activeTouchpoint?.endDate
        )
      );
    } else if (scheduledDate) {
      setShowChangedDate(
        isNotEqualDate(
          scheduledDate,
          activeTouchpoint?.dateTimeCompleted || activeTouchpoint?.scheduledDate
        )
      );
    }
  }, [scheduledDate, activeTouchpoint, activeTouchpoint?.endDate,
    calculatedDate, noDueDate, activeTouchpoint?.dateTimeCompleted,
    activeTouchpoint?.scheduledDate, canvasTargetEnd]);

  useEffect(() => {
    const dateSel = () => {
      if (activeTouchpoint.status === 'Completed') {
        return isDateModified ? new Date(scheduledDate) : new Date(activeTouchpoint.dateTimeCompleted);
      } else if (dateToBeScheduled) {
        return scheduledDate ? new Date(scheduledDate) : null;
      } else {
        return null;
      }
    };
    setDateSelected(dateSel);
  }, [dateToBeScheduled, activeTouchpoint, dynamicTimeline, scheduledDate, isDateModified]);

  const handleScheduledDateChange = (date) => {
    if (touchpoint?.customerTouchpointStatus === 'Completed') {
      if (new Date() < date[0]) {
        date.pop();
        date.push(new Date());
        setDidExceed(true);
        setShowChangedDate(true);
      } else if (new Date(canvasStartDate) > date[0]) {
        date.pop();
        date.push(new Date(canvasStartDate));
        setDidExceed(true);
        setShowChangedDate(true);
      } else {
        setDidExceed(false);
      }
    }
    if (date) {
      setDateSelected(date[0]);
      updateScheduledDate(date[0]);
    }
    updateIsScheduled(true);
  };

  return {
    isNewForecastedEndDateSame,
    formattedForecastedEndDate,
    formattedNewForecastedEndDate,
    dependentStepTitle,
    showChangedDate,
    didExceed,
    dateSelected,
    handleScheduledDateChange,
  };
}

export default DueDateHooks;
