import * as signalR from '@microsoft/signalr';
import React, { Component } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Api from '../api/Api';
import Header from '../components/Header/Header';
import { ProtectedRoute, userLoggedIn } from '../helpers/RouteHelper';
import Builder from '../views/Builder';
import Canvases from '../views/Canvases';
import Customer from '../views/Customer';
import CustomerCanvas from '../views/CustomerCanvas';
import Customers from '../views/Customers/Customers';
import Dashboard from '../views/Dashboard';
import Notifications from '../views/Notifications';
import Profile from '../views/Profile/Profile';
import Settings from '../views/Settings/Settings';
import Users from '../views/Users/Users';
import Sidebar from './Sidebar/Sidebar';
import Reporting from '../views/Reporting';
import ReportingState from 'views/reporting/context/reportingState';
import GlobalAutomationRules from '../views/GlobalAutomationRules';
import AuthState from 'stores/Auth/authState';
import CustomerState from 'stores/Customer/customerState';
import FullState from 'stores/Full/fullState';
import FullContext from 'stores/Full/fullContext';
import SettingsState from 'stores/Settings/settingsState';
import { ADMIN, FRONT_LINE, CONTRIBUTOR, CREATOR } from 'constants';
import { Full as FullModel, Integration, LocalStorage } from 'models';
import AccountCustomFields from 'views/CustomFields/Account';
import ProfileCustomFields from 'views/CustomFields/Profile';
import CustomFieldsState from 'stores/CustomFields/customFieldsState';
import AuthContext from 'stores/Auth/authContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import LoginPassword from 'views/Authentication/LoginPassword';

// const Dashboard = lazy(() => import('../views/Dashboard'));
// const Notifications = lazy(() => import('../views/Notifications'));
// const Opportunities = lazy(() => import('../views/Opportunities'));
// const Opportunity = lazy(() => import('../views/Opportunity'));
// const Profile = lazy(() => import('../views/Profile'));
// const Settings = lazy(() => import('../views/Settings'));
// const User = lazy(() => import('../views/User'));
// const Users = lazy(() => import('../views/Users'));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

class Full extends Component {
  state = {
    notificationHubConnection: null,
    loggedIn: userLoggedIn(),
    avatar: null,
    appSettings: {},
    notifications: null,
    unreadNotificationCount: 0,
    sidebarShowing: false,
    token: null,
    stripeEventMessage: null,
  };

  componentDidMount() {
    this.loadIdentifiers();
    this.loadCookie();
  }

  initializeSettings = () => {
    const notificationHubConnection = new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_API_URL + '/notificationHub', {
        // This parameter will cause the SignalR calls to pass all the cookies to the
        // backend including the accesstoken cookie.
        withCredentials: true,
      })
      .configureLogging(signalR.LogLevel.Error)
      .build();

    notificationHubConnection.start().then().catch();
    notificationHubConnection.on('notification', (message) => {
      this.loadNotifications();
    });

    notificationHubConnection.on('stripe-event', (message) => {
      this.setState({
        stripeEventMessage: message,
      });
    });

    this.setState({
      notificationHubConnection: notificationHubConnection,
    });
    this.loadAppSettings();
    this.loadNotifications();
  };

  loadCookie = async () => {
    const accessToken = await FullModel.verifyCookie();

    this.setState({
      loggedIn: !!accessToken,
      token: accessToken,
    });
    this.initializeSettings();
  };

  loadNotifications = async () => {
    const data = await Api.get(`Notification/GetNotifications`);
    this.setState({ notifications: data.items, unreadNotificationCount: data.unreadMessageCount });
  };

  refreshAppSettings = (data) => {
    this.setState({ appSettings: data });
  };

  /**
   * This callback handlers the retrieval of the 3rd party identifiers
   * @returns {Object} identifiers - contains all the identifiers needed
   * for integration.
   */
  loadIdentifiers = async () => {
    const { identifiers } = await Integration.getIdentifiers();
    const {
      nylasClientId,
      nylasMailboxId,
      newRelicAccountId,
      newRelicLicense,
      newRelicAgentId,
      googleClientid,
    } = identifiers;

    sessionStorage.nylasClientId = nylasClientId;
    sessionStorage.nylasMailboxId = nylasMailboxId;
    sessionStorage.newRelicAccountId = newRelicAccountId;
    sessionStorage.newRelicLicense = newRelicLicense;
    sessionStorage.newRelicAgentId = newRelicAgentId;
    sessionStorage.googleClientid = googleClientid;
  };

  readNotification = (notification) => {
    if (notification.notificationStatus !== 'Read') {
      notification.notificationStatus = 'Read';
      this.setState({ unreadNotificationCount: this.state.unreadNotificationCount - 1 });
      Api.get(`Notification/ReadNotification?notificationId=${notification.id}`);
      //   this.loadNotifications();
    }
  };

  changeAvatar = (avatar) => {
    this.setState({ avatar: avatar });
  };

  changeBrandImage = (avatar) => {
    this.loadAppSettings();
  };

  async loadAppSettings() {
    const response = await Api.get('Settings/GetAppSettings');
    LocalStorage.set('tenantLogo', response?.logo || '');
    this.setState({ appSettings: response });
  }

  toggleSidebar = () => {
    this.setState((prev) => ({ sidebarShowing: !prev.sidebarShowing }));
  };

  hideSidebar = () => {
    this.setState({ sidebarShowing: false });
  };

  render() {
    return this.state.loggedIn && Object.keys(this.state.appSettings).length ? (
      <Elements stripe={stripePromise}>
        <FullState>
          <AuthState>
            <FullContext.Consumer>
              {({
                isViewAsCustomer,
                isExternal,
                isContributor,
                isFrontLine,
                isCanvasUpdated,
                userData,
                changeAvatar,
                setCanvasState,
                roleAtleast,
                appSettings,
                populateAppSettings,
              }) => {
                if (Object.keys(appSettings).length === 0 && Object.keys(this.state.appSettings).length) {
                  populateAppSettings(this.state.appSettings);
                }
                return (
                  <div className="app">
                    {this.state.loggedIn ? (
                      <Header
                        appSettings={this.state.appSettings}
                        avatar={userData.avatar}
                        unreadNotificationCount={this.state.unreadNotificationCount}
                        toggleSidebar={this.toggleSidebar}
                        sidebarShowing={this.state.sidebarShowing}
                      />
                    ) : null}

                    <div
                      className={
                        'app-body' +
                        (this.state.sidebarShowing && !isViewAsCustomer ? ' sidebar-mobile-show' : '')
                      }
                    >
                      <ToastContainer autoClose={3000} position="bottom-left" limit={5} />
                      {!isExternal && !isContributor && !isViewAsCustomer && (
                        <Sidebar
                          hideSidebar={this.hideSidebar}
                          unreadNotificationCount={this.state.unreadNotificationCount}
                          isGlobalAutomationEnabled={this.state.appSettings?.globalAutomationEnabled}
                          isConfirmed={userData?.isConfirmed}
                          isExternal={isExternal}
                        />
                      )}

                      <main
                        className={
                          'main' +
                          (isExternal || isContributor || isViewAsCustomer ? ' externalUser ' : '') +
                          (!userData?.isConfirmed && !isExternal ? ' pt-4-3' : '')
                        }
                      >
                        <Routes>
                          <Route
                            path="/select-company"
                            name="Select Company"
                            element={
                              <ProtectedRoute token={this.state.token}>
                                <LoginPassword selectCompany={true} />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/dashboard"
                            element={
                              <ProtectedRoute token={this.state.token} minRole={CONTRIBUTOR}>
                                <Dashboard />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/notifications"
                            element={
                              <ProtectedRoute token={this.state.token}>
                                <Notifications
                                  notifications={this.state.notifications}
                                  readNotification={this.readNotification}
                                />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/accounts"
                            element={
                              <ProtectedRoute token={this.state.token} minRole={FRONT_LINE}>
                                <CustomFieldsState>
                                  <Customers />
                                </CustomFieldsState>
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/accounts/:id"
                            element={
                              <ProtectedRoute token={this.state.token}>
                                <CustomFieldsState>
                                  <CustomerState>
                                    <Customer />
                                  </CustomerState>
                                </CustomFieldsState>
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/accounts/:id/projects/:customerCanvasId/*"
                            element={
                              <Routes>
                                <Route
                                  index
                                  element={
                                    <ProtectedRoute token={this.state.token}>
                                      <CustomFieldsState>
                                        <CustomerState>
                                          <CustomerCanvas
                                            appSettings={this.state.appSettings}
                                            isExternal={isExternal}
                                            currentUserId={userData?.id}
                                            setCanvasState={setCanvasState}
                                            isCanvasUpdated={isCanvasUpdated}
                                            isFrontLine={isFrontLine}
                                          />
                                        </CustomerState>
                                      </CustomFieldsState>
                                    </ProtectedRoute>
                                  }
                                />
                                <Route
                                  path="steps/:touchpointId"
                                  element={
                                    <ProtectedRoute token={this.state.token}>
                                      <CustomFieldsState>
                                        <CustomerState>
                                          <CustomerCanvas
                                            appSettings={this.state.appSettings}
                                            isExternal={isExternal}
                                            currentUserId={userData?.id}
                                            setCanvasState={setCanvasState}
                                            isCanvasUpdated={isCanvasUpdated}
                                            isFrontLine={isFrontLine}
                                          />
                                        </CustomerState>
                                      </CustomFieldsState>
                                    </ProtectedRoute>
                                  }
                                />
                                <Route
                                  path="settings"
                                  element={
                                    <ProtectedRoute token={this.state.token}>
                                      <CustomFieldsState>
                                        <CustomerState>
                                          <CustomerCanvas
                                            appSettings={this.state.appSettings}
                                            isExternal={isExternal}
                                            currentUserId={userData?.id}
                                            setCanvasState={setCanvasState}
                                            isCanvasUpdated={isCanvasUpdated}
                                            isFrontLine={isFrontLine}
                                          />
                                        </CustomerState>
                                      </CustomFieldsState>
                                    </ProtectedRoute>
                                  }
                                />
                              </Routes>
                            }
                          />
                          <Route
                            path="/users"
                            element={
                              <ProtectedRoute token={this.state.token} minRole={ADMIN}>
                                <CustomFieldsState>
                                  <Users upsellTeams={this.state.appSettings?.upsellTeams} />
                                </CustomFieldsState>
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path="/templates"
                            element={
                              <ProtectedRoute token={this.state.token} minRole={CREATOR}>
                                <CustomFieldsState>
                                  <Canvases />
                                </CustomFieldsState>
                              </ProtectedRoute>
                            }
                          />

                          <Route
                            path="/templates/:id/*"
                            element={
                              <Routes>
                                <Route
                                  index
                                  element={
                                    <ProtectedRoute token={this.state.token}>
                                      <Builder appSettings={this.state.appSettings} />
                                    </ProtectedRoute>
                                  }
                                />
                                <Route
                                  path="steps/:touchpointId"
                                  element={
                                    <ProtectedRoute token={this.state.token}>
                                      <Builder appSettings={this.state.appSettings} />
                                    </ProtectedRoute>
                                  }
                                />
                              </Routes>
                            }
                          />

                          {this.state.appSettings?.globalAutomationEnabled && (
                            <Route
                              path="/automation"
                              element={
                                <ProtectedRoute token={this.state.token} minRole={CREATOR}>
                                  <GlobalAutomationRules
                                    upsellTeams={this.state.appSettings?.upsellTeams}
                                    upsellIntegrations={this.state.appSettings?.upsellIntegrations}
                                  />
                                </ProtectedRoute>
                              }
                            />
                          )}

                          <Route
                            path="/reporting"
                            element={
                              <ProtectedRoute token={this.state.token} minRole={CREATOR}>
                                <ReportingState>
                                  <Reporting />
                                </ReportingState>
                              </ProtectedRoute>
                            }
                          />

                          <Route
                            path="/profile"
                            element={
                              <ProtectedRoute token={this.state.token}>
                                <CustomFieldsState>
                                  <Profile
                                    changeAvatar={changeAvatar}
                                    isTrialMode={this.state.appSettings?.upsellTeams}
                                  />
                                </CustomFieldsState>
                              </ProtectedRoute>
                            }
                          />

                          <Route
                            path="/settings"
                            element={
                              <ProtectedRoute token={this.state.token} minRole={FRONT_LINE}>
                                <CustomFieldsState>
                                  <SettingsState>
                                    <AuthContext.Consumer>
                                      {() => {
                                        return (
                                          <Settings
                                            refreshAppSettings={this.refreshAppSettings}
                                            changeBrandImage={this.changeBrandImage}
                                            isAtleastAdmin={roleAtleast(ADMIN)}
                                          />
                                        );
                                      }}
                                    </AuthContext.Consumer>
                                  </SettingsState>
                                </CustomFieldsState>
                              </ProtectedRoute>
                            }
                          />

                          <Route
                            path="/accounts/custom-fields/edit-account-fields"
                            element={
                              <ProtectedRoute token={this.state.token} minRole={ADMIN}>
                                <CustomFieldsState>
                                  <AccountCustomFields />
                                </CustomFieldsState>
                              </ProtectedRoute>
                            }
                          />

                          <Route
                            path="/profile/custom-fields/edit-profile-fields"
                            element={
                              <ProtectedRoute token={this.state.token} minRole={ADMIN}>
                                <CustomFieldsState>
                                  <ProfileCustomFields />
                                </CustomFieldsState>
                              </ProtectedRoute>
                            }
                          />

                          <Route path="/" element={<Navigate replace to="/dashboard" />} />
                        </Routes>
                      </main>
                    </div>
                  </div>
                );
              }}
            </FullContext.Consumer>
          </AuthState>
        </FullState>
      </Elements>
    ) : null;
  }
}

export default Full;
