export const menuItems = [
  {
    header: 'General',
    items: [
      {
        name: 'Auto-advance',
        fieldName: 'autoadvance',
        info: {
          text: `When a step is completed, any dependent step(s) automatically transition from 'Not started' to 'In progress' if not already completed.`,
        },
      },
      {
        name: 'Dynamic Timeline',
        fieldName: 'dynamicTimeline',
        info: {
          text: `If steps finish earlier or later than their due date, due dates for dependent steps dynamically adjust earlier or later accordingly.`,
        },
      },
      {
        name: 'Allow external users to assign and add members to checklist items.',
        fieldName: 'externalsHaveTaskItemPermissions',
      },
    ],
  },
  {
    header: 'System Emails',
    items: [
      {
        name: 'Project Update',
        fieldName: 'projectUpdate',
        property: 'emailSettings/projectUpdate/isEnabled',
        info: {
          text: `This email gives a summary of progress, recent task assignments and changes in step statuses.`,
        },
      },
      {
        name: 'Past Due Step',
        fieldName: 'pastDueStep',
        property: 'emailSettings/pastDueStep/isEnabled',
        info: {
          text: `This email is sent when a step goes past due. Customers will never get system emails for internal steps.`,
        },
      },
      {
        name: 'Invited to Project',
        fieldName: 'invitedToProject',
        property: 'emailSettings/invitedToProject/isEnabled',
        info: {
          text: `This email is sent when a user is added or invited to a project.`,
        },
      },
      {
        name: 'Assigned to a Step or Task',
        fieldName: 'assignedToStepOrTask',
        property: 'emailSettings/assignedToStepOrTask/isEnabled',
        info: {
          text: `This email is sent when a user is assigned to a step or task.`,
        },
      },
      {
        name: 'Comments',
        fieldName: 'comments',
        property: 'emailSettings/comments/isEnabled',
        info: {
          text: `This email is sent when a comment is added to the step. You cannot turn off email notifications for direct mentions.`,
        },
      },
    ],
  },
];

export const daysList = Array.from({ length: 31 }, (_, k) => ({ label: k + 1, value: k + 1 }));

export const getFrequencyList = (frequencyValue) => [
  {
    label: frequencyValue === 1 ? 'Day' : 'Days',
    value: 'Days',
  },
  {
    label: frequencyValue === 1 ? 'Week' : 'Weeks',
    value: 'Weeks',
  },
  {
    label: frequencyValue === 1 ? 'Month' : 'Months',
    value: 'Months',
  },
];

export const projectList = [
  {
    label: 'External project members',
    value: 'External project members',
  },
  {
    label: 'Internal project members',
    value: 'Internal project members',
  },
  {
    label: 'All project members',
    value: 'All project members',
  },
  {
    label: 'Project owner',
    value: 'Project owner',
  },
];

export const stepList = [
  {
    label: 'External step members',
    value: 'External step members',
  },
  {
    label: 'Internal step members',
    value: 'Internal step members',
  },
  {
    label: 'All step members',
    value: 'All step members',
  },
  {
    label: 'Project owner',
    value: 'Project owner',
  },
];

export const sendForList = [
  {
    label: 'Customer-facing steps',
    value: 'Customer-facing steps',
  },
  {
    label: 'Internal steps',
    value: 'Internal steps',
  },
  {
    label: 'All steps',
    value: 'All steps',
  },
];

export const commonSettings = ['invitedToProject', 'assignedToStepOrTask', 'comments'];

export const internalSteps = ['External step members', 'All step members'];

export const initialDropdownValues = {
  sendProjectUpdateTo: projectList[0],
  sendProjectUpdateEvery: daysList[0],
  sendProjectUpdateFrequency: getFrequencyList(1)[0],
  sendPastDueEmailFor: sendForList[0],
  sendPastDueEmailTo: stepList[0],
  invitedToProject: projectList[0],
  assignedToStepOrTask: projectList[0],
  comments: projectList[0],
};

export const initialEmailSettings = {
  autoAdvance: false,
  dynamicTimeline: false,
  externalsHaveTaskItemPermissions: false,
  projectUpdate: false,
  pastDueStep: false,
  invitedToProject: false,
  assignedToStepOrTask: false,
  comments: false,
};
