import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getClickableLink } from '../../../helpers/CommonHelper';
import React, { useContext } from 'react';
import LoadingSpinner from '../../Loading/LoadingSpinner';
import styled from 'styled-components';
import FullContext from 'stores/Full/fullContext';

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: ${({ color }) => color};
`;

export default function Resource({
  iconInfo,
  name,
  href,
  deleting,
  canDelete,
  onDelete,
  hasBottomMargin = true,
  linkStyle = {
    color: 'color-black',
    fontWeight: 'font-weight-normal',
  },
  userId,
}) {
  const { color, fontWeight } = linkStyle;
  const { isViewAsCustomer, isExternal, userData } = useContext(FullContext);

  const isExternalOwner = canDelete && isExternal && String(userId) === userData?.id;
  const isInternalOwner = canDelete && !isViewAsCustomer && !isExternal;

  return (
    <div
      className={`${
        hasBottomMargin ? `mb-3` : ''
      }  d-flex align-items-center justify-content-between deletable-resource`}
    >
      <div className="d-flex align-items-center">
        <StyledIcon
          icon={iconInfo.icon}
          className={'flex-shrink-0 ' + iconInfo.className}
          color={iconInfo.color}
          fixedWidth
          aria-hidden
        />
        <a
          className={`${color} ml-3 ${fontWeight} text-break`}
          href={getClickableLink(href)}
          target="_blank"
          rel="noreferrer"
        >
          {name}
        </a>
      </div>
      {deleting ? (
        <LoadingSpinner className="ml-2" />
      ) : (
        (isExternalOwner || isInternalOwner) && (
          <FontAwesomeIcon icon={faTrash} className="ml-2 cursor-pointer" onClick={onDelete} />
        )
      )}
    </div>
  );
}
