import { useState, useEffect, useRef, useContext, Fragment } from 'react';
import * as Api from '../../../api/Api';
import { Button } from 'react-bootstrap';
import { Mention, MentionsInput } from 'react-mentions';
import Comment from './Comment';
import ModalConfirm from '../Confirm/index';
import LoadingInline from '../../Loading/LoadingInline';
import toast from '../../toast';
import FullContext from 'stores/Full/fullContext';
import { COMMENT_PLACEHOLDER } from 'constants';

export default function CustomerComments({
  customerTouchpoint,
  customerComments,
  mentionUsers,
  setNewComments,
}) {
  const { isViewAsCustomer } = useContext(FullContext);
  const [comments, setComments] = useState(customerComments);
  const [mentions, setMentions] = useState([]);
  const [text, setText] = useState('');
  const [creating, setCreating] = useState(false);
  const [commentToEdit, setCommentToEdit] = useState(null);
  const [commentToDelete, setCommentToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const textInput = useRef(null);

  useEffect(() => {
    if (customerComments) {
      setComments(customerComments);
    }

    return () => {
      setComments([]);
    };
  }, [customerComments]);

  const handleTextChange = (event, mentions) => {
    setText(event.target.value);
    if (mentions) {
      setMentions(mentions);
    }
  };

  const createComment = async () => {
    const text = textInput.current.value;
    if (!text || text.trim() === '') return;

    if (isViewAsCustomer) {
      setText('');
      toast.error('Please exit customer view to do this.');
      return;
    }

    setCreating(true);
    const response = await Api.post(
      'Customer/CreateComment',
      {
        body: text,
        userIds: mentions.map((x) => x.id),
      },
      {
        params: {
          customerTouchpointId: customerTouchpoint.id,
        },
      }
    );
    setMentions([]);
    setCreating(false);
    setText('');
    setNewComments([{ ...response }, ...comments]);
    toast.saved(`Comment created successfully.`);
  };

  const deleteComment = async () => {
    setIsDeleting(true);
    await Api.post('Customer/DeleteComment', commentToDelete.id);
    setNewComments(comments.filter((comment) => comment.id !== commentToDelete.id));
    setIsDeleting(false);
    setCommentToDelete(null);
    toast.saved(`Comment deleted successfully.`);
  };

  return (
    <>
      <h2 className="settings-section-title mt-5">Comments</h2>
      <MentionsInput
        inputRef={textInput}
        classNames={{
          'mentions': 'mentions-wrapper',
          'mentions__input': 'form-control',
          'mentions__suggestions': 'suggestions',
          'mentions__suggestions__item': 'item',
          'mentions__suggestions__item--focused': 'item-focused',
        }}
        value={text}
        disabled={creating}
        onChange={(event, newValue, newPlainTextValue, mentions) => handleTextChange(event, mentions)}
        placeholder={COMMENT_PLACEHOLDER}
        id="comment-text-area"
      >
        <Mention
          trigger="@"
          data={mentionUsers.map((x) => {
            return { id: x.id, display: `${x.firstName} ${x.lastName}` };
          })}
          displayTransform={(id, display) => {
            return '@' + display;
          }}
          allowSpaceInQuery={true}
          allowSuggestionsAboveCursor={true}
        />
      </MentionsInput>
      <div className="text-right mb-4">
        <Button disabled={!text.trim() || creating} variant="primary" onClick={createComment}>
          {creating ? (
            <LoadingInline white text={mentions.length === 0 ? 'Saving...' : 'Sending...'} />
          ) : mentions.length === 0 ? (
            'Save comment'
          ) : (
            'Send'
          )}
        </Button>
      </div>
      <div className="comments-section">
        {comments &&
          comments.length !== 0 &&
          comments.map((comment, index) => (
            <Fragment key={index}>
              <Comment
                customerTouchpointId={customerTouchpoint.id}
                comment={comment}
                isEditing={commentToEdit === comment}
                startEdit={() => setCommentToEdit(comment)}
                finishEdit={() => setCommentToEdit(null)}
                handleDelete={() => setCommentToDelete(comment)}
              />
              {comments[comments.length - 1] !== comments[index] && <hr />}
            </Fragment>
          ))}
      </div>
      <ModalConfirm
        title={`Delete Comment`}
        message={
          <>
            Are you sure you want to delete this comment?
            <br />
            This action cannot be undone.
          </>
        }
        show={commentToDelete !== null}
        onConfirm={deleteComment}
        onHide={() => {
          setCommentToDelete(null);
        }}
        confirmLoading={isDeleting}
      />
    </>
  );
}
