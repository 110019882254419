import {
  RULE_ACTIONS_BY_TRIGGER_OPTIONS,
  ACTIONS_DEPENDENCIES,
  HIDDEN_ACTIONS_BY_TRIGGER_TYPE,
  RULE_ACTIONS,
} from '../../../constants/automationRules';

/**
 * checks if action should be hidden based on trigger type
 * @param {string} triggerType - Can be customer or user trigger.
 * @param {string} actionType - The author of the book.
 * @returns {boolean}
 */
export const actionShouldBeHidden = (triggerType, actionType) => {
  const hiddenActions = HIDDEN_ACTIONS_BY_TRIGGER_TYPE[triggerType];
  return hiddenActions?.some((act) => act === actionType);
};

/**
 * Builds options for actions dropdown
 * @param {string} trigger - Can be customer or user trigger.
 * @param {array} actions - The author of the book.
 * @returns {array}
 */
export const getActionsOptions = (trigger, triggerType, actions = []) => {
  if (!trigger) {
    return [];
  }

  return RULE_ACTIONS_BY_TRIGGER_OPTIONS[trigger].filter((opt) => {
    const actionType = opt.value;

    const actionAlreadyExist = actions.some((action) => action.actionType === actionType);

    // added action should be removed (except for RULE_ACTIONS.AddUserToCustomerCanvas, see SB-2201)
    if (actionAlreadyExist && (opt.value !== RULE_ACTIONS.AddUserToCustomerCanvas && opt.value !== RULE_ACTIONS.SetCustomerTouchpointStatus)) {
      return false;
    }

    // should be hidden
    if (actionShouldBeHidden(triggerType, actionType)) {
      return false;
    }

    const dependencies = ACTIONS_DEPENDENCIES[actionType];
    if (!dependencies?.length) {
      return true;
    }

    // check if all dependent actions were added
    return dependencies.every((dependency) => actions.some((action) => action.actionType === dependency));
  });
};
