import { faLink } from '@fortawesome/pro-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import * as Api from '../../../api/Api';
import toast from '../../toast';
import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
  ModalLargePaddingHeader,
} from '../LargePadding';

export default function ModalNewLink({ show, onHide, touchpointId, customerTouchpointId, onLinksUpdated }) {
  const [name, setName] = useState('');
  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  function validate() {
    if (!name || !name.trim()) {
      return 'Name is not provided.';
    }

    if (!link || !link.trim()) {
      return 'Link is not provided.';
    }

    if (!isValidURL(link)) {
      return 'Link is not a valid url.';
    }
  }

  function isValidURL(string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    );
    return res !== null;
  }

  async function handleOnClick() {
    const error = validate();
    if (error) {
      setError(error);
      return;
    }
    const url = customerTouchpointId
      ? 'Customer/AddLink?customerTouchpointId=' + customerTouchpointId
      : 'Touchpoint/AddLink?touchpointId=' + touchpointId;

    setLoading(true);
    setError(null);
    const response = await Api.post(url, { name: name, path: link });
    onLinksUpdated(response);
    onHide();
    setLoading(false);
    setName('');
    setLink('');
    toast.saved('Link added successfully.');
  }

  function handleOnChangeName(e) {
    setName(e.target.value);
  }

  function handleOnChangeLink(e) {
    setLink(e.target.value);
  }

  return (
    <ModalLargePadding
      show={show}
      onHide={onHide}
      content={
        <>
          <ModalLargePaddingHeader icon={faLink} text="Add a link" />
          {error ? <Alert variant="danger">{error}</Alert> : ''}
          <Form.Group>
            <Form.Control autoFocus value={name} placeholder="Name" onChange={handleOnChangeName} />
          </Form.Group>
          <Form.Group>
            <Form.Control
              autoFocus
              value={link}
              name="link"
              placeholder="Link"
              onChange={handleOnChangeLink}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleOnClick();
                }
              }}
            />
          </Form.Group>
        </>
      }
      buttons={
        <>
          <ModalLargePaddingCancelButton onClick={onHide} />
          <ModalLargePaddingConfirmButton onClick={handleOnClick} loading={loading} text="Add" />
        </>
      }
    />
  );
}
