import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLORS_HEX_TO_RGB } from 'helpers/CommonHelper';
import useIsOverflow from 'helpers/hooks/useIsOverflow';
import React, { useState, useRef, useEffect } from 'react';
import { Tag, TagsContainer } from './Styles';

const Tags = ({ isAddToProjectChecked, canvasTags, handleAddTags, selectedUser, selectedTags }) => {
  const [baseSelectedTags, setBaseSelectedTags] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (selectedTags && selectedTags?.length === 0) setBaseSelectedTags([]);
  }, [selectedTags]);

  useEffect(() => {
    if (!selectedUser) setBaseSelectedTags([]);
  }, [selectedUser]);

  useEffect(() => {
    isAddToProjectChecked ? setIsCollapsed(true) : setIsCollapsed(false);
  }, [isAddToProjectChecked]);

  const tagsRef = useRef(null);

  const isOverflow = useIsOverflow(tagsRef);

  const renderTag = (tag) => {
    const [r, g, b] = COLORS_HEX_TO_RGB[tag.color] || [0, 0, 0];

    const isSelected = baseSelectedTags.some((item) => item.id === tag.id);

    const handleClick = (event) => {
      event.stopPropagation();

      setBaseSelectedTags((prev) => {
        if (isSelected) return prev.filter((item) => item.id !== tag.id);

        return [...prev, { ...tag }];
      });

      handleAddTags(tag?.id);
    };

    return (
      <Tag
        className={'tag cursor-pointer d-flex align-items-center'}
        r={r}
        g={g}
        b={b}
        isSelected={isSelected}
        onClick={handleClick}
      >
        {tag.text}
      </Tag>
    );
  };

  return (
    <div className="d-flex align-items-center">
      <TagsContainer isCollapsed={isCollapsed} ref={tagsRef}>
        {canvasTags.map((tag) => (
          <div key={tag.id}>{renderTag(tag)}</div>
        ))}
      </TagsContainer>

      {!isCollapsed && isOverflow && (
        <FontAwesomeIcon
          icon={faEllipsisH}
          onClick={(e) => {
            e.stopPropagation();
            setIsCollapsed(true);
          }}
        />
      )}
    </div>
  );
};

export default Tags;
