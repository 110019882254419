import { post } from 'api/Api';

class Gmail {
  constructor(props) {
    if (props) Object.assign(this, props);
  }

  static connect(code) {
    return post('Account/ConnectGmail', { code });
  }

  static disconnect() {
    return post('Account/DisconnectGmail');
  }
}

export default Gmail;
