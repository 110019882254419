import React from 'react';
import { FORMIK_FIELD_TYPES } from 'constants';
import DatePicker from './DatePicker';
import DateTimePicker from './DateTimePicker';
import TimePicker from './TimePicker';

const FormPicker = ({
  name,
  type = FORMIK_FIELD_TYPES.DATE,
  disabled,
  handleChange,
  fieldLabel,
  isRequired,
  dateProps,
  timeProps,
}) => {
  switch (type) {
    case FORMIK_FIELD_TYPES.TIME:
      return (
        <TimePicker
          name={name}
          disabled={disabled}
          handleChange={handleChange}
          fieldLabel={fieldLabel}
          isRequired={isRequired}
          timeProps={timeProps}
        />
      );

    case FORMIK_FIELD_TYPES.DATE_TIME:
      return (
        <DateTimePicker
          name={name}
          disabled={disabled}
          handleChange={handleChange}
          fieldLabel={fieldLabel}
          isRequired={isRequired}
          dateProps={dateProps}
          timeProps={timeProps}
        />
      );

    default:
      return (
        <DatePicker
          name={name}
          fieldLabel={fieldLabel}
          isRequired={isRequired}
          disabled={disabled}
          handleChange={handleChange}
          dateProps={dateProps}
        />
      );
  }
};

export default React.memo(FormPicker);
