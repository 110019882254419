import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { useEditableField, useReadOnlyField } from 'components/CustomFields/hooks';
import { DEFAULT_FIELDS, CUSTOM_FIELDS_MODAL_VARIATIONS, FIELD_TYPES, PERMISSIONS } from 'constants';
import { useParser } from 'views/Customers/hooks';
import { debounce, noop } from 'helpers/CommonHelper';
import { CustomerCanvas, Users } from 'models';
import ClientType from './ClientType';
import ModalConfirm from '../Confirm';
import { FormFieldContainer, GrayIcon } from './Styles';
import { Form } from 'react-bootstrap';
import { faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import { getFieldPermission } from 'components/CustomFields/Details/fixtures';

const FormField = ({
  field,
  name,
  menuFooterLink,
  isEdit = false,
  setSaving,
  setHasError,
  variation = CUSTOM_FIELDS_MODAL_VARIATIONS.USERS,
  isDropDownLastItem = false,
  isPreviewMode,
}) => {
  const { values, setFieldValue } = useFormikContext();

  const [showModal, setShowModal] = useState(false);
  const [internalValue, setInternalValue] = useState(null);

  useEffect(() => {
    if (field?.formikValue) setInternalValue(field?.formikValue);
  }, [field?.formikValue]);

  const fieldRef = useRef(null);

  const userId = values?.userId ?? null;
  const canvasId = values?.canvasId ?? null;

  const isReadOnly = isEdit && field?.syncOption === PERMISSIONS.READ_ONLY;

  const { handleParseRequestProperty, handleParseRequestValues } = useParser();

  const handleUpdate = async (value) => {
    const isInvalidDateTime = field?.fieldType === FIELD_TYPES.DATE_AND_TIME && (!value.date || !value.time);

    if (!value && field?.isRequired) return;
    if (isInvalidDateTime) return;

    try {
      setSaving(true);
      setHasError(false);

      const request = {
        field: handleParseRequestProperty(field),
        value: handleParseRequestValues(field, value),
      };

      if (variation === CUSTOM_FIELDS_MODAL_VARIATIONS.USERS) await Users.updateUser(userId, request);
      if (variation === CUSTOM_FIELDS_MODAL_VARIATIONS.PROJECTS)
        await CustomerCanvas.updateCustomerCanvas(canvasId, request);

      setSaving(false);
    } catch (error) {
      setSaving(false);
      setHasError(true);
    }
  };

  const handleChange = useRef(debounce(async (value) => handleUpdate(value), 500));

  const handleBlur = useCallback(() => {
    if (isEdit && !field?.formikValue && field?.isRequired) setShowModal(true);
  }, [isEdit, field?.formikValue, field?.isRequired]);

  const handleClear = async () => {
    if (field?.isRequired || !isEdit) return;

    await handleUpdate('');
  };

  const isEditable =
    (isEdit && variation === CUSTOM_FIELDS_MODAL_VARIATIONS.USERS) ||
    (isEdit && variation === CUSTOM_FIELDS_MODAL_VARIATIONS.PROJECTS);

  const { renderField: renderEditableField } = useEditableField({
    field,
    name,
    menuFooterLink,
    disabled: isReadOnly || isPreviewMode,
    onChange: isEditable ? handleChange.current : noop,
    onBlur: handleBlur,
    onClear: handleClear,
    isModal: !isEdit,
    fieldRef,
    isDropDownLastItem,
  });

  const fieldPermission = getFieldPermission(field?.syncOption);

  const { renderField: renderReadOnlyField } = useReadOnlyField({
    type: field?.fieldType,
    permission: fieldPermission,
    label: field?.fieldLabel,
  });

  const renderModalMessage = () => (
    <p>
      Please fill in a value for <b>{field?.fieldLabel}</b>.
    </p>
  );

  const handleModalClose = useCallback(() => {
    setFieldValue(name, internalValue);

    setShowModal(false);

    setTimeout(() => {
      !internalValue && fieldRef.current && fieldRef.current.focus();
    }, 500);
  }, [internalValue, name, fieldRef, setFieldValue]);

  const modalStyle = {
    inner: {
      maxWidth: '33.563rem',
      padding: '2.5rem 1.25rem !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  const isDateTime = {
    [FIELD_TYPES.DATE]: FIELD_TYPES.DATE,
    [FIELD_TYPES.TIME]: FIELD_TYPES.TIME,
    [FIELD_TYPES.DATE_AND_TIME]: FIELD_TYPES.DATE_AND_TIME,
    [FIELD_TYPES.DATE_TIME]: FIELD_TYPES.DATE_TIME,
  };

  const renderField = isPreviewMode ? renderReadOnlyField() : renderEditableField();

  return (
    <FormFieldContainer
      className={`custom-field ${isReadOnly ? 'read-only' : ''}  ${
        isDateTime[field?.fieldType] ? 'date-time' : ''
      }`}
    >
      {isEdit && (
        <Form.Label>
          {`${field?.fieldLabel} ${field?.isRequired ? '*' : ''}`}
          {isReadOnly && <GrayIcon icon={faLockKeyhole} className="ml-2 fa-sm" />}
        </Form.Label>
      )}

      <div className={`custom-field__input user ${isEdit ? 'is-edit' : ''}`}>
        {field?.fieldLabel === DEFAULT_FIELDS.TYPE && field?.isProtected ? (
          <ClientType field={field} name={name} />
        ) : (
          renderField
        )}
      </div>

      <ModalConfirm
        title="Required field"
        message={renderModalMessage()}
        show={!!showModal}
        hideCancel
        confirmText="Close"
        variant="primary"
        innerProps={modalStyle.inner}
        buttonsContainerProps={modalStyle.buttonContainer}
        autoButtonsWidth
        onConfirm={handleModalClose}
        onHide={() => setShowModal(false)}
      />
    </FormFieldContainer>
  );
};

export default React.memo(FormField);
