import { useCallback } from 'react';
import { FIELD_TYPES, PARSED_FIELDS, DEFAULT_FIELDS, RADIO_GROUP_VALUES } from 'constants';
import { defaultValue } from 'components/Formik/FormRadioGroup/fixtures/initialValues';
import { timezoneList } from 'components/Modals/Customers/fixtures';
import { isJson } from 'helpers/CommonHelper';

export const useParser = () => {
  const handleParseRequestProperty = useCallback((field) => {
    const { fieldLabel, isProtected, targetFieldName } = field;

    if (fieldLabel === DEFAULT_FIELDS.OWNER && isProtected) return PARSED_FIELDS.OWNER_ID;
    if (fieldLabel === DEFAULT_FIELDS.ACTIVE && isProtected) return PARSED_FIELDS.STATUS;
    if (fieldLabel === DEFAULT_FIELDS.TYPE && isProtected) return PARSED_FIELDS.CLIENT_TYPE_ID;
    if (fieldLabel === DEFAULT_FIELDS.PHONE && isProtected) return PARSED_FIELDS.PHONE;

    return targetFieldName;
  }, []);

  const handleParseRequestValues = useCallback((field, value = null) => {
    const { fieldLabel, fieldType, isProtected, formikValue } = field;
    const fieldValue = value ?? formikValue;

    const singleDropdowns = {
      [FIELD_TYPES.SINGLE_DROPDOWN]: FIELD_TYPES.SINGLE_DROPDOWN,
      [FIELD_TYPES.TIMEZONE]: FIELD_TYPES.TIMEZONE,
    };

    const defaultFields = {
      [DEFAULT_FIELDS.OWNER]: DEFAULT_FIELDS.OWNER,
      [DEFAULT_FIELDS.TYPE]: DEFAULT_FIELDS.TYPE,
    };

    if (defaultFields[fieldLabel] && isProtected) return fieldValue ? fieldValue?.response : null;
    if (singleDropdowns[fieldType]) return fieldValue ? fieldValue?.value : null;
    if (fieldType === FIELD_TYPES.MULTI_DROPDOWN)
      return fieldValue ? fieldValue.map((item) => item.value).join(',') : null;
    if (fieldType === FIELD_TYPES.DATE_AND_TIME) return fieldValue?.dateTime;
    if (fieldType === FIELD_TYPES.YES_NO && fieldValue?.label === RADIO_GROUP_VALUES.YES) return true;
    if (fieldType === FIELD_TYPES.YES_NO && fieldValue?.label === RADIO_GROUP_VALUES.NO) return false;
    if (fieldType === FIELD_TYPES.SWITCH) return !fieldValue ? 1 : 0;

    return fieldValue;
  }, []);

  const handleParseResponseValue = (field, value, list = {}, customer = {}) => {
    const { typeList, ownerList } = list;
    const { fieldLabel, fieldType, fieldTypeOptions, isProtected } = field;

    switch (fieldType) {
      case FIELD_TYPES.UNKNOWN:
      case FIELD_TYPES.TEXT:
      case FIELD_TYPES.PARAGRAPH:
      case FIELD_TYPES.NUMBER:
      case FIELD_TYPES.CURRENCY:
      case FIELD_TYPES.EMAIL:
      case FIELD_TYPES.LINK:
      case FIELD_TYPES.SWITCH:
      case FIELD_TYPES.YES_NO:
        return value;

      case FIELD_TYPES.BOOLEAN:
        return Boolean(value) ? defaultValue[0] : defaultValue[1];

      case FIELD_TYPES.DROPDOWN_SINGLE_SELECT:
      case FIELD_TYPES.SINGLE_DROPDOWN: {
        const dropdownValue = value || null;
        const parsedOptions = fieldTypeOptions?.map((item) => (isJson(item) ? JSON.parse(item) : item));
        const customDropdownValue = parsedOptions?.find(({ value }) => value === dropdownValue);
        const ownerId = customer[PARSED_FIELDS.OWNER_ID] ?? null;
        const ownerDropdownValue = ownerList ? ownerList.find(({ response }) => response === ownerId) : [];

        return fieldLabel === DEFAULT_FIELDS.TYPE && isProtected
          ? typeList.find(({ response }) => response === customer[PARSED_FIELDS.CLIENT_TYPE_ID])
          : fieldLabel === DEFAULT_FIELDS.OWNER && isProtected
          ? ownerDropdownValue
          : customDropdownValue;
      }

      case FIELD_TYPES.DROPDOWN_MULTI_SELECT:
      case FIELD_TYPES.MULTI_DROPDOWN: {
        const dropdownValues = value ? value.split(',') : null;
        const parsedOptions = fieldTypeOptions
          ?.map((item) => JSON.parse(item))
          .filter((item) => dropdownValues && dropdownValues.includes(item.value))
          .sort((a, b) => a.label.localeCompare(b.label));

        return parsedOptions;
      }

      case FIELD_TYPES.TIMEZONE: {
        const dropdownValue = value || null;

        return timezoneList?.find(({ value }) => value === dropdownValue);
      }

      case FIELD_TYPES.DATE:
      case FIELD_TYPES.TIME: {
        return value ? new Date(value).toISOString() : null;
      }

      case FIELD_TYPES.DATE_TIME:
      case FIELD_TYPES.DATE_AND_TIME: {
        return {
          date: value ? new Date(value) : null,
          time: value ? new Date(value) : null,
          dateTime: value ? new Date(value).toISOString() : null,
        };
      }

      default:
        return null;
    }
  };

  return {
    handleParseRequestProperty,
    handleParseRequestValues,
    handleParseResponseValue,
  };
};
