import { useContext } from 'react';
import { useFormik } from 'formik';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { camelize, noop } from 'helpers/CommonHelper';
import { DEFAULT_FIELDS } from 'constants';
import { getFieldType } from 'components/CustomFields/Details/fixtures';
import CustomerContext from 'stores/Customer/customerContext';
import { useParser } from 'views/Customers/hooks';
import { editCustomerSchema } from '../validations';

export const useCustomer = (customerId) => {
  const { customerDetail } = useContext(CustomerContext);
  const { editableSections, options } = useContext(CustomFieldsContext);

  const { ownerList, typeList } = options;

  const { handleParseResponseValue } = useParser();

  /**
   * Retrieves the Field Value
   * @param fieldLabel
   * @param fieldTypeOptions
   * @returns Field Value
   */
  const getFieldValues = (fieldLabel, fieldTypeOptions) =>
    fieldLabel === DEFAULT_FIELDS.OWNER
      ? ownerList
      : fieldLabel === DEFAULT_FIELDS.TYPE
      ? typeList
      : fieldTypeOptions?.map((value) => JSON.parse(value));

  const newDetailsFields = editableSections.map((section) => ({
    ...section,
    fields: section.fields
      .filter(({ isHidden }) => !isHidden)
      .map((field) => {
        const list = { typeList, ownerList };
        const fieldType = getFieldType(field?.fieldType);
        const fieldTypeOptions = getFieldValues(field?.fieldLabel, field?.fieldTypeOptions);
        const targetFieldName = camelize(field?.targetFieldName);
        const mappedFieldName = customerDetail[targetFieldName] ?? customerDetail[field?.targetFieldName];
        const formikValue = handleParseResponseValue(field, mappedFieldName, list, customerDetail);

        return {
          ...field,
          fieldType,
          fieldTypeOptions,
          formikValue,
          targetFieldName,
        };
      }),
  }));

  /**
   * Add Custom Field Formik Instance
   */
  const formikBag = useFormik({
    initialValues: { customerId, detailsFields: newDetailsFields },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: editCustomerSchema,
    onSubmit: noop,
  });

  return {
    formikBag,
  };
};
