const ReportingReducer = (state, action) => {
  switch (action.type) {
    case 'populate_account_filters':
      return {
        ...state,
        accountFilters: action.payload
      }
    case 'add_active_account_filter':
      return {
        ...state,
        activeAccountFilters: [...state.activeAccountFilters, action.payload]
      }
    case 'remove_active_account_filter':
      return {
        ...state,
        activeAccountFilters: state.activeAccountFilters.filter((filterType) => filterType !== action.payload)
      }
    case 'add_active_time_range':
      return {
        ...state,
        activeTimeRangeFilters: [...state.activeTimeRangeFilters, action.payload]
      }
    case 'remove_active_time_range':
      return {
        ...state,
        activeTimeRangeFilters: state.activeTimeRangeFilters.filter((filterType) => filterType?.label !== action.payload.label)
      }
    case 'set_active_template':
      return {
        ...state,
        activeTemplate: action.payload.id,
        activeTemplateTitle: action.payload.name,
        activePhase: ''
      }
    case 'set_active_phase':
      return {
        ...state,
        activePhase: action.payload
      }
    case 'set_start_and_end_dates':
      return {
        ...state,
        startDate: action.payload.start,
        endDate: action.payload.end
      }
    default:
      return state
  }
}

export default ReportingReducer