import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import Select from 'components/Select';
import {
  RULES_FORM_FIELDS,
  CONDITION_OPERATORS_BY_TYPE_OPTIONS,
  OPERAND_TYPES,
  OPERATORS,
  CONDITION_TYPE_DISPLAY_VALUE_MAP,
  LEFT_OPERAND_PATH_NAME,
} from '../../../constants/automationRules';
import DeleteButtonTrashIcon from 'components/DeleteButtonTrashIcon';
import ErrorText from './ErrorText';
import useCanvases from '../../../helpers/hooks/useCanvases';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.3rem;
  height: 42px;
`;

const ConditionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  > * {
    margin: 0 0.5rem;
  }
`;

const ConditionTypeText = styled.div`
  font-weight: bold;
  font-size: 0.875rem;
  color: var(--playbook-pewter);
`;

const InputContainer = styled.div`
  position: relative;
`;

/**
 * Component to build a single condition
 * @property {number} props.conditionIndex
 * @property {object} props.condition
 * @property {object} props.form formik utils
 * @property {array} props.leftOperandOptions
 * @property {array} props.leftOperands
 * @returns {JSX.Element}
 */
const RuleCondition = ({ conditionIndex, condition, form, leftOperandOptions = [], leftOperands = [] }) => {
  const { setFieldValue, values: formValues, errors = {}, touched = {} } = form;
  const { canvasesOptions } = useCanvases();

  const shouldShowOperatorInput = (leftOperandType, operatorType) =>
    [OPERAND_TYPES.string, OPERAND_TYPES.datetime, OPERAND_TYPES.numeric, OPERAND_TYPES.func_canvas].includes(
      leftOperandType
    ) && ![OPERATORS.isNullOrEmpty, OPERATORS.isNotNullOrEmpty].includes(operatorType);

  const [operatorsOptions, setOperatorsOptions] = useState([]);
  const [showRightOperatorInput, setShowRightOperatorInput] = useState(
    shouldShowOperatorInput(condition.leftOperandType, condition.logicalOperator)
  );
  const [rightOperandOptions, setRightOperandOptions] = useState([]);

  const currentLeftOperand =
    leftOperands.find((operand) => operand.anonPathName === condition.leftOperand) || null;

  const validationErrors =
    Array.isArray(errors[RULES_FORM_FIELDS.conditions]) &&
    errors?.[RULES_FORM_FIELDS.conditions]?.[conditionIndex]
      ? errors[RULES_FORM_FIELDS.conditions][conditionIndex]
      : [];

  const touchedFields =
    Array.isArray(touched[RULES_FORM_FIELDS.conditions]) &&
    touched?.[RULES_FORM_FIELDS.conditions]?.[conditionIndex]
      ? touched[RULES_FORM_FIELDS.conditions][conditionIndex]
      : [];

  const logicalOperatorOptionValue =
    operatorsOptions.find((opt) => opt.value === condition.logicalOperator) || null;

  const leftOperandOptionValue = condition.leftOperand
    ? { value: condition.leftOperand, label: currentLeftOperand?.friendlyName || '' }
    : null;

  const groupedOpt =
    leftOperandOptions.find((opt) =>
      opt.options.some((innerOpt) => innerOpt.value === condition.leftOperand)
    ) || null;

  /**
   * Determines if error should be visible
   * @param {string} field form field
   * @returns {void}
   */
  const shouldShowFieldError = (field) => touchedFields[field] && validationErrors[field];

  /**
   * Updates condition form field
   * @param {array} conditions conditions list
   * @returns {void}
   */
  const updateConditionsField = (conditions) => setFieldValue(RULES_FORM_FIELDS.conditions, conditions);

  /**
   * Handles left operand change (leftOperand is a property in the conditions object)
   * Ex. In the condition "If Name starts with Iduart"
   * Left operand is: "Name"
   * @param {object} option selected left operand
   * @returns {void}
   */
  const handleLeftOerandChange = (option) => {
    const leftOperand = option.value;
    const leftOperandType = option?.leftOperand?.valueType;

    const newConditions = [...formValues[RULES_FORM_FIELDS.conditions]];

    newConditions[conditionIndex] = {
      ...condition,
      leftOperand,
      leftOperandType,
      logicalOperator: null,
      rightOperand: '',
    };
    updateConditionsField(newConditions);
  };

  /**
   * Handles logical operator change (logicalOperator is a property in the conditions object)
   * Ex. In the condition "If Name starts with Iduart"
   * Logical Operator is: "Starts with"
   * @param {string} option selected logical operator
   * @returns {void}
   */
  const handleLogicalOperatorChange = (option) => {
    const logicalOperator = option.value;
    const newConditions = [...formValues[RULES_FORM_FIELDS.conditions]];
    newConditions[conditionIndex].logicalOperator = logicalOperator;
    updateConditionsField(newConditions);
  };

  /**
   * Handles right operand change (rightOperand is a property in the conditions object)
   * Ex. In the condition "If Name starts with Iduart"
   * Right operand is: "Iduart"
   * @param {string} event javascript text input event
   * @returns {void}
   */
  const handleRightOperandChange = (event) => {
    const rightOperand = event?.target?.value ?? event;
    const newConditions = [...formValues[RULES_FORM_FIELDS.conditions]];
    newConditions[conditionIndex].rightOperand = rightOperand;

    updateConditionsField(newConditions);
  };

  /**
   * Handles condition deletion
   * @returns {void}
   */
  const handleDeleteCondition = () => {
    const newConditions = [...formValues[RULES_FORM_FIELDS.conditions]];
    newConditions.splice(conditionIndex, 1);

    updateConditionsField(newConditions);
  };

  /**
   * @param {string} pathName left operand's path name
   * A callback to populate right operand dropdown component
   * based from the left operand's path name.
   * NOTE: Currently, there's no available service for this instance.
   * It's kind of a preparation for this kind of implementation
   */
  // const populateRightOperandOptions = useCallback((pathName) => {
  //   return;
  // }, [canvasesOptions, currentLeftOperand?.anonPathName]);

  useEffect(() => {
    if (!condition.leftOperand) {
      return;
    }
    const leftOperandType = currentLeftOperand?.valueType;
    let operatorsByOperandType = null;
    if (leftOperandType === OPERAND_TYPES.func_canvas) {
      // For future implementation if we need to have different values of operators based from
      // any of the left operand's property. Also, getOperator (which still not exist)
      // should contain an endpoint call or a fixture retrieval of the desired operator values.
      // operatorsByOperandType = getOperator() || [];
      operatorsByOperandType = CONDITION_OPERATORS_BY_TYPE_OPTIONS[leftOperandType] || [];
      const _rightOperandOptions =
        currentLeftOperand?.anonPathName === LEFT_OPERAND_PATH_NAME.LIST_OF_PROJECTS
          ? [...canvasesOptions]
          : // : [...populateRightOperandOptions()]
            [];
      setRightOperandOptions(_rightOperandOptions);
    } else {
      operatorsByOperandType = CONDITION_OPERATORS_BY_TYPE_OPTIONS[leftOperandType] || [];
    }
    setOperatorsOptions(operatorsByOperandType);
    setShowRightOperatorInput(shouldShowOperatorInput(leftOperandType, condition.logicalOperator));
  }, [
    condition.leftOperand,
    currentLeftOperand?.valueType,
    condition.logicalOperator,
    canvasesOptions,
    currentLeftOperand?.anonPathName,
  ]);

  return (
    <Container>
      <ConditionContainer>
        {conditionIndex > 0 && (
          <ConditionTypeText>{CONDITION_TYPE_DISPLAY_VALUE_MAP[condition.conditionType]}</ConditionTypeText>
        )}
        {groupedOpt?.label && <div>{groupedOpt?.label}</div>}
        <InputContainer>
          <Select
            options={leftOperandOptions}
            value={leftOperandOptionValue}
            onChange={handleLeftOerandChange}
            containerWidth={200}
          />
          {shouldShowFieldError('leftOperand') && <ErrorText>{validationErrors.leftOperand}</ErrorText>}
        </InputContainer>
        {Boolean(condition.leftOperand) && (
          <InputContainer>
            <Select
              options={operatorsOptions}
              value={logicalOperatorOptionValue}
              onChange={handleLogicalOperatorChange}
              containerWidth={200}
            />
            {shouldShowFieldError('logicalOperator') && (
              <ErrorText>{validationErrors.logicalOperator}</ErrorText>
            )}
          </InputContainer>
        )}
        {showRightOperatorInput && (
          <InputContainer>
            {condition.leftOperandType === OPERAND_TYPES.func_canvas ? (
              <Select
                placeholder="Select template..."
                containerWidth={250}
                options={rightOperandOptions.sort((a, b) => a.label.localeCompare(b.label))}
                onChange={handleRightOperandChange}
                value={condition.rightOperand}
              />
            ) : (
              <Form.Control
                type="text"
                value={condition.rightOperand}
                onChange={handleRightOperandChange}
                placeholder="Fill in..."
              />
            )}
            {shouldShowFieldError('rightOperand') && <ErrorText>{validationErrors.rightOperand}</ErrorText>}
          </InputContainer>
        )}
      </ConditionContainer>
      <DeleteButtonTrashIcon onClick={handleDeleteCondition} />
    </Container>
  );
};

export default RuleCondition;
