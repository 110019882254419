export const SETTINGS_PERMISSIONS = {
  READWRITE: 'readWrite',
  READONLY: 'readOnly',
  NONE: 'none',
};

export const LINK_BUTTON_LABEL = {
  VIEW: 'Preview',
  EDIT: 'Preview & Edit',
};

export const EMPLOYEE_PERMISSIONS_FIELDS = {
  IDEAS: 'ideas',
  EDIT_PROJECT: 'canEditProject',
  EDIT_PROJECT_SETTINGS: 'canEditProjectSettings',
};

export const EMPLOYEE_PERMISSIONS = {
  IDEAS: 'Ideas',
  EDIT_PROJECT: 'Edit project (add/remove steps, change dependencies...)',
  EDIT_PROJECT_SETTINGS: 'Edit project settings (system emails sent...)',
};

export const EMAIL_CONFIG_LABELS = {
  INVITED_TO_PROJECT: 'Invited to Project',
  ASSIGNED: 'Assigned to Step or Task',
  PROJECT_UPDATE: 'Project Update',
  PROJECT_UPDATES: 'Project Updates',
  PASTDUE: 'Past Due Step',
  PASTDUES: 'Past Due Steps',
  COMMENTS: 'Comments',
  DIRECT_MENTION: 'Direct Mention',
  UPLOAD: 'File or Link Uploaded',
};

export const EMAIL_CONFIG_FIELDS = {
  INVITED_TO_PROJECT: 'addToProject',
  ASSIGNED: 'assignToStepOrTask',
  PROJECT_UPDATE: 'projectUpdate',
  PASTDUE: 'pastDueStep',
  COMMENT: 'comment',
  UPLOAD: 'fileOrLinkUploaded',
};

export const EDITOR_LABELS = {
  PREVIEW: 'preview',
  EDIT: 'edit',
};

export const BILLING_PLANS = {
  FREE: 'Free',
  TEAMS: 'Teams',
  INTEGRATIONS: 'Teams + Integrations',
  TIER_ONE: 'Tier 1',
  TIER_TWO: 'Tier 2',
  TIER_THREE: 'Tier 3',
  ENTERPRISE: 'Enterprise',
};
