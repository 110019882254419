import { useContext, useMemo } from 'react';
import { useFormik } from 'formik';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { camelize, noop } from 'helpers/CommonHelper';
import { useParser } from 'views/Customers/hooks';
import { getFieldType } from 'components/CustomFields/Details/fixtures';
import FullContext from 'stores/Full/fullContext';

export const useCustomerCanvas = (canvas) => {
  const { projects } = useContext(CustomFieldsContext);
  const { isExternal } = useContext(FullContext);

  const { handleParseResponseValue } = useParser();

  const newProjectFields = projects?.editableFields
    ?.filter(({ isHidden }) => !isHidden)
    .map((field) => {
      const { targetFieldName, fieldType, fieldTypeOptions } = field;

      const getFieldName = () => {
        if (!!canvas[newTargetFieldName]) return canvas[newTargetFieldName];
        if (!!canvas[targetFieldName]) return canvas[targetFieldName];
        return '';
      };

      const newTargetFieldName = camelize(targetFieldName);
      const newFieldType = getFieldType(fieldType);
      const newFieldTypeOptions = fieldTypeOptions ? fieldTypeOptions.map((value) => JSON.parse(value)) : [];
      const mappedFieldName = getFieldName();
      const formikValue = mappedFieldName ? handleParseResponseValue(field, mappedFieldName) : null;

      return {
        ...field,
        fieldType: newFieldType,
        fieldTypeOptions: newFieldTypeOptions,
        targetFieldName: newTargetFieldName,
        formikValue,
      };
    });

  const projectFields = useMemo(
    () => (isExternal ? newProjectFields.filter(({ isProtected }) => isProtected) : newProjectFields),
    [isExternal, newProjectFields]
  );

  /**
   * Add Custom Field Formik Instance
   */
  const formikBag = useFormik({
    initialValues: { projectFields, canvasId: canvas.id, isChangesSaving: null, hasServerError: null },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: noop,
  });

  return {
    formikBag,
  };
};
