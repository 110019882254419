import {
  fieldTypeEnums,
  fieldTypeIcons,
  getSourceTypes,
  permissionEnums,
  sourceIcons,
  stagebaseEnums,
  stagebaseIcons,
} from 'components/Modals/CustomFields/fixtures';
import { NEW_STAGEBASE_FIELD, EXTERNAL_SOURCE, INTERNAL_SOURCE, FIELD_TYPES } from 'constants';

const CustomFieldsReducer = (state, action) => {
  switch (action.type) {
    case 'get_enums': {
      const enums = action.payload;
      const { sourceTypes, customFieldTypes, syncOptions, sources } = enums;

      const stagebaseFields = sourceTypes.filter((field) => field.value === INTERNAL_SOURCE);
      const externalFields = sourceTypes.filter((field) => field.value !== INTERNAL_SOURCE);

      const sourcesList = Object.keys(sources).filter(
        (source) => source !== NEW_STAGEBASE_FIELD.split(' ').join('')
      );

      const getMappedSources = (source, group) =>
        sources[source][group].map(({ fieldLabel, fieldName, ...option }) => ({
          ...option,
          label: fieldLabel,
          value: fieldLabel,
          targetSource: fieldName,
        }));

      const transformedSources = sourcesList.map((source) => ({
        [source]: Object.keys(sources[source]).map((group) => ({
          label: group,
          options: getMappedSources(source, group),
        })),
      }));

      const transformedFlattenedExternalFields = sourcesList
        .map((source) =>
          Object.keys(sources[source])
            .map((group) => getMappedSources(source, group))
            .flat()
        )
        .flat();

      const transformedExternalSourceTypes = externalFields.map(({ display, value }) => ({
        value: display,
        label: display,
        icon: display === EXTERNAL_SOURCE.SALESFORCE ? sourceIcons[0] : sourceIcons[1],
        request: getSourceTypes(display),
        response: value,
      }));

      const transformedStagebaseFields = stagebaseFields.map(({ display, value }, index) => ({
        value: display,
        label: display,
        icon: stagebaseIcons[index],
        request: stagebaseEnums[index],
        response: value,
      }));

      const transformedFieldTypes = customFieldTypes.map(({ display, value }, index) => ({
        value: value === FIELD_TYPES.CURRENCY ? value : display,
        label: display,
        icon: fieldTypeIcons[index],
        request: fieldTypeEnums[index],
        response: value,
      }));

      const transformedPermissions = syncOptions.map(({ display, value }, index) => ({
        value: display,
        label: display,
        request: permissionEnums[index],
        response: value,
      }));
      const dataSourceList = [
        {
          label: 'Extended Data Source',
          options: transformedExternalSourceTypes,
        },
        {
          label: 'Stagebase Only',
          options: transformedStagebaseFields,
        },
      ];

      const fieldLabelLookup = {};

      transformedFlattenedExternalFields.forEach((field) => {
        fieldLabelLookup[field.label] = field;
      });

      return {
        ...state,
        options: {
          ...state.options,
          fieldTypeList: transformedFieldTypes,
          permissionList: transformedPermissions,
          dataSourceList,
          externalFields: transformedSources,
          flattenedExternalFields: transformedFlattenedExternalFields,
          externalFieldsLookUp: fieldLabelLookup,
        },
      };
    }

    case 'get_owner_types': {
      const { owners, types } = action.payload;

      const transformedTypes = types?.items.map(({ id, name }) => ({
        value: name,
        label: name,
        response: id,
      }));

      const transformedOwners = owners?.map(({ primaryId, firstName, lastName, defaultOwner }) => ({
        value: `${firstName} ${lastName}`,
        label: `${firstName} ${lastName}`,
        isDefaultOwner: defaultOwner,
        response: primaryId,
      }));

      return {
        ...state,
        options: {
          ...state.options,
          ownerList: transformedOwners,
          typeList: transformedTypes,
        },
      };
    }

    case 'get_editable_sections': {
      const response = action.payload;

      return {
        ...state,
        details: {
          ...state.details,
          editableSections: response,
        },
      };
    }

    case 'get_editable_fields': {
      const response = action.payload;

      return {
        ...state,
        details: {
          ...state.details,
          editableFields: response,
        },
      };
    }

    case 'is_changes_saving': {
      const value = action.payload;

      return {
        ...state,
        isChangesSaving: value,
      };
    }

    case 'has_server_error': {
      const value = action.payload;

      return {
        ...state,
        hasServerError: value,
      };
    }

    case 'get_editable_profile_sections': {
      const response = action.payload;

      return {
        ...state,
        profile: {
          ...state.profile,
          editableSections: response,
        },
      };
    }

    case 'get_editable_profile_fields': {
      const response = action.payload;

      return {
        ...state,
        profile: {
          ...state.profile,
          editableFields: response,
        },
      };
    }

    case 'get_editable_projects_sections': {
      const response = action.payload;

      return {
        ...state,
        projects: {
          ...state.projects,
          editableSections: response,
        },
      };
    }

    case 'get_editable_projects_fields': {
      const response = action.payload;

      return {
        ...state,
        projects: {
          ...state.projects,
          editableFields: response,
        },
      };
    }

    case 'update_contacts_sections': {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          sections: action.payload,
        },
      };
    }

    case 'update_contacts_editable_sections': {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          editableSections: action.payload,
        },
      };
    }

    case 'update_contacts_editable_fields': {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          editableFields: action.payload,
        },
      };
    }

    case 'is_data_loading': {
      const value = action.payload;

      return {
        ...state,
        isLoading: value,
      };
    }

    case 'set_mode': {
      const mode = action.payload;

      return {
        ...state,
        mode,
      };
    }

    default:
      return state;
  }
};

export default CustomFieldsReducer;
