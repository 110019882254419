import { Table } from 'react-bootstrap';
import styled from 'styled-components';

export const OptionsContainer = styled.div`
  .dropdown-menu {
    border: 0;
    box-shadow: var(--box-shadow-menu);
  }

  .dropdown-menu.show {
    transform: translate3d(0px, 26px, 0px) !important;
    inset: 0px 0px auto auto !important;
    margin-top: 0.625rem !important;
  }

  .dropdown-item {
    padding: 0.5rem 1.5rem;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RowContainer = styled.div`
  overflow-x: auto;
`;

export const StyledTable = styled(Table)`
  & > thead {
    & > tr {
      & > td {
        padding: 0.75rem 2.8125rem 0.75rem 0;
        vertical-align: middle;
        white-space: nowrap;
        min-width: 4rem;
        color: var(--playbook-pewter);
      }
    }
  }

  & > tbody {
    & > tr {
      &:hover {
        cursor: pointer;
      }

      & > td {
        padding: 1rem 2.8125rem 1rem 0;
        vertical-align: middle;
        max-width: 19.625rem;
        white-space: nowrap;

        &.is-paragraph {
          max-width: 15rem;
          white-space: unset;
        }

        & > .value {
          display: inline;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
      }
    }
  }
`;

export const DeleteModalMessage = styled.p`
  margin: 0;
`;
