import * as uuid from 'uuid';

export const mockDetails = {
  sections: [
    {
      id: '668624e8-4053-4299-aaa2-e4a9302aad2a',
      sectionName: 'Information',
      category: 'default',
      fields: [
        {
          id: uuid.v4(),
          fieldType: 'Text',
          fieldName: 'Address',
          currency: null,
          value: '123 Good Way, Lehi, UT 84043',
          dropdownValues: [],
          permission: 'Syncs',
          category: 'default',
          dataSource: 'NewStagebaseField',
          isSortedFieldValues: false,
          isRequired: true,
          isMergeTag: true,
          sectionId: '668624e8-4053-4299-aaa2-e4a9302aad2a',
        },
        {
          id: uuid.v4(),
          fieldType: 'Text',
          fieldName: 'External ID',
          currency: null,
          value: '123',
          dropdownValues: [],
          permission: 'ReadOnly',
          category: 'default',
          dataSource: 'NewStagebaseField',
          isSortedFieldValues: false,
          isRequired: true,
          isMergeTag: true,
          sectionId: '668624e8-4053-4299-aaa2-e4a9302aad2a',
        },
        {
          id: uuid.v4(),
          fieldType: 'Currency',
          fieldName: 'Revenue',
          currency: 'Dollars',
          value: '22',
          dropdownValues: [],
          permission: 'Syncs',
          category: 'default',
          dataSource: 'NewStagebaseField',
          isSortedFieldValues: false,
          isRequired: true,
          isMergeTag: true,
          sectionId: '668624e8-4053-4299-aaa2-e4a9302aad2a',
        },
      ],
    },
    {
      id: '778624e8-4053-4299-aaa2-e4a9302aad2a',
      sectionName: 'Owner',
      category: 'default',
      fields: [
        {
          id: uuid.v4(),
          fieldType: 'Single-select dropdown',
          dropdownValues: [
            { id: 'cbcfca81-9f55-4696-a8b3-491a14e5b64e', value: 'aer', label: 'aer' },
            { id: 'aacb6fa1-365b-403e-9554-c91c2520d024', value: 'Try', label: 'Try' },
          ],
          fieldName: 'Owner',
          currency: null,
          value: 'John Doe',
          permission: 'WontSync',
          category: 'default',
          dataSource: 'NewStagebaseField',
          isSortedFieldValues: true,
          isRequired: true,
          isMergeTag: true,
          sectionId: '778624e8-4053-4299-aaa2-e4a9302aad2a',
        },
      ],
    },
    {
      id: '888624e8-4053-4299-aaa2-e4a9302aad2a',
      sectionName: 'Status',
      category: 'default',
      fields: [
        {
          id: uuid.v4(),
          fieldType: 'Switch',
          fieldName: 'Active',
          currency: null,
          value: true,
          dropdownValues: [],
          permission: null,
          category: 'default',
          dataSource: 'NewStagebaseField',
          isSortedFieldValues: false,
          isRequired: true,
          isMergeTag: true,
          sectionId: '888624e8-4053-4299-aaa2-e4a9302aad2a',
        },
      ],
    },
    {
      id: '998624e8-4053-4299-aaa2-e4a9302aad2a',
      sectionName: 'Type',
      category: 'default',
      fields: [
        {
          id: uuid.v4(),
          fieldType: 'Single-select dropdown',
          dropdownValues: [{ id: 'cbcfca81-9f55-4696-a8b3-491a14e5b64e', value: 'GGGG', label: 'GGGG' }],
          fieldName: 'Type',
          currency: null,
          value: 'Gold Tier',
          permission: 'WontSync',
          category: 'default',
          dataSource: 'NewStagebaseField',
          isSortedFieldValues: false,
          isRequired: true,
          isMergeTag: true,
          sectionId: '998624e8-4053-4299-aaa2-e4a9302aad2a',
        },
      ],
    },
  ],
};

export const mockIds = {
  'section-id-1': ['field-id-1', 'field-id-2', 'field-id-3'],
  'section-id-2': ['field-id-4'],
  'section-id-3': ['field-id-5'],
  'section-id-4': ['field-id-6'],
};

export const mockRelatedAccounts = 25;
