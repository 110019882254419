import styled from 'styled-components';

export const SubscriptionContainer = styled.div`
  padding: 2rem 2.625rem 2.438rem 2.625rem;

  p {
    margin: 0;
  }

  .plans {
    display: flex;
    gap: 0.625rem;
    justify-content: center;

    .plan {
      outline: 1px solid var(--pool-water);
      border-radius: 0.75rem;
      padding: 1rem;
      text-align: center;
      width: 10.938rem;
      height: 10.938rem;
      display: flex;
      flex-direction: column;
      transition: all 0.4s;

      h4 {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.625rem;
        color: var(--schedule-sapphire);
      }

      .limit {
        font-weight: bold;
        margin-block: 0.75rem;
        line-height: 1.125rem;
      }

      button {
        margin-top: auto;
      }
    }

    .plan:hover {
      cursor: pointer;
      background-color: var(--light-gray-bg);
    }

    .plan.active {
      outline-width: 5px;
    }
  }

  @media only screen and (max-width: 992px) {
    .plans {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      place-items: center;
    }
  }

  @media only screen and (max-width: 410px) {
    .plans {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      place-items: center;
    }
  }
`;

export const RecurringContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3.188rem;
  gap: 1.8rem;

  .interval {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    outline: 1px solid var(--pool-water);
    padding: 1rem;
    width: 18.75rem;
    border-radius: 0.625rem;
    cursor: pointer;
    transition: all 0.4s;
    font-size: 1.25rem;
    color: var(--schedule-sapphire);

    h4,
    p {
      font-weight: bold;
    }

    span {
      font-weight: 400;
    }
  }

  .interval.active {
    outline-width: 5px;
  }

  .interval:hover {
    background-color: var(--light-gray-bg);
    border-color: var(--playbook-pewter);
  }

  @media only screen and (max-width: 410px) {
    flex-direction: column;
    align-items: center;

    .interval {
      width: 10.938rem;
    }
  }
`;

export const PaymentInformationContainer = styled.div`
  margin-top: 2.625rem;
  padding-inline: 2.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .payment {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 992px) {
    padding-inline: 0.2rem;
  }

  @media only screen and (max-width: 426px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }
`;
