import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  margin: auto;
`;

export const ModalWrapper = styled.div`
  margin: 200px auto;
  width: 670px;
  background: #fff;
  border-radius: 13px;
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.15);
  padding: 32px 40px;
`;

export const HeaderText = styled.h1`
  width: 100%;
  text-align: center;
`;

export const CloseBtn = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const DateFields = styled.div`
  margin-top: 20px;
`;

export const DependentStepHeader = styled.p`
  margin: 16px 0 8px;
  opacity: ${(props) => (props.notActive ? 0.5 : 1)};
  text-align: ${(props) => props.textAlign};
`;

export const ScheduleDateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
  padding-left: ${(props) => props.paddingLeft};
  & > label {
    margin: 0 8px;
    opacity: ${(props) => (props.notActive ? 0.5 : 1)};
  }
  & > input {
    opacity: ${(props) => (props.notActive ? 0.5 : 1)};
  }
`;
export const DatePickerWrapper = styled.div`
  position: relative;
  width: 200px;
  & > span {
    position: absolute;
    top: 8px;
    left: 10px;
    opacity: 1;
    pointer-events: none;
  }
  & > svg {
    position: absolute;
    right: 15px;
    top: 10px;
    pointer-events: none;
    color: var(--schedule-sapphire);
  }
`;
export const CautionSection = styled.div`
  padding-top: 16px;
  & > p {
    color: var(--orange-bright);
    font-weight: bold;
    margin: 0;
  }
  & > ul {
    padding-left: 20px;
  }
  & > li {
    color: var(--champion-charcoal);
  }
`;
export const ButtonWrapper = styled.div`
  margin-top: 25px;
  text-align: center;
`;

export const DateMessageWrapper = styled.div`
    background: ${props => props.background};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    border-radius: ${props => props.borderRadius};
`;

export const RowSeparator = styled.div`
  border-bottom: 1px solid var(--icon-light-gray);
  margin: 32px 0;
  width: 100%;
`;

export const StyledParagraph = styled.p`
  margin: 0;
  padding-top: 1.5rem;
  text-align: center;
`;

export const LoadingWrapper = styled.div`
  text-align: center;
  padding-top: 1.5rem;
`;
