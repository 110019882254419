import { useCallback, useReducer, useEffect, useContext } from 'react';
import CustomerReducer from './customerReducer';
import CustomerContext from './customerContext';
import * as Api from 'api/Api';
import FullContext from 'stores/Full/fullContext';
import { CustomerCanvas, Customers } from 'models';
import { useParams } from 'react-router-dom';

const CustomerState = ({ children }) => {
  const initialState = {
    accountData: {},
    summaryData: [],
    externalData: {},
    canvasForDateEdit: null,
    customerDetail: {},
    customerContact: {},
  };

  const params = useParams();

  const id = params?.id;

  const { setActiveCanvasMode, isExternal } = useContext(FullContext);

  useEffect(() => {
    const getSummaryData = async () => {
      const response = await Api.get('Customer/GetCustomerProjectSummaries', { id });
      populateSummaryData(response.reverse());
    };

    if (!isExternal) getSummaryData();
  }, [id, isExternal]);

  useEffect(() => {
    const getExternalData = async () => {
      const response = await Api.get('Customer/GetCustomerExternalSummary', { customerId: id });
      populateExternalData(response);
    };

    if (isExternal) getExternalData();
  }, [id, isExternal]);

  const [state, dispatch] = useReducer(CustomerReducer, initialState);

  const onShowDateModal = (canvas) => {
    updateCanvasDate(canvas);
  };

  const onHideDateModal = () => () => {
    updateCanvasDate(null);
  };

  const callUpdateStartDate = async (customerCanvasId, _canvasId, _customerId, startDate) => {
    const patchItems = [
      {
        op: 'replace',
        path: '/startDate',
        value: startDate.toISOString(),
      },
    ];

    const params = { customerCanvasId };

    try {
      const response = await CustomerCanvas.updateCanvasStartDate(patchItems, params);

      const newData = state.summaryData.map((summary) => {
        if (summary.id === customerCanvasId) {
          return {
            ...summary,
            startDate: response.startDate,
            endDate: response.endDate,
          };
        }

        return { ...summary };
      });

      populateSummaryData(newData.reverse());
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const populateAccountData = (data) => {
    dispatch({
      type: 'populate_account_data',
      payload: data,
    });
  };

  const populateSummaryData = (data) => {
    dispatch({
      type: 'populate_summary_data',
      payload: data,
    });
  };

  const populateExternalData = (data) => {
    dispatch({
      type: 'populate_external_data',
      payload: data,
    });
  };

  const populateCustomerDetail = (data) => {
    dispatch({
      type: 'populate_customer_detail',
      payload: data,
    });
  };

  const populateCustomerContact = (data) => {
    dispatch({
      type: 'populate_customer_contact',
      payload: data,
    });
  };

  const updateCanvasDate = (data) => {
    dispatch({
      type: 'update_canvas_for_date_edit',
      payload: data,
    });
  };

  const fetchCustomerDetail = useCallback(async () => {
    const response = await Customers.getCustomerDetail(id);
    populateCustomerDetail(response);
  }, [id]);

  const fetchCustomerContact = useCallback(async (contactId) => {
    const contact = await Customers.getCustomerContact(contactId);
    populateCustomerContact(contact);
  }, []);

  return (
    <CustomerContext.Provider
      value={{
        accountData: state.accountData,
        summaryData: state.summaryData,
        externalData: state.externalData,
        canvasForDateEdit: state.canvasForDateEdit,
        customerDetail: state.customerDetail,
        customerContact: state.customerContact,
        populateAccountData,
        populateSummaryData,
        populateExternalData,
        populateCustomerContact,
        setActiveCanvasMode,
        onShowDateModal,
        onHideDateModal,
        callUpdateStartDate,
        fetchCustomerDetail,
        fetchCustomerContact,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export default CustomerState;
