import React from 'react';
import Select from 'components/Select';
import ErrorText from '../ErrorText';
import InputContainer from './ActionInputContainer';
import ActionContainer from './ActionContainer';

/**
 * Renders action to set an status the account created/updated
 * @property {object} props.action Action object type
 * @property {object} props.errors Errors in the form
 * @property {object} props.touched Indicates if elements in the form were touched
 * @property {object} props.onActionInputChange callback to execute when input changes
 * @returns {JSX.Element}
 */
const SetAccountStatusAction = ({
  action = {},
  errors = {},
  touched = {},
  onActionInputChange = () => {},
}) => {
  const actionInput = action?.actionInput || {};
  const { status } = actionInput;

  const statusOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
  ];

  const SET_ACCOUNT_STATUS_TEMPLATE = {
    id: 0,
    clientTypeId: 0,
    status: '',
    ownerId: 0,
  };

  /**
   * Function to handle input change
   * @param {object} opt object with label and value for the selected item
   * @returns {void}
   */
  const handleActionChange = (opt) => {
    const status = opt.value;
    const newInput = {
      ...SET_ACCOUNT_STATUS_TEMPLATE,
      ...actionInput,
      status,
    };
    onActionInputChange(newInput);
  };

  return (
    <ActionContainer>
      <div className="action-label">Set Account Status To</div>
      <InputContainer>
        <Select
          placeholder="Select status..."
          options={statusOptions}
          onChange={handleActionChange}
          value={statusOptions.find((opt) => opt.value === status)}
        />
        {touched.actionInput && errors.status ? <ErrorText>{errors.status}</ErrorText> : null}
      </InputContainer>
    </ActionContainer>
  );
};

export default SetAccountStatusAction;
