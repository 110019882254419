import { post } from "api/Api";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import LoadingInline from "./Loading/LoadingInline";

export default function EditNote({ note, originalText, finishEdit }) {
  const [text, setText] = useState(originalText);
  const [updatingNote, setUpdatingNote] = useState(false);

  function handleTextChange(event) {
    setText(event.target.value);
  }

  async function handleUpdateNote() {
    setUpdatingNote(true);
    await post(
      'Customer/UpdateNote',
      {
        text: text,
      },
      { params: { noteId: note.id } }
    );
    note.text = text;
    setUpdatingNote(false);
    finishEdit();
  }

  return (
    <>
      <Form.Control
        as="textarea"
        autoFocus
        onFocus={(e) => {
          e.target.setSelectionRange(e.target.value.length, e.target.value.length);
        }}
        rows="3"
        className="mb-2-5"
        value={text}
        onChange={(e) => handleTextChange(e)}
        placeholder="Edit note..."
        disabled={updatingNote}
      />
      <div className="text-right">
        <Button variant="outline-light" onClick={finishEdit} className="mr-2-5">
          Cancel
        </Button>
        <Button disabled={!text.trim() || updatingNote} variant="primary" onClick={() => handleUpdateNote()}>
          {updatingNote ? <LoadingInline white text="Saving..." /> : 'Save'}
        </Button>
      </div>
    </>
  );
}
