import { Link, Navigate } from 'react-router-dom';
import FullScreenWithBackground from '../components/FullScreenWithBackground';
import { getExternalProject, logOut } from '../helpers/CommonHelper';

export default function Error500() {
  const projectLink = getExternalProject();
  const userIsExternal = !!projectLink;

  const getLink = () => {
    if (userIsExternal) {
      if (projectLink) {
        return <Link to={projectLink}>Go back to project</Link>;
      } else {
        logOut();
        return (
          <Navigate replace to={'/login'}>
            Login again
          </Navigate>
        );
      }
    } else {
      return <Link to="/">Go back to home</Link>;
    }
  };

  return (
    <FullScreenWithBackground>
      <div className="text-center">
        <h3 className="pb-4">Oops, looks like something happened.</h3>
        <p className="text-muted">
          We'll get you back up and running soon. In the meantime, you can contact support.
        </p>
        {getLink()}
      </div>
    </FullScreenWithBackground>
  );
}
