import { useState, useEffect, useRef, useContext } from 'react';
import { CheckMark, DropDown, DropDownArrow, FilterEntry, Wrapper } from './SharedStyles';
import { faCheck, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import useOutsideClickNotifier from 'helpers/hooks/useOutsideClickNotifier';
import { ACTIVE_TEMPLATE, ACTIVE_TEMPLATE_TITLE } from 'constants/sessionStorage';
import * as Api from '../../api/Api';
import ReportingContext from './context/reportingContext';
import { SessionStorage } from 'models';

export default function ActiveTemplateDropdown() {
  const { selectActiveTemplateId, selectActivePhase, activeTemplateTitle } = useContext(ReportingContext);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [isTemplatePickerOpen, setIsTemplatePickerOpen] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState(activeTemplateTitle ?? 'Choose a template');
  const clickRef = useRef(null);

  useOutsideClickNotifier(clickRef, () => {
    setIsTemplatePickerOpen(false);
  });

  useEffect(() => {
    const getTemplateInfo = async () => {
      const res = await Api.get('Canvas/GetAll');
      setTemplateOptions(res?.items);
    };
    getTemplateInfo();
  }, []);

  const handleClick = ({ name, id }) => {
    setActiveTemplate(name);
    selectActiveTemplateId(id, name);
    SessionStorage.set(ACTIVE_TEMPLATE, id);
    SessionStorage.set(ACTIVE_TEMPLATE_TITLE, name);
    selectActivePhase({});
    setIsTemplatePickerOpen(false);
  };
  return (
    <div className="position-relative">
      <DropDown
        padding="8px 32px 8px 16px"
        margin="16px 0 0 0"
        width="auto"
        onClick={() => setIsTemplatePickerOpen(true)}
      >
        {activeTemplate}
        <DropDownArrow icon={faChevronDown} size="xs" right="12px" />
      </DropDown>
      {isTemplatePickerOpen && (
        <Wrapper top="80px" ref={clickRef}>
          {templateOptions &&
            templateOptions?.length > 0 &&
            templateOptions.map(
              (template) =>
                template?.canvasStatus !== 'Draft' && (
                  <FilterEntry key={template.id} onClick={() => handleClick(template)}>
                    {template?.name}
                    {activeTemplate === template?.name && <CheckMark icon={faCheck} size="xs" top="8px" />}
                  </FilterEntry>
                )
            )}
        </Wrapper>
      )}
    </div>
  );
}
