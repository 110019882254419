import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EXTERNAL } from 'constants';
import { displayRole, getFormattedUsersList } from 'helpers/CommonHelper';
import { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import * as Api from '../../../api/Api';
import List from '../../List';
import LoadingInline from '../../Loading/LoadingInline';
import LoadingSpinner from '../../Loading/LoadingSpinner';
import toast from '../../toast';
import { Avatar } from '../Avatar';
import { MembersContainer } from './AddMembers/Styles';

export default function ModalEditMembers({
  show,
  onHide,
  customerId,
  customerCanvasId,
  members,
  startAssign,
  removeMember,
  showAddMembers,
  isExternalInviteEnabled,
}) {
  const [removingItem, setRemovingItem] = useState({});
  const [resendingTo, setResendingTo] = useState({});
  const [resentTo, setResentTo] = useState([]);
  const inCanvas = !!startAssign;

  async function resendInvite(item) {
    setResendingTo(item);
    await Api.post('User/SendInvite', null, {
      params: { userId: item.id, customerId: customerId, customerCanvasId: customerCanvasId },
    });
    setResentTo([...resentTo, item]);
    setResendingTo(null);
    toast.saved('Invitation resent successfully.');
  }

  async function handleRemove(item) {
    setRemovingItem(item);
    await removeMember(item);
    setRemovingItem(null);
  }

  function actionButton(item) {
    return (
      inCanvas && (
        <Button variant="secondary" onClick={() => startAssign(item)} className="text-nowrap">
          {/* Assign, Resend, Direct Message? */}
          Assign
        </Button>
      )
    );
  }

  function emailDetail(item) {
    return (
      <>
        <div>{item.email}</div>
        {inCanvas && item.role === EXTERNAL && isExternalInviteEnabled && (
          <div className="color-completed">
            Invite sent
            {!resentTo.includes(item) && (
              <Button
                variant="link"
                className="p-0 ml-2"
                disabled={resendingTo === item}
                onClick={() => resendInvite(item)}
              >
                {resendingTo === item ? <LoadingInline text="sending..." /> : 'resend'}
              </Button>
            )}
          </div>
        )}
      </>
    );
  }

  function membersList() {
    const formattedUserList = getFormattedUsersList(members, 'modal');

    return (
      <List>
        {formattedUserList.map((member) => {
          const isExternal = member.role === 'EXTERNAL';

          const getPosition = () => {
            if (isExternal && member.position) {
              return `- ${member.position}`;
            }

            if (!isExternal && member.isAccountOwner) {
              return `- Account Owner`;
            }

            return '';
          };

          return (
            <Row key={member.id} className="d-flex align-items-center">
              <Col className="d-flex align-items-center">
                <Avatar
                  user={member}
                  className="mr-4 member-avatar"
                  backgroundColor={
                    member.role === 'EXTERNAL' ? 'var(--implement-iron)' : 'var(--action-anchor)'
                  }
                />
                <div>
                  <div>
                    <div className="font-weight-bold">
                      {member?.firstName} {member?.lastName}
                    </div>
                    <div className="small-info">
                      {displayRole(member?.role)} {getPosition()}
                    </div>
                  </div>
                  <div className="d-sm-none">
                    {emailDetail(member)}
                    {actionButton(member)}
                  </div>
                </div>
              </Col>
              <Col className="d-none d-sm-block pl-1">{emailDetail(member)}</Col>
              <Col className="d-flex align-items-center justify-content-end flex-grow-0 pl-0 pl-sm-1">
                <div className="d-none d-sm-block mr-3-5">{actionButton(member)}</div>
                {removingItem === member ? (
                  <LoadingSpinner />
                ) : (
                  <FontAwesomeIcon icon={faTimes} onClick={() => handleRemove(member)} />
                )}
              </Col>
            </Row>
          );
        })}
      </List>
    );
  }

  return (
    <Modal show={show} onHide={onHide} scrollable size="lg">
      <Modal.Header>
        <h1>Project Members</h1>
        <div>
          <Button variant="outline-light" onClick={onHide} className="mr-2-5">
            Done
          </Button>
          <Button variant="primary" onClick={showAddMembers}>
            {inCanvas ? 'Add Project Member' : 'Assign Project Member'}
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        {members === null ? (
          <LoadingInline />
        ) : members.length === 0 ? (
          'No current members'
        ) : (
          <MembersContainer>
            <hr />
            {membersList()}
          </MembersContainer>
        )}
      </Modal.Body>
    </Modal>
  );
}
