import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import styled from 'styled-components';
import * as Api from '../../api/Api';
import canvasIcon from '../../img/canvas_icon.svg';
import CircleCheck from '../CircleCheck';
import TableRow from '../TableRow';
import { DropdownContainer } from './CanvasSettings/styles';
import ModalLargePadding from './LargePadding';
import Select from 'react-select';
import {
  daysList,
  getFrequencyList,
  initialDropdownValues,
  initialEmailSettings,
  internalSteps,
  projectList,
  sendForList,
  stepList,
} from './CanvasSettings/fixtures';
import { INTERNAL_STEPS, ALL_STEPS, EXTERNAL_STEP_MEMBERS } from 'constants';

const StyledImage = styled.img`
  max-width: 18.313rem;
`;

const CommonItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0.75rem;

  &:last-child {
    margin-bottom: 1.5rem;
  }

  .month-select {
    min-width: 6rem !important;
  }
`;

const PastDueStepContainer = styled(CommonItemContainer)`
  .common-select {
    min-width: 13.25rem;
  }
`;

export default function ModalNewCanvas({ show, onHide, onAdd }) {
  const [selectedTab, setSelectedTab] = useState('blank');
  const [canvases, setCanvases] = useState([]);
  const [filteredCanvases, setFilteredCanvases] = useState([]);
  const [name, setName] = useState('');
  const [templateId, setTemplateId] = useState(null);
  const [error, setError] = useState('');
  const [search, setSearch] = useState('');
  const [dropdownValues, setDropdownValues] = useState(initialDropdownValues);
  const [emailSettings, setEmailSettings] = useState(initialEmailSettings);

  const fetchData = useCallback(async () => {
    const response = await Api.get('Canvas/GetAll');
    const mappedData = response.items.map((item) => ({ id: item.id, name: item.name, color: item.color }));

    setCanvases(mappedData);
  }, []);

  const resetState = () => {
    setName('');
    setSearch('');
    setSelectedTab('blank');
    setTemplateId(null);
    setDropdownValues(initialDropdownValues);
    setEmailSettings(initialEmailSettings);
  };

  useEffect(() => {
    if (show) fetchData();

    return resetState();
  }, [fetchData, show]);

  useEffect(() => {
    if (search) {
      const filteredData = canvases.filter((item) =>
        item?.name?.toLowerCase().includes(search?.toLowerCase())
      );

      setFilteredCanvases(filteredData);
      return;
    }

    setFilteredCanvases([]);
  }, [search, canvases]);

  async function handleOnClickAdd() {
    if (!name) {
      setError('You must provide a template name');
      return;
    } else {
      const canvasValues = {
        dropdownValues,
        emailSettings,
      };

      const response = await onAdd(name, templateId, canvasValues);
      if (response !== undefined) {
        setError(response);
        return;
      }
    }
  }

  const formattedStepList = useMemo(() => {
    if (dropdownValues.sendPastDueEmailFor?.label === INTERNAL_STEPS) {
      return stepList.map((step) => {
        if (internalSteps.includes(step.label)) {
          return {
            ...step,
            isDisabled: true,
          };
        }

        return { ...step };
      });
    }

    if (dropdownValues.sendPastDueEmailFor?.label === ALL_STEPS) {
      return stepList.map((step) => {
        if (step.label === EXTERNAL_STEP_MEMBERS) {
          return {
            ...step,
            isDisabled: true,
          };
        }

        return { ...step };
      });
    }

    return stepList;
  }, [dropdownValues.sendPastDueEmailFor]);

  const pluralizedFrequency = useMemo(
    () =>
      dropdownValues.sendProjectUpdateEvery
        ? getFrequencyList(dropdownValues.sendProjectUpdateEvery.value)
        : [],
    [dropdownValues.sendProjectUpdateEvery]
  );

  const handleChangeEmailSwitch = (key) => (event) => {
    const { checked } = event.target;

    setEmailSettings((prev) => ({
      ...prev,
      [key]: checked,
    }));
  };

  const handleChangeEmailDropdown = (key) => (value) => {
    setDropdownValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  function handleOnChangeName(e) {
    setName(e.target.value);
  }

  function handleOnChangeSearch(e) {
    setSearch(e.target.value);
  }

  function selectCanvasId(id) {
    if (templateId === id) {
      setTemplateId(null);
    } else {
      setTemplateId(id);
    }
  }

  function blankTab() {
    return (
      <>
        <Row className="modal-section-header">
          <Col className="switch-row" xs={12}>
            General
          </Col>
        </Row>

        <Row>
          <Col className="switch-row" xs={12}>
            <div>Auto-advance</div>
            <Form.Check
              type="switch"
              id="autoAdvance"
              name="autoAdvance"
              checked={emailSettings.autoAdvance}
              onChange={handleChangeEmailSwitch('autoAdvance')}
            />
          </Col>
        </Row>

        <Row className="top-border">
          <Col className="switch-row" xs={12}>
            <div>Dynamic Timelines</div>
            <Form.Check
              type="switch"
              id="dynamicTimeline"
              name="dynamicTimeline"
              checked={emailSettings.dynamicTimeline}
              onChange={handleChangeEmailSwitch('dynamicTimeline')}
            />
          </Col>
        </Row>

        <Row className="top-border mb-3">
          <Col className="switch-row" xs={12}>
            <div>Allow external users to assign and add members to checklist items.</div>
            <Form.Check
              type="switch"
              id="externalsHaveTaskItemPermissions"
              name="externalsHaveTaskItemPermissions"
              checked={emailSettings.externalsHaveTaskItemPermissions}
              onChange={handleChangeEmailSwitch('externalsHaveTaskItemPermissions')}
            />
          </Col>
        </Row>

        <Row className="modal-section-header">
          <Col className="switch-row" xs={12}>
            System Emails
          </Col>
        </Row>

        <div>
          <Row className="top-border">
            <Col className="switch-row" xs={12}>
              <div>Project Update</div>
              <Form.Check
                type="switch"
                id="sendEmail"
                name="sendEmail"
                checked={emailSettings.projectUpdate}
                onChange={handleChangeEmailSwitch('projectUpdate')}
              />
            </Col>
          </Row>

          {emailSettings.projectUpdate && (
            <Row>
              <Col xs={12}>
                <CommonItemContainer>
                  <span className="mr-3">Send to</span>
                  <DropdownContainer className={`mr-2 common-select`}>
                    <Select
                      value={dropdownValues.sendProjectUpdateTo}
                      classNamePrefix="select"
                      isSearchable={false}
                      placeholder=""
                      onChange={handleChangeEmailDropdown('sendProjectUpdateTo')}
                      options={projectList}
                    />
                  </DropdownContainer>
                </CommonItemContainer>

                <CommonItemContainer>
                  <span className="mr-3">Every</span>
                  <DropdownContainer className={`mr-2 day-select`}>
                    <Select
                      value={dropdownValues.sendProjectUpdateEvery}
                      classNamePrefix="select"
                      isSearchable={false}
                      placeholder=""
                      onChange={handleChangeEmailDropdown('sendProjectUpdateEvery')}
                      options={daysList}
                    />
                  </DropdownContainer>

                  <DropdownContainer className={`mr-2 month-select`}>
                    <Select
                      value={dropdownValues.sendProjectUpdateFrequency}
                      classNamePrefix="select"
                      isSearchable={false}
                      placeholder=""
                      onChange={handleChangeEmailDropdown('sendProjectUpdateFrequency')}
                      options={pluralizedFrequency}
                    />
                  </DropdownContainer>
                </CommonItemContainer>
              </Col>
            </Row>
          )}
        </div>

        <div>
          {/** past due step */}
          <Row className="top-border ">
            <Col className="switch-row" xs={12}>
              <div>Past Due Step</div>
              <Form.Check
                type="switch"
                id="pastDueStep"
                name="pastDueStep"
                checked={emailSettings.pastDueStep}
                onChange={handleChangeEmailSwitch('pastDueStep')}
              />
            </Col>
          </Row>

          {emailSettings.pastDueStep && (
            <Row>
              <Col xs={12}>
                <PastDueStepContainer>
                  <span className="mr-3">Send for</span>
                  <DropdownContainer className={`mr-2 common-select`}>
                    <Select
                      value={dropdownValues.sendPastDueEmailFor}
                      classNamePrefix="select"
                      isSearchable={false}
                      placeholder=""
                      onChange={handleChangeEmailDropdown('sendPastDueEmailFor')}
                      options={sendForList}
                    />
                  </DropdownContainer>

                  <DropdownContainer className={`mr-2 common-select`}>
                    <Select
                      value={dropdownValues.sendPastDueEmailTo}
                      classNamePrefix="select"
                      isSearchable={false}
                      placeholder=""
                      onChange={handleChangeEmailDropdown('sendPastDueEmailTo')}
                      options={formattedStepList}
                      isOptionDisabled={(option) => option.isDisabled}
                    />
                  </DropdownContainer>
                </PastDueStepContainer>
              </Col>
            </Row>
          )}
        </div>

        <div>
          {/** Invited to Project */}
          <Row className="top-border ">
            <Col className="switch-row" xs={12}>
              <div>Invited to Project</div>
              <Form.Check
                type="switch"
                id="invitedToProject"
                name="invitedToProject"
                checked={emailSettings.invitedToProject}
                onChange={handleChangeEmailSwitch('invitedToProject')}
              />
            </Col>
          </Row>

          {emailSettings.invitedToProject && (
            <Row>
              <Col xs={12}>
                <CommonItemContainer>
                  <span className="mr-3">Send to</span>
                  <DropdownContainer className={`mr-2 common-select`}>
                    <Select
                      value={dropdownValues.invitedToProject}
                      classNamePrefix="select"
                      isSearchable={false}
                      placeholder=""
                      onChange={handleChangeEmailDropdown('invitedToProject')}
                      options={projectList}
                    />
                  </DropdownContainer>
                </CommonItemContainer>
              </Col>
            </Row>
          )}
        </div>

        <div>
          {/** Assigned to a Step or Task */}
          <Row className="top-border ">
            <Col className="switch-row" xs={12}>
              <div>Assigned to a Step or Task</div>
              <Form.Check
                type="switch"
                id="assignedToStepOrTask"
                name="assignedToStepOrTask"
                checked={emailSettings.assignedToStepOrTask}
                onChange={handleChangeEmailSwitch('assignedToStepOrTask')}
              />
            </Col>
          </Row>

          {emailSettings.assignedToStepOrTask && (
            <Row>
              <Col xs={12}>
                <CommonItemContainer>
                  <span className="mr-3">Send to</span>
                  <DropdownContainer className={`mr-2 common-select`}>
                    <Select
                      value={dropdownValues.assignedToStepOrTask}
                      classNamePrefix="select"
                      isSearchable={false}
                      placeholder=""
                      onChange={handleChangeEmailDropdown('assignedToStepOrTask')}
                      options={projectList}
                    />
                  </DropdownContainer>
                </CommonItemContainer>
              </Col>
            </Row>
          )}
        </div>

        <div>
          {/** Comments */}
          <Row className="top-border ">
            <Col className="switch-row" xs={12}>
              <div>Comments</div>
              <Form.Check
                type="switch"
                id="comments"
                name="comments"
                checked={emailSettings.comments}
                onChange={handleChangeEmailSwitch('comments')}
              />
            </Col>
          </Row>

          {emailSettings.comments && (
            <Row>
              <Col xs={12}>
                <CommonItemContainer>
                  <span className="mr-3">Send to</span>
                  <DropdownContainer className={`mr-2 common-select`}>
                    <Select
                      value={dropdownValues.comments}
                      classNamePrefix="select"
                      isSearchable={false}
                      placeholder=""
                      onChange={handleChangeEmailDropdown('comments')}
                      options={projectList}
                    />
                  </DropdownContainer>
                </CommonItemContainer>
              </Col>
            </Row>
          )}
        </div>
      </>
    );
  }

  const memoizedData = useMemo(
    () => (!filteredCanvases.length && !search ? canvases : filteredCanvases),
    [canvases, filteredCanvases, search]
  );

  function existingTab() {
    return (
      <>
        <Form.Control
          value={search}
          placeholder="search..."
          className="mb-3"
          onChange={handleOnChangeSearch}
        />
        {memoizedData.length > 0 &&
          memoizedData.map((item) => (
            <TableRow
              onClick={() => {
                selectCanvasId(item.id);
              }}
              key={`item-${item.id}`}
            >
              <Col xs={10} className="text-break canvas-content-wrapper">
                <div className={`mr-3 canvas-icon ${item.color}`}>
                  <StyledImage src={canvasIcon} alt="" />
                </div>
                <div>
                  <div className="bold">{item.name}</div>
                </div>
              </Col>
              <Col xs={1}>
                <CircleCheck selected={templateId === item.id} />
              </Col>
            </TableRow>
          ))}
      </>
    );
  }

  const modalStyle = {
    inner: {
      maxWidth: '36.25rem',
    },
  };

  return (
    <ModalLargePadding
      show={show}
      onHide={onHide}
      innerProps={modalStyle.inner}
      content={
        <>
          <div className="d-flex align-items-center mb-4">
            <h1 className="d-flex align-items-center">New template</h1>
          </div>
          {error ? <Alert variant="danger">{error}</Alert> : ''}

          <Form.Control value={name} placeholder="Template name..." onChange={handleOnChangeName} />
          <Tabs
            defaultActiveKey="blank"
            id="New-canvas-tabs"
            className="mt-4"
            activeKey={selectedTab}
            onSelect={setSelectedTab}
          >
            <Tab eventKey="blank" title="Blank template">
              {blankTab()}
            </Tab>
            <Tab eventKey="existing" title="Use existing template">
              {existingTab()}
            </Tab>
          </Tabs>
        </>
      }
      buttons={
        <>
          <Button variant="outline-light" onClick={onHide} className="mr-0 mr-sm-2 mb-2 mb-sm-0 w-100">
            Cancel
          </Button>
          <Button onClick={handleOnClickAdd} className="ml-0 ml-sm-2 w-100">
            Add
          </Button>
        </>
      }
    />
  );
}
