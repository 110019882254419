import styled from 'styled-components'

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  > .action-label { 
    white-space: nowrap;
  }
  > * {
    margin-right: 12px;
  }
`;

export default ActionContainer