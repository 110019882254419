import React, { useRef, useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreatableReactSelect from 'react-select/creatable';
import { components } from 'react-select';
import { faTrash, faPencil, faCheck } from '@fortawesome/pro-light-svg-icons';
import ModalConfirm from 'components/Modals/Confirm/index';
import { Form } from 'react-bootstrap';
import { CREATOR } from 'constants';
import styled from 'styled-components';
import FullContext from 'stores/Full/fullContext';

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${({ selected }) => (selected ? 'white' : 'inherit')};
`;

export const Option = ({
  children,
  isEditing,
  onEdit,
  onDelete,
  setItemForEdit,
  isSelected,
  setValue,
  data,
  ...props
}) => {
  const input = useRef(null);
  const [newLabel, setNewLabel] = useState('');

  useEffect(() => {
    if (isEditing) {
      input.current.focus();
    }
  }, [isEditing]);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const saveLabel = () => {
    if (newLabel) {
      onEdit(newLabel);
    }
  };

  return (
    <components.Option isSelected={isSelected} {...props}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="flex-fill mr-3" onClick={() => setItemForEdit(null)}>
          {isEditing ? (
            <Form.Control
              ref={input}
              defaultValue={children}
              onChange={(e) => setNewLabel(e.target.value)}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === 'Enter') {
                  setItemForEdit(null);
                  saveLabel();
                }
              }}
              onClick={stopPropagation}
              onMouseDown={stopPropagation}
            />
          ) : (
            children
          )}
        </div>
        {!data.__isNew__ && (
          <div>
            {onEdit && (
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setItemForEdit(isEditing ? null : data.value);
                  if (isEditing) {
                    saveLabel();
                  }
                }}
              >
                <StyledIcon
                  icon={isEditing ? faCheck : faPencil}
                  className="light mr-3 clickable-icon"
                  selected={isSelected}
                />
              </span>
            )}
            {onDelete && (
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDelete(data.value);
                }}
              >
                <StyledIcon icon={faTrash} className="light mr-2" />
              </span>
            )}
          </div>
        )}
      </div>
    </components.Option>
  );
};

function CreatableSelect({ onChange, onCreate, onDelete, onEdit, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [itemForDelete, setItemForDelete] = useState(null);
  const [itemForEdit, setItemForEdit] = useState(null);
  const { roleAtleast } = useContext(FullContext);

  const handleCreate = async (label) => {
    setIsLoading(true);
    await onCreate(label);
    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    await onDelete(itemForDelete);
    setItemForDelete(null);
    setIsLoading(false);
  };

  const handleEdit = async (newLabel) => {
    setIsLoading(true);
    await onEdit(itemForEdit, newLabel);
    setItemForEdit(null);
    setIsLoading(false);
  };

  return (
    <>
      <CreatableReactSelect
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={(change) => onChange(change || {})}
        onCreateOption={handleCreate}
        className={(props.className || '') + ' react-select-container'}
        classNamePrefix="react-select"
        getNewOptionData={roleAtleast(CREATOR) ? undefined : () => false}
        components={
          roleAtleast(CREATOR)
            ? {
                Option: (optionProps) => (
                  <Option
                    {...optionProps}
                    isEditing={optionProps.data.value === itemForEdit}
                    onEdit={handleEdit}
                    onDelete={setItemForDelete}
                    setItemForEdit={setItemForEdit}
                  />
                ),
              }
            : undefined
        }
        menuIsOpen={!!(itemForDelete || itemForEdit) ? true : undefined}
        isSearchable={!!props.options?.length || roleAtleast(CREATOR)}
        {...props}
      />
      <ModalConfirm
        title={`Delete client type`}
        message={
          <>
            {`Are you sure you want to delete this client type? Other accounts with this type will no longer have a type.`}
            <br />
            This action cannot be undone.
          </>
        }
        show={itemForDelete !== null}
        onConfirm={handleDelete}
        onHide={() => setItemForDelete(null)}
      />
    </>
  );
}

export default CreatableSelect;
