import React, { useState, useEffect, useRef } from 'react';
import { Alert, Form } from 'react-bootstrap';
import FullScreenWithBackground from 'components/FullScreenWithBackground';
import { LoginContainer } from './Styles';
import { SignIn } from 'models';
import * as uuid from 'uuid';
import { debounce, setOrgData } from 'helpers/CommonHelper';
import { useLocation, useNavigate } from 'react-router-dom';

const LoginOTP = () => {
  const location = useLocation();
  const history = useNavigate();
  const params = new URLSearchParams(location.search);

  const [passcode, setPasscode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [tenantName] = useState(location.state.tenantName);
  const [isLoading, setIsLoading] = useState(false);

  const email = params.get('email');

  const handleGenerateOTP = async () => {
    setIsLoading(true);
    setErrorMessage('');

    await SignIn.generateOTP(email);

    setIsLoading(false);

    setInfoMessage('A new passcode has been sent to your email.');
  };

  const handleProcessOTP = useRef(
    debounce(async (passcode) => {
      setIsLoading(true);

      const response = await SignIn.signInWithOTP(email, passcode);

      if (response?.errors) {
        const flattenedErrors = Object.values(response?.errors).flatMap((errors) => errors);

        setErrorMessage(flattenedErrors.join(' '));
        setIsLoading(false);
        return;
      }

      const redirectRoute = `/${response?.redirect}`;

      localStorage.setItem('externalProject', redirectRoute);

      const data = {
        activeOrganization: {
          id: uuid.v4(),
          name: tenantName,
        },
        page: 1,
        total: 0,
        all: false,
        totalItems: 0,
        items: [],
      };

      setOrgData(data);

      history(redirectRoute);

      setIsLoading(false);
    }, 500)
  );

  useEffect(() => {
    if (passcode.length === 6) handleProcessOTP.current(passcode);
  }, [passcode]);

  const handleChange = (event) => {
    setPasscode(event.target.value);
    setErrorMessage('');
    setInfoMessage('');
  };

  const onInput = (event) =>
    (event.target.value = Math.max(0, parseInt(event.target.value)).toString().slice(0, 6));

  return (
    <FullScreenWithBackground
      title="One-Time Code"
      loading={isLoading}
      error={errorMessage}
      showFooter={false}
      showBackButton
    >
      <LoginContainer $hasError={!!errorMessage}>
        {infoMessage && <Alert variant="success">{infoMessage}</Alert>}
        <p className="otp-info">
          Check your inbox at {email} for a <span className="text-nowrap">one-time</span> passcode.
        </p>
        <Form.Group>
          <Form.Control
            required
            value={passcode}
            name="passcode"
            type="number"
            placeholder="Passcode"
            autoFocus
            onInput={onInput}
            onChange={handleChange}
          />
        </Form.Group>

        <span className="resend-link" onClick={handleGenerateOTP}>
          Resend code
        </span>
      </LoginContainer>
    </FullScreenWithBackground>
  );
};

export default LoginOTP;
