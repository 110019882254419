import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const ErrorContainer = styled.div`
  margin: 0.25rem 0 0 0.25rem;
  text-align: left;
  color: var(--danger);
`;

export const CompletedIcon = styled(FontAwesomeIcon)`
  color: var(--join-jade-1);
`;

export const SignUpInfoContainer = styled.div`
  color: var(--white);

  h1 {
    font-size: 2.625rem;
    margin-bottom: 1rem;
  }

  ul {
    margin-bottom: 2rem;
  }
`;
