import * as uuid from 'uuid';

export const previewData = {
  id: uuid.v4(),
  tenantId: 10004,
  contactId: 1,
  firstName: 'John',
  lastName: 'Doe',
  title: 'Manager',
  email: 'name@email.com',
  phoneNumber: '000-000-0000',
  role: 0,
  primaryId: 0,
  primary: false,
  invitedToCanvas: false,
  defaultOwner: false,
  externalId: '667777c6-aae2-4abe-a5a7-919fe51c3a20',
  LastActivity: new Date(),
};

export const defaultFields = ['FirstName', 'LastName', 'Email', 'Phone', 'Title', 'LastActivity'];
