import React, { useState } from 'react';
import { Field } from 'formik';
import { Customers, SessionStorage } from 'models';
import { ACCOUNT_FILTER } from 'constants';
import CustomCreatableSelect from './CustomCreatableSelect';
import useUpdateEffect from 'helpers/hooks/useUpdateEffect';
import { FieldLabel } from './Styles';

const ClientType = ({ field, name, handleChange, handleClear, handleBlur, disabled, isModal = true }) => {
  const [value, setValue] = useState(null);
  const [clientTypes, setClientTypes] = useState(field.fieldTypeOptions ?? []);

  /**
   * Prevent's useEffect to trigger on initial mount
   * since this might cause issues in debouncing the
   * handleChange
   **/
  useUpdateEffect(() => {
    if (handleChange) handleChange(value);
  }, [handleChange, value]);

  /**
   * Create new client types
   * @param {*} newLabel
   * @param {*} form
   * @returns void
   */
  const createClientType = async (newLabel, form) => {
    try {
      const request = { name: newLabel, description: '' };

      const { id, name: label } = await Customers.addClientType(request);

      const newClientType = { value: label, label, response: id };
      setClientTypes((prev) => [...prev, newClientType]);

      form.setFieldValue(name, newClientType);
      setValue(newClientType);
    } catch (error) {
      console.log('Error: could note create', error);
    }
  };

  /**
   * Deletes the selected client type
   * @param {*} label
   * @returns void
   */
  const deleteClientType = async (label) => {
    try {
      const foundType = clientTypes.find(({ value }) => value === label);

      await Customers.deleteClientType(foundType?.response);

      const newTypes = clientTypes.filter((type) => type.label !== label);

      SessionStorage.set(
        ACCOUNT_FILTER,
        newTypes.map(({ label }) => label)
      );

      setClientTypes(newTypes);
    } catch (error) {
      console.log('Error: could not delete', error);
    }
  };

  /**
   * Edits the selected client type
   * @param {*} label
   * @param {*} newLabel
   * @param {*} form
   * @returns void
   */
  const editClientType = async (item, newLabel, form) => {
    try {
      const foundType = clientTypes.find(({ value }) => value === item);

      const request = { name: newLabel };
      const response = await Customers.updateClientType(request, foundType?.response);

      const newTypes = clientTypes.map((type) => {
        if (type.response === response?.id) {
          return {
            ...type,
            label: response.name,
            value: response.name,
          };
        }

        return type;
      });

      SessionStorage.set(
        ACCOUNT_FILTER,
        newTypes.map(({ label }) => label)
      );

      setClientTypes(newTypes);

      const value = newTypes.find(({ label }) => label === newLabel);
      form.setFieldValue(name, value);
      setValue(value);
    } catch (error) {
      console.log('Error: could not edit', error);
    }
  };

  const onCreateClientType = (form) => (newLabel) => createClientType(newLabel, form);

  const onEditClientType = (form) => (item, newLabel) => editClientType(item, newLabel, form);

  const onChangeClientType = (form) => (args) => {
    form.setFieldValue(name, args);
    setValue(args);
  };

  const onSelectFocus = (form) => () => {
    form.setFieldTouched(name, true);
  };

  const onClearValues = (form) => () => {
    form.setFieldValue(name, null);
    setValue(null);

    if (handleClear) handleClear();
  };

  return (
    <>
      {isModal && (
        <FieldLabel>
          <span>
            {field?.fieldLabel} {field?.isRequired && '*'}
          </span>
        </FieldLabel>
      )}

      <Field name={name}>
        {({ field, form }) => (
          <CustomCreatableSelect
            options={clientTypes}
            inputValue={field.value}
            disabled={disabled}
            onChange={onChangeClientType(form)}
            onEdit={onEditClientType(form)}
            onCreate={onCreateClientType(form)}
            onDelete={deleteClientType}
            onClearValues={onClearValues(form)}
            onFocus={onSelectFocus(form)}
            onBlur={handleBlur}
          />
        )}
      </Field>
    </>
  );
};

export default React.memo(ClientType);
