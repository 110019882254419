import React, { useRef, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, InputGroup } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import { faCalendar, faClock } from '@fortawesome/pro-regular-svg-icons';
import Autocomplete from 'components/Autocomplete';
import { noop } from 'helpers/CommonHelper';
import { FIELD_TYPES, PERMISSIONS, PLACEHOLDERS, DEFAULT_FIELDS } from 'constants';
import { DateTimeContainer, FlatPickrContainer, RadioGroupContainer } from '../Details/Styles';

export const useReadOnlyField = ({ type, permission, label = '', value }) => {
  const dateRef = useRef(null);
  const timeRef = useRef(null);

  useEffect(() => {
    if (type === FIELD_TYPES.DATE && dateRef.current) dateRef.current.flatpickr._input.disabled = true;
    if (type === FIELD_TYPES.TIME && timeRef.current) timeRef.current.flatpickr._input.disabled = true;

    if (type === FIELD_TYPES.DATE_AND_TIME && dateRef.current && timeRef.current) {
      dateRef.current.flatpickr._input.disabled = true;
      timeRef.current.flatpickr._input.disabled = true;
    }
  }, [type]);

  /**
   * Displays the custom field depending on their
   * field type
   * @param type
   * @param value
   * @returns Field Component
   */
  const renderField = useCallback(() => {
    const options = {
      date: { altInput: true, static: true, mode: 'single', disableMobile: 'false' },
      time: { enableTime: true, static: true, noCalendar: true, altInput: true },
    };

    const getTextPlaceholder = () => {
      const labelObj = {
        [DEFAULT_FIELDS.ADDRESS]: PLACEHOLDERS.ADDRESS,
        [DEFAULT_FIELDS.EXTERNAL_ID]: PLACEHOLDERS.NUMBER,
        [DEFAULT_FIELDS.ADDRESS]: PLACEHOLDERS.ADDRESS,
        [DEFAULT_FIELDS.FIRST_NAME]: PLACEHOLDERS.FIRST_NAME,
        [DEFAULT_FIELDS.LAST_NAME]: PLACEHOLDERS.LAST_NAME,
        [DEFAULT_FIELDS.POSITION]: PLACEHOLDERS.POSITION,
        [DEFAULT_FIELDS.REVENUE]: PLACEHOLDERS.REVENUE,
        [DEFAULT_FIELDS.PHONE]: PLACEHOLDERS.PHONE,
        [DEFAULT_FIELDS.EMAIL]: PLACEHOLDERS.EMAIL,
      };

      return labelObj[label] || PLACEHOLDERS.TEXT;
    };

    const getDropdownPlaceholder = () => {
      const labelObj = {
        [DEFAULT_FIELDS.OWNER]: PLACEHOLDERS.OWNER,
        [DEFAULT_FIELDS.TYPE]: PLACEHOLDERS.TYPE,
      };
      return labelObj[label] || PLACEHOLDERS.SINGLE_DROPDOWN;
    };

    const defaultTextPlaceholder = getTextPlaceholder();

    const defaultDropdownPlaceholder = getDropdownPlaceholder();

    switch (type) {
      case FIELD_TYPES.PARAGRAPH:
        return (
          <Form.Control
            as="textarea"
            placeholder={PLACEHOLDERS.TEXT}
            rows={3}
            onChange={noop}
            disabled
            value={value}
          />
        );

      case FIELD_TYPES.YES_NO:
        return (
          <RadioGroupContainer>
            <Form.Check type="radio" label="Yes" checked onChange={noop} value={value} />
            <Form.Check type="radio" label="No" checked={false} onChange={noop} value={!value} />
          </RadioGroupContainer>
        );

      case FIELD_TYPES.EMAIL:
        return <Form.Control type="email" placeholder={PLACEHOLDERS.EMAIL} disabled value={value} />;

      case FIELD_TYPES.LINK:
        return (
          <Form.Control type="text" placeholder={PLACEHOLDERS.LINK} onChange={noop} disabled value={value} />
        );

      case FIELD_TYPES.SINGLE_DROPDOWN:
        return <Autocomplete placeholder={defaultDropdownPlaceholder} isDisabled value={value} />;

      case FIELD_TYPES.MULTI_DROPDOWN:
        return <Autocomplete placeholder={PLACEHOLDERS.MULTI_DROPDOWN} isDisabled value={value} />;

      case FIELD_TYPES.TIMEZONE:
        return <Autocomplete placeholder={PLACEHOLDERS.TIMEZONE} isDisabled value={value} />;

      case FIELD_TYPES.NUMBER:
        return (
          <Form.Control
            type="number"
            placeholder={PLACEHOLDERS.NUMBER}
            onChange={noop}
            disabled
            value={value}
          />
        );

      case FIELD_TYPES.CURRENCY:
        return (
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>$</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="number"
              placeholder={PLACEHOLDERS.REVENUE}
              onChange={noop}
              disabled
              value={value}
            />
          </InputGroup>
        );

      case FIELD_TYPES.SWITCH: {
        return <Form.Check type="switch" onChange={noop} value={value} />;
      }

      case FIELD_TYPES.DATE:
        return (
          <FlatPickrContainer>
            <Flatpickr
              ref={dateRef}
              className="form-control"
              placeholder={PLACEHOLDERS.BASE_DATE}
              options={options.date}
              value={value ? new Date(value) : null}
            />
            <FontAwesomeIcon icon={faCalendar} />
          </FlatPickrContainer>
        );

      case FIELD_TYPES.TIME:
        return (
          <FlatPickrContainer>
            <Flatpickr
              ref={timeRef}
              className="form-control"
              placeholder={PLACEHOLDERS.BASE_TIME}
              options={options.time}
              value={value ? new Date(value) : null}
            />
            <FontAwesomeIcon icon={faClock} />
          </FlatPickrContainer>
        );

      case FIELD_TYPES.DATE_TIME:
      case FIELD_TYPES.DATE_AND_TIME:
        return (
          <DateTimeContainer>
            <FlatPickrContainer>
              <Flatpickr
                ref={dateRef}
                className="form-control"
                placeholder={PLACEHOLDERS.BASE_DATE}
                options={options.date}
                value={value ? new Date(value) : null}
              />
              <FontAwesomeIcon icon={faCalendar} />
            </FlatPickrContainer>

            <FlatPickrContainer>
              <Flatpickr
                ref={timeRef}
                className="form-control"
                placeholder={PLACEHOLDERS.BASE_TIME}
                options={options.time}
                value={value ? new Date(value) : null}
              />
              <FontAwesomeIcon icon={faClock} />
            </FlatPickrContainer>
          </DateTimeContainer>
        );

      default:
        return (
          <Form.Control
            type="text"
            placeholder={defaultTextPlaceholder}
            onChange={noop}
            disabled
            value={value}
          />
        );
    }
  }, [label, type, value]);

  /**
   * Displays the custom field info text on their
   * field permission
   * @param type
   * @param value
   * @returns Field Info Text
   */
  const renderInfoText = useCallback(() => {
    switch (permission) {
      case PERMISSIONS.WONT_SYNC:
        return <p>This is a Stagebase field only.</p>;

      case PERMISSIONS.READ_ONLY:
        return (
          <p>
            Do not allow edits to this Salesforce field. <span>Edit</span>
          </p>
        );

      default:
        return (
          <p>
            Sync all updates here back to Salesforce and vice versa. <span>Edit</span>
          </p>
        );
    }
  }, [permission]);

  return {
    renderField,
    renderInfoText,
  };
};
