import React, { useEffect, useMemo } from 'react';
import { AddCardContainer, ErrorMessageContainer } from './Styles';
import { CardElement } from '@stripe/react-stripe-js';
import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
} from 'components/Modals/LargePadding';
import { FormInput } from 'components/Formik/FormInput';
import { useFormikContext } from 'formik';
import { FormDropdown } from 'components/Formik/FormDropdown';
import { FORMIK_FIELD_TYPES } from 'constants';
import { monthList, stateList, yearList } from './fixtures';
import { Form } from 'react-bootstrap';
import { cardFields } from 'views/Settings/fixtures';
import Select from 'react-select';
import { DropdownContainer } from 'components/Modals/CanvasSettings/styles';
import { isPast } from 'date-fns';

const Card = ({ show, onHide, isCardLoading }) => {
  const { submitForm, setFieldError, setFieldValue, setFieldTouched, errors, isSubmitting, values } =
    useFormikContext();

  const { mode } = values;

  const isEdit = mode === 'edit';

  const handleCardChange = (event) => {
    setFieldError('isCardValid', '');

    if (event?.error && !event?.completed && !isEdit) {
      setFieldError('isCardValid', event?.error?.message);
      return;
    }
  };

  useEffect(() => {
    if (values.expMonth && values.expYear && isEdit) {
      const formattedDate = new Date(`${values?.expYear.value}/${values?.expMonth.value}`);

      isPast(formattedDate)
        ? setFieldError('expYear', 'Please enter a future date')
        : setFieldError('expYear', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.expMonth, values.expYear, isEdit]);

  const hasCardErrors = useMemo(() => !!errors?.isCardValid, [errors]);
  const hasDateErrors = useMemo(() => !!errors?.expYear, [errors]);

  const renderContent = (
    <AddCardContainer>
      <h1 className="mb-3 text-center">{isEdit ? 'Edit Payment Method' : 'New Payment Method'}</h1>

      <h2>Card Details</h2>

      <div className="mb-3">
        <FormInput fieldLabel="Cardholder Name" isRequired name="cardName" type="text" />
      </div>

      {!isEdit && (
        <div className="card mb-3">
          <Form.Label>Card *</Form.Label>

          <CardElement
            onChange={handleCardChange}
            options={{
              hidePostalCode: true,
              classes: {
                focus: 'card-focus',
                invalid: 'card-error',
              },
            }}
          />

          {!!hasCardErrors && (
            <ErrorMessageContainer>
              <span>{errors?.isCardValid}</span>
            </ErrorMessageContainer>
          )}
        </div>
      )}

      {mode === 'edit' && (
        <div className="mb-3">
          <Form.Label>Expiration Date *</Form.Label>

          <div className="expiry-dropdowns">
            <DropdownContainer className="mr-2 month-picker">
              <Select
                value={values.expMonth}
                classNamePrefix="select"
                isSearchable={false}
                placeholder="Month"
                onChange={(value) => {
                  setFieldValue('expMonth', value);
                  setFieldTouched('expMonth', true);
                }}
                options={monthList}
              />
            </DropdownContainer>

            <DropdownContainer className="year-picker">
              <Select
                value={values.expYear}
                classNamePrefix="select"
                isSearchable={false}
                placeholder="Year"
                onChange={(value) => {
                  setFieldValue('expYear', value);
                  setFieldTouched('expYear', true);
                }}
                options={yearList}
              />
            </DropdownContainer>
          </div>

          {!!hasDateErrors && (
            <ErrorMessageContainer>
              <span>{errors?.expYear}</span>
            </ErrorMessageContainer>
          )}
        </div>
      )}

      <hr />

      <h2>Billing Address</h2>

      {cardFields.map((card, index) => (
        <div className="mb-3" key={index}>
          {card?.fieldType === 'input' ? (
            <FormInput
              fieldLabel={card?.fieldLabel}
              isRequired={card?.isRequired}
              name={card?.fieldName}
              type={card?.type}
              placeholder={card?.placeholder}
              maxLength={card?.maxLength}
            />
          ) : (
            <FormDropdown
              fieldLabel={card?.fieldLabel}
              isRequired={card?.isRequired}
              type={FORMIK_FIELD_TYPES.SEARCHABLE}
              name={card?.fieldName}
              options={stateList}
              searchableProps={{
                placeholder: card?.placeholder,
              }}
            />
          )}
        </div>
      ))}
    </AddCardContainer>
  );

  const renderButtons = (
    <>
      <ModalLargePaddingCancelButton onClick={onHide} autoWidth />
      <ModalLargePaddingConfirmButton
        onClick={submitForm}
        disabled={hasCardErrors || hasDateErrors}
        loading={isSubmitting || isCardLoading}
        text="Save"
        autoWidth
      />
    </>
  );

  const modalStyle = {
    inner: {
      maxWidth: 'unset',
      padding: '0 !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'var(--light-gray-bg)',
      borderRadius: '0 0 0.813rem 0.813rem',
      padding: '1.875rem 0 2rem 0 !important',
    },
  };

  return (
    <ModalLargePadding
      show={show}
      innerProps={modalStyle.inner}
      buttonsContainerProps={modalStyle.buttonContainer}
      content={renderContent}
      buttons={renderButtons}
    />
  );
};

export default Card;
