import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalLargePadding from 'components/Modals/LargePadding';
import { CONTACT_LABEL, CUSTOMERS_FIELDS } from 'constants';
import { useFormikContext } from 'formik';
import { noop } from 'helpers/CommonHelper';
import { TextButton } from 'views/Customer/Styles';
import PreviewMoreInfoFields from './PreviewMoreInfoFields';

const PreviewMoreInfo = ({ show, handleHide }) => {
  const { values } = useFormikContext();

  const modalStyle = {
    inner: {
      maxWidth: '35.25rem',
      padding: '2rem 0 !important',
    },
  };

  return (
    <>
      <ModalLargePadding
        show={show}
        onHide={handleHide}
        innerProps={modalStyle.inner}
        content={
          <div>
            <div className="text-center">
              <h1 className="mb-3 d-inline-flex">{CONTACT_LABEL.MOREINFO}</h1>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={handleHide}
                className="close-button clickable float-right fa-xl"
              />
            </div>
            {values.contactFields.map((item, index) => {
              const name = `${CUSTOMERS_FIELDS.CONTACT_FIELDS}[${index}].formikValue`;
              return (
                <PreviewMoreInfoFields
                  key={index}
                  fieldIndex={index}
                  isRequired={item?.isRequired}
                  name={name}
                />
              );
            })}
          </div>
        }
        buttons={
          <TextButton onClick={noop} color="var(--danger)">
            Delete Contact
          </TextButton>
        }
      />
    </>
  );
};

export default PreviewMoreInfo;
