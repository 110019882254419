import styled from 'styled-components';

export const ModalContainer = styled.div`
  padding: 2rem 2.5rem 8.813rem 2.5rem;
  background: var(--marketing-gradient);
  position: relative;
  overflow: hidden;

  .pricing-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      font-weight: 400;
      font-size: 1.813rem;
      line-height: 2.5rem;
      color: var(--white);
    }

    .fa-xmark {
      position: absolute;
      right: 2.5rem;
      font-size: 1.2rem;
      color: var(--white);
      cursor: pointer;
    }
  }

  h4 {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.625rem;
  }

  .pricing-plans {
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    margin-top: 1.276rem;

    .pricing-plan {
      text-align: center;
      background-color: var(--standard-snow);
      padding: 1rem;
      border-radius: 0.313rem;

      hr {
        margin: 0;
        border-top: 1px solid var(--champion-charcoal);
      }

      &__name-free,
      &__name-teams,
      &__name-integrations {
        color: var(--schedule-sapphire);
      }

      &__info {
        margin-top: 0.375rem;
      }

      &__features {
        margin-block: 0.75rem;
        list-style-type: none;
        padding: 0;

        li {
          text-align: left;
          line-height: 1.625rem;
        }

        &-teams,
        &-integrations {
          font-weight: 700;
        }
      }
    }

    .pricing-plan.active {
      border: 1px solid var(--playbook-pewter);
    }

    .pricing-plan.default {
      box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    }
  }

  @media only screen and (max-width: 769px) {
    padding-inline: 2.25rem;
  }

  @media only screen and (max-width: 426px) {
    .pricing-plans {
      flex-direction: column;
      align-items: center;
    }
  }
`;
