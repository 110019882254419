import { useRef, useContext } from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import DueDate from './DueDate';
import useOutsideClickNotifier from 'helpers/hooks/useOutsideClickNotifier';
import DateChangeContext from '../context/dateChangeContext';
import { Modal, ModalWrapper, HeaderText, CloseBtn, DateFields } from '../styles/DueDate';

const EditDateModal = ({ dynamicTimeline, dependentData, touchpoint, onCanvasUpdate }) => {
  const { setShowEditDateModal } = useContext(DateChangeContext);
  const clickRef = useRef(null);
  useOutsideClickNotifier(clickRef, () => {
    setShowEditDateModal(false);
  });

  let header = 'Edit Due Date';
  if (touchpoint?.customerTouchpointStatus === 'Completed') {
    header = 'Edit Completion Date';
  } else if (touchpoint?.dateToBeScheduled) {
    header = 'Edit Scheduled Date';
  }

  return (
    <Modal>
      <ModalWrapper ref={clickRef}>
        <div className="position-relative">
          <HeaderText>{header}</HeaderText>
          <CloseBtn icon={faTimes} onClick={() => setShowEditDateModal(false)} />
          <DateFields>
            <DueDate
              dynamicTimeline={dynamicTimeline}
              dependentData={dependentData}
              touchpoint={touchpoint}
              onCanvasUpdate={onCanvasUpdate}
            />
          </DateFields>
        </div>
      </ModalWrapper>
    </Modal>
  );
};

export default EditDateModal;
