import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import LoadingSpinner from '../Loading/LoadingSpinner';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

const InnerDiv = styled.div`
  max-width: ${(props) => props.maxWidth || '520px'};
  padding: ${(props) => props.padding || '40px 20px'};
  height: ${(props) => props.height};
`;

const ButtonsContainer = styled.div`
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justifyContent};
  text-align: center;
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius};
  padding: ${(props) => props.padding};
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${({ color }) => color ?? 'var(--icon-light-gray)'};
`;

const StyledTimesIcon = styled(FontAwesomeIcon)`
  top: 10;
  right: 15;
`;

const StyledModal = styled(Modal)`
  z-index: 5000;

  .modal-content {
    border: 1px solid var(--border-gray);
  }
`;

export function ModalLargePaddingHeader({ icon, text, iconColor }) {
  return (
    <div className="d-flex align-items-center mb-4">
      <h1 className="d-flex align-items-center">
        <div className="circle-icon mr-2-5">
          <StyledIcon icon={icon} color={iconColor} />
        </div>
        {text}
      </h1>
    </div>
  );
}

export function ModalLargePaddingCancelButton({ onClick, autoWidth, text = 'Cancel' }) {
  return (
    <Button
      variant="outline-light"
      onClick={onClick}
      className={`mr-0 mr-sm-2 mb-2 mb-sm-0 bg-white ${autoWidth ? 'auto' : 'w-100'}`}
    >
      {text}
    </Button>
  );
}

export function ModalLargePaddingConfirmButton({
  onClick,
  loading,
  disabled,
  variant,
  text,
  type,
  autoWidth,
}) {
  return (
    <Button
      variant={variant}
      onClick={onClick}
      className={`ml-0 ml-sm-2 ${autoWidth ? 'auto' : 'w-100'}`}
      disabled={loading || disabled}
      type={type}
    >
      {loading ? <LoadingSpinner white /> : text || 'Save'}
    </Button>
  );
}

export default function ModalLargePadding({
  show,
  onHide,
  content,
  buttons,
  dimissable,
  innerProps,
  buttonsContainerProps,
  centered = true,
  ...props
}) {
  return (
    <StyledModal show={show} onHide={onHide} centered={centered} className="modal-large-padding" {...props}>
      {dimissable && <StyledTimesIcon className="position-absolute" icon={faTimes} onClick={onHide} />}
      <div className={`wrapper ${props.wrapperClassName ?? ''}`}>
        <InnerDiv className={`inner ${props.innerclassname ?? ''}`} {...innerProps}>
          {content}
          {buttons && (
            <ButtonsContainer className={`buttons ${props.buttonclassname}`} {...buttonsContainerProps}>
              {buttons}
            </ButtonsContainer>
          )}
        </InnerDiv>
      </div>
    </StyledModal>
  );
}
