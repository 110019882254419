import React from 'react';
import Select from 'react-select';

export default function Autocomplete(props) {
  const selectedValue = props?.options?.find((option) => option.value === props?.value?.value);
  if (props?.value?.label === ' ') {
    props.value.label = selectedValue?.label;
  }
  return (
    <Select
      {...props}
      className={(props.className || '') + ' react-select-container'}
      classNamePrefix="react-select"
      isClearable={true}
      id={props.testId}
      //   options={props.options}
      //   placeholder={props.placeholder}
    />
  );
}
