import styled from 'styled-components';
import { SectionBody as BaseSectionBody } from 'components/CustomFields/Details/Styles';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const SectionBody = styled(BaseSectionBody)`
  padding-left: 0rem;

  .custom-field__input {
    margin-left: 0rem;

    &.date-time .form-control:disabled,
    &.date-time .form-control[readonly] {
      background-color: var(--light-gray-bg);
    }

    .error-container {
      display: none;
    }
  }

  .custom-field__options span {
    margin-right: 0rem;
  }
`;

export const PhoneStyled = styled.div`
  color: var(--playbook-pewter);
`;

export const TextButton = styled.a`
  color: ${({ color }) => color ?? 'var(--champion-charcoal)'};
  font-weight: 700;
  ${({ isUnderlined }) => (isUnderlined ? 'text-decoration: underline;' : null)}
  :hover {
    color: ${({ color }) => color ?? 'var(--champion-charcoal)'};
    font-weight: 700;
    cursor: pointer;
  }
`;

export const StyledTable = styled(Table)`
  & > thead {
    & > tr {
      & > td {
        padding: 0.75rem 2.8125rem 0.75rem 0;
        vertical-align: middle;
        white-space: nowrap;
        min-width: 4rem;
        color: var(--playbook-pewter);
      }
    }
  }

  & > tbody {
    & > tr {
      :hover {
        cursor: auto;
      }
      & > td {
        padding: 1rem 2.8125rem 1rem 0;
        vertical-align: middle;
        white-space: nowrap;
      }
    }
  }
`;

export const TableContainer = styled.div`
  overflow-x: auto;
`;

export const EditFieldsLink = styled(Link)`
  font-size: 0.938rem;
  font-weight: 400;
  color: var(--champion-charcoal);
  border: 1px solid var(--border-gray);
  display: block;
  max-height: 2.25rem;
  padding: 0.4rem 1.5rem;
  border-radius: 1.125rem;
  margin-bottom: 0.375rem;
  transition: background-color, border-color 0.15s ease-in-out;

  &:hover {
    background-color: var(--light-gray-bg);
    border: 1px solid var(--light-gray-bg);
    color: var(--champion-charcoal);
    text-decoration: none;
  }
`;
