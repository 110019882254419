import { useContext } from 'react';
import dateChangeContext from '../context/dateChangeContext';
import DateMessage from './DateMessage';
import { Button, PrimaryButton } from 'components/Modals/Touchpoint/Styles';
import { ButtonWrapper, CautionSection, DateMessageWrapper, LoadingWrapper } from '../styles';
import { Touchpoint } from 'models';
import LoadingSpinner from 'components/Loading/LoadingSpinner';

const DueDateFooter = ({
  isNewForecastedEndDateSame,
  formattedForecastedEndDate,
  formattedNewForecastedEndDate,
  dynamicTimeline,
  showChangedDate,
  touchpoint,
  didExceed,
  onCanvasUpdate,
} = {
    touchpoint: new Touchpoint(),
  }) => {
  const {
    activeTouchpoint,
    isAccounts,
    isDateModified,
    isEarlierDate,
    isLoading,
    saveTemplateDate,
    saveProjectDateInfo,
    setShowEditDateModal,
    warnings,
  } = useContext(dateChangeContext);

  const hasWarnings = warnings?.length;
  const changedCompletedDynamicView = touchpoint?.isComplete && dynamicTimeline && !!warnings.length && showChangedDate;
  const adjustStylesOn = touchpoint?.isIncomplete && (touchpoint?.isScheduledType
    || hasWarnings || showChangedDate || isLoading);

  return (
    <DateMessageWrapper
      background={
        touchpoint?.isIncomplete && (
          showChangedDate
          || (showChangedDate && hasWarnings)
          || isLoading)
          ? 'var(--light-gray-bg)' : ''}
      margin={adjustStylesOn ? '30px -40px -32px !important' : ''}
      padding={adjustStylesOn ? '0px 40px 32px' : ''}
      borderRadius={adjustStylesOn ? '0 0 0.8125rem 0.8125rem' : ''}
    >
      {activeTouchpoint && (
        <DateMessage
          dynamicTimeline={dynamicTimeline}
          showChangedDate={showChangedDate}
          didExceed={didExceed}
          touchpoint={touchpoint}
        />
      )}
      {
        changedCompletedDynamicView && isLoading &&
        <LoadingWrapper>
          <LoadingSpinner />
        </LoadingWrapper>
      }
      {
        !isLoading && ((touchpoint?.isIncomplete
          && (((!!warnings.length && !touchpoint?.isScheduledType) && showChangedDate)
            || (showChangedDate && formattedForecastedEndDate && formattedNewForecastedEndDate
              && !isNewForecastedEndDateSame)))
          || changedCompletedDynamicView) && (
          <CautionSection>
            <p>CAUTION</p>
            <ul>
              {!!warnings.length
                && (!touchpoint?.dateToBeScheduled || touchpoint?.isComplete) && (
                  <li>
                    {warnings.length} dependent {warnings.length === 1 ? 'step' : 'steps'} will be due{' '}
                    {isEarlierDate ? 'earlier' : 'later'}
                  </li>
                )}
              {
                touchpoint?.isIncomplete && !isNewForecastedEndDateSame && (
                  <li>
                    {touchpoint?.isScheduledType ? 'The forecasted project ' : 'Project '}
                    end date will change from {formattedForecastedEndDate} to{' '}
                    {formattedNewForecastedEndDate}
                  </li>
                )}

            </ul>
          </CautionSection>
        )
      }
      <ButtonWrapper>
        <Button
          fontSize="15px"
          width="95px"
          height="36px"
          onClick={() => setShowEditDateModal(false)}
        >
          Cancel
        </Button>
        <PrimaryButton
          disabled={!isDateModified}
          onClick={() => {
            isAccounts ? saveProjectDateInfo() : saveTemplateDate().then(() => onCanvasUpdate());
          }}
          fontSize="15px"
        >
          Confirm
        </PrimaryButton>
      </ButtonWrapper>
    </DateMessageWrapper >
  );
}

export default DueDateFooter;
