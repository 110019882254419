import { faUserGroup } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 1.25rem;
  background: var(--light-gray-bg);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: 1.125rem;
  padding: 0.5rem 0.75rem;
  align-items: center;

  .user-icon {
    margin-right: 0.5rem;
    color: var(--implement-iron);
  }
`;

const Label = styled.span`
  margin-right: 0.5rem;
  color: var(--champion-charcoal);
`;

const CustomerFacingToggle = ({ checked, onChange }) => {
  return (
    <>
      <Wrapper className="d-flex justify-content-end settings-section-title my-4 mx-3">
        <FontAwesomeIcon className="user-icon" fixedWidth icon={faUserGroup} />
        <Label>Customer-facing steps</Label>
        <Form.Check
          type="switch"
          id="isPreviewMode"
          name="isPreviewMode"
          checked={checked}
          onChange={onChange}
        />
      </Wrapper>
    </>
  );
};

export default CustomerFacingToggle;
