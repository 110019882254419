import { useEffect, useState } from 'react';
import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
} from './LargePadding';
import { Form } from 'react-bootstrap';

export default function PauseCanvas({
  show,
  onHide,
  onConfirm,
  confirmLoading,
  pausingCanvasNote,
  handlePauseCanvasNoteUpdates
}) {
  const [loading, setLoading] = useState(false);
  const [unmounted, setUnmounted] = useState(false);

  useEffect(() => {
    return () => {
      setUnmounted(true);
    };
  }, []);

  async function handleOnConfirm() {
    setLoading(true);
    await onConfirm();
    if (!unmounted) {
      setLoading(false);
    }
  }

  return (
    <ModalLargePadding
      show={show}
      onHide={onHide}
      content={
        <div className="text-center">
          <h1 className="mb-3">Pause Canvas</h1>
          <div className="mb-4">Remember to explain why this canvas is being put on hold.</div>
          <Form.Control
            as="textarea"
            className="mb-2-5"
            rows="6"
            value={pausingCanvasNote}
            placeholder="Add note ..."
            //disabled={creating}
            onChange={handlePauseCanvasNoteUpdates}
            //ref={textInput}
          />
        </div>
      }
      buttons={
        <>
          <ModalLargePaddingCancelButton onClick={onHide} />
          <ModalLargePaddingConfirmButton
            onClick={handleOnConfirm}
            variant={'primary'}
            loading={loading}
            text='Confirm'
          />
        </>
      }
    />
  );
}
