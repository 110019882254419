import { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { faCopy, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingInline from 'components/Loading/LoadingInline';
import { Button, Form } from 'react-bootstrap';
import { LogoWrapper, StyledImg, SubdomainContainer } from './styles';
import { Settings } from 'models';
import toast from 'components/toast';
import { useLocation } from 'react-router-dom';
import { employeePermissions } from './fixtures';
import ModalModifySubdomain from 'components/Modals/Settings/ModifySubdomain';
import ModalDisableSubdomain from 'components/Modals/Settings/DisableSubdomain/DisableSubdomain';
import { FormikContext, useFormik } from 'formik';
import { subdomainSchema } from './validations';
import FullContext from 'stores/Full/fullContext';
import { ADMIN } from 'constants';

const General = ({ appSettings, changeBrandImage }) => {
  const { roleAtleast } = useContext(FullContext);

  const location = useLocation();

  const fileInput = useRef(null);

  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModifySubdomain, setShowModifySubdomain] = useState(false);
  const [showDisableSubdomain, setShowDisableSubdomain] = useState(false);
  const [generalConfig, setGeneralConfig] = useState([]);
  const [subdomainConfig, setSubdomainConfig] = useState(null);

  /**
   * Callback used to retrieve the general endpoint
   */
  const getGeneralConfig = useCallback(async () => {
    const _config = await Settings.getGeneralConfig();
    setGeneralConfig(_config);

    if (roleAtleast(ADMIN)) {
      const _subdomainConfig = await Settings.getSubdomain();

      setSubdomainConfig(_subdomainConfig);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!appSettings?.logo) {
      setLogo(appSettings?.logo);
    }
  }, [appSettings?.logo]);

  useEffect(() => {
    if (!generalConfig.length && (location.hash === '' || '#general')) {
      getGeneralConfig();
    }
  }, [getGeneralConfig, generalConfig.length, location.hash]);

  const onLogoDrop = async (event) => {
    setLoading(true);
    const response = await Settings.uploadLogo(event.target.files);
    const data = await response.json();
    if (response.status === 400) {
      toast.error(data);
    } else {
      setLogo(data.filePath);
      if (data.filePath) await updateLogo(data.filePath);
    }
    setLoading(false);
  };

  const updateLogo = async (logo) => {
    const _appSettings = {
      ...appSettings,
      logo,
    };
    await Settings.UpdateAppSettings(_appSettings);
    changeBrandImage();
  };

  /**
   * Handler that updates the state of the switches under the
   * Employees Permissions
   * @param {Event} event - event object upon clicking
   * @param {string} name - the fieldName of the  switch to be updated
   * @param {number} index
   */
  const permissionsHandler = async (event, name, index) => {
    const value = event.target.checked;
    await Settings.editGeneralPermissions(value, name);
    const _generalConfig = generalConfig.map((config, _index) => {
      if (index === _index) {
        return {
          ...config,
          value,
        };
      }
      return config;
    });
    setGeneralConfig(_generalConfig);
  };

  const handleHide = () => {
    setShowModifySubdomain(false);
    formikBag.resetForm();
  };

  const handleSubmit = async ({ subdomainName }) => {
    const payload = [
      {
        op: 'replace',
        path: 'subdomain',
        value: subdomainName,
      },
      {
        op: 'replace',
        path: 'isSubdomainEnabled',
        value: true,
      },
    ];

    await Settings.updateSubdomain(payload);
    handleHide();
    getGeneralConfig();
  };

  const formikBag = useFormik({
    initialValues: { subdomainName: '', mode: 'add' },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: subdomainSchema,
    onSubmit: handleSubmit,
  });

  const handleCopyURL = () => {
    const formattedURL = `https://${subdomainConfig?.subdomain}.stagebase.com`;

    navigator.clipboard.writeText(formattedURL);
    toast.success('Subdomain copied');
  };

  const handleModifySubdomain = () => {
    setShowModifySubdomain(true);
    formikBag.setFieldValue('mode', 'edit');
    formikBag.setFieldValue('subdomainName', subdomainConfig?.subdomain);
  };

  const handleConfigureSubdomain = () => {
    setShowModifySubdomain(true);
    formikBag.setFieldValue('mode', 'add');
  };

  const handleDisableSubdomain = async () => {
    const payload = [
      {
        op: 'replace',
        path: 'isSubdomainEnabled',
        value: false,
      },
    ];

    setLoading(true);

    await Settings.updateSubdomain(payload);

    setShowDisableSubdomain(false);
    getGeneralConfig();
    setLoading(false);
  };

  return (
    <>
      <h2 className="mt-4 mb-3 top">Logo</h2>
      <LogoWrapper>
        <input id="logo-input" hidden type="file" onChange={onLogoDrop} ref={fileInput} />
        {!!logo ? (
          <StyledImg src={logo} alt="Logo" />
        ) : (
          <div className="settings-logo-placeholder">
            <div className="inner">Add customer-facing logo</div>
          </div>
        )}
        <div className="d-flex">
          <Button
            onClick={() => fileInput.current.click()}
            disabled={loading}
            variant="secondary"
            block
            className="mt-3"
          >
            {loading ? (
              <LoadingInline centered white text="Uploading..." />
            ) : !!logo ? (
              'Upload new'
            ) : (
              <FontAwesomeIcon icon={faPlus} />
            )}
          </Button>
          {!!logo && !loading && (
            <Button
              className="mt-3 ml-3"
              onClick={() => {
                setLogo(null);
                updateLogo(null);
              }}
              variant="danger"
              block
            >
              Delete
            </Button>
          )}
        </div>
      </LogoWrapper>
      <h2 className="mb-1 top">Employee Permissions</h2>
      <div className="fs-7 mb-4">Control permissions for frontline users:</div>
      {generalConfig?.map((config, index) => (
        <div className="settings-row" key={`general-config-${index}`}>
          {employeePermissions[index].label}
          <Form.Check
            type="switch"
            id={config.name}
            name={config.name}
            checked={config.value}
            onChange={(event) => permissionsHandler(event, config.name, index)}
          />
        </div>
      ))}

      {roleAtleast(ADMIN) && (
        <FormikContext.Provider value={formikBag}>
          <SubdomainContainer>
            <h2 className="mt-5 mb-1 top">Customer Portal</h2>
            <div className="fs-7 mb-4">
              Configure a custom co-branded subdomain for internal and external users.
            </div>

            {!!subdomainConfig?.subdomain && subdomainConfig?.isSubdomainEnabled ? (
              <>
                <div className="subdomain-container">
                  <div className="subdomain-container__name-box">
                    <span>{subdomainConfig?.subdomain}.stagebase.com</span>
                  </div>
                  <FontAwesomeIcon icon={faCopy} onClick={handleCopyURL} className="clickable" />
                </div>

                <div className="d-flex mb-5">
                  <Button className="mr-3" onClick={handleModifySubdomain} variant="secondary">
                    Modify
                  </Button>
                  <Button onClick={() => setShowDisableSubdomain(true)} variant="danger">
                    Disable
                  </Button>
                </div>
              </>
            ) : (
              <Button className="mb-5" onClick={handleConfigureSubdomain}>
                Configure
              </Button>
            )}

            <ModalModifySubdomain show={showModifySubdomain} onHide={handleHide} />
            <ModalDisableSubdomain
              show={showDisableSubdomain}
              onSubmit={handleDisableSubdomain}
              onHide={() => setShowDisableSubdomain(false)}
            />
          </SubdomainContainer>
        </FormikContext.Provider>
      )}
    </>
  );
};

export default General;
