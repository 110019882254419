import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import {
  faCaretDown,
  faText,
  fa1,
  faAlignJustify,
  faCalendar,
  faDollar,
  faListCheck,
  faClockFive,
  faCalendarDay,
  faEarthAmerica,
  faAt,
  faLink,
  faCircleDot,
} from '@fortawesome/pro-solid-svg-icons';
import { faJira, faSlack, faSalesforce, faHubspot } from '@fortawesome/free-brands-svg-icons';
import { NEW_SECTION } from 'constants';

export const extendedDataList = [
  {
    value: 'Atlassian Jira',
    label: 'Atlassian Jira',
    icon: faJira,
  },
  {
    value: 'Slack',
    label: 'Slack',
    icon: faSlack,
  },
  {
    value: 'Salesforce',
    label: 'Salesforce',
    icon: faSalesforce,
  },
];

export const stagebaseList = [
  {
    value: 'New Stagebase Field',
    label: 'New Stagebase Field',
    icon: faPlus,
  },
];

export const dataSourceList = [
  {
    label: 'Extended Data Source',
    options: extendedDataList,
  },
  {
    label: 'Stagebase Only',
    options: stagebaseList,
  },
];

export const currencyList = [
  {
    value: 'Dollars',
    label: 'Dollars',
  },
];

export const fieldTypeList = [
  {
    value: 'Text',
    label: 'Text box',
    icon: faText,
  },
  {
    value: 'Paragraph',
    label: 'Paragraph',
    icon: faAlignJustify,
  },
  {
    value: 'Single-select dropdown',
    label: 'Single-select dropdown',
    icon: faCaretDown,
  },
  {
    value: 'Multi-select dropdown',
    label: 'Multi-select dropdown',
    icon: faListCheck,
  },
  {
    value: 'Number',
    label: 'Number (12345)',
    icon: fa1,
  },
  {
    value: 'Currency',
    label: 'Currency ($1,000)',
    icon: faDollar,
  },
  {
    value: 'Date',
    label: 'Date (01/01/2000)',
    icon: faCalendar,
  },
  {
    value: 'Time',
    label: 'Time (8:00 AM)',
    icon: faClockFive,
  },
  {
    value: 'Date and Time',
    label: 'Date and Time (8:00 AM 01/01/2000)',
    icon: faCalendarDay,
  },
  {
    value: 'Timezone',
    label: 'Timezone (GMT-06:00 Central Time)',
    icon: faEarthAmerica,
  },
  {
    value: 'Email',
    label: 'Email (name@email.com)',
    icon: faAt,
  },
  {
    value: 'Link',
    label: 'Link (www.stagebase.com)',
    icon: faLink,
  },
  {
    value: 'Yes/No',
    label: 'Yes/No',
    icon: faCircleDot,
  },
];

export const externalAccountList = [
  {
    value: 'account-email',
    label: 'Email',
  },
  {
    value: 'account-time',
    label: 'Time',
  },
];

export const externalLeadList = [
  {
    value: 'lead-birthday',
    label: 'Birthday',
  },
  {
    value: 'lead-contactNumber',
    label: 'Contact Number',
  },
  {
    value: 'lead-timezone',
    label: 'Timezone',
  },
  {
    value: 'lead-type',
    label: 'Type',
  },
];

export const externalOpportunityList = [
  {
    value: 'opportunity-currency',
    label: 'Currency',
  },
  {
    value: 'opportunity-time',
    label: 'Time',
  },
];

export const externalFieldList = [
  {
    label: 'Account',
    options: externalAccountList,
  },
  {
    label: 'Lead',
    options: externalLeadList,
  },
  {
    label: 'Opportunity',
    options: externalOpportunityList,
  },
];

export const permissionList = [
  {
    value: 'Read and Write',
    label: 'Read and Write',
  },
  {
    value: 'Read Only',
    label: 'Read Only',
  },
];

export const sourceIcons = [faSalesforce, faHubspot];
export const sourceEnums = [2, 3, 4];
export const stagebaseIcons = [faPlus];
export const stagebaseEnums = [1];
export const fieldTypeIcons = [
  faText,
  faAlignJustify,
  faCaretDown,
  faListCheck,
  fa1,
  faDollar,
  faCalendar,
  faClockFive,
  faCalendarDay,
  faEarthAmerica,
  faAt,
  faLink,
  faCircleDot,
];
export const fieldTypeEnums = Array.from({ length: 13 }, (_, i) => i + 1);
export const permissionEnums = Array.from({ length: 3 }, (_, i) => i + 1);

export const NAPermission = {
  value: 'NA',
  label: 'NA',
  request: 0,
  response: 'NA',
};

export const defaultSection = { value: NEW_SECTION, label: 'New section...' };

export const getSourceTypes = (display) => {
  switch (display) {
    case 'Salesforce':
      return sourceEnums[0];
    case 'Hubspot':
      return sourceEnums[1];
    default:
      return sourceEnums[2];
  }
};
