export const timezoneList = [
  {
    label: '(GMT-12:00) Eniwetok, Kwajalein',
    value: '(GMT-12:00) Eniwetok, Kwajalein',
    response: '-12:00',
  },
  {
    label: '(GMT-11:00) Midway Island, Samoa',
    value: '(GMT-11:00) Midway Island, Samoa',
    response: '-11:00',
  },
  {
    label: '(GMT-10:00) Hawaii',
    value: '(GMT-10:00) Hawaii',
    response: '-10:00',
  },
  {
    label: '(GMT-9:30) Taiohae',
    value: '(GMT-9:30) Taiohae',
    response: '-09:50',
  },
  {
    label: '(GMT-9:00) Alaska',
    value: '(GMT-9:00) Alaska',
    response: '-09:00',
  },
  {
    label: '(GMT-8:00) Pacific Time (US & Canada)',
    value: '(GMT-8:00) Pacific Time (US & Canada)',
    response: '-08:00',
  },
  {
    label: '(GMT-7:00) Mountain Time (US & Canada)',
    value: '(GMT-7:00) Mountain Time (US & Canada)',
    response: '-07:00',
  },
  {
    label: '(GMT-6:00) Central Time (US & Canada), Mexico City',
    value: '(GMT-6:00) Central Time (US & Canada), Mexico City',
    response: '-06:00',
  },
  {
    label: '(GMT-5:00) Eastern Time (US & Canada), Bogota, Lima',
    value: '(GMT-5:00) Eastern Time (US & Canada), Bogota, Lima',
    response: '-05:00',
  },
  {
    label: '(GMT-4:30) Caracas',
    value: '(GMT-4:30) Caracas',
    response: '-04:50',
  },
  {
    label: '(GMT-4:00) Atlantic Time (Canada), Caracas, La Paz',
    value: '(GMT-4:00) Atlantic Time (Canada), Caracas, La Paz',
    response: '-04:00',
  },
  {
    label: '(GMT-3:30) Newfoundland',
    value: '(GMT-3:30) Newfoundland',
    response: '-03:50',
  },
  {
    label: '(GMT-3:00) Brazil, Buenos Aires, Georgetown',
    value: '(GMT-3:00) Brazil, Buenos Aires, Georgetown',
    response: '-03:00',
  },
  {
    label: '(GMT-2:00) Mid-Atlantic',
    value: '(GMT-2:00) Mid-Atlantic',
    response: '-02:00',
  },
  {
    label: '(GMT-1:00) Azores, Cape Verde Islands',
    value: '(GMT-1:00) Azores, Cape Verde Islands',
    response: '-01:00',
  },
  {
    label: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
    value: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
    response: '+00:00',
  },
  {
    label: '(GMT+1:00) Brussels, Copenhagen, Madrid, Paris',
    value: '(GMT+1:00) Brussels, Copenhagen, Madrid, Paris',
    response: '+01:00',
  },
  {
    label: '(GMT+2:00) Kaliningrad, South Africa',
    value: '(GMT+2:00) Kaliningrad, South Africa',
    response: '+02:00',
  },
  {
    label: '(GMT+3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
    value: '(GMT+3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
    response: '+03:00',
  },
  {
    label: '(GMT+3:30) Tehran',
    value: '(GMT+3:30) Tehran',
    response: '+03:50',
  },
  {
    label: '(GMT+4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
    value: '(GMT+4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
    response: '+04:00',
  },
  {
    label: '(GMT+4:30) Kabul',
    value: '(GMT+4:30) Kabul',
    response: '+04:50',
  },
  {
    label: '(GMT+5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
    value: '(GMT+5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
    response: '+05:00',
  },
  {
    label: '(GMT+5:30) Bombay, Calcutta, Madras, New Delhi',
    value: '(GMT+5:30) Bombay, Calcutta, Madras, New Delhi',
    response: '+05:50',
  },
  {
    label: '(GMT+5:45) Kathmandu, Pokhara',
    value: '(GMT+5:45) Kathmandu, Pokhara',
    response: '+05:75',
  },
  {
    label: '(GMT+6:00) Almaty, Dhaka, Colombo',
    value: '(GMT+6:00) Almaty, Dhaka, Colombo',
    response: '+06:00',
  },
  {
    label: '(GMT+6:30) Yangon, Mandalay',
    value: '(GMT+6:30) Yangon, Mandalay',
    response: '+06:50',
  },
  {
    label: '(GMT+7:00) Bangkok, Hanoi, Jakarta',
    value: '(GMT+7:00) Bangkok, Hanoi, Jakarta',
    response: '+07:00',
  },
  {
    label: '(GMT+8:00) Beijing, Perth, Singapore, Hong Kong',
    value: '(GMT+8:00) Beijing, Perth, Singapore, Hong Kong',
    response: '+08:00',
  },
  {
    label: '(GMT+8:45) Eucla',
    value: '(GMT+8:45) Eucla',
    response: '+08:75',
  },
  {
    label: '(GMT+9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
    value: '(GMT+9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
    response: '+09:00',
  },
  {
    label: '(GMT+9:30) Adelaide, Darwin',
    value: '(GMT+9:30) Adelaide, Darwin',
    response: '+09:50',
  },
  {
    label: '(GMT+10:00) Eastern Australia, Guam, Vladivostok',
    value: '(GMT+10:00) Eastern Australia, Guam, Vladivostok',
    response: '+10:00',
  },
  {
    label: '(GMT+10:30) Lord Howe Island',
    value: '(GMT+10:30) Lord Howe Island',
    response: '+10:50',
  },
  {
    label: '(GMT+11:00) Magadan, Solomon Islands, New Caledonia',
    value: '(GMT+11:00) Magadan, Solomon Islands, New Caledonia',
    response: '+11:00',
  },
  {
    label: '(GMT+11:30) Norfolk Island',
    value: '(GMT+11:30) Norfolk Island',
    response: '+11:50',
  },
  {
    label: '(GMT+12:00) Auckland, Wellington, Fiji, Kamchatka',
    value: '(GMT+12:00) Auckland, Wellington, Fiji, Kamchatka',
    response: '+12:00',
  },
  {
    label: '(GMT+12:45) Chatham Islands',
    value: '(GMT+12:45) Chatham Islands',
    response: '+12:75',
  },
  {
    label: '(GMT+13:00) Apia, Nukualofa',
    value: '(GMT+13:00) Apia, Nukualofa',
    response: '+13:00',
  },
  {
    label: '(GMT+14:00) Line Islands, Tokelau',
    value: '(GMT+14:00) Line Islands, Tokelau',
    response: '+14:00',
  },
];
