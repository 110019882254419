import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 14px;
  width: 100%;
  margin: -6px 0 0;
  text-align: ${(props) => props.align ?? 'left'};
`;

const InlineSpan = styled.span`
  display: flex;
  width: 100%;
`;

const BarItem = styled.div`
  display: inline-flex;
  width: ${(props) => (props.type !== 'noCount' ? `${props.maxWidth / props.total}px` : '4px')};
  height: ${(props) => props.height ?? '8px'};
  border: ${(props) => props.color ?? 'var(--playbook-pewter)'};
  background: ${(props) => props.color ?? 'var(--playbook-pewter)'};
  margin-right: ${(props) => (props.divider ? 'solid 4px var(--playbook-pewter)' : 0)};
  opacity:${(props) => props.opacity ?? '1'};
`;

const CountDiv = styled.div`
  margin: 1px 16px;
  display: inline;
`;

const FirstBarWrapper = styled.div`
  margin: 0 0 -13px;
`;

const SingleBarChart = ({
  type = 'two-rows',
  firstBar,
  secondBar,
  firstBarColor,
  secondBarColor,
  total,
  hoverOn,
  hoverOut,
  align,
  height,
  divider,
}) => {
  const elRef = useRef();
  const [maxWidth, setMaxWidth] = useState(0);
  const offset = type === 'two-rows' ? 64 : 8;

  useEffect(() => {
    if (elRef) setMaxWidth(elRef.current.clientWidth - offset);
  }, [elRef, offset]);

  const renderBar = (bar, idName, color, opacity) => {
    return (
      Boolean(bar) &&
      Array.from(Array(bar), (item, i) => (
        <BarItem
          key={`${idName}-${i}`}
          color={color}
          total={idName === "no-bar" ? 1 : total}
          maxWidth={maxWidth - 1}
          divider={divider}
          height={height}
          opacity={opacity}
        />
      ))
    );
  };

  const TwoRowBar = () => (
    <InlineSpan>
      {type === 'two-rows' && <CountDiv className="text-right ml-0">{firstBar}</CountDiv>}
      <Wrapper 
        ref={elRef}
        onMouseLeave={() => {
          hoverOut && hoverOut();
        }}
        onMouseEnter={() => {
          hoverOn && hoverOn(elRef, firstBar, secondBar);
        }}
        align={align}
      >
        {type === 'two-rows' ? (
          <>
            <div data-testid="two-row-chart" className="d-inline">
              <FirstBarWrapper>
                {renderBar(firstBar, 'first-bar', firstBarColor)}
                {firstBar === 0 && (
                  <BarItem key={`first-bar-key-0`} type="noCount" total={total} height={height} />
                )}
              </FirstBarWrapper>
              {renderBar(secondBar, 'second-bar', secondBarColor)}
              {secondBar === 0 && (
                <BarItem key={`second-bar-key-0`} type="noCount" total={total} height={height} />
              )}
            </div>
            <CountDiv>{secondBar}</CountDiv>
          </>
        ) : (
          // Populate parameter "type" with "single-row" for consistency.
          <div data-testid="single-row-chart">
            {!firstBar && !secondBar ? (
              <>{renderBar(1, 'no-bar', 'var(--playbook-pewter)')}</>
            ) : (
              <>
                {renderBar(firstBar, 'first-bar', firstBarColor)}
                {renderBar(secondBar, 'second-bar', secondBarColor, firstBarColor === secondBarColor ? 0.3 : 1)}
              </>
            )}
          </div>
        )}
      </Wrapper>
    </InlineSpan>
  );

  return (
    <>
      <TwoRowBar />
    </>
  );
};

export default SingleBarChart;
