import { useState } from 'react';
import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
} from '../LargePadding';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextButton } from 'views/Customer/Styles';
import MoreInfoFields from '../../CustomFields/Contacts/MoreInfoFields';
import { CUSTOMERS_FIELDS, CONTACT_LABEL, CUSTOM_FIELDS_MODAL_VARIATIONS } from 'constants';
import { FieldArray, useFormikContext } from 'formik';
import { Customers } from 'models';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import ModalConfirm from '../Confirm';
import toast from 'components/toast';
import { isDropDownType, noop } from 'helpers/CommonHelper';
import { Form } from 'react-bootstrap';
import { ScrollableContainer } from './Styles';
import Tags from '../Touchpoint/AddMembers/Tags';
import Checkbox from 'components/Checkbox/Checkbox';
import { EmptyTagsContainer } from '../Touchpoint/AddMembers/Styles';
import InfoTextBox from '../Shared/InfoTextBox';

const MoreInfoModal = ({
  contact,
  show,
  handleHide,
  onUpdateContact,
  setSaving,
  setHasError,
  variation = CUSTOM_FIELDS_MODAL_VARIATIONS.CONTACTS,
  addMembers,
  canAddToAllTouchpoints = false,
  canvasTags = [],
}) => {
  const { values, submitForm, isSubmitting } = useFormikContext();

  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [addToAll, setAddToAll] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const isInviteNew = variation === CUSTOM_FIELDS_MODAL_VARIATIONS.INVITE_NEW;

  const modalLabel = () => {
    if (isInviteNew) {
      return CONTACT_LABEL.INVITE_NEW;
    } else {
      return !contact || Object.keys(contact).length === 0 ? CONTACT_LABEL.ADD : CONTACT_LABEL.MOREINFO;
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    await Customers.deleteContact(contact.contactId);
    setLoading(false);
    setShowDeleteModal(false);
    handleHide();
    onUpdateContact();
    toast.saved('Contact deleted successfully.');
  };

  const handleSubmit = async () => {
    const res = await submitForm();
    if (res) {
      if (isInviteNew) {
        await addMembers({
          userIds: [res.userId],
          addToAllTouchpointsUserIds: Array.from(addToAll ? [res.userId] : []),
          addToFollowingTouchpoints: addToAll,
          sendInvite: true,
          userTouchpointTags: [{ userId: res.userId, tagIds: selectedTags }],
        });
        toast.saved('Member added and invited successfully.');
      } else {
        toast.saved('Contact created successfully.');
      }
      handleHide();
    }
  };

  const inputValueChanged = (event) => {
    const target = event.target;
    setAddToAll(target.checked);
  };

  const modalStyle = {
    inner: {
      maxWidth: 'unset',
      padding: '2rem 0 0 0 !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'var(--light-gray-bg)',
      borderRadius: '0 0 0.813rem 0.813rem',
      padding: '1.875rem 0 2rem 0 !important',
    },
  };

  const handleAddTags = (selectedTagId) => {
    setSelectedTags((prev) => {
      const foundTag = prev.some((item) => item === selectedTagId);

      if (foundTag) return prev.filter((item) => item !== selectedTagId);

      return [...prev, selectedTagId];
    });
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <ModalLargePadding
          show={show}
          onHide={contact && !Object.keys(contact).length ? noop : handleHide}
          innerProps={modalStyle.inner}
          buttonsContainerProps={modalStyle.buttonContainer}
          content={
            <div>
              <div className="text-center">
                <h1 className="mb-3 d-inline-flex">{modalLabel()}</h1>
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={handleHide}
                  className="close-button clickable float-right fa-xl"
                />
              </div>
              <ScrollableContainer>
                <FieldArray
                  name={CUSTOMERS_FIELDS.CONTACT_FIELDS}
                  render={() => {
                    return (
                      <>
                        {values.contactFields.map((item, index) => {
                          const isDropDown = isDropDownType(item?.fieldType);
                          const name = `${CUSTOMERS_FIELDS.CONTACT_FIELDS}[${index}].formikValue`;
                          return (
                            <MoreInfoFields
                              key={index}
                              isRequired={item?.isRequired}
                              contactId={contact?.contactId}
                              field={item}
                              name={name}
                              isDropUp={isDropDown && values.contactFields.length - 2 <= index}
                              setSaving={setSaving}
                              setHasError={setHasError}
                            />
                          );
                        })}
                        {canAddToAllTouchpoints && (
                          <>
                            <Form.Group className="px-4">
                              <Form.Label>
                                Select tags to assign this member to those steps or tasks.
                              </Form.Label>

                              {canvasTags.length > 0 ? (
                                <Tags
                                  isAddToProjectChecked
                                  canvasTags={canvasTags}
                                  handleAddTags={handleAddTags}
                                />
                              ) : (
                                <EmptyTagsContainer>
                                  <span>No tags. Use tags for easier assignment</span>
                                  <InfoTextBox
                                    notActive={false}
                                    width="30.625rem"
                                    top="-6.175rem"
                                    left="-15rem"
                                  >
                                    Add tags to steps in your template, for example “Customer”. When selecting
                                    the tag here, the user will be added to all steps with that tag.
                                  </InfoTextBox>
                                </EmptyTagsContainer>
                              )}
                            </Form.Group>

                            <Form.Group className="mb-0 px-4" controlId="addToAll">
                              <Checkbox
                                label="Assign to all steps"
                                checked={addToAll}
                                onChange={inputValueChanged}
                              />
                            </Form.Group>
                          </>
                        )}
                      </>
                    );
                  }}
                />
              </ScrollableContainer>
            </div>
          }
          buttons={
            !!contact && !!Object.keys(contact).length ? (
              <TextButton onClick={() => setShowDeleteModal(true)} color="var(--danger)">
                Delete Contact
              </TextButton>
            ) : (
              <>
                <ModalLargePaddingCancelButton onClick={handleHide} autoWidth />
                <ModalLargePaddingConfirmButton
                  onClick={handleSubmit}
                  loading={isSubmitting}
                  text={isInviteNew ? 'Add and Send Invite' : 'Save'}
                  autoWidth
                />
              </>
            )
          }
        />
      )}
      <ModalConfirm
        title={`Delete contact`}
        message={`Are you sure you want to delete ${contact?.firstName}? \n\nThis action cannot be undone.`}
        show={showDeleteModal}
        onConfirm={handleDelete}
        onHide={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default MoreInfoModal;
