import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import * as Api from '../../api/Api';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import EditCard from './EditCard';
import CardColumn from './CardColumn';
import { useEffect } from 'react';

export default function BuilderCardColumns({
  columns,
  onAddItem,
  onDeleteItem,
  onDuplicateItem,
  onAddColumn,
  onEditColumn,
  onUpdated,
  journeyOrder,
  onJourneyReorder,
  allJourneys,
  onReorderTouchpoints,
  isPreviewMode,
}) {
  const [addingColumn, setAddingNewColumn] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [cardColumns, setCardColumns] = useState([]);

  useEffect(() => {
    const filteredColumns = columns.filter(
      ({ items }) => !!items.some(({ customerFacing }) => !!customerFacing)
    );

    const filteredCustomerFacingItems = filteredColumns.map((columns) => {
      return {
        ...columns,
        items: columns.items.filter(({ customerFacing }) => !!customerFacing),
      };
    });

    !isPreviewMode ? setCardColumns(columns) : setCardColumns(filteredCustomerFacingItems);
  }, [columns, isPreviewMode]);

  function onDragStart() {
    setDragging(true);
  }

  function onDragEnd(result) {
    const { destination, source, draggableId, type } = result;
    setTimeout(() => setDragging(false), 100);

    if (!destination) return;
    const sourceDroppableId = source.droppableId.replace('journey-', '');
    const destinationDroppableId = destination.droppableId.replace('journey-', '');
    if (destinationDroppableId === sourceDroppableId && destination.index === source.index) return;

    if (type === 'journey') {
      const newOrder = Array.from(journeyOrder);
      newOrder.splice(source.index, 1);
      newOrder.splice(destination.index, 0, draggableId.replace('journey-', ''));
      onJourneyReorder(newOrder);
      updateJourneyOrder(newOrder);
    } else if (type === 'touchpoint') {
      const start = allJourneys[sourceDroppableId];
      const finish = allJourneys[destinationDroppableId];
      // MOVE TO SAME JOURNEY
      if (start === finish) {
        const newTouchpointIds = Array.from(start.touchpointIds);
        newTouchpointIds.splice(source.index, 1);
        newTouchpointIds.splice(destination.index, 0, draggableId);

        const newStart = {
          ...start,
          touchpointIds: newTouchpointIds,
        };

        onReorderTouchpoints({
          [newStart.id]: newStart,
        });

        updateTouchpointOrder(newTouchpointIds, start.id, finish.id);
      }
      // MOVE TO DIFFERENT JOURNEY
      else {
        const startTouchpointIds = Array.from(start.touchpointIds);
        startTouchpointIds.splice(source.index, 1);

        const finishTouchpointIds = Array.from(finish.touchpointIds);
        finishTouchpointIds.splice(destination.index, 0, draggableId);

        onReorderTouchpoints({
          [start.id]: {
            ...start,
            touchpointIds: startTouchpointIds,
          },
          [finish.id]: {
            ...finish,
            touchpointIds: finishTouchpointIds,
          },
        });

        updateTouchpointOrder(finishTouchpointIds, start.id, finish.id);
      }
    }
  }

  async function updateJourneyOrder(journeyIds) {
    const newOrder = journeyIds.map((id, index) => ({ id, order: index }));
    Api.post('Journey/UpdateOrder', newOrder);
  }

  async function updateTouchpointOrder(touchpoints, startJourneyId, endJourneyId) {
    const newOrder = touchpoints.map((id, index) => ({ id, order: index }));
    Api.post(`Touchpoint/UpdateOrder`, newOrder, {
      params: { startJourneyId: startJourneyId, endJourneyId: endJourneyId },
    });
  }

  async function onColummnAdd(name) {
    await onAddColumn(name);
    setAddingNewColumn(false);
  }

  return (
    <div className="columns-wrapper">
      <div className={'columns pt-1' + (dragging ? ' dragging' : '')}>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Droppable droppableId="all-journeys" direction="horizontal" type="journey">
            {(provided, snapshot) => (
              <div className="d-flex" {...provided.droppableProps} ref={provided.innerRef}>
                {cardColumns.map((item, index) => {
                  return (
                    <CardColumn
                      key={item.id}
                      columnItem={item}
                      id={item.id}
                      icons={item.icons}
                      cards={item.items || []}
                      onAddItem={onAddItem}
                      onEditColumn={onEditColumn}
                      onDeleteItem={onDeleteItem}
                      onDuplicateItem={onDuplicateItem}
                      onUpdated={onUpdated}
                      isPreviewMode={isPreviewMode}
                      index={index}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {!isPreviewMode && (
          <div className="column">
            {addingColumn ? (
              <EditCard key={'edit'} type={'stage'} onItemAdd={onColummnAdd} />
            ) : (
              <div className="card-wrapper">
                <Button
                  onClick={() => setAddingNewColumn(true)}
                  className="card-width text-align-start"
                  variant="light"
                >
                  <FontAwesomeIcon className="mr-2" icon={faPlus} />
                  <span>Add phase</span>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
