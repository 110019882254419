import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import * as Api from '../../api/Api';
import Autocomplete from '../Autocomplete';
import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
  ModalLargePaddingHeader,
} from './LargePadding';

export default function ModalEditReminder(props) {
  const [customer, setCustomer] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [text, setText] = useState('');
  const [date, setDate] = useState(new Date());
  const [error, setError] = useState('');
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      const response = await Api.get('Customer/GetAllCustomers', {
        userCustomers: props.justMyCustomers,
      });

      setIsLoading(false);

      setCustomers(response.items.map((item) => ({ value: item.id, label: item.name })));
      if (props.reminder) {
        setText(props.reminder.title);
        setDate(new Date(props.reminder.displayDate));
      } else {
        setText('');
        setDate(new Date());
        setCustomer(null);
      }
    }
    fetchData();
  }, [props.justMyCustomers, props.reminder]);

  useEffect(() => {
    if (props.reminder && customers != null) {
      customers.forEach((customer) => {
        if (customer.value === props.reminder.customerId) {
          setCustomer(customer);
        }
      });
    }
  }, [customers, props.reminder]);

  async function handleOnClickEdit() {
    if (!customer || !text || !date) {
      setError('Kindly complete all of the fields to continue.');
      return;
    }
    await props.onEdit(customer.value, text, date);
    setText('');
  }

  function handleOnChangeText(e) {
    setText(e.target.value);
  }

  function handleOnChangeDate(dates) {
    if (dates && dates.length) {
      setDate(dates[0]);
    }
  }

  return (
    <ModalLargePadding
      show={props.show}
      onHide={props.onHide}
      content={
        <>
          <ModalLargePaddingHeader
            icon={faStar}
            iconColor="var(--purple-bright)"
            text={props.reminder ? 'Edit reminder' : 'New reminder'}
          />
          {error ? <Alert variant="danger">{error}</Alert> : ''}

          <Autocomplete
            testId="select-customer"
            className="mb-4"
            options={customers}
            placeholder="Choose an account..."
            value={customer}
            isDisabled={loading}
            isLoading={loading}
            onChange={setCustomer}
          />

          <Form.Control
            as="textarea"
            rows={3}
            value={text}
            placeholder="Enter reminder here..."
            onChange={handleOnChangeText}
          />
          <div className="d-flex align-items-center mt-3">
            <div className="mr-3 text-muted font-weight-bold">Due</div>
            <div className="date-picker">
              <Flatpickr
                id="reminder-date"
                className="form-control reminder-date-input"
                options={{ altInput: true, static: true, mode: 'single', disableMobile: 'false' }}
                value={date}
                onChange={handleOnChangeDate}
              ></Flatpickr>
              <FontAwesomeIcon icon={faCalendar} />
            </div>
          </div>
        </>
      }
      buttons={
        <>
          <ModalLargePaddingCancelButton onClick={props.onHide} />
          <ModalLargePaddingConfirmButton
            disabled={!customer || !text}
            onClick={handleOnClickEdit}
            text={props.reminder ? 'Save' : 'Add'}
          />
        </>
      }
    />
  );
}
