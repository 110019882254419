import React, { useContext, useMemo } from 'react';
import Select, { components } from 'react-select';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';
import SearchableSelect from 'components/SearchableSelect';
import { capitalizeFirstLetter } from 'helpers/CommonHelper';
import {
  NEW_STAGEBASE_FIELD,
  PLACEHOLDERS,
  FIELDS,
  CUSTOM_FIELD_MODES,
  PERMISSIONS,
  CUSTOM_FIELDS_MODAL_VARIATIONS,
  DEFAULT_FIELDS,
} from 'constants';
import { ErrorMessageContainer } from 'views/CustomFields/Account/Styles';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { externalFieldList } from './fixtures';
import { DropdownContainer, PermissionLabel, PermissionOption } from './Styles';
import { useEffect } from 'react';

const External = ({ onFieldLoad, LoadedField, isRequiredError, onFieldFocus, isVariation }) => {
  const { values, setFieldValue } = useFormikContext();
  const { options } = useContext(CustomFieldsContext);

  const { showPermission, showExternalField, dataSource, fieldMode, isProtected, fieldLabel } = values;

  const isNewStagebaseField = dataSource?.value === NEW_STAGEBASE_FIELD;
  const isEdit = fieldMode === CUSTOM_FIELD_MODES.EDIT;
  const isDefaultField = isEdit && isProtected;

  const onGroupedSelectChange = (form) => (args) => {
    const filteredSelect = externalFieldList.filter(({ options }) =>
      options.find(({ label }) => label?.toLowerCase() === args.label?.toLowerCase())
    );

    const hasFilteredSelect = filteredSelect.length > 1;
    const selectGroup = args.value?.split('-')[0];
    const uniqueLabel = `(${capitalizeFirstLetter(selectGroup)}) ${args.label}`;

    const selectValue = {
      ...args,
      label: hasFilteredSelect ? uniqueLabel : args.label,
    };

    form.setFieldValue(FIELDS.EXTERNAL_FIELD, selectValue);

    onFieldLoad(FIELDS.SHOW_PERMISSION);
  };

  const onSelectChange = (form) => (args) => {
    form.setFieldValue(FIELDS.PERMISSION, args);

    isVariation(CUSTOM_FIELDS_MODAL_VARIATIONS.CUSTOMER) ||
    isVariation(CUSTOM_FIELDS_MODAL_VARIATIONS.CONTACTS)
      ? onFieldLoad(FIELDS.SHOW_SECTION)
      : onFieldLoad(FIELDS.SHOW_CHECKBOXES);
  };

  const Option = (props) => (
    <components.Option {...props}>
      <PermissionOption>
        <span>{props.data.label}.</span>{' '}
        {props.data.label === PERMISSIONS.READ_AND_WRITE
          ? 'Your edits in Stagebase will sync back to the extended data source.'
          : 'Cannot be edited in Stagebase.'}
      </PermissionOption>
    </components.Option>
  );

  const externalFieldsOptions = useMemo(() => {
    const dataSourceLabel = dataSource?.label.toLowerCase();
    const list = options.externalFields.find((source) => source[dataSourceLabel]) ?? [];

    return list[dataSourceLabel] ?? [];
  }, [dataSource?.label, options.externalFields]);

  const formattedPermissionList = useMemo(() => {
    const isReadOnlyLabels = {
      [DEFAULT_FIELDS.EXTERNAL_ID]: DEFAULT_FIELDS.EXTERNAL_ID,
      [DEFAULT_FIELDS.EMAIL]: DEFAULT_FIELDS.EMAIL,
      [DEFAULT_FIELDS.LAST_ACTIVITY]: DEFAULT_FIELDS.LAST_ACTIVITY,
    };

    return options.permissionList.map((permission) => {
      if (permission.label === 'Read and Write' && isReadOnlyLabels[fieldLabel]) {
        return {
          ...permission,
          isDisabled: true,
        };
      }

      return { ...permission };
    });
  }, [fieldLabel, options.permissionList]);

  useEffect(() => {
    if (formattedPermissionList.length > 0) {
      if (formattedPermissionList.find(({ isDisabled }) => !!isDisabled)) {
        setFieldValue(FIELDS.IS_REQUIRED_SHOWN, false);
      } else {
        setFieldValue(FIELDS.IS_REQUIRED_SHOWN, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedPermissionList]);

  return (
    <>
      {!isNewStagebaseField && (
        <LoadedField isVisible={showExternalField}>
          <Form.Label>Field</Form.Label>

          <Field name={FIELDS.EXTERNAL_FIELD}>
            {({ field, form }) => (
              <>
                <SearchableSelect
                  selectValue={field.value}
                  options={externalFieldsOptions}
                  disabled={isEdit}
                  showClearIndicator={false}
                  placeholder={PLACEHOLDERS.EXTERNAL_FIELD}
                  onChange={onGroupedSelectChange(form)}
                  onFocus={onFieldFocus(form, FIELDS.EXTERNAL_FIELD)}
                />

                {!isRequiredError(FIELDS.EXTERNAL_FIELD) && (
                  <ErrorMessageContainer>
                    <ErrorMessage name={FIELDS.EXTERNAL_FIELD} />
                  </ErrorMessageContainer>
                )}
              </>
            )}
          </Field>
        </LoadedField>
      )}

      {(!isNewStagebaseField || isDefaultField) && (
        <LoadedField isVisible={showPermission || isDefaultField}>
          <DropdownContainer>
            <PermissionLabel>Editing Permissions</PermissionLabel>

            <Field name={FIELDS.PERMISSION}>
              {({ field, form }) => (
                <Select
                  value={field.value}
                  classNamePrefix="select"
                  placeholder={PLACEHOLDERS.PERMISSION}
                  isSearchable={false}
                  onChange={onSelectChange(form)}
                  components={{ Option }}
                  onFocus={onFieldFocus(form, FIELDS.PERMISSION)}
                  options={formattedPermissionList}
                  isOptionDisabled={(option) => option.isDisabled}
                />
              )}
            </Field>
          </DropdownContainer>
        </LoadedField>
      )}
    </>
  );
};

export default External;
