import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { COLORS_HEX_TO_RGB } from '../helpers/CommonHelper';

const TagContainer = styled.div`
  border-color: ${({ r, g, b, isActive }) => `rgba(${r},${g},${b},${isActive ? 0.15 : 1})`}!important;
`;

function Tag({ tag, onDelete, onClick, onEdit, large, activeGlobalTagId, activeId }) {
  const isActive = () => {
    if (activeGlobalTagId) {
      return tag.tagId === activeGlobalTagId;
    } else if (activeId) {
      return tag.id === activeId;
    } else return false;
  };

  const [r, g, b] = COLORS_HEX_TO_RGB[tag.color] || [0, 0, 0];

  return (
    <TagContainer
      className={
        'tag' + (large ? ' large' : '') + (onClick != null ? ' cursor-pointer d-flex align-items-center' : '')
      }
      r={r}
      g={g}
      b={b}
      isActive={isActive()}
      onClick={(e) => {
        if (onClick != null) {
          onClick(tag);
        }
      }}
    >
      {tag.text}
      {onDelete && (
        <FontAwesomeIcon
          icon={faTimes}
          className="tag-delete-icon clickable"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete(tag);
          }}
        />
      )}
      {onEdit && (
        <FontAwesomeIcon
          icon={faPencil}
          className="tag-delete-icon clickable"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onEdit(tag);
          }}
        />
      )}
    </TagContainer>
  );
}

export default function Tags({
  tags,
  large,
  onClick,
  onDelete,
  onEdit,
  activeGlobalTagId,
  activeId,
  isActive,
}) {
  return (
    <div className="tags">
      {tags
        .filter((x) => !x.isArchived && (x.tag == null || !x.tag.isArchived))
        .map((tag, index) => (
          <Tag
            tag={tag}
            onDelete={onDelete}
            onClick={onClick}
            onEdit={onEdit}
            large={large}
            activeGlobalTagId={activeGlobalTagId}
            activeId={activeId}
            key={index}
          />
        ))}
    </div>
  );
}
