import { useState, useContext, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup } from '@fortawesome/pro-light-svg-icons';
import FullContext from 'stores/Full/fullContext';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--icon-light-gray);
`;

export default function CustomerFacing({ touchpoint, onUpdated, isProject = false, isCancelled = false }) {
  const [checked, setChecked] = useState(touchpoint.customerFacing);
  const { isEditMode } = useContext(FullContext);

  useEffect(() => {
    if (isCancelled) {
      setChecked(true);
    }
  }, [isCancelled]);

  const handleChange = (checked) => {
    setChecked(checked);
    onUpdated({ customerFacing: checked });
  };

  return (
    <>
      <Wrapper className="mb-3 mb-md-0 ml-md-3 customer-facing">
        <StyledIcon icon={faUserGroup} className="fa-xs fa-light mr-1" title="Customer facing" />
        <h2 className="mr-2">Customer-facing</h2>
        {(!isProject || isEditMode) && (
          <Form.Check
            type="switch"
            id="customerFacing"
            name="customerFacing"
            checked={checked}
            onChange={(e) => handleChange(e.target.checked)}
          />
        )}
      </Wrapper>
    </>
  );
}
