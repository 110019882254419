import React, { Fragment, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FilterList, HeaderMessageBox } from './SharedStyles';
import ReportingContext from './context/reportingContext';

const HeaderFilter = ({ showFilters, setShowFilters, useTimeFilter = true, useAccountFilter = true }) => {
  const elFilter = useRef();
  const { activeAccountFilters, activeTimeRangeFilters } = useContext(ReportingContext);

  return (
    <>
      <FilterList
        ref={elFilter}
        onMouseOver={() => {
          setShowFilters(true);
        }}
        onMouseOut={() => {
          setShowFilters(false);
        }}
      >
        <FontAwesomeIcon icon={faBarsFilter} />
        {(activeAccountFilters?.length > 0 || activeTimeRangeFilters?.length > 0) && (
          <span className="filter">
            (
            {String(
              (useAccountFilter ? activeAccountFilters?.length : 0) +
                (useTimeFilter ? activeTimeRangeFilters?.length : 0)
            )}
            )
          </span>
        )}
        {useTimeFilter && activeTimeRangeFilters?.length > 0 && (
          <span className="filter">
            {activeTimeRangeFilters[0]?.label}
            {activeTimeRangeFilters?.length > 1 ? ',' : ''}
          </span>
        )}
        {useTimeFilter &&
        useAccountFilter &&
        activeTimeRangeFilters?.length > 0 &&
        activeAccountFilters?.length > 0
          ? ','
          : ''}
        {useAccountFilter &&
          activeAccountFilters.map((filter, index) => (
            <span key={`${filter}-${index}`} className="filter">
              {filter}
              {index !== activeAccountFilters.length - 1 && ','}
            </span>
          ))}
      </FilterList>
      {showFilters && (
        <HeaderMessageBox
          top={elFilter.current.getBoundingClientRect().top}
          left={elFilter.current.getBoundingClientRect().left - 20}
        >
          {useTimeFilter && activeTimeRangeFilters?.length > 0 && activeTimeRangeFilters[0]?.label}
          {useTimeFilter &&
          useAccountFilter &&
          activeTimeRangeFilters?.length > 0 &&
          activeAccountFilters?.length > 0
            ? ', '
            : ''}
          {useAccountFilter &&
            activeAccountFilters.map((filter, index) => (
              <Fragment key={`${filter}-${index}`}>
                {filter}
                {index !== activeAccountFilters.length - 1 && ', '}
              </Fragment>
            ))}
        </HeaderMessageBox>
      )}
    </>
  );
};

export default HeaderFilter;
