export default function PagingMoreLess({ items, totalItems, page, loadMore, showLess }) {
  return (
    <div className="my-2">
      {totalItems !== items.length ? (
        <span
          className="cursor-pointer text-primary"
          onClick={loadMore}
        >
          Show more
        </span>
      ) : null}

      {page > 1 ? (
        <span
          className="cursor-pointer text-muted"
          onClick={showLess}
        >
          {totalItems !== items.length && <span>&nbsp;|</span>} Show less
        </span>
      ) : null}
    </div>
  );
}
