import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav } from 'react-bootstrap';
import styled, { css } from 'styled-components';

export const ActiveIcon = styled(FontAwesomeIcon)`
  color: var(--schedule-sapphire);
`;

export const SidebarContainer = styled.div`
  .progress-content {
    color: var(--champion-charcoal);

    p {
      margin: 0;
    }
  }

  ${({ $isConfirmed, isExternal }) =>
    !$isConfirmed &&
    !isExternal &&
    css`
      padding-top: 3rem;
    `}
`;

export const SidebarItemsContainer = styled(Nav)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;

  .dropdown-menu {
    border: 0;
    box-shadow: var(--box-shadow-menu);
  }

  .dropdown-menu.show {
    transform: translate3d(4.55rem, -0.25rem, 0) !important;
  }

  .connect-email,
  .fa-envelope {
    color: var(--danger);
  }

  .connect-email:hover {
    color: var(--action-anchor);
  }
`;

export const OptionsContainer = styled.div`
  padding-inline: 3rem;
  display: flex;
  justify-content: center;
  gap: 1.8rem;

  .guide {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    border: 1px solid var(--border-gray);
    padding: 1rem 0.875rem 2.8rem 0.875rem;
    min-width: 10rem;
    border-radius: 0.625rem;
    cursor: pointer;
    transition: all 0.4s;

    h4 {
      font-size: 1rem;
    }

    svg {
      font-size: 4rem;
    }
  }

  .guide:hover {
    background-color: var(--light-gray-bg);
    border-color: var(--playbook-pewter);
  }
`;

export const ProgressContainer = styled.div`
  ${({ $isDanger }) =>
    $isDanger &&
    css`
      .progress-bar {
        background: var(--danger);
      }
    `}
`;
