import { useState, useMemo, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import Members from 'components/Members';
import CircleCheck from '../../CircleCheck';
import List from '../../List';
import { Form } from 'react-bootstrap';
import * as Api from '../../../api/Api';
import ChecklistItem from '../Shared/ChecklistItem';
import ModalConfirm from '../Confirm/index';
import FullContext from 'stores/Full/fullContext';
import toast from 'components/toast';
import { FRONT_LINE } from 'constants';

export default function Checklist({
  customerTouchpoint,
  onUpdate,
  canvasMembersById,
  customerCanvasId,
  addMembersTaskItem,
  removeMemberTaskItem,
  externalsHaveTaskItemPermissions,
  customerId,
  onTouchpointUpdate,
  activeTouchpointTasks,
  onTaskEdit,
  onTaskCreation,
  onTaskDeletion,
}) {
  const { isViewAsCustomer, isExternal, roleAtleast } = useContext(FullContext);
  const [taskItems, setTaskItems] = useState(activeTouchpointTasks ?? []);
  const [newItemText, setNewItemText] = useState('');
  const [savingNewTask, setSavingNewTask] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);

  async function updateTaskComplete(task) {
    if (isViewAsCustomer) {
      toast.error('Please exit customer view to do this.');
      return;
    }

    task.completed = !task.completed;
    setTaskItems([...taskItems]);
    const response = await Api.post('Customer/UpdateTaskComplete', null, {
      params: { taskId: task.id, customerTouchpointId: customerTouchpoint.id, complete: task.completed },
    });
    onUpdate(response);
  }

  const canAssignMembers = useMemo(() => {
    return externalsHaveTaskItemPermissions || (!isViewAsCustomer && roleAtleast(FRONT_LINE));
  }, [externalsHaveTaskItemPermissions, isViewAsCustomer, roleAtleast]);

  const handleAddMembers = async (itemId, data) => {
    const res = await addMembersTaskItem(itemId, data);
    updateTaskItemMembers(itemId, res.memberIds);
  };

  const handleRemoveMember = async (itemId, memberId) => {
    await removeMemberTaskItem(itemId, memberId);
    setTaskItems(
      taskItems.map((taskItem) => {
        if (taskItem.id === itemId) {
          return {
            ...taskItem,
            memberIds: taskItem.memberIds.filter((id) => id !== memberId),
          };
        }
        return taskItem;
      })
    );
  };

  const updateTaskItemMembers = (taskId, memberIds) => {
    setTaskItems(
      taskItems.map((taskItem) => {
        if (taskItem.id === taskId) {
          return {
            ...taskItem,
            memberIds,
          };
        }
        return taskItem;
      })
    );
  };

  const updateCustomerTask = async (task, text) => {
    if (text.trim() && text !== '') {
      const response = await Api.put('Touchpoint/UpdateCustomerTask', {
        id: task.id,
        description: text,
        customerTouchpointId: customerTouchpoint.id,
        order: task.order,
      });
      onTaskEdit(response);
    }
  };

  const createNewCustomerTask = async () => {
    if (newItemText.trim() && newItemText !== '') {
      setSavingNewTask(true);
      const response = await Api.post('Touchpoint/CreateCustomerTask', {
        description: newItemText,
        customerTouchpointId: customerTouchpoint.id,
        order: taskItems?.length + 1,
      });
      setNewItemText('');
      setSavingNewTask(false);
      onTaskCreation(response);
    }
  };

  const deleteCustomerTask = async () => {
    await Api.deleteRequest(`Touchpoint/DeleteCustomerTask?id=${taskToDelete.id}`);
    setTaskToDelete(null);
    onTaskDeletion(taskToDelete);
  };

  return (
    <>
      <div className="d-flex justify-content-between settings-section-title mt-4">
        <h2>Checklist</h2>
        <h2>Assigned to</h2>
      </div>
      {taskItems?.length === 0 ? (
        <div className="small-info">No checklist items</div>
      ) : (
        <List>
          {taskItems
            .sort((a, b) => a.order - b.order)
            .map((item, index) => (
              <div
                key={index}
                className={'d-flex justify-content-between' + (item.completed ? ' color-completed' : '')}
              >
                <div className="d-flex align-items-center flex-grow-1">
                  <CircleCheck
                    complete={item.completed}
                    onClick={() => updateTaskComplete(item)}
                    className="mr-2-5"
                  />
                  <ChecklistItem item={item} onSave={updateCustomerTask} />
                  <Members
                    className="ml-2"
                    customerCanvasId={customerCanvasId}
                    customerId={customerId}
                    members={
                      item?.memberIds?.length !== 0 && item.memberIds.map((id) => canvasMembersById[id])
                    }
                    onAddMembers={(data) => handleAddMembers(item.id, data)}
                    onRemoveMember={(member) => handleRemoveMember(item.id, member.id)}
                    canView={canAssignMembers}
                    canAdd={canAssignMembers}
                    scope="task"
                    rightAlign
                  />
                  {!isExternal && !isViewAsCustomer && (
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="ml-3 cursor-pointer"
                      onClick={() => {
                        if (isViewAsCustomer) {
                          toast.error('Please exit customer view to do this.');
                          return;
                        }

                        setTaskToDelete(item);
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
        </List>
      )}
      {!isExternal && !isViewAsCustomer && (
        <Form.Control
          className="mt-3 mb-4 no-border"
          placeholder="Add Item..."
          disabled={savingNewTask || isExternal || isViewAsCustomer}
          value={newItemText}
          onChange={(e) => setNewItemText(e.target.value)}
          onBlur={createNewCustomerTask}
          onKeyPress={(e) => e.key === 'Enter' && createNewCustomerTask()}
        />
      )}
      <ModalConfirm
        title="Delete task"
        message={
          <>
            Are you sure you want to delete this task?
            <br />
            This action cannot be undone.
          </>
        }
        show={taskToDelete !== null}
        onConfirm={() => {
          deleteCustomerTask();
        }}
        onHide={() => {
          setTaskToDelete(null);
        }}
      />
    </>
  );
}
