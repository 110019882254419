import React, { useEffect, useRef, useState, useMemo, useContext, useCallback, Fragment } from 'react';
import { Form, Tab, Tabs, Row, Col, Nav } from 'react-bootstrap';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { get, post } from 'api/Api';
import { debounce, noop } from 'helpers/CommonHelper';
import { shortDate } from 'helpers/DateHelper';
import { format } from 'date-fns';
import PageHeader from 'components/PageHeader';
import Search from 'components/Search';
import toast from 'components/toast';
import AuthContext from 'stores/Auth/authContext';
import styled from 'styled-components';
import InfoTextBox from 'components/Modals/Shared/InfoTextBox';
import CustomerActivity from './CustomerActivity';
import CustomerCanvases from './CustomerCanvases';
import CustomerContacts from './CustomerContacts';
import CustomerDetails from './CustomerDetail';
import CustomerResources from './CustomerResources';
import CustomerInbox from './CustomerInbox';
import CustomerContext from 'stores/Customer/customerContext';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullContext from 'stores/Full/fullContext';
import { ADMIN, IS_SAVING, HAS_SERVER_ERROR, FRONT_LINE, UNNAMED_ACCOUNT } from 'constants';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { Formik } from 'formik';
import { EditFieldsLink } from './Styles';
import useAutosizeTextArea from 'helpers/hooks/useAutosizeTextArea';

const HeaderLabel = styled.div`
  display: flex;
  color: var(--playbook-pewter);
  font-size: 14px;
  @media screen and (max-width: 426px) {
    margin-top: 12px;
    font-size: 13px;
  }
`;

const HeaderValue = styled.div`
  display: inline;
  color: var(--champion-charcoal);
  font-size: 16px;
  margin-bottom: 16px;
  @media screen and (max-width: 426px) {
    margin-bottom: 12px;
    flex-direction: column;
  }
`;

const LastUpdate = styled.div`
  margin: 0 0 0 12px;
  display: inline-flex;
  align-items: flex-end;
  color: var(--action-anchor);
  font-size: 11px;
  font-weight: 700;
  @media screen and (max-width: 426px) {
    margin: 0 0 16px;
  }
`;

const DateWrapper = styled.div`
  float: right;
  @media screen and (max-width: 480px) {
    float: left;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 0.875rem;
  margin: 0 1rem 0 auto;
  align-items: center;

  span {
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--playbook-pewter);
  }

  span.saving {
    animation: fadeIn 0.5s;
  }

  span.auto-saved {
    animation: fadeOut 2s normal 0.5s;
  }

  span.error {
    color: var(--danger);
  }

  @media only screen and (max-width: 768px) {
    margin: 0.5rem 1rem;
  }
`;

const TabSelector = styled.span`
  display: flex;
  flex-wrap: wrap;
`;

function Customer() {
  const [customer, setCustomer] = useState(null);
  const [headerData, setHeaderData] = useState(null);
  const [resources, setResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  const [searchedTerm, setSearchedTerm] = useState('');
  const [resProjSummary, setResProjSummary] = useState('');
  const [resExtData, setResExtData] = useState(null);
  const [assignCanvases, setAssignCanvases] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const {
    accountData,
    externalData,
    fetchCustomerDetail,
    populateAccountData,
    populateSummaryData,
    populateExternalData,
  } = useContext(CustomerContext);
  const { isExternal, roleAtleast } = useContext(FullContext);
  const {
    updateContactsSections,
    fetchInitialData,
    fetchInitialContactsData,
    getOwnerTypes,
    fetchInitialProjectsData,
  } = useContext(CustomFieldsContext);

  const params = useParams();

  const { id } = params;

  const location = useLocation();
  const history = useNavigate();

  const { nylasToken, isTenantAllowed } = useContext(AuthContext);

  const isResourcesTabActive = location.hash === '#resources';
  const isNotesTabActive = location.hash === '#notes';
  const isDetailsTabActive = location.hash === '#details';
  const isProjectsTabActive = location.hash === '' || location.hash === '#projects';
  const isContactsTabActive = location.hash === '#contacts';

  const titleRef = useRef();

  useAutosizeTextArea(titleRef.current, customer ? customer.name || '' : location.state?.name || '...');

  const getCustomerData = async () => {
    const data = await get('Customer/GetCustomerById', { id, undefined });
    setCustomer(data);
    updateContactsSections(data.contacts);
  };

  /**
   * Memoized filtering of the searched resource
   * @returns void
   */
  const handleSearchResource = useCallback(() => {
    const formattedSearch = searchedTerm.trim().toLowerCase();

    let filtered = [];

    const roles = {
      EXTERNAL: 'EXTERNAL',
      RESOURCE: 'RESOURCE',
      CLIENT_UPLOAD: 'CLIENT UPLOAD',
    };

    if (!searchedTerm.trim()) setFilteredResources([]);

    // If searched value is found then return filtered data else return initial resource data
    formattedSearch
      ? (filtered = resources.filter(
          (item) =>
            item?.attachment.name.toLowerCase().includes(searchedTerm.toLowerCase()) ||
            item?.project.name.toLowerCase().includes(searchedTerm.toLowerCase()) ||
            item?.step.name.toLowerCase().includes(searchedTerm.toLowerCase()) ||
            shortDate(new Date(item?.attachment.dateAdded))
              .toLowerCase()
              .includes(searchedTerm.toLowerCase()) ||
            (roles.RESOURCE.trim().toLowerCase().includes(searchedTerm.toLowerCase().trim()) &&
              item?.attachment.addedBy.role !== roles.EXTERNAL) ||
            (roles.CLIENT_UPLOAD.trim().toLowerCase().includes(searchedTerm.toLowerCase().trim()) &&
              item?.attachment.addedBy.role === roles.EXTERNAL)
        ))
      : (filtered = resources);

    setFilteredResources(filtered);
  }, [resources, searchedTerm]);

  /**
   * Fetch Resources Data
   * @returns void
   */
  const getResourcesData = async (customerId) => {
    const resourcesData = await get('Customer/GetAttachments', { customerId });
    setResources(resourcesData ?? []);
  };

  useEffect(() => {
    const getCustomerData = async () => {
      const data = await get('Customer/GetCustomerById', { id, undefined });
      const headerData = await get('Customer/GetCustomerSummary', { id });
      const resProjSummary = await get('Customer/GetCustomerProjectSummaries', { id });
      setResProjSummary(resProjSummary);
      setCustomer(data);
      setHeaderData(headerData);
    };

    const getExternalData = async () => {
      const response = externalData;
      if (!response.projects) {
        window.location.replace('/404');
      }
      const data = {
        name: response.name,
        canvases:
          response.projects?.map(({ customerCanvasId, name, status, progress, currentStep }) => {
            return {
              id: customerCanvasId,
              name,
              status,
              progress,
              currentStep,
            };
          }) ?? [],
      };

      setCustomer(data);
      setHeaderData(data);
      setResExtData(response);
    };

    if (!isExternal) {
      getCustomerData();
    } else {
      if (Object.keys(externalData).length) {
        getExternalData();
      }
    }
    return () => {
      setCustomer(null);
      setResExtData(null);
      setHeaderData(null);
      setResources([]);
    };
  }, [id, externalData, isExternal, updateContactsSections]);

  useEffect(() => {
    if (isResourcesTabActive) getResourcesData(customer?.id ?? resExtData?.customerId);
  }, [customer, resExtData, isResourcesTabActive]);

  useEffect(() => {
    if (isDetailsTabActive) {
      fetchInitialData();
      getOwnerTypes();
      fetchCustomerDetail();
    }
  }, [fetchCustomerDetail, fetchInitialData, getOwnerTypes, isDetailsTabActive]);

  useEffect(() => {
    if (isProjectsTabActive) {
      fetchInitialProjectsData();
    }
  }, [fetchInitialProjectsData, isProjectsTabActive]);

  useEffect(() => {
    if (isContactsTabActive) {
      fetchInitialContactsData();
    }
  }, [fetchInitialContactsData, isContactsTabActive]);

  useEffect(() => {
    if (headerData && !Object.keys(accountData).length) {
      populateAccountData(headerData);
    }
  }, [headerData, accountData, externalData, populateAccountData, populateExternalData]);

  useEffect(() => {
    if (resExtData && !Object.keys(externalData).length) {
      populateExternalData(resExtData);
    }
  }, [resExtData, externalData, populateExternalData]);

  useEffect(() => {
    if (resProjSummary) {
      populateSummaryData(resProjSummary.reverse());
      setResProjSummary(null);
    }
  }, [resProjSummary, populateSummaryData]);

  /**
   * Side effect for search queries depending on the tabs
   * @returns void
   */
  useEffect(() => {
    if (isResourcesTabActive) {
      handleSearchResource();
    }
  }, [isResourcesTabActive, handleSearchResource]);

  const saveName = useRef(
    debounce(async (customer) => {
      try {
        // KLUDGE - This is a workaround to solve bug ACD-255 (See also ACD-72 and another comment just
        // like this one. The data structure for the Customer page is overkill. All canvases with all their
        // touchpoints, plus all their touchpoints (again!) are included. When making updates to the title
        // and other attributes of the Customer entity, the endpoint is receiving a null object and throwing
        // an exception. This workaround strips all the extra stuff out by making a deep copy first and
        // dropping the long arrays.
        // This way uses some object destructuring, we can filter out the object properties we don't want,
        // and just include a new object with the rest of the data.
        const { canvases, customerTouchpoints, contacts, ...newObject } = customer;
        await post('Customer/UpdateCustomer', newObject, { params: { removeOldOwner: false } });
      } catch (error) {
        console.log(error);
      }
    }, 500)
  );

  const handleChangeCustomerName = (e) => {
    const newCustomer = { ...customer, name: e.target.value };
    setCustomer(newCustomer);
    saveName.current(newCustomer);
  };

  const onDeleteContact = (contactId) => {
    const { contacts } = customer;
    setCustomer({
      ...customer,
      contacts: contacts.filter((contact) => contact.contactId !== contactId),
    });
    toast.saved('Contact deleted successfully.');
  };

  const onEditContact = (contact) => {
    const { contacts } = customer;
    setCustomer({
      ...customer,
      contacts: contacts.map((c) => (c.contactId === contact.contactId ? contact : c)),
    });
    toast.saved('Contact saved successfully.');
  };

  const onNewContact = (contact) => {
    // Not ideal but User/CreateUser does not return the new contactId.
    getCustomerData();
    toast.saved('Contact created successfully.');
  };

  const handleSearch = (queryString) => {
    setSearchedTerm(queryString);
  };

  /**
   * Resource message when resources or filteredResources are empty
   */
  const resourcesEmptyMessage = useMemo(() => {
    if (resources && resources.length <= 0) return 'No resources';
    if (filteredResources && filteredResources.length <= 0) return 'No resources within filters';

    return '';
  }, [resources, filteredResources]);

  const contactEmails = useMemo(
    () => customer?.contacts?.map((contact) => contact.email).join(','),
    [customer?.contacts]
  );

  const InternalHeaders = () => (
    <Col className="mt-5">
      <Row>
        <Col className="p-0 align-items-end">
          <DateWrapper>
            <HeaderLabel>
              <span className="mr-1">Last Customer Activity</span>
              <InfoTextBox notActive={false} width={'310px'} top={'50px'} left={'-145px'}>
                Customer activity includes comments, completing tasks, uploading files or viewing resources.
              </InfoTextBox>
            </HeaderLabel>
            <HeaderValue>
              {headerData?.latestExternalActivity
                ? format(new Date(headerData.latestExternalActivity), 'MMM dd, yyyy')
                : '--'}
            </HeaderValue>
          </DateWrapper>
        </Col>
        <Col className="p-0">
          <DateWrapper>
            <HeaderLabel>
              <span className="mr-1">Last Internal Activity</span>
              <InfoTextBox notActive={false} width={'300px'} top={'50px'} left={'-275px'}>
                Internal activity includes notes, comments, completing tasks, and editing step or project
                details or members.
              </InfoTextBox>
            </HeaderLabel>
            <HeaderValue>
              {headerData?.latestInternalActivity
                ? format(new Date(headerData.latestInternalActivity), 'MMM dd, yyyy')
                : '--'}
            </HeaderValue>
          </DateWrapper>
        </Col>
      </Row>
    </Col>
  );

  const LastNote = () => (
    <div className="mb-4">
      <HeaderLabel>Last Note</HeaderLabel>
      <HeaderValue>
        {headerData?.latestNote ? (
          <>
            <span className="font-weight-bold mr-1">
              {`${headerData?.latestNote.userFirstName} ${headerData?.latestNote.userLastName}`}
            </span>
            {headerData?.latestNote?.text}
            <LastUpdate>
              {format(
                new Date(new Date(headerData?.latestNote?.dateCreated).toLocaleString()),
                'h:mm aaa PP'
              ).toUpperCase()}
            </LastUpdate>
          </>
        ) : (
          'No latest notes'
        )}
      </HeaderValue>
    </div>
  );

  function CustomerDetail() {
    if (isExternal) {
      return (
        <Tabs
          id="customer-tabs"
          className="extend-to-edge"
          activeKey={location.hash || '#projects'}
          onSelect={(key) => history(key)}
        >
          <Tab eventKey="#projects" title="Projects">
            <CustomerCanvases customerId={id} canvases={customer?.canvases} setCustomer={setCustomer} />
          </Tab>

          <Tab eventKey="#resources" title="Documents & Links">
            <CustomerResources
              resources={filteredResources}
              customerId={id}
              resourcesEmptyMessage={resourcesEmptyMessage}
            />
          </Tab>
        </Tabs>
      );
    } else {
      return (
        <Formik initialValues={{ isChangesSaving: null, hasServerError: null }} onSubmit={noop}>
          {({ values: { isChangesSaving, hasServerError }, setFieldValue }) => (
            <Tab.Container defaultActiveKey={location.hash || '#projects'}>
              <Col>
                <Row className="d-block">
                  <Nav variant="tabs">
                    <TabSelector>
                      <Nav.Item>
                        <Nav.Link eventKey="#projects" href="#projects">
                          Projects
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="#details" href="#details">
                          Details
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="#contacts" href="#contacts">
                          Contacts
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="#notes" href="#notes">
                          Activity & Notes
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="#resources" href="#resources">
                          Documents & Links
                        </Nav.Link>
                      </Nav.Item>
                      {nylasToken && isTenantAllowed && (
                        <Nav.Item>
                          <Nav.Link eventKey="#inbox" href="#inbox">
                            Inbox
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </TabSelector>

                    {(location.hash ||
                      location.hash === '' ||
                      location.hash === '#projects' ||
                      location.hash === '#details' ||
                      location.hash === '#contacts') && (
                      <ActionsContainer>
                        {!isChangesSaving && typeof isChangesSaving != 'boolean' ? null : Boolean(
                            isChangesSaving
                          ) ? (
                          <span className="saving">Saving...</span>
                        ) : Boolean(hasServerError) ? (
                          <span className="error">Unsaved changes</span>
                        ) : (
                          <span>Changes auto-saved.</span>
                        )}

                        {(location.hash === '' ||
                          location.hash === '#projects' ||
                          location.hash === '#details' ||
                          location.hash === '#contacts') &&
                          roleAtleast(ADMIN) && (
                            <EditFieldsLink
                              to={`/accounts/custom-fields/edit-account-fields${
                                location.hash === '' ? '#projects' : location.hash
                              }`}
                            >
                              Edit fields
                            </EditFieldsLink>
                          )}
                      </ActionsContainer>
                    )}

                    {location.hash === '#contacts' && roleAtleast(FRONT_LINE) && (
                      <div className="ml-1">
                        <button
                          onClick={() => setIsCreateNew((prev) => !prev)}
                          className="btn btn-secondary px-3 fs-7"
                        >
                          <FontAwesomeIcon icon={faPlus} /> Add contact
                        </button>
                      </div>
                    )}

                    {(location.hash === '#projects' || location.hash === '') && !isExternal && (
                      <div className="ml-1">
                        <button onClick={() => setAssignCanvases(true)} className="btn btn-primary px-3 fs-7">
                          <FontAwesomeIcon icon={faPlus} /> <strong>New Project</strong>
                        </button>
                      </div>
                    )}
                  </Nav>
                </Row>
                <Row className="d-block">
                  <Tab.Content>
                    <Tab.Pane eventKey="#projects">
                      <CustomerCanvases
                        customerId={id}
                        canvases={customer?.canvases}
                        setCustomer={setCustomer}
                        assignCanvases={assignCanvases}
                        setAssignCanvases={setAssignCanvases}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="#details">
                      <CustomerDetails
                        customerId={customer?.id}
                        setSaving={(value) => setFieldValue(IS_SAVING, value)}
                        setHasError={(value) => setFieldValue(HAS_SERVER_ERROR, value)}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                  <Tab.Content>
                    <Tab.Pane eventKey="#contacts">
                      <CustomerContacts
                        isCreateNew={isCreateNew}
                        setIsCreateNew={setIsCreateNew}
                        contacts={customer?.contacts}
                        onDelete={onDeleteContact}
                        onEdit={onEditContact}
                        onNew={onNewContact}
                        customerId={customer?.id}
                        setSaving={(value) => setFieldValue(IS_SAVING, value)}
                        setHasError={(value) => setFieldValue(HAS_SERVER_ERROR, value)}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="#notes">
                      <CustomerActivity customer={customer} isNotesTabActive={isNotesTabActive} />
                    </Tab.Pane>
                  </Tab.Content>
                  <Tab.Content>
                    <Tab.Pane eventKey="#resources">
                      <CustomerResources
                        resources={filteredResources}
                        customerId={customer?.id}
                        resourcesEmptyMessage={resourcesEmptyMessage}
                      />
                    </Tab.Pane>
                    {nylasToken && isTenantAllowed && (
                      <Tab.Pane eventKey="#inbox">
                        <CustomerInbox contactEmails={contactEmails} nylasToken={nylasToken} />
                      </Tab.Pane>
                    )}
                  </Tab.Content>
                </Row>
              </Col>
            </Tab.Container>
          )}
        </Formik>
      );
    }
  }

  return (
    <div id="customer-detail" className="pb-5">
      <PageHeader
        fullTitleWidth={true}
        className="align-items-start"
        title={
          isExternal ? (
            <Form.Label>{headerData ? headerData.name : UNNAMED_ACCOUNT}</Form.Label>
          ) : (
            <Form.Control
              ref={titleRef}
              className="no-border resizable-textarea editable-header"
              placeholder={UNNAMED_ACCOUNT}
              as="textarea"
              value={customer ? customer.name || '' : location.state?.name || '...'}
              onChange={handleChangeCustomerName}
            />
          )
        }
        loading={!headerData}
        subRow={!isExternal && <LastNote />}
        upName={!isExternal ? 'Accounts' : null}
        upPath={!isExternal ? '/accounts' : null}
      >
        <Fragment key="account-page-header">
          {!isExternal && <InternalHeaders />}
          {isResourcesTabActive && (
            <div className="d-flex flex-column flex-md-row mt-2-5 mt-md-0">
              <div className="d-flex mr-md-2-5 align-items-center">
                <Search onSearch={handleSearch} />
              </div>
            </div>
          )}
        </Fragment>
      </PageHeader>

      {customer && <CustomerDetail />}
    </div>
  );
}

export default Customer;
