import { faCog, faEllipsisH, faTrash, faClone, faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Alert, Dropdown, Form } from 'react-bootstrap';
import styled from 'styled-components';
import * as Api from '../api/Api';
import BuilderCardColumns from '../components/BuilderCardColumns';
import ModalCanvasSettings from '../components/Modals/CanvasSettings/CanvasSettings';
import ModalConfirm from 'components/Modals/Confirm/index';
import ModalEditTouchpoint from '../components/Modals/EditTouchpoint/EditTouchpoint';
import PageHeader from '../components/PageHeader';
import toast from 'components/toast';
import { debounce, getUniqueName } from '../helpers/CommonHelper';
import CustomerFacingToggle from 'components/Modals/CustomerFacingToggle';
import { Touchpoint } from 'models';
import { withRouter } from 'helpers/RouteHelper';

const BuilderCardColumnsContainer = styled.div`
  margin-top: 36px;
`;

const BuilderContainer = styled.div`
  h1 {
    width: 100%;
  }

  .template-title {
    text-overflow: ellipsis;
  }
`;

class Builder extends React.Component {
  state = {
    canvas: null,
    journeys: null,
    canvasForDelete: null,
    journeyForDelete: null,
    showCanvasSettingsModal: false,
    deleting: false,
    showingTouchpointId: null,

    allTouchpoints: {},
    allJourneys: null,
    journeyOrder: [],
    dependentData: [],
    loading: false,
    error: null,

    isPreviewMode: false,
  };

  async componentDidMount() {
    this.initPathVariables();
    this.loadCanvas();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.router.params.touchpointId &&
      (!prevProps.router.params.touchpointId ||
        prevProps.router.params.touchpointId !== this.props.router.params.touchpointId)
    ) {
      this.showTouchpointModal();
    }
    if (prevProps.router.params.touchpointId && !this.props.router.params.touchpointId) {
      this.setState({ showingTouchpointId: null });
    }
  }

  initPathVariables() {
    this.canvasId = parseInt(this.props.router.params.id);
  }

  onCanvasChanged = async () => {
    this.setState({ loading: true });
    const data = await Api.get('Canvas/GetCanvasById', {
      id: this.canvasId,
    });
    this.setState({
      canvas: data,
      loading: false,
    });
  };

  async loadCanvas() {
    await this.onCanvasChanged();
    this.loadJourneys();
  }

  async loadJourneys() {
    this.setState({ loading: true });

    const { items } = await Api.get('Journey/GetByCanvas', {
      canvasId: this.canvasId,
    });

    const allTouchpoints = {},
      allJourneys = {},
      journeyOrder = [],
      dependentData = [];
    for (const journey of items.sort((a, b) => a.order - b.order)) {
      journeyOrder.push(journey.id);
      const { touchpoints, ...rest } = journey;
      allJourneys[journey.id] = {
        ...rest,
        touchpointIds: journey.touchpoints
          .sort((a, b) => a.order - b.order)
          .map((t) => {
            allTouchpoints[t.id] = t;
            return t.id.toString();
          }),
      };
      dependentData.push({ id: journey?.id, name: journey?.name, touchpoints: [...touchpoints] });
    }

    this.setState({
      allTouchpoints: allTouchpoints,
      allJourneys: allJourneys,
      journeyOrder: journeyOrder,
      dependentData: dependentData,
      loading: false,
    });

    this.showTouchpointModal();
  }

  showCanvasSettingsModal = () => {
    this.setState({ showCanvasSettingsModal: true });
  };

  handleChangeCanvasName = (e) => {
    this.setState(
      {
        canvas: {
          ...this.state.canvas,
          name: e.target.value,
        },
      },
      () => this.editPartial([this.patchFieldProvider(this.state.canvas, 'name')]) //this.editCanvas()
    );
  };

  patchFieldProvider = (obj, fieldName) => {
    return {
      op: 'replace',
      path: '/' + fieldName,
      value: obj[fieldName],
    };
  };

  editPartial = debounce(async (data) => {
    await Api.patch(`Canvas/PartialCanvasUpdate?canvasId=${this.state.canvas.id}`, data);
  });

  editCanvas = debounce(async () => {
    const response = await Api.post(`Canvas/EditCanvas?canvasId=${this.state.canvas.id}`, {
      name: this.state.canvas.name,
      autoadvance: this.state.canvas.autoadvance,
      canvasStatus: this.state.canvas.canvasStatus,
      days: this.state.canvas.days,
      dynamicTimeline: this.state.canvas.dynamicTimeline,
      emailFrequencyUnit: this.state.canvas.emailFrequencyUnit,
      emailFrequencyValue: this.state.canvas.emailFrequencyValue,
      externalsHaveTaskItemPermissions: this.state.canvas.externalsHaveTaskItemPermissions,
      sendEmail: this.state.canvas.sendEmail,
      sendPastDueEmail: this.state.canvas.sendPastDueEmail,
    });

    const { name: newName } = response;
    if (newName === undefined) {
      this.setState({ error: response });
    } else {
      this.setState({ error: null });
    }
    this.setState({
      canvas: {
        ...this.state.canvas,
        name: newName,
      },
    });
  }, 1000);

  canvasSettingsModalOnHide = () => {
    this.setState({ showCanvasSettingsModal: false });
  };

  deleteCanvas = async () => {
    this.setState({ deleting: true });
    await Api.post(`Canvas/DeleteCanvas?canvasId=${this.state.canvasForDelete.id}`, { props: this.props });
    this.setState({ deleting: false });
    this.setState({ canvasForDelete: null });
    this.props.router.navigate('/templates');
  };

  cloneJourney = async (id) => {
    await Api.post(`Journey/CloneJourney?journeyId=${id}`);
    this.loadJourneys();
    toast.saved('Phase cloned successfully.');
  };

  confirmDeleteJourney = async (id) => {
    if (this.state.deleting) {
      return;
    }
    this.setState({ journeyForDelete: id });
  };

  deleteJourney = async () => {
    this.setState({ deleting: true });
    await Api.deleteRequest(`Journey/ArchiveJourney?id=${this.state.journeyForDelete}`);
    this.setState({ deleting: false });

    const { [this.state.journeyForDelete]: journey, ...restOfJourneys } = this.state.allJourneys;
    const newAllTouchpoints = { ...this.state.allTouchpoints };
    for (const id of journey.touchpointIds) {
      delete newAllTouchpoints[id];
    }

    const newJourneyOrder = Array.from(this.state.journeyOrder);
    newJourneyOrder.splice(newJourneyOrder.indexOf(journey.id), 1);

    this.setState({
      allJourneys: restOfJourneys,
      allTouchpoints: newAllTouchpoints,
      journeyOrder: newJourneyOrder,
    });

    this.setState({ journeyForDelete: null });
  };

  onAddJourney = async (name) => {
    const data = {
      name: name,
      canvasId: this.canvasId,
    };
    const res = await Api.post(`Journey/CreateJourney`, data);
    this.setState({
      allJourneys: {
        ...this.state.allJourneys,
        [res.id]: {
          ...res,
          touchpointIds: [],
        },
      },
      journeyOrder: [...this.state.journeyOrder, res.id],
    });
  };

  onEditJourney = async ({ name, id }) => {
    const res = await Api.post(`Journey/UpdateJourney`, {
      ...this.state.allJourneys[id],
      name,
    });

    this.setState({
      allJourneys: {
        ...this.state.allJourneys,
        [id]: {
          ...this.state.allJourneys[id],
          name: res.name,
        },
      },
    });
  };

  onDeleteTouchpoint = async (id) => {
    await Api.deleteRequest(`Touchpoint/DeleteTouchpoint?id=${id}`);

    const { [id]: touchpoint, ...rest } = this.state.allTouchpoints;
    const journey = { ...this.state.allJourneys[touchpoint.journeyId] };
    journey.touchpointIds = journey.touchpointIds.filter((x) => x !== id.toString());

    this.setState({
      allTouchpoints: rest,
      allJourneys: {
        ...this.state.allJourneys,
        [touchpoint.journeyId]: journey,
      },
    });

    await this.loadCanvas();
  };

  onDuplicateTouchpoint = async (id) => {
    const response = await Touchpoint.duplicateTouchpoint(id);
    console.log(response);

    await this.loadCanvas();
  };

  BFSDependendants(objects, id) {
    const queue = [...objects];
    while (queue.length) {
      const t = queue.shift();
      if (t.id === id) return true;
      queue.push(...(t.touchpointDependents || []));
    }
    return false;
  }

  getDependables(touchpoint) {
    const allTouchpoints = Object.values(this.state.allTouchpoints);
    return (
      allTouchpoints.reduce((res, t) => {
        if (t.id !== touchpoint.id && t.durationAdded && t.durationOptionAmount !== 0) {
          let foundInDependencyTree = this.BFSDependendants(touchpoint.touchpointDependents || [], t.id);
          if (!foundInDependencyTree) {
            res.push({ id: t.id, title: t.title });
          }
        }
        return res;
      }, []) ?? []
    );
  }

  async createTouchpoint(id, name, order) {
    const dependentDataCopy = this.state.dependentData ?? [];
    const mergedSteps = this.state.dependentData.map((item) => item.touchpoints).flat();

    const res = await Api.post('Touchpoint/CreateTouchpoint', {
      title: getUniqueName(mergedSteps, name),
      description: '',
      journeyId: id,
      order,
      durationAdded: true,
      durationOptionAmount: 1,
      tasks: [],
    });

    const journey = this.state.allJourneys[res.journeyId];

    if (dependentDataCopy?.length === 0) {
      // For brand new templates
      dependentDataCopy.push({ id: journey?.id, name: journey?.name, touchpoints: [res] });
    } else {
      // For existing templates with phase names
      const journeyIndex = dependentDataCopy.findIndex((item) => item.id === journey.id);
      if (journeyIndex !== -1) {
        dependentDataCopy[journeyIndex].touchpoints.push({ ...res });
      } else {
        dependentDataCopy.push({ id: journey?.id, name: journey?.name, touchpoints: [res] });
      }
    }

    this.setState({
      allTouchpoints: {
        ...this.state.allTouchpoints,
        [res.id]: res,
      },
      allJourneys: {
        ...this.state.allJourneys,
        [journey.id]: {
          ...journey,
          touchpointIds: [...journey.touchpointIds, res.id.toString()],
        },
      },
      dependentData: dependentDataCopy,
    });
  }

  showingTouchpoint = () => {
    for (let j of this.state.journeys) {
      let t = j.touchpoints.find((t) => t.id === this.state.showingTouchpointId);
      if (t) return t;
    }
    return null;
  };

  showTouchpointModal() {
    const touchpointId = parseInt(this.props.router.params.touchpointId);
    if (touchpointId) {
      this.setState({ showingTouchpointId: touchpointId });
    }
  }

  touchpointModalOnHide = () => {
    this.props.router.navigate(`/templates/${this.canvasId}`);
    this.setState({ showingTouchpointId: null });
  };

  onUpdated = async () => {
    this.setState({});
    await this.onCanvasChanged();
  };

  updateTouchpoint = (touchpoint, shouldReload = false) => {
    if (shouldReload) {
      this.loadJourneys();
    } else {
      this.setState({
        allTouchpoints: {
          ...this.state.allTouchpoints,
          [touchpoint.id]: touchpoint,
        },
      });
    }
  };

  onFilesUpdated = (attachments, links) => {
    const touchpoint = this.state.allTouchpoints[this.state.showingTouchpointId];
    this.setState({
      allTouchpoints: {
        ...this.state.allTouchpoints,
        [touchpoint.id]: {
          ...touchpoint,
          touchpointAttachments: attachments,
          touchpointLinks: links,
        },
      },
    });
  };

  onTagAdded = (touchpointId, tags) => {
    const touchpoint = this.state.allTouchpoints[touchpointId];
    this.setState({
      allTouchpoints: {
        ...this.state.allTouchpoints,
        [touchpoint.id]: {
          ...touchpoint,
          touchpointTags: tags,
        },
      },
    });
  };

  /**
   * Updates all touchpoint tags with the data from the newly edited global tag.
   * @param {Object} tag The data of the global tag that has been edited
   */
  onTagEdited = (tag) => {
    const newTouchpointState = {};
    for (const key in this.state.allTouchpoints) {
      const touchpoint = this.state.allTouchpoints[key];
      newTouchpointState[key] = {
        ...touchpoint,
        touchpointTags: touchpoint.touchpointTags.map((t) => {
          if (t.tagId === tag.id) {
            return {
              ...t,
              color: tag.color,
              text: tag.text,
            };
          }
          return t;
        }),
      };
    }

    this.setState({
      allTouchpoints: newTouchpointState,
    });
  };

  /**
   * Removes any touchpoint tag from all touchpoints that corresponds to a deleted global tag
   * @param {int} globalTagId The id of the global tag
   */
  handleDeleteTag = (globalTagId) => {
    const newTouchpointState = {};
    for (const key in this.state.allTouchpoints) {
      const touchpoint = this.state.allTouchpoints[key];
      newTouchpointState[key] = {
        ...touchpoint,
        touchpointTags: touchpoint.touchpointTags.filter((t) => t.tagId !== globalTagId),
      };
    }

    this.setState({
      allTouchpoints: newTouchpointState,
    });
  };

  /**
   * Removes a touchpoint tag from one touchpoint.
   * @param {int} tagId The id of a touchpoint tag
   * @param {int} touchpointId The id of the touchpoint affected.
   */
  handleRemoveTag = (tagId, touchpointId) => {
    const touchpoint = this.state.allTouchpoints[touchpointId];
    this.setState({
      allTouchpoints: {
        ...this.state.allTouchpoints,
        [touchpointId]: {
          ...touchpoint,
          touchpointTags: touchpoint.touchpointTags.filter((t) => t.id !== tagId),
        },
      },
    });
  };

  onIdeasUpdated = (ideas) => {
    const touchpoint = this.state.allTouchpoints[this.state.showingTouchpointId];
    this.setState({
      ...this.state.allTouchpoints,
      [touchpoint.id]: {
        ...touchpoint,
        touchpointIdeas: ideas,
      },
    });
  };

  onJourneyReorder = (newOrder) => {
    this.setState({
      journeyOrder: newOrder,
    });
  };

  onReorderTouchpoints = (newJourneys) => {
    this.setState({
      ...this.state,
      allJourneys: {
        ...this.state.allJourneys,
        ...newJourneys,
      },
    });
  };

  toggleShowMyTouchpoints = (event) => this.setState({ isPreviewMode: event.target.checked });

  headerDetail() {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <div ref={ref} onClick={onClick} className="cursor-pointer">
        {children}
      </div>
    ));
    return (
      <div className="builder-header order-md-0">
        <div className="section">
          {this.state.canvas.days > 0 ? (
            <span>
              <span className="nav-link-light mr-2">Length of project</span>
              <span className="small-info">{this.state.canvas.days} business days</span>
            </span>
          ) : null}
        </div>
        <div onClick={this.showCanvasSettingsModal} className="section nav-link-light cursor-pointer">
          <FontAwesomeIcon className="color-action-anchor mr-2 mt-1" icon={faCog} />
          Settings
        </div>
        <div className="section">
          <Dropdown className="align-self-center order-md-1">
            <Dropdown.Toggle as={CustomToggle}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </Dropdown.Toggle>
            <Dropdown.Menu align="left">
              <Dropdown.Item
                className="danger"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (this.state.deleting) {
                    return;
                  }
                  this.setState({ canvasForDelete: this.state.canvas });
                }}
              >
                <FontAwesomeIcon fixedWidth icon={faTrash} />
                Delete template
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <ModalConfirm
          title="Delete template"
          message={
            <>
              Are you sure you want to delete {this.state.canvasForDelete?.name || 'this canvas'}?
              <br />
              This action cannot be undone.
            </>
          }
          show={this.state.canvasForDelete !== null}
          onConfirm={this.deleteCanvas}
          onHide={() => {
            this.setState({ canvasForDelete: null });
          }}
        />
      </div>
    );
  }

  render() {
    return (
      <BuilderContainer>
        <PageHeader
          title={
            <Form.Control
              className="no-border editable-header template-title"
              placeholder="Unnamed Canvas"
              value={this.state.canvas ? this.state.canvas.name || '' : '...'}
              onChange={this.handleChangeCanvasName}
            />
          }
          titleSize="24px"
          loading={!this.state.canvas || !this.state.allJourneys}
          upName="Templates"
          upPath="/templates"
          border
        >
          {this.state.canvas && this.headerDetail()}
          {this.state.showCanvasSettingsModal && (
            <ModalCanvasSettings
              show={this.state.showCanvasSettingsModal}
              onHide={this.canvasSettingsModalOnHide}
              canvas={this.state.canvas}
              onUpdated={this.onUpdated}
              isTemplate
            />
          )}
        </PageHeader>
        <BuilderCardColumnsContainer>
          {this.state.error ? <Alert variant="danger">{this.state.error}</Alert> : ''}
          {this.state.canvas && this.state.allJourneys && (
            <BuilderCardColumns
              columns={this.state.journeyOrder.map((jid) => {
                const journey = this.state.allJourneys[jid];
                const touchpoints = journey.touchpointIds.map((id) => {
                  const touchpointDependentId = this.state.allTouchpoints[id]?.touchpointDependentId;
                  const dependent = Object.values(this.state.allTouchpoints).find(
                    (data) => data.id === touchpointDependentId
                  );
                  const dependentTitle = dependent?.title;

                  return {
                    ...this.state.allTouchpoints[id],
                    dependentTitle,
                    link: `/templates/${this.canvasId}/steps/${id}`,
                  };
                });

                return {
                  ...journey,
                  icons: [
                    { icon: faClone, onClick: this.cloneJourney },
                    { icon: faTrash, onClick: this.confirmDeleteJourney },
                    { icon: faGripVertical, onClick: () => {} },
                  ],
                  items: touchpoints,
                };
              })}
              isPreviewMode={this.state.isPreviewMode}
              onAddItem={this.createTouchpoint.bind(this)}
              onDeleteItem={this.onDeleteTouchpoint}
              onDuplicateItem={this.onDuplicateTouchpoint}
              onAddColumn={this.onAddJourney}
              onEditColumn={this.onEditJourney.bind(this)}
              onUpdated={this.onUpdated}
              allJourneys={this.state.allJourneys}
              journeyOrder={this.state.journeyOrder}
              onJourneyReorder={this.onJourneyReorder}
              onReorderTouchpoints={this.onReorderTouchpoints}
            />
          )}
          <ModalConfirm
            title="Delete phase"
            message={
              <>
                Are you sure you want to delete this phase?
                <br />
                This action cannot be undone.
              </>
            }
            show={this.state.journeyForDelete !== null}
            onConfirm={this.deleteJourney}
            onHide={() => {
              this.setState({ journeyForDelete: null });
            }}
          />
        </BuilderCardColumnsContainer>
        {this.state.showingTouchpointId && (
          <ModalEditTouchpoint
            show={this.state.showingTouchpointId != null}
            onHide={this.touchpointModalOnHide}
            touchpoint={this.state.allTouchpoints[this.state.showingTouchpointId]}
            onFilesUpdated={this.onFilesUpdated}
            onTagAdded={this.onTagAdded}
            onTagEdited={this.onTagEdited}
            onDeleteTag={this.handleDeleteTag}
            onRemoveTag={this.handleRemoveTag}
            onIdeasUpdated={this.onIdeasUpdated}
            onUpdateTouchpoint={this.updateTouchpoint}
            onCanvasUpdate={this.onCanvasChanged}
            getDependables={this.getDependables.bind(this)}
            appSettings={this.props.appSettings}
            allTouchpoints={this.state.allTouchpoints}
            dependentData={this.state.dependentData}
          />
        )}

        {!this.state.loading && (
          <CustomerFacingToggle checked={this.state.isPreviewMode} onChange={this.toggleShowMyTouchpoints} />
        )}
      </BuilderContainer>
    );
  }
}

export default withRouter(Builder);
