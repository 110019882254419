import { PERMISSIONS, FIELD_TYPES } from 'constants';

export const getFieldPermission = (permission) => {
  switch (permission) {
    case 'WontSync':
      return PERMISSIONS.WONT_SYNC;

    case 'ReadOnly':
      return PERMISSIONS.READ_ONLY;

    default:
      return PERMISSIONS.SYNCS;
  }
};

export const getFieldType = (type) => {
  switch (type) {
    case 'Paragraph':
      return FIELD_TYPES.PARAGRAPH;

    case 'DropdownSingleSelect':
      return FIELD_TYPES.SINGLE_DROPDOWN;

    case 'DropdownMultiSelect':
      return FIELD_TYPES.MULTI_DROPDOWN;

    case 'Number':
      return FIELD_TYPES.NUMBER;

    case 'Currency':
      return FIELD_TYPES.CURRENCY;

    case 'Date':
      return FIELD_TYPES.DATE;

    case 'Time':
      return FIELD_TYPES.TIME;

    case 'DateTime':
      return FIELD_TYPES.DATE_AND_TIME;

    case 'Timezone':
      return FIELD_TYPES.TIMEZONE;

    case 'Email':
      return FIELD_TYPES.EMAIL;

    case 'Link':
      return FIELD_TYPES.LINK;

    case 'Boolean':
      return FIELD_TYPES.YES_NO;

    case 'Switch':
      return FIELD_TYPES.SWITCH;

    default:
      return FIELD_TYPES.TEXT;
  }
};
