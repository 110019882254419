import styled from 'styled-components';

export const SuccessContainer = styled.div`
  p {
    margin: 0;
  }

  .text-subscription-success {
    margin-bottom: 1.5rem;

    .interval {
      text-transform: capitalize;
    }
  }
`;
