import { useRef } from 'react';
import { ErrorMessage, FormikContext } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { signUpFields } from './fixtures';
import { useSignUp } from './hooks/useSignUp';
import FullScreenWithBackground from 'components/FullScreenWithBackground';
import ReCAPTCHA from 'react-google-recaptcha';
import SigningUp from './SigningUp';
import { ErrorContainer, SignUpInfoContainer } from './styles';
import { LOADING_TITLES, DEFAULT_FIELDS } from 'constants';

const SignUp = () => {
  const { formikBag, isSigningUp, signUpStatus, setSignUpStatus, setCaptchaToken } = useSignUp();
  const captchaRef = useRef(null);

  const onSubmitForm = async (event) => {
    event.preventDefault();
    const token = captchaRef.current.getValue();
    setCaptchaToken(token);
    captchaRef.current.reset();
    await formikBag.submitForm();
  };

  const sendHelpEmail = () => {
    setSignUpStatus({
      title: LOADING_TITLES.EMAIL_SENT.title,
      subTitle: LOADING_TITLES.EMAIL_SENT.subTitle,
    });
  };

  const renderLeftContent = (
    <SignUpInfoContainer>
      <h1>Let's Get Started!</h1>
      <ul>
        <li><h3>Build your templates</h3></li>
        <li><h3>Add your clients</h3></li>
        <li><h3>Build better relationships</h3></li>
      </ul>
    </SignUpInfoContainer>
  );

  return (
    <FormikContext.Provider value={formikBag}>
      {!isSigningUp ? (
        <FullScreenWithBackground
          titleClassName="fs-6 font-weight-bold my-3 py-1"
          title="Sign up for a free account"
          leftContent={renderLeftContent}
          maxWidth="34.25rem"
        >
          <form onSubmit={onSubmitForm} className="text-center form_submitted">
            {signUpFields.map((field, index) => {
              return (
                <Form.Group key={index}>
                  <Form.Control
                    autoFocus={field.label === DEFAULT_FIELDS.FIRST_NAME}
                    name={field.fieldName}
                    type="text"
                    placeholder={field.label}
                    value={formikBag.values[field.fieldName]}
                    onChange={formikBag.handleChange}
                  />
                  <ErrorContainer>
                    <ErrorMessage name={field.fieldName} />
                  </ErrorContainer>
                </Form.Group>
              );
            })}
            <div className="d-inline-flex mt-2 mb-3 pb-1">
              <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} ref={captchaRef} />
            </div>
            <Button type="submit" className="mr-4 mt-2 w-auto d-inline-flex" variant="primary" block>
              Submit
            </Button>
          </form>
          <div className="text-center mt-3">
            Already have an account?{' '}
            <a href="/login" className="font-weight-bold ml-1">
              Sign In
            </a>
          </div>
        </FullScreenWithBackground>
      ) : (
        <SigningUp status={signUpStatus} sendEmail={sendHelpEmail} />
      )}
    </FormikContext.Provider>
  );
};

export default SignUp;
