import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function LoadingSpinner({ className, white }) {
  return (
    <FontAwesomeIcon
      data-testid="loading-spinner"
      className={(white ? '' : 'text-muted ') + (className ? className : '')}
      spin
      icon={faSpinner}
    />
  );
}
