import styled from 'styled-components';

export const HeaderContainer = styled.div`
  padding-inline: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    align-self: flex-start;
    font-size: 1.563rem;
    cursor: pointer;
  }
`;
