import { useCallback, useEffect, useRef, useState, useContext } from 'react';

import { useEditableField } from 'components/CustomFields/hooks';
import { Form, Col } from 'react-bootstrap';

import { faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import ModalConfirm from 'components/Modals/Confirm';
import { debounce, noop } from 'helpers/CommonHelper';
import { useParser } from 'views/Customers/hooks';

import { useFormikContext } from 'formik';

import { Customers } from 'models';
import { PERMISSIONS, FIELD_TYPES, ADMIN } from 'constants';
import { GrayIcon, MoreInfoFieldContainer } from './Styles';
import FullContext from 'stores/Full/fullContext';

const MoreInfoFields = ({ contactId, isRequired, field, name, isDropUp, setSaving, setHasError }) => {
  const { handleParseRequestProperty, handleParseRequestValues } = useParser();
  const { setFieldValue } = useFormikContext();
  const { roleAtleast } = useContext(FullContext);

  const [showModal, setShowModal] = useState(false);
  const [internalValue, setInternalValue] = useState(null);

  const isReadOnly = field?.syncOption === PERMISSIONS.READ_ONLY;

  const fieldRef = useRef(null);

  const menuFooterLink =
    roleAtleast(ADMIN) && field?.externalDataField?.isCustomField
      ? `/accounts/custom-fields/edit-account-fields#contacts?field=${field.objectMapId}`
      : '';

  useEffect(() => {
    if (field?.formikValue) setInternalValue(field?.formikValue);
  }, [field?.formikValue]);

  const handleUpdate = async (value) => {
    const isInvalidDateTime = field?.fieldType === FIELD_TYPES.DATE_AND_TIME && (!value.date || !value.time);

    if (!value && field?.isRequired) return;
    if (isInvalidDateTime) return;

    try {
      setSaving(true);
      setHasError(false);

      const request = {
        field: handleParseRequestProperty(field),
        value: handleParseRequestValues(field, value),
      };

      await Customers.updateContact(contactId, request);

      setSaving(false);
    } catch (error) {
      setSaving(false);
      setHasError(true);
    }
  };

  const handleChange = useRef(debounce(async (value) => handleUpdate(value), 500));

  const handleBlur = useCallback(() => {
    if (!!contactId && !field?.formikValue && field?.isRequired) setShowModal(true);
  }, [contactId, field?.formikValue, field?.isRequired]);

  const handleClear = async () => {
    if (field?.isRequired || !contactId) return;

    await handleUpdate('');
  };

  const { renderField } = useEditableField({
    field,
    name,
    menuFooterLink,
    disabled: !!contactId && isReadOnly,
    onChange: !!contactId ? handleChange.current : noop,
    onBlur: handleBlur,
    onClear: handleClear,
    isModal: !contactId,
    fieldRef,
    isDropDownLastItem: isDropUp,
  });

  const renderModalMessage = () => (
    <p>
      Please fill in a value for <b>{field?.fieldLabel}</b>.
    </p>
  );

  const modalStyle = {
    inner: {
      maxWidth: '33.563rem',
      padding: '2.5rem 1.25rem !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  const handleModalClose = useCallback(() => {
    setFieldValue(name, internalValue);

    setShowModal(false);

    setTimeout(() => {
      !internalValue && fieldRef.current && fieldRef.current.focus();
    }, 500);
  }, [internalValue, name, fieldRef, setFieldValue]);

  const isDateTime = {
    [FIELD_TYPES.DATE]: FIELD_TYPES.DATE,
    [FIELD_TYPES.TIME]: FIELD_TYPES.TIME,
    [FIELD_TYPES.DATE_AND_TIME]: FIELD_TYPES.DATE_AND_TIME,
    [FIELD_TYPES.DATE_TIME]: FIELD_TYPES.DATE_TIME,
  };

  return (
    <>
      <Form.Group controlId={`${field}`}>
        {!!contactId && (
          <Form.Label column sm={12}>
            {`${field?.fieldLabel} ${isRequired ? '*' : ''}`}
            {!!contactId && isReadOnly && <GrayIcon icon={faLockKeyhole} className="ml-2 fa-sm" />}
          </Form.Label>
        )}

        <MoreInfoFieldContainer
          className={`${isDateTime[field?.fieldType] ? 'date-time' : ''} ${
            isReadOnly && !!contactId ? 'read-only' : ''
          } ${!!contactId ? 'is-edit' : ''}`}
        >
          <Col sm={12}>{renderField()}</Col>
        </MoreInfoFieldContainer>
      </Form.Group>

      <ModalConfirm
        title="Required field"
        message={renderModalMessage()}
        show={!!showModal}
        hideCancel
        confirmText="Close"
        variant="primary"
        innerProps={modalStyle.inner}
        buttonsContainerProps={modalStyle.buttonContainer}
        autoButtonsWidth
        onConfirm={handleModalClose}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};
export default MoreInfoFields;
