import { useState, useContext } from 'react';
import { useFormik } from 'formik';
import { initialValues } from 'components/Modals/Customers/fixtures';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { getFieldType } from 'components/CustomFields/Details/fixtures';
import { DEFAULT_FIELDS } from 'constants';
import { Customers, Settings } from 'models';
import toast from 'components/toast';
import { addCustomerSchema } from '../validations';
import { useParser } from './useParser';
import FullContext from 'stores/Full/fullContext';

export const useCustomers = ({ fetchCustomers }) => {
  const { populateAppSettings } = useContext(FullContext);
  const { editableSections, options } = useContext(CustomFieldsContext);
  const { handleParseRequestProperty, handleParseRequestValues } = useParser();
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);

  const { ownerList, typeList } = options;

  const getFieldValues = (fieldLabel, fieldTypeOptions) =>
    fieldLabel === DEFAULT_FIELDS.OWNER
      ? ownerList
      : fieldLabel === DEFAULT_FIELDS.TYPE
      ? typeList
      : fieldTypeOptions?.map((value) => JSON.parse(value));

  const newDetailsFields = editableSections.map((section) => ({
    ...section,
    fields: section.fields
      .filter(({ isHidden }) => !isHidden)
      .map((field) => ({
        ...field,
        fieldType: getFieldType(field?.fieldType),
        fieldTypeOptions: getFieldValues(field?.fieldLabel, field?.fieldTypeOptions),
        formikValue: field?.fieldLabel === DEFAULT_FIELDS.ACTIVE || null,
      })),
  }));

  const handleLoadAppSettings = async () => {
    const appSettings = await Settings.getAppSettings();
    populateAppSettings(appSettings);
  };

  /**
   * Submit handler for Formik's onSubmit instance
   * endpoint and dynamic values will be properly
   * implemented once submit endpoint will be
   * integrated
   * @param values
   * @returns void
   */
  const handleSubmit = async (values) => {
    const flattenedValues = values.detailsFields.map(({ fields }) => fields).flat();
    const transformedValues = flattenedValues.reduce((result, current) => {
      return {
        ...result,
        [`${handleParseRequestProperty(current)}`]: handleParseRequestValues(current, null),
      };
    }, {});

    const request = { name: values.accountName, ...transformedValues };

    const response = await Customers.addCustomer(request);

    if (response?.errors) {
      const flattenedErrors = Object.values(response?.errors).flatMap((errors) => errors);
      toast.error(flattenedErrors.join(' '));
      return;
    }

    if (response && response.errorMessage) {
      toast.error(response.errorMessage);
      return;
    } else {
      toast.saved('Account created successfully!');
    }

    await handleLoadAppSettings();
    await fetchCustomers();

    handleHide();
  };

  /**
   * Closes the add custom field modal and resets the formik instance
   * @param none
   * @returns void
   */
  const handleHide = () => {
    setShowAddCustomerModal(false);

    setTimeout(() => {
      formikBag.resetForm();
    }, 500);
  };

  /**
   * Opens the add custom field modal
   * @param none
   * @returns void
   */
  const handleShow = () => {
    setShowAddCustomerModal(true);
  };

  /**
   * Add Custom Field Formik Instance
   */
  const formikBag = useFormik({
    initialValues: { ...initialValues, detailsFields: newDetailsFields },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: addCustomerSchema,
    onSubmit: handleSubmit,
  });

  return {
    formikBag,
    showAddCustomerModal,
    handleHide,
    handleShow,
    handleLoadAppSettings,
  };
};
