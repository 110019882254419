import { useState, useEffect, useRef, useContext } from 'react';
import * as Api from '../../api/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TableWrapperStyle,
  TableTitleStyle,
  TableStyle,
  TableDataStyle,
  TableDataConditional,
  NoDataTextStyle,
  StyledTr,
} from './SharedStyles';
import TableHeader from './TableHeader';
import { sortByLetter, sortByNumber } from './sorting';
import ReportingContext from './context/reportingContext';
import { Link } from 'react-router-dom';
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import HeaderFilter from './HeaderFilter';
import styled from 'styled-components';

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--schedule-sapphire);
`;

const VerticalSlash = styled.span`
  color: var(--action-anchor);
`;

export default function StepPerformanceTable({ showTable, setShowTable }) {
  const [showFilters, setShowFilters] = useState(false);
  const [touchpointData, setTouchpointData] = useState([]);
  const { activeTemplate, activePhase, activeTimeRangeFilters, activeAccountFilters, startDate, endDate } =
    useContext(ReportingContext);
  const [sortedColumn, setSortedColumn] = useState(activePhase?.name ? activePhase?.name : 'Step');
  const table = useRef();

  useEffect(() => {
    let accountFilters = '';
    if (activeAccountFilters?.length > 0) {
      activeAccountFilters.forEach((filter) => {
        accountFilters += `&clientTypes=${filter}`;
      });
    }
    const prepareSort = (data) => {
      const sortedData = data.sort((a, b) => b?.name.localeCompare(a?.name));
      setTouchpointData(sortedData);
    };
    const getPhaseData = async () => {
      const res = await Api.get(
        `Report/GetTouchpointPerformance?startDate=${startDate}&endDate=${endDate}${
          activeTemplate && activePhase && `&canvasIds=${activeTemplate}&journeyIds=${activePhase?.id}`
        }${accountFilters}`
      );
      prepareSort(res);
    };
    if (activePhase) {
      getPhaseData();
    } else {
      setTouchpointData([]);
    }
  }, [activeAccountFilters, activePhase, activeTemplate, endDate, startDate]);

  return (
    <TableWrapperStyle width="half">
      <TableTitleStyle onClick={setShowTable}>
        <span className="title">
          <StyledIcon icon={showTable ? faAnglesUp : faAnglesDown} className="mr-2" />
          Step Performance by Template
        </span>
        {(activeTimeRangeFilters.length > 0 || activeAccountFilters.length > 0) && (
          <HeaderFilter setShowFilters={setShowFilters} showFilters={showFilters} />
        )}
      </TableTitleStyle>
      {showTable && (
        <TableStyle ref={table}>
          <thead>
            <StyledTr>
              <TableHeader
                sortedColumn={sortedColumn}
                setSortedColumn={setSortedColumn}
                table={table}
                sortByFunction={sortByLetter}
                sortByDefault={true}
                flexBasis={24}
              >
                {activePhase?.name ? activePhase?.name : 'Step'}
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                setSortedColumn={setSortedColumn}
                table={table}
                sortByFunction={sortByNumber}
                flexBasis={28}
              >
                Avg &nbsp;|&nbsp; Median Completion Time
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                setSortedColumn={setSortedColumn}
                table={table}
                sortByFunction={sortByNumber}
                flexBasis={24}
              >
                Template Completion Time
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                setSortedColumn={setSortedColumn}
                table={table}
                sortByFunction={sortByNumber}
                flexBasis={24}
              >
                Dropout Rate
              </TableHeader>
            </StyledTr>
          </thead>
          <tbody>
            {activeTemplate ? (
              activePhase && touchpointData?.length > 0 ? (
                touchpointData.map((item) => (
                  <tr key={item?.id}>
                    <TableDataStyle flexBasis={24} link={true}>
                      <Link to={`/templates/${activeTemplate}/steps/${item?.id}`}>{item?.name}</Link>
                    </TableDataStyle>
                    <TableDataStyle flexBasis={28}>
                      <TableDataConditional
                        danger={item?.averageActualCompletionTimeInDays > item?.targetCompletionInDays}
                      >
                        {item?.averageActualCompletionTimeInDays}
                      </TableDataConditional>
                      &nbsp;<VerticalSlash>&nbsp;|&nbsp;</VerticalSlash>&nbsp;
                      <TableDataConditional
                        danger={item?.medianActualCompletionTimeInDays > item?.targetCompletionInDays}
                      >
                        {item?.medianActualCompletionTimeInDays}
                      </TableDataConditional>
                      &nbsp;days
                    </TableDataStyle>
                    <TableDataStyle flexBasis={24}>
                      {item?.targetCompletionInDays} {item?.targetCompletionInDays > 1 ? 'days' : 'day'}
                    </TableDataStyle>
                    <TableDataStyle flexBasis={24} danger={+item?.dropOutRate > 0}>
                      {' '}
                      {item?.dropOutRate}%
                    </TableDataStyle>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="w-full">
                    <NoDataTextStyle>Choose a phase to the left</NoDataTextStyle>
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td className="w-full">
                  <NoDataTextStyle>There is no available step data for this template.</NoDataTextStyle>
                </td>
              </tr>
            )}
          </tbody>
        </TableStyle>
      )}
    </TableWrapperStyle>
  );
}
