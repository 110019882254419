import React, { useEffect, useCallback, useContext } from 'react';
import {
  DndContext,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import EditableField from 'components/CustomFields/Projects/EditableField';
import EditableSection from 'components/CustomFields/Projects/EditableSection';
import LoadingInline from 'components/Loading/LoadingInline';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { AccountBodyContainer } from '../Styles';
import { useProjectsDnd } from '../hooks';
import { useCustomerCanvas } from 'views/CustomerCanvas/hooks/useCustomerCanvas';
import { previewData } from 'views/CustomerCanvas/fixtures/previewData';
import { FormikContext } from 'formik';
import MoreInfoModal from 'components/Modals/Touchpoint/MoreInformation';
import { DEFAULT } from 'constants';
import { useLocation } from 'react-router-dom';

const Projects = () => {
  const {
    collisionDetectionStrategy,
    containers,
    coordinateGetter,
    handleDragCancel,
    handleDragEnd,
    handleDragOver,
    handleStart,
    items,
    recentlyMovedToNewContainer,
  } = useProjectsDnd();

  const { isLoading, isPreviewMode, setMode } = useContext(CustomFieldsContext);

  const { formikBag } = useCustomerCanvas(previewData);
  const location = useLocation();

  // DND sensors
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items, recentlyMovedToNewContainer]);

  const renderSection = useCallback(
    (containerId, index) => {
      const _items = Object.keys(items).length > 0 ? items[containerId]?.fields : null;

      return (
        <EditableSection
          isUndraggable
          showSectionText={index === 0}
          columnCount={1}
          key={containerId}
          id={containerId}
          items={_items}
        >
          <SortableContext items={_items} strategy={verticalListSortingStrategy}>
            {_items.map((value, index) => (
              <EditableField key={value} id={value} index={index} />
            ))}
          </SortableContext>
        </EditableSection>
      );
    },
    [items]
  );

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={collisionDetectionStrategy}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
        onDragStart={handleStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <AccountBodyContainer>
          <SortableContext items={containers} strategy={verticalListSortingStrategy}>
            {isLoading ? (
              <LoadingInline />
            ) : (
              containers.map((containerId, index) => {
                return renderSection(containerId, index);
              })
            )}
          </SortableContext>
        </AccountBodyContainer>
      </DndContext>

      <FormikContext.Provider value={formikBag}>
        <MoreInfoModal
          show={isPreviewMode && location.hash === '#projects'}
          onHide={() => setMode(DEFAULT)}
          isPreviewMode
        />
      </FormikContext.Provider>
    </>
  );
};

export default Projects;
