import * as Yup from 'yup';
import { arrayFieldsSchema, selectSchema } from 'helpers/schemaHelper';
import { ERROR_MESSAGES } from 'constants';

export const addUserSchema = Yup.object().shape({
  defaultFields: arrayFieldsSchema,

  customFields: arrayFieldsSchema,

  role: selectSchema.required(ERROR_MESSAGES.REQUIRED),

  isDefaultOwner: Yup.boolean(),
});
