import * as Api from 'api/Api';

class CanvasSettings {
  constructor(props) {
    if (props) Object.assign(this, props);
  }

  static async unpublish(id) {
    var response = await Api.post(`Canvas/UnPublishCanvas?canvasId=${id}`, null, {
      rawResponse: true,
    });
    return response;
  }

  static async publish(id) {
    var response = await Api.post(`Canvas/PublishCanvas?canvasId=${id}`);
    return response;
  }

  static async save(id, data) {
    var response = await Api.post(`Canvas/EditCanvas?canvasId=${id}`, data);
    return response;
  }

  static async changeCustomerCanvasSettingParam(field, value, id) {

    const patchItems = [
      {
        "op": "replace",
        "path": `/${field}`,
        "value": value
      }
    ];

    //Handle the field name difference between request & response for customer canvas name. Our request uses name while response uses canvasName
    const responseFieldName = field === 'name' ? 'canvasName' : field;
    const { [responseFieldName]: newValue } = await Api.patch(
      'Customer/PartialCustomerCanvasUpdate', patchItems,
      { params: { customerCanvasId: id } }
    );

    return newValue;
  }

  static async changeCanvasSettingParam(field, value, id) {

    const patchItems = [
      {
        "op": "replace",
        "path": `/${field}`,
        "value": value
      }
    ];

    const { [field]: newValue } = await Api.patch(
      'Canvas/PartialCanvasUpdate', patchItems,
      { params: { canvasId: id } }
    );

    return newValue;
  }
}

export default CanvasSettings;
