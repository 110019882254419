import { useContext, useState } from 'react';
import { faEye, faEyeSlash, faCopy, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import makeLogoImage from '../../img/make_logo.svg';
import zapierLogoImage from '../../img/zapier_logo.svg';
import toast from 'components/toast';
import { Form, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import SettingsContext from 'stores/Settings/settingsContext';
import {
  CustomFieldScreenText,
  CustomFieldsHelperText,
  DescriptiveHelperText,
  FormApiToken,
  IconAppend,
  IconContainer,
  IntegrationCol,
  LastUsedText,
  TokenButton,
  TokenContainer,
  TokenHelperText,
} from './styles';
import { TextButton } from 'views/Customer/Styles';
import ModalConfirm from 'components/Modals/Confirm';
import InfoTextBox from 'components/Modals/Shared/InfoTextBox';

const Integrations = ({
  appSettings,
  isAtleastAdmin,
  setTokenForDelete,
  setIntegrationState,
  handleLoadBillingTab,
}) => {
  const {
    apiTokenList,
    showApiToken,
    onAddApiToken,
    isGenerateBtnDisabled,
    onToggleLastUsed,
    isApiTokenEmpty,
  } = useContext(SettingsContext);

  const history = useNavigate();

  const [trialModal, setTrialModal] = useState(false);

  const trialProps = {
    title: 'Upgrade to Access',
    confirmText: 'Manage Subscription',
    variant: 'primary',
    messageAlign: 'left',
    innerProps: {
      maxWidth: '500px',
      padding: '70px 120px 40px',
    },
    buttonsContainerProps: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  return (
    <>
      <ModalConfirm
        show={trialModal}
        message={
          <>
            <p>
              Enable Integrations
              <ul>
                <li>Automatically sync data to and from your CRM</li>
                <li>View and edit CRM object fields in Stagebase</li>
                <li>Access the public API to get execution data</li>
              </ul>
            </p>
            <p>Upgrade your subscription to access this feature.</p>
          </>
        }
        iautoButtonsWidth
        onConfirm={() => {
          history('#billing');
          setTrialModal(false);
          handleLoadBillingTab();
        }}
        onHide={() => setTrialModal(false)}
        {...trialProps}
      />
      <IntegrationCol md={8} lg={8}>
        <>
          <h2 className="mb-3 top">Integrations</h2>

          <div className="d-flex mt-4">
            <DescriptiveHelperText>
              <span className="mr-3">
                Configure and manage automated data integrations with the no-code platform Zapier.com.
              </span>
            </DescriptiveHelperText>
            <InfoTextBox isInfoHoverable width="26.375rem" top="1rem" left="-3.188rem">
              Zapier.com is a powerful platform that allows you to automate your workflows and integrate your
              favorite apps with Stagebase. With Zapier, you can easily connect your apps to Stagebase and
              create custom workflows without any coding skills required. Whether you're looking to streamline
              your workflow or automate tedious tasks, Zapier has you covered. Plus, with its intuitive
              interface and extensive library of pre-built integrations, you can get started in no time. So,
              if you're looking for a simple and efficient way to automate your workflow and integrate your
              apps with Stagebase, Zapier.com is definitely worth checking out.
            </InfoTextBox>
          </div>
          <div className="settings-row py-3 mb-0 square border-bottom">
            <img
              src={zapierLogoImage}
              alt="Logo for Zapier.com"
              onClick={() => window.open('https://zapier.com', '_blank')}
            />
            <TextButton
              color="var(--schedule-sapphire)"
              onClick={() =>
                window.open(
                  'https://zapier.com/developer/public-invite/179909/7e68aadb35c05bb346b56106aeaf3a55/',
                  '_blank'
                )
              }
            >
              View and install
            </TextButton>
          </div>
          <div className="d-flex mt-4">
            <DescriptiveHelperText>
              <span className="mr-3">
                Configure and manage automated data integrations with the no-code platform Make.com.
              </span>
            </DescriptiveHelperText>
            <InfoTextBox isInfoHoverable width="26.375rem" top="1rem" left="-3.188rem">
              Using Make.com is the easiest and fastest way to get your data integrated with Stagebase.
              Standard Make blueprints are available to get scenarios created quickly. Make provides free and
              low-cost options depending on your scenario and execution needs.
            </InfoTextBox>
          </div>
          <div className="settings-row py-3 mb-0 square border-bottom">
            <img
              src={makeLogoImage}
              alt="Logo for Make.com formerly Integromat"
              onClick={() => window.open('https://make.com', '_blank')}
            />
            <TextButton
              color="var(--schedule-sapphire)"
              onClick={() =>
                window.open('https://us1.make.com/app/invite/15dbf3f688dd489993cbeeb558ae16f5', '_blank')
              }
            >
              View and install
            </TextButton>
            <TextButton
              color="var(--schedule-sapphire)"
              onClick={() =>
                window.open('https://stagebase-img.s3.amazonaws.com/resources/blueprints.zip', '_blank')
              }
            >
              Download blueprints
            </TextButton>
          </div>
          <DescriptiveHelperText className="mt-4">
            Manage data integrations directly using the Stagebase public API.
          </DescriptiveHelperText>
          <TextButton
            color="var(--schedule-sapphire)"
            onClick={() => window.open('https://developers.stagebase.com', '_blank')}
          >
            View API documentation
          </TextButton>
        </>

        {isAtleastAdmin && (
          <>
            <h2 className="settings-section-title mt-4">API token</h2>
            {apiTokenList &&
              apiTokenList.map((token, index) => (
                <TokenContainer key={index}>
                  <div className="d-flex align-items-center">
                    <Form.Group className="mr-2 mb-0">
                      <InputGroup>
                        <FormApiToken
                          type={token.isTokenShown ? 'text' : 'password'}
                          value={token.token}
                          disabled
                        />

                        <IconAppend type="button" onClick={() => showApiToken(index)}>
                          <FontAwesomeIcon icon={token.isTokenShown ? faEye : faEyeSlash} />
                        </IconAppend>
                      </InputGroup>
                    </Form.Group>

                    <IconContainer>
                      <FontAwesomeIcon
                        icon={faCopy}
                        onClick={() => {
                          onToggleLastUsed(token.id);
                          navigator.clipboard.writeText(token.token);
                          toast.success('API token copied');
                        }}
                        className="clickable"
                      />

                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => setTokenForDelete(token.id)}
                        className="clickable"
                      />
                    </IconContainer>
                  </div>

                  <LastUsedText>Last used: {!token.lastUsed ? 'never' : `${token.lastUsed}`}</LastUsedText>
                </TokenContainer>
              ))}

            <TokenButton
              className={!isGenerateBtnDisabled && 'mb-3'}
              onClick={() =>
                appSettings?.upsellTeams || appSettings?.upsellIntegrations
                  ? setTrialModal(true)
                  : onAddApiToken()
              }
              variant="secondary"
              disabled={isGenerateBtnDisabled}
            >
              {isApiTokenEmpty ? 'Generate Token' : 'Generate New Token'}
            </TokenButton>

            {isGenerateBtnDisabled && (
              <TokenHelperText>Generating tokens will be limited to three at a time.</TokenHelperText>
            )}
            <h2 className="settings-section-title custom-fields">Edit fields</h2>
            <CustomFieldsHelperText>
              Edit the information you would like to track per account and profile.
            </CustomFieldsHelperText>

            <CustomFieldScreenText className="square border-bottom">
              <span className="align-self-center">Account </span>

              <Link to="/accounts/custom-fields/edit-account-fields">Edit fields</Link>
            </CustomFieldScreenText>
            <CustomFieldScreenText className="square border-bottom">
              <span className="align-self-center">Contacts</span>
              <Link className="d-inline-flex" to="/accounts/custom-fields/edit-account-fields#contacts">
                Edit fields
              </Link>
            </CustomFieldScreenText>
            <CustomFieldScreenText className="square border-bottom">
              <span className="align-self-center">Profile</span>
              <Link className="d-inline-flex" to="/profile/custom-fields/edit-profile-fields">
                Edit fields
              </Link>
            </CustomFieldScreenText>
            <CustomFieldScreenText>
              <span className="align-self-center">Projects</span>
              <Link className="d-inline-flex" to="/accounts/custom-fields/edit-account-fields#projects">
                Edit fields
              </Link>
            </CustomFieldScreenText>
          </>
        )}
      </IntegrationCol>
    </>
  );
};

export default Integrations;
