import React from 'react';
import Select from '../../../../components/Select';
import InputContainer from './ActionInputContainer';
import ActionContainer from './ActionContainer';
import { getCustomerTouchpointStatusList } from '../../../../helpers/CommonHelper';
import useTouchpoints from '../../../../helpers/hooks/useTouchpoints';
import { RULES_FORM_FIELDS, RULE_ACTIONS } from '../../../../constants/automationRules';
import ErrorText from '../ErrorText';

/**
 * Renders action to set a type the account created/updated
 * @property {object} props.action Action object type
 * @property {object} props.errors Errors in the form
 * @property {object} props.touched Indicates if elements in the form were touched
 * @property {object} props.formValues All values from automation rule form
 * @property {object} props.onActionInputChange callback to execute when input changes
 * @returns {JSX.Element}
 */
const SetCustomerTouchpointStatusAction = ({
  action = {},
  errors = {},
  touched = {},
  formValues = {},
  onActionInputChange = () => {},
}) => {
  const actions = formValues[RULES_FORM_FIELDS.actions];
  const createCanvasAction = actions.find((action) => action.actionType === RULE_ACTIONS.AssignCanvas) || {};
  const canvasId = createCanvasAction?.actionInput?.canvasId;

  const actionInput = action?.actionInput || {};
  const { customerTouchpointStatus, customerTouchpointId } = actionInput;

  const { touchpointsOptions } = useTouchpoints(canvasId);
  const touchpointStatusList = getCustomerTouchpointStatusList();
  const statusOptions = touchpointStatusList?.map((touchpoint) => ({
    value: touchpoint.value,
    label: touchpoint.title,
  }));

  let selectedTouchpoint = null;
  touchpointsOptions?.forEach((group) => {
    const selected = group?.options?.find((opt) => opt.value === customerTouchpointId);
    if (selected?.value) {
      selectedTouchpoint = selected;
    }
  });

  const SET_CUSTOMER_TOUCHPOINT_STATUS_TEMPLATE = {
    customerTouchpointId: 0,
    customerTouchpointStatus: '',
  };

  /**
   * Function to handle input change
   * @param {string} field object property to update when the input changes
   * @returns {void}
   */
  const handleActionChange = (field) => (opt) => {
    const newInput = {
      ...SET_CUSTOMER_TOUCHPOINT_STATUS_TEMPLATE,
      ...actionInput,
      [field]: opt.value,
    };
    onActionInputChange(newInput);
  };

  return (
    <ActionContainer>
      <span className="action-label">Set step</span>
      <InputContainer>
        <Select
          placeholder="Select step..."
          options={touchpointsOptions}
          onChange={handleActionChange('customerTouchpointId')}
          value={selectedTouchpoint}
          containerWidth={250}
        />
        {touched.actionInput && errors.customerTouchpointId ? (
          <ErrorText>{errors.customerTouchpointId}</ErrorText>
        ) : null}
      </InputContainer>
      <span className="action-label">status to</span>
      <InputContainer>
        <Select
          placeholder="Select status..."
          options={statusOptions}
          value={statusOptions.find((type) => type.value === customerTouchpointStatus)}
          onChange={handleActionChange('customerTouchpointStatus')}
        />
        {touched.actionInput && errors.customerTouchpointStatus ? (
          <ErrorText>{errors.customerTouchpointStatus}</ErrorText>
        ) : null}
      </InputContainer>
    </ActionContainer>
  );
};

export default SetCustomerTouchpointStatusAction;
