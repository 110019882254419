import React, { useEffect, useState, useCallback } from 'react';
import FullScreenWithBackground from '../components/FullScreenWithBackground';
import { Link, useLocation } from 'react-router-dom';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import { SignUp } from 'models';

const ConfirmAccount = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmEmail = useCallback(async () => {
    const email = params.get('email');
    const code = params.get('code');

    setIsLoading(true);

    const response = await SignUp.confirmAccount(email, code);

    if (!response?.errorMessage) setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleConfirmEmail();
  }, [handleConfirmEmail]);

  if (isLoading) {
    return (
      <FullScreenWithBackground
        titleClassName="fs-5 mt-5 pt-3 font-weight-bold"
        title="Confirming your Account"
      >
        <div className="text-center">
          <div className="mt-1 mb-3">This should take less than a minute</div>
          <LoadingSpinner white className="text-primary fs-4" />
        </div>
      </FullScreenWithBackground>
    );
  }

  return (
    <FullScreenWithBackground>
      <h3>Account confirmed successfully!</h3>
      <p>You may now login with your confirmed account.</p>
      <Link to="/login" className="d-inline-block mt-4">
        Log in
      </Link>
    </FullScreenWithBackground>
  );
};

export default ConfirmAccount;
