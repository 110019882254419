import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import TableHeader from '../../components/TableHeader';
import AutomationRuleForm from '../../components/Modals/GlobalAutomationRules/AutomationRuleForm';
import toast from '../../components/toast';
import LoadingInline from '../../components/Loading/LoadingInline';
import ModalConfirm from '../../components/Modals/Confirm';
import * as AutomationApi from '../../api/automationRules';
import useFetchAutomationRuleDetails from '../../helpers/hooks/useFetchAutomationRuleDetails';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { AutomationContainer, FilterButton, HeaderColumn, LoadingContainer, TableContainer } from './Styles';
import Rule from './Rule';
import { noop } from 'helpers/CommonHelper';
import { CheckMark, DropdownMenu } from 'views/Customers/Styles';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

const GlobalAutomationRules = ({ upsellTeams, upsellIntegrations }) => {
  const history = useNavigate();
  const [showAddRuleModal, setShowAddRuleModal] = useState(false);
  const [itemForDelete, setItemForDelete] = useState(null);
  const [trialModal, setTrialModal] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [filteredTypes, setFilteredTypes] = useState(() => {
    const cachedFilterTypes = localStorage.getItem('filteredTypes');
    return cachedFilterTypes ? cachedFilterTypes.split(',') : [];
  });
  const [automationRules, setAutomationRules] = useState([]);
  const [filteredRules, setFilteredRules] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    fetchAutomationRuleDetails,
    setRule,
    rule,
    loading: loadingDetails,
  } = useFetchAutomationRuleDetails();

  const fetchAutomationRules = useCallback(async () => {
    setLoading(true);
    try {
      var response = await AutomationApi.fetchAutomationRules();
      setAutomationRules(response.value);
    } catch (err) {
      toast.error("Coudn't load automation rules");
    }
    setLoading(false);
  }, []);

  const handleDelete = async (ruleId) => {
    if (!ruleId) {
      return;
    }

    try {
      await AutomationApi.deleteAutomationRule(ruleId);
      fetchAutomationRules();
    } catch (err) {
      console.log(err);
      toast.error('There was an error deleting the rule');
    } finally {
      setItemForDelete(null);
    }
  };

  const handleClearFilter = () => {
    setFilteredRules([]);
    setFilteredTypes([]);
    localStorage.setItem('filteredTypes', []);
  };

  const handleRuleStatusUpdate = async (rule, isActive) => {
    if (!rule.id) {
      return;
    }

    const confirmStatusText = isActive ? 'is live' : 'was turned off';

    try {
      await AutomationApi.updateAutomationRuleStatus(rule.id, isActive);
      toast.success(`Rule "${rule.name}" ${confirmStatusText}`);
    } catch (err) {
      console.log(err);
      toast.error('There was an error updating this rule');
    }
  };

  const closeModal = () => {
    setShowAddRuleModal(false);
    setRule({});
  };

  const openRuleDetails = (rule) => {
    fetchAutomationRuleDetails(rule.id);
    setShowAddRuleModal(true);
  };

  const mappedTriggerType = useMemo(
    () => ({
      AccountCreation: 'Account Creation',
      AccountUpdate: 'Account Update',
      ContactCreation: 'Contact Creation',
      ContactUpdated: 'Contact Updated',
      CanvasCreation: 'Canvas Creation',
      CanvasStatusChange: 'Canvas Status Change',
    }),
    []
  );

  useEffect(() => {
    fetchAutomationRules();
  }, [fetchAutomationRules]);

  useEffect(() => {
    const filteredRules = automationRules.filter(({ triggerType }) =>
      filteredTypes.includes(mappedTriggerType[triggerType])
    );

    setFilteredRules(filteredRules);

    localStorage.setItem('filteredTypes', filteredTypes);
  }, [automationRules, filteredTypes, mappedTriggerType]);

  const toggleFilterStatus = (event) => {
    setFilteredTypes((prev) => {
      const filteredData = prev.includes(event.target.innerText)
        ? prev.filter((item) => item !== event.target.innerText)
        : [...prev, event.target.innerText];

      return filteredData;
    });
  };

  const trialModalProps = {
    title: 'Upgrade to Access',
    confirmText: 'Manage Subscription',
    variant: 'primary',
    show: trialModal,
    message: (
      <>
        <p>
          Create rules to automate basic tasks when data is imported to Stagebase.
          <ul>
            <li>Create a new project</li>
            <li>Make ownership assignments</li>
            <li>Add contacts to projects</li>
            <li>And more...</li>
          </ul>
        </p>
        <p>Upgrade your subscription to access this feature.</p>
      </>
    ),
    messageAlign: 'left',
    innerProps: {
      maxWidth: '500px',
      padding: '70px 120px 40px',
    },
    buttonsContainerProps: {
      display: 'flex',
      justifyContent: 'center',
    },
    autoButtonsWidth: true,
    onConfirm: () => history('/settings#billing'),
    onHide: () => setTrialModal(false),
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const updateFieldOrder = useCallback(async (id, order) => {
    await AutomationApi.reorderRule(id, order + 1);
  }, []);

  /**
   * Drag handler for the Field Values
   * @param event
   * @param move
   * @returns void
   */
  const handleDragEnd = useCallback(
    async (event) => {
      const { active, over } = event;

      if (active.id !== over.id) {
        const newFieldIndex = automationRules.findIndex((element) => element.id === over.id);

        updateFieldOrder(active.id, newFieldIndex);

        setAutomationRules((items) => {
          const oldIndex = items.findIndex((element) => element.id === active.id);
          const newIndex = items.findIndex((element) => element.id === over.id);

          return arrayMove(items, oldIndex, newIndex);
        });
      }
    },
    [automationRules, updateFieldOrder]
  );

  const memoizedTypes = useMemo(
    () => [...new Set(automationRules?.map(({ triggerType }) => triggerType))],
    [automationRules]
  );

  const memoizedRules = useMemo(
    () => (filteredRules.length > 0 ? filteredRules : automationRules),
    [automationRules, filteredRules]
  );

  return (
    <AutomationContainer>
      <AutomationRuleForm
        show={showAddRuleModal}
        onHide={closeModal}
        rule={rule}
        loading={loadingDetails}
        fetchAutomationRules={fetchAutomationRules}
      />
      <PageHeader
        title="Automation Rules"
        subRow={<span className="automation-subheader">Rules will run in the order they are listed. </span>}
      >
        <DropdownPersist
          title="Dropdown"
          showFilterHandler={() => setFilterShow((prev) => !prev)}
          defaultFilter={noop}
        >
          <Dropdown.Toggle variant="light" className="filter">
            Filter {filteredTypes.length > 0 && <>({filteredTypes.length})</>}
          </Dropdown.Toggle>
          <DropdownMenu align="center">
            <Dropdown.Header className="filter-header mt-2-5">TYPE</Dropdown.Header>

            {filterShow &&
              memoizedTypes?.map((triggerType) => (
                <Dropdown.Item
                  className="pl-48 py-2"
                  key={triggerType}
                  eventKey={'type' + triggerType}
                  onClick={toggleFilterStatus}
                >
                  <div className="d-flex align-items-center">
                    {filteredTypes.includes(mappedTriggerType[triggerType]) && <CheckMark icon={faCheck} />}
                    {mappedTriggerType[triggerType]}
                  </div>
                </Dropdown.Item>
              ))}

            <Dropdown.Divider className="mx-4" />
            <div className="d-flex">
              <FilterButton
                disabled={!filteredTypes?.length}
                filterHolder={filteredTypes?.length > 0}
                onClick={handleClearFilter}
              >
                Clear All
              </FilterButton>
            </div>
          </DropdownMenu>
        </DropdownPersist>
        <Button
          variant="primary"
          className="ml-4"
          onClick={() => {
            upsellTeams || upsellIntegrations ? setTrialModal(true) : setShowAddRuleModal(true);
          }}
        >
          New Rule
        </Button>
      </PageHeader>
      <TableContainer>
        <TableHeader>
          <HeaderColumn md={4}>Name</HeaderColumn>
          <HeaderColumn md={2}>Status</HeaderColumn>
          <HeaderColumn md={2}>Last Run</HeaderColumn>
          <HeaderColumn md={3}>Type</HeaderColumn>
          <HeaderColumn></HeaderColumn>
        </TableHeader>
        {loading ? (
          <LoadingContainer>
            <LoadingInline white text="Loading automation rules..." />
          </LoadingContainer>
        ) : (
          <>
            <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
              <SortableContext items={memoizedRules} strategy={verticalListSortingStrategy}>
                {memoizedRules?.map((rule) => (
                  <Rule
                    key={rule.id}
                    rule={rule}
                    mappedTriggerType={mappedTriggerType}
                    openRuleDetails={openRuleDetails}
                    setItemForDelete={setItemForDelete}
                    handleRuleStatusUpdate={handleRuleStatusUpdate}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </>
        )}
      </TableContainer>
      <ModalConfirm
        title={`Delete Automation Rule`}
        contentProps={{
          message: 'Are you sure you want to delete this rule?',
        }}
        show={Boolean(itemForDelete)}
        onConfirm={() => handleDelete(itemForDelete)}
        onHide={() => setItemForDelete(null)}
      />
      <ModalConfirm {...trialModalProps} show={trialModal} />
    </AutomationContainer>
  );
};

const DropdownPersist = (props) => {
  const { defaultFilter, showFilterHandler, ...rest } = props;

  const [open, setOpen] = useState(false);

  const onToggle = (isOpen, _ev, metadata) => {
    if (metadata.source === 'click' || metadata.source === 'rootClose') {
      defaultFilter();
      showFilterHandler();
    }

    if (metadata.source === 'select') {
      setOpen(true);
      return;
    }

    setOpen(isOpen);
  };

  return <Dropdown show={open} onToggle={onToggle} alignRight {...rest}></Dropdown>;
};

export default GlobalAutomationRules;
