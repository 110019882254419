import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { accessToken } from 'helpers/CommonHelper';

/**
 * React Component to download an authenticated API file. Useful because anchor tags alone do not send token information.
 * @param props Object of component properties
 * @param props.url the api endpoint to dowload file from
 * @param props.children the link component to display
 * @returns ReactComponet
 */
const DownloadLink = ({ url, children, className }) => {
  const linkRef = useRef(null);

  useEffect(() => {
    const token = accessToken;
    linkRef.current.href = url + `&access_token=${token}`;
  }, [url]);

  return (
    <a
      className={classNames('btn-link', className)}
      ref={linkRef}
      href={url}
      onClick={() => linkRef.current.click()}
      download
    >
      {children}
    </a>
  );
};

export default DownloadLink;
