import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, post } from 'api/Api';
import EditNote from 'components/EditNote';
import LoadingInline from 'components/Loading/LoadingInline';
import ModalConfirm from 'components/Modals/Confirm/index';
import TableHeader from 'components/TableHeader';
import TableRow from 'components/TableRow';
import toast from 'components/toast';
import { debounce } from 'helpers/CommonHelper';
import { ADMIN } from 'constants';
import { formatDateTime, getDateFromServerString } from 'helpers/DateHelper';
import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import FullContext from 'stores/Full/fullContext';
import styled from 'styled-components';

const NameWrapper = styled.div`
  white-space: pre-wrap;
`;

function CreateNote({ customer, onCreated }) {
  const [text, setText] = useState('');
  const [creating, setCreating] = useState(false);
  const textInput = useRef(null);

  function handleTextChange(event) {
    setText(event.target.value);
  }

  useEffect(() => {
    // refocus in comment box after commenting
    if (!creating) {
      textInput.current?.focus();
    }
  }, [creating]);

  async function createNote() {
    if (!(text || '').trim()) return;

    setCreating(true);
    await post(
      'Customer/CreateNote',
      {
        text: text.trim(),
      },
      {
        params: {
          customerId: customer.id,
        },
      }
    );
    setText('');
    setCreating(false);
    onCreated();
    toast.saved('Note created successfully.');
  }

  return (
    <>
      <Form.Control
        as="textarea"
        className="mb-2-5"
        rows="3"
        value={text}
        placeholder="Add note"
        disabled={creating}
        onChange={handleTextChange}
        ref={textInput}
      />
      <div className="text-right mb-4">
        <Button disabled={!text.trim() || creating} variant="primary" onClick={createNote}>
          {creating ? <LoadingInline white text="Saving..." /> : 'Save'}
        </Button>
      </div>
    </>
  );
}

function Search({ onSearch }) {
  const [search, setSearch] = useState('');
  const debouncedSearch = useRef(debounce(onSearch, 300), []);

  function handleOnChangeSearch(e) {
    setSearch(e.target.value);
    debouncedSearch.current(e.target.value);
  }

  return (
    <Form.Control placeholder="Search" value={search} onChange={handleOnChangeSearch} className="mr-2-5" />
  );
}

function CustomerActivity({ customer, isNotesTabActive }) {
  const [notes, setNotes] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [toDeleteItem, setToDeleteItem] = useState(null);
  const [search, setSearch] = useState('');
  const { userData, roleAtleast } = useContext(FullContext);

  const loadNotes = useCallback(async () => {
    // const response = await get('Customer/GetNotes', { customerId: customer.id });
    const response = await get('Customer/GetNotesAndActivitiesByCustomer', { customerId: customer.id });
    setNotes(response);
  }, [customer.id]);

  useEffect(() => {
    isNotesTabActive && loadNotes();

    return () => {
      setNotes([]);
    };
  }, [loadNotes, isNotesTabActive]);

  const renderTimeDate = (dateString) => {
    const { time, date } = formatDateTime(getDateFromServerString(dateString));
    return (
      <span className="date">
        <span className="mr-1-5">{time}</span>
        {date}
      </span>
    );
  };

  async function deleteItem() {
    setDeleting(true);
    await post('Customer/DeleteNote', toDeleteItem.id);
    setNotes(notes.filter((item) => item.id !== toDeleteItem.id));
    setDeleting(false);
    setToDeleteItem(null);
    toast.saved('Note deleted successfully.');
  }

  function finishEdit() {
    setEditingItem(null);
  }

  function handleNoteCreated() {
    loadNotes();
  }

  function getFilteredNotes() {
    const searchTrimmed = search.trim().toLowerCase();
    if (searchTrimmed) {
      return notes.filter(
        (x) =>
          (!x.customerCanvasId && 'general'.includes(searchTrimmed)) ||
          (x.canvasName || '').toLowerCase().includes(searchTrimmed) ||
          (x.touchpointName || '').toLowerCase().includes(searchTrimmed) ||
          (x.userFirstName || '').toLowerCase().includes(searchTrimmed) ||
          (x.userLastName || '').toLowerCase().includes(searchTrimmed) ||
          (x.text || '').toLowerCase().includes(searchTrimmed)
      );
    }
    return notes;
  }

  function getNoteProjectTitle(canvasNote, note) {
    if (note.noteAction === 'Pausing') return 'Paused';

    if (note.noteAction === 'Unpausing') return 'Unpaused';

    return canvasNote ? note.canvasName : 'General';
  }

  const noteList = () => {
    if (!notes.length) return <div className="small-info">No notes</div>;
    var myId = parseInt(userData?.id);
    return (
      <>
        <Search onSearch={(q) => setSearch(q)} />
        <br />
        <TableHeader>
          <Col md={2}>Project</Col>
          <Col md={2}>Step</Col>
          <Col md={6}>Note</Col>
          <Col md={2} className="text-md-right">
            Time / Date
          </Col>
        </TableHeader>

        {getFilteredNotes().map((note) => {
          if (editingItem === note) {
            return (
              <div className="border-top py-4">
                <EditNote key={note.id} note={note} originalText={note.text} finishEdit={finishEdit} />
              </div>
            );
          }
          const canvasNote = !!note.customerCanvasId;
          const canEditAndDelete = !note.isActivity && (myId === note.userId || roleAtleast(ADMIN));
          return (
            <TableRow
              to={
                canvasNote
                  ? `/accounts/${customer.id}/projects/${note.customerCanvasId}/steps/${note.customerTouchpointId}#activity`
                  : undefined
              }
              key={note.id}
              className="align-top"
            >
              <Col md={2} className={canvasNote && !note.isActivity ? '' : 'color-completed'}>
                {getNoteProjectTitle(canvasNote, note)}
              </Col>
              <Col md={2} className="text-anchor">
                {canvasNote ? note.touchpointName : '-'}
              </Col>
              <Col md={6}>
                {note.isActivity ? (
                  <div
                    className="color-completed"
                    dangerouslySetInnerHTML={{
                      __html: note.text ? note.text.replace('color: blue', '') : '',
                    }}
                  ></div>
                ) : (
                  <NameWrapper>
                    <span className="bold">
                      {note.userFirstName} {note.userLastName}{' '}
                    </span>{' '}
                    {note.text}
                  </NameWrapper>
                )}
              </Col>
              <Col md={2} className="text-md-right">
                {renderTimeDate(note.dateCreated)}
                {canEditAndDelete && (
                  <>
                    <div className="small-info">
                      <>
                        <FontAwesomeIcon
                          icon={faPencil}
                          className="light mr-3"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setEditingItem(note);
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setToDeleteItem(note);
                          }}
                        />
                      </>
                    </div>
                  </>
                )}
              </Col>
            </TableRow>
          );
        })}
      </>
    );
  };

  return (
    <>
      <CreateNote customer={customer} onCreated={handleNoteCreated} />
      {notes ? noteList() : <LoadingInline />}
      <ModalConfirm
        title={'Delete note'}
        message={
          <>
            Are you sure you want to delete this note?
            <br />
            This action cannot be undone.
          </>
        }
        show={toDeleteItem !== null}
        onConfirm={deleteItem}
        onHide={() => {
          setToDeleteItem(null);
        }}
        confirmLoading={deleting}
      />
    </>
  );
}

export default CustomerActivity;
