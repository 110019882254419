import React, { forwardRef, useEffect, useContext } from 'react';
import { FormikContext } from 'formik';
import { Tab, Row, Col, Nav, Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useLocation } from 'react-router-dom';
import { capitalizeFirstLetter } from 'helpers/CommonHelper';
import PageHeader from 'components/PageHeader';
import FieldModal from 'components/Modals/CustomFields/Field';
import SectionModal from 'components/Modals/CustomFields/Section';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import FullContext from 'stores/Full/fullContext';
import { CUSTOM_FIELDS_MODAL_VARIATIONS, DEFAULT, PREVIEW } from 'constants';
import AlertDialog from 'components/Modals/Shared/AlertDialog';
import { useAccountField, useAccountSection } from './hooks';
import { AddFieldsButton, TabButtonContainer, TabSelector } from './Styles';
import Details from './tabs/Details';
import Contacts from './tabs/Contacts';
import { OBJECT_TYPE_ENUM } from 'constants';
import Projects from './tabs/Projects';

const Account = () => {
  const {
    getAllEnums,
    fetchInitialData,
    fetchInitialContactsData,
    fetchInitialProjectsData,
    getOwnerTypes,
    isChangesSaving,
    hasServerError,
    isPreviewMode,
    setMode,
  } = useContext(CustomFieldsContext);
  const { route } = useContext(FullContext);

  const location = useLocation();

  const {
    formikBag: fieldFormikBag,
    handleShow: onShowFieldModal,
    handleHide: onHideFieldModal,
    showAddFieldModal,
  } = useAccountField();

  const {
    formikBag: sectionFormikBag,
    showEditSectionModal,
    handleShow: onShowSectionModal,
    handleHide: onHideSectionModal,
  } = useAccountSection();

  const isDetailsTab = location.hash === '' || location.hash === '#details';
  const isContactsTab = location.hash.split('?')[0] === '#contacts';
  const isProjectsTab = location.hash.split('?')[0] === '#projects';

  useEffect(() => {
    if (isDetailsTab) {
      fetchInitialData();
      getOwnerTypes();
      getAllEnums(OBJECT_TYPE_ENUM.CUSTOMER);
      return;
    }

    if (isProjectsTab) {
      fetchInitialProjectsData();
      getAllEnums(OBJECT_TYPE_ENUM.PROJECTS);
      return;
    }

    if (isContactsTab) {
      fetchInitialContactsData();
      getAllEnums(OBJECT_TYPE_ENUM.CONTACTS);
      return;
    }
  }, [
    isDetailsTab,
    isContactsTab,
    fetchInitialProjectsData,
    fetchInitialData,
    fetchInitialContactsData,
    getAllEnums,
    getOwnerTypes,
    isProjectsTab,
  ]);

  useEffect(() => {
    setMode(DEFAULT);

    return () => {
      setMode(DEFAULT);
    };
  }, [location, setMode]);

  /**
   * Customizes the Toggle component
   * @param children
   * @param onClick
   * @param ref
   * @returns Custom Toggle Component
   */
  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <Button ref={ref} onClick={onClick} variant="primary" className="btn-add">
      <FontAwesomeIcon icon={faPlus} className="mr-1" /> {children}
    </Button>
  ));

  const getDefaultActiveKey = () => {
    if (isContactsTab) return '#contacts';
    if (isProjectsTab) return '#projects';

    return '#details';
  };

  /**
   * Renders the Custom Field Account Tabs
   * @param none
   * @returns Tabs Component
   */
  const renderTabs = () => (
    <Tab.Container defaultActiveKey={getDefaultActiveKey()}>
      <Col>
        <Row className="d-block">
          <Nav variant="tabs">
            <TabSelector>
              <Nav.Item>
                <Nav.Link eventKey="#details" href="#details">
                  Details
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="#contacts" href="#contacts">
                  Contacts
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="#projects" href="#projects">
                  Projects
                </Nav.Link>
              </Nav.Item>
            </TabSelector>

            {!isPreviewMode && (
              <TabButtonContainer>
                {!isChangesSaving && typeof isChangesSaving != 'boolean' ? null : Boolean(isChangesSaving) ? (
                  <span className="saving">Saving...</span>
                ) : Boolean(hasServerError) ? (
                  <span className="error">Unsaved changes</span>
                ) : (
                  <span>Changes auto-saved.</span>
                )}

                <Button onClick={() => setMode(PREVIEW)} variant="light" className="btn-preview">
                  Preview
                </Button>

                {isDetailsTab ? (
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}>Add items</Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={onShowFieldModal}>Add new field</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={onShowSectionModal}>Add new section</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <AddFieldsButton onClick={onShowFieldModal} variant="primary">
                    <FontAwesomeIcon icon={faPlus} className="mr-1" /> Add fields
                  </AddFieldsButton>
                )}
              </TabButtonContainer>
            )}
          </Nav>
        </Row>

        <Row className="d-block">
          <Tab.Content>
            <Tab.Pane eventKey="#details">
              <Details />
            </Tab.Pane>
          </Tab.Content>

          <Tab.Content>
            <Tab.Pane eventKey="#contacts">
              <Contacts />
            </Tab.Pane>
          </Tab.Content>

          <Tab.Content>
            <Tab.Pane eventKey="#projects">
              <Projects />
            </Tab.Pane>
          </Tab.Content>
        </Row>
      </Col>
    </Tab.Container>
  );

  const { from, to } = route;
  const splitFrom = from.split('/').filter((value) => !!value);
  const isFromAccountPage = splitFrom.length > 1;
  const isSameRoute = from === to;

  const headerRoute = {
    upName: isSameRoute ? 'Accounts' : isFromAccountPage ? 'Account' : capitalizeFirstLetter(splitFrom[0]),
    upPath: isSameRoute ? '/accounts' : from,
  };

  const pageHeaderProps = {
    title: 'Edit Account Fields',
    upName: headerRoute.upName,
    upPath: headerRoute.upPath,
  };

  const getVariation = () => {
    if (isProjectsTab) return CUSTOM_FIELDS_MODAL_VARIATIONS.PROJECTS;

    if (isContactsTab) return CUSTOM_FIELDS_MODAL_VARIATIONS.CONTACTS;

    return CUSTOM_FIELDS_MODAL_VARIATIONS.CUSTOMER;
  };

  return (
    <div id="edit-fields" className="pb-5">
      <PageHeader {...pageHeaderProps} />

      {renderTabs()}

      <FormikContext.Provider value={fieldFormikBag}>
        <FieldModal show={!!showAddFieldModal} onHide={onHideFieldModal} variation={getVariation()} />
      </FormikContext.Provider>

      <FormikContext.Provider value={sectionFormikBag}>
        <SectionModal show={!!showEditSectionModal} onHide={onHideSectionModal} />
      </FormikContext.Provider>

      <AlertDialog
        visible={isPreviewMode && !isProjectsTab}
        title="Preview Mode"
        info="This is an example of how the fields look like with placeholder data"
        btnLabel="Exit Preview"
        top="10%"
        hasBorder={false}
        maxWidth="46.875rem"
        onClick={() => setMode(DEFAULT)}
      />
    </div>
  );
};

export default Account;
