import React, { useMemo, useCallback } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import { sectionSchema } from 'views/CustomFields/Account/validations';
import { ErrorMessageContainer } from 'views/CustomFields/Account/Styles';
import { CUSTOM_FIELD_MODES } from 'constants';
import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
} from '../LargePadding';
import { FieldContainer } from './Styles';

const SectionModal = ({ show, onHide }) => {
  const { values, submitForm, errors } = useFormikContext();

  const isValid = sectionSchema.isValidSync(values);

  /**
   * Checks if the error is a required type
   * @param field
   * @returns boolean
   */
  const isRequiredError = useCallback((field) => errors[field]?.includes('required'), [errors]);

  /**
   * Renders the content for the modal
   * @param none
   * @returns Modal Content Component
   */
  const renderContent = useMemo(() => {
    const isEdit = values.sectionMode === CUSTOM_FIELD_MODES.EDIT;

    return (
      <div>
        <h1 className="mb-3 text-center">{isEdit ? 'Edit Section' : 'Add new section'}</h1>

        <FieldContainer>
          <Field name="sectionLabel">
            {({ form, meta, ...props }) => (
              <Form.Control
                type="text"
                value={meta.value}
                placeholder="Section label..."
                onChange={(event) => {
                  const { value } = event.target;

                  form.setFieldError('sectionLabel', '');
                  form.setFieldValue('sectionLabel', value);
                }}
                {...props}
              />
            )}
          </Field>

          {!isRequiredError('sectionLabel') && (
            <ErrorMessageContainer>
              <ErrorMessage name="sectionLabel" />
            </ErrorMessageContainer>
          )}
        </FieldContainer>
      </div>
    );
  }, [isRequiredError, values.sectionMode]);

  /**
   * Renders the buttons for the modal
   * @param none
   * @returns Button Component
   */
  const renderButtons = (
    <>
      <ModalLargePaddingCancelButton onClick={onHide} autoWidth />
      <ModalLargePaddingConfirmButton onClick={submitForm} disabled={!isValid} text="Save" autoWidth />
    </>
  );

  const modalStyle = {
    inner: {
      maxWidth: '35.25rem',
      padding: '2.5rem 1.25rem !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  return (
    <ModalLargePadding
      show={show}
      onHide={onHide}
      innerProps={modalStyle.inner}
      buttonsContainerProps={modalStyle.buttonContainer}
      content={renderContent}
      buttons={renderButtons}
    />
  );
};

export default SectionModal;
