import { faAngleDown, faBuilding, faSignOut, faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'helpers/RouteHelper';
import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FullContext from 'stores/Full/fullContext';
import * as CommonHelper from '../../helpers/CommonHelper';
import { Avatar } from '../Modals/Avatar';

class HeaderProfile extends Component {
  static contextType = FullContext;

  goToProfile = () => {
    this.props.router.navigate('/profile');
    this.toggle();
  };

  getGreetingTime() {
    var myDate = new Date();
    var hrs = myDate.getHours();
    var greet;

    if (hrs < 12) greet = 'Good morning';
    else if (hrs >= 12 && hrs <= 17) greet = 'Good afternoon';
    else if (hrs >= 17 && hrs <= 24) greet = 'Good evening';

    return greet;
  }

  getInitials(fullName) {
    if (!fullName) return;
    let splitted = fullName.split(' ');
    return splitted[0].charAt(0) + splitted[1].charAt(0);
  }

  render() {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <div ref={ref} onClick={onClick} className="cursor-pointer">
        {children}
      </div>
    ));
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <div className="d-flex align-items-center nav-link">
            <Avatar
              className="mr-0 mr-lg-3"
              avatar={this.props.avatar}
              fullName={this.context.userData.fullName}
            />
            {/* {this.props.avatar && this.props.avatar !== 'undefined' && this.props.avatar !== 'null'
              ? this.renderAvatarButton(this.props.avatar)
              : this.renderInitialButton(this.getInitials(localStorage.getItem('fullName')))} */}
            <div data-testid="greeting" className="d-none d-lg-block">
              {this.getGreetingTime()}, {this.context.userData.firstName}!
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu align="right">
          {this.props.activeOrganization && (
            <>
              <Dropdown.Item onClick={this.props.showOrganizations} className="d-block d-lg-none">
                <FontAwesomeIcon fixedWidth icon={faBuilding} />
                {this.props.activeOrganization.name}
                <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
              </Dropdown.Item>
              <Dropdown.Divider className="d-block d-lg-none" />
            </>
          )}
          <Dropdown.Item as={Link} to="/profile">
            <FontAwesomeIcon fixedWidth icon={faUserCircle} />
            Profile
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => CommonHelper.logOut(this.props)}>
            <FontAwesomeIcon fixedWidth icon={faSignOut} />
            Log out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default withRouter(HeaderProfile);
