import { useFormikContext } from "formik";
import { Form } from 'react-bootstrap';
import { noop } from "helpers/CommonHelper";
import { useEditableField } from "../hooks";
import { faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';
import { FieldCol, GrayIcon } from "./Styles";
import { PERMISSIONS } from "constants";


const PreviewMoreInfoFields = ({name, fieldIndex, isRequired}) => {
  const {values} = useFormikContext()
  const contactFields = values.contactFields;
  const {
    fieldLabel: title,
    targetFieldName: field,
    syncOption,
    fieldType: type,
  } = contactFields[fieldIndex];

  const isDisabled = syncOption === PERMISSIONS.READ_ONLY;
  const { renderField } = useEditableField({
    field: {
      fieldType: type,
    },
    name,
    disabled: true,
    onChange: noop,
  });
  return (
    <Form.Group controlId={`${field}`}>
      <Form.Label column sm={12}>
        {`${title} ${isRequired ? '*' : ''}`}
        { isDisabled && <GrayIcon icon={faLockKeyhole} className="ml-2 fa-sm" />}
      </Form.Label>
      <FieldCol isDisabled={isDisabled} sm={12}>{renderField()}</FieldCol>
    </Form.Group>
  );
}

export default PreviewMoreInfoFields;