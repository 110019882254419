import React from 'react';
import { useFormikContext, Field } from 'formik';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faTrash } from '@fortawesome/pro-solid-svg-icons';
import * as uuid from 'uuid';
import { FIELDS, PLACEHOLDERS, CUSTOM_FIELDS_MODAL_VARIATIONS } from 'constants';

const SortableItem = ({ id, index, push, remove, onFieldLoad, isVariation }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const { values } = useFormikContext();

  const isLastItem = index === values.fieldValues.length - 1;
  const isDraggable = values.fieldValues.length > 2;
  const showGrip = isDraggable && !isLastItem;

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : undefined,
  };

  /**
   * Renders the Grip Icon and checks if the
   * Grip is visible and if isSortedFieldValues
   * is enabled
   * @param none
   * @returns Icon Component
   */
  const renderGripIcon = () => {
    if (!showGrip) return null;

    return (
      <div {...attributes} {...listeners}>
        <FontAwesomeIcon icon={faGripVertical} />
      </div>
    );
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Field name={`fieldValues.${index}.value`}>
        {({ form, meta, ...props }) => (
          <div className="field-values__input">
            <InputGroup>
              <InputGroup.Prepend>{renderGripIcon()}</InputGroup.Prepend>

              <Form.Control
                type="text"
                value={meta.value}
                placeholder={PLACEHOLDERS.FIELD_VALUE}
                onChange={(event) => {
                  const { value } = event.target;
                  const nextElement = values.fieldValues[index + 1];

                  if (!nextElement) push({ id: uuid.v4(), value: '' });

                  form.setFieldValue(`fieldValues.${index}.value`, value);
                  form.setFieldValue(FIELDS.IS_SORTED_FIELD_VALUES, false);

                  isVariation(CUSTOM_FIELDS_MODAL_VARIATIONS.CUSTOMER) ||
                  isVariation(CUSTOM_FIELDS_MODAL_VARIATIONS.CONTACTS)
                    ? onFieldLoad(FIELDS.SHOW_SECTION)
                    : onFieldLoad(FIELDS.SHOW_CHECKBOXES);
                }}
                onBlur={() => {
                  form.setFieldTouched(`fieldValues.${index}.id`, true);
                }}
                {...props}
              />

              <InputGroup.Append>
                {!isLastItem && <FontAwesomeIcon icon={faTrash} onClick={() => remove(index)} />}
              </InputGroup.Append>
            </InputGroup>
          </div>
        )}
      </Field>
    </div>
  );
};

export default React.memo(SortableItem);
