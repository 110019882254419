import { useFormik } from 'formik';
import { useCallback, useContext, useMemo, useState } from 'react';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { getFieldType } from 'components/CustomFields/Details/fixtures';
import { camelize } from 'helpers/CommonHelper';
import { Customers } from 'models';
import { PARSED_FIELDS, CONTACTS_FIXED_FIELDS } from 'constants';
import toast from 'components/toast';
import { useParser } from 'views/Customers/hooks';
import FullContext from 'stores/Full/fullContext';
import { contactSchema } from 'views/CustomFields/Account/validations/contactSchema';

export const useContacts = () => {
  const { contacts } = useContext(CustomFieldsContext);
  const { isExternal } = useContext(FullContext);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const { handleParseRequestProperty, handleParseRequestValues, handleParseResponseValue } = useParser();

  /**
   * Setup the initial values of contact info
   * @returns {Number} id - contact ID
   * @returns {Array} fields - contains the fields of the contact
   */
  const contactFields = useMemo(() => {
    const fields = contacts.editableFields
      ?.map((field) => {
        const fieldType = getFieldType(field?.fieldType);
        const formikValue = null;
        const targetFieldName = camelize(field?.targetFieldName);
        const newFieldTypeOptions =
          field?.fieldTypeOptions && field?.fieldTypeOptions.length > 0
            ? field?.fieldTypeOptions?.map((value) => JSON.parse(value))
            : [];

        return {
          ...field,
          fieldType,
          formikValue,
          targetFieldName,
          fieldTypeOptions: newFieldTypeOptions,
        };
      })
      .filter(({ fieldLabel }) => fieldLabel !== 'Last activity');

    return isExternal ? fields.filter(({ isCustomField }) => !isCustomField) : fields;
  }, [contacts.editableFields, isExternal]);

  /**
   * Submit handler for Formik's onSubmit instance
   * endpoint and dynamic values will be properly
   * implemented once submit endpoint will be
   * integrated
   * @param values
   * @returns an always true boolean value since all the
   * conditions in the validations were already met before this
   * handler will be called
   */
  const handleSubmit = async (values) => {
    const transformedValues = values.contactFields.reduce((result, current) => {
      return {
        ...result,
        [`${handleParseRequestProperty(current)}`]: handleParseRequestValues(current, null),
      };
    }, {});

    const request = { customerId, ...transformedValues };

    const response = await Customers.addContact(request);
    if (response && response?.errorMessage) {
      toast.error(response.errorMessage);
    } else {
      handleHide();
      return response;
    }
  };

  /**
   * Closes the add custom field modal and resets the formik instance
   * @param none
   * @returns void
   */
  const handleHide = () => {
    setShowAddContactModal(false);
    setTimeout(() => {
      formikBag.resetForm();
    }, 500);
  };

  /**
   * Add Custom Field Formik Instance
   */
  const formikBag = useFormik({
    initialValues: { contactFields },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: contactSchema,
    onSubmit: handleSubmit,
  });

  const handleShow = useCallback(
    (contact = null) => {
      setShowAddContactModal(true);

      if (!!contact) {
        const newContactFields = contacts.editableFields.map((value) => {
          const newFieldType = getFieldType(value?.fieldType);

          const newFieldTypeOptions =
            value.fieldTypeOptions && value.fieldTypeOptions.length > 0
              ? value.fieldTypeOptions?.map((item) => JSON.parse(item))
              : [];

          const targetFieldName =
            value.targetFieldName.toLowerCase() === CONTACTS_FIXED_FIELDS.PHONE.toLowerCase()
              ? camelize(CONTACTS_FIXED_FIELDS.PHONENUMBER)
              : camelize(value?.targetFieldName);

          const mappedFieldName = contact[value.targetFieldName] ?? contact[targetFieldName];
          const formikValue = handleParseResponseValue(value, mappedFieldName);
          return {
            ...value,
            formikValue,
            fieldType: newFieldType,
            fieldTypeOptions: newFieldTypeOptions,
          };
        });

        formikBag.setValues({
          contactFields: [...newContactFields],
        });
      } else {
        const createNewContactFields = contactFields.filter(
          (field) => field.targetFieldName !== PARSED_FIELDS.LAST_ACTIVITY
        );
        formikBag.setValues({
          contactFields: [...createNewContactFields],
        });
      }
    },
    [contactFields, contacts.editableFields, formikBag, handleParseResponseValue]
  );

  return {
    formikBag,
    showAddContactModal,
    handleShow,
    handleHide,
    setCustomerId,
  };
};
