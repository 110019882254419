import {
  faBell,
  faChartLine,
  faCog,
  faCogs,
  faEdit,
  faTachometerAltFast,
  faAddressBook,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import { ADMIN, CREATOR, FRONT_LINE, SUPERUSER } from 'constants';

export const navItems = {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: faTachometerAltFast,
    },
    {
      name: 'Accounts',
      url: '/accounts',
      icon: faAddressBook,
      roles: [SUPERUSER, ADMIN, CREATOR, FRONT_LINE],
    },
    {
      name: 'Notifications',
      url: '/notifications',
      icon: faBell,
      mobile: true,
      showUnreadNotificationCount: true,
    },
    // {
    //   name: 'Templates',
    //   roles: [SUPERUSER, ADMIN, CREATOR],
    // },
    {
      name: 'Templates',
      url: '/templates',
      icon: faEdit,
      roles: [SUPERUSER, ADMIN, CREATOR],
    },
    {
      name: 'Automation',
      url: '/automation',
      icon: faCogs,
      roles: [SUPERUSER, ADMIN, CREATOR],
    },
    {
      name: 'Reporting',
      url: '/reporting',
      icon: faChartLine,
      roles: [SUPERUSER, ADMIN, CREATOR],
    },
    // {
    //   name: 'Archive',
    //   url: '/archive',
    //   icon: faFileArchive,
    //   roles: [SUPERUSER, ADMIN, CREATOR],
    // },
    // {
    //   name: 'Account',
    //   roles: [SUPERUSER, ADMIN],
    // },
    // {
    //   name: 'Profile',
    //   url: '/profile',
    //   icon: faUserCircle,
    //   roles: [SUPERUSER, ADMIN, CREATOR, FRONT_LINE, EXTERNAL],
    // },
    {
      name: 'Settings',
      url: '/settings',
      icon: faCog,
      roles: [SUPERUSER, ADMIN, CREATOR, FRONT_LINE],
    },
    {
      name: 'Users',
      url: '/users',
      icon: faUsers,
      roles: [SUPERUSER, ADMIN],
    },
    // {
    //   name: 'Departments',
    //   url: '/departments',
    //   icon: faBriefcase,
    //   roles: [SUPERUSER, ADMIN],
    // },
    //{
    //  name: 'Groups',
    //  url: '/groups',
    //  icon: 'icon-people',
    //  roles: [SUPERUSER, ADMIN]
    //}
  ],
};
