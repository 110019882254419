import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import styled from 'styled-components';
import { Alert, Form, InputGroup } from 'react-bootstrap';
import * as Api from '../../api/Api';
import toast from '../toast';
import Checkbox from 'components/Checkbox/Checkbox';
import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
  ModalLargePaddingHeader,
} from './LargePadding';

export default function ModalNewTenant({ show, onHide }) {
  const newTenant = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
    userLimit: '',
    features: 'Legacy',
    overrideOneUser: false
  };
  const [saving, setSaving] = useState(false);
  const [tenant, setTenant] = useState(newTenant);
  const [error, setError] = useState(null);

  function inputValueChanged(event) {
    const target = event.target;
    setTenant((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));
  }

  async function handleSave(event) {
    event.preventDefault();
    tenant.company = tenant.company.trim();
    if (!tenant.company) {
      setError('The company name can not be blank.');
      return;
    }
    setSaving(true);
    const response = await Api.post('Account/CreateTenant', tenant, { rawResponse: true });
    setSaving(false);
    if (response.status === 200) {
      setTenant(newTenant);
      onHide();
      toast.saved('New tenant provisioned successfully.');
    } else if (response.status === 400) {
      response.text().then((data) => {
        toast.error(data);
      });
    }
    else if (response.status === 500) {
      toast.error('An unknown error has occurred.');
    }
  }

  const FormControl = styled(Form.Control)`
  width: initial;
`;

  return (
    <ModalLargePadding
      show={show}
      onHide={onHide}
      content={
        <form onSubmit={handleSave}>
          <ModalLargePaddingHeader icon={faBuilding} text="Provision new tenant" />
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group>
            <InputGroup>
              <Form.Control
                type="text"
                autoFocus
                required
                placeholder="First name"
                name="firstName"
                value={tenant.firstName}
                onChange={inputValueChanged}
              />
              <Form.Control
                type="text"
                required
                placeholder="Last name"
                name="lastName"
                value={tenant.lastName}
                onChange={inputValueChanged}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="email"
              required
              placeholder="Email"
              name="email"
              value={tenant.email}
              onChange={inputValueChanged}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Phone"
              name="phone"
              value={tenant.phone}
              onChange={inputValueChanged}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              required
              placeholder="Company"
              name="company"
              value={tenant.company}
              onChange={inputValueChanged}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="number"
              required
              min="1"
              placeholder="Max number of users (e.g. 10)"
              name="userLimit"
              value={tenant.userLimit}
              onChange={inputValueChanged}
            />
          </Form.Group>
          <Form.Group className="mr-4 mb-2">
            <Form.Label htmlFor="emailTrigger" className="settings-section-title mt-0 mr-2">
              Edition
            </Form.Label>
            <FormControl
              as="select"
              custom
              value={tenant.features}
              name="features"
              id="features"
              className="email-field custom-select"
              onChange={inputValueChanged}
            >
              <option value="Legacy">Legacy</option>
              <option value="Trial">Free Trial</option>
              <option value="Team">Team</option>
              <option value="Integrations">Team with Integrations</option>
            </FormControl>
          </Form.Group>
          <Checkbox
            checked={tenant.overrideOneUser}
            label='Override One Freemium User per Team'
            name='overrideOneUser'
            onChange={() => setTenant((prev) => ({ ...prev, overrideOneUser: !tenant.overrideOneUser }))}
          />
          <div className="buttons">
            <ModalLargePaddingCancelButton onClick={onHide} />
            <ModalLargePaddingConfirmButton
              type="submit"
              variant={'primary'}
              loading={saving}
              text={'Create'}
            />
          </div>
        </form>
      }
    />
  );
}
