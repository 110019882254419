import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0px;
  right: 20px;
  background: #f6f7f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  padding: 8px 5px 8px 12px;
`;

const Label = styled.span`
  margin-right: 10px;
  color: black;
`;

export default function MyTouchpointToggle({ state, onUpdated }) {
  function handleMyTouchpointToggle(event) {
    state.showMyTouchpoints = event.target.checked;
    onUpdated();
  }

  return (
    <>
      <Wrapper className="d-flex justify-content-end settings-section-title my-4 mx-3">
        <Label>My steps</Label>
        <Form.Check
          type="switch"
          id="mySteps"
          name="mySteps"
          checked={state.showMyTouchpoints}
          onChange={(e) => handleMyTouchpointToggle(e)}
        />
      </Wrapper>
    </>
  );
}
