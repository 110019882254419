import React, { useCallback, useMemo, useState } from 'react';
import { useField, ErrorMessage } from 'formik';
import { InputGroup as BaseInputGroup } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { ErrorMessageContainer } from 'views/CustomFields/Account/Styles';
import useUpdateEffect from 'helpers/hooks/useUpdateEffect';
import { FieldLabel } from 'components/Modals/Customers/Styles';
import { INPUT_TYPES } from 'constants';

const InputGroup = (
  {
    name,
    type = INPUT_TYPES.TEXT,
    prepend,
    handleChange,
    handleBlur,
    disabled,
    fieldLabel,
    isRequired,
    placeholder,
    ...props
  },
  ref
) => {
  const [, meta, helpers] = useField(name);

  const [value, setValue] = useState(meta.value || meta.initialValue);

  useUpdateEffect(() => {
    setValue(meta.value);
  }, [meta.value]);

  const hasError = useMemo(() => meta.touched && !!meta.error, [meta.error, meta.touched]);

  /**
   * On Input Change handler
   * @params event
   * @returns void
   */
  const onInputChange = useCallback(
    (event) => {
      const { value: inputValue } = event.target;

      setValue(inputValue);

      helpers.setError('');
      helpers.setValue(inputValue);

      if (handleChange) handleChange(inputValue);
    },
    [helpers, handleChange]
  );

  /**
   * On Input Focus handler
   * @returns void
   */
  const onInputBlur = useCallback(() => {
    helpers.setTouched(true);

    if (handleBlur) handleBlur();
    if(meta.touched) helpers.setTouched(false);
  }, [helpers, handleBlur, meta.touched]);

  /**
   * On Input Wheel handler
   * @returns void
   */
  const onInputWheel = (event) => type === INPUT_TYPES.NUMBER && event.target.blur();

  return (
    <div>
      {fieldLabel && (
        <FieldLabel>
          <span>{fieldLabel}</span>
          {isRequired && !hasError && <span>*</span>}
          {hasError && (
            <ErrorMessageContainer className="error-container">
              (<ErrorMessage name={name} />)
            </ErrorMessageContainer>
          )}
        </FieldLabel>
      )}

      <BaseInputGroup>
        <BaseInputGroup.Prepend>
          <BaseInputGroup.Text>{prepend}</BaseInputGroup.Text>
        </BaseInputGroup.Prepend>
        <Form.Control
          ref={ref}
          type={type}
          value={value ?? ''}
          readOnly={disabled}
          onChange={onInputChange}
          onBlur={onInputBlur}
          onWheel={onInputWheel}
          placeholder={meta.touched ? placeholder : ''}
          {...props}
        />
      </BaseInputGroup>
    </div>
  );
};

export default React.forwardRef(InputGroup);
