import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import LoadingInline from '../../../Loading/LoadingInline';
import { get } from 'api/Api';
import CanvasItem from '../../../CanvasItem';
import TableRow from 'components/TableRow';
import { mergeObjs } from 'helpers/CommonHelper';
import CustomerContext from 'stores/Customer/customerContext';
import { FormikContext, useFormik } from 'formik';
import AssignCanvasContent from './AssignCanvasContent';
import { SelectedCanvasContainer } from './Styles';
import { Customers } from 'models';
import toast from 'components/toast';
import { getFieldType } from 'components/CustomFields/Details/fixtures';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { useParser } from 'views/Customers/hooks';
import { addCanvasSchema } from 'views/Customer/validations';
import { PERMISSIONS } from 'constants';

const AssignCanvas = ({ show, onHide, customerId, setCustomer }) => {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  // Original Canvases Copy - not changed by user
  const [canvases, setCanvases] = useState([]);
  // Filtered by search from Canvases - not changed by user
  const [filteredCanvases, setFilteredCanvases] = useState([]);
  // Partial Copy(id, customerCanvasId, selected, startDate) of Canvases - changed by user
  const [modifiedCanvases, setModifiedCanvases] = useState({});
  const [search, setSearch] = useState('');
  const { populateSummaryData } = useContext(CustomerContext);

  useEffect(() => {
    const fetchCanvases = async () => {
      try {
        setLoading(true);
        const { items } = await get('Canvas/GetAll');
        const _modifiedCanvases = {};
        const _canvases = items.filter((item) => {
          if (item.canvasStatus === 'Live') {
            const { customerCanvasId = null, selected = null, startDate = null, id } = item;
            _modifiedCanvases[item.id] = {
              id,
              customerCanvasId,
              selected,
              startDate,
            };

            return true;
          }

          return false;
        });

        setCanvases(_canvases);
        setModifiedCanvases(_modifiedCanvases);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (show) {
      fetchCanvases();
      return () => {
        setCanvases([]);
      };
    }
  }, [show]);

  useEffect(() => {
    if (canvases && canvases.length && modifiedCanvases && Object.keys(modifiedCanvases).length) {
      const filtered = canvases.filter((canvas) => canvas.name.toLowerCase().includes(search));

      setFilteredCanvases(filtered);
    }
  }, [canvases, modifiedCanvases, search]);

  const updateModifiedCanvases = (id, objPropValueMap) => {
    setModifiedCanvases((prev) =>
      mergeObjs(prev, {
        [id]: mergeObjs(prev[id], objPropValueMap),
      })
    );
  };

  const selectCanvas = (id, selected, event) => {
    event.stopPropagation();
    updateModifiedCanvases(id, {
      selected,
      startDate: new Date(),
    });
  };

  const { projects } = useContext(CustomFieldsContext);

  const { handleParseRequestProperty, handleParseRequestValues } = useParser();

  const projectFields = projects?.editableFields
    .map((field) => {
      const newFieldTypeOptions =
        field?.fieldTypeOptions && field?.fieldTypeOptions.length > 0
          ? field?.fieldTypeOptions?.map((value) => JSON.parse(value))
          : [];

      return {
        ...field,
        fieldType: getFieldType(field.fieldType),
        fieldTypeOptions: newFieldTypeOptions,
        formikValue: null,
      };
    })
    .filter(
      ({ fieldLabel, syncOption }) => fieldLabel !== 'Last activity' && syncOption !== PERMISSIONS.READ_ONLY
    );

  const initialValues = { projectFields, selectedCanvas: null, startDate: null };

  /**
   * Submit handler for Formik's onSubmit instance
   * endpoint and dynamic values will be properly
   * implemented once submit endpoint will be
   * integrated
   * @param values
   * @returns void
   */
  const handleSubmit = async (values) => {
    const { projectFields, selectedCanvas, startDate } = values;

    const transformedValues = projectFields.reduce((result, current) => {
      return {
        ...result,
        [`${handleParseRequestProperty(current)}`]: handleParseRequestValues(current, null),
      };
    }, {});

    const request = {
      id: selectedCanvas.id,
      startDate,
      selected: true,
      ...transformedValues,
    };

    setSaving(true);

    const response = await Customers.assignCustomerCanvases(customerId, request);

    if (response?.errors) {
      const flattenedErrors = Object.values(response?.errors).flatMap((errors) => errors);
      toast.error(flattenedErrors.join(' '));
      return;
    }

    if (response && response.errorMessage) {
      toast.error(response.errorMessage);
      return;
    } else {
      toast.saved('Canvas created successfully!');
    }

    const resProjSummary = await get('Customer/GetCustomerProjectSummaries', { id: customerId });
    populateSummaryData(resProjSummary.reverse());
    onHide();
    setCustomer(response);
    setSaving(false);
  };

  /**
   * Add Custom Field Formik Instance
   */
  const formikBag = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: addCanvasSchema,
    onSubmit: handleSubmit,
  });

  const { values, setFieldValue } = formikBag;

  const { selectedCanvas } = values;

  const handleClickNext = (canvas) => {
    setFieldValue('selectedCanvas', canvas);
  };

  const isSelectedCanvasMode = !!selectedCanvas;

  return (
    <FormikContext.Provider value={formikBag}>
      <Modal show={show} onHide={onHide}>
        <Modal.Header>
          <h1>Assign a project</h1>
          <div>
            <Button variant="outline-light" onClick={onHide} className="mr-2-5">
              Cancel
            </Button>
            <Button disabled={saving || !selectedCanvas} variant="primary" onClick={formikBag.submitForm}>
              {saving ? <LoadingInline white text="Saving..." /> : 'Done'}
            </Button>
          </div>

          {!isSelectedCanvasMode ? (
            <Form.Control
              value={search}
              className="full-width-grid-item mt-3"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
            />
          ) : (
            <>
              <SelectedCanvasContainer>
                <CanvasItem canvas={selectedCanvas} />
              </SelectedCanvasContainer>
            </>
          )}
        </Modal.Header>
        <Modal.Body className={`pb-0 ${isSelectedCanvasMode && 'px-0'}`}>
          {!isSelectedCanvasMode ? (
            <div>
              {loading ? (
                <LoadingInline />
              ) : (
                filteredCanvases.map((canvas, index) => {
                  const { id } = canvas;
                  const { selected } = modifiedCanvases[id];

                  return (
                    <TableRow
                      key={index}
                      onClick={(e) => !selected && selectCanvas(id, !selected, e)}
                      noBorder={!index}
                    >
                      <Col xs={12} sm={8}>
                        <CanvasItem key={id} canvas={canvas} />
                      </Col>
                      <Col xs={12} sm={4} className="d-md-flex align-items-center justify-content-end">
                        <Button variant="primary" onClick={() => handleClickNext(canvas)}>
                          Next
                        </Button>
                      </Col>
                    </TableRow>
                  );
                })
              )}
            </div>
          ) : (
            <AssignCanvasContent />
          )}
        </Modal.Body>
      </Modal>
    </FormikContext.Provider>
  );
};

export { AssignCanvas };
