import styled from 'styled-components';

export const ToastContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DeleteMessage = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;

  & > span {
    font-weight: 700;
  }
`;

export const UndoLink = styled.a`
  display: block;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  text-decoration: underline;
  text-decoration-color: var(--white);
  color: #fff;
  text-transform: capitalize;
  margin-right: 4px;
  transition: all 0.2s ease-in;

  &:hover {
    text-decoration-color: var(--light-gray-bg);
    color: var(--light-gray-bg);
    cursor: pointer;
  }
`;

export const CautionSection = styled.div`
  margin-top: 24px;
  text-align: left;

  & > p {
    color: var(--orange-bright);
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }

  & > ul {
    padding-left: 20px;

    & > li {
      color: var(--champion-charcoal);

      & > span {
        font-weight: 700;
      }
    }
  }
`;

export const CircleIcon = styled.div`
  background-color: transparent;
`;

export const Content = styled.div`
  min-height: 1px;
`;

export const IconWrapper = styled.div.attrs(props => ({
  className: !props.noDueDate
    && props.shouldShowOrange ? 'past-due'
    : props.shouldShowPurple ? 'past-due-purple' : ''
}))``;

export const ColumnsWrapper = styled.div`
  margin-top: 24px;
`;