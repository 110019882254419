import * as uuid from 'uuid';
import { CUSTOM_FIELD_MODES } from 'constants/customFields';

export const fieldVisibility = {
  showFieldLabel: false,
  showFieldType: false,
  showSection: false,
  showCheckboxes: false,
  showPermission: false,
  showExternalField: false,
};

export const initialSectionValues = {
  sectionLabel: '',
  sectionMode: CUSTOM_FIELD_MODES.ADD,
  editedSectionId: null,
  previousSectionLabel: '',
};

export const initialFieldValues = {
  ...fieldVisibility,
  ...initialSectionValues,
  dataSource: null,
  fieldLabel: '',
  fieldType: null,
  section: null,
  currency: null,
  isSortedFieldValues: false,
  fieldValues: [{ id: uuid.v4(), value: '' }],
  isRequired: false,
  isMergeTag: false,
  externalField: null,
  permission: null,

  fieldMode: CUSTOM_FIELD_MODES.ADD,
  editedFieldId: null,
  previousFieldLabel: '',
  previousExternalField: null,
  isProtected: false,
};
