import { useState, useRef, useContext } from 'react';
import { DropDown, DropDownArrow, Wrapper, FilterEntry, CheckMark, DropDownItem } from './SharedStyles';
import { faCheck, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import useOutsideClickNotifier from 'helpers/hooks/useOutsideClickNotifier';
import reportingContext from './context/reportingContext';
import { SessionStorage } from 'models';
import { TIME_FILTER } from 'constants/sessionStorage';

export default function TimeRangeFilter() {
  const [timeFilterOpen, setTimeFilterOpen] = useState(false);
  const clickRef = useRef(null);
  const {
    activeTimeRangeFilters,
    addActiveTimeRangeFilter,
    removeActiveTimeRangeFilter,
    setStartAndEndDates,
  } = useContext(reportingContext);

  useOutsideClickNotifier(clickRef, () => {
    setTimeFilterOpen(false);
  });

  const toggleFilter = (period) => {
    const storageValue = [SessionStorage.get(TIME_FILTER, true)] ?? [];
    const filterValue = storageValue[0]?.label === period?.label;
    if (filterValue) {
      SessionStorage.remove(TIME_FILTER);
      removeActiveTimeRangeFilter(period);
    } else {
      SessionStorage.set(TIME_FILTER, period, true);
      addActiveTimeRangeFilter(period);
      setStartAndEndDates(period);
      activeTimeRangeFilters.length > 0 &&
        activeTimeRangeFilters.forEach((item) => {
          if (item !== period) {
            removeActiveTimeRangeFilter(item);
          }
        });
    }
  };

  return (
    <>
      <DropDown
        margin="0 10px 0 0"
        padding="8px 32px 8px 16px"
        width="auto"
        onClick={() => setTimeFilterOpen(true)}
      >
        <span>{activeTimeRangeFilters?.length === 0 ? 'Time Range' : activeTimeRangeFilters[0]?.label}</span>
        <DropDownArrow icon={faChevronDown} size="xs" right="10px" />
        <DropDownItem>
          {timeFilterOpen && (
            <Wrapper top="70px" ref={clickRef}>
              {timeRanges.map((period) => {
                return (
                  <FilterEntry key={period.label} onClick={() => toggleFilter(period)}>
                    {period.label}
                    {activeTimeRangeFilters && activeTimeRangeFilters[0]?.label === period.label && (
                      <CheckMark icon={faCheck} size="xs" top="8px" />
                    )}
                  </FilterEntry>
                );
              })}
            </Wrapper>
          )}
        </DropDownItem>
      </DropDown>
    </>
  );
}

const timeRanges = [
  {
    label: 'Last Year',
    value: 12,
    discrepancy: new Date().getMonth() + 1,
    segment: 'months',
  },
  {
    label: 'Year To Date',
    value: new Date().getMonth() + 1,
    discrepancy: 0,
    segment: 'months',
  },
  {
    label: 'Last 12 Months',
    value: 12,
    discrepancy: 0,
    segment: 'months',
  },
  {
    label: 'Last 6 Months',
    value: 6,
    discrepancy: 0,
    segment: 'months',
  },
  {
    label: 'Last 3 Months',
    value: 3,
    discrepancy: 0,
    segment: 'months',
  },
  {
    label: 'This Month',
    value: 1,
    discrepancy: 0,
    segment: 'months',
  },
  {
    label: 'Last Month',
    value: 1,
    discrepancy: 1,
    segment: 'months',
  },
];
