import { Link, Navigate } from 'react-router-dom';
import FullScreenWithBackground from '../components/FullScreenWithBackground';
import { getExternalProject, logOut } from '../helpers/CommonHelper';

export default function Error404() {
  const projectLink = getExternalProject();
  const userIsExternal = !!projectLink;

  const getLink = () => {
    if (userIsExternal) {
      if (projectLink) {
        return <Link to={projectLink}>Go back to project</Link>;
      } else {
        logOut();
        return (
          <Navigate replace to={'/login'}>
            Login again
          </Navigate>
        );
      }
    } else {
      return <Link to="/">Go back to home</Link>;
    }
  };

  return (
    <FullScreenWithBackground>
      <div className="text-center">
        <h1>404</h1>
        <h4 className="py-3">Oops! You're lost.</h4>
        <p className="text-muted">The page you are looking for was not found.</p>
        {getLink()}
      </div>
    </FullScreenWithBackground>
  );
}
