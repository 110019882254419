import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import FullScreenWithBackground from 'components/FullScreenWithBackground';
import { LoginContainer } from './Styles';
import { clearLocalStorage } from 'helpers/CommonHelper';
import { LocalStorage, SignIn } from 'models';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const history = useNavigate();
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    clearLocalStorage();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!username) {
      setErrorMessage('Please provide a username.');
      return;
    }

    setErrorMessage('');
    setIsLoading(true);

    const method = await SignIn.getAuthenticationMethod(username);

    if (method === 'otp') {
      const { companyLogo, tenantName } = await SignIn.generateOTP(username);
      LocalStorage.set('tenantLogo', companyLogo || '');

      const formattedURI = `/login-otp?email=${encodeURIComponent(username)}`;
      history(formattedURI, { state: { tenantName } });
      return;
    }

    if (method === 'password') {
      const { companyLogo } = await SignIn.getSelectedTenantInfo(username);
      LocalStorage.set('tenantLogo', companyLogo || '');

      const formattedURI = `/login-password?email=${encodeURIComponent(username)}`;
      history(formattedURI);
    }

    setIsLoading(false);
  };

  const handleChange = (event) => {
    setUsername(event.target.value);
    setErrorMessage('');
  };

  return (
    <FullScreenWithBackground title="Log in" loading={isLoading} error={errorMessage} showFooter={false}>
      <LoginContainer $hasError={!!errorMessage}>
        <form onSubmit={handleSubmit} className="text-center">
          <Form.Group>
            <Form.Control
              autoFocus
              value={username}
              name="username"
              type="text"
              placeholder="Email"
              onChange={handleChange}
            />
          </Form.Group>

          <Button type="submit" variant="primary" block>
            Next
          </Button>
        </form>
      </LoginContainer>
    </FullScreenWithBackground>
  );
};

export default Login;
