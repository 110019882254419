import React from 'react';
import { Button } from 'react-bootstrap';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: -50%;
  left: 50%;
  z-index: 1050;
  transform: translate(-50%, -50%);
  max-width: ${({ maxWidth }) => maxWidth || '40.938rem'};
  border-radius: 5px;
  background-color: var(--white);
  box-shadow: 0px 2px 20px 2px rgb(0 0 0 / 8%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out;

  &.show {
    opacity: 1;
    top: ${({ top }) => top};
    visibility: visible;
  }

  ${({ hasBorder }) =>
    !hasBorder &&
    css`
      .top-border {
        display: none;
      }
    `}

  @media only screen and (max-width: 768px) {
    top: ${({ info }) => (!info ? '9% !important' : '21% !important')};
    width: 80%;
  }
`;

const TopBorder = styled.div`
  width: 100%;
  border-top: 6px solid var(--sunglow);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const Content = styled.div`
  padding: ${({ info }) => (!info ? '1.5rem 2rem' : '1.5rem 2rem 0.875rem 2rem')};

  @media only screen and (max-width: 768px) {
    padding-bottom: 1.5rem;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 0.625rem;

  & > button {
    align-self: flex-start;
    margin-top: -1%;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 0;

    & > button {
      align-self: center;
    }
  }
`;

const Title = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--champion-charcoal);
  margin-bottom: 0.25rem;

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;

const Information = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  color: var(--playbook-pewter);
`;

const NoInfoContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
`;

const AlertDialog = ({
  title,
  info,
  btnLabel,
  onClick,
  visible,
  maxWidth,
  hasBorder = true,
  top,
  children,
}) => {
  return (
    <Wrapper
      className={`${visible && 'show'}`}
      info={info}
      top={top}
      maxWidth={maxWidth}
      hasBorder={hasBorder}
    >
      <TopBorder className="top-border" />

      {children ? (
        <>{children}</>
      ) : (
        <Content info={info}>
          {info ? (
            <>
              <Title>{title}</Title>

              <Row>
                <Information>{info}</Information>
                <Button variant="primary" onClick={onClick}>
                  {btnLabel}
                </Button>
              </Row>
            </>
          ) : (
            <NoInfoContainer>
              <Title>{title}</Title>

              <Button variant="primary" onClick={onClick}>
                {btnLabel}
              </Button>
            </NoInfoContainer>
          )}
        </Content>
      )}
    </Wrapper>
  );
};

export default AlertDialog;
