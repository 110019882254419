export const SUPERUSER = 'SUPERUSER';
export const ADMIN = 'ADMIN';
export const CREATOR = 'CREATOR';
export const FRONT_LINE = 'FRONT_LINE';
export const EXTERNAL = 'EXTERNAL';
export const CONTRIBUTOR = 'CONTRIBUTOR';
export const SUPERUSER_LABEL = 'Superuser';
export const ADMIN_LABEL = 'Admin';
export const CREATOR_LABEL = 'Creator';
export const FRONT_LINE_LABEL = 'Front line';
export const EXTERNAL_LABEL = 'External';
export const CONTRIBUTOR_LABEL = 'Contributor';
export const ROLE = 'role';
export const ROLE_ORDER = [SUPERUSER, ADMIN, CREATOR, FRONT_LINE, CONTRIBUTOR, EXTERNAL];