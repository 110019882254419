import styled from 'styled-components';

export const BillingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;

  .btn-light {
    border: 1px solid var(--border-gray);
    background-color: var(--white);
  }

  .btn-light:hover {
    background-color: var(--goal-gray);
  }

  span.link {
    cursor: pointer;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375rem;
    color: var(--schedule-sapphire);
  }

  h3 {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    color: var(--playbook-pewter);
    margin-bottom: 0.75rem;
  }

  .overview {
    margin-top: 1.25rem;

    &-details {
      display: flex;
      flex-direction: column;
      gap: 0.313rem;

      .accounts {
        display: flex;
        align-items: center;
        gap: 0.563rem;
      }

      .auto-upgrade {
        color: var(--danger);
      }

      &__licenses {
        display: flex;
        align-items: center;
        gap: 0.75rem;
      }

      &__actions {
        margin-top: 0.75rem;
        display: flex;
        gap: 0.875rem;
      }
    }

    &-payment-methods {
      margin-top: 2.625rem;

      p {
        margin-bottom: 0;
      }

      .payment-card {
        max-width: 16.125rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 0.5rem 0.75rem 0.813rem 0.75rem;
        background-color: var(--standard-snow);
        border: 1px solid var(--border-gray);
        border-radius: 0.313rem;
        margin-bottom: 1rem;

        &__header {
          display: flex;
          gap: 0.241rem;
          align-items: center;
          margin-bottom: 0.438rem;
        }

        &__brand {
          font-size: 1.25rem;
        }

        &__right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          justify-self: flex-end;

          .ellipsis-container {
            margin-left: auto;
          }
        }

        &__info {
          font-size: 0.875rem;
        }
      }
    }

    &-history {
      margin-top: 2.5rem;

      th {
        color: var(--champion-charcoal);
        font-weight: 700;
        padding-bottom: 0;
      }

      .table tbody tr:hover {
        background-color: unset;
        cursor: default;
      }

      .fa-arrow-down-to-line {
        font-size: 1.094rem;
        color: var(--schedule-sapphire);
      }
    }
  }

  .plans {
    height: 100%;
    background: var(--marketing-gradient);
    padding: 2rem;

    p {
      margin: 0;
    }

    h2 {
      color: var(--white);
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2.063rem;
      text-align: center;
      margin-bottom: 1.5rem;
    }

    .plan-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 1.5rem;
    }

    .plan-list.teams-plan {
      justify-content: center;
    }

    .plan {
      text-align: center;
      background-color: var(--white);
      padding: 1rem;
      width: 18.75rem;
      border-radius: 0.75rem;

      p {
        margin-bottom: 0.75rem;
      }

      h4 {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.625rem;
        color: var(--schedule-sapphire);
      }

      hr {
        margin: 0 0 0.75rem 0;
        border-top: 1px solid var(--border-gray);
      }

      .interval-info {
        font-size: 1rem;
        font-weight: 400;
      }
    }
  }

  @media only screen and (max-width: 426px) {
    grid-template-columns: 1fr;
  }
`;
