import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import Checkbox from 'components/Checkbox/Checkbox';
import * as Api from '../../api/Api';
import toast from '../toast';
import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
  ModalLargePaddingHeader,
} from './LargePadding';
import { useEffect } from 'react';

export default function ModalModifyTenant({ show, onHide }) {
  const newTenant = {
    features: 'Trial',
    nativeIntegrationEnabled: false,
    globalAutomationEnabled: false,
    userLimit: 0,
  };
  const [saving, setSaving] = useState(false);
  const [tenant, setTenant] = useState(newTenant);

  useEffect(() => {
    loadAppSettings();
  }, []);

  async function loadAppSettings() {
    const data = await Api.get('Settings/GetTenantSettings');

    setTenant({
      features: data.featureFlagsValue,
      nativeIntegrationEnabled: data.nativeIntegrationEnabled,
      globalAutomationEnabled: data.globalAutomationEnabled,
      userLimit: data.userLimit,
    });
  }

  function inputValueChanged(event) {
    const target = event.target;
    setTenant((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));
  }

  async function handleSave(event) {
    event.preventDefault();
    setSaving(true);
    const response = await Api.post('Settings/UpdateTenantSettings', tenant, { rawResponse: true });
    setSaving(false);
    if (response.status === 200) {
      setTenant(newTenant);
      onHide();
      toast.saved('Tenant updated successfully.');
    } else if (response.status === 400) {
      response.text().then((data) => {
        toast.error(data);
      });
    } else if (response.status === 500) {
      toast.error('An unknown error has occurred.');
    }
  }

  const FormControl = styled(Form.Control)`
    width: initial;
  `;

  return (
    <ModalLargePadding
      show={show}
      onHide={onHide}
      content={
        <form onSubmit={handleSave}>
          <ModalLargePaddingHeader icon={faBuilding} text="Modify Tenant" />
          <Form.Group className="mr-4 mb-2">
            <Form.Label htmlFor="emailTrigger" className="settings-section-title mt-0 mr-2">
              Edition
            </Form.Label>
            <FormControl
              as="select"
              custom
              value={tenant.features}
              name="features"
              id="features"
              className="email-field custom-select"
              onChange={inputValueChanged}
            >
              <option value="Legacy">Legacy (Beta Customers)</option>
              <option value="Trial">Free Trial</option>
              <option value="Team">Team</option>
              <option value="Integrations">Team with Integrations</option>
            </FormControl>
          </Form.Group>
          <Checkbox
            checked={tenant.globalAutomationEnabled}
            label="Enable Global Automation"
            name="globalAutomationEnabled"
            onChange={() =>
              setTenant((prev) => ({ ...prev, globalAutomationEnabled: !tenant.globalAutomationEnabled }))
            }
          />
          <Checkbox
            checked={tenant.nativeIntegrationEnabled}
            label="Enable Native Integration"
            name="nativeIntegrationEnabled"
            onChange={() =>
              setTenant((prev) => ({ ...prev, nativeIntegrationEnabled: !tenant.nativeIntegrationEnabled }))
            }
          />
          <Form.Group>
            <Form.Label htmlFor="userLimitValue" className="settings-section-title mt-0 mr-2">
              User Limit
            </Form.Label>
            <Form.Control
              type="number"
              required
              min="1"
              placeholder="Max number of users (e.g. 10) +1 for the superuser"
              name="userLimit"
              value={tenant.userLimit}
              onChange={inputValueChanged}
            />
          </Form.Group>
          <div className="buttons">
            <ModalLargePaddingCancelButton onClick={onHide} />
            <ModalLargePaddingConfirmButton
              type="submit"
              variant={'primary'}
              loading={saving}
              text={'Update'}
            />
          </div>
        </form>
      }
    />
  );
}
