import styled from 'styled-components';

const ErrorText = styled.div.attrs(() => ({
  className: 'invalid-feedback',
}))`
  position: absolute;
  display: inline-block;
`;

export default ErrorText;
