import React, { useContext, useEffect, useState } from 'react';
import profileIcon from '../../img/profile_icon.svg';

import { formatPhoneNumber, trimStringWithEllipsis } from '../../helpers/CommonHelper';

import { PhoneStyled, StyledTable, TableContainer, TextButton } from './Styles';
import MoreInfoModal from 'components/Modals/Customers/MoreInfoContact';
import { useContacts } from './hooks';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { GENERAL, PROFILE_FIELDS, CONTACTS_FIXED_FIELDS, CONTACT_LABEL } from 'constants';
import { FormikContext } from 'formik';
import { FIELD_TYPES } from 'constants';
import { format } from 'date-fns';
import { useParser } from 'views/Customers/hooks';

function CustomerContacts({ customerId, isCreateNew, setIsCreateNew, setSaving, setHasError }) {
  const [selectedContact, setSelectedContact] = useState({});
  const [generalFields, setGeneralFields] = useState([]);

  const { formikBag, handleShow, handleHide, showAddContactModal, setCustomerId } = useContacts();
  const { contacts: contactsState, fetchInitialContactsData } = useContext(CustomFieldsContext);
  const { FIRST_NAME, LAST_NAME, EMAIL, PHONE } = PROFILE_FIELDS;
  useEffect(() => {
    if (!showAddContactModal && isCreateNew) {
      setCustomerId(customerId);
      handleShow(null);
    }
  }, [isCreateNew, customerId, showAddContactModal, handleShow, setCustomerId]);

  useEffect(() => {
    const editableSections = contactsState.editableSections;
    const hasEditableSections = editableSections.length > 0;
    if (hasEditableSections) {
      const _generalFields = editableSections.find((section) => section.name === GENERAL);
      setGeneralFields(_generalFields);
    }
    return function cleanup() {
      setGeneralFields([]);
    };
  }, [contactsState.editableSections]);

  /**
   * Handler for cancelling or hiding
   * the contact modal window
   * @returns void
   */
  const hideModal = () => {
    setIsCreateNew(false);
    handleHide();
    fetchInitialContactsData();
  };

  /**
   * Handler for setting and showing the More Info Modal
   * @param {Object} contact - all the info of a specific contact
   * @returns void
   */
  const moreInfoHandler = (contact, contactIndex) => {
    handleShow(contact);
    setSelectedContact({ ...contact, contactIndex });
  };

  const getCustomizedLabel = (fieldLabel) => {
    switch (fieldLabel) {
      case FIRST_NAME:
        return CONTACT_LABEL.NAME;
      case EMAIL:
        return CONTACT_LABEL.EMAIL_PHONE;
      default:
        break;
    }
  };

  const getCustomizedData = (contact, fieldName, index) => {
    switch (fieldName) {
      case CONTACTS_FIXED_FIELDS.FIRST_NAME:
        return (
          <td key={`general-field-${index}`}>
            <img className="mr-4" src={contact.avatar || profileIcon} alt="contact-avatar" aria-hidden />
            {`${contact[CONTACTS_FIXED_FIELDS.FIRST_NAME]} ${contact[CONTACTS_FIXED_FIELDS.LAST_NAME]}`}
          </td>
        );
      case CONTACTS_FIXED_FIELDS.EMAIL:
        return (
          <td key={`general-field-${index}`}>
            {trimStringWithEllipsis(contact[CONTACTS_FIXED_FIELDS.EMAIL], 30)}
            <PhoneStyled>{formatPhoneNumber(contact[CONTACTS_FIXED_FIELDS.PHONENUMBER]) ?? '--'}</PhoneStyled>
          </td>
        );
      case CONTACTS_FIXED_FIELDS.LAST_ACTIVITY:
        return (
          <td key={`general-field-${index}`}>
            {contact[CONTACTS_FIXED_FIELDS.LAST_ACTIVITY] ? (
              <>
                <div>
                  {format(new Date(contact[CONTACTS_FIXED_FIELDS.LAST_ACTIVITY]), 'MMM d, yyyy hh:mm aa')}
                </div>
              </>
            ) : (
              '--'
            )}
          </td>
        );
      default:
        break;
    }
  };

  const { handleParseResponseValue } = useParser();

  const renderValue = (field, value) => {
    const { fieldType } = field;

    if (!value && typeof value !== 'boolean') return <>--</>;

    switch (fieldType) {
      case FIELD_TYPES.BOOLEAN:
      case FIELD_TYPES.YES_NO: {
        return <>{!!value ? 'Yes' : 'No'}</>;
      }

      case FIELD_TYPES.LINK: {
        return <>{trimStringWithEllipsis(`https://${value}`, 30)}</>;
      }

      case FIELD_TYPES.DROPDOWN_SINGLE_SELECT:
      case FIELD_TYPES.SINGLE_DROPDOWN:
      case FIELD_TYPES.TIMEZONE: {
        return <>{trimStringWithEllipsis(value?.label, 30)}</>;
      }

      case FIELD_TYPES.DROPDOWN_MULTI_SELECT:
      case FIELD_TYPES.MULTI_DROPDOWN: {
        const formattedValue = value?.map(({ label }) => label).join(', ');

        return <>{trimStringWithEllipsis(formattedValue, 30)}</>;
      }

      case FIELD_TYPES.DATE: {
        return <>{format(new Date(value), 'MMM dd, yyyy')}</>;
      }

      case FIELD_TYPES.TIME: {
        return <>{format(new Date(value), 'hh:mm aa')}</>;
      }

      case FIELD_TYPES.DATE_TIME:
      case FIELD_TYPES.DATE_AND_TIME: {
        return <>{value?.dateTime ? format(new Date(value?.dateTime), 'MMM d, yyyy hh:mm aa') : '--'}</>;
      }

      case FIELD_TYPES.EMAIL:
        return <>{trimStringWithEllipsis(value, 30)}</>;

      default:
        return <>{trimStringWithEllipsis(value, 30)}</>;
    }
  };

  return (
    <>
      <TableContainer>
        {contactsState?.sections?.length ? (
          <StyledTable>
            <thead>
              <tr>
                {generalFields?.fields?.map(({ fieldLabel }, index) => {
                  if (fieldLabel !== LAST_NAME && fieldLabel !== PHONE) {
                    return (
                      <td className="border-0 pt-0" key={`general-field-label-${index}`}>
                        {fieldLabel === FIRST_NAME || fieldLabel === EMAIL
                          ? getCustomizedLabel(fieldLabel)
                          : fieldLabel}
                      </td>
                    );
                  } else {
                    return null;
                  }
                })}
                <td className="border-0 pt-0"></td>
              </tr>
            </thead>
            <tbody>
              {contactsState?.sections?.map((contact, index) => {
                return (
                  <tr
                    key={`contact-field-${index}`}
                    onClick={() => moreInfoHandler(contact, index)}
                    className="cursor-pointer"
                  >
                    {generalFields?.fields?.map((field, index) => {
                      const { targetFieldName } = field;

                      const _fieldName =
                        targetFieldName === CONTACTS_FIXED_FIELDS.LAST_ACTIVITY
                          ? CONTACTS_FIXED_FIELDS.LAST_ACTIVITY
                          : targetFieldName.replace(targetFieldName[0], targetFieldName[0].toLowerCase());
                      const mappedFieldName = contact[_fieldName] ?? contact[targetFieldName];
                      const formikValue = handleParseResponseValue(field, mappedFieldName);

                      if (
                        _fieldName !== CONTACTS_FIXED_FIELDS.LAST_NAME &&
                        _fieldName !== CONTACTS_FIXED_FIELDS.PHONE
                      ) {
                        return _fieldName === CONTACTS_FIXED_FIELDS.FIRST_NAME ||
                          _fieldName === CONTACTS_FIXED_FIELDS.EMAIL ||
                          _fieldName === CONTACTS_FIXED_FIELDS.LAST_ACTIVITY ? (
                          getCustomizedData(contact, _fieldName, index)
                        ) : (
                          <td className="py-3" key={`general-field-${index}`}>
                            <div className="value">{renderValue(field, formikValue)}</div>
                          </td>
                        );
                      } else {
                        return null;
                      }
                    })}
                    <td className="py-3 pr-4">
                      <TextButton onClick={() => moreInfoHandler(contact, index)} isUnderlined>
                        {CONTACT_LABEL.MOREINFO}
                      </TextButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
        ) : (
          <div className="small-info">No contacts</div>
        )}

        {showAddContactModal && (
          <FormikContext.Provider value={formikBag}>
            <MoreInfoModal
              contact={selectedContact}
              show={showAddContactModal}
              handleHide={hideModal}
              onUpdateContact={fetchInitialContactsData}
              setSaving={setSaving}
              setHasError={setHasError}
            />
          </FormikContext.Provider>
        )}
      </TableContainer>
    </>
  );
}

export default CustomerContacts;
