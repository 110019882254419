import { Editor } from "@tinymce/tinymce-react";
import { MergeTag } from "models";

const EmailEditor = ({body, setBody, handleSave}) => {
  return <Editor
  tinymceScriptSrc={process.env.REACT_APP_BASE_URL + '/tinymce/tinymce.min.js'}
  apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
  init={{
    branding: false,
    height: 500,
    menubar: false,
    statusbar: false,
    selector: 'textarea',
    plugins: 'lists advlist link autolink code',
    toolbar:
      'undo | formatselect | bold italic strikethrough forecolor backcolor | link unlink | alignleft aligncenter alignright alignjustify | numlist listgroup | customcode | mergetags ',
    toolbar_groups: {
      listgroup: {
        icon: 'image-options',
        items: 'bullist outdent indent',
      },
    },
    setup: (editor) => {
      let menuItemsNames = '';
      editor.ui.registry.addButton('customcode', {
        text: 'HTML',
        tooltip: 'Open HTML code',
        onAction: () => editor.execCommand('mceCodeEditor'),
      });
      editor.ui.registry.addMenuButton('mergetags', {
        text: 'Merge Tag',
        fetch: (callback) => callback(menuItemsNames),
        onSetup: async () => {
          const mergeTags = await MergeTag.fetchAll();
          const menuItems = [];
          for (let i = 0; i < mergeTags.length; i++) {
            const { id, name } = mergeTags[i];
            const menuItem = `mergetag-${id}`;
            const text = `{{${name}}}`;

            editor.ui.registry.addMenuItem(menuItem, {
              text,
              onAction: () => editor.insertContent(text),
            });

            menuItems.push(menuItem);
          }

          menuItemsNames = menuItems.join(' ');

          return () => {};
        },
      });
    },
  }}
  value={body}
  onEditorChange={(newValue) => setBody(newValue)}
  onBlur={handleSave}
/>;
}

export default EmailEditor;