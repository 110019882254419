import { useState, useEffect, useRef, useContext } from 'react';
import * as Api from '../../api/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TableStyle,
  TableTitleStyle,
  TableWrapperStyle,
  TableDataStyle,
  NoDataTextStyle,
  HeaderMessageBox,
} from './SharedStyles';
import { sortByLetter, sortByNumber, sortByDate } from './sorting';
import TableHeader from './TableHeader';
import { Link } from 'react-router-dom';
import { shortDate } from 'helpers/DateHelper';
import { displayMoney } from 'helpers/CommonHelper';
import ReportingContext from './context/reportingContext';
import InfoTextBox from 'components/Modals/Shared/InfoTextBox';
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import HeaderFilter from './HeaderFilter';
import styled from 'styled-components';
import { UNNAMED_ACCOUNT } from 'constants';

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--schedule-sapphire);
`;

export default function LostAccountsTable() {
  const [lostAccounts, setLostAccounts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showTooltip, setShowToolTip] = useState(false);
  const [elRef, setElRef] = useState(null);
  const [sortedColumn, setSortedColumn] = useState('Inactive Date');
  const [showTable, setShowTable] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const { activeAccountFilters, activeTimeRangeFilters, startDate, endDate } = useContext(ReportingContext);
  const table = useRef();

  // Filter out table data based on active filters
  useEffect(() => {
    let accountFilters = '';
    if (activeAccountFilters?.length > 0) {
      activeAccountFilters.forEach((filter) => {
        accountFilters += `&accountType=${filter}`;
      });
    }
    const prepareSort = (data) => {
      const sortedData = data.sort(
        (a, b) => new Date(b.inactiveDate).getTime() - new Date(a.inactiveDate).getTime()
      );
      return sortedData;
    };

    const getLostAccounts = async () => {
      const res = await Api.get(`Report/GetLost?startDate=${startDate}&endDate=${endDate}${accountFilters}`);
      const data = prepareSort(res);
      const ids = data.map((value) => value.id);
      const filteredData = data.filter(({ id }, index) => !ids.includes(id, index + 1));

      setLostAccounts(filteredData);
    };

    getLostAccounts();
  }, [activeAccountFilters, endDate, startDate]);

  useEffect(() => {
    const filteredData = lostAccounts.filter((data) =>
      activeAccountFilters.length > 0 ? activeAccountFilters.includes(data.accountType) : true
    );

    setFilteredData(filteredData);
  }, [activeAccountFilters, lostAccounts]);

  return (
    <TableWrapperStyle>
      <TableTitleStyle onClick={() => setShowTable(!showTable)}>
        <span className="title d-flex">
          <span className="mr-2">
            <StyledIcon icon={showTable ? faAnglesUp : faAnglesDown} className="mr-2" />
            Lost Accounts
          </span>
          <InfoTextBox notActive={false} width={'411px'} top={0}>
            Lost accounts are calculated when toggling the account status to 'Inactive'
          </InfoTextBox>
        </span>
        {(activeTimeRangeFilters.length > 0 || activeAccountFilters.length > 0) && (
          <HeaderFilter setShowFilters={setShowFilters} showFilters={showFilters} />
        )}
      </TableTitleStyle>
      {showTooltip && (
        <HeaderMessageBox
          top={elRef.current.getBoundingClientRect().top}
          left={elRef.current.getBoundingClientRect().left}
          width={411}
        >
          {elRef.current.innerText === 'Inactive Date' &&
            "The date the account was toggled to 'Inactive' status."}
          {elRef.current.innerText === 'Account Value' && 'Annual account revenue.'}
        </HeaderMessageBox>
      )}
      {showTable && (
        <TableStyle ref={table}>
          <thead>
            <tr>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByLetter}
                setSortedColumn={setSortedColumn}
                flexBasis={20}
              >
                Account
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByDate}
                setSortedColumn={setSortedColumn}
                sortByDefault={true}
                flexBasis={10}
                hoverOn={(ref) => {
                  setElRef(ref);
                  setShowToolTip(true);
                }}
                hoverOut={() => {
                  setElRef(null);
                  setShowToolTip(false);
                }}
              >
                Inactive Date
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByNumber}
                setSortedColumn={setSortedColumn}
                flexBasis={10}
                hoverOn={(ref) => {
                  setElRef(ref);
                  setShowToolTip(true);
                }}
                hoverOut={() => {
                  setElRef(null);
                  setShowToolTip(false);
                }}
              >
                Account Value
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByLetter}
                setSortedColumn={setSortedColumn}
                flexBasis={10}
              >
                Account Type
              </TableHeader>
              {/* <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByLetter}
                setSortedColumn={setSortedColumn}
                flexBasis={25}
              >
                Project
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByLetter}
                setSortedColumn={setSortedColumn}
                flexBasis={25}
              >
                Template Used
              </TableHeader> */}
            </tr>
          </thead>
          <tbody>
            {filteredData?.length > 0 ? (
              filteredData.map((item) => (
                <tr key={item?.id}>
                  <TableDataStyle flexBasis={20}>
                    <Link to={`/accounts/${item?.id}`}>{item?.name || UNNAMED_ACCOUNT}</Link>
                  </TableDataStyle>
                  <TableDataStyle flexBasis={10}>{shortDate(new Date(item?.inactiveDate))}</TableDataStyle>
                  <TableDataStyle flexBasis={10}>{displayMoney(item?.revenue)}</TableDataStyle>
                  <TableDataStyle flexBasis={10}>{item?.accountType}</TableDataStyle>
                  {/* <TableDataStyle link={true} flexBasis={25}>
                    {item?.id && item?.customerCanvasId ? (
                      <Link to={`/accounts/${item?.id}/projects/${item?.customerCanvasId}`}>
                        {item?.customerCanvasName ?? item?.canvasName}
                      </Link>
                    ) : (
                      item?.customerCanvasName ?? item?.canvasName
                    )}
                  </TableDataStyle>
                  <TableDataStyle link={true} flexBasis={25}>
                    {item?.canvasId ? (
                      <Link to={`/templates/${item?.canvasId}`}>{item?.canvasName}</Link>
                    ) : (
                      item?.canvasName
                    )}
                  </TableDataStyle> */}
                </tr>
              ))
            ) : (
              <tr>
                <td className="w-full">
                  <NoDataTextStyle>No lost accounts within filters</NoDataTextStyle>
                </td>
              </tr>
            )}
          </tbody>
        </TableStyle>
      )}
    </TableWrapperStyle>
  );
}
