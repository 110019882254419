import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import checkedIcon from 'img/checked.svg';
import unCheckedIcon from 'img/unchecked.svg';

const MainContainer = styled.div`
  display: flex;
  gap: 0.5rem;

  & > label,
  label:last-child {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

const CheckboxContainer = styled.label`
  position: relative;
  display: inline-block;
  transition: all 0.3s;
  margin-bottom: 0;
  cursor: pointer;
  top: -0.125rem;
`;

const Input = styled.input`
  opacity: 0;
  display: none;
`;

export const CheckboxImage = styled.img`
  filter: ${({ disabled }) => (disabled ? 'grayscale(1)' : 'grayscale(0)')};
`;

const Checkbox = ({ checked, onChange, label, name, disabled }) => {
  return (
    <MainContainer disabled={disabled}>
      <CheckboxContainer>
        <Input type="checkbox" disabled={disabled} onChange={onChange} checked={checked} id={name} />
        <span>
          {checked ? (
            <span>
              <CheckboxImage disabled={disabled} src={checkedIcon} alt="isChecked" />
            </span>
          ) : (
            <span>
              <CheckboxImage disabled={disabled} src={unCheckedIcon} alt="isUnchecked" />
            </span>
          )}
        </span>
      </CheckboxContainer>

      <Form.Label htmlFor={name}>{label}</Form.Label>
    </MainContainer>
  );
};

export default Checkbox;
