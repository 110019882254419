import * as Api from 'api/Api';

class SignIn {
  constructor(props) {
    if (props) Object.assign(this, props);
  }

  static async getAuthenticationMethod(email) {
    const response = await Api.get(
      `Authentication/GetAuthenticationMethod?email=${encodeURIComponent(email)}`
    );
    return response;
  }

  static async checkUserLoginType(email) {
    const response = await Api.post(`Authentication/CheckUserLoginType`, { email });
    return response;
  }

  static async generateOTP(email) {
    const response = await Api.post(`Authentication/GenerateOTP`, { email });
    return response;
  }

  static async signInWithOTP(email, otp) {
    const response = await Api.post(`Authentication/SignInWithOTP?email=${encodeURIComponent(email)}&otp=${otp}`);
    return response;
  }

  static async getSelectedTenantInfo(email) {
    const response = await Api.get(`Authentication/GetSelectedTenantInfo?email=${encodeURIComponent(email)}`);
    return response;
  }

  static async getTenantInfo() {
    const response = await Api.get(`Authentication/GetTenantInfo`);
    return response;
  }
}

export default SignIn;
