import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
} from 'components/Modals/LargePadding';
import React from 'react';
import { AddSeatsContainer } from './Styles';

const AddSeats = ({ onHide, show, onSubmit }) => {
  const renderContent = (
    <AddSeatsContainer>
      <h1 className="mb-2 text-center">Add More Seats</h1>

      <p>You have used all of your seats. To add a new user, you’ll need to subscribe to a plan.</p>
    </AddSeatsContainer>
  );

  const renderButtons = (
    <>
      <ModalLargePaddingCancelButton onClick={onHide} autoWidth />
      <ModalLargePaddingConfirmButton onClick={onSubmit} text="Add More Seats" autoWidth />
    </>
  );

  const modalStyle = {
    inner: {
      maxWidth: '35.25rem',
      padding: '2.5rem 1.25rem !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  return (
    <ModalLargePadding
      show={show}
      innerProps={modalStyle.inner}
      buttonsContainerProps={modalStyle.buttonContainer}
      content={renderContent}
      buttons={renderButtons}
    />
  );
};

export default AddSeats;
