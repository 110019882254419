import React, { useEffect, useState } from 'react';
import Select from '../../../../components/Select';
import MultiSelect from '../../../../components/MultiSelect';
import * as Api from '../../../../api/Api';
import ErrorText from '../ErrorText';
import InputContainer from './ActionInputContainer';
import ActionContainer from './ActionContainer';
import { noop } from 'helpers/CommonHelper';

const PROJECT_ONLY_VALUE = 'ProjectOnly';
const CUSTOMER_FACING_VALUE = 'CustomerFacing';
const ALL_STEPS_VALUE = 'AllSteps';
const STEPS_TAGS = 'StepsWithTags';

/**
 * Renders action to add user a project/canvas created in a previos action.
 * @property {object} props.action Action object type
 * @property {object} props.errors Errors in the form
 * @property {object} props.touched Indicates if elements in the form were touched
 * @property {object} props.onActionInputChange callback to execute when input changes
 * @returns {JSX.Element}
 */

const AddContactToProjectsAction = ({
  action = {},
  errors = {},
  touched = {},
  onActionInputChange = noop,
}) => {
  const actionInput = action?.actionInput || {};
  const selectedTagIds = actionInput.selectedTagIds;
  const selectedOption = actionInput.selection;
  const allOptions = [{ label: 'Add to project(s) only', value: PROJECT_ONLY_VALUE },
  { label: 'Add to customer facing steps', value: CUSTOMER_FACING_VALUE },
  { label: 'Add to all steps', value: ALL_STEPS_VALUE },
  { label: 'Add to steps with tags', value: STEPS_TAGS}];

  const [tags, setTags] = useState([]);
  const [newInput, setNewInput] = useState({...actionInput});

  useEffect(() => {
    loadTags();
  }, []);

  const loadTags = async () => {
    const response = await Api.get('Project/GetAllTags');
    setTags(response.map(x => ({ label: x.text, value: x.id })));
  }

  const ASSIGN_USER_TO_CANVAS_TEMPLATE = {
    AllActiveProjects: true,
    AddToAllSteps: false,
    AddToCustomerFacing: false,
    selection: PROJECT_ONLY_VALUE,
  };

  /**
   * Function to handle input change
   * @param {string} opt selected option
   * @returns {void}
   */
  const handleActionChange = (opt) => {
    const updatedField = {};
    updatedField.AllActiveProjects = true;
    updatedField.selection = PROJECT_ONLY_VALUE;

    const selectedAllSteps = opt?.value === ALL_STEPS_VALUE;
    updatedField.AddToAllSteps = Boolean(selectedAllSteps);
    if (updatedField.AddToAllSteps) {
      updatedField.selection = ALL_STEPS_VALUE;
    }

    const selectedCustomerFacing = opt?.value === CUSTOMER_FACING_VALUE;
    updatedField.AddToCustomerFacing = Boolean(selectedCustomerFacing);
    if (updatedField.AddToCustomerFacing) {
      updatedField.selection = CUSTOMER_FACING_VALUE;
    }

    const selectedStepsWithTags = opt?.value === STEPS_TAGS;
    updatedField.AddToStepsWithTags = Boolean(selectedStepsWithTags);
    if (updatedField.AddToStepsWithTags) {
      updatedField.selection = STEPS_TAGS;
    }

    const _input = {
      ...ASSIGN_USER_TO_CANVAS_TEMPLATE,
      ...actionInput,
      ...updatedField
    };
    setNewInput(_input);
    onActionInputChange(_input);
  };

  const handleTagsChange = (opt) => {
    const _input = {...newInput, ...{selectedTagIds: opt?.map((op) => op.value) || []}};
    setNewInput(_input);
    onActionInputChange(_input);
  };

  return (
    <ActionContainer>
      <div className="action-label">Add to all active projects</div>
      <InputContainer>
        <Select
          placeholder="Select options..."
          containerWidth={250}
          closeMenuOnSelect
          options={allOptions}
          onChange={handleActionChange}
          isSearchable={false}
          isClearable={false}
          value={allOptions.find((option) => option.value === selectedOption)}
        />
        {touched.actionInput && errors.selection ? <ErrorText>{errors.selection}</ErrorText> : null}
      </InputContainer>
      {newInput.selection === STEPS_TAGS &&
      <InputContainer>
        <MultiSelect
          placeholder="Select tags..."
          containerWidth={250}
          closeMenuOnSelect={false}
          options={tags}
          onChange={handleTagsChange}
          isSearchable={false}
          isClearable={false}
          value={tags.filter((option) => selectedTagIds?.some((tagId) => tagId === option.value))}
        />
      </InputContainer>}
    </ActionContainer>
  );
};

export default AddContactToProjectsAction;
