import React from 'react';
import styled from 'styled-components';
import PlusButtonDropdown from './PlusButtonDropdown';
import RoundedPlusButton from './RoundedPlusButton';
import ErrorText from './ErrorText';

const Container = styled.div`
  position: relative;
  padding-bottom: 1.5rem;
  border-bottom: ${(props) => props.withSeparator && 'solid var(--border-gray) 1px'};
  min-height: ${(props) => (props.isBlank ? '7rem' : '')};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleText = styled.div`
  font-weight: bold;
  color: var(--playbook-pewter);
`;

const RuleSectionButtonDropdown = styled(PlusButtonDropdown)`
  margin-top: 2px;
`;

const RuleSectionButton = styled(RoundedPlusButton)`
  margin-top: 2px;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
  button {
    background-color: ${(props) => props.isBlank && 'var(--schedule-sapphire)'};
  }
  button > svg {
    color: ${(props) => props.isBlank && 'white'};
  }
  > * {
    margin-right: 1rem;
  }
`;

const Error = styled(ErrorText)`
  position: relative;
  margin-top: 0.6rem;
`;

/**
 * Renders common elements for a section on the automation rule form
 * it can be trigger section, condition sections, actions section
 * @property {string} props.title Section title
 * @property {JSX.Element} props.children React children
 * @property {boolean} props.showAddButton Indicates if rounded plus button should be visible
 * @property {className} props.className styles from parent
 * @property {array} props.addButtonOptions options for add button dropdown [{label: 'one', value: '1'}, {label: 'two', value: '2'}]
 * @property {string} props.addButtonLabel Label for the button
 * @property {boolean} props.addButtonAsDropdown Indicates if add button should be dropdown or not
 * @property {boolean} props.withSeparator Indicates if section should have a bottom separator line
 * @property {boolean} props.isBlank Indicates if section is in a blank state
 * @property {string} props.validationError Feedback to the user
 * @property {fuction} props.onButtonClick callback when clicking on section button or dropdown items
 * @property {JSX.Element} props.renderHeaderRightSide Elements to render on the right side of the section
 * @returns {JSX.Element}
 */
const RuleSection = ({
  title = '',
  children,
  showAddButton = true,
  className,
  addButtonOptions = [],
  addButtonLabel = '',
  addButtonAsDropdown = true,
  withSeparator,
  isBlank,
  validationError = '',
  onButtonClick = () => {},
  renderHeaderRightSide = () => {},
}) => {
  return (
    <Container className={className} withSeparator={withSeparator} isBlank={isBlank}>
      <HeaderContainer>
        <TitleText>{title}</TitleText>
        <div>{renderHeaderRightSide()}</div>
      </HeaderContainer>
      {children}
      {Boolean(showAddButton) && (
        <ActionsContainer isBlank={isBlank}>
          {addButtonAsDropdown ? (
            <RuleSectionButtonDropdown options={addButtonOptions} onSelect={onButtonClick} />
          ) : (
            <RuleSectionButton onClick={onButtonClick} />
          )}
          {isBlank && addButtonLabel}
        </ActionsContainer>
      )}
      <Error>{validationError}</Error>
    </Container>
  );
};

export default RuleSection;
