import { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { MergeTag } from 'models';
import { getDateFromServerString, formatDateTime } from 'helpers/DateHelper';

const SaveMessage = styled.span`
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

const FormControl = styled(Form.Control)`
  width: initial;
`;

const FormGroup = styled(Form.Group)`
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.4 : 1)};
`;

const Actions = ({ touchpoint, onSave }) => {
  const [trigger, setTrigger] = useState(touchpoint.emailTrigger || '');
  const [sendTo, setSendTo] = useState(touchpoint.emailAudience || '');
  const [subject, setSubject] = useState(touchpoint.triggerEmailSubject || '');
  const [body, setBody] = useState('');
  const [saving, setSaving] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (touchpoint?.emailSentDate || touchpoint?.status === touchpoint?.emailTrigger) {
      setIsDisabled(true);
    }
  }, [touchpoint?.emailSentDate, touchpoint?.emailTrigger, touchpoint?.status]);

  useEffect(() => {
    setBody(touchpoint.triggerEmailBody);
  }, [touchpoint.triggerEmailBody]);

  function handleSave() {
    setSaving(true);
    onSave({
      emailTrigger: trigger || null,
      emailAudience: sendTo,
      triggerEmailSubject: subject || null,
      triggerEmailBody: body || null,
    });
    setSaving(false);
  }

  const date = getDateFromServerString(touchpoint?.emailSentDate ?? Date.now());
  const { formattedDate: sentDate } = formatDateTime(date, { year: 'numeric' });

  return (
    <div className="my-4">
      {touchpoint?.emailSentDate && <p>Email sent on {sentDate}</p>}
      <div className="d-flex justify-content-between my-4">
        <div className="d-flex">
          <Form inline>
            <Form.Group className="mr-4 mb-2">
              <Form.Label htmlFor="emailTrigger" className="settings-section-title mt-0 mr-2">
                Email Trigger
              </Form.Label>
              <FormControl
                as="select"
                custom
                value={trigger}
                disabled={isDisabled}
                name="emailTrigger"
                id="emailTrigger"
                className="email-field custom-select"
                onChange={(e) => setTrigger(e.target.value)}
                onBlur={handleSave}
              >
                <option value="">Disabled</option>
                <option value="InProgress">Status is in progress</option>
                <option value="Completed">Status is completed</option>
              </FormControl>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label htmlFor="sendTo" className="settings-section-title mt-0 mr-2">
                Send to
              </Form.Label>
              <FormControl
                as="select"
                custom
                value={sendTo}
                disabled={!trigger || isDisabled}
                name="sendTo"
                id="sendTo"
                className="email-field custom-select"
                onChange={(e) => setSendTo(e.target.value)}
                onBlur={handleSave}
              >
                <option value="Internal">Internal Users</option>
                <option value="External">External Users</option>
                <option value="All">Both Internal and External Users</option>
              </FormControl>
            </Form.Group>
          </Form>
        </div>
        {trigger && !isDisabled && (
          <div className="d-flex align-items-center">
            {saving ? (
              <SaveMessage>Saving...</SaveMessage>
            ) : (
              <SaveMessage disabled={!trigger}>Changes auto-saved</SaveMessage>
            )}
          </div>
        )}
      </div>
      <>
        <Form.Group>
          <Form.Control
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
            disabled={!trigger || isDisabled}
            placeholder="Add subject..."
            name="triggerEmailSubject"
            id="triggerEmailSubject"
            className="email-field"
            onBlur={handleSave}
          />
        </Form.Group>
        <FormGroup $isDisabled={!trigger || isDisabled}>
          <Editor
            tinymceScriptSrc={process.env.REACT_APP_BASE_URL + '/tinymce/tinymce.min.js'}
            apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
            disabled={!trigger || isDisabled}
            init={{
              branding: false,
              height: 500,
              menubar: false,
              statusbar: false,
              selector: 'textarea',
              plugins: 'lists advlist link autolink code',
              toolbar:
                'undo | formatselect | bold italic strikethrough forecolor backcolor | link unlink | alignleft aligncenter alignright alignjustify | numlist listgroup | customcode | mergetags ',
              toolbar_groups: {
                listgroup: {
                  icon: 'image-options',
                  items: 'bullist outdent indent',
                },
              },
              setup: (editor) => {
                let menuItemsNames = '';
                editor.ui.registry.addButton('customcode', {
                  text: 'HTML',
                  tooltip: 'Open HTML code',
                  onAction: () => editor.execCommand('mceCodeEditor'),
                });
                editor.ui.registry.addMenuButton('mergetags', {
                  text: 'Merge Tag',
                  fetch: (callback) => callback(menuItemsNames),
                  onSetup: async () => {
                    const mergeTags = await MergeTag.fetchAll();
                    const menuItems = [];
                    for (let i = 0; i < mergeTags.length; i++) {
                      const { id, name } = mergeTags[i];
                      const menuItem = `mergetag-${id}`;
                      const text = `{{${name}}}`;

                      editor.ui.registry.addMenuItem(menuItem, {
                        text,
                        onAction: () => editor.insertContent(text),
                      });

                      menuItems.push(menuItem);
                    }

                    menuItemsNames = menuItems.join(' ');

                    return () => {};
                  },
                });
              },
            }}
            value={body}
            onEditorChange={(newValue) => setBody(newValue)}
            onBlur={handleSave}
          />
        </FormGroup>
      </>
    </div>
  );
};

export default Actions;
