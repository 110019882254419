import React, { useContext, useMemo } from 'react';
import { BILLING_PLANS } from 'constants';
import { Button, Dropdown, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons';
import { faCcAmex, faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import FullContext from 'stores/Full/fullContext';
import InfoTextBox from 'components/Modals/Shared/InfoTextBox';
import { format } from 'date-fns';

const Overview = ({
  subscriptionDetails,
  defaultPayment,
  isCancelLoading,
  handleEdit,
  handleUpgrade,
  handleShowAddCardModal,
  handleCancelSubscription,
}) => {
  const { appSettings } = useContext(FullContext);
  const { activeAccountCount, stripeData } = appSettings;
  const { customerCapping } = stripeData;

  const isFreeTier = subscriptionDetails?.planName === BILLING_PLANS.FREE;

  const memoizedScheduledDate = useMemo(
    () => (stripeData?.scheduledDate ? format(new Date(stripeData?.scheduledDate), 'MMM d, yyyy') : ''),
    [stripeData?.scheduledDate]
  );

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div ref={ref} onClick={onClick} className="cursor-pointer">
      {children}
    </div>
  ));

  const renderCard = () => {
    const { card, billingDetails, id } = defaultPayment;
    const { address, name } = billingDetails;
    const { city, line1, line2, postalCode, state } = address;
    const { last4, expMonth, expYear, brand } = card;

    const mappedCardBrand = {
      visa: faCcVisa,
      mastercard: faCcMastercard,
      amex: faCcAmex,
    };

    const values = { name, expMonth, expYear, line1, line2, id, city, state, postalCode };

    const handleEditPayment = () => {
      handleEdit(values);
    };

    return (
      <div className="payment-card">
        <div className="payment-card__left">
          <div className="payment-card__header">
            <FontAwesomeIcon className="payment-card__brand" icon={mappedCardBrand[brand] || faCreditCard} />
            <p>{last4}</p>
          </div>

          <div className="payment-card__info">
            <p>{name}</p>
            <p>{line1}</p>

            {line2 && <p>{line2}</p>}

            <p>
              {city}, {state} {postalCode}
            </p>
          </div>
        </div>

        <div className="payment-card__right">
          <p>
            {expMonth}/{expYear}
          </p>

          <div className="ellipsis-container">
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle}>
                <FontAwesomeIcon icon={faEllipsisH} className="ml-3" />
              </Dropdown.Toggle>
              <Dropdown.Menu align="left">
                <Dropdown.Item onClick={handleEditPayment}>Edit</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => handleShowAddCardModal({ isNewPayment: false, plan: null })}>
                  Replace
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  };

  const isDeprecatedPlans =
    subscriptionDetails?.planName === BILLING_PLANS.TEAMS ||
    subscriptionDetails?.planName === BILLING_PLANS.INTEGRATIONS;

  const endLimit = customerCapping?.end <= -1 ? 20 : customerCapping?.end;

  const isAutoUpgrade = +activeAccountCount > +endLimit && !isDeprecatedPlans;

  const getInfoText = () => {
    if (isAutoUpgrade && subscriptionDetails?.interval === 'month')
      return 'The upper limit of accounts under management for your current subscription has been exceeded. Your subscription will automatically update on the date shown.';

    if (isAutoUpgrade && subscriptionDetails?.interval === 'year')
      return `The upper limit of accounts under management for your current subscription has been exceeded. Your subscription will automatically update on ${memoizedScheduledDate} at a prorated value for the current subscription period. If the number of accounts is at or below the upper limit before ${memoizedScheduledDate}, the automatic upgrade will be canceled.`;

    switch (subscriptionDetails?.planName) {
      case BILLING_PLANS.TIER_ONE:
      case BILLING_PLANS.TIER_TWO:
        return 'The upper limit of accounts under management for your current subscription, Tier 2, is 200. Adding new accounts above that limit will automatically schedule your subscription for an upgrade to the next tier.';

      case BILLING_PLANS.TIER_THREE:
        return 'The upper limit of accounts under management for your current subscription, Tier 3, is 500. Adding new accounts above that limit requires an upgrade to an Enterprise subscription. Contact support@stagebase.com for information and assistance.';
      default:
        return 'The upper limit of accounts under management for your current free subscription is 20. To manage additional accounts and add unlimited users, upgrade your subscription.';
    }
  };

  const formattedAmount = useMemo(() => {
    return {
      activePlanPrice: subscriptionDetails?.activePlanPrice?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      salesTax: subscriptionDetails?.salesTax?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      amount: subscriptionDetails?.amount?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      prorateValue: subscriptionDetails?.prorateValue?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    };
  }, [subscriptionDetails]);

  const planPriceInfo = `$${formattedAmount.activePlanPrice || 0.0}/${subscriptionDetails?.interval}`;

  const salesTax = subscriptionDetails?.salesTax ? ` + $${formattedAmount.salesTax || 0.0} sales tax` : '';

  const accountsInfo = isDeprecatedPlans
    ? `${activeAccountCount}`
    : `${activeAccountCount}/${endLimit} ${
        isAutoUpgrade ? `- Automatic upgrade on ${memoizedScheduledDate}` : ''
      }`;

  const isSubscriptionInfoShown = subscriptionDetails?.interval === 'year' && isAutoUpgrade;

  return (
    <section className="overview">
      <div className="overview-details">
        <h3>Subscription Details</h3>

        <h6>
          Plan <strong>- {subscriptionDetails.planName}</strong>{' '}
          {!isFreeTier && `(${planPriceInfo + salesTax})`}
        </h6>

        <div className={`accounts ${isFreeTier ? 'mb-4' : ''}`}>
          <h6>
            Accounts <strong className={isAutoUpgrade ? 'auto-upgrade' : ''}>- {accountsInfo}</strong>{' '}
          </h6>

          {!isDeprecatedPlans && (
            <InfoTextBox notActive={false} width="30.625rem" top="1.525rem" left="-0.5rem">
              {getInfoText()}
            </InfoTextBox>
          )}
        </div>

        {!isFreeTier && !isSubscriptionInfoShown && (
          <h6>
            Next Bill
            <strong>
              - ${formattedAmount.amount || 0.0} on {subscriptionDetails.nextBillDate}
            </strong>
          </h6>
        )}

        {!isFreeTier && isSubscriptionInfoShown && (
          <>
            <h6>
              Subscription Upgrade
              <strong>
                - ${formattedAmount.prorateValue || 0.0} (prorated) on {memoizedScheduledDate}
              </strong>
            </h6>

            <h6>
              Subscription Renewal
              <strong>
                - ${formattedAmount.amount || 0.0} on {subscriptionDetails.nextBillDate}
              </strong>
            </h6>
          </>
        )}

        <div className="overview-details__actions">
          <Button
            onClick={() => handleUpgrade({ plan: null, paymentMethod: defaultPayment })}
            variant="secondary"
          >
            Change Plan
          </Button>

          <Button
            onClick={handleCancelSubscription}
            disabled={isFreeTier}
            variant="light"
            className="btn-light"
          >
            {isCancelLoading ? <LoadingSpinner white /> : 'Cancel'}
          </Button>
        </div>
      </div>

      <div className="overview-payment-methods">
        <h3>Payment Method</h3>

        {!defaultPayment ? (
          <Button
            onClick={() => handleShowAddCardModal({ isNewPayment: true, plan: null })}
            variant="light"
            className="btn-light"
          >
            Add New Card
          </Button>
        ) : (
          renderCard()
        )}
      </div>

      <div className="overview-history">
        <h3>Billing History (Last 12 months)</h3>

        <Table responsive borderless hover={false}>
          <thead>
            <tr>
              <th>Billed On</th>
              <th>Plan</th>
              <th>Amount</th>
              <th>Invoice</th>
            </tr>
          </thead>

          <tbody>
            {subscriptionDetails.invoices?.map(({ billedOn, plan, amount, pdfLink }, index) => {
              const _amount = amount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });

              return (
                <tr key={index}>
                  <td>{billedOn}</td>
                  <td>{plan}</td>
                  <td>${_amount || 0.0}</td>
                  <td>
                    <a href={pdfLink} rel="noreferrer" target="_blank">
                      <FontAwesomeIcon icon={faArrowDownToLine} />
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default Overview;
