import styled, { css } from 'styled-components';

export const InfoTextBoxContainer = styled.div`
  margin-top: 0.375rem;

  .info-content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .inner-info-icon {
    height: 1rem;
  }

  a {
    text-decoration: none;
    color: var(--champion-charcoal);
  }

  &:hover {
    svg {
      color: var(--implement-iron);
    }
  }

  svg {
    height: 1.25rem;
    margin-right: 0;
  }
`;

export const Visible = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;

export const NavContainer = styled.div`
  .banner {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 2rem;
    background-color: var(--danger);
    color: var(--white);

    p {
      padding-top: 0.375rem;
      margin: 0;

      span {
        cursor: pointer;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  ${({ $isConfirmed, $isExternal }) =>
    !$isConfirmed &&
    !$isExternal &&
    css`
      nav {
        top: 2rem;
      }
    `}
`;
