import React, { Fragment, useMemo, useCallback } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { isDropDownType } from 'helpers/CommonHelper';
import ModalLargePadding from '../../LargePadding';
import { FieldContainer } from '../../CustomFields/Styles';
import FormField from '../../Customers/FormField';
import { ScrollableContainer } from '../../Customers/Styles';
import { CUSTOM_FIELDS_MODAL_VARIATIONS, HAS_SERVER_ERROR, IS_SAVING } from 'constants';
import { ActionsContainer } from 'views/Users/Styles';
import { SaveContainer } from 'views/Profile/Styles';
import { HeaderContainer } from './Styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

const ModalMoreInformation = ({ show, onHide, isPreviewMode }) => {
  const { values, setFieldValue, resetForm } = useFormikContext();

  const renderFieldArray = useCallback(
    () => (
      <FieldArray
        name="projectFields"
        render={() => (
          <>
            {(values.projectFields || [])?.map((field, index) => {
              const isDropDown = isDropDownType(field?.fieldType);
              const formikName = `projectFields.[${index}].formikValue`;

              return (
                <Fragment key={field.objectMapId}>
                  <FieldContainer key={field.objectMapId}>
                    <FormField
                      field={field}
                      name={formikName}
                      isEdit
                      isDropDownLastItem={isDropDown && values.projectFields.length - 1 === index}
                      setSaving={(value) => setFieldValue(IS_SAVING, value)}
                      setHasError={(value) => setFieldValue(HAS_SERVER_ERROR, value)}
                      variation={CUSTOM_FIELDS_MODAL_VARIATIONS.PROJECTS}
                      isPreviewMode={isPreviewMode}
                    />
                  </FieldContainer>
                </Fragment>
              );
            })}
          </>
        )}
      />
    ),
    [values.projectFields, setFieldValue, isPreviewMode]
  );

  const renderContent = useMemo(() => {
    const handleHide = () => {
      onHide();

      setTimeout(() => {
        resetForm();
      }, 500);
    };

    return (
      <div>
        <HeaderContainer className="mb-4">
          <div className="header">
            <h1>More Info</h1>
            {isPreviewMode && <span>(Preview Mode)</span>}
            <ActionsContainer>
              <SaveContainer>
                {!values.isChangesSaving && typeof values.isChangesSaving != 'boolean' ? null : Boolean(
                    values.isChangesSaving
                  ) ? (
                  <span className="saving mr-3">Saving...</span>
                ) : Boolean(values.hasServerError) ? (
                  <span className="error mr-3">Unsaved changes</span>
                ) : (
                  <span className="mr-3">Changes auto-saved.</span>
                )}
              </SaveContainer>
            </ActionsContainer>
          </div>

          <FontAwesomeIcon icon={faTimes} onClick={handleHide} />
        </HeaderContainer>

        <ScrollableContainer>{renderFieldArray()}</ScrollableContainer>
      </div>
    );
  }, [isPreviewMode, onHide, renderFieldArray, resetForm, values.hasServerError, values.isChangesSaving]);

  const modalStyle = {
    inner: {
      maxWidth: 'unset',
      padding: '2rem 0 2rem 0 !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'var(--light-gray-bg)',
      borderRadius: '0 0 0.813rem 0.813rem',
      padding: '1.875rem 0 2rem 0 !important',
    },
  };

  return (
    <>
      <ModalLargePadding
        show={show}
        innerProps={modalStyle.inner}
        buttonsContainerProps={modalStyle.buttonContainer}
        content={renderContent}
      />
    </>
  );
};

export default ModalMoreInformation;
