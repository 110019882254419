import React from 'react';
import Select from 'components/Select';
import InputContainer from './ActionInputContainer';
import ActionContainer from './ActionContainer';
import useClientTypes from '../../../../helpers/hooks/useClientTypes';
import ErrorText from '../ErrorText';

/**
 * Renders action to set a type the account created/updated
 * @property {object} props.action Action object type
 * @property {object} props.errors Errors in the form
 * @property {object} props.touched Indicates if elements in the form were touched
 * @property {object} props.onActionInputChange callback to execute when input changes
 * @returns {JSX.Element}
 */
const SetAccountTypeAction = ({ action = {}, errors = {}, touched = {}, onActionInputChange = () => {} }) => {
  const { clientTypeOptions } = useClientTypes();
  const actionInput = action?.actionInput || {};
  const { clientTypeId } = actionInput;

  const SET_ACCOUNT_TYPE_TEMPLATE = {
    clientTypeId: 0,
  };

  /**
   * Function to handle input change
   * @param {object} opt object with label and value for the selected item
   * @returns {void}
   */
  const handleActionChange = (opt) => {
    const clientTypeId = opt.value;
    const newInput = {
      ...SET_ACCOUNT_TYPE_TEMPLATE,
      ...actionInput,
      clientTypeId,
    };
    onActionInputChange(newInput);
  };

  return (
    <ActionContainer>
      <div className="action-label">Set Account Type To</div>
      <InputContainer>
        <Select
          placeholder="Select type..."
          containerWidth={200}
          options={clientTypeOptions}
          onChange={handleActionChange}
          value={clientTypeOptions.find((type) => type.value === clientTypeId)}
        />
        {touched.actionInput && errors.clientTypeId ? <ErrorText>{errors.clientTypeId}</ErrorText> : null}
      </InputContainer>
    </ActionContainer>
  );
};

export default SetAccountTypeAction;
