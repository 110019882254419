
export const reminderModalContent = () => {
  return {
  title: "Mark Complete",
  message: "Mark this reminder as complete?",
  confirmText: "Mark complete"
}}

export const stepModalContent = (step) => {
  return {
  title: "Complete Step",
  message: ['Mark ', <strong>{step}</strong>, ' and any tasks within complete?'],
  confirmText: "Mark tasks and step complete"
}}
