import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast as react_toast } from 'react-toastify';

const toast = {
  saved(msg, options = {}) {
    return react_toast.success(
      <>
        <FontAwesomeIcon icon={faCheck} className="mr-2" />
        {msg}
      </>,
      options
    );
  },
  success(msg, options = {}) {
    return react_toast.success(msg, options);
  },
  error(msg, options = {}) {
    return react_toast.error(msg, options);
  },
  info(msg, options = {}) {
    return react_toast.info(msg, options);
  },
  tip(msg, options = {}) {
    return react_toast(msg, options)
  },
};

export default toast;
