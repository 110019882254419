import { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import FullContext from 'stores/Full/fullContext';

export default function ChecklistItem({ item, onSave }) {
  const { isViewAsCustomer, isExternal } = useContext(FullContext);
  const [text, setText] = useState(item.description);
  const [hasChanged, setHasChanged] = useState(false);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (text !== item.description) {
      setHasChanged(true);
    }
  }, [item.description, text]);

  function handleSave() {
    setEditing(false);
    if (hasChanged) {
      onSave(item, text);
    }
  }

  return editing && !isExternal && !isViewAsCustomer ? (
    <Form.Control
      autoFocus
      className="checklist-item no-border mr-2-5"
      placeholder="Description..."
      disabled={isExternal}
      value={text}
      onChange={(e) => setText(e.target.value)}
      onBlur={handleSave}
      onKeyPress={(e) => e.key === 'Enter' && handleSave()}
    />
  ) : (
    <div className="cursor-pointer flex-grow-1" onClick={() => setEditing(true)}>
      {text}
    </div>
  );
}
