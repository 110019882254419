import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { FieldContainer as BaseFieldContainer } from '../CustomFields/Styles';

export const SectionName = styled.h2`
  margin-bottom: 0.875rem;
`;

export const ScrollableContainer = styled.div`
  max-height: 54vh;
  overflow-y: auto;
  padding-inline: 3.75rem;

  &::-webkit-scrollbar-track {
    background-color: var(--standard-snow);
    border: 1px solid var(--border-gray);
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
    background-color: var(--standard-snow);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: var(--modal-scrollbar);
  }
`;

export const FieldContainer = styled(BaseFieldContainer)`
  &:last-child {
    margin-bottom: 1rem;
  }
`;

export const FieldLabel = styled(Form.Label)`
  display: flex;
  align-items: center;
  gap: 0.2rem;
`;

export const FormFieldContainer = styled.div`
  &.date-time .form-control:disabled,
  &.date-time .form-control[readonly] {
    background-color: var(--light-gray-bg);
  }

  &.read-only {
    input,
    textarea,
    .select__placeholder,
    .select__single-value {
      color: var(--playbook-pewter);
    }

    input,
    textarea {
      background-color: var(--white);
    }

    .form-check {
      opacity: 30%;
    }

    .custom-field__input {
      svg {
        opacity: 30%;
      }
    }
  }

  .user.is-edit {
    .error-container {
      display: none;
    }
  }
`;

export const GrayIcon = styled(FontAwesomeIcon)`
  color: var(--playbook-pewter);
`;

export const AUMInformationContainer = styled.div`
  p {
    margin: 0;
    text-align: center;
  }
`;
