import styled, { css } from 'styled-components';

export const LoginContainer = styled.div`
  text-align: center;

  div[role='alert'] {
    text-align: left;
  }

  ${({ $hasError }) =>
    !$hasError &&
    css`
      form {
        margin-top: 3.188rem;
      }
    `}

  .otp-info {
    margin: 0 0 1.063rem 0;
  }

  .form-group {
    margin-bottom: 2.813rem;
  }

  .resend-link {
    color: var(--dodger-blue);
    font-weight: 400;
    text-decoration: underline;
    text-decoration-thickness: 0.063rem;
    cursor: pointer;
  }
`;
