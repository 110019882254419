import LoadingInline from 'components/Loading/LoadingInline';
import { withRouter } from 'helpers/RouteHelper';
import React from 'react';
import { Button } from 'react-bootstrap';
import * as Api from '../api/Api';
import FullScreenWithBackground from '../components/FullScreenWithBackground';
import * as CommonHelper from '../helpers/CommonHelper';

class ProcessToken extends React.Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.router.location.search);

    this.state = {
      token: params.get('token'),
      nextUrl: params.get('next'),
      action: params.get('action'),
      actionId: params.get('actionId'),
      taskId: params.get('task_id'),
      customerTouchpointId: params.get('customer_touchpoint_id'),
      error: false,
      loadingMessage: 'Redirecting...',
      newLinkSent: false,
    };
  }

  async componentDidMount() {
    let response;
    try {
      response = await Api.post('Authentication/SignInWithToken', null, {
        params: { tokenString: this.state.token },
        history: this.props.router.navigate,
      });
    } catch (error) {
      this.setState({ error: 'Unknown Error' });
    }
    if (response) {
      if (response.errorMessage) {
        this.setState({ error: response.errorMessage });
      } else {
        this.storeSession(response);
      }
    }
  }

  sendNewToken = async () => {
    let response;
    this.setState({ loadingMessage: 'Sending Link ...', error: false });
    try {
      response = await Api.post('Authentication/SendNewToken', null, {
        params: { tokenString: this.state.token, redirectLink: this.state.nextUrl },
        history: this.props.router.navigate,
      });
    } catch (error) {
      this.setState({ error: 'Unknown Error' });
    }
    if (response) {
      if (response.errorMessage) {
        this.setState({ error: response.errorMessage });
      } else {
        this.setState({ newLinkSent: true });
      }
    }
  };

  render() {
    return (
      <FullScreenWithBackground showLogo={false}>
        {this.state.error || this.state.newLinkSent ? (
          <div className="text-center">
            {this.state.newLinkSent ? (
              <>
                <h4 className="py-3">New link has been sent.</h4>
              </>
            ) : (
              <>
                <h1>Hi!</h1>
                <h4 className="py-3">{this.state.error}</h4>

                <Button
                  onClick={() => {
                    this.sendNewToken();
                  }}
                  variant="primary"
                  block
                >
                  Send new link
                </Button>
              </>
            )}
          </div>
        ) : (
          <LoadingInline text={this.state.loadingMessage} centered={true} />
        )}
      </FullScreenWithBackground>
    );
  }

  storeSession = async (data) => {
    localStorage.setItem('externalProject', this.state.nextUrl.match(/\/accounts\/\d+\/projects\/\d+/)?.[0]);
    this.setState({ loading: true });
    const { errorMessage } = await CommonHelper.loadUserData(data.authToken);
    if (this.state.action !== null) {
      if (this.state.action === 'completeCustomerTouchpoint') {
        await Api.post('Customer/SetCustomerTouchpointStatus', 'Completed', {
          params: { customerTouchpointId: this.state.actionId },
        });
      }
      if (this.state.action === 'complete_task') {
        await Api.post('Customer/UpdateTaskComplete', null, {
          params: {
            taskId: this.state.taskId,
            customerTouchpointId: this.state.customerTouchpointId,
            complete: true,
          },
        });
      }
    }
    if (errorMessage) {
      this.setState({ error: errorMessage, loading: false });
    } else {
      this.props.router.navigate(this.state.nextUrl);
    }
  };
}

export default withRouter(ProcessToken);
