import { useState, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import {
  NEW_SECTION,
  NEW_STAGEBASE_FIELD,
  FIELD,
  CONTACTS_FIELD,
  PROJECTS_FIELD,
  BASE_FIELD,
  CUSTOM_FIELD_MODES,
  PERMISSION_ENUM,
  PROJECTS,
  CONTACTS,
  DETAILS,
  OBJECT_TYPE_ENUM,
  PROJECTS_CUSTOM_SECTION,
} from 'constants';
import { initialFieldValues } from 'components/Modals/CustomFields/fixtures';
import { fieldSchema, baseFieldSchema, onFieldSubmission } from '../validations';

export const useAccountField = () => {
  const { populateNewField, updateField, isLabelExist } = useContext(CustomFieldsContext);

  const [showAddFieldModal, setShowAddFieldModal] = useState(false);
  const [fieldLabelType, setFieldLabelType] = useState(FIELD);
  const [objectType, setObjectType] = useState(OBJECT_TYPE_ENUM.UNKNOWN);

  const location = useLocation();
  const history = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const queryFieldId = queryParams.has(FIELD) ? queryParams.get(FIELD) : null;

  const currLocation = location.hash.split('#')[1];

  useEffect(() => {
    switch (currLocation) {
      case DETAILS:
        setFieldLabelType(FIELD);
        setObjectType(OBJECT_TYPE_ENUM.CUSTOMER);
        break;
      case CONTACTS:
        setFieldLabelType(CONTACTS_FIELD);
        setObjectType(OBJECT_TYPE_ENUM.CONTACTS);
        break;
      case PROJECTS:
        setFieldLabelType(PROJECTS_FIELD);
        setObjectType(OBJECT_TYPE_ENUM.PROJECTS);
        break;
      default:
        setFieldLabelType(FIELD);
        setObjectType(OBJECT_TYPE_ENUM.CUSTOMER);
        break;
    }
  }, [currLocation, location.hash]);

  const validationSchema = useMemo(() => {
    const isFieldSchema = [OBJECT_TYPE_ENUM.CUSTOMER, OBJECT_TYPE_ENUM.CONTACTS].includes(objectType);

    return isFieldSchema ? fieldSchema : baseFieldSchema;
  }, [objectType]);

  /**
   * Population of the Added Custom Field
   * to its corresponding endpoint
   * @param values
   * @returns void
   */
  const handlePopulateData = (values) => {
    const {
      fieldLabel,
      dataSource,
      sectionLabel,
      section,
      fieldType,
      fieldValues,
      permission,
      externalField,
      isRequired,
      isMergeTag,
      fieldMode,
      editedFieldId,
      isSortedFieldValues,
      previousFieldLabel,
    } = values;
    const isNewSection = section?.value === NEW_SECTION;
    const isNewStagebaseField = dataSource?.value === NEW_STAGEBASE_FIELD;

    const newFieldLabel = isNewStagebaseField ? fieldLabel : externalField?.label;
    const newPermission =
      isNewStagebaseField && fieldMode === CUSTOM_FIELD_MODES.ADD ? PERMISSION_ENUM.NA : permission?.request;

    const dropdownValues = fieldValues
      .filter(({ value }) => value !== '')
      .map((item) => ({ ...item, label: item.value }));

    const formattedFieldName = String(fieldLabel)
      .replace(/(^\w|\s\w)/g, (text) => text.toUpperCase())
      .split(' ')
      .join('');

    const targetSourceName = isNewStagebaseField ? formattedFieldName : externalField?.targetSource;
    const formattedDropdownValues = dropdownValues.map((value) => JSON.stringify(value));
    const formattedFieldOptions = dropdownValues.length > 0 ? formattedDropdownValues : null;
    const formattedSectionName = isNewSection && sectionLabel ? sectionLabel : section?.label;
    const formattedFieldType = isNewStagebaseField ? fieldType?.request : externalField?.type;
    const externalFieldId = isNewStagebaseField ? null : externalField?.id;
    const customFieldSectionName =
      objectType === OBJECT_TYPE_ENUM.PROJECTS ? PROJECTS_CUSTOM_SECTION : formattedSectionName;

    const addCustomFieldRequest = {
      objectType,
      sourceType: dataSource?.request,
      customFieldSectionName,
      fieldLabel: newFieldLabel,
      targetFieldName: targetSourceName,
      fieldType: formattedFieldType,
      fieldTypeOptions: formattedFieldOptions,
      syncOption: newPermission,
      isCustomField: isNewStagebaseField,
      isSortedFieldTypeOptions: isSortedFieldValues,
      externalDataFieldId: externalFieldId,
      isRequired,
      isMergeTag,
    };

    const editCustomFieldRequest = {
      fieldLabel: newFieldLabel,
      syncOption: newPermission,
      targetFieldName: targetSourceName,
      customFieldSectionName: formattedSectionName,
      fieldTypeOptions: formattedFieldOptions,
      isSortedFieldTypeOptions: isSortedFieldValues,
      isRequired,
      isMergeTag,
    };

    if (fieldMode === CUSTOM_FIELD_MODES.ADD) populateNewField({ ...addCustomFieldRequest }, objectType);

    if (fieldMode === CUSTOM_FIELD_MODES.EDIT) {
      if (newFieldLabel === previousFieldLabel) {
        delete editCustomFieldRequest['targetFieldName'];
      }

      updateField(editedFieldId, { ...editCustomFieldRequest }, objectType ?? OBJECT_TYPE_ENUM.CUSTOMER);
    }
  };

  /**
   * Submit handler for Formik's onSubmit instance
   * @param values
   * @returns void
   */
  const handleSubmit = (values) => {
    const { hasExistentLabel } = onFieldSubmission(formikBag, isLabelExist, fieldLabelType);
    // If there is an existent label, end process
    if (hasExistentLabel) return;

    handlePopulateData(values);

    handleHide();
  };

  /**
   * Closes the add custom field modal and resets the formik instance
   * @param none
   * @returns void
   */
  const handleHide = () => {
    setShowAddFieldModal(false);

    if (queryParams.has(FIELD) || queryParams.has(BASE_FIELD)) {
      queryParams.delete(FIELD);
      queryParams.delete(BASE_FIELD);
      history({
        search: String(queryParams),
      });
    }

    setTimeout(() => {
      formikBag.resetForm();
    }, 500);
  };

  /**
   * Add Custom Field Formik Instance
   */
  const formikBag = useFormik({
    initialValues: initialFieldValues,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const setFieldURLParam = (fieldId) => {
    if (!queryParams.has(BASE_FIELD)) {
      queryParams.set(BASE_FIELD, fieldId);
      history({
        search: queryParams.toString(),
      });
    }
  };

  /**
   * Opens the add custom field modal
   * @param none
   * @returns void
   */
  const handleShow = () => {
    setShowAddFieldModal(true);
  };

  return {
    formikBag,
    showAddFieldModal,
    handleHide,
    handleShow,
    queryFieldId,
    setFieldURLParam,
  };
};
