import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
} from 'components/Modals/LargePadding';
import { BILLING_PLANS } from 'constants';
import { format } from 'date-fns';
import React, { useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import FullContext from 'stores/Full/fullContext';
import { AUMInformationContainer } from './Styles';

const AUMInformation = ({ onHide, show, onSubmit }) => {
  const { appSettings } = useContext(FullContext);

  const history = useNavigate();

  const { stripeData, activeAccountCount } = appSettings;
  const customerCapping = stripeData?.customerCapping;
  const plan = stripeData?.plan;

  const memoizedAppSettings = useMemo(() => {
    const allowedPlans = [BILLING_PLANS.TIER_ONE, BILLING_PLANS.TIER_TWO];
    const isPlanAllowed = allowedPlans.includes(plan);

    const isLimitPlusOne = activeAccountCount === customerCapping?.end + 1;
    const isLimitPlusTwo = activeAccountCount >= customerCapping?.end + 2;
    const isFirstAccountOverLimit = isLimitPlusOne && isPlanAllowed;
    const isAccountOverLimit = isLimitPlusTwo && isPlanAllowed;
    const isFreeOrTierThree = !isPlanAllowed && activeAccountCount === customerCapping?.end;
    const scheduledDate = stripeData?.scheduledDate
      ? format(new Date(stripeData?.scheduledDate), 'MMM d, yyyy')
      : '';

    return {
      isFirstAccountOverLimit,
      isAccountOverLimit,
      scheduledDate,
      isFreeOrTierThree,
    };
  }, [activeAccountCount, customerCapping?.end, plan, stripeData?.scheduledDate]);

  const renderContent = (
    <AUMInformationContainer>
      <h1 className="mb-4 text-center">Account Limit Exceeded</h1>

      {memoizedAppSettings.isFreeOrTierThree && (
        <p>
          Creating a new account will exceed your current subscription's limit. You can increase the limit by
          making changes to your subscription.
        </p>
      )}

      {memoizedAppSettings.isFirstAccountOverLimit && (
        <p>
          Creating a new account will exceed your current subscription’s limit and result in an upgrade
          according to the terms of the subscription on {memoizedAppSettings.scheduledDate}.
        </p>
      )}

      {memoizedAppSettings.isAccountOverLimit && (
        <p>
          You are currently over the subscription limit for the number of accounts. Your subscription will
          automatically be upgraded on {memoizedAppSettings.scheduledDate}.
        </p>
      )}

      {!memoizedAppSettings.isFreeOrTierThree && <p className="mt-4">Do you wish to continue?</p>}
    </AUMInformationContainer>
  );

  const handleSubmit = () => {
    if (!memoizedAppSettings.isFreeOrTierThree) {
      onSubmit();
      return;
    }

    history('/settings#billing');
  };

  const renderButtons = (
    <>
      <ModalLargePaddingCancelButton onClick={onHide} autoWidth />
      <ModalLargePaddingConfirmButton
        onClick={handleSubmit}
        text={!memoizedAppSettings.isFreeOrTierThree ? 'Continue' : 'Manage subscription'}
        autoWidth
      />
    </>
  );

  const modalStyle = {
    inner: {
      maxWidth: '35.25rem',
      padding: '2.5rem 1.25rem !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  return (
    <ModalLargePadding
      show={show}
      innerProps={modalStyle.inner}
      buttonsContainerProps={modalStyle.buttonContainer}
      content={renderContent}
      buttons={renderButtons}
    />
  );
};

export default AUMInformation;
