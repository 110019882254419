import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CustomerContext from 'stores/Customer/customerContext';
import { Body, Container, Header } from './SharedStyles';

const CustomerEngagement = ({ canvasId, dateTodayFormat }) => {
  const { summaryData } = useContext(CustomerContext);
  const [externalEngagement, setExternalEngagement] = useState(null);
  useEffect(() => {
    const getExternalDates = summaryData?.filter((data) => data.id === canvasId)[0]?.engagement ?? null;
    setExternalEngagement(getExternalDates);
  }, [summaryData, canvasId]);

  return (
    <Container>
      <Header>Customer Project Engagement</Header>
      <Body>
        <Row className="mb-3">
          <Col className="font-weight-bold d-flex">Last Viewed</Col>
          <Col className="text-right mr-3">
            {externalEngagement?.lastCustomerLogin
              ? dateTodayFormat(externalEngagement?.lastCustomerLogin)
              : '--'}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="font-weight-bold d-flex">Last Task Completed</Col>
          <Col className="text-right mr-3">
            {externalEngagement?.lastCustomerTaskCompleted
              ? dateTodayFormat(externalEngagement?.lastCustomerTaskCompleted)
              : '--'}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="font-weight-bold d-flex">Last Upload</Col>
          <Col className="text-right mr-3">
            {externalEngagement?.lastCustomerUpload
              ? dateTodayFormat(externalEngagement?.lastCustomerUpload)
              : '--'}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="font-weight-bold d-flex">Last Comment</Col>
          <Col className="text-right mr-3">
            {externalEngagement?.lastCustomerComment
              ? dateTodayFormat(externalEngagement?.lastCustomerComment)
              : '--'}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="font-weight-bold d-flex">Last Project View</Col>
          <Col className="text-right mr-3">
            {externalEngagement?.lastCustomerView
              ? dateTodayFormat(externalEngagement?.lastCustomerView)
              : '--'}
          </Col>
        </Row>
        {/* <Row className='mb-3'>
          <Col className="font-weight-bold d-flex">Last Resource View</Col>
          <Col className="text-right mr-3">
            {externalEngagement?.lastCustomerView ? dateTodayFormat(externalEngagement?.lastCustomerView) : '--'}
          </Col>
        </Row> */}
      </Body>
    </Container>
  );
};

export default CustomerEngagement;
