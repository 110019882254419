import ModalConfirm from 'components/Modals/Confirm';
import React from 'react';
import { CancelContainer } from './Styles';

const Cancel = ({ onHide, onSubmit, show, planName, seats, periodEnd }) => {
  const renderContent = (
    <CancelContainer>
      <p>
        Are you sure you want to cancel this subscription for <strong>{planName}</strong> with{' '}
        <strong>{seats}</strong> seats? You will lose access after {periodEnd}.
      </p>
    </CancelContainer>
  );

  return (
    <ModalConfirm
      title="Canceling Subscription"
      message={renderContent}
      show={show}
      confirmText="Yes"
      cancelText="No"
      onConfirm={onSubmit}
      onHide={onHide}
    />
  );
};

export default Cancel;
