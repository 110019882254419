import { dateMixin } from 'mixins';

class Canvas extends dateMixin() {
  ageDays = null;
  canvasId = null;
  canvasName = '';
  // TODO: define constants or objects?
  canvasTags = [];
  canvasTemplateName = null;
  color = '';
  currentJourneyId = null;
  currentJourneyName = '';
  currentTouchpointId = null;
  currentTouchpointName = '';
  customerId = null;
  dynamicTimeline = false;
  endDate = '';
  externalsHaveTaskItemPermissions = false;
  id = null;
  order = null;
  progress = null;
  projectedDate = '';
  startDate = '';
  status = '';

  constructor(props) {
    super(props);

    if (props) {
      Object.assign(this, props);
    }
  }
}

export default Canvas;
