import { useRef, useContext } from 'react';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { Wrapper, Entry, CheckMark } from '../styles';
import useOutsideClickNotifier from '../../../../../helpers/hooks/useOutsideClickNotifier';
import DateChangeContext from '../context/dateChangeContext';

export default function Amount() {
  const { durationAmount, updateDurationAmount, toggleDurationAmountModal, durationOption } =
    useContext(DateChangeContext);

  const clickRef = useRef(null);
  useOutsideClickNotifier(clickRef, () => {
    toggleDurationAmountModal(false);
  });

  const handleSelection = (value) => {
    updateDurationAmount(value);
    toggleDurationAmountModal(false);
  };

  const isDay = durationOption === 'Days';

  const getValue = (k) => (isDay ? k : k + 1);

  return (
    <Wrapper ref={clickRef} width="105px" left="0">
      {Array.from({ length: isDay ? 32 : 31 }, (_, k) => (
        <Entry key={getValue(k)} value={getValue(k)} onClick={() => handleSelection(getValue(k))}>
          {durationAmount === getValue(k) && <CheckMark icon={faCheck} size="xs" top="5px" />}
          {k === 0 && durationOption === 'Days' ? 'Same' : getValue(k)}
        </Entry>
      ))}
    </Wrapper>
  );
}
