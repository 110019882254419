import React, { useState, useRef, useEffect } from 'react';
import Select, { components } from 'react-select';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOutsideClickNotifier from 'helpers/hooks/useOutsideClickNotifier';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.div`
  div[id$='-heading'] {
    color: var(--action-anchor);
    font-size: 0.688rem;
    font-weight: 700;
    line-height: 15px;
    text-transform: uppercase;
  }
`;

export const DropdownContainer = styled.div`
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: var(--box-shadow-menu);
  position: absolute;
  ${({ isDropDownLastItem, itemCount, withFooterLink }) => {
    if (withFooterLink) {
      return `top: ${isDropDownLastItem ? (itemCount < 3 ? '-12rem' : '-14.5rem') : '-1.75rem'};`;
    } else {
      return `top: ${isDropDownLastItem ? (itemCount < 3 ? '-9.25rem' : '-11.75rem') : '-1.75rem'};`;
    }
  }}
  left: 0.5rem;
  z-index: 2;
  width: 100%;

  & > div > div[class$='-control'] > div > div:last-child {
    margin-left: 2rem;
  }

  .css-1n7v3ny-option,
  .css-9gakcf-option:hover {
    background-color: var(--goal-gray);
  }

  .css-9gakcf-option {
    background-color: transparent;
    color: inherit;
  }

  .css-4ljt47-MenuList {
    max-height: 8rem;
    overflow-x: hidden;
  }
`;

export const Input = styled(Select)`
  .select__control {
    background-color: var(--light-gray-bg);
    border-style: none;

    &--is-focused {
      filter: drop-shadow(0 0px 3px var(--schedule-sapphire-focus));
      box-shadow: none;
    }
  }

  svg {
    color: var(--schedule-sapphire);
  }

  .select__control--is-disabled {
    pointer-events: all;
    cursor: not-allowed;

    svg {
      opacity: 30%;
    }
  }

  .select__clear-indicator {
    svg {
      visibility: hidden;
    }
  }

  ${({ open }) =>
    open &&
    css`
      .select__control {
        border-color: var(--outline);
        box-shadow: 0 0 0 1px var(--outline);
        &--is-focused {
          filter: drop-shadow(0 0px 3px var(--schedule-sapphire-focus));
          box-shadow: none;
        }
      }
    `}
`;

export const Menu = styled.div`
  position: relative;
`;

export const MenuFooter = styled.div`
  margin: 0.5rem 1.375rem;
`;

export const ClearIconContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 3rem;

  svg {
    color: var(--playbook-pewter);
  }
`;

export const SearchIconContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: 1.375rem;
`;

export const MultiOptionContainer = styled.div`
  svg {
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    color: var(--schedule-sapphire);
    height: 0.75rem;
  }
`;

export const SearchableSelectContainer = styled.div`
  position: relative;
`;

const SearchableSelect = ({
  options,
  selectValue,
  onChange,
  onBlur,
  onClearValues,
  onFocus,
  placeholder,
  hasDefaultPlaceholder = false,
  disabled,
  menuFooterLink,
  initialMultiValue,
  isMulti = false,
  showClearIndicator = true,
  isDropDownLastItem,
}) => {
  const [open, setOpen] = useState(false);
  const [multiList, setMultiList] = useState([]);

  const clickRef = useRef(null);

  useOutsideClickNotifier(clickRef, () => {
    if (open && onBlur) onBlur();

    setOpen(false);
  });

  /**
   * On Focus handler for GroupedSelect
   * @param none
   * @returns void
   */
  const onFocusSelect = () => {
    onToggle();

    if (onFocus) onFocus();
  };

  /**
   * Dropdown menu visibility toggle handler
   * @param none
   * @returns void
   */
  const onToggle = () => setOpen((prev) => !prev);

  useEffect(() => {
    setMultiList(initialMultiValue);
  }, [initialMultiValue]);

  /**
   * On Change handler for GroupedSelect
   * @param value
   * @returns void
   */
  const onSelectChange = (value) => {
    !isMulti && onToggle();
    isMulti && setMultiList(value);

    if (onChange) onChange(value);
  };

  /**
   * Customized Group component
   * @param props
   * @returns Group component
   */
  const Group = (props) => {
    return (
      <HeaderContainer>
        <components.Group {...props} />
      </HeaderContainer>
    );
  };

  const hasGroup = JSON.stringify(options)?.includes('"options":');

  /**
   * Check if there is a multi selected value
   * @param option
   * @returns boolean
   */
  const isMultiOptionSelected = (option) => multiList.some(({ label }) => label === option.label);

  /**
   * Renders a Custom Option Label
   * @param option
   * @returns Option Label Component
   */
  const getOptionLabel = (option) => (
    <div>
      {isMulti ? (
        <MultiOptionContainer isVisible={isMultiOptionSelected(option)}>
          <FontAwesomeIcon icon={faCheck} className="mr-2" />
          <span className="ml-2">{option.label}</span>
        </MultiOptionContainer>
      ) : (
        <span className={hasGroup ? 'ml-4' : 'ml-2'}>{option.label}</span>
      )}
    </div>
  );

  /**
   * Modifies the provided option style
   * if isMulti is true
   * @param provided
   * @returns option style
   */
  const getOptionStyle = (provided) =>
    isMulti
      ? {
          ...provided,
          backgroundColor: 'var(--white)',
          color: 'var(--champion-charcoal)',
        }
      : { ...provided };

  const popoutSelectStyles = {
    control: (provided) => ({
      ...provided,
      margin: 8,
    }),
    menu: () => ({ boxShadow: 'unset' }),
    option: (provided) => getOptionStyle(provided),
  };

  const clearIndicatorStyles = (base) => ({
    ...base,
    cursor: 'pointer',
    borderRight: showClearIndicator && '1px solid var(--playbook-pewter)',
    height: 22,
  });

  const inputSelectStyles = {
    menu: () => ({ display: 'none' }),
    clearIndicator: clearIndicatorStyles,
  };

  const formattedInputValue = isMulti ? multiList.map(({ label }) => label).join(', ') : selectValue?.label;

  const inputValue = formattedInputValue ? { ...selectValue, label: formattedInputValue } : null;

  const CustomMenu = (props) => {
    return (
      <>
        <components.Menu {...props}>{props.children}</components.Menu>
        {menuFooterLink && (
          <MenuFooter>
            <Link to={menuFooterLink} className="font-weight-bold">
              Edit values
            </Link>
          </MenuFooter>
        )}
      </>
    );
  };

  return (
    <SearchableSelectContainer ref={clickRef}>
      <Input
        open={open}
        value={inputValue}
        options={undefined}
        classNamePrefix="select"
        readOnly
        isDisabled={disabled}
        placeholder={open || hasDefaultPlaceholder ? placeholder : ''}
        isSearchable={false}
        onFocus={onFocusSelect}
        isClearable
        components={{
          IndicatorSeparator: null,
        }}
        styles={inputSelectStyles}
      />

      {(multiList?.length > 0 || selectValue) && showClearIndicator && (
        <ClearIconContainer
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onClearValues();
            setOpen(true);
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </ClearIconContainer>
      )}

      {open && (
        <Menu>
          <DropdownContainer
            itemCount={options?.length}
            isDropDownLastItem={isDropDownLastItem}
            withFooterLink={!!menuFooterLink}
          >
            <Select
              options={options}
              components={{
                Group,
                Menu: CustomMenu,
                DropdownIndicator: null,
                IndicatorSeparator: null,
              }}
              placeholder=""
              backspaceRemovesValue={false}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              styles={popoutSelectStyles}
              menuIsOpen
              onChange={onSelectChange}
              getOptionLabel={getOptionLabel}
              tabSelectsValue={false}
              value={selectValue}
              isMulti={isMulti}
            />

            <SearchIconContainer>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </SearchIconContainer>
          </DropdownContainer>
        </Menu>
      )}
    </SearchableSelectContainer>
  );
};

export default SearchableSelect;
