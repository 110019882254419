import styled from 'styled-components';

export const AddCardContainer = styled.div`
  padding: 2rem 3.781rem 2.438rem 3.781rem;

  h2 {
    margin-bottom: 1.25rem;
  }

  hr {
    margin-block: 1.75rem;
  }

  .card {
    input,
    .StripeElement {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: var(--champion-charcoal);
      background-color: var(--white);
      background-clip: padding-box;
      border: 1px solid var(--whisper);
      border-radius: 0.375rem;
      display: block;
      width: 100%;
      padding: 0.75rem;
      transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
    }

    .card-focus {
      outline: 0;
      border-color: hsla(210, 96%, 45%, 50%);
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
        0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
    }

    .card-error {
      color: var(--danger);
      border-color: var(--danger);
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px var(--danger);
    }
  }

  .label {
    margin-bottom: 0;
  }

  .expiry-dropdowns {
    display: flex;

    .month-picker {
      width: 5.031rem;
    }

    .year-picker {
      width: 5.906rem;
    }
  }
`;

export const ErrorMessageContainer = styled.div`
  color: var(--danger);
  margin-top: 0.5rem;
`;
