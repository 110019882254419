import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { faAngleDown, faAngleRight, faExclamation } from '@fortawesome/pro-solid-svg-icons';
import TableHeader from '../../TableHeader';
import ExpandableTableRow from '../../ExpandableTableRow';
import * as AutomationRulesApi from '../../../api/automationRules';
import { ACTIVITY_LOG_AUTOMATION_TYPE, ACTIVITY_LOG_AUTOMATION_TYPE_DISPLAY_VALUE } from '../../../constants/automationRules';

const Column = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  [data-icon='angle-right'],
  [data-icon='angle-down'] {
    position: relative;
    top: 1px;
    color: var(--schedule-sapphire);
  }
`;

const Timestamp = styled.div`
  white-space: nowrap;
  color: var(--implement-iron);
`;

const ToggleExpandButton = styled.button`
  border: none;
  background-color: transparent;
`;

const ExclamationIcon = styled(FontAwesomeIcon)`
  position: relative;
  top: 1px;
  margin-right: 7px;
  font-weight: bold;
  color: var(--danger);
`;

/**
 * Activity log component
 * @returns {JSX.Element}
 */
const ActivityLog = ({ ruleId }) => {
  const [activityLog, setActivityLog] = useState([]);

  const renderRow = (log) => {
    const hasError = log?.activityLog?.activityType === ACTIVITY_LOG_AUTOMATION_TYPE.automationError;
    const date = log?.dateCreated ? format(new Date(log?.dateCreated), 'p LLL dd') : '-';

    return (
      <ExpandableTableRow
        key={log?.activityLogId}
        renderDetails={() => <div dangerouslySetInnerHTML={{ __html: log?.activityLog?.message }}></div>}
      >
        {({ isExpanded, setExpanded }) => (
          <>
            <Column md={2} title="Rule Executed Successfully">
              <div>
                {hasError && <ExclamationIcon icon={faExclamation} />}
                {ACTIVITY_LOG_AUTOMATION_TYPE_DISPLAY_VALUE[log?.activityLog?.activityType] ||
                  log?.activityLog?.activityType}
              </div>
              {hasError && (
                <ToggleExpandButton onClick={() => setExpanded((prevExpanded) => !prevExpanded)}>
                  <FontAwesomeIcon
                    icon={isExpanded ? faAngleDown : faAngleRight}
                    size="sm"
                    className="ml-2"
                  />
                </ToggleExpandButton>
              )}
            </Column>
            <Column md={3} title={date}>
              <Timestamp>{date}</Timestamp>
            </Column>
            <Column>
              <div dangerouslySetInnerHTML={{ __html: log?.activityLog?.message }}></div>
            </Column>
          </>
        )}
      </ExpandableTableRow>
    );
  };

  useEffect(() => {
    const fetchActivityLog = async () => {
      if (!ruleId) {
        return;
      }

      const response = await AutomationRulesApi.fetchAutomationRuleActivityLog(ruleId);
      setActivityLog(response);
    };

    fetchActivityLog();
  }, [ruleId]);

  return (
    <>
      <TableHeader>
        <Col md={2}>Event</Col>
        <Col md={3}>Timestamp</Col>
        <Col>Description</Col>
      </TableHeader>
      {activityLog?.map(renderRow)}
    </>
  );
};

export default ActivityLog;
