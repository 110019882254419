import React, { Component } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import FullScreenWithBackground from '../../components/FullScreenWithBackground';
import * as Api from '../../api/Api';
import { EmailField, TermsAndPolicyContainer } from './styles';
import { IconAppend } from 'views/Settings/styles';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EXTERNAL } from 'constants';
import { loadUserData } from 'helpers/CommonHelper';
import { withRouter } from 'helpers/RouteHelper';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.router.location.search);

    this.state = {
      password: '',
      confirmPassword: '',
      email: params.get('email'),
      code: params.get('code'),
      changed: false,
      termsAndPolicy: false,
      showPassword: false,
      showConfirmPassword: false,
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <FullScreenWithBackground
        title={this.props.create ? 'Create password' : 'Reset password'}
        loading={this.state.loading}
        error={this.state.error}
      >
        <form onSubmit={this.handleSubmit}>
          {this.props.create && (
            <Form.Group className="mb-3">
              <EmailField name="email" placeholder="Email" value={this.state.email} disabled />
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <InputGroup>
              <Form.Control
                name="password"
                type={this.state.showPassword ? 'text' : 'password'}
                placeholder="Password"
                onChange={this.handleInputChange}
              />
              <IconAppend
                type="button"
                onClick={() =>
                  this.setState((prev) => {
                    return {
                      ...prev,
                      showPassword: !prev.showPassword,
                    };
                  })
                }
              >
                <FontAwesomeIcon
                  className="border-start-0"
                  icon={this.state.showPassword ? faEyeSlash : faEye}
                />
              </IconAppend>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <InputGroup>
              <Form.Control
                name="confirmPassword"
                type={this.state.showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm Password"
                onChange={this.handleInputChange}
              />
              <IconAppend
                type="button"
                onClick={() =>
                  this.setState((prev) => {
                    return {
                      ...prev,
                      showConfirmPassword: !prev.showConfirmPassword,
                    };
                  })
                }
              >
                <FontAwesomeIcon
                  className="border-start-0"
                  icon={this.state.showConfirmPassword ? faEyeSlash : faEye}
                />
              </IconAppend>
            </InputGroup>
          </Form.Group>
          {this.props.create && (
            <Form.Group className="mb-3 pb-1 d-flex">
              <Form.Check
                name="termsAndPolicy"
                checked={this.state.termsAndPolicy}
                onClick={() =>
                  this.setState((prev) => {
                    return { ...prev, termsAndPolicy: !prev.termsAndPolicy };
                  })
                }
              />
              <TermsAndPolicyContainer>
                By clicking 'Submit' you agree to the{' '}
                <a href="https://stagebase.com/terms-and-conditions/" target="#blank" className="mr-1">
                  Terms of Service
                </a>
                and{' '}
                <a href="https://stagebase.com/privacy-policy/" target="#blank">
                  Privacy Policy.
                </a>
              </TermsAndPolicyContainer>
            </Form.Group>
          )}
          <Button
            type="submit"
            id="btn"
            variant="primary"
            onClick={this.handleSubmit}
            block
            disabled={this.props.create && !this.state.termsAndPolicy}
          >
            Submit
          </Button>
        </form>
      </FullScreenWithBackground>
    );
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    if (!this.state.password || !this.state.confirmPassword) {
      this.setState({ error: 'Please provide both password and confirm password.' });
      return;
    }

    this.setState({ error: null, loading: true });

    let params = {};
    if (this.state.code) {
      params = {
        email: this.state.email,
        code: this.state.code,
      };
    } else {
      params = {
        email: this.state.email,
      };
    }

    let payload = {
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    };

    let response, json;

    try {
      response = await Api.post('Registration/ResetPassword', payload, { params, rawResponse: true });
    } catch (error) {
      console.error(error);
    }

    if (!response) {
      this.setState({ error: "There's a problem connecting to the server.", loading: false });
      return;
    }

    try {
      json = await response.json();
    } catch (error) {
      console.error(error);
    }

    if (json) {
      if (!json.hasOwnProperty('errorMessage')) {
        await this.storeSession(response);
      } else {
        this.setState({ error: json.errorMessage, loading: false });
      }
    } else {
      this.setState({ error: 'Unable to complete registration at this time.', loading: false });
    }
  };

  storeSession = async (data) => {
    const { errorMessage, role } = await loadUserData(data.authToken);

    this.setState({ loading: false });
    if (errorMessage) {
      this.setState({ error: errorMessage });
    } else {
      this.goToDashboard(role);
    }
  };

  goToDashboard = (role) => {
    if (role !== EXTERNAL) {
      window.pendo.initialize({
        visitor: {
          id: this.state.userGlobalId,
          email: this.state.username,
        },
        account: {
          id: this.state.tenantUuid,
          name: this.state.orgName,
        },
      });
    }

    this.props.router.navigate('/dashboard');
  };
}

export default withRouter(ChangePassword);
