import React, { useState, useEffect, useRef, useContext } from 'react';
import * as Api from '../../api/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { displayMoney } from 'helpers/CommonHelper';
import {
  TableWrapperStyle,
  TableTitleStyle,
  TableStyle,
  TableDataStyle,
  NoDataTextStyle,
  HeaderMessageBox,
} from './SharedStyles';
import TableHeader from './TableHeader';
import { sortByLetter, sortByNumber, sortActiveProjects } from './sorting';
import SingleBarChart from 'components/Charts/SingleBarChart';
import styled from 'styled-components';
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import ReportingContext from './context/reportingContext';
import HeaderFilter from './HeaderFilter';

const StyledBody = styled.tbody`
  max-height: 356px !important;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--schedule-sapphire);
`;

const TeamWorkload = ({ showTable, setShowTable }) => {
  const [activeManagedRevenues, setActiveManagedRevenues] = useState([]);
  const [sortedColumn, setSortedColumn] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [topProjects, setTopProject] = useState(0);
  const [tooltipContent, setTooltipContent] = useState({});
  const [showTooltip, setShowToolTip] = useState(false);
  const [elRef, setElRef] = useState(null);
  const { activeAccountFilters } = useContext(ReportingContext);

  const table = useRef();

  useEffect(() => {
    const getTeamWorkLoadData = async () => {
      try {
        let accountFilters = '';
        if (activeAccountFilters?.length > 0) {
          activeAccountFilters.forEach((filter, index) => {
            accountFilters += index === 0 ? `?clientTypes=${filter}` : `&clientTypes=${filter}`;
          });
        }
        const response = await Api.get(`Report/GetTeamWorkloads${accountFilters}`);
        setActiveManagedRevenues(response);

        let highestValue = 0;
        response.forEach((item) => {
          highestValue = item.totalProjectsOwned > highestValue ? item.totalProjectsOwned : highestValue;
        });
        setTopProject(highestValue);
      } catch (error) {
        console.log(error);
      }
    };
    getTeamWorkLoadData();
  }, [activeAccountFilters]);

  return (
    <>
      <TableWrapperStyle className="my-0">
        <TableTitleStyle onClick={setShowTable}>
          <span className="title">
            <StyledIcon icon={showTable ? faAnglesUp : faAnglesDown} className="mr-2" />
            Team Workload
          </span>
          {activeAccountFilters.length > 0 && (
            <HeaderFilter setShowFilters={setShowFilters} showFilters={showFilters} useTimeFilter={false} />
          )}
        </TableTitleStyle>
        {showTable && (
          <TableStyle ref={table}>
            <thead>
              <tr>
                <TableHeader
                  sortedColumn={sortedColumn}
                  table={table}
                  sortByFunction={sortByLetter}
                  setSortedColumn={setSortedColumn}
                  flexBasis="25"
                >
                  Project Owner
                </TableHeader>
                <TableHeader
                  sortedColumn={sortedColumn}
                  table={table}
                  sortByFunction={sortActiveProjects}
                  setSortedColumn={setSortedColumn}
                  flexBasis="50"
                >
                  Past Due / Total Active Projects
                </TableHeader>
                <TableHeader
                  sortedColumn={sortedColumn}
                  table={table}
                  sortByFunction={sortByNumber}
                  setSortedColumn={setSortedColumn}
                  flexBasis="25"
                >
                  Managing Value
                </TableHeader>
              </tr>
            </thead>
            <StyledBody>
              {activeManagedRevenues?.length > 0 ? (
                activeManagedRevenues.map((bar) => (
                  <tr key={bar.ownerId}>
                    <TableDataStyle flexBasis="25">{`${bar?.ownerFirstName} ${bar?.ownerLastName}`}</TableDataStyle>
                    <TableDataStyle flexBasis="50">
                      {showTooltip && (
                        <HeaderMessageBox
                          top={elRef.current.getBoundingClientRect().top - 20}
                          left={
                            elRef.current.getBoundingClientRect().left +
                            elRef.current.getBoundingClientRect().width / 8
                          }
                        >
                          {`${((tooltipContent.pastDue / tooltipContent.totalActive || 0) * 100).toFixed(
                            0
                          )}% of projects are past due. (${tooltipContent.pastDue} out of ${
                            tooltipContent.totalActive
                          }).`}
                        </HeaderMessageBox>
                      )}
                      <SingleBarChart
                        firstBar={bar?.overDueProjectsOwned}
                        secondBar={bar?.totalProjectsOwned}
                        firstBarColor="var(--orange-bright)"
                        secondBarColor="var(--purple-bright)"
                        total={topProjects}
                        hoverOn={(ref, pastDue, totalActive) => {
                          setTooltipContent({
                            pastDue,
                            totalActive,
                          });
                          setElRef(ref);
                          setShowToolTip(true);
                        }}
                        hoverOut={() => {
                          setTooltipContent({});
                          setElRef(null);
                          setShowToolTip(false);
                        }}
                      />
                    </TableDataStyle>
                    <TableDataStyle flexBasis="25">{displayMoney(bar?.valueManged)}</TableDataStyle>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="w-full">
                    <NoDataTextStyle>No active projects past due</NoDataTextStyle>
                  </td>
                </tr>
              )}
            </StyledBody>
          </TableStyle>
        )}
      </TableWrapperStyle>
    </>
  );
};

export default TeamWorkload;
