
export const LOADING_TITLES = {
  IN_PROGRESS: { title: 'Creating your Free Account', subTitle: 'This should take less than a minute' },
  COMPLETED: {
    title: 'Account Created',
    subTitle: 'Check your inbox (or spam folder) to verify your email address and create a password. If you have questions or problems, please contact support@stagebase.com.',
  },
  FAILED: {
    title: 'Sorry, this is taking longer than expected.',
    subTitle: 'We will send you an email when your account is set up.',
  },
  DUPLICATE: {
    title: 'Your company already has an account',
    subTitle: 'Someone from your company already has a Stagebase account.',
  },
  EMAIL_SENT: {
    title: 'Email Sent',
    subTitle: 'Someone from our team will look into your company\'s account and respond in the next business day.',
  },
  PROHIBITED: {
    title: 'Sorry, we cannot create your free account',
    subTitle: 'Set up a call with our team to discuss options.',
  },
};

export const SIGNUP_ERROR_MESSAGES = {
  PERSONAL_EMAIL: "Company Email Required",
  PROHIBITED_EMAIL: "Unable to add user with a prohibited email domain",
  DUPLICATE_EMAIL: "Found existing user from the same domain",
  DUPLICATE_COMPANY: "is already in use"
}

export const COMPANY = "Company"
