import { useContext, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { camelize, noop } from 'helpers/CommonHelper';
import { useParser } from 'views/Customers/hooks';
import { getFieldType } from 'components/CustomFields/Details/fixtures';
import FullContext from 'stores/Full/fullContext';

export const useProfile = (userProfile) => {
  const { fetchInitialProfileData, profile } = useContext(CustomFieldsContext);
  const { isExternal } = useContext(FullContext);

  const { handleParseResponseValue } = useParser();

  useEffect(() => {
    fetchInitialProfileData();
  }, [fetchInitialProfileData]);

  const newProfileFields = profile?.editableFields
    ?.filter(({ isHidden }) => !isHidden)
    .map((field) => {
      const { targetFieldName, fieldType, fieldTypeOptions } = field;

      const newTargetFieldName = camelize(targetFieldName);
      const newFieldType = getFieldType(fieldType);
      const newFieldTypeOptions = fieldTypeOptions ? fieldTypeOptions.map((value) => JSON.parse(value)) : [];
      const mappedFieldName = userProfile[newTargetFieldName] ?? userProfile[targetFieldName];
      const formikValue = handleParseResponseValue(field, mappedFieldName);

      return {
        ...field,
        fieldType: newFieldType,
        fieldTypeOptions: newFieldTypeOptions,
        targetFieldName: newTargetFieldName,
        formikValue,
      };
    });

  const profileFields = useMemo(
    () => (isExternal ? newProfileFields.filter(({ isProtected }) => isProtected) : newProfileFields),
    [isExternal, newProfileFields]
  );

  /**
   * Add Custom Field Formik Instance
   */
  const formikBag = useFormik({
    initialValues: { profileFields, userId: userProfile?.id },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: noop,
  });

  return {
    formikBag,
  };
};
