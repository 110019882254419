import {
  faCheck,
  faStar,
  faPause,
  faCalendarPlus,
  faHexagonMinus,
  faAnglesRight,
} from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
  faMapMarkerAlt
} from '@fortawesome/pro-solid-svg-icons';
import * as Api from '../api/Api';
import styled from 'styled-components';
import { SessionStorage } from 'models';
import { HOVERED, TASK_ITEM, EXTERNAL, FIELD_TYPES } from 'constants/';

export let accessToken = '';

const IconContainer = styled.div`
  background: ${({ background }) => background};
  opacity: ${({ hideIcon }) => hideIcon && '0'};
  border: ${({ isHovered }) => (isHovered ? '1px solid var(--playbook-pewter)' : 'transparent')};
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${({ color }) => color};
`;

export function getItemFromLocalStorage(item) {
  return localStorage.getItem(item);
}

export function getOrg() {
  const org = getItemFromLocalStorage('org');
  return org && JSON.parse(org);
}

export function getExternalUserOrg() {
  const orgName = getItemFromLocalStorage('externalUserOrg');
  return orgName && orgName + '';
}

export function getOrgName() {
  const org = getOrg();
  return org && org.name;
}

export function hasMultipleOrgs() {
  return getItemFromLocalStorage('hasMultipleOrgs');
}

export function getExternalProject() {
  return getItemFromLocalStorage('externalProject');
}

export function getCustomerTouchpointStatusList() {
  return [
    {
      value: 'InProgress',
      title: 'In progress',
      color: '#4c65ef',
      gradient: 'scope-sky',
      // color: '#008BE7',
    },
    {
      value: 'NotStarted',
      title: 'Not started',
      // color: '#676767',
      color: '#BDC3C7',
    },
    //{
    //    value: 'PastDue',
    //    title: 'Past Due',
    //    color: '#FF4500'
    //},
    // {
    //   value: 'OnHold',
    //   title: 'On hold',
    //   color: '#9902C1',
    //   gradient: 'retention-rose',
    // },
    {
      value: 'Paused',
      title: 'Paused',
      color: '#9902C1',
      gradient: 'process-peach',
    },
    // {
    //   value: 'OffTrack',
    //   title: 'Off track',
    //   color: '#DDA405',
    //   gradient: 'process-peach',
    // },
    {
      value: 'Blocked',
      title: 'Blocked',
      // color: '#C10202',
      color: '#FF4A52',
      // gradient: 'retention-rose'
    },
    {
      value: 'NotApplicable',
      title: 'Not Applicable',
      color: '#676767',
    },
    {
      value: 'Completed',
      title: 'Completed',
      color: '#1A7200',
      gradient: 'join-jade',
    },
  ];
}

export function getActivityTypes() {
  return [
    {
      value: 'ChecklistItemCompleted',
      title: 'Completed checklist item',
    },
    {
      value: 'ChecklistItemUncompleted',
      title: 'Uncompleted checklist item',
    },
    {
      value: 'TouchpointCompleted',
      title: 'Completed touchpoint',
    },
    {
      value: 'DocumentUploaded',
      title: 'Uploaded document',
    },
    {
      value: 'UserAssignedToTouchpoint',
      title: 'Assigned user to touchpoint',
    },
  ];
}

export function getFrontColor(backgroundColor) {
  const whiteFrontColors = ['#ffee00', '#00fffd'];
  if (whiteFrontColors.includes(backgroundColor)) return 'black';

  return 'white';
}

export function getCustomerTouchpointStatus(value) {
  let list = getCustomerTouchpointStatusList();
  return list.find((x) => x.value === value) || list.find((x) => x.value === 'InProgress');
}

export function displayMoney(num) {
  let result = Math.ceil(num);
  return isNaN(result) ? `$${num}` : '$' + commafy(result);
}

export function abbreviateMoney(value) {
  let newValue = value;
  if (value >= 1000) {
    const suffixes = ['', 'k', 'm', 'b', 't'];
    const valueLength = value.toString().split('.')[0].length;
    const suffixIndex = Math.floor(valueLength / 3);
    const suffixNum = valueLength % 3 === 0 ? suffixIndex - 1 : suffixIndex;
    const expNum = Math.floor(valueLength / (valueLength % 3 === 0 ? 4 : 3));
    let shortValue = parseFloat(suffixNum !== 0 ? value / Math.pow(1000, expNum) : value);
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  const formattedValue = displayMoney(newValue);
  return formattedValue;
}

export function displayRole(role) {
  if (role === EXTERNAL) {
    return 'External User';
  } else if (role) {
    return 'Internal User';
  }
}

export function commafy(num) {
  var str = num.toString().split('.');
  if (str[0].length >= 5) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join('.');
}

/**
 * Extracts the access token from the cookie document
 * @param token sets the token in the local accesstoken variable of common helper
 * @returns void
 */
export function setAccessToken(token) {
  accessToken = token;
}

export async function loadUserData(accessToken) {
  let response;
  let errorMessage;

  try {
    response = await Api.get('Account/UserData');
  } catch (error) {
    errorMessage = error.message;
  }

  if (response) {
    if (response.errorMessage) {
      errorMessage = response.errorMessage;
    } else {
      if (response.role === 'EXTERNAL' && !getExternalProject()) {
        errorMessage = 'Unable to log into this account. Please try again later';
        logOut();
      }
    }
  } else {
    errorMessage = 'An unknown error has occurred. Please try again later.';
  }
  if (errorMessage) {
    accessToken = '';
  }

  return { errorMessage, role: response?.role };
}

export function setOrgData(data) {
  if (data?.activeOrganization) {
    localStorage.setItem('org', JSON.stringify(data?.activeOrganization));
  }
  if (data?.items && data?.items.length > 1) {
    localStorage.setItem('hasMultipleOrgs', true);
  }
}

export const clearLocalStorage = () => {
  [
    'authToken',
    'calendar-base-date',
    'fullName',
    'hasMultipleOrgs',
    'opportunities-table-page-size',
    'opportunities-table-page',
    'org',
    'salesforceIntegrated',
    'surveyMonkeyIntegrated',
    'externalUserOrg',
    'tenantLogo',
  ].forEach((key) => localStorage.removeItem(key));
};

export async function logOut(props) {
  setAccessToken('');
  clearLocalStorage();
  SessionStorage.clear();
  if (typeof props !== 'boolean') {
    await Api.get('Authentication/SignOut');
  }
  props ? props.router.navigate('/login') : window.location.replace('/login');
}

export function isComplete(item) {
  return item.customerTouchpointStatus === 'Completed' || item.status === 'Completed' || item.completed;
}

export function getIconInfo(item, color = '') {
  const statusIcons = {
    InProgress: faAnglesRight,
    NotStarted: faCircle,
    Paused: faPause,
    Blocked: faHexagonMinus,
    Completed: faCheck,
    Hovered: faCheck,
    NotApplicable: faCheck
  };
  let icon = faMapMarkerAlt;
  let iconColor;
  let circleColor;
  let className = '';
  let statusTitle;
  const isHovered = item?.customerTouchpointStatus === HOVERED;
  if (item && item.calendarItemType === TASK_ITEM) {
    icon = faStar;
    iconColor = 'var(--purple-bright)';
    if (item.completed) {
      iconColor = 'white';
      className = 'join-jade';
    }
  } else {
    if (item) {
      let { customerTouchpointStatus: status, dateToBeScheduled: isScheduled } = item;
      if (isScheduled && ['InProgress', 'NotStarted'].includes(status)) {
        icon = faCalendarPlus;
      } else if (status) {
        icon = statusIcons[status];
      }
    }

    iconColor = 'white';
    circleColor = '#BDC3C7';
    const status = item && getCustomerTouchpointStatus(item?.customerTouchpointStatus ?? item?.status);
    if (status) {
      if (status.gradient) {
        circleColor = null;
        className = status.gradient;
      } else {
        circleColor = status.color;
      }
      if (isHovered) {
        const { title } = getCustomerTouchpointStatus(item.statusTitle);
        statusTitle = title;
        circleColor = 'var(--white)';
      } else {
        statusTitle = item ? status.title : '';
      }
    }
  }

  if (isHovered) {
    iconColor = 'var(--playbook-pewter)';
  }

  return {
    icon: (
      <IconContainer
        className={'circle-icon cursor-pointer ' + className}
        background={color || circleColor}
        hideIcon={!!color}
        isHovered={isHovered}
      >
        <StyledIcon fixedWidth icon={icon} color={iconColor} />
      </IconContainer>
    ),
    statusTitle,
  };
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
}

export function debounce(fn, delay) {
  let timer = null;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

/**
 * Turns words into camelCase name
 *
 * camelize("EquipmentClass name");
 * camelize("Equipment className");
 * camelize("equipment class name");
 * camelize("Equipment Class Name");
 *
 * all output "equipmentClassName"
 */
export function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export function getClickableLink(link = '') {
  return link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}`;
}

export function BFSDependendants(objects = [], id) {
  const queue = [...objects];
  while (queue.length) {
    const t = queue.shift();
    if (t.id === id) return true;
    queue.push(...(t.touchpointDependents || []));
  }
  return false;
}

export function getDependables(allTouchpoints, touchpoint) {
  const allSteps = Object.values(allTouchpoints);
  return (
    allSteps.reduce((res, t) => {
      if (t.id !== touchpoint.id && t.durationAdded && t.durationOptionAmount !== 0) {
        let foundInDependencyTree = this.BFSDependendants(touchpoint.touchpointDependents, t.id);
        if (!foundInDependencyTree) {
          res.push({ id: t.id, title: t.title });
        }
      }
      return res;
    }, []) ?? []
  );
}

export const COLORS_HEX = {
  BLUE: '#008BE7',
  PURPLE: '#7773FC',
  GREEN: '#1DB082',
  RED: '#FF4A52',
  ORANGE: '#FF7F2A',
  YELLOW: '#FFC43A',
};

/**
 * RGB color lookup keyed by equivalent hex value.
 */
export const COLORS_HEX_TO_RGB = {
  [COLORS_HEX.BLUE]: [0, 139, 231],
  [COLORS_HEX.PURPLE]: [119, 115, 252],
  [COLORS_HEX.GREEN]: [29, 176, 130],
  [COLORS_HEX.RED]: [255, 74, 82],
  [COLORS_HEX.ORANGE]: [255, 127, 42],
  [COLORS_HEX.YELLOW]: [255, 196, 58],
};

let regex = new RegExp(/(?:\.([^.]+))?$/);

export const getDocumentInfo = (filename) => {
  var ext = regex.exec(filename)[1];
  if (ext === 'pdf') {
    return { icon: faFilePdf, color: '#FF0000' };
  } else if (ext === 'png' || ext === 'jpg') {
    return { icon: faFileImage, color: '#BA55D3' };
  } else if (ext === 'ppt' || ext === 'pptx') {
    return { icon: faFilePowerpoint, color: '#E06B11' };
  } else if (ext === 'doc' || ext === 'docx') {
    return { icon: faFileWord, color: '#226ED5' };
  } else if (ext === 'xlsx' || ext === 'xls') {
    return { icon: faFileExcel, color: '#087A04' };
  } else {
    return { icon: faFileAlt, className: 'color-icon-light-gray' };
  }
};

export const getUniqueDataById = (data) =>
  data?.filter((item, index, self) => self.findIndex((value) => value.id === item.id) === index);

export const getUniqueName = (list, name) => {
  let num = 0;
  let currentName = `${name}`;
  let exists = list.filter((item) => item.title.toLowerCase() === currentName.toLowerCase()).length;

  while (exists) {
    currentName = `${name} (${++num})`;
    // eslint-disable-next-line no-loop-func
    exists = list.filter((item) => item.title.toLowerCase() === currentName.toLowerCase()).length;
  }

  return currentName;
};

export const concatStringsOn = (condition, classes, classesToMerge) => {
  if (condition) return classes + ' ' + classesToMerge;

  return classes;
};

export const pluralize = (count, label) => {
  if (!count) return '';

  return `${count} ${label}${count >= 2 ? 's' : ''}`;
};

export const hasPluralize = (count) => {
  if (!count) return '';
  return `${count === 1 ? 'has' : 'have'}`;
};

export const mergeObjs = (obj, propValueMap) => ({
  ...obj,
  ...Object.keys(propValueMap).reduce(
    (acc, prop) => ({
      ...acc,
      [prop]: propValueMap[prop],
    }),
    {}
  ),
});

export const noop = () => { };

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * A helper function that'll limit a given string
 * as it exceeds the maxLength and
 * appends an ellipsis right after.
 * It returns the same string if it doesn't exceed
 * the maxLength
 * @param {string} string - given string to be trimmed
 * @param {number} maxLength - max number of characters before trimmed
 * @returns {string} - trimmed string or the same exact string itself
 */
export const trimStringWithEllipsis = (string, maxLength) => {
  return string.length > maxLength ? `${string.slice(0, maxLength)}...` : string;
};

export const isJson = (item) => {
  item = typeof item !== 'string' ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === 'object' && item !== null) {
    return true;
  }

  return false;
};

export const isDropDownType = (fieldType) =>
  fieldType === FIELD_TYPES.SINGLE_DROPDOWN ||
  fieldType === FIELD_TYPES.MULTI_DROPDOWN ||
  fieldType === FIELD_TYPES.TIMEZONE ||
  fieldType === FIELD_TYPES.DROPDOWN_SINGLE_SELECT ||
  fieldType === FIELD_TYPES.DROPDOWN_MULTI_SELECT;

export const getFormattedUsersList = (members, type = 'canvas') => {
  const definedMembers = members?.filter((member) => member !== undefined) || [];

  const externalUsersList =
    definedMembers
      ?.filter(({ role }) => role === 'EXTERNAL')
      ?.sort((a, b) => {
        const aFullName = `${a.firstName} ${a.lastName}`;
        const bFullName = `${b.firstName} ${b.lastName}`;

        return aFullName.localeCompare(bFullName);
      }) || [];

  const internalOwner = definedMembers?.filter(({ isAccountOwner }) => isAccountOwner) || [];

  const internalUsersList =
    definedMembers
      ?.filter(({ role, isAccountOwner }) => role !== 'EXTERNAL' && !isAccountOwner)
      ?.sort((a, b) => {
        const aFullName = `${a.firstName} ${a.lastName}`;
        const bFullName = `${b.firstName} ${b.lastName}`;

        return aFullName.localeCompare(bFullName);
      }) || [];

  if (type === 'canvas') return [...internalOwner, ...internalUsersList, ...externalUsersList];

  return [...externalUsersList, ...internalOwner, ...internalUsersList];
};
