import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
} from 'components/Modals/LargePadding';
import { SubscriptionContainer } from './Styles';
import { Form } from 'react-bootstrap';
import { FormInput } from 'components/Formik/FormInput';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import { useMemo } from 'react';

const ModifySubdomain = ({ onHide, show }) => {
  const { submitForm, isSubmitting, values } = useFormikContext();
  const [, meta] = useField('subdomainName');

  const hasError = useMemo(() => meta.touched && !!meta.error, [meta.error, meta.touched]);

  const isAdd = values.mode === 'add';

  const renderContent = (
    <SubscriptionContainer>
      <h1 className="text-center">{isAdd ? 'Create' : 'Edit'} Custom Subdomain</h1>

      <div className="form-content">
        <Form.Label>Subdomain Name</Form.Label>

        <div className="field-wrapper">
          <FormInput name="subdomainName" />
          <span>.stagebase.com</span>
        </div>

        {hasError && (
          <div className="mt-1 error-message-wrapper">
            <ErrorMessage name="subdomainName" />
          </div>
        )}
      </div>

      <p className="info">Allow 30-60 minutes for changes to take affect.</p>
    </SubscriptionContainer>
  );

  const renderButtons = (
    <>
      <ModalLargePaddingCancelButton onClick={onHide} autoWidth />
      <ModalLargePaddingConfirmButton onClick={submitForm} loading={isSubmitting} text="Save" autoWidth />
    </>
  );

  const modalStyle = {
    inner: {
      maxWidth: 'unset',
      padding: '0 !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'var(--light-gray-bg)',
      borderRadius: '0 0 0.813rem 0.813rem',
      padding: '1.875rem 0 2rem 0 !important',
    },
  };

  return (
    <>
      <ModalLargePadding
        show={show}
        innerProps={modalStyle.inner}
        buttonsContainerProps={modalStyle.buttonContainer}
        content={renderContent}
        buttons={renderButtons}
        size="md"
      />
    </>
  );
};

export default ModifySubdomain;
