import styled from 'styled-components';

const AvatarContainer = styled.div`
  background: ${({ background }) => background};
`;

const AvatarButton = styled.div`
  background-image: ${({ image }) => `url(${image})`};
`;

export function Avatar({ user, avatar, fullName, onClick, className, backgroundColor, small = false }) {
  const classNames =
    'avatar-circle' +
    (onClick ? ' cursor-pointer' : '') +
    (small ? ' small' : '') +
    (className ? ` ${className}` : '');

  function getInitials() {
    if (user?.initials) {
      return user.initials;
    } else if (fullName) {
      let splitted = fullName.split(' ');
      return splitted[0].charAt(0) + splitted[1].charAt(0);
    } else if (user) {
      return (user.firstName || '').charAt(0) + (user.lastName || '').charAt(0);
    }
    return '';
  }

  function renderInitialButton() {
    return (
      <AvatarContainer className={classNames} onClick={onClick} background={backgroundColor}>
        {getInitials()}
      </AvatarContainer>
    );
  }

  function renderAvatarButton(image) {
    return <AvatarButton image={image} className={classNames} onClick={onClick} />;
  }

  avatar = avatar || (user && user.avatar);
  if (avatar && avatar !== '' && avatar !== 'undefined' && avatar !== 'null') {
    return renderAvatarButton(avatar);
  } else {
    return renderInitialButton();
  }
}
