import React, { forwardRef, useContext, useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { faFile, faEllipsisH, faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, FormikContext } from 'formik';
import { Button, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ModalConfirm from 'components/Modals/Confirm/index';
import ModalLargePadding, {
  ModalLargePaddingConfirmButton,
  ModalLargePaddingHeader,
} from 'components/Modals/LargePadding';
import ModalNewTenant from 'components/Modals/NewTenant';
import ModalModifyTenant from 'components/Modals/ModifyTenant';
import PageHeader from 'components/PageHeader';
import toast from 'components/toast';
import {
  EDIT,
  ADD,
  FIELD_TYPES,
  IS_SAVING,
  HAS_SERVER_ERROR,
  FORMIK_FIELD_TYPES,
  USERS_FIELDS,
  SETTINGS_PERMISSIONS,
} from 'constants';
import FullContext from 'stores/Full/fullContext';
import ModalAddUser from 'components/Modals/Users/AddUser';
import { OptionsContainer, ActionsContainer, RowContainer, StyledTable, DeleteModalMessage } from './Styles';
import { useUsers } from './hooks';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { getFieldType } from 'components/CustomFields/Details/fixtures';
import { camelize, noop, pluralize, trimStringWithEllipsis } from 'helpers/CommonHelper';
import { useParser } from 'views/Customers/hooks';
import { roles } from 'components/Modals/Users/fixtures';
import { format } from 'date-fns';
import useIsOverflow from 'helpers/hooks/useIsOverflow';
import { SaveContainer } from 'views/Profile/Styles';
import { Settings, Users as UserModel } from 'models';
import { FormDropdown } from 'components/Formik/FormDropdown';
import { AssignedContentContainer } from 'components/Modals/Users/Styles';
import ModalAddSeats from 'components/Modals/Billing/AddSeats';
import LoadingInline from 'components/Loading/LoadingInline';

export default function Users({ upsellTeams }) {
  const [toDelete, setToDelete] = useState(null);
  const [showingNewTenant, setShowingNewTenant] = useState(false);
  const [showingModifyTenant, setShowingModifyTenant] = useState(false);
  const [showingImportFinishedModal, setShowingImportFinishedModal] = useState(false);
  const [trialModal, setTrialModal] = useState(false);
  const [subscription, setSubscription] = useState({ user: null, licenses: null });
  const [showAddSeats, setShowAddSeats] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isSuperuser } = useContext(FullContext);
  const { fetchInitialProfileData, profile } = useContext(CustomFieldsContext);

  const { formikBag, handleHide, handleShow, showUserModal, mode, users, loadUsers } = useUsers();

  const handleLoadData = useCallback(async () => {
    setIsLoading(true);

    const { billing } = await Settings.getSettingsPermissions();

    if (billing === SETTINGS_PERMISSIONS.NONE) {
      setIsLoading(false);
      return;
    }

    const { internalUserCount, stripeData } = await Settings.getAppSettings();

    setSubscription({ user: internalUserCount, licenses: stripeData?.seats || 0, plan: stripeData?.plan });

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  // const [numberOfSucceeded, setNumberOfSucceeded] = useState(0);
  // const [numberOfFailed, setNumberOfFailed] = useState(0);
  // const fileInput = useRef(null);
  const history = useNavigate();

  const { handleParseResponseValue } = useParser();

  const tableRef = useRef();

  const isOverflow = useIsOverflow(tableRef);

  const customFields = useMemo(
    () =>
      profile?.editableFields
        .filter(({ isProtected, isHidden }) => !isProtected && !isHidden)
        .map((field) => ({
          ...field,
          fieldType: getFieldType(field.fieldType),
          formikValue: null,
        })),
    [profile?.editableFields]
  );

  useEffect(() => {
    fetchInitialProfileData();
    handleLoadData();
  }, [fetchInitialProfileData, handleLoadData]);

  async function deleteUser() {
    try {
      const newOwner = users.find(({ id }) => formikBag.values?.assignedUser?.response === id);
      const newOwnerId = hasAccounts ? newOwner.primaryId : null;

      await UserModel.deleteAndReassignUser(toDelete?.primaryId, newOwnerId);
    } catch (error) {
      toast.error(error.message);
      return;
    }

    await loadUsers();
    hideDeleteConfirm();
    handleHide();
    toast.saved('User deleted successfully.');
  }

  // async function onImportFileChange(event) {
  //   const response = await Api.postFiles('User/Upload', event.target.files);
  //   this.setState({
  //     numberOfSucceeded: response.NumberOfSucceeded,
  //     numberOfFailed: response.numberOfFailed,
  //     showingImportFinishedModal: true,
  //   });
  //   load();
  //   this.fileInput.value = null;
  // }

  function toggleNewTenant() {
    setShowingNewTenant((prev) => !prev);
  }

  function toggleModifyTenant() {
    setShowingModifyTenant((prev) => !prev);
  }

  function toggleImportFinished() {
    setShowingImportFinishedModal((prev) => !prev);
  }

  function hideDeleteConfirm() {
    setToDelete(null);
  }

  const renderValue = (field, value) => {
    const { fieldType } = field;

    if (!value && typeof value !== 'boolean') return <>--</>;

    switch (fieldType) {
      case FIELD_TYPES.BOOLEAN:
      case FIELD_TYPES.YES_NO: {
        return <>{!!value ? 'Yes' : 'No'}</>;
      }

      case FIELD_TYPES.LINK: {
        return <>{trimStringWithEllipsis(`https://${value}`, 30)}</>;
      }

      case FIELD_TYPES.DROPDOWN_SINGLE_SELECT:
      case FIELD_TYPES.SINGLE_DROPDOWN:
      case FIELD_TYPES.TIMEZONE: {
        return <>{trimStringWithEllipsis(value?.label, 30)}</>;
      }

      case FIELD_TYPES.DROPDOWN_MULTI_SELECT:
      case FIELD_TYPES.MULTI_DROPDOWN: {
        const formattedValue = value?.map(({ label }) => label).join(', ');

        return <>{trimStringWithEllipsis(formattedValue, 30)}</>;
      }

      case FIELD_TYPES.DATE: {
        return <>{format(new Date(value), 'MMM dd, yyyy')}</>;
      }

      case FIELD_TYPES.TIME: {
        return <>{format(new Date(value), 'hh:mm aa')}</>;
      }

      case FIELD_TYPES.DATE_TIME:
      case FIELD_TYPES.DATE_AND_TIME: {
        return <>{value?.dateTime ? format(new Date(value?.dateTime), 'MMM d, yyyy hh:mm aa') : '--'}</>;
      }

      case FIELD_TYPES.EMAIL:
        return <>{trimStringWithEllipsis(value, 30)}</>;

      default:
        return <>{trimStringWithEllipsis(value, 30)}</>;
    }
  };

  const userList = () => {
    return (
      <RowContainer>
        <StyledTable ref={tableRef} isOverflow={isOverflow}>
          <thead>
            <tr>
              <td className="border-0 pt-0">Name</td>
              <td className="border-0 pt-0">Email and Phone</td>
              <td className="border-0 pt-0">Role</td>

              {customFields?.map((field) => (
                <td className="border-0 pt-0" key={field.objectMapId}>
                  <div>{field?.fieldLabel}</div>
                </td>
              ))}
            </tr>
          </thead>

          <tbody>
            {users.map((item, index) => (
              <tr key={index} onClick={() => handleShow(EDIT, item)}>
                <td className="py-3">
                  <div>
                    <div>{trimStringWithEllipsis(`${item.firstName} ${item.lastName}`, 30)}</div>
                    <div>{item.title && <div className="text-muted">{item.title}</div>}</div>
                  </div>
                </td>

                <td className="py-3">
                  <div>
                    <div>{trimStringWithEllipsis(item.email, 30)}</div>
                    <div>{item.phoneNumber && <div className="text-muted">{item.phoneNumber}</div>}</div>
                  </div>
                </td>

                <td className="py-3">
                  <div>
                    <div>{(roles.find((x) => x.response === item.role) || { label: 'Unknown' }).label}</div>
                    <div>{item.defaultOwner && <div className="text-muted">Default owner</div>}</div>
                  </div>
                </td>

                {customFields?.map((field) => {
                  const { targetFieldName } = field;

                  const newTargetFieldName = camelize(targetFieldName);
                  const mappedFieldName = item[newTargetFieldName] ?? item[targetFieldName];
                  const formikValue = handleParseResponseValue(field, mappedFieldName);

                  return (
                    <td
                      key={field.objectMapId}
                      className={`py-3 ${field.fieldType === 'Paragraph' ? 'is-paragraph' : ''}`}
                    >
                      <div className="value">{renderValue(field, formikValue)}</div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </RowContainer>
    );
  };

  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <div ref={ref} onClick={onClick} className="cursor-pointer">
      {children}
    </div>
  ));

  const headerOverflow = (
    <OptionsContainer>
      <Dropdown id="options">
        <Dropdown.Toggle as={CustomToggle}>
          <FontAwesomeIcon icon={faEllipsisH} className="ml-3 mr-3" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="left">
          <Dropdown.Item onClick={() => history('/profile/custom-fields/edit-profile-fields')}>
            <FontAwesomeIcon fixedWidth icon={faPen} />
            Edit fields
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </OptionsContainer>
  );

  const trialModalProps = {
    title: 'Upgrade to Access',
    confirmText: 'Manage Subscription',
    variant: 'primary',
    show: trialModal,
    message: (
      <>
        <p>
          Collaborate as a team
          <ul>
            <li>Share account and project data with teammates</li>
            <li>Add and assign stakeholders to steps and tasks</li>
            <li>Give managers access to reports</li>
          </ul>
        </p>
        <p>Upgrade your subscription to access this feature.</p>
      </>
    ),
    messageAlign: 'left',
    innerProps: {
      maxWidth: '470px',
      padding: '70px 100px 40px',
    },
    buttonsContainerProps: {
      display: 'flex',
      justifyContent: 'center',
    },
    autoButtonsWidth: true,
    onConfirm: () => history('/settings#billing'),
    onHide: () => setTrialModal(false),
  };

  const hasAccounts = !!formikBag.values?.totalAccounts;

  const renderDeleteModalMessage = () => {
    const firstName = toDelete && toDelete.firstName;

    if (hasAccounts) {
      return (
        <AssignedContentContainer>
          <p>
            You are about to delete {firstName} as a user. Select a new owner for {firstName}’s{' '}
            {pluralize(formikBag.values?.totalAccounts, 'account')}.
          </p>

          <FormDropdown
            type={FORMIK_FIELD_TYPES.SEARCHABLE}
            name={USERS_FIELDS.ASSIGNED_USER}
            searchableProps={{
              placeholder: 'New Owner',
              hasDefaultPlaceholder: true,
            }}
            options={formikBag.values?.users?.filter(({ id }) => id !== toDelete?.id)}
          />
        </AssignedContentContainer>
      );
    }

    return (
      <DeleteModalMessage>
        Are you sure you want to delete {firstName}? {firstName} has {formikBag.values?.totalAccounts}{' '}
        accounts assigned to them.
      </DeleteModalMessage>
    );
  };

  const handleShowAddUsers = () => {
    if (subscription.user === 1 && !subscription.plan) {
      setShowAddSeats(true);
      return;
    }

    handleShow(ADD);
  };

  if (isLoading)
    return (
      <div className="mt-4">
        <LoadingInline />
      </div>
    );

  return (
    <div>
      <Formik initialValues={{ isChangesSaving: null, hasServerError: null }} onSubmit={noop}>
        {({ values: { isChangesSaving, hasServerError }, setFieldValue }) => (
          <>
            <PageHeader title={<span>Users</span>} loading={!users} className="with-buttons" showOrgName>
              {users && (
                <ActionsContainer>
                  <SaveContainer>
                    {!isChangesSaving && typeof isChangesSaving != 'boolean' ? null : Boolean(
                        isChangesSaving
                      ) ? (
                      <span className="saving mr-3">Saving...</span>
                    ) : Boolean(hasServerError) ? (
                      <span className="error mr-3">Unsaved changes</span>
                    ) : (
                      <span className="mr-3">Changes auto-saved.</span>
                    )}
                  </SaveContainer>
                  {/* Currently broken in production <input
                hidden
                type="file"
                onChange={this.onImportFileChange}
                ref={fileInput}
              />
              <Button variant="outline-light" onClick={() => this.fileInput.click()}>
                Import
              </Button> */}
                  {isSuperuser && (
                    <>
                      <Button
                        variant="outline-light"
                        data-testid="btn-modify-tenant"
                        onClick={toggleModifyTenant}
                        className="mr-2-5 mt-2-5 mt-md-0"
                      >
                        Modify tenant
                      </Button>
                      <Button
                        variant="outline-light"
                        data-testid="btn-new-tenant"
                        onClick={toggleNewTenant}
                        className="mr-2-5 mt-2-5 mt-md-0"
                      >
                        Provision new tenant
                      </Button>
                    </>
                  )}
                  <Button
                    data-testid="btn-new-user"
                    onClick={() => (upsellTeams ? setTrialModal(true) : handleShowAddUsers())}
                    className="mt-2-5 mt-md-0"
                  >
                    New user
                  </Button>
                  <ModalConfirm {...trialModalProps} />
                  {headerOverflow}
                </ActionsContainer>
              )}
            </PageHeader>
            <div>{users ? userList() : null}</div>

            {showingNewTenant && <ModalNewTenant show={showingNewTenant} onHide={toggleNewTenant} />}
            {showingModifyTenant && (
              <ModalModifyTenant show={showingModifyTenant} onHide={toggleModifyTenant} />
            )}
            {showingImportFinishedModal && (
              <ModalImportFinished show={showingImportFinishedModal} onHide={toggleImportFinished} />
            )}

            <FormikContext.Provider value={formikBag}>
              <ModalAddUser
                show={showUserModal}
                onHide={handleHide}
                mode={mode}
                setToDelete={setToDelete}
                setSaving={(value) => setFieldValue(IS_SAVING, value)}
                setHasError={(value) => setFieldValue(HAS_SERVER_ERROR, value)}
                toDelete={toDelete}
              />

              <ModalConfirm
                title={hasAccounts ? 'Delete User & Reassign' : 'Delete user'}
                message={renderDeleteModalMessage()}
                show={toDelete !== null}
                onConfirm={deleteUser}
                autoButtonsWidth
                onHide={hideDeleteConfirm}
                confirmText={hasAccounts ? 'Delete and Reassign' : 'Delete'}
                disabledConfirm={hasAccounts && !formikBag.values?.assignedUser}
              />
            </FormikContext.Provider>

            <ModalAddSeats
              onHide={() => setShowAddSeats(false)}
              onSubmit={() => history('/settings#billing')}
              show={showAddSeats}
            />
          </>
        )}
      </Formik>
    </div>
  );
}

function ModalImportFinished({ show, onHide, numberOfSucceeded, numberOfFailed }) {
  return (
    <ModalLargePadding
      show={show}
      onHide={onHide}
      content={
        <>
          <ModalLargePaddingHeader icon={faFile} text="Import finished with results" />
          <p>Succeeded: {numberOfSucceeded ? numberOfSucceeded : 0}</p>
          <p>Failed: {numberOfFailed ? numberOfFailed : 0}</p>
          <div className="buttons">
            <ModalLargePaddingConfirmButton text={'OK'} />
          </div>
        </>
      }
    />
  );
}
