import { useContext } from 'react';
import styled from 'styled-components';
import { shortDate, findNumberOfDays } from 'helpers/DateHelper';
import LoadingSpinner from 'components/Loading/LoadingSpinner';
import { Touchpoint } from 'models';
import DateChangeContext from '../context/dateChangeContext';
import { StyledParagraph, LoadingWrapper } from '../styles';
import { pluralize } from 'helpers/CommonHelper';

const Divider = styled.hr`
  width: 100%;
  border: 1px solid var(--border-gray);
  & ~ p {
    margin: 0;
    text-align: center;
  }
  & ~ p > span {
    font-weight: bold;
  }
`;

export default function DateMessage(
  { dynamicTimeline, showChangedDate, didExceed, touchpoint } = { touchpoint: new Touchpoint() }
) {
  const {
    activeTouchpoint,
    isEarlierDate,
    scheduledDate,
    calculatedDate,
    isLoading,
    noDueDate,
    canvasStartDate,
  } = useContext(DateChangeContext);
  const newScheduledDateDueDiff = findNumberOfDays(
    new Date(new Date(scheduledDate).toLocaleDateString()),
    new Date(new Date(activeTouchpoint?.endDate).toLocaleDateString())
  );
  const unscheduledTypeFrom = activeTouchpoint?.noDueDate
    ? 'End of Project'
    : shortDate(new Date(activeTouchpoint?.displayDate));
  const unscheduledTypeTo = noDueDate ? 'End of Project' : shortDate(new Date(calculatedDate));
  const isUnscheduledTypeSame = unscheduledTypeFrom === unscheduledTypeTo;

  if (touchpoint?.isIncomplete && isLoading)
    return (
      <LoadingWrapper>
        <LoadingSpinner />
      </LoadingWrapper>
    );

  return (
    <>
      {calculatedDate && showChangedDate && !isUnscheduledTypeSame && touchpoint?.isIncomplete && (
        <>
          <StyledParagraph>
            You are changing the due date for
            <span className="font-weight-bold"> {activeTouchpoint?.title} </span>
            from
            <span className="font-weight-bold"> {unscheduledTypeFrom} </span>
            to
            <span className="font-weight-bold"> {unscheduledTypeTo}.</span>
          </StyledParagraph>
        </>
      )}
      {!didExceed &&
        scheduledDate &&
        !calculatedDate &&
        showChangedDate &&
        scheduledDate?.toDateString() !== touchpoint?.scheduledDate?.toDateString() &&
        touchpoint?.isIncomplete && (
          <>
            <StyledParagraph
              className={activeTouchpoint?.dateToBeScheduled && !isEarlierDate ? 'text-left' : ''}
            >
              You are scheduling
              <span className="font-weight-bold"> {activeTouchpoint?.title} </span>
              for
              <span className="font-weight-bold"> {shortDate(scheduledDate)}</span>
              {!activeTouchpoint?.dateToBeScheduled
                ? ' with the customer.'
                : `. This is ${pluralize(Math.abs(newScheduledDateDueDiff), 'day')} 
                ${
                  newScheduledDateDueDiff === 0 ? 'on' : newScheduledDateDueDiff > 0 ? 'after' : 'before'
                } the due date.`}
            </StyledParagraph>
          </>
        )}

      {touchpoint?.isComplete && (
        <>
          <Divider />
          <p>
            {dynamicTimeline ? <b>Dynamic Timeline is on</b> : <b>Dynamic Timeline is off</b>}{' '}
            {dynamicTimeline
              ? `so this will affect dependent step due dates. It will also update completion times in Reporting`
              : `so this will NOT affect dependent step due dates, but it will update completion times in Reporting`}
          </p>
        </>
      )}

      {didExceed && (
        <>
          <Divider />
          <p>
            {shortDate(scheduledDate ?? new Date()) === shortDate(new Date())
              ? `Unable to change the completion date to a date beyond today, ${shortDate(new Date())}`
              : `Unable to change the completion date to a date before the start of the project, ${shortDate(
                  new Date(canvasStartDate)
                )}`}
          </p>
        </>
      )}
    </>
  );
}
