import React, { Fragment, useState, useCallback, useMemo } from 'react';
import { useField, ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { ErrorMessageContainer } from 'views/CustomFields/Account/Styles';
import { defaultValue } from './fixtures/initialValues';
import { FieldLabel } from 'components/Modals/Customers/Styles';

const RadioGroupContainer = styled.div`
  display: flex;
  gap: 1.5rem;
`;

const FormRadioGroup = ({ name, options = defaultValue, fieldLabel, isRequired, handleChange, disabled }) => {
  const [, meta, helpers] = useField(name);

  const [value, setValue] = useState(meta.value || meta.initialValue);

  /**
   * On Radio Change handler
   * @params option
   * @returns void
   */
  const onRadioChange = useCallback(
    (option) => () => {
      setValue(option);

      helpers.setError('');
      helpers.setValue(option);
      helpers.setTouched(true);

      if (handleChange) handleChange(option);
    },
    [helpers, handleChange]
  );

  const hasError = useMemo(() => meta.touched && !!meta.error, [meta.error, meta.touched]);

  return (
    <>
      {fieldLabel && (
        <FieldLabel>
          <span>{fieldLabel}</span>
          {isRequired && !hasError && <span>*</span>}
          {hasError && (
            <ErrorMessageContainer className="error-container">
              (<ErrorMessage name={name} />)
            </ErrorMessageContainer>
          )}
        </FieldLabel>
      )}

      <RadioGroupContainer>
        {options?.map((option) => (
          <Fragment key={option.id}>
            <Form.Check
              type="radio"
              label={option.label}
              disabled={disabled}
              checked={value && value.id === option.id}
              onChange={onRadioChange(option)}
            />
          </Fragment>
        ))}
      </RadioGroupContainer>
    </>
  );
};

export default React.memo(FormRadioGroup);
