import { useState, useEffect, useCallback } from 'react';
import * as AutomationApi from '../../api/automationRules';
import toast from '../../components/toast';
import { TRIGGER_DISPLAY_VALUE_MAP } from '../../constants/automationRules';

/**
 * Gets display name for leftOperand dropdown
 * @param {object} data
 * @returns {string}
 */
const getDisplayName = (data) => data.displayName || data.friendlyName || data.propName;

/**
 * Mapper function for left operand dropdown API response
 * @param {object} data
 * @returns {object}
 */
const mapLeftOperands = (data) => ({
  id: data.id,
  friendlyName: getDisplayName(data),
  valueType: data.leftOperandType,
  anonPathName: data.propName,
  source: data.source,
});

/**
 * Hook to get leftOperand list and leftOperand options from API
 * @param {string} sourceCategory
 * @returns {object}
 */
const useLeftOperandsByCategory = (sourceCategory) => {
  const [leftOperands, setLeftOperands] = useState([]);
  const [leftOperandOptions, setleftOperandOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchLeftOperand = useCallback(async () => {
    setLoading(true);
    try {
      const response = await AutomationApi.fetchLeftOperandsByCategory(sourceCategory);

      if (response?.value?.length) {
        const mappedLeftOperands = response.value.map(mapLeftOperands);
        setLeftOperands(mappedLeftOperands);

        const opts = mappedLeftOperands.map((leftOperand) => {
          return {
            label: leftOperand.friendlyName,
            value: leftOperand.anonPathName,
            leftOperand,
          };
        });
        const groupedOpts = [{ label: TRIGGER_DISPLAY_VALUE_MAP[sourceCategory], options: opts }];
        setleftOperandOptions(groupedOpts);
      }
    } catch (err) {
      console.log(err);
      toast.error('There was an error fetching left operand options list');
    } finally {
      setLoading(false);
    }
  }, [sourceCategory]);

  useEffect(() => {
    fetchLeftOperand();
  }, [fetchLeftOperand]);

  return {
    leftOperands,
    leftOperandOptions,
    loading,
  };
};

export default useLeftOperandsByCategory;
