import * as Api from 'api/Api';
import { OBJECT_TYPE_ENUM, REPLACE_OPERATION, REMOVE_OLD_OWNER } from 'constants';

class Customers {
  constructor(props) {
    if (props) Object.assign(this, props);
  }

  static async addCustomer(data) {
    const response = await Api.post('Customer/CreateCustomer', data);
    return response;
  }

  static async getCustomerDetail(id) {
    const response = await Api.get(`Customer/GetCustomerDetail?customerId=${id}`);
    return response;
  }

  static async getCustomerContact(contactId) {
    const response = await Api.get('User/GetContact', { contactId });
    return response;
  }

  static async addClientType(data) {
    const response = await Api.post('ClientType/CreateClientType', data);
    return response;
  }

  static async deleteClientType(id) {
    const response = await Api.get('ClientType/Delete', { id });
    return response;
  }

  static async updateClientType(data, id) {
    const response = await Api.post('ClientType/Update', data, { params: { id } });
    return response;
  }

  static async updateCustomer(id, data, removeOldOwner = false) {
    const { field, value } = data;

    const request = [
      {
        operationType: OBJECT_TYPE_ENUM.CUSTOMER,
        path: field,
        op: REPLACE_OPERATION,
        value,
      },
      {
        operationType: OBJECT_TYPE_ENUM.CUSTOMER,
        path: REMOVE_OLD_OWNER,
        op: REPLACE_OPERATION,
        value: removeOldOwner,
      },
    ];

    const response = await Api.patch(`Customer/PartialCustomerUpdate`, request, {
      params: { customerId: id },
    });

    return response;
  }

  static async updateContact(id, data) {
    const { field, value } = data;

    const request = [
      {
        operationType: OBJECT_TYPE_ENUM.CONTACTS,
        path: field,
        op: REPLACE_OPERATION,
        value,
      },
    ];

    const response = await Api.patch(`User/PartialContactUpdate`, request, {
      params: { contactId: id },
    });

    return response;
  }

  static async getContacts(customerId) {
    const response = await Api.get(`User/GetContacts`, {
      customerId,
    });

    return response;
  }

  static async deleteContact(contactId) {
    const response = await Api.post(`User/DeleteContact?contactId=${contactId}`);
    return response;
  }

  static async addContact(payload) {
    const response = await Api.post(`User/AddContact`, payload);
    return response;
  }

  static async assignCustomerCanvases(customerId, canvas) {
    const response = await Api.post(`Customer/AssignCustomerCanvases`, [{ ...canvas }], {
      params: { customerId },
    });

    return response;
  }
}

export default Customers;
