import * as Api from 'api/Api';
import { OBJECT_TYPE_ENUM } from 'constants';

class CustomFields {
  constructor(props) {
    if (props) Object.assign(this, props);
  }

  static async addCustomField(data) {
    const response = await Api.post('CustomField/Create', data);
    return response;
  }

  static async addCustomSection(data) {
    const response = await Api.post('CustomField/CreateSection', data);
    return response;
  }

  static async updateCustomField(fieldId, data) {
    const response = await Api.patch('CustomField/PartialUpdate', data, { params: { objectMapId: fieldId } });
    return response;
  }

  static async getAllEditableSections(type = OBJECT_TYPE_ENUM.CUSTOMER) {
    const response = await Api.get(`CustomField/GetSectionByObjectType?type=${type}`);
    return response;
  }

  static async getAllEnums(type = OBJECT_TYPE_ENUM.CUSTOMER) {
    const response = await Api.get(`CustomField/GetEnums?type=${type}`);
    return response;
  }

  static async getAllExternalFields(type = OBJECT_TYPE_ENUM.CUSTOMER) {
    const response = await Api.get(`CustomField/GetExternalFieldInfo?type=${type}`);
    return response;
  }

  static async deleteSection(sectionId) {
    const response = await Api.post(`CustomField/DeleteSection`, null, {
      params: { customFieldSectionId: sectionId },
    });

    return response;
  }

  static async updateSection(sectionId, data) {
    const response = await Api.patch(`CustomField/PartialUpdateSection`, data, {
      params: { customFieldSectionId: sectionId },
    });

    return response;
  }

  static async deleteField(fieldId) {
    const response = await Api.post(`CustomField/Delete`, null, {
      params: { objectMapId: fieldId },
    });

    return response;
  }

  static async getAllTypes() {
    const response = await Api.get('ClientType/GetAll');
    return response;
  }

  static async getOwnerOptions() {
    const response = await Api.get('User/GetOwnerOptions');
    return response;
  }

  static async getGetCustomFieldCountOfStoredData(fieldId) {
    const response = await Api.get(`CustomField/GetCustomFieldCountOfStoredData?objectMapId=${fieldId}`);

    return response;
  }
}

export default CustomFields;
