import { ResponsiveLine } from '@nivo/line';
import { displayMoney, abbreviateMoney } from 'helpers/CommonHelper';
import styled from 'styled-components';

const theme = {
  axis: {
    ticks: {
      text: {
        fontSize: 12,
        fill: 'var(--action-anchor)',
      },
    },
  },
  dots: {
    text: {
      fontSize: 12,
      fill: 'var(--action-anchor)',
    },
  },
};

const PointTooltip = styled.div`
  background: white;
  padding: 8px 24px;
  border-radius: 5px;
  filter: drop-shadow(1px 1px 3px black);
  .color1 {
    color: ${(props) => props.color1};
  }
  .color2 {
    color: ${(props) => props.color2};
  }
`;

const LineChart = ({ data, isMoney = false }) => {
  return (
    <ResponsiveLine
      data={data}
      theme={theme}
      colors={['#7773FC', '#FF4A52']}
      margin={{ top: 50, right: 50, bottom: 150, left: 50 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 0,
        tickPadding: 20,
        tickRotation: -45,
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={null}
      enableGridY={false}
      pointSize={10}
      pointColor={{ from: 'color', modifiers: [] }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      enablePointLabel={true}
      pointLabel={(point) => (isMoney && point.y ? abbreviateMoney(point.y) : point.y)}
      pointLabelYOffset={-12}
      useMesh={true}
      tooltip={(item) => {
        const pointIndex = item.point.id.split('.')[1];
        if (data[0].data[pointIndex].y === data[1].data[pointIndex].y) {
          return (
            <PointTooltip color1={data[0].color} color2={data[1].color}>
              Date: <strong>{item.point.data.x}</strong>
              <div className="color1">
                {`${data[0].id}: `}
                <strong>
                  {isMoney ? displayMoney(data[0].data[pointIndex].y) : data[0].data[pointIndex].y}
                </strong>
              </div>
              <div className="color2">
                {`${data[1].id}: `}
                <strong>
                  {isMoney ? displayMoney(data[1].data[pointIndex].y) : data[1].data[pointIndex].y}
                </strong>
              </div>
            </PointTooltip>
          );
        } else {
          return (
            <PointTooltip color1={item.point.color}>
              Date: <strong>{item.point.data.x}</strong>
              <div className="color1">
                {`${item.point.serieId}: `}
                <strong>{isMoney ? displayMoney(item.point.data.y) : item.point.data.y}</strong>
              </div>
            </PointTooltip>
          );
        }
      }}
    />
  );
};

export default LineChart;
