import { useEffect, useRef, useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { TableHeaderStyle } from './SharedStyles';
import { ChevronArrow } from './SharedStyles';

export default function TableHeader({
  children,
  sortedColumn,
  setSortedColumn,
  alignText = true,
  table,
  sortByFunction,
  flexBasis,
  hoverOn = () => {},
  hoverOut,
  sortByDefault,
  type = 'primary',
}) {
  const [isSorted, setIsSorted] = useState(sortByDefault ?? false);
  const [init, setInit] = useState(true);
  const elRef = useRef();

  const handleClick = () => {
    sortByFunction(table, elRef.current.textContent, isSorted);
    setIsSorted(!isSorted);
    setSortedColumn(children);
  };

  useEffect(() => {
    if (sortByDefault) {
      setSortedColumn(children);
    }
  }, [children, setSortedColumn, sortByDefault]);

  useEffect(() => {
    if (
      (children === 'Past Due / Total Active Projects' || children === 'Type') &&
      table?.current?.tBodies[0]?.rows?.length &&
      init
    ) {
      sortByFunction(table, children, true);
      setInit(false);
    }
  }, [children, table, init, sortByFunction]);

  return (
    <TableHeaderStyle
      ref={elRef}
      flexBasis={flexBasis}
      alignText={alignText}
      onClick={handleClick}
      onMouseOver={() => hoverOn(elRef)}
      onMouseOut={hoverOut}
      type={type}
    >
      <div>{children}</div>
      <div>
        {sortedColumn === children && (
          <ChevronArrow onClick={handleClick} icon={isSorted ? faChevronDown : faChevronUp} />
        )}
      </div>
    </TableHeaderStyle>
  );
}
