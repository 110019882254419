import React, { memo, useContext } from 'react';
import FullContext from 'stores/Full/fullContext';
import Card from './Card';

const InnerList = ({
  items,
  onDelete,
  onDuplicate,
  onEdit,
  draggedStep,
  reloadCanvas,
  assigningToMember,
}) => {
  const { isEditMode, isViewAsCustomer } = useContext(FullContext);

  const lastItem = items.length - 1;

  const displaySteps = (steps) =>
    steps.map((item, index) => (
      <Card
        key={item.key ?? item.id}
        reloadCanvas={reloadCanvas}
        index={index}
        item={item}
        onDelete={() => onDelete(item.id)}
        onDuplicate={() => onDuplicate(item.id)}
        connected={isEditMode ? index !== lastItem && item.id !== Number(draggedStep) : true}
        showIcon={item.id !== Number(draggedStep)}
        onEdit={onEdit}
        assigningToMember={assigningToMember}
      />
    ));

  if (isViewAsCustomer) {
    const filteredData = items.filter((item) => item.customerFacing);
    return displaySteps(filteredData);
  }

  return displaySteps(items);
};

export default memo(InnerList);
