import { useState, useEffect, useRef, useContext } from 'react';
import {
  TableWrapperStyle,
  TableTitleStyle,
  TableStyle,
  TableDataStyle,
  NoDataTextStyle,
} from './SharedStyles';
import * as Api from '../../api/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableHeader from './TableHeader';
import { sortByLetter, sortByNumber } from './sorting';
import { Link } from 'react-router-dom';
import { displayMoney } from 'helpers/CommonHelper';
import ReportingContext from './context/reportingContext';
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import HeaderFilter from './HeaderFilter';
import styled from 'styled-components';
import { UNTITLED_PROJECT } from 'constants';

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--schedule-sapphire);
`;

export default function StatusTable() {
  const [statusData, setStatusData] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [sortedColumn, setSortedColumn] = useState('Days Past Due');
  const [showTable, setShowTable] = useState(true);
  const { activeAccountFilters } = useContext(ReportingContext);
  const table = useRef();

  useEffect(() => {
    const prepareSort = (data) => {
      const sortedData = data.sort((a, b) => b.daysBehind - a.daysBehind);
      setStatusData(sortedData);
    };
    const getProjectStatusData = async () => {
      let accountFilters = '';
      if (activeAccountFilters?.length > 0) {
        activeAccountFilters.forEach((filter, index) => {
          accountFilters += index === 0 ? `?clientTypes=${filter}` : `&clientTypes=${filter}`;
        });
      }
      const res = await Api.get(`Report/GetProjectsPastDue/${accountFilters}`);
      prepareSort(res);
    };
    getProjectStatusData();
  }, [activeAccountFilters]);

  useEffect(() => {
    const filteredData = statusData.filter((data) =>
      activeAccountFilters.length > 0 ? activeAccountFilters.includes(data.accountType) : true
    );
    setFilteredData(filteredData);
  }, [activeAccountFilters, statusData]);

  return (
    <TableWrapperStyle>
      <TableTitleStyle onClick={() => setShowTable(!showTable)}>
        <span className="title">
          <StyledIcon icon={showTable ? faAnglesUp : faAnglesDown} className="mr-2" />
          Past Due Active Projects
        </span>
        {activeAccountFilters.length > 0 && (
          <HeaderFilter setShowFilters={setShowFilters} showFilters={showFilters} useTimeFilter={false} />
        )}
      </TableTitleStyle>
      {showTable && (
        <TableStyle ref={table}>
          <thead>
            <tr>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByLetter}
                setSortedColumn={setSortedColumn}
              >
                Project
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByLetter}
                setSortedColumn={setSortedColumn}
              >
                Account
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByLetter}
                setSortedColumn={setSortedColumn}
              >
                Target Completion Time
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByNumber}
                setSortedColumn={setSortedColumn}
                sortByDefault={true}
              >
                Days Past Due
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByLetter}
                setSortedColumn={setSortedColumn}
              >
                Account Owner
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByNumber}
                setSortedColumn={setSortedColumn}
              >
                Account Value
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByLetter}
                setSortedColumn={setSortedColumn}
              >
                Account Type
              </TableHeader>
            </tr>
          </thead>
          <tbody>
            {filteredData?.length > 0 ? (
              filteredData.map((item) => (
                <tr key={item?.projectId}>
                  <TableDataStyle link={true}>
                    {item?.customerId && item?.projectId ? (
                      <Link to={`/accounts/${item?.customerId}/projects/${item?.projectId}`}>
                        { item?.projectName || UNTITLED_PROJECT}
                      </Link>
                    ) : (
                      item?.customerName
                    )}
                  </TableDataStyle>
                  <TableDataStyle>{item?.customerName}</TableDataStyle>
                  <TableDataStyle>
                    {item?.editedTargetDays > 0 ? item?.editedTargetDays : ''}{' '}
                    {item?.editedTargetDays > 1 && 'days'} {item?.editedTargetDays === 1 && 'day'}
                  </TableDataStyle>
                  <TableDataStyle>{item?.daysBehind}</TableDataStyle>
                  <TableDataStyle>
                    {item?.ownerFirstName} {item?.ownerLastName}
                  </TableDataStyle>
                  <TableDataStyle>{displayMoney(item?.revenue)}</TableDataStyle>
                  <TableDataStyle>{item?.accountType}</TableDataStyle>
                </tr>
              ))
            ) : (
              <tr>
                <td className="w-full">
                  <NoDataTextStyle>No active projects past due</NoDataTextStyle>
                </td>
              </tr>
            )}
          </tbody>
        </TableStyle>
      )}
    </TableWrapperStyle>
  );
}
