import { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { initialFieldValues } from 'components/Modals/CustomFields/fixtures';
import {
  NEW_STAGEBASE_FIELD,
  CUSTOM_FIELD_MODES,
  PROFILE_FIELD,
  PERMISSION_ENUM,
  OBJECT_TYPE_ENUM,
  FIELD,
  BASE_FIELD,
  PROFILE_CUSTOM_SECTION,
} from 'constants';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { baseFieldSchema, onFieldSubmission } from 'views/CustomFields/Account/validations';
import { useNavigate, useLocation } from 'react-router-dom';

export const useProfileField = () => {
  const { populateNewField, updateField, isLabelExist } = useContext(CustomFieldsContext);

  const [showAddFieldModal, setShowAddFieldModal] = useState(false);

  const location = useLocation();
  const history = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const queryFieldId = queryParams.has(FIELD) ? queryParams.get(FIELD) : null;

  /**
   * Population of the Added Custom Field
   * to its corresponding endpoint
   * @param values
   * @returns void
   */
  const handlePopulateData = (values) => {
    const {
      fieldLabel,
      dataSource,
      fieldType,
      fieldValues,
      permission,
      externalField,
      isRequired,
      isMergeTag,
      fieldMode,
      isSortedFieldValues,
      editedFieldId,
    } = values;
    const isNewStagebaseField = dataSource?.value === NEW_STAGEBASE_FIELD;

    const newFieldLabel = isNewStagebaseField ? fieldLabel : externalField?.label;
    const newPermission =
      isNewStagebaseField && fieldMode === CUSTOM_FIELD_MODES.ADD ? PERMISSION_ENUM.NA : permission?.request;

    const dropdownValues = fieldValues
      .filter(({ value }) => value !== '')
      .map((item) => ({ ...item, label: item.value }));

    const formattedFieldName = String(fieldLabel)
      .replace(/(^\w|\s\w)/g, (text) => text.toUpperCase())
      .split(' ')
      .join('');

    const targetSourceName = isNewStagebaseField ? formattedFieldName : externalField?.targetSource;
    const formattedDropdownValues = dropdownValues.map((value) => JSON.stringify(value));
    const formattedFieldOptions = dropdownValues.length > 0 ? formattedDropdownValues : null;
    const formattedFieldType = isNewStagebaseField ? fieldType?.request : externalField?.type;
    const externalFieldId = isNewStagebaseField ? null : externalField?.id;

    const addedField = {
      objectType: OBJECT_TYPE_ENUM.PROFILE,
      sourceType: dataSource?.request,
      customFieldSectionName: PROFILE_CUSTOM_SECTION,
      fieldLabel: newFieldLabel,
      targetFieldName: targetSourceName,
      fieldType: formattedFieldType,
      fieldTypeOptions: formattedFieldOptions,
      syncOption: newPermission,
      isCustomField: isNewStagebaseField,
      isSortedFieldTypeOptions: isSortedFieldValues,
      externalDataFieldId: externalFieldId,
      isRequired,
      isMergeTag,
    };

    const editedField = {
      fieldLabel: newFieldLabel,
      targetFieldName: targetSourceName,
      syncOption: newPermission,
      fieldTypeOptions: formattedFieldOptions,
      isRequired,
      isMergeTag,
    };

    const fieldModeHandlers = {
      [CUSTOM_FIELD_MODES.ADD]: () => populateNewField(addedField, OBJECT_TYPE_ENUM.PROFILE),
      [CUSTOM_FIELD_MODES.EDIT]: () => updateField(editedFieldId, editedField, OBJECT_TYPE_ENUM.PROFILE),
    };

    fieldModeHandlers[fieldMode]();
  };

  /**
   * Submit handler for Formik's onSubmit instance
   * @param values
   * @returns void
   */
  const handleSubmit = (values) => {
    const { hasExistentLabel } = onFieldSubmission(formikBag, isLabelExist, PROFILE_FIELD);

    // If there is an existent label, end process
    if (hasExistentLabel) return;

    handlePopulateData(values);

    handleHide();
  };

  /**
   * Add Custom Field Formik Instance
   */
  const formikBag = useFormik({
    initialValues: initialFieldValues,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: baseFieldSchema,
    onSubmit: handleSubmit,
  });

  /**
   * Opens the add custom field modal
   * @param none
   * @returns void
   */
  const handleShow = () => {
    setShowAddFieldModal(true);
  };

  const setFieldURLParam = (fieldId) => {
    if (!queryParams.has(BASE_FIELD)) {
      queryParams.set(BASE_FIELD, fieldId);
      history({
        search: queryParams.toString(),
      });
    }
  };

  /**
   * Closes the add custom field modal and resets the formik instance
   * @param none
   * @returns void
   */
  const handleHide = () => {
    setShowAddFieldModal(false);

    if (queryParams.has(FIELD) || queryParams.has(BASE_FIELD)) {
      queryParams.delete(FIELD);
      queryParams.delete(BASE_FIELD);
      history({
        search: String(queryParams),
      });
    }

    setTimeout(() => {
      formikBag.resetForm();
    }, 500);
  };

  return {
    formikBag,
    showAddFieldModal,
    handleHide,
    handleShow,
    queryFieldId,
    setFieldURLParam,
  };
};
