import React, { useState } from 'react';
import styled from 'styled-components';
import ReactSelect, { components } from 'react-select';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconContainer = styled.div`
  position: absolute;
  left: -25px;
  > svg {
    color: var(--schedule-sapphire);
  }
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const BASE_SELECT_WIDTH = 100;
const SELECT_WIDTH_INCREASE_FACTOR = 5;

const BASE_MENU_WIDTH = 200;
const MENU_WIDTH_INCREASE_FACTOR = 3;

const customStyles = ({ backgroundColor, dropdownIndicatorColor, containerWidth, menuWidth }) => ({
  control(base, state) {
    const control = {
      ...base,
      'backgroundColor': backgroundColor || 'var(--light-gray-bg)',
      'border': state.isFocused && state.menuIsOpen ? 'solid rgb(0 139 231 / 25%) 2px' : 'none',
      'fontSize': '14px',
      'borderRadius': '6px',
      'width': containerWidth || 150,
      'boxShadow': 0,
      '&:hover': {
        border: state.isFocused ? 'solid rgb(0 139 231 / 25%) 2px' : 'none',
      },
    };
    return control;
  },
  clearIndicator() {
    return { display: 'none' };
  },
  indicatorSeparator() {
    return { display: 'none' };
  },
  dropdownIndicator(base) {
    return { ...base, color: dropdownIndicatorColor || 'var(--schedule-sapphire)' };
  },
  menu(base) {
    return { ...base, width: menuWidth || BASE_MENU_WIDTH };
  },
  option(base, state) {
    return {
      ...base,
      backgroundColor: 'none',
      color: 'black',
      paddingLeft: 44,
      paddingRight: 20,
    };
  },
  groupHeading(base) {
    return {
      ...base,
      color: 'var(--action-anchor)',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: '11px',
    };
  },
});

const Option = (props) => {
  return (
    <components.Option {...props}>
      <OptionContainer>
        {props.isSelected && (
          <IconContainer>
            <FontAwesomeIcon icon={faCheck} />
          </IconContainer>
        )}
        {props.label}
      </OptionContainer>
    </components.Option>
  );
};

/**
 * Styled wrapper for react-select input
 * @property {object} props.components react-select components
 * @property {object} props.onChange select onchange event
 * @property {object} props.containerWidth default width for select input
 * @property {object} props all available react-select props
 * @returns {JSX.Element}
 */
const Select = ({ components = {}, onChange = () => {}, containerWidth, ...props }) => {
  const value = props?.value;

  /**
   * Recalculate width for dropdown input
   * @param {number} itemLength
   * @returns
   */
  const getNewSelectInputWidth = (itemLength) =>
    BASE_SELECT_WIDTH + itemLength * SELECT_WIDTH_INCREASE_FACTOR;

  /**
   * Recalculate width for dropdown menu
   * @param {*} itemLength
   * @returns
   */
  const getNewMenuWidth = (itemLength) => BASE_MENU_WIDTH + itemLength * MENU_WIDTH_INCREASE_FACTOR;

  const [selectWidth, setSelectWidth] = useState(
    value && typeof value === 'string' ? getNewSelectInputWidth(value?.length) : containerWidth
  );

  /**
   * Find the largest label in the options array
   * @returns {void}
   */
  const getLargestOptionLabel = () => {
    if (!props?.options?.length) {
      return null;
    }

    const labelLengths = props?.options?.map((opt) => {
      if (opt?.options) {
        const innerLengths = opt?.options.map((innerOpt) => innerOpt?.label.length);
        return Math.max(...innerLengths);
      } else {
        return opt?.label.length;
      }
    });

    const maxLength = labelLengths?.length ? Math.max(...labelLengths) : null;
    return maxLength;
  };

  /**
   * onChange handler
   * @param {object | array} selectedOption
   */
  const handleChange = (selectedOption) => {
    onChange(selectedOption);
    if (typeof selectedOption?.label === 'string') {
      setSelectWidth(getNewSelectInputWidth(selectedOption?.label?.length));
    }
  };

  return (
    <ReactSelect
      styles={customStyles({
        ...props,
        containerWidth: selectWidth,
        menuWidth: getNewMenuWidth(getLargestOptionLabel()),
      })}
      components={{ ...components, Option }}
      onChange={handleChange}
      {...props}
    />
  );
};

export default Select;
