import { useState, useEffect } from 'react';
import * as ClientTypeApi from '../../api/clientType';
import toast from '../../components/toast';

const useClientTypes = () => {
  const [clientTypes, setClientTypes] = useState([]);
  const [clientTypeOptions, setClientTypeOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchClientTypes = async () => {
    setLoading(true);
    try {
      const response = await ClientTypeApi.fetchClientTypes();
      setClientTypes(response?.items || []);

      if (response?.items?.length) {
        setClientTypeOptions(
          response.items.map((clientType) => ({ label: clientType.name, value: clientType.id }))
        );
      }
    } catch (err) {
      console.log(err);
      toast.error('There was an error fetching client types list');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientTypes();
  }, []);

  return {
    clientTypes,
    clientTypeOptions,
    loading,
  };
};

export default useClientTypes;
