import { useRef, useContext } from 'react';
import styled from 'styled-components';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOutsideClickNotifier from 'helpers/hooks/useOutsideClickNotifier';
import DateChangeContext from '../context/dateChangeContext';

const Wrapper = styled.div`
  position: absolute;
  top: 15px;
  left: 240px;
  width: 350px;
  max-height: 400px;
  overflow-y: auto;
  padding: 16px 20px 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
`;
const Stage = styled.div`
  color: var(--action-anchor);
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 8px;
  &:hover {
    cursor: default;
  }
`;
const StartingStep = styled.button`
  display: block;
  width: 100%;
  text-align: left;
  font-size: 16px;
  line-height: 30px;
  padding-left: 25px;
  margin-bottom: 8px;
  position: relative;
  border: none;
  background: #fff;
  &:hover {
    cursor: pointer;
    background-color: rgb(0, 0, 0, 0.1);
  }
  &:disabled {
    cursor: not-allowed;
    color: var(--playbook-pewter);
  }
`;
const Step = styled.button`
  display: block;
  width: 100%;
  text-align: left;
  font-size: 16px;
  padding-left: ${(props) => (props.indentText ? '35px' : '25px')};
  margin-bottom: 8px;
  position: relative;
  text-indent: ${(props) => (props.indentText ? '-10px' : '0')};
  border: none;
  background: #fff;
  &:hover {
    cursor: pointer;
    background-color: rgb(0, 0, 0, 0.1);
  }
  &:disabled {
    cursor: not-allowed;
    color: var(--playbook-pewter);
  }
`;
const Divider = styled.div`
  border-bottom: 1px solid var(--border-gray);
  margin-bottom: 8px;
`;
const CheckMark = styled(FontAwesomeIcon)`
  position: absolute;
  left: 6px;
  top: ${(props) => props.top};
`;

export default function DependentModal({ dependentData }) {
  const {
    activeTouchpoint,
    dependentStep,
    updateDependencyStatus,
    updateDependentStep,
    toggleDependentStepModal,
  } = useContext(DateChangeContext);

  const clickRef = useRef(null);
  useOutsideClickNotifier(clickRef, () => {
    toggleDependentStepModal(false);
  });

  const handleDependencyChange = (id, status) => {
    updateDependencyStatus(status);
    updateDependentStep(id);
    toggleDependentStepModal(false);
  };

  const buildDependenciesFrom = (touchpoint) => {
    // This will recursively build the list of all dependencies downstream of touchpoint
    let chain = [touchpoint?.id];

    // Is this a template Touchpoint
    if (touchpoint?.touchpointDependents !== undefined && touchpoint?.touchpointDependents?.length > 0) {
      touchpoint?.touchpointDependents.forEach(
        (dependent) => (chain = chain.concat(buildDependenciesFrom(dependent)))
      );
    }

    // Or is at a project CustomerTouchpoint
    if (
      touchpoint?.customerTouchpointDependents !== undefined &&
      touchpoint?.customerTouchpointDependents?.length > 0
    ) {
      touchpoint?.customerTouchpointDependents.forEach(
        (dependent) => (chain = chain.concat(buildDependenciesFrom(dependent)))
      );
    }

    return chain;
  };

  const hasDependencies = (touchpoint) => {
    const dependencyList = buildDependenciesFrom(activeTouchpoint);
    // If we find this touchpoint in the chain of dependencies downstream of activeTouchpoint
    // then we cannot allow it to be a valid dependency option
    return undefined !== dependencyList.find((x) => x === touchpoint?.id);
  };

  return (
    <Wrapper ref={clickRef}>
      <StartingStep onClick={() => handleDependencyChange(null, true, 'start')}>
        {!dependentStep && <CheckMark icon={faCheck} size="xs" top="8px" />}
        <span>Start of the project</span>
      </StartingStep>
      {dependentData &&
        dependentData.length !== 0 &&
        dependentData.map((item, index) => (
          <div key={item + index}>
            <Stage>{item.name}</Stage>
            {item?.touchpoints.map((touchpoint) => (
              <Step
                disabled={
                  activeTouchpoint?.id === touchpoint.id ||
                  hasDependencies(touchpoint) ||
                  touchpoint?.noDueDate
                }
                key={touchpoint.id}
                onClick={() => handleDependencyChange(touchpoint?.id, true, 'step')}
                indentText={touchpoint?.title.length >= 29 ? true : false}
              >
                {dependentStep === touchpoint?.id && <CheckMark icon={faCheck} size="xs" top="5px" />}
                {touchpoint?.title}
              </Step>
            ))}
            {index !== dependentData.length - 1 && <Divider />}
          </div>
        ))}
    </Wrapper>
  );
}
