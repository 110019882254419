import { useRef, useContext } from 'react';
import { Wrapper, Entry, CheckMark } from '../styles';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import useOutsideClickNotifier from '../../../../../helpers/hooks/useOutsideClickNotifier';
import DateChangeContext from '../context/dateChangeContext';

export default function Duration() {
  const { durationAmount, durationOption, updateDurationOption, toggleDurationOptionModal } =
    useContext(DateChangeContext);

  const options = ['Days', 'Weeks', 'Months'];

  const clickRef = useRef(null);
  useOutsideClickNotifier(clickRef, () => {
    toggleDurationOptionModal(false);
  });

  const handleSelection = (value) => {
    updateDurationOption(value);
    toggleDurationOptionModal(false);
  };

  return (
    <Wrapper ref={clickRef} width="200px" left="86px">
      {options.map((option) => (
        <Entry key={option} onClick={() => handleSelection(option)}>
          {durationOption === option && <CheckMark icon={faCheck} size="xs" top="5px" />}
          {option === 'Days' ? 'Business Days (M-F)' : durationAmount === 1 ? option.slice(0, -1) : option}
        </Entry>
      ))}
    </Wrapper>
  );
}
