import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import RoundedButton from 'components/RoundedButton';

/**
 * Rounded button with a plus icon inside
 * @property {object} className styles from parent component
 * @property {function} onClick callback to execute when clicking the button
 * @returns {JSX.Element}
 */
const RoundedPlusButton = ({ onClick, className }) => (
  <RoundedButton onClick={onClick} className={`${className} rounded-plus-button`}>
    <FontAwesomeIcon icon={faPlus} size="xs" />
  </RoundedButton>
);

export default RoundedPlusButton;
