import { faClone, faEllipsisH, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFile, faTasks, faUserFriends } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import * as CommonHelper from '../../helpers/CommonHelper';
import Tags from '../Tags';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { DueDescriptionContainer, TagsContainer } from './Styles';
import { NOT_STARTED } from 'constants';
import { Dropdown } from 'react-bootstrap';

const StyledIcon = styled(FontAwesomeIcon)`
  margin-top: 2px;
`;

const durationOptions = {
  Days: 'day',
  Weeks: 'week',
  Months: 'month',
};

export default function Card({ item, onDelete, onDuplicate, index, isPreviewMode }) {
  const iconItem = { ...item, customerTouchpointStatus: NOT_STARTED };
  const iconInfo = CommonHelper.getIconInfo(iconItem);
  const isEmptyTasks = !item?.tasks?.length;
  const isEmptyTags = !item?.touchpointTags?.length;
  const isEmptyAttachments = !item?.touchpointAttachments?.length;
  const isEmptyLinks = !item?.touchpointLinks?.length;
  const isCustomerFacing = item?.customerFacing;
  const isEmptyEmailTrigger = !item?.emailTrigger;
  const isEmptyAttachmentsLinks = isEmptyAttachments && isEmptyLinks;
  const isEmptyWatermarkIcons =
    !isCustomerFacing && isEmptyTasks && isEmptyAttachmentsLinks && isEmptyEmailTrigger;
  let dueDateDescription = '';
  if (item) {
    if (item.noDueDate) {
      dueDateDescription = 'No due date';
    } else {
      const durationAmount = item.durationOptionAmount;
      const duration = CommonHelper.pluralize(durationAmount, durationOptions[item.durationOption]);
      const dependent = item.dependentTitle || 'start of project';
      const isSameDay = durationAmount === 0;

      dueDateDescription = isSameDay ? `Same day as ${dependent}` : `${duration} after ${dependent}`;
    }
  }

  // Workaround to trigger preventDefault event when clicking toggle
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="cursor-pointer"
    >
      {children}
    </div>
  ));

  // Workaround to remove anchor tag nested warning
  const CustomItem = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      role="link"
      className="dropdown-item"
    >
      {children}
    </div>
  ));

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(item.id);
  };

  const handleDuplicate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDuplicate(item.id);
  };

  const cardDetails = () => (
    <>
      {iconInfo.icon}
      <Link to={item.link} className="cursor-pointer card-content card-width position-relative">
        <div className="card-title">
          <div className="large-text">{item.title}</div>

          <Dropdown className="canvas-overflow order-md-1">
            <Dropdown.Toggle as={CustomToggle}>
              <FontAwesomeIcon icon={faEllipsisH} style={{ marginLeft: '20px' }} />
            </Dropdown.Toggle>
            <Dropdown.Menu align="left">
              <Dropdown.Item as={CustomItem} onClick={handleDuplicate}>
                <FontAwesomeIcon fixedWidth icon={faClone} />
                Duplicate Step
              </Dropdown.Item>
              <Dropdown.Item as={CustomItem} onClick={handleDelete}>
                <FontAwesomeIcon fixedWidth icon={faTrash} />
                Delete Step
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <DueDescriptionContainer
          hasBottomMargin={isEmptyTags && !isEmptyWatermarkIcons}
          isOrange={item?.noDueDate}
        >
          {dueDateDescription}
        </DueDescriptionContainer>
        {!isEmptyTags && (
          <TagsContainer noBottomMargin={isEmptyWatermarkIcons}>
            <Tags tags={item.touchpointTags} />
          </TagsContainer>
        )}
        <div>
          <div className="d-flex align-items-end justify-content-between">
            <div className="d-flex flex-wrap items-gap mr-2"></div>
            <div className="d-flex items-gap card-icons">
              {isCustomerFacing && (
                <StyledIcon icon={faUserFriends} className="fa-xs" title="Customer Facing" />
              )}
              {!isEmptyTasks && <FontAwesomeIcon icon={faTasks} title="Checklist items" />}
              {!isEmptyAttachmentsLinks && <FontAwesomeIcon icon={faFile} title="Uploaded assets" />}
              {!isEmptyEmailTrigger && <FontAwesomeIcon icon={faEnvelope} title="Triggers action" />}
            </div>
          </div>
        </div>
      </Link>
    </>
  );

  if (isPreviewMode) return <div className="card-wrapper connected">{cardDetails()}</div>;

  return (
    <Draggable key={`touchpoint-${item.id}`} draggableId={`${item.id}`} index={index}>
      {(provided) => (
        <div
          className="card-wrapper connected"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {cardDetails()}
        </div>
      )}
    </Draggable>
  );
}
