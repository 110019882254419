import { get } from 'api/Api';

class MergeTag {
  id = '';
  name = '';

  constructor(props) {
    if (props) Object.assign(this, props);
  }

  static fetchAll() {
    return get('TouchPoint/GetMergeTags');
  }
}

export default MergeTag;
