import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Api from '../api/Api';
import FullScreenWithBackground from '../components/FullScreenWithBackground';

class ResetPassword extends React.Component {
  state = {
    email: '',
    emailSent: false,
    error: null,
    loading: false,
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    var email = this.state.email;
    let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    if (!emailValid) {
      this.setState({ error: 'Please enter a valid email address.' });
      return;
    }
    this.setState({ error: null });

    let payload = {
      email: email,
    };

    this.setState({ loading: true });
    let response;
    try {
      response = await Api.post('Authentication/SendRecoveryEmail', payload, {
        rawResponse: true,
      });
    } catch (error) {
      this.setState({ error: error.message });
    }
    if (response) {
      if (response?.status === 404) {
        this.setState({ error: 'User not found.' });
      } else if (response?.status === 200) {
        this.setState({ emailSent: true });
      } else {
        this.setState({ error: 'An unknown error has occurred. Please try again later.' });
      }
    }
    this.setState({ loading: false });
  };

  render() {
    if (this.state.emailSent) {
      return (
        <FullScreenWithBackground>
          <h3>An email is on its way...</h3>
          <p>
            If you don't receive an email, please make sure you've entered the address you registered with,
            and check your spam folder.
          </p>
          <Link to="/login" className="d-inline-block mt-1 font-weight-bold">
            Log in
          </Link>
        </FullScreenWithBackground>
      );
    }
    return (
      <FullScreenWithBackground title="Reset password" loading={this.state.loading} error={this.state.error}>
        <form onSubmit={this.handleSubmit}>
          <p>Enter your email below and we will send you a link to reset your password.</p>
          <Form.Group className="mb-4">
            <Form.Control
              autoFocus
              name="email"
              type="email"
              placeholder="Email"
              onChange={this.handleInputChange}
            />
          </Form.Group>
          <Button type="submit" variant="primary" onClick={this.handleSubmit} block>
            Send
          </Button>
          <div className="text-center">
            <Link to="/login" className="d-inline-block mt-4 muted font-weight-bold">
              Log in
            </Link>
          </div>
        </form>
      </FullScreenWithBackground>
    );
  }
}

export default ResetPassword;
