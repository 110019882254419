export const TEMPLATE_SETTINGS = 'Template Settings';
export const PROJECT_SETTINGS = 'Project Settings';
export const NAME = 'name';
export const LIVE = 'Live';
export const AUTO_ADVANCE = 'autoadvance';
export const DYNAMIC_TIMELINE = 'dynamicTimeline';
export const LIVE_STATUS = 'liveStatus';
export const SEND_EMAIL = 'projectUpdate';
export const SEND_TO = 'sendTo';
export const EMAIL_FREQUENCY_VALUE = 'emailFrequencyValue';
export const EMAIL_FREQUENCY_UNIT = 'emailFrequencyUnit';
export const SEND_PASTDUE_EMAIL = 'sendPastDueEmail';
export const PAST_DUE_STEP = 'pastDueStep';
export const SYSTEM_EMAILS = 'System Emails';
export const INTERNAL_STEPS = 'Internal steps';
export const ALL_STEPS = 'All steps';
export const EXTERNAL_STEP_MEMBERS = 'External step members';

export const CANVAS_SETTINGS_FIELD = {
  PROJECT_UPDATE_TO: 'sendProjectUpdateTo',
  PROJECT_UPDATE_EVERY: 'sendProjectUpdateEvery',
  PROJECT_UPDATE_FREQUENCY: 'sendProjectUpdateFrequency',
  PAST_DUE_FOR: 'sendPastDueEmailFor',
  PAST_DUE_TO: 'sendPastDueEmailTo',
};

export const CANVAS_SETTINGS_PROPERTY = {
  [CANVAS_SETTINGS_FIELD.PROJECT_UPDATE_TO]: 'emailSettings/projectUpdate/to',
  [CANVAS_SETTINGS_FIELD.PROJECT_UPDATE_EVERY]: 'emailSettings/projectUpdate/frequencyValue',
  [CANVAS_SETTINGS_FIELD.PROJECT_UPDATE_FREQUENCY]: 'emailSettings/projectUpdate/frequencyUnit',
  [CANVAS_SETTINGS_FIELD.PAST_DUE_FOR]: 'emailSettings/pastDueStep/for',
  [CANVAS_SETTINGS_FIELD.PAST_DUE_TO]: 'emailSettings/pastDueStep/to',
};

export const PROJECT_LIST = {
  ALL_MEMBERS: "All project members",
  EXTERNAL: "External project members",
  INTERNAL: "Internal project members",
  PROJECT_OWNER: "Project owner"
}
