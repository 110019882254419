import { useState, useRef, useContext, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { debounce, getIconInfo, getUniqueName } from 'helpers/CommonHelper';
import FullContext from 'stores/Full/fullContext';
import CustomerFacing from '../EditTouchpoint/CustomerFacing';
import styled from 'styled-components';
import useAutosizeTextArea from 'helpers/hooks/useAutosizeTextArea';
import * as Api from '../../../api/Api';

const DescriptionContainer = styled.div`
  margin-bottom: 36px;
`;

const StatusIconContainer = styled.div`
  margin-right: 0.75rem;
  top: 0.75rem;
  position: relative;
`;

const CustomerFacingContainer = styled.div`
  top: 1.125rem;
  position: relative;
  margin-top: 1.5rem;
`;

export default function TouchpointHeader({
  touchpoint,
  isCustomerFacing,
  handleCustomerFacing,
  isCancelled,
  handleReload,
  mergedSteps,
}) {
  const [title, setTitle] = useState(touchpoint.title);
  const [description, setDescription] = useState(touchpoint.description || '');
  const { isEditMode, isViewAsCustomer, isExternal } = useContext(FullContext);
  const titleRef = useRef();
  const descriptionRef = useRef();
  const iconInfo = getIconInfo(touchpoint);

  useAutosizeTextArea(descriptionRef.current, description);
  useAutosizeTextArea(titleRef.current, title);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTouchpointUpdates = useCallback(
    debounce(async (value) => {
      // If the title hasn't changed, or the field is blank, don't send a POST request
      if (!value || value === touchpoint?.title) return;

      await Api.post('Touchpoint/EditCustomerTouchpoint', {
        ...touchpoint,
        title: getUniqueName(mergedSteps, value),
        description,
      });

      handleReload();
    }, 500),
    [description]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDescriptionChange = useCallback(
    debounce(async (value) => {
      if (value === touchpoint?.description) return;

      await Api.post('Touchpoint/EditCustomerTouchpoint', {
        ...touchpoint,
        description: value,
        title,
      });

      handleReload();
    }, 500),
    [title]
  );

  return (
    <>
      <div className="d-flex justify-content-between flex-column flex-md-row">
        <h1 className="mt-4 mb-1 font-weight-bold d-flex flex-grow-1">
          <StatusIconContainer>{iconInfo.icon}</StatusIconContainer>
          <Form.Control
            className="resizable-textarea ml-1"
            placeholder="Description..."
            disabled={isExternal || isViewAsCustomer}
            ref={titleRef}
            as="textarea"
            value={title ?? touchpoint?.title}
            onChange={async (e) => {
              const { value } = e.target;
              setTitle(value);
              handleTouchpointUpdates(value);
            }}
          />
        </h1>
        {(isCustomerFacing || isEditMode) && !isExternal && (
          <CustomerFacingContainer>
            <CustomerFacing
              touchpoint={touchpoint}
              onUpdated={handleCustomerFacing}
              isProject
              isCancelled={isCancelled}
            />
          </CustomerFacingContainer>
        )}
      </div>

      {!isEditMode && !description ? (
        <DescriptionContainer />
      ) : (
        <DescriptionContainer>
          <Form.Control
            ref={descriptionRef}
            className="resizable-textarea"
            placeholder="Enter a step description..."
            as="textarea"
            value={description}
            disabled={!isEditMode}
            onChange={async (e) => {
              const { value } = e.target;
              setDescription(value);
              handleDescriptionChange(value);
            }}
          />
        </DescriptionContainer>
      )}
    </>
  );
}
