import InfoTextBox from '../../InfoTextBox';

const templateData = [
  `When selected, the customer will not see the due
  date but a "to be scheduled" label.`,
  `Choosing a due date, even though the actual date
  requires scheduling, provides an estimated project
  length, reminds CSMs when to schedule the step
  by and allows you to set this step as a dependency
  for other steps.`
];

const editModeData = [
  ...templateData,
  `You can schedule a date once you exit Edit Project 
  View.`
];

const Paragraphs = ({ data }) => (
  data.map(val => <p>{val}</p>)
);

const ScheduledTypeInfo = ({ noDueDate, activeTouchpoint }) => (
  <InfoTextBox
    notActive={noDueDate}
    width="410px"
  >
    {activeTouchpoint ? (
      <Paragraphs data={editModeData} />
    ) : (
      <Paragraphs data={templateData} />
    )}
  </InfoTextBox>
);

export default ScheduledTypeInfo;