import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableRow from 'components/TableRow';
import { faExclamation, faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { ActionsColumn, Column, ExclamationIcon, LastRunColumn, NameColumn, Switch } from './Styles';
import { format } from 'date-fns';
import DeleteButtonTrashIcon from 'components/DeleteButtonTrashIcon';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const Rule = ({ rule, openRuleDetails, handleRuleStatusUpdate, setItemForDelete, mappedTriggerType }) => {
  const { setNodeRef, listeners, isDragging, transform, transition } = useSortable({
    id: rule?.id,
  });

  const fieldStyle = {
    transition,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : undefined,
  };

  return (
    <div ref={setNodeRef} style={fieldStyle}>
      <TableRow>
        <NameColumn md={4} title={rule.name} onClick={() => openRuleDetails(rule)}>
          <FontAwesomeIcon
            icon={faGripVertical}
            className="mr-2-5"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            {...listeners}
          />

          <b>{rule.name}</b>
        </NameColumn>
        <Column md={2}>
          <b>Live</b>
          <Switch
            type="switch"
            id={`live-rule-switch-${rule.id}`}
            name={`live-rule-switch-${rule.id}`}
            defaultChecked={Boolean(rule.isLive)}
            onChange={(e) => handleRuleStatusUpdate(rule, e.target.checked)}
          />
        </Column>
        <LastRunColumn md={2}>
          {Boolean(rule.hasError) && <ExclamationIcon icon={faExclamation} />}
          {Boolean(rule.hasError) && (
            <>
              <b>Error: </b> &nbsp;
            </>
          )}{' '}
          {rule.lastExecution ? format(new Date(rule.lastExecution), 'p LLL dd') : '-'}
        </LastRunColumn>
        <Column md={3}>{mappedTriggerType[rule.triggerType] || '-'}</Column>
        <ActionsColumn>
          <DeleteButtonTrashIcon onClick={() => setItemForDelete(rule.id)} />
        </ActionsColumn>
      </TableRow>
    </div>
  );
};

export default Rule;
