import { faGripVertical, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Form } from 'react-bootstrap';
import * as Api from '../../../api/Api';
import ChecklistItem from '../Shared/ChecklistItem';
import ModalConfirm from '../Confirm/index';

export default function Checklist({ touchpoint, onUpdated }) {
  const [newText, setNewText] = useState('');
  const [savingNew, setSavingNew] = useState(false);
  const [taskForDelete, setTaskForDelete] = useState(null);
  const loaded = useRef(false);
  const textInput = useRef(null);

  useEffect(() => {
    // refocus in add item after creating, but not on first load
    if (!loaded.current) {
      loaded.current = true;
    } else if (!savingNew) {
      textInput.current?.focus();
    }
  }, [savingNew]);

  async function createTask() {
    if (newText.trim()) {
      setSavingNew(true);
      const response = await Api.post(
        'Touchpoint/CreateTemplateTask',
        { description: newText, order: touchpoint.tasks.length + 1 },
        { params: { touchpointId: touchpoint.id } }
      );
      onUpdated(response);
      setNewText('');
      setSavingNew(false);
    }
  }

  async function deleteTask() {
    await Api.deleteRequest(`Touchpoint/DeleteTemplateTask?id=${taskForDelete.id}`);
    onUpdated(touchpoint.tasks.filter((t) => t.id !== taskForDelete.id));
    setTaskForDelete(null);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((item, index) => (item.order = index));
    return result;
  };

  async function updateTask(task, description) {
    await Api.put('Touchpoint/UpdateTemplateTask', { description: description }, { params: { id: task.id } });
  }

  async function handleItemSave(task, text) {
    await updateTask(task, text);
    onUpdated(touchpoint.tasks.map((t) => (task.id === t.id ? { ...t, description: text } : t)));
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(touchpoint.tasks, result.source.index, result.destination.index);

    onUpdated(items);

    const orderModel = items.map((a, index) => ({
      description: a.description,
      id: a.id,
      order: index,
    }));

    Api.post('Touchpoint/UpdateTaskOrder', orderModel);
    // onUpdated();
  }

  return (
    <>
      <div className="d-flex justify-content-between settings-section-title mt-4 mb-0">
        <h2>Checklist</h2>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="all-tasks" direction="vertical">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {touchpoint.tasks
                .sort((a, b) => a.order - b.order)
                .map((item, index) => (
                  <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <div className="d-flex align-items-center justify-content-between py-3">
                          <div className="d-flex align-items-center flex-grow-1">
                            <FontAwesomeIcon icon={faGripVertical} className="mr-2-5" />
                            <ChecklistItem item={item} onSave={handleItemSave} />
                          </div>
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="ml-2 cursor-pointer"
                            onClick={() => {
                              setTaskForDelete(item);
                            }}
                          />
                        </div>
                        <hr className="my-0" />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Form.Control
        className="mt-3 mb-4 no-border"
        placeholder="Add item..."
        ref={textInput}
        disabled={savingNew}
        value={newText}
        onChange={(e) => setNewText(e.target.value)}
        onBlur={createTask}
        onKeyPress={(event) => event.key === 'Enter' && createTask()}
      />
      <ModalConfirm
        title="Delete task"
        message={
          <>
            Are you sure you want to delete this task?
            <br />
            This action cannot be undone.
          </>
        }
        show={taskForDelete !== null}
        onConfirm={() => {
          deleteTask();
        }}
        onHide={() => {
          setTaskForDelete(null);
        }}
      />
    </>
  );
}
