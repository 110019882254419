import canvasIcon from '../img/canvas_icon.svg';
import Tags from './Tags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesUp, faAnglesDown } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { UNTITLED_PROJECT } from 'constants';

const LinkFormat = styled.div`
  font-size: 14px;
`;

const IconContainer = styled.div`
  cursor: pointer;
  svg {
    color: var(--schedule-sapphire);
  }
`;

export default function CanvasItem({
  canvas,
  showTags = false,
  to,
  showSummary,
  setShowSummary,
  enableSubItems,
}) {
  const summaryHandler = (e) => {
    e.preventDefault();
    setShowSummary();
  };

  return (
    <div className="canvas-item">
      {enableSubItems && (
        <IconContainer>
          <FontAwesomeIcon
            icon={showSummary ? faAnglesUp : faAnglesDown}
            className="mr-3 fa-lg"
            onClick={summaryHandler}
          />
        </IconContainer>
      )}
      <div className={`mr-3 canvas-icon flex-shrink-0 ${canvas?.color || 'process-peach'}`}>
        <img src={canvasIcon} alt="canvas-icon" aria-hidden />
      </div>
      <div className="canvas-info d-flex flex-column">
        <div className="canvas-name">{canvas?.canvasName || canvas?.name || UNTITLED_PROJECT}</div>
        {enableSubItems && (
          <Link className="font-weight-bold d-inline-flex" to={to}>
            <LinkFormat>View Project</LinkFormat>
          </Link>
        )}
        {showTags && canvas?.canvasTags && (
          <div className="tags-days-wrapper">
            <Tags tags={canvas?.canvasTags} />
            {canvas?.days > 0 ? <div className="days-subheader">{canvas?.days} business days</div> : null}
          </div>
        )}
      </div>
    </div>
  );
}
