import React, { Fragment, useMemo } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { FormInput } from 'components/Formik/FormInput';
import { CUSTOM_FIELDS_MODAL_VARIATIONS, CUSTOMERS_FIELDS } from 'constants';
import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
} from '../LargePadding';
import FormField from './FormField';
import { ScrollableContainer, SectionName, FieldContainer } from './Styles';
import { isDropDownType } from 'helpers/CommonHelper';

const ModalAddAccount = ({ show, onHide }) => {
  const { values, submitForm, isSubmitting } = useFormikContext();

  const renderContent = useMemo(() => {
    return (
      <div>
        <h1 className="mb-3 text-center">New account</h1>

        <ScrollableContainer>
          <FieldContainer>
            <FormInput fieldLabel="Account name" isRequired name={CUSTOMERS_FIELDS.ACCOUNT_NAME} />
          </FieldContainer>

          <FieldArray
            name={CUSTOMERS_FIELDS.DETAILS_FIELDS}
            render={() => (
              <>
                {values.detailsFields.map((section, sectionKey) => (
                  <Fragment key={section.customFieldSectionId}>
                    <SectionName>{section.name}</SectionName>
                    {section.fields.map((field, fieldKey) => {
                      const isDropDown = isDropDownType(field?.fieldType);
                      const name = `detailsFields[${sectionKey}].fields[${fieldKey}].formikValue`;

                      return (
                        <FieldContainer key={field.objectMapId}>
                          <FormField
                            field={field}
                            name={name}
                            variation={CUSTOM_FIELDS_MODAL_VARIATIONS.CUSTOMER}
                            isDropDownLastItem={isDropDown && values.detailsFields.length - 1 === sectionKey}
                          />
                        </FieldContainer>
                      );
                    })}
                  </Fragment>
                ))}
              </>
            )}
          />
        </ScrollableContainer>
      </div>
    );
  }, [values.detailsFields]);

  /**
   * Renders the buttons for the modal
   * @param none
   * @returns Button Component
   */
  const renderButtons = (
    <>
      <ModalLargePaddingCancelButton onClick={onHide} autoWidth />
      <ModalLargePaddingConfirmButton onClick={submitForm} loading={isSubmitting} text="Save" autoWidth />
    </>
  );

  const modalStyle = {
    inner: {
      maxWidth: 'unset',
      padding: '2rem 0 0 0 !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'var(--light-gray-bg)',
      borderRadius: '0 0 0.813rem 0.813rem',
      padding: '1.875rem 0 2rem 0 !important',
    },
  };

  return (
    <ModalLargePadding
      show={show}
      innerProps={modalStyle.inner}
      buttonsContainerProps={modalStyle.buttonContainer}
      content={renderContent}
      buttons={renderButtons}
      centered={false}
    />
  );
};

export default ModalAddAccount;
