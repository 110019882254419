import { Form } from 'react-bootstrap';
import styled from 'styled-components';

export const DropdownContainer = styled.div`
  .select__control {
    background-color: var(--light-gray-bg);
    border-style: none;
    &--is-focused {
      filter: drop-shadow(0 0px 3px var(--schedule-sapphire-focus));
      box-shadow: none;
    }

    &--is-disabled {
      svg {
        opacity: 30%;
      }
    }
  }

  div[id$='-heading'] {
    color: var(--action-anchor);
    font-size: 0.688rem;
    font-weight: 700;
    line-height: 15px;
    text-transform: uppercase;
  }

  .select__single-value svg {
    display: none;
  }

  .select__menu {
    top: 0;
    left: 0.5rem;
    box-shadow: var(--box-shadow-menu);
  }

  .select__option {
    text-align: left;

    & > div {
      display: flex;
      align-items: center;
    }

    &--is-selected {
      background-color: transparent;
      color: inherit;
    }

    &--is-focused {
      background-color: var(--goal-gray);
    }
  }

  .select__indicator-separator {
    width: unset;
  }

  .select__dropdown-indicator {
    svg {
      color: var(--schedule-sapphire);
    }
  }

  .select__control--is-disabled {
    pointer-events: all;
    cursor: not-allowed;
  }
`;

export const FieldContainer = styled.div`
  transition: all 0.3s ease-out;
  animation: fadeIn 0.5s;

  .field-label > .form-control:disabled {
    border: none;
  }

  .form-control:disabled {
    background-color: var(--light-gray-bg);
    color: var(--input-disabled);
    cursor: not-allowed;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const SectionLabel = styled.div`
  margin-top: 0.625rem;
`;

export const CheckboxContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FieldValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  margin-block: 1rem;

  .field-values__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .field-values__input {
    animation: fadeIn 0.5s;
    padding-inline: 0.25rem;
    margin-bottom: 0.75rem;

    input {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      padding: 0 1.5rem 0.5rem 1.5rem;

      &:focus {
        box-shadow: var(--field-value-shadow);
      }

      &:disabled {
        background-color: transparent;
        cursor: not-allowed;
      }
    }

    .fa-grip-vertical {
      position: absolute;
      top: 18%;
      left: 0;
      z-index: 4;
      cursor: grabbing;
      color: var(--playbook-pewter);
    }

    .fa-grip-vertical.disabled {
      cursor: not-allowed;
    }

    .fa-trash {
      position: absolute;
      top: 18%;
      right: 0;
      z-index: 4;
      cursor: pointer;
    }
  }
`;

export const PermissionLabel = styled(Form.Label)`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .permissions {
    padding-inline: 1.625rem;
    margin: 0;

    span {
      font-weight: bold;
    }
  }
`;

export const PermissionOption = styled.p`
  margin: 0 2em;
  text-indent: -2em;

  span {
    font-size: 1rem;
    font-weight: bold;
  }
`;
