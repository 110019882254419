import { useReducer, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
  FIELD,
  SECTION,
  DETAILS,
  OBJECT_TYPE_ENUM,
  REPLACE_OPERATION,
  PATCH_FIELDS,
  PROFILE_FIELD,
  CONTACTS_FIELD,
  PREVIEW,
  DEFAULT,
} from 'constants';
import { Customers, CustomFields } from 'models';
import CustomFieldsReducer from './customFieldsReducer';
import CustomFieldsContext from './customFieldsContext';
import toast from 'components/toast';

const CustomFieldsState = ({ children }) => {
  const initialState = {
    details: {
      editableSections: [],
      editableFields: [],
    },
    contacts: {
      sections: [],
      editableSections: [],
      editableFields: [],
    },
    projects: {
      editableSections: [],
      editableFields: [],
    },
    profile: {
      editableSections: [],
      editableFields: [],
    },
    options: {
      fieldTypeList: [],
      permissionList: [],
      dataSourceList: [],
      ownerList: [],
      typeList: [],
      externalFields: [],
      flattenedExternalFields: [],
      externalFieldsLookUp: {},
    },
    isChangesSaving: null,
    hasServerError: null,
    isLoading: false,
    mode: DEFAULT,
  };

  const [state, dispatch] = useReducer(CustomFieldsReducer, initialState);
  const location = useLocation();
  const customerId = location.pathname.split('/')[2];

  /**
   * Adds a new section
   * @param sectionLabel
   * @returns void
   */
  const populateNewSection = (sectionLabel) => {
    const request = { name: sectionLabel, objectType: OBJECT_TYPE_ENUM.CUSTOMER };

    debouncedSave(async () => {
      await CustomFields.addCustomSection(request);
      await getAllEditableData();
    });
  };

  /**
   * Adds a new field
   * @param newField
   * @returns void
   */
  const populateNewField = (newField, variation = OBJECT_TYPE_ENUM.CUSTOMER) => {
    switch (variation) {
      case OBJECT_TYPE_ENUM.PROFILE:
        debouncedSave(async () => {
          await CustomFields.addCustomField(newField);
          await getAllEnums(OBJECT_TYPE_ENUM.PROFILE);
          await getAllProfileEditableData();
        });

        break;

      case OBJECT_TYPE_ENUM.PROJECTS:
        debouncedSave(async () => {
          const { errors } = await CustomFields.addCustomField(newField);
          await getAllEnums(OBJECT_TYPE_ENUM.PROJECTS);
          await getAllProjectsEditableData();

          if (errors && errors?.TargetFieldName.length > 0) toast.error(errors?.TargetFieldName.join(' '));
        });

        break;

      case OBJECT_TYPE_ENUM.CONTACTS:
        debouncedSave(async () => {
          const { errors } = await CustomFields.addCustomField(newField);
          await getAllEnums(OBJECT_TYPE_ENUM.CONTACTS);
          await getAllContactsEditableData();

          if (errors && errors?.TargetFieldName.length > 0) toast.error(errors?.TargetFieldName.join(' '));
        });

        break;

      default:
        debouncedSave(async () => {
          await CustomFields.addCustomField(newField);
          await getAllEnums();
          await getAllEditableData();
        });

        break;
    }
  };

  /**
   * Debounces the save state and returns null
   * once process is finished
   * @param none
   * @returns void
   */
  const debouncedSave = async (callback) => {
    setIsSaving(true);
    setHasError(false);

    try {
      await callback();
    } catch (error) {
      setIsSaving(false);
      setHasError(true);
    } finally {
      setIsSaving(false);
    }
  };

  /**
   * Updates the section name
   * @param sectionId
   * @param updatedLabel
   * @returns void
   */
  const updateSection = (sectionId, updatedLabel) => {
    const request = [
      {
        operationType: OBJECT_TYPE_ENUM.CUSTOMER,
        path: PATCH_FIELDS.NAME,
        op: REPLACE_OPERATION,
        value: updatedLabel,
      },
    ];

    debouncedSave(async () => {
      await CustomFields.updateSection(sectionId, request);
      await getAllEditableData();
    });
  };

  /**
   * Updates the section order
   * @param sectionId
   * @param sequenceNumber
   * @returns void
   */
  const updateSectionOrder = async (sectionId, sequenceNumber) => {
    const request = [
      {
        operationType: OBJECT_TYPE_ENUM.CUSTOMER,
        path: PATCH_FIELDS.SEQUENCE_NUMBER,
        op: REPLACE_OPERATION,
        value: +sequenceNumber,
      },
    ];

    await CustomFields.updateSection(sectionId, request);
    await getAllEditableData();
  };

  /**
   * Updates the field order
   * @param fieldId
   * @param sectionId
   * @param sequenceNumber
   * @returns void
   */
  const updateFieldOrder = async (
    fieldId,
    sectionId,
    sequenceNumber,
    tab = DETAILS,
    variation = OBJECT_TYPE_ENUM.CUSTOMER
  ) => {
    const _section = state
      ? state[tab].editableSections.find(({ customFieldSectionId }) => customFieldSectionId === +sectionId)
      : null;

    const sectionName = _section?.name;

    const request = [
      {
        operationType: variation,
        path: PATCH_FIELDS.SEQUENCE_NUMBER,
        op: REPLACE_OPERATION,
        value: +sequenceNumber,
      },
      {
        operationType: variation,
        path: PATCH_FIELDS.SECTION_NAME,
        op: REPLACE_OPERATION,
        value: sectionName,
      },
    ];

    switch (variation) {
      case OBJECT_TYPE_ENUM.PROFILE:
        await CustomFields.updateCustomField(fieldId, request);
        await getAllProfileEditableData();

        break;

      case OBJECT_TYPE_ENUM.CONTACTS:
        await CustomFields.updateCustomField(fieldId, request);
        await getAllContactsEditableData();

        break;

      case OBJECT_TYPE_ENUM.PROJECTS:
        await CustomFields.updateCustomField(fieldId, request);
        await getAllProjectsEditableData();

        break;

      default:
        await CustomFields.updateCustomField(fieldId, request);
        await getAllEditableData();

        break;
    }
  };

  /**
   * Deletes the section
   * @param sectionId
   * @returns void
   */
  const deleteSection = (sectionId) => {
    debouncedSave(async () => {
      await CustomFields.deleteSection(sectionId);
      await getAllEditableData();
    });
  };

  /**
   * Deletes the field
   * @param fieldId
   * @returns void
   */
  const deleteField = (fieldId, variation = OBJECT_TYPE_ENUM.CUSTOMER) => {
    switch (variation) {
      case OBJECT_TYPE_ENUM.PROFILE:
        debouncedSave(async () => {
          await CustomFields.deleteField(fieldId);
          await getAllEnums(OBJECT_TYPE_ENUM.PROFILE);
          await getAllProfileEditableData();
        });

        break;

      case OBJECT_TYPE_ENUM.CONTACTS:
        debouncedSave(async () => {
          await CustomFields.deleteField(fieldId);
          await getAllEnums(OBJECT_TYPE_ENUM.CONTACTS);
          await getAllContactsEditableData();
        });

        break;

      case OBJECT_TYPE_ENUM.PROJECTS:
        debouncedSave(async () => {
          await CustomFields.deleteField(fieldId);
          await getAllEnums(OBJECT_TYPE_ENUM.PROJECTS);
          await getAllProjectsEditableData();
        });

        break;

      default:
        debouncedSave(async () => {
          await CustomFields.deleteField(fieldId);
          await getAllEnums();
          await getAllEditableData();
        });

        break;
    }
  };

  /**
   * Enum population dispatcher
   * @param enums
   * @returns void
   */
  const populateEnums = useCallback((enums) => {
    dispatch({
      type: 'get_enums',
      payload: enums,
    });
  }, []);

  const setMode = useCallback((mode) => {
    dispatch({
      type: 'set_mode',
      payload: mode,
    });
  }, []);

  const populateOwnerTypes = useCallback(({ types, owners }) => {
    dispatch({
      type: 'get_owner_types',
      payload: { types, owners },
    });
  }, []);

  /**
   * Save dispatcher
   * @param value
   * @returns void
   */
  const setIsSaving = useCallback((value) => {
    dispatch({
      type: 'is_changes_saving',
      payload: value,
    });
  }, []);

  const setHasError = useCallback((value) => {
    dispatch({
      type: 'has_server_error',
      payload: value,
    });
  }, []);

  /**
   * Loading dispatcher
   * @param value
   * @returns void
   */
  const setIsLoading = useCallback((value) => {
    dispatch({
      type: 'is_data_loading',
      payload: value,
    });
  }, []);

  /**
   * Editable data population dispatcher
   * @param value
   * @returns void
   */
  const populateEditableData = useCallback((sections, fields) => {
    dispatch({
      type: 'get_editable_sections',
      payload: sections,
    });

    dispatch({
      type: 'get_editable_fields',
      payload: fields,
    });
  }, []);

  /**
   * Editable data profile population dispatcher
   * @param value
   * @returns void
   */
  const populateEditableProfileData = useCallback((sections, fields) => {
    dispatch({
      type: 'get_editable_profile_sections',
      payload: sections,
    });

    dispatch({
      type: 'get_editable_profile_fields',
      payload: fields,
    });
  }, []);

  const populateEditableProjectsData = useCallback((sections, fields) => {
    dispatch({
      type: 'get_editable_projects_sections',
      payload: sections,
    });

    dispatch({
      type: 'get_editable_projects_fields',
      payload: fields,
    });
  }, []);

  /**
   * Updates the field values
   * @param fieldId
   * @param requestBody
   * @returns void
   */
  const updateField = (fieldId, updatedField, variation = OBJECT_TYPE_ENUM.CUSTOMER) => {
    const {
      fieldLabel,
      syncOption,
      customFieldSectionName,
      fieldTypeOptions,
      targetFieldName,
      isRequired,
      isMergeTag,
      isSortedFieldTypeOptions,
    } = updatedField;

    const customSectionName = {
      operationType: variation,
      path: PATCH_FIELDS.SECTION_NAME,
      op: REPLACE_OPERATION,
      value: customFieldSectionName,
    };

    const customFieldLabel = {
      operationType: variation,
      path: PATCH_FIELDS.TARGET_FIELD_NAME,
      op: REPLACE_OPERATION,
      value: targetFieldName,
    };

    const request = [
      {
        operationType: variation,
        path: PATCH_FIELDS.FIELD_LABEL,
        op: REPLACE_OPERATION,
        value: fieldLabel,
      },

      {
        operationType: variation,
        path: PATCH_FIELDS.FIELD_TYPE_OPTIONS,
        op: REPLACE_OPERATION,
        value: fieldTypeOptions,
      },
      {
        operationType: variation,
        path: PATCH_FIELDS.SYNC_OPTION,
        op: REPLACE_OPERATION,
        value: +syncOption,
      },
      {
        operationType: variation,
        path: PATCH_FIELDS.IS_REQUIRED,
        op: REPLACE_OPERATION,
        value: isRequired,
      },
      {
        operationType: variation,
        path: PATCH_FIELDS.IS_MERGE_TAG,
        op: REPLACE_OPERATION,
        value: isMergeTag,
      },
      {
        operationType: variation,
        path: PATCH_FIELDS.IS_SORTED_FIELD_VALUES,
        op: REPLACE_OPERATION,
        value: isSortedFieldTypeOptions,
      },
    ];

    if (customFieldSectionName) request.push(customSectionName);
    if (targetFieldName) request.push(customFieldLabel);

    switch (variation) {
      case OBJECT_TYPE_ENUM.PROFILE:
        debouncedSave(async () => {
          await CustomFields.updateCustomField(fieldId, request);
          await getAllProfileEditableData();
        });

        break;

      case OBJECT_TYPE_ENUM.PROJECTS:
        debouncedSave(async () => {
          await CustomFields.updateCustomField(fieldId, request);
          await getAllProjectsEditableData();
        });

        break;

      case OBJECT_TYPE_ENUM.CONTACTS:
        debouncedSave(async () => {
          const { errors } = await CustomFields.updateCustomField(fieldId, request);
          await getAllContactsEditableData();

          if (errors && errors?.TargetFieldName.length > 0) toast.error(errors?.TargetFieldName.join(' '));
        });

        break;

      default:
        debouncedSave(async () => {
          await CustomFields.updateCustomField(fieldId, request);
          await getAllEditableData();
        });

        break;
    }
  };

  /**
   * Toggles the field visibility
   * @param fieldId
   * @param isHidden
   * @returns void
   */
  const toggleHideField = async (fieldId, isHidden) => {
    const request = [
      {
        operationType: OBJECT_TYPE_ENUM.CUSTOMER,
        path: PATCH_FIELDS.IS_HIDDEN,
        op: REPLACE_OPERATION,
        value: isHidden,
      },
    ];

    await CustomFields.updateCustomField(fieldId, request);
  };

  const updateContactsSections = useCallback((payload) => {
    dispatch({
      type: 'update_contacts_sections',
      payload,
    });
  }, []);

  const updateContactsEditableSections = useCallback((payload) => {
    dispatch({
      type: 'update_contacts_editable_sections',
      payload,
    });
  }, []);

  const updateContactsEditableFields = useCallback((payload) => {
    dispatch({
      type: 'update_contacts_editable_fields',
      payload,
    });
  }, []);

  /**
   * Retrieves all enums
   * @param none
   * @returns void
   */
  const getAllEnums = useCallback(
    async (variation = OBJECT_TYPE_ENUM.CUSTOMER) => {
      const enums = await CustomFields.getAllExternalFields(variation);
      populateEnums(enums);
    },
    [populateEnums]
  );

  /**
   * Retrieves owner options and types
   * @param none
   * @returns void
   */
  const getOwnerTypes = useCallback(async () => {
    const types = await CustomFields.getAllTypes();
    const owners = await CustomFields.getOwnerOptions();

    populateOwnerTypes({ types, owners });
  }, [populateOwnerTypes]);

  /**
   * Retrieves all editable data
   * @param none
   * @returns void
   */
  const getAllEditableData = useCallback(async () => {
    const sections = await CustomFields.getAllEditableSections();
    const fields = sections?.map(({ fields }) => fields.map((field) => field)).flat();
    populateEditableData(sections, fields);
  }, [populateEditableData]);

  /**
   * Retrieves all contacts editable data
   * @param none
   * @returns void
   */
  const getAllContactsEditableData = useCallback(async () => {
    const sections = await CustomFields.getAllEditableSections(OBJECT_TYPE_ENUM.CONTACTS);
    const data = await Customers.getContacts(customerId);
    const fields = sections?.map(({ fields }) => fields.map((field) => field)).flat();

    updateContactsSections(data);
    updateContactsEditableSections(sections);
    updateContactsEditableFields(fields);
  }, [customerId, updateContactsEditableSections, updateContactsEditableFields, updateContactsSections]);

  /**
   * Retrieves all profile editable data
   * @param none
   * @returns void
   */
  const getAllProfileEditableData = useCallback(async () => {
    const sections = await CustomFields.getAllEditableSections(OBJECT_TYPE_ENUM.PROFILE);
    const fields = sections.map(({ fields }) => fields).flat();

    populateEditableProfileData(sections, fields);
  }, [populateEditableProfileData]);

  /**
   * Retrieves all project editable data
   * @param none
   * @returns void
   */
  const getAllProjectsEditableData = useCallback(async () => {
    const sections = await CustomFields.getAllEditableSections(OBJECT_TYPE_ENUM.PROJECTS);
    const fields = sections.map(({ fields }) => fields).flat();

    populateEditableProjectsData(sections, fields);
  }, [populateEditableProjectsData]);

  /**
   * Retrieves the initial custom fields data
   * @param none
   * @returns void
   */
  const fetchInitialData = useCallback(async () => {
    setIsLoading(true);

    try {
      await getAllEditableData();
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [getAllEditableData, setIsLoading]);

  /**
   * Retrieves the initial custom fields data
   * @param none
   * @returns void
   */
  const fetchInitialProfileData = useCallback(async () => {
    setIsLoading(true);

    try {
      await getAllProfileEditableData();
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [getAllProfileEditableData, setIsLoading]);

  /**
   * Retrieves the initial contacts custom fields data
   * @param none
   * @returns void
   */
  const fetchInitialContactsData = useCallback(async () => {
    setIsLoading(true);

    try {
      await getAllContactsEditableData();
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [getAllContactsEditableData, setIsLoading]);

  /**
   * Retrieves the initial projects custom fields data
   * @param none
   * @returns void
   */
  const fetchInitialProjectsData = useCallback(async () => {
    setIsLoading(true);

    try {
      await getAllProjectsEditableData();
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, [getAllProjectsEditableData, setIsLoading]);

  const fetchFieldCount = useCallback(async (fieldId) => {
    return await CustomFields.getGetCustomFieldCountOfStoredData(fieldId);
  }, []);

  /**
   * Formats the initial section state
   * @param sections
   * @returns structured initial section object
   */
  const getSectionsInitialState = (sections, isDetails = true) => {
    const isContacts = !isDetails ? 'contacts-' : '';

    return sections?.reduce((result, current) => {
      return {
        ...result,
        [`${current.customFieldSectionId}-${isContacts}section-${current.sequenceNumber}`]: isDetails
          ? current?.fields?.map((field) => `${field.objectMapId}-${isContacts}field-${field.sequenceNumber}`)
          : {
              section: current?.name,
              fields: current?.fields?.map(
                (field) => `${field.objectMapId}-${isContacts}field-${field.sequenceNumber}`
              ),
            },
      };
    }, {});
  };

  const getProjectSectionsInitialState = (sections) => {
    return sections?.reduce((result, current) => {
      return {
        ...result,
        [`${current.customFieldSectionId}-projects-section-${current.sequenceNumber}`]: {
          section: current?.name,
          fields: current?.fields?.map(
            (field) => `${field.objectMapId}-projects-field-${field.sequenceNumber}`
          ),
        },
      };
    }, {});
  };

  /**
   * Checks if the field or section label exists
   * @param key
   * @param value
   * @returns boolean
   */
  const isLabelExist = (key = FIELD, value) => {
    const { editableSections, editableFields } = state.details;
    const { editableFields: profileFields } = state.profile;
    const { editableFields: contactFields } = state.contacts;

    switch (key) {
      case FIELD:
        return editableFields?.some(({ fieldLabel }) => fieldLabel.toLowerCase() === value?.toLowerCase());

      case PROFILE_FIELD:
        return profileFields?.some(({ fieldLabel }) => fieldLabel.toLowerCase() === value?.toLowerCase());

      case CONTACTS_FIELD:
        return contactFields?.some(({ fieldLabel }) => fieldLabel.toLowerCase() === value?.toLowerCase());

      case SECTION:
        return editableSections?.some(({ name }) => name?.toLowerCase() === value?.toLowerCase());

      default:
        return false;
    }
  };

  const sectionDropdown = useMemo(() => {
    const tab = location.hash.split('#')[1]?.split('?')[0] ?? DETAILS;
    return (
      state[tab]?.editableSections?.map(({ customFieldSectionId, name }) => {
        return {
          value: customFieldSectionId,
          label: name,
        };
      }) || []
    );
  }, [state, location.hash]);

  const draggableSections = useMemo(
    () => getSectionsInitialState(state.details?.editableSections),
    [state.details?.editableSections]
  );

  const draggableContactsSections = useMemo(
    () => getSectionsInitialState(state.contacts?.editableSections, false),
    [state.contacts?.editableSections]
  );

  const draggableProjectsSections = useMemo(
    () => getProjectSectionsInitialState(state.projects?.editableSections),
    [state.projects?.editableSections]
  );

  const isPreviewMode = useMemo(() => state.mode === PREVIEW, [state.mode]);

  return (
    <CustomFieldsContext.Provider
      value={{
        details: state.details,
        sections: state.details?.sections,
        editableSections: state.details?.editableSections,
        editableFields: state.details?.editableFields,
        contacts: state.contacts,
        profile: state.profile,
        projects: state.projects,
        options: state.options,
        profileFields: state.profile?.fields,
        isChangesSaving: state.isChangesSaving,
        hasServerError: state.hasServerError,
        isLoading: state.isLoading,
        isPreviewMode,
        draggableSections,
        draggableContactsSections,
        draggableProjectsSections,
        sectionDropdown,
        isLabelExist,
        populateNewSection,
        populateNewField,
        updateSection,
        deleteSection,
        deleteField,
        updateField,
        updateContactsSections,
        getAllEnums,
        getAllEditableData,
        updateSectionOrder,
        updateFieldOrder,
        toggleHideField,
        fetchInitialData,
        fetchInitialContactsData,
        getOwnerTypes,
        getAllProfileEditableData,
        fetchFieldCount,
        fetchInitialProfileData,
        fetchInitialProjectsData,
        getAllProjectsEditableData,
        setMode,
      }}
    >
      {children}
    </CustomFieldsContext.Provider>
  );
};

export default CustomFieldsState;
