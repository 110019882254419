export const CUSTOMERS_FIELDS = {
  ACCOUNT_NAME: 'accountName',
  DETAILS_FIELDS: 'detailsFields',
  CONTACT_FIELDS: 'contactFields',
};

export const CONTACTS_FIXED_FIELDS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  LAST_ACTIVITY: 'LastActivity',
  EMAIL: 'email',
  PHONE: 'phone',
  PHONENUMBER: 'phoneNumber',
};

export const CONTACT_LABEL = {
  ADD: 'Add Contact',
  MOREINFO: 'More Info',
  INVITE_NEW: 'Invite New Project Member',
  NAME: 'Name',
  EMAIL_PHONE: 'Email and Phone',
  POSITION: 'Position',
};

export const UNTITLED_PROJECT = 'Untitled Project';
export const UNNAMED_ACCOUNT = 'Unnamed Account';
