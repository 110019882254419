import React, { useState, useCallback, useMemo } from 'react';
import { useField, ErrorMessage } from 'formik';
import Select from 'react-select';
import { ErrorMessageContainer } from 'views/CustomFields/Account/Styles';
import useUpdateEffect from 'helpers/hooks/useUpdateEffect';
import { FieldLabel } from 'components/Modals/Customers/Styles';

const Dropdown = ({ name, options, disabled, fieldLabel, isRequired, dropdownProps }) => {
  const [, meta, helpers] = useField(name);

  const [value, setValue] = useState(meta.value || meta.initialValue);

  useUpdateEffect(() => {
    setValue(meta.value);
  }, [meta.value]);

  /**
   * On Select Change handler
   * @param args
   * @returns void
   */
  const onSelectChange = useCallback(
    (args) => {
      const selectedOption = { value: args.value, label: args.label, response: args?.response ?? '' };

      setValue(selectedOption);

      helpers.setValue(selectedOption);
      helpers.setTouched(true);

      if (dropdownProps?.handleChange) dropdownProps.handleChange(selectedOption);
    },
    [dropdownProps, helpers]
  );

  /**
   * On Select Focus handler
   * @returns void
   */
  const onSelectFocus = useCallback(() => {
    helpers.setTouched(true);

    if (dropdownProps?.handleFocus) dropdownProps.handleFocus();
  }, [dropdownProps, helpers]);

  const hasError = useMemo(() => meta.touched && !!meta.error, [meta.error, meta.touched]);

  return (
    <>
      {fieldLabel && (
        <FieldLabel>
          <span>{fieldLabel}</span>
          {isRequired && !hasError && <span>*</span>}
          {hasError && (
            <ErrorMessageContainer className="error-container">
              (<ErrorMessage name={name} />)
            </ErrorMessageContainer>
          )}
        </FieldLabel>
      )}

      <Select
        value={value}
        options={options}
        onChange={onSelectChange}
        onFocus={onSelectFocus}
        isDisabled={disabled}
        {...dropdownProps}
      />
    </>
  );
};

export default React.memo(Dropdown);
