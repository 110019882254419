import LoadingSpinner from 'components/Loading/LoadingSpinner';
import FullScreenWithBackground from 'components/FullScreenWithBackground';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { LOADING_TITLES } from 'constants';
import { CompletedIcon } from './styles';
import { Button } from 'react-bootstrap';

const SigningUp = ({ status, sendEmail }) => {
  const onScheduleTime = () => {
    window.open('https://calendly.com/d/gsp-kkq-2pj/upgrade-to-stagebase-team?month=2022-11', '_blank');
  };

  const renderChildComponent = () => {
    switch (status.title) {
      case LOADING_TITLES.COMPLETED.title:
        return <CompletedIcon className="fa-xl" icon={faCheckCircle} />;
      case LOADING_TITLES.IN_PROGRESS.title:
        return <LoadingSpinner white className="text-primary fs-4" />;
      case LOADING_TITLES.DUPLICATE.title:
        return (
          // Temporary solution while the sendEmail endpoint is not yet addressed
          // <Button onClick={sendEmail}>
          //   <FontAwesomeIcon icon={faEnvelope} className="mr-1" /> <span>Help</span>
          // </Button>
          <Button onClick={onScheduleTime}>Schedule for support</Button>
        );
      case LOADING_TITLES.PROHIBITED.title:
        return (
          <div className="pt-3">
            <Button onClick={onScheduleTime}>Schedule Time</Button>
            <div className="mt-3">
              Already have an account?{' '}
              <a href="/login" className="font-weight-bold ml-1">
                Sign In
              </a>
            </div>
          </div>
        );
      default:
        <></>;
    }
  };

  return (
    <FullScreenWithBackground titleClassName="fs-5 mt-5 pt-3 font-weight-bold" title={status.title}>
      <div className="text-center">
        <div className="mt-1 mb-3">{status.subTitle}</div>
        {renderChildComponent()}
      </div>
    </FullScreenWithBackground>
  );
};

export default SigningUp;
