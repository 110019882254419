import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEnvelope, faFile, faTasks, faUserFriends } from '@fortawesome/pro-regular-svg-icons';
import { faClock, faPencil, faTrash, faEllipsisH, faClone } from '@fortawesome/pro-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';
import { useLocation } from 'react-router-dom';
import * as CommonHelper from 'helpers/CommonHelper';
import * as DateHelper from 'helpers/DateHelper';
import { Avatar } from '../Modals/Avatar';
import ModalConfirm from 'components/Modals/Confirm';
import Tags from '../Tags';
import { IconWrapper } from './Styles';
import { endOfYesterday } from 'date-fns';
import FullContext from 'stores/Full/fullContext';
import { CustomerCanvas as CustomerCanvasModel, Dashboard as DashboardModel } from 'models';
import { reminderModalContent, stepModalContent } from './Fixtures';
import { COMPLETED, TASK_ITEM, HOVERED } from 'constants';

const Card = ({
  item,
  connected,
  onDelete,
  onDuplicate,
  index,
  onEdit,
  showIcon,
  reloadCanvas,
  assigningToMember,
}) => {
  const { isEditMode, isExternal, setReminderState, setCanvasState } = useContext(FullContext);
  const location = useLocation();
  const isInDashboard = location.pathname.endsWith('dashboard');
  const date = item.displayDate && DateHelper.getDateFromServerString(item.displayDate);
  const dateOptions = { month: 'short', day: 'numeric' };
  const formattedDate = date && date.toLocaleString('en-US', dateOptions).toUpperCase();
  const [iconInfo, setIconInfo] = useState(CommonHelper.getIconInfo(item, !showIcon ? 'none' : ''));
  const isComplete = CommonHelper.isComplete(item);
  const isInternal = !isExternal;
  const isScheduledYesterday =
    item.dateToBeScheduled && item.dateIsScheduled && item.scheduledDate <= endOfYesterday();
  const isUnscheduledScheduledData = item.dateToBeScheduled && (!item.dateIsScheduled || !item.scheduledDate);
  const isIncompletePastDue = item.pastDue && !isComplete;
  const shouldShowOrange =
    !isComplete &&
    ((item.pastDue && item.dateToBeScheduled && !item.dateIsScheduled) ||
      (item.pastDue && !item.noDueDate && !item.dateToBeScheduled) ||
      isScheduledYesterday);
  const shouldShowPurple =
    isInternal && !isIncompletePastDue && item.dateToBeScheduled && !item.dateIsScheduled && !isComplete;
  const tbdText = `TBD${isInternal ? ` - ${formattedDate}` : ''}`;
  const isTaskItem = item.calendarItemType === TASK_ITEM;
  let headerText = item.headerText || iconInfo.statusTitle;
  let canClick = isInternal || item.isMember;
  let CardContentComponent = item.onSelect || !canClick ? 'div' : Link;
  const onSelect = item.onSelect && (() => item.onSelect(item));
  const [isCompleted, setIsCompleted] = useState(item.customerTouchpointStatus === COMPLETED);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const {
    message: modalMessage,
    title: modalTitle,
    confirmText: modalConfirmText,
  } = isTaskItem ? reminderModalContent() : stepModalContent(item.title);

  useEffect(() => {
    setIconInfo(CommonHelper.getIconInfo(item));
  }, [item]);

  useEffect(() => {
    if (item.customerTouchpointStatus === COMPLETED) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  }, [item.customerTouchpointStatus]);

  /**
   * Handler for hovering to a step's icon that aren't tagged as complete
   * @param {String} statusTitle - used to retain title while hovering
   * @returns {void}
   */
  const iconHoverHandler = (statusTitle) => {
    const hoverItem = {
      statusTitle,
      customerTouchpointStatus: HOVERED,
    };
    const hoverIcon = CommonHelper.getIconInfo(hoverItem);
    setIconInfo(hoverIcon);
  };

  /**
   * Handler for unhovering to a step's icon that aren't tagged as complete
   * @returns {void}
   */
  const iconUnhoverHandler = () => {
    const icon = CommonHelper.getIconInfo(item, !showIcon ? 'none' : '');
    setIconInfo(icon);
  };
  /**
   * Handler responsible for completing the step by clicking the icon
   * @returns {void}
   */
  const quickCompleteHandler = async () => {
    if (isTaskItem) {
      await DashboardModel.updateTaskComplete(item.id, true);
      setReminderState(true);
    } else {
      await CustomerCanvasModel.setCustomerTouchpointStatus(COMPLETED, item.id);
      setCanvasState(true);
    }

    const completedItem = {
      customerTouchpointStatus: COMPLETED,
    };

    const getCompleteIcon = CommonHelper.getIconInfo(completedItem, !showIcon ? 'none' : '');
    setIconInfo(getCompleteIcon);
    setIsCompleted(true);
    setShowCompleteModal(false);
    reloadCanvas();
  };

  // Workaround to trigger preventDefault event when clicking toggle
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="cursor-pointer"
    >
      {children}
    </div>
  ));

  // Workaround to remove anchor tag nested warning
  const CustomItem = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      role="link"
      className="dropdown-item"
    >
      {children}
    </div>
  ));

  const formattedMembers = CommonHelper.getFormattedUsersList(item?.members);

  const displayCardDetails = () => (
    <>
      {!assigningToMember && (
        <div
          onMouseOver={() => !isCompleted && iconHoverHandler(item.customerTouchpointStatus)}
          onMouseOut={() => !isCompleted && iconUnhoverHandler()}
          onClick={() => {
            !isCompleted && setShowCompleteModal(true);
          }}
        >
          {iconInfo.icon}
        </div>
      )}

      <CardContentComponent
        data-testid={item.title}
        to={item.link}
        onClick={onSelect}
        className={`card-content card-width position-relative ${isCompleted ? 'complete' : ''} ${
          canClick ? '' : 'no-click'
        }`}
      >
        {item.onSelect && (
          <div
            className={
              'mr-0 circle-icon check cursor-pointer position-absolute large step-toggle' +
              (item.selectedModel.assigned ? ' selected' : '')
            }
          >
            {item.selectedModel.assigned && <FontAwesomeIcon icon={faCheck} />}
          </div>
        )}

        <div className="header">
          <div className="small-text">{headerText}</div>
          {!isEditMode && date ? (
            <IconWrapper
              className="ml-2 date"
              noDueDate={item.noDueDate}
              shouldShowOrange={shouldShowOrange}
              shouldShowPurple={shouldShowPurple}
            >
              {shouldShowOrange && <FontAwesomeIcon icon={faClock} className="mr-1" />}
              {isUnscheduledScheduledData && !isComplete ? tbdText : formattedDate}
            </IconWrapper>
          ) : !isCompleted ? (
            <Dropdown className="canvas-overflow order-md-1">
              <Dropdown.Toggle as={CustomToggle}>
                <FontAwesomeIcon icon={faEllipsisH} style={{ marginLeft: '20px' }} />
              </Dropdown.Toggle>
              <Dropdown.Menu align="left" className="visible">
                <Dropdown.Item as={CustomItem} onClick={onDuplicate}>
                  <FontAwesomeIcon fixedWidth icon={faClone} className="icon-implement-iron" />
                  Duplicate Step
                </Dropdown.Item>
                <Dropdown.Item as={CustomItem} onClick={onDelete}>
                  <FontAwesomeIcon fixedWidth icon={faTrash} />
                  Delete Step
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : null}
        </div>

        {isInDashboard && item.projectedDate > item.displayDate && !item.pastDue && (
          <div className="d-flex justify-content-end align-items-center date past-due large-text project-late">
            <FontAwesomeIcon icon={faClock} className="mr-1" />
            Project late
          </div>
        )}
        <div className="card-title">
          <div className="large-text">{item.title}</div>
          {isTaskItem && (
            <FontAwesomeIcon
              icon={faPencil}
              spin={item.deleting}
              data-testid={`${item.title}-edit`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (onEdit) onEdit(item);
              }}
            />
          )}
        </div>

        {!isInDashboard && item.touchpointTags && item.touchpointTags.length > 0 && (
          <Tags tags={item.touchpointTags} />
        )}

        <div className="d-flex align-items-end justify-content-between">
          <div className="d-flex flex-wrap items-gap mr-2">
            {formattedMembers?.map((member) => (
              <Avatar
                small
                user={member}
                key={member.id}
                backgroundColor={
                  member.role === 'EXTERNAL' ? 'var(--implement-iron)' : 'var(--action-anchor)'
                }
              />
            ))}
          </div>
          <div className="d-flex items-gap card-icons">
            {item?.customerFacing && (
              <FontAwesomeIcon
                icon={faUserFriends}
                className="fa-xs"
                style={{ marginTop: '2px' }}
                title="Customer facing"
              />
            )}

            {item?.tasks && item?.tasks.length !== 0 && (
              <FontAwesomeIcon icon={faTasks} title="Checklist items" />
            )}

            {item?.tasks &&
              (item?.touchpointAttachments.length !== 0 || item?.touchpointLinks.length !== 0) && (
                <FontAwesomeIcon icon={faFile} title="Uploaded assets" />
              )}
            {item?.emailTrigger && <FontAwesomeIcon icon={faEnvelope} title="Triggers action" />}
          </div>
        </div>
      </CardContentComponent>
    </>
  );

  if (isEditMode)
    return (
      <Draggable key={`touchpoint-${item.id}`} draggableId={`${item.id}`} index={index}>
        {(provided) => (
          <div
            className={`card-wrapper ${connected ? 'connected' : ''}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {displayCardDetails()}
          </div>
        )}
      </Draggable>
    );

  return (
    <div className={`card-wrapper ${connected ? 'connected' : ''}`}>
      <ModalConfirm
        variant="primary"
        autoButtonsWidth={!isTaskItem}
        show={showCompleteModal}
        message={modalMessage}
        title={modalTitle}
        onHide={() => setShowCompleteModal(false)}
        onConfirm={quickCompleteHandler}
        confirmText={modalConfirmText}
      />
      {displayCardDetails()}
    </div>
  );
};

export default Card;
