import { faCheck, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import checkedIcon from 'img/checked.svg';
import unCheckedIcon from 'img/unchecked.svg';
import { CheckboxImage } from './Checkbox/Checkbox';

export default function CircleCheck({
  selected,
  complete,
  onClick,
  large,
  className,
  usePlusIcon,
  checkboxIcon = false,
  circleIcon = true,
}) {
  return (
    <div
      className={
        'check cursor-pointer' +
        (circleIcon ? ' circle-icon' : '') +
        (selected && !checkboxIcon ? ' selected' : '') +
        (complete ? ' complete' : '') +
        (large ? ' large' : '') +
        (className ? ' ' + className : '')
      }
      onClick={onClick}
    >
      {checkboxIcon && (
        <>
          {selected ? (
            <span>
              <CheckboxImage src={checkedIcon} alt="isChecked" />
            </span>
          ) : (
            <span>
              <CheckboxImage src={unCheckedIcon} alt="isUnchecked" />
            </span>
          )}
        </>
      )}

      {!checkboxIcon && <FontAwesomeIcon icon={selected || complete || !usePlusIcon ? faCheck : faPlus} />}
    </div>
  );
}
