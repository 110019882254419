import * as Api from '../api/Api';

/**
 * Fetch all automation rules
 * @returns {Promise}
 */
export const fetchAutomationRules = () => Api.get('Automation/Rules');

/**
 * Creates an automation rule
 * @param {object} payload
 * @returns {Promise}
 */
export const createAutomationRule = (payload) => Api.post('Automation/CreateRule', payload);

/**
 * Updates an automation rule
 * @param {number} ruleId
 * @param {object} payload
 * @returns {Promise}
 */
export const updateAutomationRule = (ruleId, payload) =>
  Api.post(`Automation/UpdateRule?id=${ruleId}`, payload);

/**
 * Deletes automation rule
 * @param {number} ruleId
 * @returns {Promise}
 */
export const deleteAutomationRule = (ruleId) => Api.deleteRequest(`Automation/DeleteRule?id=${ruleId}`);

/**
 *
 * @param {number} ruleId
 * @param {boolean} isActive
 * @returns {Promise}
 */
export const updateAutomationRuleStatus = (ruleId, isActive) =>
  Api.put(`Automation/UpdateStatus?id=${ruleId}&isActive=${isActive}`);

/**
 * Fetch single automation rule by id
 * @param {number} ruleId
 * @returns {Promise}
 */
export const fetchAutomationRuleDetail = (ruleId) => Api.get(`Automation/RuleById`, { id: ruleId });

/**
 * Fetches rule activity log
 * @param {number} ruleId
 * @returns {Promise}
 */
export const fetchAutomationRuleActivityLog = (id) => Api.get(`Automation/ActivitiesByRule`, { id });

/**
 * Fetches available fields for use in rule definitions
 * @param {string} sourceCategory
 * @returns {Promise}
 */
export const fetchLeftOperandsByCategory = (sourceCategory) =>
  Api.get('Automation/Fields', { sourceCategory });

/**
 * Reorders the rule to a new position
 * @param {number} id
 * @param {number} orderNo
 * @returns {Promise}
 */
export const reorderRule = (id, orderNo) => Api.put(`Automation/UpdateOrder?id=${id}&orderNo=${orderNo}`);
