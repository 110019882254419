import * as Api from 'api/Api';

class Full {
  constructor(props) {
    if (props) Object.assign(this, props);
  }

  static async getUserData() {
    const response = await Api.get('Account/UserData');
    return response;
  }

  /**
   * Extracts the access token from the cookie document
   * @returns the access token embedded in the cookie
   */
  static async verifyCookie() {
    const { authToken: accessToken } = await Api.get('Authentication/GetToken');
    return accessToken;
  }
}

export default Full;
