import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import CardColumn from './CardColumn';
import { ColumnsWrapper } from './Styles';

const CanvasCardColumns = ({ canvasState, canvasHandlers, onEdit, reloadCanvas }) => {
  const [dragging, setDragging] = useState(false);
  const [draggedStep, setDraggedStep] = useState(null);

  const [reorderedJourneys, setReorderedJourneys] = useState([]);
  const [flattenedSteps, setFlattenedSteps] = useState([]);

  const { columns, mergedSteps } = canvasState;

  let onReorderTouchpoints;
  if (canvasHandlers?.onReorderTouchpoints) ({ onReorderTouchpoints } = canvasHandlers);

  useEffect(() => {
    setReorderedJourneys(columns);
    setFlattenedSteps(mergedSteps);
  }, [columns, mergedSteps]);

  const reorderSameItems = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);

    return list;
  };

  const reorderAnotherItems = (list, startIndex, itemToInsert) => {
    list.splice(startIndex, 0, itemToInsert);
    return list;
  };

  const findStepOrder = (journey, destination) => {
    if (destination.index === 0) return 0;
    if (destination.index === journey.items.length - 1) return destination.index + 1;

    return destination.index;
  };

  const handleReorder = (data, destinationId, items) => {
    const { reorderType, result, dataToInsert } = items;
    const { destination, source } = result;

    const reorderedData = data.map((journey) => {
      if (journey.id.toString() === destinationId.toString()) {
        return {
          ...journey,
          items:
            reorderType === 'same'
              ? reorderSameItems(journey.items, source.index, destination.index)
              : reorderAnotherItems(journey.items, destination.index, dataToInsert),
        };
      }

      return { ...journey };
    });

    setReorderedJourneys(reorderedData);

    const journey = reorderedData.find((journey) => journey.id.toString() === destinationId.toString());

    return findStepOrder(journey, destination);
  };

  const onDragStart = (initial) => {
    const { draggableId } = initial;

    setDragging(true);
    setDraggedStep(draggableId);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    setTimeout(() => {
      setDragging(false);
      setDraggedStep(null);
    }, 100);

    const destinationId = destination?.droppableId.split('-')[1];
    const sourceId = source?.droppableId.split('-')[1];

    if (!destination) return;

    // If the dragged step will be placed in a different journey
    if (sourceId !== destinationId) {
      const filteredData = reorderedJourneys.map((journey) => ({
        ...journey,
        items: journey.items.filter((item) => item?.id.toString() !== draggableId),
      }));

      const dataToInsert = flattenedSteps.find((item) => item.id.toString() === draggableId);
      const items = { reorderType: 'different', result, dataToInsert };
      const order = handleReorder(filteredData, destinationId, items);

      onReorderTouchpoints(draggableId, order, destinationId);
      return;
    }

    if (destination.index === source.index) return;
    // If the dragged step will be placed in the same journey
    const items = { reorderType: 'same', result, dataToInsert: null };
    const order = handleReorder(reorderedJourneys, destinationId, items);

    onReorderTouchpoints(draggableId, order);
  };

  return (
    <ColumnsWrapper className="columns-wrapper">
      <div className={'columns pt-1' + (dragging ? ' dragging' : '')}>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          {reorderedJourneys
            .filter((value) => value)
            .map((item, index) => {
              const cardState = {
                id: item.id,
                icons: item.icons,
                title: item.title,
                emptyString: item.emptyString ? item.emptyString : '',
                items: item.items,
                draggedStep,
              };

              return (
                <CardColumn
                  key={index}
                  cardState={cardState}
                  canvasState={canvasState}
                  canvasHandlers={canvasHandlers}
                  onEdit={onEdit}
                  reloadCanvas={reloadCanvas}
                />
              );
            })}
        </DragDropContext>
      </div>
    </ColumnsWrapper>
  );
};

export default CanvasCardColumns;
