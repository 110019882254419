import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Mention, MentionsInput } from 'react-mentions';
import * as Api from '../../../api/Api';
import List from '../../List';
import LoadingInline from '../../Loading/LoadingInline';
import PagingMoreLess from '../../PagingMoreLess';
import toast from '../../toast';
import ModalConfirm from '../Confirm/index';
import { ADMIN, CONTRIBUTOR, COMMENT_PLACEHOLDER } from 'constants'
import FullContext from 'stores/Full/fullContext';

export default function InfiniteList({
  customerTouchpoint,
  defaultPageSize,
  isComments,
  Item,
  mentionUsers,
  refreshToggle,
}) {
  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [mentions, setMentions] = useState([]);
  const [text, setText] = useState('');
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [toDeleteItem, setToDeleteItem] = useState(null);
  const textInput = useRef(null);
  const didDeleteItem = useRef(false);
  const loaded = useRef(false);
  const page = useRef(1);
  const pageSize = useRef(defaultPageSize);
  const itemName = isComments ? 'Comment' : 'Note';
  const { userData, roleAtleast } = useContext(FullContext);

  useEffect(() => {
    // refocus in comment box after commenting, but not on first load
    if (!loaded.current) {
      loaded.current = true;
    } else if (!creating) {
      textInput.current?.focus();
    }
  }, [creating]);

  const load = useCallback(async () => {
    if (didDeleteItem.current) {
      // this fixes the problem of skipped items when loading the next page after deleting an item
      pageSize.current = pageSize.current * page.current;
      page.current = 1;
      didDeleteItem.current = false;
    }
    const response = await Api.get(isComments ? 'Customer/GetComments' : 'Customer/GetNotesAndActivities', {
      customerTouchpointId: customerTouchpoint?.id,
      page: page.current,
      size: pageSize.current,
    });
    if (page.current === 1) {
      setItems(response.items);
    } else {
      setItems((c) => c.concat(response.items));
    }
    setTotalItems(response.totalItems);
    setCreating(false);
  }, [customerTouchpoint?.id, isComments]);

  const reset = useCallback(() => {
    page.current = 1;
    pageSize.current = defaultPageSize;
    load();
  }, [defaultPageSize, load]);

  useEffect(() => {
    reset();
  }, [refreshToggle, reset]);

  function loadMore() {
    page.current = page.current + 1;
    load();
  }

  function handleTextChange(event, mentions) {
    setText(event.target.value);
    if (mentions) {
      setMentions(mentions);
    }
  }

  async function createItem() {
    // get text from textInput.value so the mentions don't show up weird
    const text = textInput.current.value;
    if (!text || text.trim() === '') return;

    setCreating(true);
    await Api.post(
      isComments ? 'Customer/CreateComment' : 'Customer/CreateNote',
      isComments
        ? {
            body: text,
            userIds: mentions.map((x) => x.id),
          }
        : {
            text: text,
          },
      {
        params: {
          customerTouchpointId: customerTouchpoint.id,
        },
      }
    );
    setMentions([]);
    setText('');
    reset();
    toast.saved(`${itemName} created successfully.`);
  }

  function handleDeleteRequested(item) {
    setToDeleteItem(item);
  }

  async function deleteItem() {
    setDeleting(true);
    await Api.post(isComments ? 'Customer/DeleteComment' : 'Customer/DeleteNote', toDeleteItem.id);
    didDeleteItem.current = true;
    setItems(items.filter((item) => item.id !== toDeleteItem.id));
    setTotalItems(totalItems - 1);
    setDeleting(false);
    setToDeleteItem(null);
    toast.saved(`${itemName} deleted successfully.`);
  }

  // useMemo is a performance optimization
  // if we have many activity items then rerendering
  // while typing a new note can be laggy
  const renderedItems = useMemo(() => {
    function startEdit(item) {
      setEditingItem(item);
    }

    function finishEdit() {
      setEditingItem(null);
    }

    var myId = parseInt(userData.id);
    return (
      items.length > 0 && (
        <List>
          {items.map((item) => (
            <Item
              key={'list-item-' + item.id + (item.type || '')}
              customerTouchpointId={customerTouchpoint?.id}
              item={item}
              isEditing={editingItem === item}
              startEdit={startEdit}
              finishEdit={finishEdit}
              handleDelete={handleDeleteRequested}
              canEditAndDelete={myId === item.userId || roleAtleast(ADMIN)}
            />
          ))}
        </List>
      )
    );
  }, [items, customerTouchpoint?.id, editingItem, userData?.id, roleAtleast]);

  return (
    <>
      {isComments ? (
        <MentionsInput
          inputRef={textInput}
          classNames={{
            'mentions': 'mentions-wrapper',
            'mentions__input': 'form-control',
            'mentions__suggestions': 'suggestions',
            'mentions__suggestions__item': 'item',
            'mentions__suggestions__item--focused': 'item-focused',
          }}
          value={text}
          disabled={creating}
          onChange={(event, newValue, newPlainTextValue, mentions) => handleTextChange(event, mentions)}
          placeholder={COMMENT_PLACEHOLDER}
          id="comment-text-area"
        >
          <Mention
            trigger="@"
            data={mentionUsers.map((x) => {
              // return { id: x.id, display: x.directMessageName ? x.directMessageName : x.firstName };
              return { id: x.id, display: `${x.firstName} ${x.lastName}` };
            })}
            displayTransform={(id, display) => {
              return '@' + display;
            }}
            allowSpaceInQuery={true}
            allowSuggestionsAboveCursor={true}
          />
        </MentionsInput>
      ) : (
        roleAtleast(CONTRIBUTOR) && (
          <Form.Control
            as="textarea"
            className="mb-2-5"
            rows="3"
            value={text}
            placeholder="Add internal note..."
            disabled={creating}
            ref={textInput}
            onChange={handleTextChange}
            // onKeyPress={(e) => {
            //   if (e.key === 'Enter') {
            //     handleCreateNote(e);
            //   }
            // }}
          />
        )
      )}

      {(isComments || roleAtleast(CONTRIBUTOR)) && (
        <div className="text-right mb-4">
          <Button disabled={!text.trim() || creating} variant="primary" onClick={createItem}>
            {creating ? (
              <LoadingInline white text={mentions.length === 0 ? 'Saving...' : 'Sending...'} />
            ) : mentions.length === 0 ? (
              isComments ? (
                'Save comment'
              ) : (
                'Save'
              )
            ) : (
              'Send'
            )}
          </Button>
        </div>
      )}

      {renderedItems}

      <PagingMoreLess
        items={items}
        totalItems={totalItems}
        page={page.current}
        loadMore={loadMore}
        showLess={reset}
      />

      <ModalConfirm
        title={`Delete ${itemName.toLocaleLowerCase()}`}
        message={
          <>
            Are you sure you want to delete this {itemName.toLowerCase()}?
            <br />
            This action cannot be undone.
          </>
        }
        show={toDeleteItem !== null}
        onConfirm={deleteItem}
        onHide={() => {
          setToDeleteItem(null);
        }}
        confirmLoading={deleting}
      />
    </>
  );
}
