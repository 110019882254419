import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Loading.scss';

export default function Loading({ showSpinner }) {
  if (showSpinner) {
    return (
      <div className="loading-container">
        <div className="spinner-container">
          <FontAwesomeIcon className="loading-spinner" spin icon={faSpinner} />
        </div>
      </div>
    );
  } else {
    return <div className="loading">Loading</div>;
  }
}
