import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import Flatpickr from 'react-flatpickr';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField, ErrorMessage } from 'formik';
import { ErrorMessageContainer } from 'views/CustomFields/Account/Styles';
import { timeOptions } from './fixtures/options';
import { FlatPickrContainer } from './Styles';
import { FieldLabel } from 'components/Modals/Customers/Styles';

const TimePicker = ({ name, disabled, handleChange, fieldLabel, isRequired, timeProps }) => {
  const [, meta, helpers] = useField(name);

  const [value, setValue] = useState(meta.value || meta.initialValue);

  const options = timeOptions;

  const timeRef = useRef(null);

  useEffect(() => {
    disabled
      ? (timeRef.current.flatpickr._input.disabled = true)
      : (timeRef.current.flatpickr._input.disabled = false);
  }, [disabled]);

  /**
   * On Time Change handler
   * @params dates
   * @returns void
   */
  const onTimeChange = useCallback(
    (dates) => {
      const selectedTime = new Date(dates?.[0]).toISOString();

      setValue(new Date(selectedTime));

      helpers.setError('');
      helpers.setValue(selectedTime);
      helpers.setTouched(true);

      if (handleChange) handleChange(selectedTime);
    },
    [helpers, handleChange]
  );

  const hasError = useMemo(() => meta.touched && !!meta.error, [meta.error, meta.touched]);

  return (
    <>
      {fieldLabel && (
        <FieldLabel>
          <span>{fieldLabel}</span>
          {isRequired && !hasError && <span>*</span>}
          {hasError && (
            <ErrorMessageContainer className="error-container">
              (<ErrorMessage name={name} />)
            </ErrorMessageContainer>
          )}
        </FieldLabel>
      )}

      <FlatPickrContainer>
        <Flatpickr
          ref={timeRef}
          className="form-control"
          value={value}
          options={options}
          onChange={onTimeChange}
          {...timeProps}
        />
        <FontAwesomeIcon icon={faClock} />
      </FlatPickrContainer>
    </>
  );
};

export default React.memo(TimePicker);
