import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Badge, Col, Modal, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SessionStorage, Users } from 'models';
import * as Api from 'api/Api';
import * as CommonHelper from 'helpers/CommonHelper';
import logo from 'img/logo.svg';
import TableRow from '../TableRow';
import HeaderProfile from './HeaderProfile';
import FullContext from 'stores/Full/fullContext';
import { NavContainer, Visible } from './Styles';
import toast from 'components/toast';

class Header extends Component {
  static contextType = FullContext;

  state = {
    appSettings: null,
    organizations: [],
    activeOrganization: null,
    showOrganizationModal: false,
    cachedOrgName: CommonHelper.getOrgName(),
    hasMultipleOrgs: CommonHelper.hasMultipleOrgs(),
  };

  componentDidMount() {
    this.loadOrganizations();
  }

  componentDidUpdate(_prevProps, prevState) {
    const newOrgName = CommonHelper.getOrgName();

    // Listen for changes in the orgName localStorage
    if (newOrgName !== prevState.cachedOrgName) {
      this.setState({ cachedOrgName: newOrgName });
      this.setExternalOrganizationName(newOrgName);
    }
  }

  componentWillUnmount() {
    this.resetDocumentTitle();
  }

  setExternalOrganizationName = (newOrgName) => {
    document.title = newOrgName || 'stagebase';
  };

  resetDocumentTitle = () => {
    document.title = 'stagebase';
  };

  loadOrganizations = async () => {
    const data = await Api.get('User/GetAvailableOrganizations');
    this.context.populateOrganizationData(data);
    this.setState({
      organizations: CommonHelper.getUniqueDataById(data?.items),
      activeOrganization: data?.activeOrganization,
    });
    CommonHelper.setOrgData(data);
    this.setExternalOrganizationName(data?.activeOrganization?.name || this.state.cachedOrgName);
  };

  showOrganizations = () => {
    this.setState({ showOrganizationModal: true });
  };

  onHideOrganizationModel = () => {
    this.setState({ showOrganizationModal: false });
  };

  switchOrganization = async (organization) => {
    this.setState({ showOrganizationModal: false });
    const data = await Api.post('User/SwitchOrganization?organizationId=' + organization?.id);
    // fixes a bug where sometimes org name doesn't update after switching orgs
    localStorage.setItem('org', JSON.stringify(organization));
    const { errorMessage } = await CommonHelper.loadUserData(data.authToken);
    if (errorMessage) {
      alert(errorMessage);
    }

    // Clears session storage when switching between different tenants
    SessionStorage.clear();

    if (!data?.redirect) {
      window.location = '/dashboard';
      return;
    }

    const redirectRoute = `/${data?.redirect}`;
    localStorage.setItem('externalProject', redirectRoute);
    window.location = redirectRoute;
  };

  onResendConfirmation = async () => {
    const { email } = this.context.userData;

    await Users.sendConfirmationEmail(email);

    toast.success('Confirmation email has been resent.');
  };

  render() {
    const canChangeOrgs =
      this.state.organizations && this.state.activeOrganization && this.state.organizations.length > 1;

    const cachedOrgName = this.state.hasMultipleOrgs && this.state.cachedOrgName;

    const { isViewAsCustomer, userData, isExternal } = this.context;

    const { isConfirmed, email } = userData;

    return (
      <NavContainer $isConfirmed={isConfirmed} $isExternal={isExternal}>
        {!isConfirmed && email && !isExternal && (
          <div className="banner">
            <p>
              Please confirm your email address: {email}.{' '}
              <span onClick={this.onResendConfirmation}>Resend email</span>
            </p>
          </div>
        )}

        <Navbar className="border-bottom" expand="lg">
          {!this.context.isExternal && !isViewAsCustomer && (
            <button
              className={
                'navbar-toggler hamburger hamburger--spin' + (this.props.sidebarShowing ? ' is-active' : '')
              }
              type="button"
              onClick={this.props.toggleSidebar}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
                <Visible
                  className="nav-dot"
                  isVisible={this.props.unreadNotificationCount > 0 && !this.props.sidebarShowing}
                >
                  <div></div>
                </Visible>
              </span>
            </button>
          )}
          {/* <Navbar.Toggle onClick={this.mobileSidebarToggle} /> */}
          <Navbar.Brand
            href={this.context.isExternal ? CommonHelper.getExternalProject() || '/404' : '/dashboard'}
            className="ml-auto ml-lg-0"
          >
            <img src={(this.props.appSettings && this.props.appSettings.logo) || logo} alt="Stagebase" />
          </Navbar.Brand>
          {/* <Navbar.Brand href="/dashboard" className="d-lg-none ml-auto">
          <img src={icon} className="icon" />
        </Navbar.Brand> */}
          <div className="ml-auto d-flex align-items-center flex-row">
            <Link to="/notifications" className="nav-link d-none d-lg-flex align-items-center">
              <FontAwesomeIcon icon={faBell} aria-hidden="true" />
              {this.props.unreadNotificationCount > 0 && (
                <Badge data-testid="unreadNotificationCountBadge" pill variant="danger" className="ml-2">
                  {this.props.unreadNotificationCount}
                </Badge>
              )}
            </Link>

            {(canChangeOrgs || cachedOrgName) && (
              <>
                <div
                  className="align-items-center nav-link nav-link-light d-none d-lg-flex cursor-pointer"
                  onClick={canChangeOrgs ? this.showOrganizations : null}
                >
                  {this.state.activeOrganization ? this.state.activeOrganization.name : cachedOrgName}
                  <FontAwesomeIcon icon={faAngleDown} className="ml-2 color-action-anchor" />
                </div>
                <OrganizationModal
                  organizations={this.state.organizations}
                  activeOrganization={this.state.activeOrganization}
                  show={this.state.showOrganizationModal}
                  onSelect={this.switchOrganization}
                  onHide={this.onHideOrganizationModel}
                />
              </>
            )}
            <HeaderProfile
              avatar={this.props.avatar}
              showOrganizations={this.showOrganizations}
              activeOrganization={canChangeOrgs && this.state.activeOrganization}
            />
          </div>
        </Navbar>
      </NavContainer>
    );
  }
}

function OrganizationModal(props) {
  return (
    <Modal show={props.show} onHide={props.onHide} centered scrollable className="modal-small">
      <Modal.Body>
        <h1 className="my-5 text-center">Select company</h1>
        {props.organizations.map((organization, index) => (
          <TableRow
            onClick={() => {
              props.onSelect(organization);
            }}
            key={index}
            className={
              'modal-small' + (organization?.id === props.activeOrganization?.id ? ' font-weight-bold' : '')
            }
            small
          >
            <Col>{organization.name}</Col>
          </TableRow>
        ))}
      </Modal.Body>
    </Modal>
  );
}

export default Header;
