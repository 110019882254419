import * as Yup from 'yup';
import { ERROR_MESSAGES, SIGNUP_ERROR_MESSAGES } from 'constants';
import { invalidEmailDomains } from './fixtures';

export const signUpSchema = Yup.object({
  firstName: Yup.string().required(ERROR_MESSAGES.REQUIRED),
  lastName: Yup.string().required(ERROR_MESSAGES.REQUIRED),
  email: Yup.string().email().required(ERROR_MESSAGES.REQUIRED).test('is-valid-email', SIGNUP_ERROR_MESSAGES.PERSONAL_EMAIL, (value) => {
    const emailDomain = value?.split('@')[1];
    const isValid = invalidEmailDomains.map(invalidEmail => emailDomain.includes(invalidEmail)).every((item) => !item);
    return isValid
  }),
  company: Yup.string().required(ERROR_MESSAGES.REQUIRED),
});
