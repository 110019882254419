import styled from 'styled-components';

export const SectionContainer = styled.section`
  padding-inline: 1rem;
  min-height: 7.813rem;
  position: relative;

  h3 {
    color: var(--playbook-pewter);
  }

  .fa-enabled {
    cursor: pointer;
  }

  .fa-disabled {
    color: var(--goal-gray);
    cursor: not-allowed !important;
  }

  &.preview {
    .grip,
    .section-options {
      display: none;
    }

    .custom-detail-fields {
      padding-left: 0;
    }
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  h3 {
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 19px;
  }

  .section-options {
    margin-left: auto;

    .fa-pen {
      margin-right: 0.875rem;
    }
  }
`;

export const SectionBody = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1.25rem;
  column-gap: 3rem;
  padding: 2rem 0 1.25rem 1rem;

  .react-select__control {
    background-color: var(--light-gray-bg);
  }

  @media only screen and (max-width: 630px) {
    grid-template-columns: 1fr;
    padding-left: 0.5rem;
  }
`;

export const RadioGroupContainer = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export const FlatPickrContainer = styled.div`
  display: flex;
  position: relative;

  & > .flatpickr-wrapper {
    width: 100%;
  }

  .svg-inline--fa {
    position: absolute;
    pointer-events: none;
    top: 0.625rem;
    right: 0.625rem;
    color: var(--schedule-sapphire);
  }

  .input {
    cursor: pointer;
  }
`;

export const DateTimeContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;

  & > div {
    width: 50%;
  }
`;

export const DeleteMessage = styled.p`
  text-align: center;
`;

export const CautionSection = styled.div`
  text-align: left;

  p {
    color: var(--orange-bright);
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }

  ul {
    padding-inline-start: 1.75rem;
  }
`;
