import { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { CUSTOM_FIELD_MODES, ERROR_MESSAGES, SECTION, FIELDS } from 'constants';
import { initialSectionValues } from 'components/Modals/CustomFields/fixtures';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { sectionSchema } from '../validations';
import { useNavigate, useLocation } from 'react-router-dom';

export const useAccountSection = () => {
  const { isLabelExist, populateNewSection, updateSection } = useContext(CustomFieldsContext);

  const [showEditSectionModal, setShowEditSectionModal] = useState(false);

  const location = useLocation();
  const history = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  /**
   * Submit handler for Formik's onSubmit instance
   * @param values
   * @returns void
   */
  const handleSubmit = (values) => {
    const { sectionLabel, sectionMode, editedSectionId, previousSectionLabel } = values;
    const { setFieldError } = formikBag;

    const isEdit = sectionMode === CUSTOM_FIELD_MODES.EDIT;

    const isSectionLabelEdited = isEdit && previousSectionLabel && sectionLabel !== previousSectionLabel;

    const isDuplicateSection =
      (isSectionLabelEdited && isLabelExist(SECTION, sectionLabel)) ||
      (!isEdit && isLabelExist(SECTION, sectionLabel));

    if (isDuplicateSection) {
      setFieldError(FIELDS.SECTION_LABEL, ERROR_MESSAGES.DUPLICATE_SECTION);
      return;
    }

    switch (sectionMode) {
      case CUSTOM_FIELD_MODES.EDIT:
        updateSection(editedSectionId, sectionLabel);
        break;

      case CUSTOM_FIELD_MODES.ADD:
        populateNewSection(sectionLabel);
        break;

      default:
        break;
    }

    handleHide();
  };

  const setSectionURLParam = (sectionId) => {
    if (!queryParams.has(SECTION)) {
      queryParams.set(SECTION, sectionId);
      history({
        search: queryParams.toString(),
      });
    }
  };

  /**
   * Closes the edit section modal and resets the formik instance
   * @param none
   * @returns void
   */
  const handleHide = () => {
    setShowEditSectionModal(false);

    if (queryParams.has(SECTION)) {
      queryParams.delete(SECTION);
      history({
        search: String(queryParams),
      });
    }

    setTimeout(() => {
      formikBag.resetForm();
    }, 500);
  };

  /**
   * Opens the edit section modal
   * @param none
   * @returns void
   */
  const handleShow = () => {
    setShowEditSectionModal(true);
  };

  /**
   * Edit Section Formik Instance
   */
  const formikBag = useFormik({
    initialValues: initialSectionValues,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: sectionSchema,
    onSubmit: handleSubmit,
  });

  return {
    showEditSectionModal,
    handleShow,
    handleHide,
    formikBag,
    setSectionURLParam,
  };
};
