import { useState, useContext, useMemo, useEffect } from 'react';
import { useFormik } from 'formik';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { initialValues, roles } from 'components/Modals/Users/fixtures';
import { DEFAULT, PARSED_FIELDS, DEFAULT_FIELDS, EDIT } from 'constants';
import { camelize } from 'helpers/CommonHelper';
import { addUserSchema } from '../validations';
import { useParser } from 'views/Customers/hooks';
import { Users } from 'models';
import toast from 'components/toast';
import { getFieldType } from 'components/CustomFields/Details/fixtures';

export const useUsers = () => {
  const { profile } = useContext(CustomFieldsContext);
  const { editableFields } = profile;
  const [mode, setMode] = useState(DEFAULT);
  const [showUserModal, setShowUserModal] = useState(false);
  const [users, setUsers] = useState([]);
  const { handleParseRequestProperty, handleParseRequestValues, handleParseResponseValue } = useParser();

  const loadUsers = async () => {
    const response = await Users.getUsers();

    setUsers(response);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const visibleEditableFields = useMemo(
    () => editableFields.filter(({ isHidden }) => !isHidden),
    [editableFields]
  );

  const getUserField = (name) =>
    name === DEFAULT_FIELDS.PHONE ? PARSED_FIELDS.PHONE_NUMBER : camelize(name);

  const defaultFields = useMemo(
    () =>
      visibleEditableFields
        .filter(({ isProtected }) => isProtected)
        .map((field) => {
          return {
            ...field,
            fieldType: getFieldType(field.fieldType),
            formikValue: null,
          };
        }),
    [visibleEditableFields]
  );

  const customFields = useMemo(
    () =>
      visibleEditableFields
        .filter(({ isProtected }) => !isProtected)
        .map((field) => ({
          ...field,
          fieldType: getFieldType(field.fieldType),
          fieldTypeOptions: field?.fieldTypeOptions
            ? field?.fieldTypeOptions.map((value) => JSON.parse(value))
            : [],
          formikValue: null,
        })),
    [visibleEditableFields]
  );

  /**
   * Submit handler for Formik's onSubmit instance
   * endpoint and dynamic values will be properly
   * implemented once submit endpoint will be
   * integrated
   * @param values
   * @returns void
   */
  const handleSubmit = async (values) => {
    const transformedDefaultValues = values.defaultFields.reduce((result, current) => {
      return {
        ...result,
        [`${handleParseRequestProperty(current)}`]: handleParseRequestValues(current, null),
      };
    }, {});

    const transformedCustomValues = values.customFields.reduce((result, current) => {
      return {
        ...result,
        [`${handleParseRequestProperty(current)}`]: handleParseRequestValues(current, null),
      };
    }, {});

    const request = {
      role: values.role.response,
      defaultOwner: values.isDefaultOwner,
      ...transformedDefaultValues,
      ...transformedCustomValues,
    };

    const response = await Users.addUser(request);

    if (response && response.errorMessage) {
      toast.error(response.errorMessage);
    } else {
      toast.saved('User created successfully!');
    }

    await loadUsers();

    handleHide();
  };

  /**
   * Closes the add custom field modal and resets the formik instance
   * @param none
   * @returns void
   */
  const handleHide = async () => {
    setMode(DEFAULT);
    setShowUserModal(false);

    if (mode === EDIT) await loadUsers();

    setTimeout(() => {
      formikBag.resetForm();
    }, 500);
  };

  /**
   * Opens the add custom field modal
   * @param none
   * @returns void
   */
  const handleShow = (mode, user = null) => {
    setMode(mode);
    setShowUserModal(true);

    if (user) {
      const newDefaultFields = visibleEditableFields
        .filter(({ isProtected }) => isProtected)
        .map((field) => {
          const { targetFieldName, fieldType, fieldTypeOptions } = field;

          const newTargetFieldName = getUserField(targetFieldName);
          const newFieldType = getFieldType(fieldType);
          const mappedFieldName = user[newTargetFieldName] ?? user[targetFieldName];
          const newFieldTypeOptions =
            fieldTypeOptions && fieldTypeOptions.length > 0
              ? fieldTypeOptions?.map((value) => JSON.parse(value))
              : [];
          const formikValue = handleParseResponseValue(field, mappedFieldName);

          return {
            ...field,
            fieldType: newFieldType,
            fieldTypeOptions: newFieldTypeOptions,
            targetFieldName: newTargetFieldName,
            formikValue,
          };
        });

      const newCustomFields = visibleEditableFields
        .filter(({ isProtected }) => !isProtected)
        .map((field) => {
          const { targetFieldName, fieldType, fieldTypeOptions } = field;

          const newTargetFieldName = getUserField(targetFieldName);
          const newFieldType = getFieldType(fieldType);
          const newFieldTypeOptions =
            fieldTypeOptions && fieldTypeOptions.length > 0
              ? fieldTypeOptions?.map((value) => JSON.parse(value))
              : [];
          const mappedFieldName = user[newTargetFieldName] ?? user[targetFieldName];
          const formikValue = handleParseResponseValue(field, mappedFieldName);

          return {
            ...field,
            fieldType: newFieldType,
            fieldTypeOptions: newFieldTypeOptions,
            targetFieldName: newTargetFieldName,
            formikValue,
          };
        });

      const userRole = roles.find(({ response }) => response === user.role);

      formikBag.setValues({
        isDefaultOwner: user.defaultOwner,
        role: userRole,
        customFields: newCustomFields,
        defaultFields: newDefaultFields,
        userId: user.id ?? null,
        primaryUserId: user.primaryId ?? null,
        user,
        users: users.map((user) => ({
          label: `${user?.firstName} ${user?.lastName}`,
          value: `${user?.firstName} ${user?.lastName}`,
          id: user.id,
        })),
        assignedUser: null,
        totalAccounts: null,
        removedUser: null,
      });
    }
  };

  /**
   * Add Custom Field Formik Instance
   */
  const formikBag = useFormik({
    initialValues: { ...initialValues, defaultFields, customFields },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: addUserSchema,
    onSubmit: handleSubmit,
  });

  return {
    formikBag,
    showUserModal,
    handleHide,
    handleShow,
    mode,
    users,
    loadUsers,
  };
};
