import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditNote from 'components/EditNote';
import { format } from 'date-fns';
import styled from 'styled-components';
import * as DateHelper from '../../../helpers/DateHelper';

const NoteWrapper = styled.div`
  white-space: pre-wrap;
`;

export default function ActivityItem({
  item,
  customerTouchpointId,
  startEdit,
  finishEdit,
  isEditing,
  handleDelete,
  canEditAndDelete,
}) {
  function startEditing() {
    startEdit(item);
  }

  function formatDate(value) {
    try {
      return format(new Date(value), 'MMM d, yyyy');
    } catch (e) {
      return value;
    }
  }

  function formatItemText(value) {
    const cleanedText = value.replace('color: blue', '');
    const cleanedTextsArr = cleanedText.split(`'`);

    // Same behavior on old API responses
    const isOldVersion = cleanedTextsArr.length !== 5;
    if (isOldVersion) return cleanedText;

    const [text, from, toText, to, end] = cleanedTextsArr;
    const user = text.match(/<.+?>(.+?)<\/.+?>/gm);

    // No due date/With Due Date to scheduled date
    if (!from && !to) return `${user} has marked that this step will be scheduled.`;
    else if (text.includes('scheduled') && (!from || !to))
      return `${user} has ${
        !from ? 'added' : 'removed'
      } a scheduled date of <span style="font-weight: 600">'${formatDate(from || to)}'</span>${end}`;

    const parsedTo = to === 'No due date' ? 'End of Project' : formatDate(to);
    const parsedText = (
      text.includes('scheduled') ? text.replace('from', 'of') : text.replace('EndDate', 'due date')
    ).replace('changed', 'changed the');
    return `${parsedText}'${formatDate(from)}'${toText}'${parsedTo}'${end}`;
  }

  function renderItem(item) {
    if (item.type === 'Note') {
      return (
        <NoteWrapper>
          <span className="font-weight-bold">{item.userName}</span> {item.text}
        </NoteWrapper>
      );
    } else {
      return (
        <div
          className="color-completed"
          dangerouslySetInnerHTML={{ __html: item.text ? formatItemText(item.text) : '' }}
        ></div>
      );
    }
  }

  if (isEditing) {
    return (
      <EditNote key={item.type + item.id} note={item} originalText={item.text} finishEdit={finishEdit} />
    );
  } else {
    const date = DateHelper.getDateFromServerString(item.dateCreated);
    const dateTime = DateHelper.formatDateTime(date);
    return (
      <div
        key={item.type + item.id}
        className="d-flex align-items-center justify-content-between text-break mb-3"
      >
        {renderItem(item)}
        <div
          className={
            'ml-3 d-flex align-items-end align-items-md-center date flex-column flex-md-row' +
            (item.type !== 'Note' ? ' color-completed' : '')
          }
        >
          {dateTime.time}
          <div className="ml-md-1-5">{dateTime.date}</div>
          <div className="ml-md-4 small-info activity-icon-wrapper">
            {canEditAndDelete && item.type === 'Note' && (
              <>
                <FontAwesomeIcon icon={faPencil} className="light mr-3" onClick={startEditing} />
                <FontAwesomeIcon icon={faTrash} onClick={() => handleDelete(item)} />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
