const DateChangeReducer = (state, action) => {
  switch (action.type) {
    case 'update_duration_amount':
      return {
        ...state,
        durationAmount: action.payload,
        noDueDate: false,
        isDateModified: true
      }
    case 'update_duration_amount_open':
      return {
        ...state,
        isDurationAmountOpen: action.payload
      }
    case 'update_duration_option':
      return {
        ...state,
        durationOption: action.payload,
        noDueDate: false,
        isDateModified: true
      }
    case 'update_duration_option_open':
      return {
        ...state,
        isDurationOptionOpen: action.payload
      }
    case 'update_dependency_status':
      return {
        ...state,
        dependencyAdded: action.payload
      }
    case 'update_dependent_step':
      return {
        ...state,
        dependentStep: action.payload,
        noDueDate: false,
        isDateModified: true
      }
    case 'update_dependent_step_open':
      return {
        ...state,
        isDependentStepOpen: action.payload
      }
    case 'update_no_due_date':
      return {
        ...state,
        noDueDate: action.payload,
        scheduledDate: null,
        dateToBeScheduled: false,
        isDateModified: true
      }
    case 'update_is_scheduled':
      return {
        ...state,
        dateToBeScheduled: action.payload,
        scheduledDate: action.payload ? state.scheduledDate : null,
        noDueDate: false,
        isDateModified: true
      }
    case 'update_scheduled_date':
      return {
        ...state,
        scheduledDate: action.payload,
        // If a scheduled date is set, that date will overwrite the calculated date.
        calculatedDate: null,
        noDueDate: false,
        isDateModified: true
      }
    case 'update_calculated_date':
      return {
        ...state,
        calculatedDate: action.payload,
        scheduledDate: state.dateToBeScheduled ? state.scheduledDate : null,
        noDueDate: false,
        isDateModified: true
      }
    case 'update_is_earlier_date':
      return {
        ...state,
        isEarlierDate: action.payload
      }
    case 'update_warning_messages':
      return {
        ...state,
        warnings: [...action.payload]
      }
    case 'update_is_loading':
      return {
        ...state,
        isLoading: action.payload
      }
    default:
      return state
  }
}

export default DateChangeReducer