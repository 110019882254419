import * as Yup from 'yup';
import { selectSchema } from 'helpers/schemaHelper';
import { ERROR_MESSAGES } from 'constants';

export const cardSchema = Yup.object().shape({
  cardName: Yup.string().required(ERROR_MESSAGES.REQUIRED),

  address1: Yup.string().required(ERROR_MESSAGES.REQUIRED),

  address2: Yup.string().nullable(),

  city: Yup.string().required(ERROR_MESSAGES.REQUIRED),

  state: selectSchema.required(ERROR_MESSAGES.REQUIRED),

  postalCode: Yup.number().nullable().required(ERROR_MESSAGES.REQUIRED),
});

export const subscriptionSchema = Yup.object().shape({
  recurring: Yup.object().shape({
    name: Yup.string().required(ERROR_MESSAGES.REQUIRED),

    priceId: Yup.string().required(ERROR_MESSAGES.REQUIRED),
  }),

  selectedPlan: selectSchema.required(ERROR_MESSAGES.REQUIRED),
});
