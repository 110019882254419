import ModalConfirm from 'components/Modals/Confirm';
import React from 'react';
import { DisableSubdomainContainer } from './Styles';

const DisableSubdomain = ({ onHide, onSubmit, show }) => {
  const renderContent = (
    <DisableSubdomainContainer>
      <p>Are you sure you want to disable this subdomain?</p>
      <p>Users will be able to use the app.stagebase.com domain.</p>
    </DisableSubdomainContainer>
  );

  return (
    <ModalConfirm
      title="Disable Subdomain"
      message={renderContent}
      show={show}
      confirmText="Disable"
      cancelText="Cancel"
      onConfirm={onSubmit}
      onHide={onHide}
    />
  );
};

export default DisableSubdomain;
