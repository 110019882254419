import * as Api from 'api/Api';

class SignUp {
  constructor(props) {
    if (props) Object.assign(this, props);
  }

  static async validateForm(queryParameters) {
    const response = await Api.get('SignUp/ValidateFreeAccount', queryParameters);
    return response;
  }

  static async verifyRecaptcha(token) {
    const response = await Api.get('SignUp/VerifyReCaptcha', token);
    return response;
  }

  static async notifyNewTenant(queryParameters) {
    let query = '?';
    const queryParametersList = Object.keys(queryParameters);
    queryParametersList?.forEach((param, index) => {
      query += `${param}=${encodeURIComponent(queryParameters[param])}${
        index !== queryParametersList.length - 1 ? '&' : ''
      }`;
    });
    try {
      const response = await fetch(`${process.env.REACT_APP_INTEGROMAT_WEBHOOK}${query}`);
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  static async createNewTenant(body) {
    try {
      const response = await Api.post('SignUp/CreateFreeAccount', body);
      return response;
    } catch (err) {
      console.error(err);
    }
  }

  static async confirmAccount(email, code) {
    const response = await Api.post('Registration/ConfirmEmail', null, { params: { email, code } });
    return response;
  }
}

export default SignUp;
