import { faLink } from '@fortawesome/pro-regular-svg-icons';
import {
  faFileAlt,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
} from '@fortawesome/pro-solid-svg-icons';
import { useContext, useEffect, useState } from 'react';
import { Button } from './Styles';
import * as Api from '../../../api/Api';
import AddAttachment from './AddAttachment';
import ModalNewLink from './NewLink';
import Resource from './Resource';
import ModalConfirm from '../Confirm/index';
import FullContext from 'stores/Full/fullContext';
import toast from 'components/toast';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';

export default function Files({
  title,
  links,
  attachments,
  touchpoint,
  customerTouchpoint,
  canEdit,
  isDraggable,
  canDelete,
  onUpdated,
  hideFileUpload,
}) {
  const { isViewAsCustomer } = useContext(FullContext);
  const [addLinkModalShowing, setAddLinkModalShowing] = useState(false);
  const [deletingLinks, setDeletingLinks] = useState(new Set());
  const [deletingAttachments, setDeletingAttachments] = useState(new Set());
  const [attachmentToDelete, setAttachmentToDelete] = useState(null);
  const [linkToDelete, setLinkToDelete] = useState(null);
  const isCustomer = !!customerTouchpoint;

  useEffect(() => {
    return () => {
      setAddLinkModalShowing(false);
      setAttachmentToDelete(null);
    };
  }, []);

  let re = new RegExp(/(?:\.([^.]+))?$/);
  function getDocumentInfo(filename) {
    var ext = re.exec(filename)[1];
    if (ext === 'pdf') {
      return { icon: faFilePdf, color: '#FF0000' };
    } else if (ext === 'png' || ext === 'jpg') {
      return { icon: faFileImage, color: '#BA55D3' };
    } else if (ext === 'ppt' || ext === 'pptx') {
      return { icon: faFilePowerpoint, color: '#E06B11' };
    } else if (ext === 'doc' || ext === 'docx') {
      return { icon: faFileWord, color: '#226ED5' };
    } else if (ext === 'xlsx' || ext === 'xls') {
      return { icon: faFileExcel, color: '#087A04' };
    } else {
      return { icon: faFileAlt, className: 'color-icon-light-gray' };
    }
  }

  function showAddLinkModal() {
    if (isViewAsCustomer) {
      toast.error('Please exit customer view to do this.');
      return;
    }

    setAddLinkModalShowing(true);
  }

  function hideAddLinkModal() {
    setAddLinkModalShowing(false);
  }

  function handleNewAttachmentsAdded(newAttachments) {
    onUpdated([...attachments, ...newAttachments], links);
  }

  async function deleteAttachment(attachment) {
    setDeletingAttachments((prev) => new Set(prev.add(attachment)));
    if (isCustomer) {
      await Api.post('Customer/RemoveAttachment', {
        touchpointId: touchpoint.id,
        attachmentId: attachment.attachmentId,
        customerId: customerTouchpoint && customerTouchpoint.customerId,
      });
    } else {
      await Api.post('Touchpoint/RemoveAttachment', {
        touchpointId: touchpoint.id,
        attachmentId: attachment.id,
      });
    }

    setAttachmentToDelete(null);

    setDeletingAttachments((prev) => new Set([...prev].filter((x) => x !== attachment)));

    onUpdated(
      attachments.filter((item) =>
        item.attachment
          ? item.attachment.id !== attachment.id ||
            item.attachment.customerTouchpointId !== attachment.customerTouchpointId ||
            item.attachment.attachmentId !== attachment.attachmentId
          : item.id !== attachment.id ||
            item.customerTouchpointId !== attachment.customerTouchpointId ||
            item.attachmentId !== attachment.attachmentId
      ),
      links
    );
  }

  function handleLinksUpdated(newLinks) {
    // SUPER KLUDGE - This should totaly get redesigned on the frontend and backend
    if (title === 'Resources' && canEdit) {
      // Assume this is editing a touchpoint
      onUpdated(attachments, newLinks);
    } else {
      // If we added a new link, the response from the backend returns with the new full
      // set of links--Resources and Uploads. Drop the items with a non-null value for
      // TouchpointLinkId. Those will have been added to the template and appear in the
      // Resources section. See CustomerTouchpointMapper

      // Assume this is an active project
      onUpdated(
        attachments,
        newLinks.filter((item) => item.touchpointLinkId === null)
      );
    }
  }

  async function deleteLink(link) {
    setDeletingLinks((prev) => new Set(prev.add(link)));
    if (isCustomer) {
      await Api.post('Customer/DeleteLink', null, {
        params: {
          id: link.id,
          customerTouchpointId: link.customerTouchpointId,
          linkId: link.linkId,
        },
      });
    } else {
      await Api.post('Touchpoint/DeleteLink', null, {
        params: { linkId: link.id },
      });
    }

    setLinkToDelete(null);

    setDeletingLinks((prev) => new Set([...prev].filter((x) => x !== link)));

    onUpdated(
      attachments,
      links.filter((item) =>
        item.link
          ? item.link.id !== link.id ||
            item.link.linkId !== link.linkId ||
            item.link.customerTouchpointId !== link.customerTouchpointId
          : item.id !== link.id ||
            item.linkId !== link.linkId ||
            item.customerTouchpointId !== link.customerTouchpointId
      )
    );
  }

  const deleteMessage = () => {
    if (attachmentToDelete) {
      return (
        <span>
          Are you sure you want to delete the{' '}
          <span className="font-weight-bold">{attachmentToDelete?.name}</span> resource?
        </span>
      );
    } else {
      return (
        <span>
          Are you sure you want to delete the <span className="font-weight-bold">{linkToDelete?.name}</span>{' '}
          link?
        </span>
      );
    }
  };
  /**
   * API Call for Updating Canvas Order of an attachment
   * @param {Array} customerAttachments - Payload of the API
   * @returns Promise<void>
   */
  const updateAttachmentOrder = (customerAttachments) => {
    return Api.post('Customer/UpdateCanvasOrderAttachment', [...customerAttachments]);
  };

  /**
   * onDragEnd event handler of DragDropContext
   * @param {Object} result - event object upon the end of dragging an item
   */
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const attachmentsHolder = attachments;
    const sortedAttachment = attachmentsHolder[result.source.index];
    attachmentsHolder.splice(result.source.index, 1);
    attachmentsHolder.splice(result.destination.index, 0, sortedAttachment);
    const orderedAttachement = attachmentsHolder.map((item, index) => {
      item.order = index;
      return item;
    });
    const customerAttachments = orderedAttachement.map((attachment) => {
      return {
        id: attachment.id,
        order: attachment.order,
      };
    });
    updateAttachmentOrder(customerAttachments);
    onUpdated([...attachmentsHolder], links);
  };

  return (
    <>
      <h2 className="settings-section-title mt-4">{title}</h2>
      {isDraggable ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="all-resources" direction="vertical">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {attachments
                  .sort((a, b) => a.order - b.order)
                  .map((item, index) => {
                    const attachment = item.attachment || item;
                    return (
                      <Draggable
                        key={`resource-${attachment.attachmentId ?? attachment.id}`}
                        draggableId={`${attachment.attachmentId ?? attachment.id}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Resource
                              key={item.attachmentId}
                              iconInfo={getDocumentInfo(attachment.name)}
                              name={attachment.name}
                              href={attachment.objectPath}
                              deleting={deletingAttachments.has(attachment)}
                              canDelete={canEdit || canDelete}
                              onDelete={() => setAttachmentToDelete(attachment)}
                              userId={item.userId}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <>
          {attachments
            .sort((a, b) => a.order - b.order)
            .map((item, index) => {
              const attachment = item.attachment || item;
              return (
                <Resource
                  key={item.attachmentId}
                  iconInfo={getDocumentInfo(attachment.name)}
                  name={attachment.name}
                  href={attachment.objectPath}
                  deleting={deletingAttachments.has(attachment)}
                  canDelete={canEdit || canDelete}
                  onDelete={() => setAttachmentToDelete(attachment)}
                  userId={item.userId}
                />
              );
            })}
        </>
      )}
      {links.map((item, index) => {
        const link = item.link || item;
        return (
          <Resource
            key={item.linkId}
            iconInfo={{ icon: faLink, className: 'color-icon-light-gray' }}
            name={link.name}
            href={link.path}
            deleting={deletingLinks.has(link)}
            canDelete={canEdit || canDelete}
            onDelete={() => setLinkToDelete(link)}
          />
        );
      })}
      {canEdit && (
        <>
          {!hideFileUpload && (
            <AddAttachment
              onAdded={handleNewAttachmentsAdded}
              touchpointId={touchpoint.id}
              customerTouchpointId={customerTouchpoint && customerTouchpoint.id}
            />
          )}
          <Button onClick={showAddLinkModal} margin="16px 0 16px 0">
            Add a link
          </Button>
          <ModalNewLink
            show={addLinkModalShowing}
            onHide={hideAddLinkModal}
            touchpointId={touchpoint.id}
            customerTouchpointId={customerTouchpoint && customerTouchpoint.id}
            onLinksUpdated={handleLinksUpdated}
          />
        </>
      )}
      <ModalConfirm
        title={`Delete ${attachmentToDelete !== null ? 'resource' : 'link'}`}
        message={deleteMessage()}
        show={attachmentToDelete !== null || linkToDelete !== null}
        onConfirm={() =>
          attachmentToDelete ? deleteAttachment(attachmentToDelete) : deleteLink(linkToDelete)
        }
        onHide={() => (attachmentToDelete ? setAttachmentToDelete(null) : setLinkToDelete(null))}
      />
    </>
  );
}
