import * as Api from 'api/Api';

class Dashboard {
  constructor(props) {
    if (props) Object.assign(this, props);
  }

  static async updateTaskComplete(taskId, complete) {
    return await Api.post('Customer/UpdateTaskComplete', null, {
      params: { taskId, complete },
    });
  }

  static async getCalendarItems(userItems, startDate, endDate) {
    return await Api.get(`Customer/GetCalendarItems?${userItems}`, {
      startDate,
      endDate,
    });
  }
}

export default Dashboard;
