import React from 'react';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const SaveContainer = styled.div`
  span {
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--playbook-pewter);
  }

  span.saving {
    animation: fadeIn 0.5s;
  }

  span.auto-saved {
    animation: fadeOut 2s normal 0.5s;
  }

  span.error {
    color: var(--danger);
  }
`;

export const StyledAvatar = styled.div`
  background-image: url(${({ url }) => url});
  width: 120px;
  height: 120px;
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 120px;
`;

export const StyledCol = styled(({ maxWidth, ...rest }) => <Col {...rest} />)`
  max-width: ${({ maxWidth }) => maxWidth ?? 'auto'};

  .section-header {
    display: flex;

    a {
      margin-top: 2.55rem;
      margin-left: auto;
    }
  }
`;

export const FieldContainer = styled.div`
  .custom-field {
    &__header {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      & > label {
        margin-bottom: 0;
      }
    }

    &__permission {
      font-size: 0.75rem;
      font-weight: 400;
    }

    &__options {
      display: flex;
      align-items: center;
      margin-left: auto;

      .tooltip-action {
        font-size: 1rem;
        font-weight: bold;
        color: var(--schedule-sapphire);
        margin-right: 0;
      }

      span {
        cursor: pointer;
        text-decoration: underline;
      }

      span,
      svg:not(:last-child) {
        margin-right: 0.875rem;
      }

      p {
        margin: 0;

        span {
          font-weight: bold;
        }
      }
    }

    &__input {
      margin-top: 0.5rem;

      .react-select--is-disabled {
        svg {
          color: var(--outline);
        }
      }

      textarea:disabled,
      input:disabled {
        color: var(--playbook-pewter);
      }

      .error-container {
        display: none;
      }
    }
  }
`;

export const DemarginizedDiv = styled.div`
  .custom-field__input {
    margin-left: 0 !important
  }
`;
