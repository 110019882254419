import * as Api from 'api/Api';

class Billing {
  constructor(props) {
    if (props) Object.assign(this, props);
  }

  static async getAllProducts() {
    return await Api.get('Billing/GetAllProducts');
  }

  static async getCurrentCustomer() {
    return await Api.get('Billing/GetCurrentCustomer');
  }

  static async createSetupIntent(customerId) {
    return await Api.post('Billing/CreateSetupIntent', { customerId });
  }

  static async updateCustomerDetails(values, customerId) {
    const { paymentMethod, address, city, country, state, postalCode, cardName } = values;

    const data = [
      {
        op: 'replace',
        path: 'InvoiceSettings/DefaultPaymentMethodId',
        value: paymentMethod,
      },
      {
        op: 'replace',
        path: 'Address/Line1',
        value: address,
      },
      {
        op: 'replace',
        path: 'Address/City',
        value: city,
      },
      {
        op: 'replace',
        path: 'Address/Country',
        value: country,
      },
      {
        op: 'replace',
        path: 'Address/State',
        value: state,
      },
      {
        op: 'replace',
        path: 'Address/PostalCode',
        value: postalCode,
      },
      {
        op: 'replace',
        path: 'Shipping/Name',
        value: cardName,
      },
      {
        op: 'replace',
        path: 'Shipping/Address/City',
        value: city,
      },
      {
        op: 'replace',
        path: 'Shipping/Address/Line1',
        value: address,
      },
      {
        op: 'replace',
        path: 'Shipping/Address/Country',
        value: country,
      },
      {
        op: 'replace',
        path: 'Shipping/Address/State',
        value: state,
      },
      {
        op: 'replace',
        path: 'Shipping/Address/PostalCode',
        value: postalCode,
      },
    ];

    const response = await Api.patch('Billing/PartialCustomerUpdate', data, { params: { customerId } });

    return response;
  }

  static async updateCardDetails(values, paymentMethodId) {
    const { cardName, address1, city, state, postalCode, expMonth, expYear } = values;

    const data = [
      {
        op: 'replace',
        path: 'card/expMonth',
        value: expMonth.value,
      },
      {
        op: 'replace',
        path: 'card/expYear',
        value: expYear.value,
      },
      {
        op: 'replace',
        path: 'billingDetails/address/city',
        value: city,
      },
      {
        op: 'replace',
        path: 'billingDetails/address/state',
        value: state.response,
      },
      {
        op: 'replace',
        path: 'billingDetails/address/line1',
        value: address1,
      },
      {
        op: 'replace',
        path: 'billingDetails/address/postalCode',
        value: postalCode,
      },
      {
        op: 'replace',
        path: 'billingDetails/name',
        value: cardName,
      },
    ];

    const response = await Api.patch('Billing/PartialPaymentMethodUpdate', data, {
      params: { paymentMethodId },
    });

    return response;
  }

  static async createSubscription(data) {
    return await Api.post('Billing/CreateSubscription', data);
  }

  static async cancelSubscription(subscriptionId) {
    return await Api.post('Billing/CancelSubscription', null, { params: { subscriptionId } });
  }

  static async detachCard(paymentMethodId) {
    return await Api.post('Billing/DetachPaymentMethod', null, { params: { paymentMethodId } });
  }

  static async updateSubscription(subscriptionId, data) {
    return await Api.post('Billing/UpdateSubscription', data, { params: { subscriptionId } });
  }
}

export default Billing;
