import { useState, useEffect, useRef, useContext } from 'react';
import * as Api from '../../api/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TableStyle,
  TableTitleStyle,
  TableWrapperStyle,
  TableDataStyle,
  NoDataTextStyle,
  HeaderMessageBox,
  TableDataConditional,
} from './SharedStyles';
import { sortByLetter, sortByNumber } from './sorting';
import TableHeader from './TableHeader';
import { Link } from 'react-router-dom';
import InfoTextBox from 'components/Modals/Shared/InfoTextBox';
import ReportingContext from './context/reportingContext';
import { faAnglesDown, faAnglesUp } from '@fortawesome/free-solid-svg-icons';
import HeaderFilter from './HeaderFilter';
import styled from 'styled-components';

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--schedule-sapphire);
`;

const VerticalSlash = styled.span`
  color: var(--action-anchor);
`;

export default function TemplatePerformanceTable() {
  const [performanceData, setPerformanceData] = useState([]);
  const [sortedColumn, setSortedColumn] = useState(``);
  const [showTable, setShowTable] = useState(true);
  const [showTooltip, setShowToolTip] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [elRef, setElRef] = useState(null);
  const table = useRef();
  const { activeAccountFilters, activeTimeRangeFilters, startDate, endDate } = useContext(ReportingContext);

  const defaultColumnSort = `Avg  \u00A0|\u00A0  Median Completion Time`; // The /u00A0 is for adding extra whitespaces, as requested in the designs.

  useEffect(() => {
    let accountFilters = '';
    if (activeAccountFilters?.length > 0) {
      activeAccountFilters.forEach((filter) => {
        accountFilters += `&clientTypes=${filter}`;
      });
    }
    const prepareSort = (data) => {
      const sortedData = data.sort(
        (a, b) => b.averageActualCompletionTimeInDays - a.averageActualCompletionTimeInDays
      );
      setPerformanceData(sortedData);
    };
    const getPerformanceData = async () => {
      const res = await Api.get(
        `Report/GetTemplatePerformance?startDate=${startDate}&endDate=${endDate}${accountFilters}`
      );
      prepareSort(res);
    };
    getPerformanceData();
  }, [activeAccountFilters, endDate, startDate]);

  return (
    <TableWrapperStyle>
      <TableTitleStyle onClick={() => setShowTable(!showTable)}>
        <span className="title d-flex">
          <span className="mr-2">
            <StyledIcon icon={showTable ? faAnglesUp : faAnglesDown} className="mr-2" />
            Template Performance
          </span>
        </span>
        {(activeTimeRangeFilters.length > 0 || activeAccountFilters.length > 0) && (
          <HeaderFilter setShowFilters={setShowFilters} showFilters={showFilters} />
        )}
        {activeTimeRangeFilters.length > 0 && (
          <span className="ml-2">
            <InfoTextBox notActive={false} width={'411px'} top={'30px'} left={'-350px'}>
              Filtering by Time Range is based on the start date of projects using this template.
            </InfoTextBox>
          </span>
        )}
      </TableTitleStyle>
      {showTooltip && (
        <HeaderMessageBox
          top={elRef.current.getBoundingClientRect().top}
          left={elRef.current.getBoundingClientRect().left}
          width={411}
        >
          {elRef.current.innerText === 'Dropout Rate' &&
            "Percentage of projects that were not completed before the account was toggled to 'Inactive'."}
        </HeaderMessageBox>
      )}
      {showTable && (
        <TableStyle ref={table}>
          <thead>
            <tr>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByLetter}
                setSortedColumn={setSortedColumn}
              >
                Template
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByNumber}
                setSortedColumn={setSortedColumn}
                sortByDefault={true}
              >
                {defaultColumnSort}
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByNumber}
                setSortedColumn={setSortedColumn}
              >
                Template Completion Time
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByNumber}
                setSortedColumn={setSortedColumn}
                hoverOn={(ref) => {
                  setElRef(ref);
                  setShowToolTip(true);
                }}
                hoverOut={() => {
                  setElRef(null);
                  setShowToolTip(false);
                }}
              >
                Dropout Rate
              </TableHeader>
              <TableHeader
                sortedColumn={sortedColumn}
                table={table}
                sortByFunction={sortByNumber}
                setSortedColumn={setSortedColumn}
              >
                Projects Included
              </TableHeader>
            </tr>
          </thead>
          <tbody>
            {performanceData?.length > 0 ? (
              performanceData.map((item) => (
                <tr key={item?.id}>
                  <TableDataStyle link={true}>
                    <Link to={`/templates/${item?.id}`}>{item?.name}</Link>
                  </TableDataStyle>
                  <TableDataStyle>
                    <TableDataConditional
                      danger={item?.averageActualCompletionTimeInDays > item?.targetCompletionInDays}
                    >
                      {item?.averageActualCompletionTimeInDays}
                    </TableDataConditional>
                    &nbsp;&nbsp;<VerticalSlash>|</VerticalSlash>&nbsp;&nbsp;
                    <TableDataConditional
                      danger={item?.medianActualCompletionTimeInDays > item?.targetCompletionInDays}
                    >
                      {item?.medianActualCompletionTimeInDays}
                    </TableDataConditional>
                    &nbsp;days
                  </TableDataStyle>
                  <TableDataStyle>
                    {item?.targetCompletionInDays} {item?.targetCompletionInDays > 1 ? 'days' : 'day'}
                  </TableDataStyle>
                  <TableDataStyle danger={+item?.dropOutRate > 0}>{item?.dropOutRate}%</TableDataStyle>
                  <TableDataStyle>{item?.customerCanvasCount}</TableDataStyle>
                </tr>
              ))
            ) : (
              <tr>
                <td className="w-full">
                  <NoDataTextStyle>No templates within filters</NoDataTextStyle>
                </td>
              </tr>
            )}
          </tbody>
        </TableStyle>
      )}
    </TableWrapperStyle>
  );
}
