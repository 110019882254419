import { BILLING_PLANS } from 'constants';
import React, { Fragment } from 'react';
import { Button } from 'react-bootstrap';

const Plans = ({ memoizedPlanList, handleUpgrade, defaultPayment, subscriptionDetails }) => {
  return (
    <>
      {memoizedPlanList.length > 0 && (
        <section className="plans">
          <h2>Plans to match your scale</h2>

          <div className={`plan-list ${memoizedPlanList.length === 1 ? 'teams-plan' : ''}`}>
            {memoizedPlanList?.map((plan, index) => {
              const { name, limit, users, info, prices } = plan;
              const isCurrentPlan = subscriptionDetails?.planName === name;

              const getPlanName = () => {
                if (name === BILLING_PLANS.ENTERPRISE) return 'Contact us';
                if (isCurrentPlan) return 'Current plan';

                return 'Upgrade';
              };

              const handleClickPlan = () => {
                if (name === BILLING_PLANS.ENTERPRISE) {
                  window.open('mailto:support@stagebase.com', '_self');
                  return;
                }

                handleUpgrade({ plan: name, paymentMethod: defaultPayment });
              };

              return (
                <div className="plan" key={index}>
                  <h4>{name}</h4>
                  <p>{users}</p>

                  <hr />

                  <p className="limit">
                    <strong>{limit}</strong>
                  </p>

                  {prices &&
                    name !== 'Enterprise' &&
                    prices
                      ?.sort((a, b) => b.recurring?.interval.localeCompare(a.recurring?.interval))
                      ?.map((cost, index) => {
                        const isMonthly = cost?.recurring?.interval === 'month';
                        const monthly = `$${cost?.unitAmount / 100 || 0}`;
                        const yearly = `$${Math.trunc(cost?.unitAmount / 100 / 12) || 0}`;

                        const price = isMonthly ? monthly : yearly;

                        return (
                          <Fragment key={index}>
                            <h4 className="price">
                              {price}{' '}
                              <span className="interval-info">
                                /month billed {isMonthly ? 'monthly' : 'annually'}
                              </span>
                            </h4>
                          </Fragment>
                        );
                      })}

                  <p>{info}</p>

                  <Button onClick={handleClickPlan} variant={isCurrentPlan ? 'secondary' : 'primary'}>
                    {getPlanName()}
                  </Button>
                </div>
              );
            })}
          </div>
        </section>
      )}
    </>
  );
};

export default Plans;
