import React, { useState, useCallback, useMemo } from 'react';
import { useField, ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';
import { ErrorMessageContainer } from 'views/CustomFields/Account/Styles';
import { FieldLabel } from 'components/Modals/Customers/Styles';
import useUpdateEffect from 'helpers/hooks/useUpdateEffect';

const FormSwitch = ({ name, handleChange, disabled, fieldLabel, isRequired, ...props }) => {
  const [, meta, helpers] = useField(name);
  const initialValue = !meta.value ? false : meta.value;

  const [value, setValue] = useState(initialValue);

  useUpdateEffect(() => {
    setValue(meta.value);
  }, [meta.value]);

  /**
   * On Switch Change handler
   * @returns void
   */
  const onSwitchChange = useCallback(() => {
    setValue((prev) => !prev);

    helpers.setError('');
    helpers.setValue(!value);
    helpers.setTouched(true);

    if (handleChange) handleChange(!value);
  }, [helpers, handleChange, value]);

  const hasError = useMemo(() => meta.touched && !!meta.error, [meta.error, meta.touched]);

  return (
    <>
      {fieldLabel && (
        <FieldLabel>
          <span>{fieldLabel}</span>
          {isRequired && !hasError && <span>*</span>}
          {hasError && (
            <ErrorMessageContainer className="error-container">
              (<ErrorMessage name={name} />)
            </ErrorMessageContainer>
          )}
        </FieldLabel>
      )}

      <Form.Check
        type="switch"
        checked={value}
        id={name}
        name={name}
        onChange={onSwitchChange}
        disabled={disabled}
        {...props}
      />
    </>
  );
};

export default React.memo(FormSwitch);
