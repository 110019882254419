import styled from 'styled-components';

export const SelectedCanvasContainer = styled.div`
  grid-column: 1/-1;
  padding-bottom: 1.4rem;
  border-bottom: 1px solid var(--border-gray);
`;

export const DatePickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .date-picker,
  .end-date {
    display: flex;
    align-items: center;

    label {
      margin-bottom: 0;
    }

    .error-message {
      color: var(--danger);
    }
  }
`;
