import { useContext } from 'react';
import Flatpickr from 'react-flatpickr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import dateChangeContext from '../context/dateChangeContext';
import InfoTextBox from '../../InfoTextBox';
import { ScheduleDateWrapper, DatePickerWrapper } from '../styles';
import { Touchpoint } from 'models';
import ScheduledTypeInfo from './ScheduledTypeInfo';
import FullContext from 'stores/Full/fullContext';

const ScheduledTypeDropdown = (
  { touchpoint, dateSelected, handleScheduledDateChange } = { touchpoint: new Touchpoint() }
) => {
  const { activeTouchpoint, dateToBeScheduled, noDueDate, updateIsScheduled, scheduledDate, canvasStartDate } =
    useContext(dateChangeContext);

  const { isEditMode } = useContext(FullContext);
  const isScheduledEditMode = touchpoint?.isScheduledType && isEditMode;

  return (
    <ScheduleDateWrapper
      justifyContent={!touchpoint
        || (isEditMode && !touchpoint?.isScheduledType)
        || isScheduledEditMode
        ? 'start' : 'center'}
      notActive={touchpoint?.isComplete ? false : noDueDate}
      paddingLeft={touchpoint?.isScheduledType && !isEditMode ? '44px' : ''}
    >
      {(!touchpoint || (touchpoint?.isIncomplete && !touchpoint?.isScheduledType)
        || isScheduledEditMode) && (
          <>
            <input
              type="checkbox"
              name="toBeScheduled"
              id="toBeScheduled"
              checked={dateToBeScheduled}
              onChange={() => updateIsScheduled(!dateToBeScheduled)}
            />
            <label htmlFor="toBeScheduled">This step will be scheduled</label>
            <ScheduledTypeInfo noDueDate={noDueDate} activeTouchpoint={activeTouchpoint} />
          </>
        )}
      {touchpoint?.isComplete && <label htmlFor="toBeScheduled">Completed On</label>}
      {(touchpoint?.dateToBeScheduled || touchpoint?.isComplete) && activeTouchpoint && (
        <DatePickerWrapper>
          <Flatpickr
            options={{
              altInput: true,
              static: true,
              mode: 'single',
              disableMobile: false,
              minDate: touchpoint.isComplete
                ? canvasStartDate < touchpoint.dateTimeCompleted
                  ? canvasStartDate
                  : touchpoint.dateTimeCompleted
                : null,
              maxDate: touchpoint.isComplete ? new Date() : null,
            }}
            onChange={handleScheduledDateChange}
            value={
              touchpoint?.isIncomplete &&
                touchpoint?.isScheduledType &&
                touchpoint?.isScheduled &&
                !touchpoint?.scheduledDate &&
                !scheduledDate
                ? null
                : dateSelected
            }
          >
            Choose a date
          </Flatpickr>
          {(!dateSelected ||
            (!scheduledDate &&
              touchpoint?.isScheduledType &&
              touchpoint?.isScheduled &&
              !touchpoint?.scheduledDate)) &&
            touchpoint.isIncomplete && <span>Schedule a date</span>}
          <FontAwesomeIcon icon={faCalendar} />
        </DatePickerWrapper>
      )}
      {touchpoint?.isScheduledType && touchpoint?.isIncomplete && (
        <div className="ml-3">
          <InfoTextBox notActive={noDueDate} top="0" width="415px">
            <p>Until a date is scheduled, the customer will see TBD instead of the due date.</p>
            <p>
              Once a date is scheduled, the customer will see the scheduled date. The due date will not change
              and will continue to be used for Reporting and Target End Date to track if this step and the
              project are completed on time.
            </p>
          </InfoTextBox>
        </div>
      )}
    </ScheduleDateWrapper>
  );
};

export default ScheduledTypeDropdown;
