import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const ChevronArrow = styled(FontAwesomeIcon)`
  color: var(--schedule-sapphire);
  margin-left: 5px;
`;

export const CheckMark = styled(FontAwesomeIcon)`
  color: #008be7;
  position: absolute;
  left: 1.5rem;
  top: ${(props) => props.top};
`;

export const FilterButton = styled.button`
  background-color: transparent;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) =>
    props.filterHolder.myHolder ||
    props.filterHolder.pastDueHolder ||
    props.filterHolder.onTimeHolder ||
    props.filterHolder.typesHolder.length > 0 ||
    props.filterHolder.ownersHolder.length > 0 ||
    props.filterHolder.statusesHolder.length > 0
      ? 'var(--schedule-sapphire)'
      : 'var(--playbook-pewter)'};
  border: none;
  padding: 8px;
  margin: 0;
  width: 100%;
  &:hover {
    background-color: #e9ecef;
  }
  &:focus {
    outline: none !important;
    outline-offset: none !important;
  }
`;

export const DropdownMenu = styled(Dropdown.Menu)`
  border: 0;
  box-shadow: var(--box-shadow-menu);
  margin: -33px 0 0 0;
`;

export const Table = styled.div`
  z-index: ${({ filterShow }) => (filterShow ? '-1' : '')};

  @media only screen and (max-width: 576px) {
    & > .table-row-outer > .table-row > .row {
      flex-direction: column;
    }
  }
`;

export const TableHeader = styled(Row)`
  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

export const NoAccountsMessage = styled.p`
  color: var(--champion-charcoal);
  font-size: 1rem;
  font-weight: 400;
  line-height: 21.82px;
  border-top: 1px solid #ebebeb;
  padding-top: 2rem;
`;

export const OptionsContainer = styled.div`
  .dropdown-menu {
    border: 0;
    box-shadow: var(--box-shadow-menu);
  }

  .dropdown-menu.show {
    transform: translate3d(0px, 26px, 0px) !important;
    inset: 0px 0px auto auto !important;
    margin-top: 0.625rem !important;
  }

  .dropdown-item {
    padding: 0.5rem 1.5rem;
  }
`;
