import { BILLING_PLANS } from 'constants';
import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import { initialCardValues, initialSubscriptionValues, planDetails } from '../fixtures';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Billing as BillingModel, Settings } from 'models';
import { BillingContainer } from './Styles';
import ModalCard from 'components/Modals/Billing/Card';
import ModalSubscription from 'components/Modals/Billing/Subscription';
import ModalSuccess from 'components/Modals/Billing/Success';
import ModalCancel from 'components/Modals/Billing/Cancel';
import { FormikContext, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { cardSchema, subscriptionSchema } from './validations';
import { monthList, stateList, yearList } from 'components/Modals/Billing/Card/fixtures';
import { format } from 'date-fns';
import Overview from './Overview';
import Plans from './Plans';
import LoadingInline from 'components/Loading/LoadingInline';
import { pluralize } from 'helpers/CommonHelper';
import { useLocation } from 'react-router-dom';
import FullContext from 'stores/Full/fullContext';
import * as uuid from 'uuid';

const Billing = ({ loadAppSettings }) => {
  const [showCardModal, setShowCardModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showConfirmSubscriptionModal, setShowConfirmSubscriptionModal] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [defaultPayment, setDefaultPayment] = useState(null);
  const [setupIntent, setSetupIntent] = useState(null);
  const [previousPlan, setPreviousPlan] = useState(null);
  const [latestPlan, setLatestPlan] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [previousTotal, setPreviousTotal] = useState(0);
  const [proratedValue, setProratedValue] = useState(0);
  const [salesTax, setSalesTax] = useState(0);
  const [products, setProducts] = useState([]);

  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();

  const { populateAppSettings } = useContext(FullContext);

  const isBillingTabActive = location.hash === '#billing';

  const handleLoadAppSettings = async () => {
    const appSettings = await Settings.getAppSettings();
    populateAppSettings(appSettings);
  };

  const handleLoadData = useCallback(async () => {
    if (!isBillingTabActive) return;

    setIsDataLoading(true);

    const currentCustomer = await BillingModel.getCurrentCustomer();
    const products = await BillingModel.getAllProducts();
    const paymentMethod = currentCustomer?.invoiceSettings?.defaultPaymentMethod;

    setCustomer(currentCustomer);
    setDefaultPayment(currentCustomer?.invoiceSettings?.defaultPaymentMethod);
    setProducts(products);

    setIsDataLoading(false);

    return {
      paymentMethod,
    };
  }, [isBillingTabActive]);

  useEffect(() => {
    handleLoadData();

    return () => {
      setCustomer(null);
      setDefaultPayment(null);
      setIsDataLoading(false);
      setProducts([]);
    };
  }, [handleLoadData]);

  const formattedProductList = useMemo(() => {
    return products
      ?.sort((a, b) => a.defaultPrice?.unitAmount - b.defaultPrice?.unitAmount)
      ?.map(({ id, defaultPrice, name, defaultPriceId, prices }) => {
        const amount = defaultPrice?.unitAmount / 100;

        return {
          id,
          name,
          defaultPriceId,
          price: `$${amount}`,
          prices,
        };
      });
  }, [products]);

  const tempProducts = useMemo(() => ['Tier 1', 'Tier 2', 'Tier 3', 'Enterprise'], []);

  const memoizedProductList = useMemo(() => {
    return formattedProductList
      ?.map((product) => ({ ...product }))
      .filter((product) => tempProducts?.includes(product.name));
  }, [formattedProductList, tempProducts]);

  const subscriptionDetails = useMemo(() => {
    if (!customer) {
      return {
        id: null,
        planName: BILLING_PLANS.FREE,
        seats: null,
        amount: 0,
        activePlanPrice: 0,
        priceId: '',
        interval: '',
        nextBillDate: '',
        invoices: [],
        salesTax: 0,
      };
    }

    const subscription =
      customer?.subscriptions && customer?.subscriptions.length > 0 ? customer?.subscriptions[0] : null;
    const currentPeriodEnd = subscription?.currentPeriodEnd;
    const price = subscription?.items[0]?.price;
    const product = price?.product;
    const quantity = subscription?.items[0]?.quantity;
    const amountDue = customer?.upcomingInvoice?.amountDue || 0;
    const flattenedProducts = memoizedProductList?.flatMap((item) => item.prices);
    const foundPrice = flattenedProducts?.find(({ id }) => id === price?.id);
    const salesTax = foundPrice?.salesTax / 100 || 0;

    const invoices = customer?.invoices?.map(({ created, invoicePdf, amountDue, plan, licenses }) => ({
      billedOn: format(new Date(created), 'MMM d, yyyy') || '',
      plan: plan || '',
      seats: +licenses || 1,
      amount: amountDue / 100 || 0,
      pdfLink: invoicePdf,
    }));

    return {
      id: subscription?.id,
      priceId: price?.id,
      interval: price?.recurring?.interval,
      planName: product?.name || BILLING_PLANS.FREE,
      activePlanPrice: price?.unitAmount / 100 || 0,
      seats: quantity || 1,
      amount: amountDue / 100 || 0,
      nextBillDate: currentPeriodEnd ? format(new Date(currentPeriodEnd), 'MMM d, yyyy') : '',
      invoices,
      salesTax,
    };
  }, [customer, memoizedProductList]);

  const memoizedPlanList = useMemo(() => {
    const freeTier = {
      id: uuid.v4(),
      name: BILLING_PLANS.FREE,
    };

    const isFreeTier = subscriptionDetails?.planName === BILLING_PLANS.FREE;

    const _memoizedProductList = isFreeTier
      ? [{ ...freeTier }].concat(memoizedProductList)
      : memoizedProductList;

    const filteredPlanDetails = isFreeTier
      ? planDetails
      : planDetails.filter((item) => item.title !== BILLING_PLANS.FREE);

    const formattedProductList = filteredPlanDetails.map((item, index) =>
      item.title === _memoizedProductList[index]?.name
        ? { ...item, ..._memoizedProductList[index] }
        : { ...item }
    );

    return formattedProductList;
  }, [memoizedProductList, subscriptionDetails?.planName]);

  const handleHideCard = () => {
    setShowCardModal(false);
    cardFormikBag.resetForm();
  };

  const handleHideSubscription = () => {
    setShowSubscriptionModal(false);
    subscriptionFormikBag.resetForm();
  };

  const handleHideConfirmSubscription = () => {
    setShowConfirmSubscriptionModal(false);
    setPreviousPlan(null);
    setLatestPlan(null);
  };

  const handleCancelSubscription = async () => {
    if (!subscriptionDetails.id) return;

    setIsCancelLoading(true);

    await BillingModel.cancelSubscription(subscriptionDetails.id);

    setIsCancelLoading(false);
    setShowCancelModal(false);

    toast.success('Successfully cancelled current subscription');

    await handleLoadData();
  };

  const handlePaymentMethod = async (values) => {
    const { cardName, address1, city, state, postalCode, paymentMethodId, mode, selectedPlan } = values;

    setIsCardLoading(true);
    cardFormikBag.setSubmitting(true);

    const cardElement = elements.getElement(CardElement);

    const { error } = await stripe.confirmCardSetup(setupIntent, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: cardName,
          address: {
            line1: address1,
            state: state.response,
            postal_code: postalCode,
            country: 'US',
            city,
          },
        },
      },
    });

    if (error) {
      toast.error(error.message);
      return;
    }

    if (mode === 'replace') await BillingModel.detachCard(paymentMethodId);

    const {
      error: retrievedIntentError,
      setupIntent: { payment_method },
    } = await stripe.retrieveSetupIntent(setupIntent);

    const request = {
      paymentMethod: payment_method,
      cardName,
      address: address1,
      city,
      country: 'US',
      state: state.response,
      postalCode,
    };

    await BillingModel.updateCustomerDetails(request, customer?.id);

    cardFormikBag.setSubmitting(false);
    setIsCardLoading(false);

    if (retrievedIntentError) return;

    const message = paymentMethodId
      ? 'Successfully replaced current payment method'
      : 'Successfully added a new payment method';

    toast.success(message);

    const { paymentMethod } = await handleLoadData();

    if (paymentMethod && mode === 'add') handleUpgrade({ plan: selectedPlan || '', paymentMethod });

    handleHideCard();
  };

  const handleEditCard = async (values) => {
    const { cardId } = values;

    if (!cardId) return;

    setIsCardLoading(true);
    cardFormikBag.setSubmitting(true);

    const { card, billingDetails } = await BillingModel.updateCardDetails(values, cardId);

    setDefaultPayment((prev) => ({ ...prev, card, billingDetails }));

    setIsCardLoading(false);
    cardFormikBag.setSubmitting(false);

    toast.success('Successfully updated the payment method');

    await handleLoadData();

    handleHideCard();
  };

  const handleSubmitCard = async (values) => {
    const mappedHandlers = {
      add: () => handlePaymentMethod(values),
      edit: () => handleEditCard(values),
      replace: () => handlePaymentMethod(values),
    };

    handleLoadAppSettings();
    mappedHandlers[values.mode]();
  };

  const handleSubmitSubscription = async (values) => {
    const {
      seats,
      selectedPlan,
      totalPrice: _totalPrice,
      previousTotal: _previousTotal,
      recurring,
      coupon,
    } = values;

    const hasNoChanges =
      recurring?.priceId === subscriptionDetails.priceId &&
      selectedPlan.label === subscriptionDetails.planName;

    if (hasNoChanges) {
      toast.error('No changes have been made');
      return;
    }

    if (+seats < +customer?.internalUserCount) {
      const difference = +customer?.internalUserCount - +seats;
      toast.error(`You need to remove ${pluralize(difference, 'user')} before you can make this change`);
      return;
    }

    const data = {
      items: [
        {
          priceId: recurring?.priceId,
          quantity: seats,
        },
      ],
    };

    const isFreeTier = subscriptionDetails?.planName === BILLING_PLANS.FREE;

    if (isFreeTier) {
      const request = coupon
        ? {
            customerId: customer?.id,
            coupon,
            ...data,
          }
        : {
            customerId: customer?.id,
            ...data,
          };

      const response = await BillingModel.createSubscription(request);

      if (response?.errors) {
        const flattenedErrors = Object.values(response?.errors).flatMap((errors) => errors);
        toast.error(flattenedErrors.join(' '));
        return;
      }

      loadAppSettings();
    } else {
      const response = await BillingModel.updateSubscription(subscriptionDetails?.id, {
        ...data,
      });

      if (response?.errors) {
        const flattenedErrors = Object.values(response?.errors).flatMap((errors) => errors);
        toast.error(flattenedErrors.join(' '));
        return;
      }

      const { currentPeriodProratedValue } = response;

      loadAppSettings();
      setProratedValue(currentPeriodProratedValue);
    }

    setPreviousPlan(subscriptionDetails?.planName);
    setLatestPlan(selectedPlan?.label);
    setSalesTax(recurring?.salesTax);
    setTotalPrice(_totalPrice);
    setPreviousTotal(_previousTotal);

    await handleLoadAppSettings();
    await handleLoadData();
    handleHideSubscription();

    setShowConfirmSubscriptionModal(true);
  };

  const cardFormikBag = useFormik({
    initialValues: initialCardValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: cardSchema,
    onSubmit: handleSubmitCard,
  });

  const subscriptionFormikBag = useFormik({
    initialValues: initialSubscriptionValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: subscriptionSchema,
    onSubmit: handleSubmitSubscription,
  });

  const handleShowAddCardModal = async (args) => {
    const { isNewPayment = true, plan } = args;

    if (defaultPayment && isNewPayment) return;

    const { clientSecret } = await BillingModel.createSetupIntent(customer?.id);

    if (!isNewPayment) {
      cardFormikBag.setFieldValue('paymentMethodId', defaultPayment?.id);
      cardFormikBag.setFieldValue('mode', 'replace');
    }

    if (plan) cardFormikBag.setFieldValue('selectedPlan', plan);

    setSetupIntent(clientSecret);
    setShowCardModal(true);
  };

  const planOptions = formattedProductList
    ?.filter(({ name }) => tempProducts?.includes(name))
    ?.map(({ name, defaultPriceId }) => ({
      label: name,
      value: name,
      response: defaultPriceId,
    }));

  const handleUpgrade = (args) => {
    const { plan = null, paymentMethod = null } = args;

    if (!defaultPayment && !paymentMethod) {
      handleShowAddCardModal({ isNewPayment: true, plan });
      return;
    }

    const planName = plan || subscriptionDetails.planName;

    const selectedPlan = planOptions?.find(({ label }) => label === planName) || null;

    const seats = subscriptionDetails.seats || 1;

    const foundPlan = selectedPlan
      ? formattedProductList?.find(({ name }) => selectedPlan?.label === name)
      : null;

    const previousTotal = (foundPlan?.price?.split('$')[1] || 0) * seats;

    const price = subscriptionDetails?.activePlanPrice || 0;

    const recurring =
      selectedPlan && subscriptionDetails?.planName === foundPlan?.name
        ? {
            name: subscriptionDetails?.interval,
            priceId: subscriptionDetails?.priceId,
            planName: subscriptionDetails?.planName,
            monthlyPrice: price,
            salesTax: subscriptionDetails?.salesTax,
          }
        : null;

    subscriptionFormikBag.setValues({
      selectedPlan,
      seats,
      previousTotal,
      recurring,
    });

    setShowSubscriptionModal(true);
  };

  const handleEdit = (values) => {
    const { name, expMonth, expYear, line1, line2, id, city, state, postalCode } = values;

    cardFormikBag.setValues({
      cardName: name,
      expMonth: monthList?.find(({ value }) => expMonth === value),
      expYear: yearList?.find(({ value }) => expYear === value),
      address1: line1,
      address2: line2,
      mode: 'edit',
      cardId: id,
      state: stateList?.find(({ response }) => response === state),
      city,
      postalCode,
    });

    setShowCardModal(true);
  };

  if (isDataLoading)
    return (
      <div className="mt-3">
        <LoadingInline />
      </div>
    );

  return (
    <BillingContainer>
      <Overview
        subscriptionDetails={subscriptionDetails}
        defaultPayment={defaultPayment}
        handleEdit={handleEdit}
        handleUpgrade={handleUpgrade}
        handleShowAddCardModal={handleShowAddCardModal}
        handleCancelSubscription={() => setShowCancelModal(true)}
        isCancelLoading={isCancelLoading}
      />

      <Plans
        handleUpgrade={handleUpgrade}
        memoizedPlanList={memoizedPlanList}
        defaultPayment={defaultPayment}
        subscriptionDetails={subscriptionDetails}
      />

      <FormikContext.Provider value={cardFormikBag}>
        <ModalCard show={showCardModal} onHide={handleHideCard} isCardLoading={isCardLoading} />
      </FormikContext.Provider>

      <FormikContext.Provider value={subscriptionFormikBag}>
        <ModalSubscription
          show={showSubscriptionModal}
          onHide={handleHideSubscription}
          formattedProductList={formattedProductList}
          planOptions={planOptions}
          currentPlan={subscriptionDetails?.planName}
        />
      </FormikContext.Provider>

      <ModalSuccess
        show={showConfirmSubscriptionModal}
        onHide={handleHideConfirmSubscription}
        previousPlan={previousPlan}
        latestPlan={latestPlan}
        totalPrice={totalPrice}
        previousTotal={previousTotal}
        interval={subscriptionDetails?.interval}
        tempProducts={tempProducts}
        salesTax={salesTax}
        periodEnd={
          showConfirmSubscriptionModal && customer?.upcomingInvoice?.periodEnd
            ? format(new Date(customer?.upcomingInvoice?.periodEnd), 'MMM d, yyyy')
            : ''
        }
        proratedValue={proratedValue}
      />

      <ModalCancel
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
        onSubmit={handleCancelSubscription}
        planName={subscriptionDetails?.planName}
        seats={subscriptionDetails?.seats}
        periodEnd={
          showCancelModal && customer?.upcomingInvoice?.periodEnd
            ? format(new Date(customer?.upcomingInvoice?.periodEnd), 'MMM d, yyyy')
            : ''
        }
      />
    </BillingContainer>
  );
};

export default Billing;
