import styled from 'styled-components';
import { Form } from 'react-bootstrap';

export const Item = styled.div`
  max-width: 25rem;
`;

export const FormControl = styled(Form.Control)`
  width: initial;
`;

export const SettingHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 19px;
  color: var(--playbook-pewter);
`;

export const FixedDynamicTimeline = styled.div`
  color: ${({ isEnabled }) => (isEnabled ? 'var(--schedule-sapphire)' : 'var(--playbook-pewter)')};
  margin-right: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
`;

export const DropdownContainer = styled.div`
  &.common-select {
    min-width: 230px;
  }

  &.month-select:not(.is-plural) {
    min-width: 92px;
  }

  &.month-select.is-plural {
    min-width: 100px;
  }

  &.read-only-frontline {
    .select__single-value {
      color: var(--input-enabled);
    }

    .select__control--is-disabled {
      cursor: default;
    }
  }

  .select__control {
    background-color: var(--light-gray-bg);
    border-style: none;

    &--is-disabled {
      svg {
        opacity: 30%;
      }
    }
  }

  div[id$='-heading'] {
    color: var(--action-anchor);
    font-size: 0.688rem;
    font-weight: 700;
    line-height: 15px;
    text-transform: uppercase;
  }

  .select__single-value svg {
    display: none;
  }

  &.day-select {
    .select__value-container {
      padding: 2px 14px 2px 16px;
      overflow: visible;
    }
  }

  .select__menu {
    top: 0;
    left: 0.5rem;
    box-shadow: var(--box-shadow-menu);
    z-index: 2;
  }

  .select__option {
    text-align: left;

    & > div {
      display: flex;
      align-items: center;
    }

    &--is-selected {
      background-color: transparent;
      color: inherit;
    }

    &--is-focused {
      background-color: var(--goal-gray);
    }
  }

  .select__indicator-separator {
    width: unset;
  }

  .select__dropdown-indicator {
    svg {
      color: var(--schedule-sapphire);
    }
  }

  .select__control--is-disabled {
    pointer-events: all;
    cursor: not-allowed;
  }
`;

export const Label = styled.span`
  opacity: ${({ isDisabled }) => (isDisabled ? '30%' : '1')};

  &.read-only-frontline {
    opacity: 1;
  }
`;
