import { useState, useRef, useContext } from 'react';
import { DropDown, DropDownArrow, Wrapper, FilterEntry, CheckMark } from './SharedStyles';
import { faCheck, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import useOutsideClickNotifier from 'helpers/hooks/useOutsideClickNotifier';
import { SessionStorage } from 'models';
import { ACCOUNT_FILTER } from 'constants/sessionStorage';
import ReportingContext from './context/reportingContext';

export default function AccountFilter() {
  const { accountFilters, activeAccountFilters, addActiveAccountFilter, removeActiveAccountFilter } =
    useContext(ReportingContext);
  const [acctFilterOpen, setAcctFilterOpen] = useState(false);
  const clickRef = useRef(null);

  useOutsideClickNotifier(clickRef, () => {
    setAcctFilterOpen(false);
  });

  const toggleFilter = (filter) => {
    const storageValue = SessionStorage.get(ACCOUNT_FILTER);
    const storageArray = storageValue && storageValue !== '' ? storageValue.split(',') : [];
    const filterValue = storageArray.includes(filter);
    if (filterValue) {
      const removeFilter = storageArray.filter((item) => item !== filter);
      if (removeFilter.length > 0) {
        SessionStorage.set(ACCOUNT_FILTER, removeFilter);
      } else {
        SessionStorage.remove(ACCOUNT_FILTER);
      }
      removeActiveAccountFilter(filter);
    } else {
      SessionStorage.set(ACCOUNT_FILTER, [...storageArray, filter]);
      addActiveAccountFilter(filter);
    }
  };

  return (
    <>
      <DropDown
        disabled={accountFilters.length === 0}
        padding="8px 32px 8px 16px"
        width="auto"
        onClick={() => setAcctFilterOpen(!acctFilterOpen)}
        isOpen={acctFilterOpen}
      >
        <span>Account Type ({activeAccountFilters.length})</span>
        <DropDownArrow icon={faChevronDown} size="xs" right="10px" />
      </DropDown>
      {acctFilterOpen && (
        <Wrapper top="70px" ref={clickRef}>
          {accountFilters &&
            accountFilters?.length > 0 &&
            accountFilters.map((filter) => (
              <FilterEntry key={filter} onClick={() => toggleFilter(filter)}>
                {filter}
                {activeAccountFilters?.length > 0 && activeAccountFilters.includes(filter) && (
                  <CheckMark icon={faCheck} size="xs" top="8px" />
                )}
              </FilterEntry>
            ))}
        </Wrapper>
      )}
    </>
  );
}
