import styled from 'styled-components';

export const PageDetails = styled.p`
  font-weight: 400;
  font-size: 1rem;
  line-height: 22px;
  color: var(--champion-charcoal);
  margin-bottom: 1.5rem;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.875rem;

  span {
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--playbook-pewter);
  }

  span.saving {
    animation: fadeIn 0.5s;
  }

  span.error {
    color: var(--danger);
  }

  span.auto-saved {
    animation: fadeOut 2s normal 0.5s;
  }
`;

export const InformationSection = styled.section`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 22px;
    color: var(--champion-charcoal);
  }

  .custom-field {
    width: 45%;

    &.default-field {
      .grip,
      .custom-field__options {
        visibility: hidden;
        pointer-events: none;
      }
    }

    svg[data-icon='grip-vertical'] {
      color: var(--playbook-pewter);
      cursor: grabbing;
    }

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &__header {
      display: flex;
      align-items: center;
      gap: 1rem;

      & > label {
        margin-bottom: 0;
      }
    }

    &__permission {
      font-size: 0.75rem;
      font-weight: 400;
    }

    &__options {
      display: flex;
      align-items: center;
      margin-left: auto;

      .lock-container,
      .eye-container {
        display: flex;
        flex-direction: column;
        position: relative;
      }

      .hidden-block {
        position: absolute;
        height: 0.875rem;
        width: 0.875rem;
        background: transparent;
        top: 1rem;
      }

      .icon-container {
        display: flex;
        position: relative;
      }

      .tooltip-action {
        font-size: 1rem;
        font-weight: bold;
        color: var(--schedule-sapphire);
        margin-right: 0;
      }

      span {
        cursor: pointer;
      }

      span,
      svg:not(:last-child) {
        margin-right: 0.875rem;
      }

      p {
        margin: 0;

        span {
          font-weight: bold;
        }
      }
    }

    &__input {
      margin: 0.5rem 0 0 1.5rem;

      textarea:disabled,
      input:disabled {
        background-color: var(--white);
        color: var(--playbook-pewter);
      }
    }

    .fa-enabled {
      cursor: pointer;
    }

    .fa-disabled {
      color: var(--goal-gray);
      cursor: not-allowed !important;
    }
  }
`;

export const Divider = styled.hr`
  width: 45%;
  background: var(--border-gray);
  margin: 1rem 0;
`;
