import styled from 'styled-components';
import { Form, Row, InputGroup, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledRow = styled(Row)`
  margin-right: -1.5rem;
  flex: 1;
  align-content: flex-start;
`;

export const StyledImg = styled.img`
  max-width: 100%;
`;

export const TokenContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormApiToken = styled(Form.Control)`
  width: 20rem !important;
  background-color: var(--white) !important;
`;

export const TokenHelperText = styled.p`
  margin-top: 0.625rem;
  color: var(--champion-charcoal);
  font-size: 0.75rem;
  opacity: 0.6;
`;

export const IconAppend = styled(InputGroup.Append)`
  border: 1px solid var(--border-gray);
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 0 6px 6px 0;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

export const LastUsedText = styled.p`
  font-size: 0.75rem;
  font-style: italic;
  color: var(--champion-charcoal);
  opacity: 0.6;
  margin-top: 0.25rem;
`;

export const DeleteTokenText = styled.p`
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  color: var(--champion-charcoal);
`;

export const CustomFieldsHelperText = styled.p`
  color: var(--champion-charcoal);
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
`;

export const DescriptiveHelperText = styled.p`
  color: var(--champion-charcoal);
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
`;

export const CustomFieldScreenText = styled.h3`
  font-size: 1rem;
  color: var(--champion-charcoal);
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  display: flex;
  max-width: 26.25rem;

  & > a {
    border: 1px solid var(--border-gray);
    border-radius: 1.125rem;
    padding: 0.5rem 1.5rem;
    margin-left: auto;
    color: var(--champion-charcoal);
    &:hover {
      text-decoration: none;
      background: var(--schedule-sapphire);
      color: white;
    }
  }
`;

export const LogoWrapper = styled.div`
  max-width: 20.5rem;
  margin-bottom: 2.5rem;
`;

export const IntegrationCol = styled(Col)`
  margin: 1.5rem 0 0 0;
  padding: 0;
  height: 100%;
`;

export const ServicePlatform = styled.div`
  display: flex;
  ${({ isLast }) => !isLast && 'border-bottom: 1px solid var(--border-gray);'}
  padding: 1rem 0;
  max-width: 26.25rem;
`;

export const BrandIcons = styled(FontAwesomeIcon)`
  color: ${({ color }) => color};
  background: ${({ background }) => background};
  ${({ background }) => {
    if (!!background) {
      return `
        border-radius: 50%;
        padding: 0.3rem;
      `;
    }
  }}
`;

export const IntegrationActionButton = styled(Button)`
  margin-left: auto;
  p {
    margin: 0 1rem;
  }
`;

export const TokenButton = styled(Button)`
  max-width: 20rem;
  width: auto;
`;

export const EditFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .edit-fields-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 19.875rem;
  }

  .edit-fields-link:last-child {
    padding-top: 0.75rem;
  }

  .edit-fields-link:not(:last-child) {
    padding-bottom: 0.375rem;
    border-bottom: 1px solid var(--border-gray);
  }
`;

export const Container = styled.div`
  margin: 2.5rem;
`;

export const HeaderTitle = styled.div`
  font-size: 1.813rem;
  margin-right: auto;
`;

export const SubjectInput = styled.input`
  align-items: center;
  margin-left: 1rem;
  border: 1px solid var(--border-gray);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  &:focus {
    border: 1px solid var(--playbook-pewter);
    outline: none;
  }
`;

export const SubdomainContainer = styled.div`
  .subdomain-container {
    display: flex;
    align-items: center;
    gap: 0.875rem;
    margin-bottom: 1.25rem;

    &__name-box {
      padding: 0.5rem 4rem 0.5rem 4rem;
      border: 1px solid var(--border-gray);
      border-radius: 0.5rem;
    }
  }
`;
