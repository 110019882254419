import React from 'react';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function PlusButton({ onClick, icon }) {
  return (
    <div className="avatar-circle light cursor-pointer" onClick={onClick}>
      <FontAwesomeIcon icon={icon || faPlus} />
    </div>
  );
}
