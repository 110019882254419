import { FormPicker } from 'components/Formik/FormPicker';
import { PLACEHOLDERS } from 'constants';
import { addBusinessDays, format, parseISO } from 'date-fns';
import { ErrorMessage, FieldArray, useField, useFormikContext } from 'formik';
import { isDropDownType } from 'helpers/CommonHelper';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import FormField from '../FormField';
import { ScrollableContainer, FieldContainer } from '../Styles';
import { DatePickerContainer } from './Styles';

const AssignCanvasContent = () => {
  const { values, setFieldValue } = useFormikContext();
  const { startDate, selectedCanvas } = values;

  const [, meta] = useField('startDate');

  const hasError = useMemo(() => meta.touched && !!meta.error, [meta.error, meta.touched]);

  useEffect(() => {
    setFieldValue('startDate', new Date().toISOString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderFieldArray = useCallback(
    (name) => (
      <FieldArray
        name={name}
        render={() => (
          <>
            {values[name].map((field, index) => {
              const isDropDown = isDropDownType(field?.fieldType);
              const formikName = `${name}[${index}].formikValue`;

              return (
                <Fragment key={field.objectMapId}>
                  <FieldContainer key={field.objectMapId}>
                    <FormField
                      field={field}
                      name={formikName}
                      isEdit={false}
                      isDropDownLastItem={isDropDown && values[name].length - 1 === index}
                    />
                  </FieldContainer>
                </Fragment>
              );
            })}
          </>
        )}
      />
    ),
    [values]
  );

  const projectedEndDate = useMemo(() => {
    const days = selectedCanvas?.days || 0;

    const selectedStartDate = startDate ? parseISO(startDate) : new Date();

    if (!selectedStartDate || !selectedCanvas) return '';

    const businessDays = days;

    const endDate = addBusinessDays(selectedStartDate, businessDays);
    const formattedEndDate = format(endDate, 'MMM d, yyyy');

    return formattedEndDate;
  }, [selectedCanvas, startDate]);

  return (
    <div>
      <ScrollableContainer>
        <DatePickerContainer>
          <div className="date-picker">
            <Form.Label className={`mr-3 text-muted font-weight-bold ${hasError && 'mb-4'}`}>
              Start
            </Form.Label>

            <div>
              <FormPicker
                name="startDate"
                isRequired
                dateProps={{
                  placeholder: PLACEHOLDERS.DATE,
                }}
              />

              {hasError && (
                <div className="error-message">
                  <ErrorMessage name="startDate" />
                </div>
              )}
            </div>
          </div>

          <div className="end-date">
            <h6 className="mr-3 text-muted font-weight-bold">End</h6>

            <span>{projectedEndDate}</span>
          </div>
        </DatePickerContainer>

        {renderFieldArray('projectFields')}
      </ScrollableContainer>
    </div>
  );
};

export default AssignCanvasContent;
