import ModalLargePadding, { ModalLargePaddingConfirmButton } from 'components/Modals/LargePadding';
import { BILLING_PLANS } from 'constants';
import React, { useMemo } from 'react';
import { SuccessContainer } from './Styles';

const Success = ({
  onHide,
  show,
  previousPlan,
  latestPlan,
  proratedValue,
  totalPrice,
  previousTotal,
  periodEnd,
  interval,
  tempProducts,
  salesTax,
}) => {
  const memoizedData = useMemo(() => {
    const previousIndex = tempProducts.indexOf(previousPlan);
    const currentIndex = tempProducts.indexOf(latestPlan);

    const isDowngrade = currentIndex < previousIndex;
    const isUpgrade = currentIndex > previousIndex;
    const isPreviousPlanFreeTier = previousPlan === BILLING_PLANS.FREE;

    return { isDowngrade, isUpgrade, isPreviousPlanFreeTier };
  }, [tempProducts, previousPlan, latestPlan]);

  const renderSuccessMessage = () => {
    if (memoizedData.isUpgrade) {
      return (
        <p className="text-subscription-success">
          You have upgraded your plan from {previousPlan} to <strong>{latestPlan}</strong>. You can enjoy the
          new features immediately.
        </p>
      );
    }

    if (memoizedData.isDowngrade) {
      return (
        <p className="text-subscription-success">
          You have changed your plan from {previousPlan} to <strong>{latestPlan}</strong>.
        </p>
      );
    }

    return (
      <p className="text-subscription-success">
        You have changed your interval to a <strong className="interval">{`${interval}ly`}</strong> basis.
      </p>
    );
  };

  const isNegative = Math.sign(proratedValue) === -1;

  const totalAmount = useMemo(() => (salesTax ? totalPrice + salesTax : totalPrice), [totalPrice, salesTax]);

  const nextMonthPrice = !isNaN(totalAmount)
    ? `$${
        totalAmount?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) || 0.0
      }`
    : `$0.00`;

  const billedNowPrice = memoizedData.isPreviousPlanFreeTier
    ? nextMonthPrice
    : `$${proratedValue?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} (pro-rated adjustment for this month)`;

  const renderContent = (
    <SuccessContainer>
      <h1 className="mb-4 text-center">Subscription Updated</h1>

      {renderSuccessMessage()}

      {isNegative ? (
        <>
          <p>
            <strong>Previous Billed Amount:</strong> $
            {previousTotal?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
          {!!salesTax && (
            <p>
              <strong>Sales Tax</strong>: $
              {salesTax?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || 0.0}
            </p>
          )}
          <p>
            <strong>Next Billed Amount:</strong> {nextMonthPrice}
          </p>
        </>
      ) : (
        <>
          <p>
            <strong>Billed Now:</strong> {billedNowPrice}
          </p>
          {!!salesTax && (
            <p>
              <strong>Sales Tax</strong>: $
              {salesTax?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || 0.0}
            </p>
          )}
          <p>
            <strong>Next Bill ({periodEnd}):</strong> {nextMonthPrice}
          </p>
        </>
      )}
    </SuccessContainer>
  );

  const renderButtons = (
    <>
      <ModalLargePaddingConfirmButton onClick={onHide} text="OK" autoWidth />
    </>
  );

  const modalStyle = {
    inner: {
      maxWidth: '35.25rem',
      padding: '2.5rem 1.25rem !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  return (
    <ModalLargePadding
      show={show}
      innerProps={modalStyle.inner}
      buttonsContainerProps={modalStyle.buttonContainer}
      content={renderContent}
      buttons={renderButtons}
    />
  );
};

export default Success;
