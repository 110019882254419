import {
  EMPLOYEE_PERMISSIONS,
  EMPLOYEE_PERMISSIONS_FIELDS,
  EMAIL_CONFIG_LABELS,
  EMAIL_CONFIG_FIELDS,
  BILLING_PLANS,
} from 'constants';

export const VALID_SUBDOMAIN_ERROR_MESSAGE = 'Please enter a valid subdomain name';

export const employeePermissions = [
  {
    fieldName: EMPLOYEE_PERMISSIONS_FIELDS.IDEAS,
    label: EMPLOYEE_PERMISSIONS.IDEAS,
  },
  {
    fieldName: EMPLOYEE_PERMISSIONS_FIELDS.EDIT_PROJECT,
    label: EMPLOYEE_PERMISSIONS.EDIT_PROJECT,
  },
  {
    fieldName: EMPLOYEE_PERMISSIONS_FIELDS.EDIT_PROJECT_SETTINGS,
    label: EMPLOYEE_PERMISSIONS.EDIT_PROJECT_SETTINGS,
  },
];

export const emailTab = [
  {
    fieldName: EMAIL_CONFIG_FIELDS.INVITED_TO_PROJECT,
    label: EMAIL_CONFIG_LABELS.INVITED_TO_PROJECT,
    infoText:
      'This email is sent when a user is added or invited to a project and gives them a link to the project that’s valid for 30 days.',
  },
  {
    fieldName: EMAIL_CONFIG_FIELDS.ASSIGNED,
    label: EMAIL_CONFIG_LABELS.ASSIGNED,
    infoText: 'This email is sent when a user is assigned to a step or task.',
  },
  {
    fieldName: EMAIL_CONFIG_FIELDS.PROJECT_UPDATE,
    label: EMAIL_CONFIG_LABELS.PROJECT_UPDATE,
    infoText:
      'This recurring email gives a summary of progress, recent task assignments and changes in step statuses. It is helpful in giving a current, easy to find link to the project for customers.',
  },
  {
    fieldName: EMAIL_CONFIG_FIELDS.PASTDUE,
    label: EMAIL_CONFIG_LABELS.PASTDUE,
    infoText: 'This email is sent when a step goes past due.',
  },
  {
    fieldName: EMAIL_CONFIG_FIELDS.COMMENT,
    label: EMAIL_CONFIG_LABELS.DIRECT_MENTION,
    infoText: 'This email is sent when a user is directly mentioned in a comment.',
  },
  {
    fieldName: EMAIL_CONFIG_FIELDS.UPLOAD,
    label: EMAIL_CONFIG_LABELS.UPLOAD,
    infoText: 'This email is sent when a user uploads a file or link to a step.',
  },
];

export const pricingPlanList = [
  {
    name: 'Free',
    subInfo: '1 user per company',
    features: [
      'Account-based management',
      'Project management',
      'Task assignment',
      'Timeline and dependencies',
      'Template creation',
      'Editable live projects',
      'Workflow triggered emails',
      'Custom email mergetags',
      'Email integration (Gmail, Outlook)',
      'Time management dashboard',
      'Internal reminders & notes',
      'Document & link library',
      'Contact management',
      'Activity logs',
      'Basic reporting',
      'Branded customer portal',
      'Customer project collaboration',
      'Customer email authentication',
      'Customer engagement timestamps',
      'Custom fields (local)',
    ],
    price: 'FREE',
  },
  {
    name: 'Teams',
    subInfo: 'Multiple users',
    features: [
      'Account-based management',
      'Project management',
      'Task assignment',
      'Timeline and dependencies',
      'Template creation',
      'Editable live projects',
      'Workflow triggered emails',
      'Custom email mergetags',
      'Email integration (Gmail, Outlook)',
      'Time management dashboard',
      'Internal reminders & notes',
      'Document & link library',
      'Contact management',
      'Activity logs',
      'Basic reporting',
      'Branded customer portal',
      'Customer project collaboration',
      'Customer email authentication',
      'Customer engagement timestamps',
      'Custom fields (local)',
      'Role based permissions',
    ],
    price: '',
    billing: 'per month per user',
  },
  {
    name: 'Teams + Integrations',
    subInfo: 'Multiple users',
    features: [
      'Account-based management',
      'Project management',
      'Task assignment',
      'Timeline and dependencies',
      'Template creation',
      'Editable live projects',
      'Workflow triggered emails',
      'Custom email mergetags',
      'Email integration (Gmail, Outlook)',
      'Time management dashboard',
      'Internal reminders & notes',
      'Document & link library',
      'Contact management',
      'Activity logs',
      'Basic reporting',
      'Branded customer portal',
      'Customer project collaboration',
      'Customer email authentication',
      'Customer engagement timestamps',
      'Custom fields (local & CRM)',
      'Role based permissions',
      'Account automation',
      'Two-way CRM integrations',
      'API Access',
      'Embedded customer access links',
    ],
    price: '',
    billing: 'per month per user',
  },
];

export const mappedPlanName = {
  [BILLING_PLANS.FREE]: 'pricing-plan__name-free',
  [BILLING_PLANS.TEAMS]: 'pricing-plan__name-teams',
  [BILLING_PLANS.INTEGRATIONS]: 'pricing-plan__name-integrations',
};

export const highlights = {
  teams: ['Role based permissions'],
  integrations: [
    'Custom fields (local & CRM)',
    'Role based permissions',
    'Account automation',
    'Two-way CRM integrations',
    'API Access',
    'Embedded customer access links',
  ],
};

export const planDetails = [
  {
    title: 'Free',
    users: 'Single User Only',
    limit: 'Limit of 20 Accounts',
    info: 'Free to use forever.',
  },
  {
    title: 'Tier 1',
    users: 'Unlimited Users',
    limit: 'Up to 50 Accounts',
    info: 'One flat rate for your whole team.',
  },
  {
    title: 'Tier 2',
    users: 'Unlimited Users',
    limit: 'Up to 200 Accounts',
    info: 'One flat rate for your whole team.',
  },
  {
    title: 'Tier 3',
    users: 'Unlimited Users',
    limit: 'Up to 500 Accounts',
    info: 'One flat rate for your whole team.',
  },
  {
    title: 'Enterprise',
    users: 'Unlimited Users',
    limit: '501+ Accounts',
    info: 'For organizations with a need for enterprise scale.',
  },
];

export const initialCardValues = {
  cardName: '',
  address1: '',
  address2: '',
  city: '',
  mode: 'add',
  isCardValid: false,
  cardId: null,
  state: null,
  postalCode: null,
  expMonth: null,
  expYear: null,
  paymentMethodId: null,
  selectedPlan: '',
};

export const initialSubscriptionValues = {
  priceId: null,
  planName: '',
  selectedPlan: null,
  price: null,
  seats: 1,
  totalPrice: 0,
  previousTotal: 0,
  recurring: null,
  coupon: '',
};

export const cardFields = [
  {
    fieldLabel: 'Street',
    fieldName: 'address1',
    fieldType: 'input',
    placeholder: '',
    type: 'text',
    isRequired: true,
  },
  {
    fieldLabel: 'City',
    fieldName: 'city',
    fieldType: 'input',
    placeholder: '',
    type: 'text',
    isRequired: true,
  },
  {
    fieldLabel: 'State',
    fieldName: 'state',
    fieldType: 'dropdown',
    placeholder: 'None',
    type: 'searchable',
    isRequired: true,
  },
  {
    fieldLabel: 'Postal Code',
    fieldName: 'postalCode',
    fieldType: 'input',
    placeholder: '',
    type: 'number',
    isRequired: true,
    maxLength: 5,
  },
];
