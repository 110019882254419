import styled from 'styled-components';

export const Button = styled.button`
  background-color: #fff;
  border: 1px solid var(--border-gray);
  border-radius: 18px;
  font-size: ${(props) => props.fontSize};
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '36px')};
  text-align: center;
  padding: 0 24px;
  margin: ${(props) => (props.margin ? props.margin : '16px 0 0 0')};
  outline: none;
  transition: background-color, border-color 0.15s ease-in-out;
  &:hover {
    background-color: var(--light-gray-bg);
    border: 1px solid var(--light-gray-bg);
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem hsla(220, 20%, 92%, 0.5);
    outline: none;
  }
  &:disabled {
    opacity: 0.4;
  }
`;

export const PrimaryButton = styled.button`
  background: linear-gradient(90deg, #4dc9fe 0%, #2585fd 100%);
  font-weight: bold;
  font-size: ${(props) => props.fontSize};
  text-align: center;
  border-radius: 18px;
  padding: 0 24px;
  border: none;
  color: white;
  margin: 0 0 0 10px;
  height: 36px;
  &:hover {
    background: linear-gradient(90deg, #27bdfe 0%, #0270f9 100%);
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const ToggleSwitch = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  margin-left: -10px;

  & > input,
  label,
  span {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : '')};
    opacity: ${(props) => (props.disabled ? '0.4' : '1')};
  }

  & > div {
    visibility: hidden;

    svg {
      display: none;
    }
  }

  &:hover {
    & > div {
      visibility: ${(props) => (props.disabled ? 'visible' : '')};
    }
  }

  .custom-toggle {
    display: none;
  }
  .custom-toggle:checked + .custom-label {
    background-color: var(--schedule-sapphire);
  }
  .custom-label {
    position: relative;
    background-color: #d0d0d0;
    border-radius: 50px;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    display: inline-block;
    margin: 0 8px 0;
    width: 30px;
    height: 15px;
  }
  .custom-ball {
    background: #fff;
    height: 11px;
    width: 11px;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }
  .custom-toggle:checked + .custom-label .custom-ball {
    transform: translateX(15px);
  }
`;
