import { useContext, useEffect, useState, useCallback } from 'react';
import { Settings } from 'models';
import { useNavigate, useLocation } from 'react-router-dom';
import { emailTab } from '../fixtures';
import { SETTINGS_PERMISSIONS, LINK_BUTTON_LABEL, EMAIL_CONFIG_FIELDS, EMAIL_CONFIG_LABELS } from 'constants';
import { TextButton } from 'views/Customer/Styles';
import InfoTextBox from 'components/Modals/Shared/InfoTextBox';
import FullContext from 'stores/Full/fullContext';
import { Modal } from 'react-bootstrap';
import EmailTemplate from './EmailTemplate';

const Email = ({ appSettings, handleLoadBillingTab }) => {
  const location = useLocation();
  const history = useNavigate();
  const { isFrontLine, isCreator } = useContext(FullContext);
  const isEmailInitial = isFrontLine || isCreator;

  const [emailConfig, setEmailConfig] = useState([]);
  const [modalState, setModalState] = useState({
    addToProject: false,
    assignToStepOrTask: false,
    projectUpdate: false,
    pastDueStep: false,
    comment: false,
    fileOrLinkUploaded: false,
  });

  /**
   * Callback used to retrieve the email endpoint
   */
  const getEmailConfig = useCallback(async () => {
    const _config = await Settings.getEmailConfig();
    setEmailConfig(_config);
  }, []);

  useEffect(() => {
    if (!emailConfig?.length && (location.hash === '#email' || (location.hash === '' && isEmailInitial))) {
      getEmailConfig();
    }
  }, [getEmailConfig, emailConfig?.length, location.hash, isEmailInitial]);

  /**
   * Handler that sets the fieldName's component to active
   * @param {string} fieldName
   */
  const onShowEmailConfig = (fieldName) => {
    setModalState((prev) => {
      return {
        ...prev,
        [fieldName]: true,
      };
    });
  };

  /**
   * Handler that reverts all active components to inactive.
   * With no active components, it wouldn't show the any email
   * modal
   */
  const onHideEmailConfig = () => {
    history({
      hash: '#email',
    });
    setModalState({
      addToProject: false,
      assignToStepOrTask: false,
      projectUpdate: false,
      pastDueStep: false,
      comment: false,
      fileOrLinkUploaded: false,
    });
  };

  /**
   * Function that records a list of active components
   * There could only be one active component at a time
   * @returns Array
   */
  const getActiveModal = () => {
    return Object.keys(modalState).reduce((state, item) => {
      if (modalState[item]) {
        state.push(item);
      }
      return state;
    }, []);
  };

  /**
   * retrieves the title of the selected modal opened
   * @name {string} = property name of the selected modal
   */
  const getTitle = (name) => {
    switch (name) {
      case EMAIL_CONFIG_FIELDS.INVITED_TO_PROJECT:
        return EMAIL_CONFIG_LABELS.INVITED_TO_PROJECT;
      case EMAIL_CONFIG_FIELDS.PASTDUE:
        return EMAIL_CONFIG_LABELS.PASTDUE;
      case EMAIL_CONFIG_FIELDS.PROJECT_UPDATE:
        return EMAIL_CONFIG_LABELS.PROJECT_UPDATE;
      case EMAIL_CONFIG_FIELDS.ASSIGNED:
        return EMAIL_CONFIG_LABELS.ASSIGNED;
      case EMAIL_CONFIG_FIELDS.COMMENT:
        return EMAIL_CONFIG_LABELS.COMMENTS;
      case EMAIL_CONFIG_FIELDS.UPLOAD:
        return EMAIL_CONFIG_LABELS.UPLOAD;
      default:
        return null;
    }
  };

  /**
   * Handler that switches the content for the modal
   * @returns Component
   */
  const renderModal = () => {
    const _active = getActiveModal()[0];
    const activeConfig = emailConfig?.find((item) => item.name === _active);
    const title = getTitle(_active);

    return (
      <EmailTemplate
        id={activeConfig?.id}
        title={title}
        onHide={onHideEmailConfig}
        isViewMode={activeConfig?.permission !== SETTINGS_PERMISSIONS.READWRITE}
        isTrialMode={appSettings?.trialMode}
        handleLoadBillingTab={handleLoadBillingTab}
      />
    );
  };

  return (
    <>
      <h6 className="pb-1">
        Available customer-facing emails generated by the system. These can be controlled in template and
        project settings.
      </h6>
      {emailConfig?.map((config, index) => {
        const _field = emailTab.find((item) => item.fieldName === config.name);
        return (
          <div className="settings-row py-3 mb-0 square border-bottom" key={`general-config-${index}`}>
            <div className="d-flex">
              <span className="mr-3">{_field?.label}</span>
              <InfoTextBox isInfoHoverable width="26.375rem" top="1rem" left="-3.188rem">
                {_field?.infoText}
              </InfoTextBox>
            </div>
            <TextButton color="var(--schedule-sapphire)" onClick={() => onShowEmailConfig(_field.fieldName)}>
              {config.permission === SETTINGS_PERMISSIONS.READWRITE
                ? LINK_BUTTON_LABEL.EDIT
                : LINK_BUTTON_LABEL.VIEW}
            </TextButton>
          </div>
        );
      })}
      <Modal
        size="xl"
        show={!!getActiveModal().length}
        onHide={onHideEmailConfig}
        contentClassName="overflow-hidden"
        enforceFocus={false}
      >
        {renderModal()}
      </Modal>
    </>
  );
};

export default Email;
