import React, { useContext, useMemo, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FormikContext } from 'formik';
import { defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { CUSTOM_FIELD_MODES } from 'constants';
import { useAccountSection } from 'views/CustomFields/Account/hooks';
import SectionModal from 'components/Modals/CustomFields/Section';
import ModalConfirm from 'components/Modals/Confirm';
import InfoTextBox from 'components/Modals/Shared/InfoTextBox';
import { SectionContainer, SectionHeader, SectionBody } from './Styles';
import { CONTACTS_SECTION_DESC, GENERAL } from 'constants';

const animateLayoutChanges = (args) => defaultAnimateLayoutChanges({ ...args, wasDragging: true });

const EditableSection = ({ id, items, isUndraggable = false, columnCount = 2, children }) => {
  const { handleShow, handleHide, formikBag, showEditSectionModal, setSectionURLParam } = useAccountSection();

  const { contacts, deleteSection } = useContext(CustomFieldsContext);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const infoRef = useRef(null);

  const section = useMemo(
    () =>
      contacts.editableSections?.find(
        ({ customFieldSectionId }) => String(customFieldSectionId) === id.split('-')[0]
      ),
    [id, contacts.editableSections]
  );

  const hasFields = section?.fields.length > 0;
  const isDeleteDisabled = hasFields;

  /**
   * On Edit Handler
   * @param none
   * @returns void
   */
  const handleEdit = () => {
    const values = {
      sectionMode: CUSTOM_FIELD_MODES.EDIT,
      editedSectionId: section?.customFieldSectionId,
      sectionLabel: section?.name,
      previousSectionLabel: section?.name,
    };

    formikBag.setValues({
      ...values,
    });

    setSectionURLParam(section?.customFieldSectionId);
    handleShow();
  };

  /**
   * Toggles the delete modal
   * @param none
   * @returns void
   */
  const toggleDeleteModal = (value) => () => {
    if (hasFields) return;
    setShowDeleteModal(value);
  };

  /**
   * On Mouse hover Handler
   * @params none
   * @returns void
   */
  const onMouseHover = () => isDeleteDisabled && infoRef.current.toggleTextBox();

  /**
   * On Delete Handler
   * @params none
   * @returns void
   */
  const handleDelete = () => {
    deleteSection(section?.customFieldSectionId);
    setShowDeleteModal(false);
  };

  const { attributes, listeners, setNodeRef, transition, transform, isDragging } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  });

  const sectionStyle = {
    transition,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : undefined,
  };

  const modalStyle = {
    inner: {
      maxWidth: '35.25rem',
      padding: '2.5rem 1.25rem !important',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  };

  const renderDeleteMessage = (
    <>
      Are you sure you want to delete the {section?.name} section?
      <br />
      This action cannot be undone.
    </>
  );

  return (
    <>
      <SectionContainer ref={setNodeRef} style={sectionStyle}>
        <SectionHeader>
          <div {...attributes} {...listeners}>
            {!isUndraggable && <FontAwesomeIcon icon={faGripVertical} />}
          </div>

          <h3>{section?.name}</h3>

          {!isUndraggable && (
            <div className="section-options">
              <FontAwesomeIcon icon={faPen} onClick={handleEdit} className="fa-enabled" />

              <FontAwesomeIcon
                icon={faTrash}
                onClick={toggleDeleteModal(true)}
                onMouseOver={onMouseHover}
                onMouseOut={onMouseHover}
                className={isDeleteDisabled ? 'fa-disabled' : 'fa-enabled'}
              />

              <InfoTextBox ref={infoRef} notActive={false} width="300px" top="-80px" left="-260px">
                Remove fields to delete section
              </InfoTextBox>
            </div>
          )}
        </SectionHeader>
        <div className="ml-3 fs-7">
          {section?.name === GENERAL ? CONTACTS_SECTION_DESC.GENERAL : CONTACTS_SECTION_DESC.MOREINFO}
        </div>
        <SectionBody className="ml-3 pt-3 border-bottom-0" columnCount={columnCount}>
          {children}
        </SectionBody>
      </SectionContainer>

      <FormikContext.Provider value={formikBag}>
        <SectionModal show={!!showEditSectionModal} onHide={handleHide} />
      </FormikContext.Provider>

      <ModalConfirm
        title="Delete Section"
        message={renderDeleteMessage}
        show={!!showDeleteModal}
        innerProps={modalStyle.inner}
        buttonsContainerProps={modalStyle.buttonContainer}
        autoButtonsWidth
        onConfirm={handleDelete}
        onHide={toggleDeleteModal(false)}
      />
    </>
  );
};

export default React.memo(EditableSection);
