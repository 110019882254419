import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TableWrapperStyle = styled.div`
  width: ${(props) => (props.width ? '50%' : '100%')};
  margin: 20px 0 20px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
  background: var(--light-gray-bg);
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const TableTitleStyle = styled.div`
  color: var(--champion-charcoal);
  font-weight: bold;
  padding: 18px 18px;
  background: var(--goal-gray);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  .title {
    margin-right: auto;
  }
  .filter {
    font-weight: normal;
    margin-left: 6px;
  }
`;

export const FilterList = styled.div`
  display: inline-block;
  font-weight: normal;
  max-width: 14rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TableStyle = styled.table`
  display: block;
  empty-cells: show;
  background: ${(props) => props.type !== 'secondary' && 'var(--light-gray-bg)'};
  border-spacing: 0;
  table-layout: fixed;

  & > thead {
    position: relative;
    display: block;
    width: 100%;
    & > tr {
      width: 100%;
      display: flex;
      padding-right: ${(props) => props.type !== 'secondary' && '15px'};
      border-bottom: ${(props) => props.type !== 'secondary' && '1px solid var(--icon-light-gray)'};
      overflow-x: auto;

      ${(props) =>
        props.type === 'secondary' &&
        `
          @media only screen and (max-width: 768px) {
            flex-direction: column;

            & > th:not(:last-child) {
              margin-bottom: 0;
            }
          }
      `}
    }
  }

  & > tbody {
    display: block;
    position: relative;
    width: 100%;
    max-height: 400px;
    overflow-y: ${(props) => (props.type === 'secondary' ? 'unset' : 'scroll')};

    & > tr {
      width: 100%;
      display: flex;

      ${(props) =>
        props.type === 'secondary' &&
        `
          @media only screen and (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
          }
      `}
    }
  }
`;

export const TableHeaderStyle = styled.th`
  flex-basis: ${(props) => props?.flexBasis ?? 100}%;
  flex-grow: 2;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.alignText ? 'left' : 'center')};
  padding: 20px 15px;
  text-align: ${(props) => (props.alignText ? 'left' : 'center')};
  background: var(--light-gray-bg);
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;

  & > span {
    color: var(--action-anchor);
  }

  ${(props) =>
    props.type === 'secondary' &&
    `
      border-bottom: none;
      padding: 0 23px 0 0;
      background: none;
      box-shadow: none;
      font-size: 0.875rem;
      color: var(--playbook-pewter);
      margin-bottom: 0.5rem;
  `}
`;

export const TableDataStyle = styled.td`
  font-size: 14px;
  font-weight: ${(props) => (props.link || props.danger) && 'bold'};
  flex-basis: ${(props) => props?.flexBasis ?? 100}%;
  flex-grow: 2;
  display: block;
  padding: 10px 15px;
  text-align: ${(props) => (props.alignText ? 'center' : 'left')};
  color: ${(props) => (props.danger ? 'var(--danger)' : props.color)};

  ${(props) =>
    props.type === 'secondary' &&
    `
      font-size: 1rem;
      padding: 0 23px 0 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
  `}
`;

export const TableDataConditional = styled.span`
  color: ${(props) => props.danger && 'var(--danger)'};
  font-weight: ${(props) => (props.link || props.danger) && 'bold'};
`;
export const NoDataTextStyle = styled.p`
  width: 100%;
  margin: 90px auto;
  color: var(--playbook-pewter);
  font-weight: bold;
  font-size: 14px;
  text-align: center;
`;

export const ChevronArrow = styled(FontAwesomeIcon)`
  color: var(--schedule-sapphire);
  margin-left: 5px;
`;

export const GraphContainerStyle = styled.div`
  margin: ${(props) => (props.margin ? props.margin : '20px 16px 0 0')};
  background: var(--light-gray-bg);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? `${props.height}` : '100%')};
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const GraphWrapperStyle = styled.div`
  margin: 16px;
`;

export const LegendSymbolStyle = styled.div`
  width: 16px;
  height: 16px;
  background: ${(props) => props.color};
  border-radius: 50%;
  margin: 0 4px 0 0;
`;

// Drop down modal styles
export const DropDown = styled.button`
  width: ${(props) => props.width};
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  background: #f6f7f9;
  border: none;
  border-radius: 5px;
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  outline: none;
  position: relative;
  opacity: ${(props) => (props.notActive ? '0.5' : '1')};
  & > span {
    font-size: 15px;
  }
`;

export const DropDownArrow = styled(FontAwesomeIcon)`
  color: #008be7;
  position: absolute;
  right: ${(props) => (props.right ? props.right : '16px')};
  top: 14px;
`;

export const DropDownItem = styled.div`
  position: fixed;
  top: 76px;
  z-index: 1;
  margin: 0 0 0 -16px;
`;

export const CheckMark = styled(FontAwesomeIcon)`
  position: absolute;
  left: 10px;
  top: ${(props) => props.top};
`;

export const Wrapper = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  width: auto;
  max-width: 400px;
  max-height: 400px;
  overflow-y: auto;
  padding: 16px 20px 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
`;

export const FilterEntry = styled.div`
  display: block;
  width: 100%;
  text-align: left;
  font-size: 16px;
  padding-inline: ${(props) => (props.indentText ? '35px' : '25px')};
  margin-bottom: 8px;
  position: relative;
  text-indent: ${(props) => (props.indentText ? '-10px' : '0')};
  border: none;
  background: #fff;

  &:hover {
    cursor: pointer;
    background-color: rgb(0, 0, 0, 0.1);
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--playbook-pewter);
  }
`;

export const LegendMessageBox = styled.div`
  transform: rotate(90deg);
  font-size: 16px;
  font-weight: 400;
  line-height: 21.82px;
  position: absolute;
  z-index: 1;
  top: 235px;
  right: -102px;
  width: ${(props) => props.width}px;
  padding: 12px 20px;
  background: #fff;
  border: 1px solid var(--border-gray);
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  white-space: pre-line;
`;

export const HeaderMessageBox = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 21.82px;
  position: fixed;
  z-index: 1;
  top: ${(props) => props.top + 50}px;
  left: ${(props) => props.left}px;
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  padding: 12px 20px;
  background: #fff;
  border: 1px solid var(--border-gray);
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  white-space: pre-line !important;
`;

export const StyledTr = styled.tr`
  white-space: nowrap;
  text-overflow: ellipsis;
  max-height: 4rem;

  @media screen and (max-width: 1350px) {
    white-space: break-spaces;
    text-overflow: unset;
  }
`;
