import { Navigate, Route, useLocation, useNavigate, useParams, Outlet } from 'react-router-dom';
import { accessToken, logOut, setAccessToken } from '../helpers/CommonHelper';
import { createBrowserHistory } from 'history';
import { useContext, useEffect } from 'react';
import FullContext from 'stores/Full/fullContext';
import AuthContext from 'stores/Auth/authContext';

export default createBrowserHistory();

export function userLoggedIn(token) {
  checkForIntegrationCode();
  return !!token;
}

function checkForIntegrationCode() {
  var urlParams = getUrlParams();
  if (urlParams['integration'] === 'SurveyMonkey') {
    var shortLivedCode = urlParams['code'];
    sendCodeToServer(shortLivedCode);
    removeIntegrationParametersFromUrl();
  }
}

function sendCodeToServer(code) {
  fetch('api/SurveyMonkey/Authenticate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken,
    },
    body: JSON.stringify({ ShortLivedCode: code }),
  });
}

function removeIntegrationParametersFromUrl() {
  window.location.href = window.location.href.split('?')[0] + 'integrations';
  throw Error();
}

export function getUrlParams() {
  var vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}

const WrapRoute = ({ element, ...rest }) => <Route {...rest} element={element} />;

export const AnonymousRoute = ({ component: Element, ...rest }) => (
  <WrapRoute {...rest} element={<Element />} />
);

export const RouteLogout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    logOut(navigate);
  }, [navigate]);

  return null;
};

export function withAuth(Component, options = {}) {
  return function AuthComponent(props) {
    const { minRole } = options;
    const { roleAtleast, userData } = useContext(FullContext);
    const { accessToken, populateAccessToken } = useContext(AuthContext);
    const token = accessToken ?? props.token;
    const hasToken = !!token;

    useEffect(() => {
      if (token && !accessToken) {
        setAccessToken(token);
        populateAccessToken(token);
      }
    }, [accessToken, populateAccessToken, token]);

    const hasUserData = Object.keys(userData).length > 0;
    const isInvalidRole = minRole && hasUserData && !roleAtleast(minRole);

    if (!userLoggedIn(token) || !hasToken) return <Navigate replace to="/login" />;

    if (isInvalidRole) return <Navigate replace to="/404" />;

    return (
      <Outlet>
        <Component {...props} />
      </Outlet>
    );
  };
}

export const ProtectedRoute = ({ children, minRole, ...rest }) => {
  const { roleAtleast, userData } = useContext(FullContext);
  const { accessToken, populateAccessToken } = useContext(AuthContext);
  const token = rest.token ?? accessToken;
  const hasToken = !!token;

  useEffect(() => {
    if (rest.token && !accessToken) {
      populateAccessToken(rest.token);
    }
  }, [accessToken, populateAccessToken, rest.token]);

  const hasUserData = Object.keys(userData).length > 0;
  const isInvalidRole = minRole && hasUserData && !roleAtleast(minRole);

  if (!userLoggedIn(token) || !hasToken) return <Navigate replace to="/login" />;
  if (isInvalidRole) return <Navigate replace to="/404" />;

  return children;
};

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
