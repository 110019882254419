import * as Yup from 'yup';
import { NEW_SECTION, FIELDS } from 'constants';
import { baseSchema, selectSchema } from 'helpers/schemaHelper';

const withSectionSchema = {
  section: selectSchema.required(),

  sectionLabel: Yup.string().when(FIELDS.SECTION, {
    is: (section) => section && section?.value === NEW_SECTION,
    then: Yup.string().required(''),
  }),
};

export const fieldSchema = Yup.object().shape({
  ...withSectionSchema,
  ...baseSchema,
});

export const baseFieldSchema = Yup.object().shape({
  ...baseSchema,
});
