import React from 'react';
import { FORMIK_FIELD_TYPES } from 'constants';
import Dropdown from './Dropdown';
import SearchableDropdown from './SearchableDropdown';

const FormDropdown = ({
  name,
  type = FORMIK_FIELD_TYPES.DEFAULT,
  options,
  disabled,
  fieldLabel,
  isRequired,
  dropdownProps,
  searchableProps,
  isDropDownLastItem,
}) => {
  switch (type) {
    case FORMIK_FIELD_TYPES.SEARCHABLE:
      return (
        <SearchableDropdown
          name={name}
          options={options}
          disabled={disabled}
          fieldLabel={fieldLabel}
          isRequired={isRequired}
          searchableProps={searchableProps}
          isDropDownLastItem={isDropDownLastItem}
        />
      );

    default:
      return (
        <Dropdown
          name={name}
          options={options}
          fieldLabel={fieldLabel}
          isRequired={isRequired}
          disabled={disabled}
          dropdownProps={dropdownProps}
        />
      );
  }
};

export default React.memo(FormDropdown);
