import { useEffect } from 'react';
import FullScreenWithBackground from '../components/FullScreenWithBackground';

export default function ErrorMaintenance() {
  useEffect(() => {
    let timer = setTimeout(() => (window.location.href = '/'), 20000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <FullScreenWithBackground>
      <div className="text-center">
        <h3 className="pb-4">The system is upgrading.</h3>
        <p className="text-muted">
          This process will complete shortly.
          <br />
          You will be automatically redirected.
        </p>
      </div>
    </FullScreenWithBackground>
  );
}
