class SessionStorage {
  static set(key, value, toString = false) {
    !toString ? sessionStorage.setItem(key, value) : sessionStorage.setItem(key, JSON.stringify(value));
  }

  static get(key, toParse = false) {
    return !toParse ? sessionStorage.getItem(key) : JSON.parse(sessionStorage.getItem(key));
  }

  static remove(key) {
    return sessionStorage.removeItem(key);
  }

  static clear() {
    sessionStorage.clear();
  }
}

export default SessionStorage;
