import React, { useMemo } from 'react';
import { FormikContext } from 'formik';
import { AccountBodyContainer } from 'views/CustomFields/Account/Styles';
import DetailField from 'components/CustomFields/Details/DetailField';
import { SectionContainer, SectionHeader } from 'components/CustomFields/Details/Styles';
import { OBJECT_TYPE_ENUM } from 'constants';
import { useCustomer } from './hooks';
import { SectionBody } from './Styles';
import { isDropDownType } from 'helpers/CommonHelper';

const CustomerDetail = ({ customerId, setSaving, setHasError }) => {
  const { formikBag } = useCustomer(customerId);
  const { values } = formikBag;

  const renderFields = useMemo(() => {
    return values.detailsFields.map((section, sectionKey) => (
      <SectionContainer key={section.customFieldSectionId}>
        <SectionHeader>
          <h3>{section?.name}</h3>
        </SectionHeader>

        <SectionBody className="customer-detail">
          {section.fields.map((field, fieldKey) => {
            const name = `detailsFields[${sectionKey}].fields[${fieldKey}].formikValue`;
            const isDropDown = isDropDownType(field?.fieldType);
            return (
              <DetailField
                key={field.objectMapId}
                field={field}
                name={name}
                variation={OBJECT_TYPE_ENUM.CUSTOMER}
                setSaving={setSaving}
                isDropDownLastItem={
                  isDropDown &&
                  fieldKey >= section.fields?.length - (section.fields?.length % 2 === 0 ? 2 : 1) &&
                  sectionKey === values.detailsFields.length - 1
                }
                setHasError={setHasError}
              />
            );
          })}
        </SectionBody>
      </SectionContainer>
    ));
  }, [setSaving, setHasError, values.detailsFields]);

  return (
    <FormikContext.Provider value={formikBag}>
      <AccountBodyContainer hasDivider>{renderFields}</AccountBodyContainer>
    </FormikContext.Provider>
  );
};

export default CustomerDetail;
