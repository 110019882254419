import { useContext } from 'react';
import { Touchpoint } from 'models';
import FullContext from 'stores/Full/fullContext';
import { DependentStepHeader } from '../styles';

const DueDateHeader = ({ noDueDate, touchpoint } = { touchpoint: new Touchpoint() }) => {
  const { isEditMode } = useContext(FullContext);
  const isScheduledEditMode = touchpoint?.isScheduledType && !isEditMode;

  if (touchpoint?.isComplete) return false;

  return (
    <DependentStepHeader
      notActive={noDueDate}
      textAlign={isScheduledEditMode ? 'center' : ''}
    >
      Due
      {isScheduledEditMode && ` by ${touchpoint.shortDateOf('endDate')}`}
    </DependentStepHeader>
  );
}

export default DueDateHeader;