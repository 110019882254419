import { faComment, faPencil, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import FullContext from 'stores/Full/fullContext';
import * as Api from '../../../api/Api';
import { ADMIN } from 'constants';
import { formatDateString } from '../../../helpers/DateHelper';
import { Avatar } from '../Avatar';

export default function Ideas({ touchpoint, touchpointIdeas, onIdeasUpdated }) {
  const [newIdeaTitle, setNewIdeaTitle] = useState('');
  const [newIdeaDescription, setNewIdeaDescription] = useState('');
  const [editingIdea, setEditingIdea] = useState(null);
  const [editingComment, setEditingComment] = useState(null);
  const [ideaBeingCommented, setIdeaBeingCommented] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [showComments, setShowComments] = useState(null);
  const { isExternal, isContributor, userData, roleAtleast } = useContext(FullContext);

  // This delays fetching related Ideas data until the ModalTouchpoint view
  // is rendered.
  useEffect(() => {
    // This is a little janky, but if the array is already populated, then
    // the assumption is that the Ideas were already fetched. Multiple calls
    // will happen if the same step modal is opened more than once--duplicating
    // the list of Ideas each time. There is probably a better way to do this.
    if (touchpointIdeas && touchpointIdeas.length <= 0) {
      let url = ``;
      if (touchpoint.touchpointId){/*CustomerTouchpoint*/
        url = `TouchpointIdea/GetByTouchpoint?customerTouchpointId=${touchpoint.id}`
      }else{/*Touchpoint*/
        url = `TouchpointIdea/GetByTouchpoint?touchpointId=${touchpoint.id}`
      }
      Api.get(url).then((data) =>
        data.items.forEach((item) => touchpointIdeas.push(item))
      );
    }
  }, [touchpoint.id, touchpoint.touchpointId, touchpointIdeas]);

  function handleTitleChange(event, idea) {
    idea.title = event.target.value;
    onIdeasUpdated(touchpointIdeas);
  }

  function handleCommentTextChange(event, comment) {
    comment.text = event.target.value;
    onIdeasUpdated(touchpointIdeas);
  }

  function handleDescriptionChange(event, idea) {
    idea.description = event.target.value;
    onIdeasUpdated(touchpointIdeas);
  }

  function handleOnChangeNewIdeaTitle(e) {
    setNewIdeaTitle(e.target.value);
  }

  function handleOnChangeNewIdeaDescription(e) {
    setNewIdeaDescription(e.target.value);
  }

  function handleOnChangeNewComment(e) {
    setNewComment(e.target.value);
  }

  async function toggleLike(idea) {
    var touchpointIdea = await Api.post(`TouchpointIdea/Upvote?touchpointIdeaId=${idea.id}`);
    var touchpointVotes = [];
    touchpointIdea.touchpointIdeaVotes.forEach((item, index) => {
      var touchpointVote = {
        id: item.id,
        userTenantId: item.userTenant.id,
      };
      touchpointVotes.push(touchpointVote);
    });
    idea.touchpointIdeaVotes = touchpointVotes;
    onIdeasUpdated(touchpointIdeas);
  }

  async function addIdea() {
    if (newIdeaTitle && newIdeaDescription) {
      var newIdeaResponse = await Api.post('TouchpointIdea/CreateTouchpointIdea', {
        Title: newIdeaTitle,
        Description: newIdeaDescription,
        TouchpointId: touchpoint.templateTouchpointId || touchpoint.id,
      });
      newIdeaResponse.avatar = newIdeaResponse.userTenant.avatar;
      newIdeaResponse.ownerId = newIdeaResponse.userTenant.id;
      newIdeaResponse.ownerName = `${newIdeaResponse.userTenant.firstName} ${newIdeaResponse.userTenant.lastName}`;
      newIdeaResponse.dateCreated = new Date(new Date().toISOString());

      touchpointIdeas.push(newIdeaResponse);
      onIdeasUpdated(touchpointIdeas);
    }
    setNewIdeaTitle('');
    setNewIdeaDescription('');
  }

  async function updateIdea(idea) {
    if (idea.title) {
      var updatedIdeaResponse = await Api.post('TouchpointIdea/UpdateTouchpointIdea', {
        Id: idea.id,
        Title: idea.title,
        Description: idea.description,
        TouchpointId: touchpoint.id,
      });
      touchpointIdeas.forEach((item) => {
        if (item.id === idea.id) {
          item.title = updatedIdeaResponse.title;
          item.description = updatedIdeaResponse.description;
        }
      });
      onIdeasUpdated(touchpointIdeas);
      setEditingIdea(null);
    }
  }

  async function addComment(idea) {
    if (newComment) {
      var newCommentResponse = await Api.post('TouchpointIdea/CreateTouchpointIdeaComment', {
        Text: newComment,
        TouchpointIdeaId: idea.id,
      });
      if (idea.touchpointIdeaComments == null) {
        idea.touchpointIdeaComments = [];
      }
      newCommentResponse.commenterName = `${newCommentResponse.userTenant.firstName} ${newCommentResponse.userTenant.lastName}`;
      newCommentResponse.commenterId = newCommentResponse.userTenant.id;
      idea.touchpointIdeaComments.push(newCommentResponse);
      onIdeasUpdated(touchpointIdeas);
    }
    setNewComment('');
  }

  async function updateComment(comment, idea) {
    if (comment.text) {
      await Api.post(`TouchpointIdea/UpdateTouchpointIdeaComment?commentId=${comment.id}`, {
        Text: comment.text,
        TouchpointIdeaId: idea.id,
      });
      setEditingComment(null);
    }
  }

  const userId = userData?.id;

  let color = isExternal || isContributor ? '#009FA3' : '#637AF1';

  const pluralize = (count, label) => {
    return `${count} ${label}${!count || count > 1 ? 's' : ''}`;
  };

  return (
    <>
      <Row className="mb-3">
        <Col>
          <Form.Control
            className="mb-3"
            placeholder="Title..."
            value={newIdeaTitle}
            onChange={(e) => handleOnChangeNewIdeaTitle(e)}
          />
          <Form.Control
            as="textarea"
            rows="3"
            placeholder="Summary..."
            value={newIdeaDescription}
            onChange={(e) => handleOnChangeNewIdeaDescription(e)}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="d-flex justify-content-end">
          <Button onClick={addIdea} variant="primary">
            Save
          </Button>
        </Col>
      </Row>
      {touchpointIdeas
        ?.sort(
          (a, b) =>
            b.touchpointIdeaVotes?.length - a.touchpointIdeaVotes?.length ||
            new Date(b.dateCreated) - new Date(a.dateCreated)
        )
        .map((item) => (
          <Row key={item.id} className="mb-3 ideas-section">
            <Col className="d-flex">
              <Avatar
                avatar={item.avatar}
                fullName={item.ownerName}
                backgroundColor={color}
                className="mr-3 flex-shrink-0"
              />
              <div className="w-100">
                <div className="d-flex justify-content-between align-content-center">
                  <div className="header mb-2">{item.ownerName}</div>
                  <div className="date">{formatDateString(item.dateCreated, { timeZone: 'UTC' }).date}</div>
                </div>
                <div className="mb-1">
                  {editingIdea !== item ? (
                    <span className="header">{item.title}</span>
                  ) : (
                    <Form.Control
                      className="mb-3"
                      placeholder="Title required..."
                      value={item.title}
                      onChange={(e) => handleTitleChange(e, item)}
                    />
                  )}
                </div>
                <div className="mb-3">
                  {editingIdea !== item ? (
                    `${item.description}`
                  ) : (
                    <Form.Control
                      as="textarea"
                      rows="3"
                      placeholder="Description..."
                      value={item.description}
                      onChange={(e) => handleDescriptionChange(e, item)}
                    />
                  )}
                </div>
                {editingIdea === item && (
                  <div className="mb-3 d-flex justify-content-end">
                    <Button
                      onClick={() => {
                        updateIdea(item);
                      }}
                      variant="primary"
                    >
                      Save
                    </Button>
                  </div>
                )}
                <Row>
                  <Col
                    xs={{ span: 12, order: 2 }}
                    md={{ span: 6, order: 1 }}
                    className="d-flex mb-3 small-info"
                  >
                    <div className="mr-3">{pluralize(item.touchpointIdeaVotes?.length || 0, 'like')}</div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        if (showComments !== item) {
                          setShowComments(item);
                        } else {
                          setShowComments(null);
                        }
                      }}
                    >
                      {pluralize(item.touchpointIdeaComments?.length || 0, 'comment')}
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 12, order: 1 }}
                    md={{ span: 6, order: 2 }}
                    className="buttons d-flex align-content-center mb-2 small-info mb-3"
                  >
                    <div className="d-flex">
                      <FontAwesomeIcon
                        className={
                          'mr-4 date' +
                          (item.touchpointIdeaVotes?.filter((vote) => vote.userTenantId === userId).length > 0
                            ? ' highlight'
                            : ' light')
                        }
                        icon={faThumbsUp}
                        onClick={() => {
                          toggleLike(item);
                        }}
                      />
                      <FontAwesomeIcon
                        className={'light' + (userId === item.ownerId || roleAtleast(ADMIN) ? ' mr-4' : '')}
                        icon={faComment}
                        onClick={() => {
                          if (ideaBeingCommented !== item) {
                            setShowComments(item);
                            setIdeaBeingCommented(item);
                          } else {
                            setShowComments(null);
                            setIdeaBeingCommented(null);
                          }
                        }}
                      />
                      {(userId === item.ownerId || roleAtleast(ADMIN)) && (
                        <FontAwesomeIcon
                          className="light"
                          onClick={() => {
                            if (editingIdea === item) {
                              setEditingIdea(null);
                            } else {
                              setEditingIdea(item);
                            }
                          }}
                          fixedWidth
                          icon={faPencil}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                {item.touchpointIdeaComments &&
                  showComments === item &&
                  item.touchpointIdeaComments.map((comment) => (
                    <Row key={comment.id} className="mb-3">
                      <Col xs={11}>
                        <span className="header mr-2">{comment.commenterName}</span>
                        {editingComment === comment ? (
                          <Form.Control
                            className="mb-3"
                            placeholder="Text required..."
                            value={comment.text}
                            onChange={(e) => handleCommentTextChange(e, comment)}
                            onBlur={() => updateComment(comment, item)}
                            onKeyPress={(event) => event.key === 'Enter' && updateComment(comment, item)}
                          />
                        ) : (
                          <span>{comment.text}</span>
                        )}
                      </Col>
                      <Col xs={1}>
                        {userId === comment.commenterId && (
                          <FontAwesomeIcon
                            className="light buttons"
                            onClick={() => {
                              if (editingComment === comment) {
                                setEditingComment(null);
                              } else {
                                setEditingComment(comment);
                              }
                            }}
                            fixedWidth
                            icon={faPencil}
                          />
                        )}
                      </Col>
                    </Row>
                  ))}
                {ideaBeingCommented === item && (
                  <div>
                    <Form.Control
                      placeholder="Comment..."
                      value={newComment}
                      onChange={(e) => handleOnChangeNewComment(e)}
                      onBlur={() => addComment(item)}
                      onKeyPress={(event) => event.key === 'Enter' && addComment(item)}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        ))}
    </>
  );
}
