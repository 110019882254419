import React, { useContext } from 'react';
import Select from 'react-select';
import { useFormikContext, Field, ErrorMessage, FieldArray } from 'formik';
import * as uuid from 'uuid';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Form } from 'react-bootstrap';
import Checkbox from 'components/Checkbox/Checkbox';
import { ErrorMessageContainer } from 'views/CustomFields/Account/Styles';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import {
  FIELD_TYPES,
  NEW_STAGEBASE_FIELD,
  PLACEHOLDERS,
  FIELDS,
  CUSTOM_FIELD_MODES,
  CUSTOM_FIELDS_MODAL_VARIATIONS,
} from 'constants';
import SortableItem from './SortableItem';
import { currencyList } from './fixtures';
import { DropdownContainer, FieldValuesContainer, SectionLabel } from './Styles';

const Custom = ({
  onFieldLoad,
  LoadedField,
  sortingContext,
  isRequiredError,
  onFieldFocus,
  renderOptionLabel,
  hasFieldModal,
  isVariation,
}) => {
  const { values } = useFormikContext();
  const { options } = useContext(CustomFieldsContext);

  const { showFieldLabel, showFieldType, fieldType, fieldValues, dataSource, fieldMode, isProtected } =
    values;
  const { sensors, handleDragEnd } = sortingContext;

  const isNewStagebaseField = dataSource?.value === NEW_STAGEBASE_FIELD;
  const isEdit = fieldMode === CUSTOM_FIELD_MODES.EDIT;
  const isDefaultField = isEdit && isProtected;

  const onChangeFieldLabel = (form) => (event) => {
    const { value } = event.target;

    form.setFieldError(FIELDS.FIELD_LABEL, '');
    form.setFieldValue(FIELDS.FIELD_LABEL, value);
    onFieldLoad(FIELDS.SHOW_FIELD_TYPE);
  };

  const onChangeFieldType = (form) => (args) => {
    form.setFieldValue(FIELDS.FIELD_TYPE, args);
    form.setFieldValue(FIELDS.CURRENCY, null);
    form.setFieldValue(FIELDS.IS_SORTED_FIELD_VALUES, false);
    form.setFieldValue(FIELDS.FIELD_VALUES, [{ id: uuid.v4(), value: '' }]);

    const hasSubfield = [
      FIELD_TYPES.CURRENCY,
      FIELD_TYPES.SINGLE_DROPDOWN,
      FIELD_TYPES.MULTI_DROPDOWN,
    ].includes(args.value);

    if (!hasSubfield)
      isVariation(CUSTOM_FIELDS_MODAL_VARIATIONS.CUSTOMER) ||
      isVariation(CUSTOM_FIELDS_MODAL_VARIATIONS.CONTACTS)
        ? onFieldLoad(FIELDS.SHOW_SECTION)
        : onFieldLoad(FIELDS.SHOW_CHECKBOXES);
  };

  const onChangeCurrency = (form) => (args) => {
    form.setFieldValue(FIELDS.CURRENCY, args);

    isVariation(CUSTOM_FIELDS_MODAL_VARIATIONS.CUSTOMER) ||
    isVariation(CUSTOM_FIELDS_MODAL_VARIATIONS.CONTACTS)
      ? onFieldLoad(FIELDS.SHOW_SECTION)
      : onFieldLoad(FIELDS.SHOW_CHECKBOXES);
  };

  const onChangeSortedField = (form) => (event) => {
    const checked = event.target.checked;
    form.setFieldValue(FIELDS.IS_SORTED_FIELD_VALUES, checked);

    if (!!checked) {
      const fieldList = [...fieldValues];
      const lastElement = fieldList.splice(-1);
      const sortedFieldValues = fieldList.sort((a, b) => a.value.localeCompare(b.value));

      form.setFieldValue(FIELDS.FIELD_VALUES, [...sortedFieldValues, ...lastElement]);
    }
  };

  if (!isNewStagebaseField) return null;

  return (
    <>
      <LoadedField isVisible={showFieldLabel}>
        <Form.Label>Field Label</Form.Label>

        <div className="field-label">
          <Field name={FIELDS.FIELD_LABEL}>
            {({ form, meta, ...props }) => (
              <Form.Control
                type="text"
                value={meta.value}
                disabled={isDefaultField}
                placeholder={PLACEHOLDERS.FIELD_LABEL}
                onChange={onChangeFieldLabel(form)}
                onBlur={onFieldFocus(form, FIELDS.FIELD_LABEL)}
                {...props}
              />
            )}
          </Field>
        </div>

        {!isRequiredError(FIELDS.FIELD_LABEL) && (
          <ErrorMessageContainer>
            <ErrorMessage name={FIELDS.FIELD_LABEL} />
          </ErrorMessageContainer>
        )}
      </LoadedField>

      <LoadedField isVisible={showFieldType}>
        <DropdownContainer>
          <Form.Label>Field Type</Form.Label>

          <Field name={FIELDS.FIELD_TYPE}>
            {({ field, form }) => (
              <Select
                value={field.value}
                classNamePrefix="select"
                placeholder={PLACEHOLDERS.FIELD_TYPE}
                isSearchable={false}
                isDisabled={isEdit}
                onChange={onChangeFieldType(form)}
                getOptionLabel={renderOptionLabel}
                onFocus={onFieldFocus(form, FIELDS.FIELD_TYPE)}
                options={options.fieldTypeList}
              />
            )}
          </Field>
        </DropdownContainer>

        {(fieldType?.response === FIELD_TYPES.CURRENCY || fieldType?.value === FIELD_TYPES.CURRENCY) && (
          <SectionLabel>
            <DropdownContainer>
              <Field name={FIELDS.CURRENCY}>
                {({ field, form }) => (
                  <Select
                    value={field.value}
                    classNamePrefix="select"
                    placeholder={PLACEHOLDERS.CURRENCY}
                    isSearchable={false}
                    isDisabled={isEdit}
                    onChange={onChangeCurrency(form)}
                    onFocus={onFieldFocus(form, FIELDS.CURRENCY)}
                    options={currencyList}
                  />
                )}
              </Field>
            </DropdownContainer>
          </SectionLabel>
        )}

        {(fieldType?.value === FIELD_TYPES.SINGLE_DROPDOWN ||
          fieldType?.value === FIELD_TYPES.MULTI_DROPDOWN) && (
          <FieldValuesContainer>
            <div className="field-values__header">
              <Form.Label>Field Values</Form.Label>

              <Field name={FIELDS.IS_SORTED_FIELD_VALUES}>
                {({ field, form }) => (
                  <Checkbox
                    checked={field.value}
                    label={PLACEHOLDERS.IS_SORTED_FIELD_VALUES}
                    name={FIELDS.IS_SORTED_FIELD_VALUES}
                    onChange={onChangeSortedField(form)}
                  />
                )}
              </Field>
            </div>

            <div className="field-values__body">
              <FieldArray
                name={FIELDS.FIELD_VALUES}
                render={({ move, ...arrayHelpers }) => (
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={(event) => handleDragEnd(event, move)}
                  >
                    <SortableContext items={fieldValues} strategy={verticalListSortingStrategy}>
                      {fieldValues?.map((item, index) => {
                        const handlers = {
                          ...arrayHelpers,
                          onFieldLoad,
                          isVariation,
                        };

                        const state = {
                          id: item?.id ?? item?.response,
                          hasFieldModal,
                          index,
                        };

                        return <SortableItem key={state?.id} {...state} {...handlers} />;
                      })}
                    </SortableContext>
                  </DndContext>
                )}
              />
            </div>
          </FieldValuesContainer>
        )}
      </LoadedField>
    </>
  );
};

export default Custom;
