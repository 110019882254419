import React, { useState, useRef } from 'react';
import { FormControl } from 'react-bootstrap';
import { debounce } from '../helpers/CommonHelper';

const Search = ({ onSearch, hasMarginBottom = true }) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useRef(debounce(onSearch, 300), []);

  function handleOnChangeSearch(e) {
    setSearch(e.target.value);
    debouncedSearch.current(e.target.value);
  }

  return (
    <FormControl
      placeholder="Search"
      value={search}
      onChange={handleOnChangeSearch}
      className={`${hasMarginBottom && 'mb-2-5'} `}
    />
  );
};

export default Search;
