import { Button } from 'react-bootstrap';
import styled, { css } from 'styled-components';

export const TabSelector = styled.span`
  display: flex;
  flex-wrap: wrap;
`;

export const TabButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;

  span {
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--playbook-pewter);
    margin-bottom: 0.375rem;
  }

  span.saving {
    animation: fadeIn 0.5s;
  }

  span.auto-saved {
    animation: fadeOut 2s normal 0.5s;
  }

  span.error {
    color: var(--danger);
  }

  .btn-add {
    position: relative;
    top: -0.5rem;
  }

  .btn-preview {
    background-color: transparent;
    font-weight: bold;
    font-size: 0.938rem;
    color: var(--implement-iron);
    position: relative;
    top: -0.2rem;
    padding: 0.4rem 1rem;
    margin-right: 1rem;
  }

  .dropdown-menu {
    border: 0;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }

  .dropdown-menu.show {
    transform: translate3d(0px, 26px, 0px) !important;
    inset: 0px 0px auto auto !important;
    margin-top: 0.625rem !important;
  }

  .dropdown-item {
    padding: 0.625rem 1.5rem;
  }
`;

export const AccountBodyContainer = styled.div`
  svg[data-icon='grip-vertical'] {
    color: var(--playbook-pewter);
    cursor: grabbing;
  }

  ${({ hasSectionMargin }) =>
    hasSectionMargin &&
    css`
      section:not(:first-of-type) {
        margin-top: 1.25rem;
      }
    `}

  section:not(:last-child) {
    ${({ hasDivider }) => hasDivider && 'border-bottom: 1px solid var(--border-gray);'}
  }
`;

export const ErrorMessageContainer = styled.div`
  color: var(--danger);
`;

export const AddFieldsButton = styled(Button)`
  margin-top: -1rem;
`;
