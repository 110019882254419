import React from 'react';
import '@nylas/components-mailbox';
import { NoDataTextStyle } from '../reporting/SharedStyles';

const CustomerInbox = ({ contactEmails, nylasToken }) => {
  if (!contactEmails || !nylasToken) return <NoDataTextStyle>Your Mailbox is empty!</NoDataTextStyle>;

  return (
    <nylas-mailbox
      id={sessionStorage.nylasMailboxId}
      access_token={nylasToken}
      query_string={`any_email=${contactEmails}`}
      show_star={true}
    ></nylas-mailbox>
  );
};

export default CustomerInbox;
