import * as Yup from 'yup';
import { VALID_SUBDOMAIN_ERROR_MESSAGE } from '../fixtures';

export const subdomainSchema = Yup.object().shape({
  subdomainName: Yup.string()
    .required(VALID_SUBDOMAIN_ERROR_MESSAGE)
    .min(1, VALID_SUBDOMAIN_ERROR_MESSAGE)
    .max(32, VALID_SUBDOMAIN_ERROR_MESSAGE)
    .matches(/^[a-zA-Z0-9]+$/, VALID_SUBDOMAIN_ERROR_MESSAGE),
});
