import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useField, ErrorMessage } from 'formik';
import { faCalendar, faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flatpickr from 'react-flatpickr';
import { ErrorMessageContainer } from 'views/CustomFields/Account/Styles';
import useUpdateEffect from 'helpers/hooks/useUpdateEffect';
import { dateOptions, timeOptions } from './fixtures/options';
import { DateTimeContainer, FlatPickrContainer } from './Styles';
import { FieldLabel } from 'components/Modals/Customers/Styles';

const DateTimePicker = ({ name, dateProps, disabled, handleChange, fieldLabel, isRequired, timeProps }) => {
  const [, meta, helpers] = useField(name);

  const [value, setValue] = useState(meta.value || meta.initialValue);

  const dateTimeObjectError = meta.error ? Object.values(meta.error)[0] : '';

  const dateRef = useRef(null);
  const timeRef = useRef(null);

  useEffect(() => {
    if (disabled) {
      dateRef.current.flatpickr._input.disabled = true;
      timeRef.current.flatpickr._input.disabled = true;
    } else {
      dateRef.current.flatpickr._input.disabled = false;
      timeRef.current.flatpickr._input.disabled = false;
    }
  }, [disabled]);

  /**
   * Did not include "helpers" in the dependency array
   * since it will cause an infinite render.
   **/
  useEffect(() => {
    helpers.setValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  /**
   * Prevent's useEffect to trigger on initial mount
   * since this might cause issues in debouncing the
   * handleChange
   **/
  useUpdateEffect(() => {
    if (handleChange) handleChange(value);
  }, [handleChange, value]);

  /**
   * On Date Change handler
   * @params dates
   * @returns void
   */
  const onDateChange = useCallback(
    (dates) => {
      const selectedDate = new Date(dates?.[0]).toISOString();

      setValue((prev) => {
        const defaultTime = new Date().toISOString().split('T')[1];
        const splittedDate = selectedDate.split('T')[0];
        const splittedTime = prev?.time ? prev?.time.toISOString().split('T')[1] : defaultTime;

        return {
          ...prev,
          date: new Date(selectedDate),
          dateTime: `${splittedDate}T${splittedTime}`,
        };
      });

      helpers.setError('');
      helpers.setTouched(true);
    },
    [helpers]
  );

  /**
   * On Time Change handler
   * @params dates
   * @returns void
   */
  const onTimeChange = useCallback(
    (dates) => {
      const selectedTime = new Date(dates?.[0]).toISOString();

      setValue((prev) => {
        const defaultDate = new Date().toISOString().split('T')[0];
        const splittedTime = selectedTime.split('T')[1];
        const splittedDate = prev?.date ? prev?.date.toISOString().split('T')[0] : defaultDate;

        return {
          ...prev,
          time: new Date(selectedTime),
          dateTime: `${splittedDate}T${splittedTime}`,
        };
      });

      helpers.setError('');
      helpers.setTouched(true);
    },
    [helpers]
  );

  const hasError = useMemo(() => meta.touched && !!meta.error, [meta.error, meta.touched]);

  return (
    <>
      {fieldLabel && (
        <FieldLabel>
          <span>{fieldLabel}</span>
          {isRequired && !hasError && <span>*</span>}
          {hasError && (
            <ErrorMessageContainer className="error-container">
              {typeof meta.error !== 'object' ? (
                <>({<ErrorMessage name={name} />})</>
              ) : (
                <div>({dateTimeObjectError})</div>
              )}
            </ErrorMessageContainer>
          )}
        </FieldLabel>
      )}

      <DateTimeContainer>
        <FlatPickrContainer>
          <Flatpickr
            ref={dateRef}
            className="form-control"
            value={value?.date}
            onChange={onDateChange}
            options={dateOptions}
            {...dateProps}
          />
          <FontAwesomeIcon icon={faCalendar} />
        </FlatPickrContainer>

        <FlatPickrContainer>
          <Flatpickr
            ref={timeRef}
            className="form-control"
            value={value?.time}
            onChange={onTimeChange}
            options={timeOptions}
            {...timeProps}
          />
          <FontAwesomeIcon icon={faClock} />
        </FlatPickrContainer>
      </DateTimeContainer>
    </>
  );
};

export default React.memo(DateTimePicker);
