import { dateMixin } from 'mixins';
import * as Api from 'api/Api';
import * as STATUS from 'constants/status';

class Touchpoint extends dateMixin() {
  assignedUsers = null;
  attachments = [];
  canvasId = null;
  canvasName = null;
  customerCanvasId = null;
  customerFacing = false;
  customerId = null;
  customerJourneyId = null;
  customerTouchpointDependentId = null;
  // TODO: Define object props or create separate class
  customerTouchpointDependents = [];
  dateIsScheduled = false;
  dateTimeCompleted = null;
  dateToBeScheduled = false;
  dependencyAdded = false;
  description = '';
  displayDate = null;
  durationAdded = false;
  // TODO: Define constants
  durationOption = '';
  durationOptionAmount = null;
  // TODO: Define constants
  emailAudience = null;
  emailSentDate = null;
  emailTrigger = null;
  endDate = '';
  id = null;
  links = [];
  noDueDate = false;
  notes = null;
  order = 0;
  pastDue = false;
  projectedDate = '';
  scheduledDate = null;
  startDate = '';
  status = '';
  tasks = [];
  templateTouchpointId = null;
  title = '';
  touchpointAttachments = [];
  touchpointDependents = null;
  touchpointId = null;
  touchpointIdeas = [];
  touchpointLinks = [];
  touchpointTags = [];
  triggerEmailBody = null;
  triggerEmailSent = false;
  triggerEmailSubject = null;
  // TODO: Define constants
  type = 'Touchpoint';

  constructor(props) {
    super(props);

    if (props) {
      Object.assign(this, props);
    }
  }

  get isScheduledType() {
    return this.dateToBeScheduled;
  }

  get isScheduled() {
    return this.isScheduledType && this.dateIsScheduled;
  }

  get isComplete() {
    return this.status === STATUS.COMPLETED;
  }

  get isIncomplete() {
    return this.status !== STATUS.COMPLETED;
  }

  get isNotStarted() {
    return this.status === STATUS.NOT_STARTED;
  }

  get hasDependents() {
    return this.customerTouchpointDependents && !!this.customerTouchpointDependents.length;
  }

  get hasIncompleteTask() {
    return this.tasks.some((task) => !task.completed);
  }

  get hasTask() {
    return this.tasks && !!this.tasks.length;
  }

  static async addStep(payload) {
    return await Api.post('Project/AddStep', payload);
  }

  static async deleteStep(stepId) {
    return await Api.post('Project/DeleteStep', { CustomerTouchpointId: stepId });
  }

  static async reorderStep(stepId, order, destinationId) {
    return await Api.post('Project/ReorderStep', {
      CustomerTouchpointId: stepId,
      Order: order,
      DestinationCustomerJourneyId: destinationId,
    });
  }

  static async toggleCustomerFacing(payload) {
    return await Api.post('Touchpoint/EditCustomerTouchpoint', payload);
  }

  static async keepCustomerFacing(payload) {
    return await Api.post('Touchpoint/EditCustomerTouchpoint', payload);
  }

  static async removeCustomerFacing(payload) {
    return await Api.post('Touchpoint/EditCustomerTouchpoint', payload);
  }

  static async duplicateTouchpoint(touchpointId) {
    const response = await Api.post(`Touchpoint/DuplicateTouchpoint`, null, {
      params: {
        touchpointId,
      },
    });

    return response;
  }

  static async duplicateStep(touchpointId) {
    const response = await Api.post(`Project/DuplicateStep`, null, {
      params: {
        customerTouchpointId: touchpointId,
      },
    });

    return response;
  }
}

export default Touchpoint;
