import React from 'react';
import { ContentDiv } from './useStyle';

const Content = ({ message, align }) => (
  message !== undefined && <ContentDiv className="mb-0" textAlign={align}>
    {message}
  </ContentDiv>
);

export default Content;
