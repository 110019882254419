import styled, { css } from 'styled-components';

export const MembersContainer = styled.div`
  .member-avatar {
    height: 3.25rem;
    width: 3.25rem;
    font-size: 1.25rem;
    text-transform: uppercase;
  }

  .txt-all-steps,
  .all-steps {
    margin-top: 0.125rem;
  }

  ${({ hasMarginTop }) =>
    hasMarginTop &&
    css`
      margin-top: 2.25rem;
    `}
`;

export const Tag = styled.div`
  border-color: ${({ r, g, b }) => `rgba(${r},${g},${b})`} !important;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${({ r, g, b }) => `rgba(${r},${g},${b})`} !important;
      color: var(--white);
    `}
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  .tag {
    border-radius: 1.875rem;
    border: 1px solid black;
    background-color: white;
    padding: 0.063rem 0.5rem;
    font-size: 0.688rem;
    margin: 0 0.375rem 0.375rem 0;
    display: flex;
    align-items: center;
  }

  ${({ isCollapsed }) =>
    !isCollapsed &&
    css`
      max-height: 1.656rem;
      overflow: hidden;
    `}
`;

export const EmptyTagsContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;

  ${({ isAddMembers }) =>
    isAddMembers
      ? css`
          & > div {
            position: absolute;
            right: -0.5rem;
            top: -0.125rem;
          }
        `
      : css`
          gap: 0.75rem;
        `}

  span {
    color: var(--playbook-pewter);
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.188rem;
  }
`;
