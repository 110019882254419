import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useField, ErrorMessage } from 'formik';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flatpickr from 'react-flatpickr';
import { ErrorMessageContainer } from 'views/CustomFields/Account/Styles';
import { dateOptions } from './fixtures/options';
import { FlatPickrContainer } from './Styles';
import { FieldLabel } from 'components/Modals/Customers/Styles';

const DatePicker = ({ name, disabled, handleChange, fieldLabel, isRequired, dateProps }) => {
  const [, meta, helpers] = useField(name);

  const [value, setValue] = useState(meta.value || meta.initialValue);

  const options = dateOptions;

  const dateRef = useRef(null);

  useEffect(() => {
    setValue(meta.value);
  }, [meta.value]);

  useEffect(() => {
    disabled
      ? (dateRef.current.flatpickr._input.disabled = true)
      : (dateRef.current.flatpickr._input.disabled = false);
  }, [disabled]);

  /**
   * On Date Change handler
   * @params dates
   * @returns void
   */
  const onDateChange = useCallback(
    (dates) => {
      const selectedDate = new Date(dates?.[0]).toISOString();

      setValue(new Date(selectedDate));

      helpers.setError('');
      helpers.setValue(selectedDate);
      helpers.setTouched(true);

      if (handleChange) handleChange(selectedDate);
    },
    [helpers, handleChange]
  );

  const hasError = useMemo(() => meta.touched && !!meta.error, [meta.error, meta.touched]);

  return (
    <>
      {fieldLabel && (
        <FieldLabel>
          <span>{fieldLabel}</span>
          {isRequired && !hasError && <span>*</span>}
          {hasError && (
            <ErrorMessageContainer className="error-container">
              (<ErrorMessage name={name} />)
            </ErrorMessageContainer>
          )}
        </FieldLabel>
      )}

      <FlatPickrContainer>
        <Flatpickr
          ref={dateRef}
          className="form-control"
          value={value}
          options={options}
          onChange={onDateChange}
          {...dateProps}
        />
        <FontAwesomeIcon icon={faCalendar} />
      </FlatPickrContainer>
    </>
  );
};

export default React.memo(DatePicker);
