import React from 'react';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getOrgName } from '../helpers/CommonHelper';
import LoadingInline from './Loading/LoadingInline';
import styled, { css } from 'styled-components';
import { Col, Form, Row } from 'react-bootstrap';

const HeaderDivider = styled.div`
  position: sticky;
  left: 0;
`;

const MobileWrapper = styled.div`
  padding: 0;
  margin: 24px 0;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '24px')};
  @media screen and (max-width: 426px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledHeader = styled.h2`
  color: inherit;
`;

const TitleHeader = styled.h1`
  font-size: ${(props) => props.fontSize};
`;

const HeaderContainer = styled.div`
  ${({ $fullTitleWidth }) =>
    !$fullTitleWidth &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}
`;

export default function PageHeader({
  showOrgName,
  upPath,
  upName,
  className,
  title,
  children,
  loading,
  border,
  subRow,
  titleSize,
  marginBottom,
  editingTitle,
  toggleEditTitleOff,
  onTitleClick,
  onTitleChange,
  subText,
  fullTitleWidth = false,
  ...props
}) {
  return (
    <>
      <MobileWrapper
        className={'page-header' + (className ? ` ${className}` : '')}
        marginBottom={marginBottom}
        {...props}
      >
        <div className="flex-fill">
          {upPath && upName && (
            <div className=" mx-4">
              <Row>
                <Col xs={12} md={6} lg={8} xl={9} className="p-0 mt-1">
                  <Link to={upPath} className="d-inline-block align-top align-items-center mb-2">
                    <StyledHeader>
                      <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                      {upName}
                    </StyledHeader>
                  </Link>
                </Col>
              </Row>
            </div>
          )}
          <h2 className="mb-2">{showOrgName && getOrgName() ? getOrgName() : ''}</h2>
          {!editingTitle ? (
            <HeaderContainer $fullTitleWidth={fullTitleWidth}>
              <TitleHeader className="mr-3 toggle-edit-nonedit" fontSize={titleSize} onClick={onTitleClick}>
                {title}
              </TitleHeader>

              {subText && <div>{subText}</div>}
            </HeaderContainer>
          ) : (
            <Form.Control
              as="textarea"
              rows="3"
              className="no-border editable-header"
              placeholder="Unnamed Canvas"
              autoFocus
              value={title}
              onChange={onTitleChange}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  toggleEditTitleOff();
                }
              }}
              onBlur={() => toggleEditTitleOff()}
            ></Form.Control>
          )}
        </div>
        {children}
      </MobileWrapper>
      {subRow}
      {loading ? (
        <LoadingInline />
      ) : (
        border && (
          <HeaderDivider>
            <hr className="my-0 extend-to-edge" />
          </HeaderDivider>
        )
      )}
    </>
  );
}

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  showOrgName: PropTypes.bool,
  upPath: PropTypes.string,
  upName: PropTypes.string,
  border: PropTypes.bool,
  className: PropTypes.string,
};
