import * as Api from 'api/Api';
import { REPLACE_OPERATION, OBJECT_TYPE_ENUM } from 'constants';

class Settings {
  id = null;
  lastUsed = null;
  token = '';

  constructor(props) {
    if (props) Object.assign(this, props);
  }

  /**
   * API Call for GetIntegrationApiTokens
   * @param none
   * @returns Promise<any>
   */
  static async getIntegrationApiTokens() {
    return await Api.get('Settings/GetIntegrationApiTokens');
  }

  /**
   * API Call for GenerateIntegrationApiToken
   * @param none
   * @returns Promise<Settings>
   */
  static async addIntegrationApiToken() {
    const response = await Api.post('Settings/GenerateIntegrationApiToken');

    return new Settings(response);
  }

  /**
   * API Call for DeleteIntegrationApiToken
   * @param id
   * @returns Promise<any>
   */
  static async deleteIntegrationApiToken(id) {
    return await Api.post('Settings/DeleteIntegrationApiToken', { id });
  }

  /**
   * API Call for UpdateLastUsedIntegrationApiToken
   * @param id
   * @returns Promise<Settings>
   */
  static async updateLastUsedIntegrationApiToken(id) {
    const response = await Api.post('Settings/UpdateLastUsedIntegrationApiToken', { id });

    return new Settings(response);
  }

  static async updateProfile(id, data) {
    const { field, value } = data;

    const request = [
      {
        operationType: OBJECT_TYPE_ENUM.PROFILE,
        path: field,
        op: REPLACE_OPERATION,
        value,
      },
    ];

    const response = await Api.patch(`Settings/PartialProfileUpdate`, request, {
      params: { guid: id },
    });

    return response;
  }
  /**
   * API Call for uploading logo inside the setting - general page
   * @param files - image content to be uploaded
   * @returns Promise<Settings>
   */
  static async uploadLogo(files) {
    const response = await Api.postFiles('Settings/UploadAppSettingsLogo', files, {
      rawResponse: true,
    });
    return response;
  }

  /**
   * API Call for getting permissions in settings page
   * @returns Promise<Settings>
   */
  static async getSettingsPermissions() {
    const response = await Api.get('Settings/GetPermissions');
    return response;
  }

  /**
   * API Call for getting the states of the switches of the general tab
   * @returns Promise<Settings>
   */
  static async getGeneralConfig() {
    const response = await Api.get('Settings/GetGeneralPermissions?role=FRONT_LINE');
    return response;
  }

  /**
   * API Call for getting the states of the email tab
   * @returns Promise<Settings>
   */
  static async getEmailConfig() {
    const response = await Api.get('Template/GetByViewOption?option=setting');
    return response;
  }

  /**
   * API Call for getting the email template by Id
   * @returns Promise<Settings>
   */
  static async getEmailTemplateById(id) {
    const response = await Api.get(`Template/GetById?id=${id}`);
    return response;
  }

  /**
   * API Call for reverting the email template by Id
   * @returns Promise<Settings>
   */
  static async revertEmailTemplateById(id) {
    const response = await Api.get(`Template/Revert?id=${id}`);
    return response;
  }

  /**
   * API Call for updating the email template by Id
   * @returns Promise<Settings>
   */
  static async editEmailTemplateById(id, subject, content) {
    const payload = [
      {
        op: 'replace',
        path: '/subject',
        value: subject,
      },
      {
        op: 'replace',
        path: '/content',
        value: content,
      },
    ];
    const response = await Api.patch(`Template/PartialUpdate?id=${id}`, payload);
    return response;
  }

  /**
   * API Call for previewing the email template by Id
   * @returns Promise<Settings>
   */
  static async previewEmailTemplateById(id, subject, content) {
    const payload = {
      subject,
      content,
    };
    const response = await Api.post(`Template/Preview?id=${id}`, payload);
    return response;
  }

  /**
   * API Call for updating permissions in general tab
   * @returns Promise<Settings>
   */
  static async editGeneralPermissions(value, name) {
    const response = await Api.post(`Settings/UpdateGeneralPermission?permission=${name}&grant=${value}`);
    return response;
  }

  /**
   * API Call for getting the states of the email preferences
   * @returns Promise<Settings>
   */
  static async getEmailPreferences() {
    const response = await Api.get('Settings/GetEmailPreferences');
    return response;
  }

  /**
   * API Call for updating email preferences in profile page
   * @returns Promise<Settings>
   */
  static async editEmailPreferences(value, name) {
    const response = await Api.post(`Settings/UpdateEmailPreference?preference=${name}&optIn=${value}`);
    return response;
  }

  /**
   * API Call for updating App Settings
   * @returns Promise<Settings>
   */
  static async UpdateAppSettings(appSettings) {
    const response = await Api.post(`Settings/UpdateAppSettings`, appSettings);
    return response;
  }

  /**
   * API Call for fetching the App Settings
   * @returns Promise<Settings>
   */
  static async getAppSettings() {
    const response = await Api.get(`Settings/GetAppSettings`);
    return response;
  }

  /**
   * API Call for fetching the logged in user's profile
   * @returns Promise<Settings>
   */
  static async getUserProfile() {
    const response = await Api.get(`Settings/GetUserProfile`);
    return response;
  }

  /**
   * API Call for retrieving the subdomain
   * @returns Promise<Settings>
   */
  static async getSubdomain() {
    const response = await Api.get(`Settings/GetSubdomain`);
    return response;
  }

  /**
   * API Call for updating the subdomain
   * @returns Promise<Settings>
   */
  static async updateSubdomain(payload) {
    const response = await Api.patch(`Settings/PartialSubdomainUpdate`, payload);
    return response;
  }
}

export default Settings;
