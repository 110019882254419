import React, { useEffect, useCallback, useContext } from 'react';
import {
  DndContext,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import EditableField from 'components/CustomFields/Details/EditableField';
import EditableSection from 'components/CustomFields/Details/EditableSection';
import LoadingInline from 'components/Loading/LoadingInline';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import { AccountBodyContainer } from '../Styles';
import { useDnd } from '../hooks';

const Details = () => {
  const {
    collisionDetectionStrategy,
    containers,
    coordinateGetter,
    handleDragCancel,
    handleDragEnd,
    handleDragOver,
    handleStart,
    items,
    recentlyMovedToNewContainer,
  } = useDnd();

  const { isLoading } = useContext(CustomFieldsContext);

  // DND sensors
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items, recentlyMovedToNewContainer]);

  const renderSection = useCallback(
    (containerId) => {
      return (
        <EditableSection key={containerId} id={containerId} items={items[containerId]}>
          <SortableContext items={items[containerId]} strategy={rectSortingStrategy}>
            {items[containerId].map((value, index) => (
              <EditableField key={value} id={value} index={index} />
            ))}
          </SortableContext>
        </EditableSection>
      );
    },
    [items]
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={handleStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <AccountBodyContainer hasDivider hasSectionMargin>
        <SortableContext items={containers} strategy={verticalListSortingStrategy}>
          {isLoading ? <LoadingInline /> : containers.map((containerId) => renderSection(containerId))}
        </SortableContext>
      </AccountBodyContainer>
    </DndContext>
  );
};

export default Details;
