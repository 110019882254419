import React, { useCallback } from 'react';
import { Alert, Card } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loading from './Loading/Loading';
import logo from '../img/logo.svg';
import styled, { css } from 'styled-components';
import { LocalStorage, SignIn } from 'models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';

const StyledCard = styled(Card)`
  max-width: ${({ maxwidth }) => maxwidth ?? '820px'};
  width: 100%;

  ${({ $showBackButton }) =>
    $showBackButton &&
    css`
      .btn-back {
        position: absolute;
        left: 2.125rem;
        top: 1.75rem;
        color: var(--schedule-sapphire);
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }

      svg {
        color: var(--schedule-sapphire);
        transition: all 0.3s ease-in-out;
        top: 0.063rem;
        position: relative;
      }

      .btn-back:hover {
        color: var(--navy-blue);

        svg {
          color: var(--navy-blue);
        }
      }
    `}

  ${({ $hasLeftContent }) =>
    !$hasLeftContent &&
    css`
      margin: 1.25rem;
    `}
`;

const StyledImg = styled.img`
  max-width: 18.313rem;
  visibility: ${({ showLogo }) => (showLogo ? 'visible' : 'hidden')};
`;

const CardBody = styled(Card.Body)`
  max-width: 440px;
  width: 100%;
`;

export default function FullScreenWithBackground({
  loading,
  maxWidth,
  showLogo = true,
  showFooter = true,
  showBackButton,
  title,
  error,
  children,
  titleClassName,
  leftContent,
}) {
  const location = useLocation();
  const isSelectCompanyActive = location.pathname === '/select-company';
  const isLoginActive = location.pathname === '/login';
  const history = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);

  const handleGetTenantInfo = useCallback(async () => {
    if (!isLoginActive) return;

    setIsLoading(true);

    const { companyLogo: logo, tenantName } = await SignIn.getTenantInfo();

    if (tenantName) document.title = tenantName;

    setIsLoading(false);
    setCompanyLogo(logo);
    LocalStorage.set('subdomainLogo', logo || '');
  }, [isLoginActive]);

  useEffect(() => {
    handleGetTenantInfo();
  }, [handleGetTenantInfo]);

  const memoizedLogo = useMemo(() => {
    if (isLoading && isLoginActive) return null;

    const tenantLogo = companyLogo || LocalStorage.get('tenantLogo') || LocalStorage.get('subdomainLogo');

    return isSelectCompanyActive || !tenantLogo ? logo : tenantLogo;
  }, [companyLogo, isLoading, isLoginActive, isSelectCompanyActive]);

  return (
    <div className={leftContent ? 'full-screen-with-left-content' : 'full-screen-with-background'}>
      {loading ? <Loading showSpinner={true} /> : ''}

      {!!leftContent && <div className="left-content">{leftContent}</div>}

      <StyledCard
        maxwidth={maxWidth}
        $hasLeftContent={!!leftContent}
        $showBackButton={showBackButton}
        className="overflow-hidden right-content position-relative"
      >
        <CardBody className={!!leftContent ? 'pt-5 m-auto' : 'py-5 m-auto'}>
          {showBackButton && (
            <div className="btn-back" onClick={() => history(-1)}>
              <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
              Back
            </div>
          )}

          <div className="text-center">
            <Link to="/">
              {memoizedLogo && (
                <StyledImg
                  data-testid="stagebase-logo"
                  src={memoizedLogo}
                  alt="tenant-logo"
                  showLogo={showLogo && !isLoading}
                />
              )}
            </Link>
            <h1 className={` ${titleClassName ?? 'my-4 py-2'}`}>{title}</h1>
          </div>
          {error ? <Alert variant="danger">{error}</Alert> : ''}
          {children}
        </CardBody>

        {showFooter && (
          <Card.Footer className="border-top text-muted text-center py-4" data-testid="footer">
            ©{new Date().getFullYear()} Stagebase, Inc. All rights reserved.
          </Card.Footer>
        )}
      </StyledCard>
    </div>
  );
}
