import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ErrorMaintenance from 'views/ErrorMaintenance';
import Full from './components/Full';
import './css/App.scss';
import { RouteLogout } from './helpers/RouteHelper';
import ChangePassword from './views/ChangePassword/ChangePassword';
import Page404 from './views/Error404';
import Page500 from './views/Error500';
import Login from './views/Authentication/LoginEmail';
import LoginPassword from './views/Authentication/LoginPassword';
import LoginOTP from 'views/Authentication/LoginOTP';
import SignUp from './views/SignUp';
import ResetPassword from './views/ResetPassword';
import ProcessToken from './views/ProcessToken';
import ConfirmAccount from 'views/ConfirmAccount';

// const Login = lazy(() => import('./views/Login'));
// const ResetPassword = lazy(() => import('./views/ResetPassword'));
// const ChangePassword = lazy(() => import('./views/ChangePassword'));
// const Page404 = lazy(() => import('./views/Error404'));
// const Page500 = lazy(() => import('./views/Error500'));
// const Full = lazy(() => import('./components/Full'));

const App = () => {
  return (
    <Router>
      {/* <Suspense fallback={<div></div>}> */}
      {process.env.REACT_APP_MAINTENANCE ? (
        <ErrorMaintenance />
      ) : (
        <Routes>
          <Route path="/login" name="Login" element={<Login />} />
          <Route path="/login-password" name="LoginPassword" element={<LoginPassword />} />
          <Route path="/login-otp" name="LoginPassword" element={<LoginOTP />} />
          <Route path="/signup" name="SignUp" element={<SignUp />} />

          <Route path="/external-token" element={<ProcessToken />} />
          <Route exact path="/reset-password" name="Reset Password" element={<ResetPassword />} />
          <Route
            exact
            path="/create-account"
            name="Create Account"
            element={<ChangePassword create={true} />}
          />
          <Route exact path="/confirm-account" name="Confirm Account" element={<ConfirmAccount />} />
          <Route exact path="/change-password" name="Change Password" element={<ChangePassword />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="/logout" element={<RouteLogout />} />
          <Route path="*" name="Home" element={<Full />} />
        </Routes>
      )}
      {/* </Suspense> */}
    </Router>
  );
};

export default App;
