export const ACTIVE = 'Active';

export const FIELD = 'field';
export const BASE_FIELD = 'baseField';
export const PROFILE_FIELD = 'profileField';
export const CONTACTS_FIELD = 'contactsField';
export const PROJECTS_FIELD = 'projectsField';
export const SECTION = 'section';

export const CUSTOM = 'custom';
export const DEFAULT_CATEGORY = 'default';

export const NEW_SECTION = 'New Section';
export const NEW_STAGEBASE_FIELD = 'New Stagebase Field';

export const DETAILS = 'details';
export const CONTACTS = 'contacts';
export const PROJECTS = 'projects';
export const PROFILE_SOURCE = 'profile';

export const ACCOUNT = 'account';

export const CUSTOMER = 'CUSTOMER';
export const PROFILE = 'PROFILE';
export const REPLACE_OPERATION = 'replace';

export const REMOVE_OLD_OWNER = 'RemoveOldCustomer';

export const GENERAL = 'General';
export const MOREINFO = 'More information';

export const PROFILE_CUSTOM_SECTION = 'Custom Profile Fields';
export const PROJECTS_CUSTOM_SECTION = 'Custom Projects Fields';

export const IS_SAVING = 'isChangesSaving';
export const HAS_SERVER_ERROR = 'hasServerError';

export const CUSTOM_FIELD_MODES = {
  DEFAULT: 'default',
  EDIT: 'edit',
  ADD: 'add',
};

export const RADIO_GROUP_VALUES = {
  YES: 'Yes',
  NO: 'No',
};

export const FIELD_TYPES = {
  TEXT: 'Text',
  PARAGRAPH: 'Paragraph',
  YES_NO: 'Yes/No',
  EMAIL: 'Email',
  LINK: 'Link',
  SINGLE_DROPDOWN: 'Single-select dropdown',
  MULTI_DROPDOWN: 'Multi-select dropdown',
  TIMEZONE: 'Timezone',
  NUMBER: 'Number',
  CURRENCY: 'Currency',
  SWITCH: 'Switch',
  DATE: 'Date',
  TIME: 'Time',
  DATE_AND_TIME: 'Date and Time',
  DROPDOWN_SINGLE_SELECT: 'DropdownSingleSelect',
  DROPDOWN_MULTI_SELECT: 'DropdownMultiSelect',
  BOOLEAN: 'Boolean',
  DATE_TIME: 'DateTime',
  UNKNOWN: 'Unknown',
};

export const PERMISSIONS = {
  NA: 'NA',
  WONT_SYNC: 'WontSync',
  READ_ONLY: 'ReadOnly',
  SYNCS: 'Syncs',
  READ_AND_WRITE: 'Read and Write',
};

export const ERROR_MESSAGES = {
  DUPLICATE_FIELD: 'Field label already in use',
  DUPLICATE_SECTION: 'Section label already in use',
  EMPTY_FIELD_VALUES: 'Field values should not be empty',
  REQUIRED: 'Required',
  EMAIL: 'Incorrect email format',
};

const FIELD_VISIBILITY_FIELDS = {
  SHOW_FIELD_LABEL: 'showFieldLabel',
  SHOW_FIELD_TYPE: 'showFieldType',
  SHOW_SECTION: 'showSection',
  SHOW_CHECKBOXES: 'showCheckboxes',
  SHOW_PERMISSION: 'showPermission',
  SHOW_EXTERNAL_FIELD: 'showExternalField',
};

const SECTION_FIELDS = {
  SECTION_LABEL: 'sectionLabel',
  SECTION_MODE: 'sectionMode',
  EDITED_SECTION_ID: 'editedSectionId',
};

const CUSTOM_FIELDS = {
  DATA_SOURCE: 'dataSource',
  FIELD_LABEL: 'fieldLabel',
  FIELD_TYPE: 'fieldType',
  FIELD_VALUES: 'fieldValues',
  SECTION: 'section',
  CURRENCY: 'currency',
  IS_SORTED_FIELD_VALUES: 'isSortedFieldValues',
  IS_REQUIRED: 'isRequired',
  IS_MERGE_TAG: 'isMergeTag',
  EXTERNAL_FIELD: 'externalField',
  PERMISSION: 'permission',
  LAST_FIELD_TYPE: 'last-element',
  FIELD_MODE: 'fieldMode',
  IS_REQUIRED_SHOWN: 'isRequiredShown',
};

export const FIELDS = {
  ...FIELD_VISIBILITY_FIELDS,
  ...SECTION_FIELDS,
  ...CUSTOM_FIELDS,
};

export const TEXT_FIELDS = {
  [FIELD_TYPES.TEXT]: FIELD_TYPES.TEXT,
  [FIELD_TYPES.PARAGRAPH]: FIELD_TYPES.PARAGRAPH,
  [FIELD_TYPES.LINK]: FIELD_TYPES.LINK,
  [FIELD_TYPES.DATE]: FIELD_TYPES.DATE,
  [FIELD_TYPES.TIME]: FIELD_TYPES.TIME,
};

export const SINGLE_DROPDOWN_FIELDS = {
  [FIELD_TYPES.SINGLE_DROPDOWN]: FIELD_TYPES.SINGLE_DROPDOWN,
  [FIELD_TYPES.TIMEZONE]: FIELD_TYPES.TIMEZONE,
};

export const NUMBER_FIELDS = {
  [FIELD_TYPES.CURRENCY]: FIELD_TYPES.CURRENCY,
  [FIELD_TYPES.NUMBER]: FIELD_TYPES.NUMBER,
};

export const DEFAULT_FIELDS = {
  ADDRESS: 'Address',
  EXTERNAL_ID: 'External ID',
  OWNER: 'Owner',
  TYPE: 'Type',
  ACTIVE: 'Active',
  PHONE: 'Phone',
  EMAIL: 'Email',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  POSITION: 'Position',
  REVENUE: 'Revenue',
  LAST_ACTIVITY: 'Last activity',
};

export const PERMISSION_ENUM = {
  NA: 0,
  READ_AND_WRITE: 1,
  READY_ONLY: 2,
};

export const SOURCE_ENUM = {
  UNKNOWN: 0,
  NEW_STAGEBASE_FIELD: 1,
  JIRA: 2,
  SLACK: 3,
  SALESFORCE: 4,
};

export const FIELD_TYPES_ENUM = {
  UNKNOWN: 0,
  TEXT: 1,
  PARAGRAPH: 2,
  SINGLE_DROPDOWN: 3,
  MULTI_DROPDOWN: 4,
  NUMBER: 5,
  CURRENCY: 6,
  DATE: 7,
  TIME: 8,
  DATE_TIME: 9,
  TIMEZONE: 10,
  EMAIL: 11,
  LINK: 12,
  YES_NO: 13,
  SWITCH: 14,
};

export const OBJECT_TYPE_ENUM = {
  UNKNOWN: 0,
  CUSTOMER: 1,
  CONTACTS: 2,
  PROFILE: 3,
  PROJECTS: 4,
};

export const PATCH_FIELDS = {
  NAME: 'name',
  SEQUENCE_NUMBER: 'sequenceNumber',
  SECTION_NAME: 'customFieldSectionName',
  FIELD_LABEL: 'fieldLabel',
  FIELD_TYPE_OPTIONS: 'fieldTypeOptions',
  SYNC_OPTION: 'syncOption',
  TARGET_FIELD_NAME: 'targetFieldName',
  IS_REQUIRED: 'isRequired',
  IS_MERGE_TAG: 'isMergeTag',
  IS_HIDDEN: 'isHidden',
  IS_SORTED_FIELD_VALUES: 'isSortedFieldTypeOptions',
};

export const CUSTOM_FIELDS_MODAL_VARIATIONS = {
  CUSTOMER: 'customer',
  PROFILE: 'profile',
  PROJECTS: 'projects',
  CONTACTS: 'contacts',
  INVITE_NEW: 'inviteNew',
  USERS: 'users',
};

export const CONTACTS_SECTION_DESC = {
  GENERAL: 'These fields appear in the table',
  MOREINFO: 'These fields appear when clicking More Info',
};

export const PROJECTS_SECTION_DESC = {
  GENERAL: 'These fields appear in this order.',
  MOREINFO: 'These fields appear when clicking More Info',
};

export const PARSED_FIELDS = {
  OWNER_ID: 'ownerId',
  STATUS: 'status',
  CLIENT_TYPE_ID: 'clientTypeId',
  PHONE_NUMBER: 'phoneNumber',
  PHONE: 'phone',
  LAST_ACTIVITY: 'lastActivity',
};

export const EXTERNAL_SOURCE = {
  SALESFORCE: 'Salesforce',
  HUBSPOT: 'Hubspot',
};

export const INTERNAL_SOURCE = 'NewStagebaseField';
