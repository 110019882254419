import { useReducer, useMemo, useCallback } from 'react';
import DateChangeContext from './dateChangeContext';
import DateChangeReducer from './dateChangeReducer';
import * as Api from '../../../../../api/Api';
import { addBusinessDays } from 'date-fns';
import { Touchpoint } from 'models';

const DateChangeState = (
  {
    touchpoint,
    canvasStartDate,
    canvasEndDate,
    updateTouchpoint,
    setShowEditDateModal,
    dependentData,
    children,
    canvasTargetEnd,
  } = {
    touchpoint: new Touchpoint(),
  }
) => {
  const initialState = {
    activeTouchpoint: touchpoint,
    durationAmount: touchpoint?.durationOptionAmount,
    isDurationAmountOpen: false,
    durationOption: touchpoint?.durationOption,
    isDurationOptionOpen: false,
    dependencyAdded: touchpoint?.dependencyAdded,
    dependentStep: touchpoint?.touchpointDependentId ?? touchpoint?.customerTouchpointDependentId,
    isDependentStepOpen: false,
    noDueDate: touchpoint?.noDueDate,
    dateToBeScheduled: touchpoint?.dateToBeScheduled,
    scheduledDate:
      touchpoint?.dateIsScheduled && touchpoint?.dateToBeScheduled ? touchpoint?.scheduledDate : null,
    calculatedDate: touchpoint?.endDate,
    isDateModified: false,
    isEarlierDate: false,
    canvasEndDate: canvasEndDate,
    canvasTargetEnd: canvasTargetEnd,
    isAccounts: document.location.href.includes('accounts'),
    warnings: [],
    setShowEditDateModal,
    isLoading: false,
  };

  const [state, dispatch] = useReducer(DateChangeReducer, initialState);

  const getDependentStepEndDate = useCallback(
    (dependentStep) => {
      if (!dependentStep || !dependentData?.length) return false;

      const dependentObj = dependentData
        .map(({ touchpoints }) => touchpoints)
        .flat()
        .find(({ id }) => id === dependentStep);

      return dependentObj.dateTimeCompleted || dependentObj.endDate;
    },
    [dependentData]
  );

  const memoizedDependentStepEndDate = useMemo(
    () => getDependentStepEndDate(state.dependentStep),
    [state.dependentStep, getDependentStepEndDate]
  );

  const buildDependenciesFrom = (touchpoint) => {
    // Note: This is very similar to buildDependenciesFrom in DependentModal. The difference
    // here is that this stores the touchpoint objects and not just the touchpoint IDs

    // This will recursively build the list of all dependencies downstream of touchpoint
    let chain = [touchpoint];

    // If there are 1 or more dependencies, those trails will be followed
    if (
      touchpoint?.customerTouchpointDependents !== undefined &&
      touchpoint?.customerTouchpointDependents?.length > 0
    ) {
      touchpoint?.customerTouchpointDependents.forEach(
        (dependent) => (chain = chain.concat(buildDependenciesFrom(dependent)))
      );
    }

    return chain;
  };

  const hideLoadingTimeout = () => {
    /**
     * Added timeout as calculation of dependencies seems instant
     * in local development, if it takes too long on staging or production
     * feel free to remove this.
     */
    setTimeout(() => updateLoadingStatus(false), 200);
  };

  const checkDependentStepStatus = () => {
    let touchpointsAffected = buildDependenciesFrom(state.activeTouchpoint);

    // The activeTouchpoint was added first and is technically not in the
    // affected list, but it was useful to kick off the recursion that way,
    // so it gets dropped here:
    touchpointsAffected.shift();

    updateWarningMessages([...touchpointsAffected]);
    hideLoadingTimeout();
  };

  const saveTemplateDate = async () => {
    const res = await Api.post('Touchpoint/UpdateTouchpoint', {
      ...state.activeTouchpoint,
      durationOptionAmount: state.durationAmount,
      durationOption: state.durationOption,
      touchpointDependentId: state.dependentStep,
      endDate: state.activeTouchpoint?.endDate,
      noDueDate: state.noDueDate,
      dateToBeScheduled: state.dateToBeScheduled,
      dependencyAdded: state.dependencyAdded,
    });
    // Refresh the template step with the new step data.
    updateTouchpoint(res, true);
    setShowEditDateModal(false);
  };

  const saveProjectDateInfo = async () => {
    await Api.post('Touchpoint/EditCustomerTouchpoint', {
      id: state.activeTouchpoint.id,
      title: state.activeTouchpoint.title,
      customerCanvasId: state.activeTouchpoint.customerCanvasId,
      customerJourneyId: state.activeTouchpoint.customerJourney?.id,
      status: state.activeTouchpoint.status,
      emailTrigger: state.activeTouchpoint.emailTrigger,
      emailAudience: state.activeTouchpoint.emailAudience,
      triggerEmailSubject: state.activeTouchpoint.triggerEmailSubject,
      triggerEmailBody: state.activeTouchpoint.triggerEmailBody,
      customerFacing: state.activeTouchpoint.customerFacing,
      initialEndDate: state.activeTouchpoint.initialEndDate,
      durationOptionAmount: state.noDueDate ? 1 : state.durationAmount,
      durationOption: state.noDueDate ? 'Days' : state.durationOption,
      customerTouchpointDependentId: state.noDueDate ? null : state.dependentStep,
      endDate: state.calculatedDate,
      noDueDate: state.noDueDate,
      dateToBeScheduled: state.dateToBeScheduled,
      dateIsScheduled: state.scheduledDate ? true : false,
      dependencyAdded: state.dependentStep && state.dependencyAdded ? true : false,
      dateTimeCompleted:
        state.activeTouchpoint.status === 'Completed' ? state.scheduledDate : state.dateTimeCompleted,
      scheduledDate: state.activeTouchpoint.dateToBeScheduled ? state.scheduledDate : null,
    });
    updateTouchpoint();
    setShowEditDateModal(false);
  };

  const calculateAndUpdateDate = (
    { durationAmount, durationOption, dependentStepEndDate } = {
      durationAmount: '',
      durationOption: '',
      dependentStepEndDate: '',
    }
  ) => {
    let totalDuration = durationAmount ?? state.durationAmount;
    switch (durationOption || state.durationOption) {
      case 'Weeks':
        totalDuration *= 5;
        break;
      case 'Months':
        totalDuration *= 20;
        break;
      default:
        totalDuration = durationAmount ?? state.durationAmount;
        break;
    }

    const newEndDate = addBusinessDays(
      dependentStepEndDate || memoizedDependentStepEndDate || new Date(canvasStartDate),
      totalDuration
    );
    updateIsEarlierDate(newEndDate < touchpoint?.endDate);
    updateCalculatedDate(newEndDate);
  };

  const updateDurationAmount = (value) => {
    updateLoadingStatus(true);
    calculateAndUpdateDate({ durationAmount: value });
    checkDependentStepStatus();

    dispatch({
      type: 'update_duration_amount',
      payload: value,
    });
  };

  const toggleDurationAmountModal = (value) => {
    dispatch({
      type: 'update_duration_amount_open',
      payload: value,
    });
  };

  const updateDurationOption = (value) => {
    updateLoadingStatus(true);
    calculateAndUpdateDate({ durationOption: value });
    checkDependentStepStatus();

    dispatch({
      type: 'update_duration_option',
      payload: value,
    });
  };

  const toggleDurationOptionModal = (value) => {
    dispatch({
      type: 'update_duration_option_open',
      payload: value,
    });
  };

  const updateDependencyStatus = (value) => {
    dispatch({
      type: 'update_dependency_status',
      payload: value,
    });
  };

  const updateDependentStep = (value) => {
    updateLoadingStatus(true);

    const depEndDate = getDependentStepEndDate(value);
    calculateAndUpdateDate({ dependentStepEndDate: depEndDate });
    checkDependentStepStatus();

    dispatch({
      type: 'update_dependent_step',
      payload: value,
    });
  };

  const toggleDependentStepModal = (value) => {
    dispatch({
      type: 'update_dependent_step_open',
      payload: value,
    });
  };

  const updateNoDueDate = (value) => {
    updateLoadingStatus(true);
    // Recalculate the new end date for the step anytime the duration amount, or option changes. AND a scheduled date has not been set.
    if (value) {
      updateCalculatedDate(state.activeTouchpoint?.endDate);
    } else {
      calculateAndUpdateDate();
    }

    dispatch({
      type: 'update_no_due_date',
      payload: value,
    });
    checkDependentStepStatus();
  };

  const updateIsScheduled = (value) => {
    dispatch({
      type: 'update_is_scheduled',
      payload: value,
    });
  };

  const updateScheduledDate = (value) => {
    updateLoadingStatus(true);
    // Check to see if the new date is earlier or later than the currently set date.
    if (value < new Date(state?.activeTouchpoint.displayDate)) {
      updateIsEarlierDate(true);
    } else {
      updateIsEarlierDate(false);
    }

    if (touchpoint?.isComplete) {
      checkDependentStepStatus();
    }

    hideLoadingTimeout();

    dispatch({
      type: 'update_scheduled_date',
      payload: value,
    });
  };

  const updateCalculatedDate = (value) => {
    dispatch({
      type: 'update_calculated_date',
      payload: value,
    });
  };

  const updateIsEarlierDate = (value) => {
    dispatch({
      type: 'update_is_earlier_date',
      payload: value,
    });
  };

  const updateWarningMessages = (value) => {
    dispatch({
      type: 'update_warning_messages',
      payload: value,
    });
  };

  const updateLoadingStatus = (value) => {
    dispatch({
      type: 'update_is_loading',
      payload: value,
    });
  };

  return (
    <DateChangeContext.Provider
      value={{
        activeTouchpoint: state.activeTouchpoint,
        durationAmount: state.durationAmount,
        isDurationAmountOpen: state.isDurationAmountOpen,
        durationOption: state.durationOption,
        isDurationOptionOpen: state.isDurationOptionOpen,
        dependentStep: state.dependentStep,
        isDependentStepOpen: state.isDependentStepOpen,
        dependencyAdded: state.dependencyAdded,
        noDueDate: state.noDueDate,
        dateToBeScheduled: state.dateToBeScheduled,
        scheduledDate: state.scheduledDate,
        calculatedDate: state.calculatedDate,
        isDateModified: state.isDateModified,
        canvasEndDate: state.canvasEndDate,
        canvasTargetEnd: state.canvasTargetEnd,
        isAccounts: state.isAccounts,
        warnings: state.warnings,
        isLoading: state.isLoading,
        isEarlierDate: state.isEarlierDate,
        setShowEditDateModal: state.setShowEditDateModal,
        canvasStartDate,
        saveTemplateDate,
        saveProjectDateInfo,
        updateDurationAmount,
        toggleDurationAmountModal,
        updateDurationOption,
        toggleDurationOptionModal,
        updateDependencyStatus,
        updateDependentStep,
        calculateAndUpdateDate,
        toggleDependentStepModal,
        updateNoDueDate,
        updateIsScheduled,
        updateScheduledDate,
        updateWarningMessages,
        checkDependentStepStatus,
        updateLoadingStatus,
      }}
    >
      {children}
    </DateChangeContext.Provider>
  );
};

export default DateChangeState;
