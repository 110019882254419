import styled from 'styled-components';
import LoadingSpinner from './LoadingSpinner';

const LoadingContainer = styled.div`
  color: ${({ isWhite }) => (isWhite ? 'white' : 'inherit')};
`;

export default function LoadingInline({ text, centered, white, className }) {
  return (
    <LoadingContainer
      data-testid="loading-container"
      className={
        'd-flex align-items-center py-0' +
        (centered ? ' justify-content-center' : '') +
        (white ? '' : ' text-muted') +
        (className ? ' ' + className : '')
      }
      isWhite={white}
    >
      <LoadingSpinner white={white} className="mr-2" />
      {text || 'Loading...'}
    </LoadingContainer>
  );
}
