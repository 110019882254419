import * as Api from '../../../api/Api';
import React, { useState, useEffect } from 'react';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import toast from '../../toast';
import Tags from '../../Tags';
import PlusButton from '../../PlusButton';
//import styled from 'styled-components';
import { EmptyTagsContainer } from './AddMembers/Styles';
import InfoTextBox from '../Shared/InfoTextBox';

export default function EditActiveStepTags({
  touchpointId,
  touchpointTags = [],
  onAdded,
  onRemoved,
}) {
  const [filteredTags, setFilteredTags] = useState([]);
  const [globalTags, setGlobalTags] = useState([]);
  const [addingTouchpointTags, setAddingTouchpointTags] = useState(false);
  const [focusTag, setFocusTag] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await Api.get('Touchpoint/GetTags');
      var filteredTags = response.items.length > 0 ?
        response.items.filter((item) => !touchpointTags
          || !touchpointTags.some((t) => t.tagId === item.id))
        : [];
      setGlobalTags(filteredTags);
    })();
  }, [touchpointTags]);

  useEffect(() => {
    setFilteredTags(touchpointTags.filter((x) => !x.isArchived && (x.tag == null || !x.tag.isArchived)));
  }, [touchpointTags]);

  async function createTouchpointTagFromTag(tag) {
    if (!touchpointTags.find((t) => t.tagId === tag.id)) {
      var response = await Api.get('Project/AddTag', { stepId: touchpointId, tagId: tag.id });

      if (response.status === 400) {
        toast.error('Unable to add that tag.');
      } else {
        var touchpointTag = {
          id: response.id,
          tagId: response.id,
          text: response.text,
          color: response.color,
          isArchived: false,
          tag: { isArchived: false },
        };
        onAdded(touchpointTag);
      }
      setAddingTouchpointTags(false);
      setFocusTag(null);
    }
  }

  async function deleteTouchpointTag(tag) {
    await Api.get('Project/RemoveTag', { stepId: touchpointId, tagId: tag.tagId });
    onRemoved(tag.id, touchpointId);
  }

  return (
    <>
      <div className="mb-4">
        <div className="row">
          <div className="col-12">
            <div className="d-flex">
              {filteredTags.length > 0 ? (
                <>
                  <Tags
                    tags={filteredTags}
                    large={true}
                    onDelete={deleteTouchpointTag}
                    activeGlobalTagId={focusTag?.id ?? undefined}
                  />

                  {!addingTouchpointTags && (
                    <PlusButton onClick={() => setAddingTouchpointTags(true)} icon={faPencil} />
                  )}
                </>
              ) : (
                <>
                  {!addingTouchpointTags && (
                    <PlusButton onClick={() => setAddingTouchpointTags(true)} icon={faPencil} />
                  )}

                  <div className="tag large d-flex align-items-center empty-tag ml-2">
                    <EmptyTagsContainer>
                      <span>No tags added</span>
                      <InfoTextBox notActive={false} width="30.625rem" top="1.825rem" left="-1rem">
                        Add tags like “Customer” or “CSM” to categorize steps. When adding users to the project, you can choose
                        specific tags to assign that user to those steps.
                      </InfoTextBox>
                    </EmptyTagsContainer>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-12 mt-3">
            {addingTouchpointTags && (
              <>
                <h2 className="title mb-1-5">Global Tags</h2>
                <div className="d-flex">
                  {globalTags.length ? (
                    <Tags
                      tags={globalTags}
                      large={true}
                      onClick={createTouchpointTagFromTag}
                      activeId={focusTag?.id ?? undefined}
                    />
                  ) : (
                    <div className="tag large d-flex align-items-center empty-tag mr-2">
                      No additional global tags found. Create new tags using the template editor.
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
