import InfoTextBox from 'components/Modals/Shared/InfoTextBox';
import TableHeader from 'components/TableHeader';
import { displayRole, getFormattedUsersList } from 'helpers/CommonHelper';
import { useMemo } from 'react';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import * as Api from '../../../../api/Api';
import CircleCheck from '../../../CircleCheck';
import LoadingInline from '../../../Loading/LoadingInline';
import TableRow from '../../../TableRow';
import { Avatar } from '../../Avatar';
import { EmptyTagsContainer, MembersContainer } from './Styles';
import Tags from './Tags';

export default function ModalAddMembers({
  show,
  onHide,
  customerCanvasId,
  canvasTags,
  addMembers,
  showInviteNewMember,
  members,
  scope = 'canvas',
}) {
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [saving, setSaving] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState(new Set());
  const [selectedAddToAllStepsMembers, setSelectedAddToAllStepsMembers] = useState(new Set());
  const [userTouchpointTags, setUserTouchpointTags] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!show) {
      setUserTouchpointTags([]);
      setSelectedAddToAllStepsMembers(new Set());
      setSelectedMembers(new Set());
      setSearch('');
    }
  }, [show]);

  useEffect(() => {
    async function load() {
      setLoadingUsers(true);
      const response = await Api.get('User/GetUsersCustomerCanvas', {
        customerCanvasId: customerCanvasId,
      });
      setUsers(response);
      setLoadingUsers(false);
    }
    load();
  }, [customerCanvasId]);

  // Exclude users already added.
  const availableUsers = useMemo(() => {
    let membersById =
      members?.length > 0 &&
      members.reduce((res, cur) => {
        res[cur.id] = cur;
        return res;
      }, {});
    return users.filter((u) => membersById[u.id] === undefined);
  }, [users, members]);

  const filteredUsers = useMemo(() => {
    if (!search.trim()) {
      return availableUsers;
    } else {
      return availableUsers.filter(
        (x) =>
          (x.firstName || '').toLowerCase().includes(search.toLowerCase()) ||
          (x.lastName || '').toLowerCase().includes(search.toLowerCase())
      );
    }
  }, [search, availableUsers]);

  async function handleSave() {
    if (selectedMembers.size === 0) {
      onHide();
    } else {
      setSaving(true);
      await addMembers({
        userIds: Array.from(selectedMembers),
        addToAllTouchpointsUserIds: Array.from(selectedAddToAllStepsMembers),
        addToFollowingTouchpoints: false,
        sendInvite: true,
        userTouchpointTags,
      });
    }
  }

  function handleOnChangeSearch(e) {
    setSearch(e.target.value);
  }

  function toggleMember(event, set, setfn, member) {
    event.stopPropagation();

    if (set.has(member.id)) {
      setfn((prev) => new Set([...prev].filter((x) => x !== member.id)));

      if (set === selectedMembers && selectedAddToAllStepsMembers.has(member.id)) {
        toggleMember(event, selectedAddToAllStepsMembers, setSelectedAddToAllStepsMembers, member);
      }

      const foundUser = userTouchpointTags.find(({ userId }) => userId === member.id);

      if (foundUser) {
        setUserTouchpointTags((prev) => prev.filter(({ userId }) => userId !== member.id));
      }
    } else {
      setfn((prev) => new Set(prev.add(member.id)));
      if (set === selectedAddToAllStepsMembers && !selectedMembers.has(member.id)) {
        toggleMember(event, selectedMembers, setSelectedMembers, member);
      }
    }
  }

  function membersList() {
    let emptyString;
    if (availableUsers.length === 0) {
      emptyString = 'No available members.';
    } else if (filteredUsers?.length === 0) {
      emptyString = 'No matching members.';
    }
    if (emptyString) {
      return <div className="my-4 small-info">{emptyString}</div>;
    }

    const formattedUserList = getFormattedUsersList(filteredUsers, 'modal');

    return formattedUserList.map((user) => {
      const isExternal = user.role === 'EXTERNAL';
      const selectedUser = userTouchpointTags.find(({ userId }) => userId === user?.id);

      const getPosition = () => {
        if (isExternal && user.position) {
          return `- ${user.position}`;
        }

        if (!isExternal && user.isAccountOwner) {
          return `- Account Owner`;
        }

        return '';
      };

      const handleAddTags = (selectedTagId) => {
        setUserTouchpointTags((prev) => {
          const isUserExist = prev.find(({ userId }) => userId === user?.id);

          if (!!isUserExist) {
            const formattedArray = prev.map((item) => {
              const isTagExist = item.tagIds.find((tagId) => tagId === selectedTagId);
              const filteredTags = item.tagIds.filter((tagId) => tagId !== selectedTagId);

              if (user?.id === item.userId) {
                return {
                  ...item,
                  tagIds: !!isTagExist ? filteredTags : [...item.tagIds, selectedTagId],
                };
              }

              return {
                ...item,
              };
            });

            const filteredArray = formattedArray.filter(({ tagIds }) => tagIds.length > 0);
            const foundUser = filteredArray.find(({ userId }) => user?.id === userId);

            if (!foundUser) setSelectedMembers((prev) => new Set([...prev].filter((x) => x !== user?.id)));

            return filteredArray;
          }

          const newTagList = [...prev, { userId: user?.id, tagIds: [selectedTagId] }];
          setSelectedMembers((prev) => new Set(prev.add(user?.id)));
          return newTagList;
        });
      };

      return (
        <TableRow
          key={user?.id}
          small={true}
          onClick={(e) => toggleMember(e, selectedMembers, setSelectedMembers, user)}
        >
          <Col sm={scope === 'canvas' && 4} className="d-flex align-items-center text-break mb-1 mb-sm-0">
            <Avatar
              user={user}
              className="mr-4 member-avatar"
              backgroundColor={user.role === 'EXTERNAL' ? 'var(--implement-iron)' : 'var(--action-anchor)'}
            />
            <div>
              <div className="font-weight-bold">
                {user?.firstName} {user?.lastName}
              </div>
              <div className="small-info">
                {displayRole(user?.role)} {getPosition()}
              </div>
            </div>
          </Col>

          <Col className="d-flex align-items-center justify-content-center" sm={3}>
            <CircleCheck selected={selectedMembers.has(user?.id)} large usePlusIcon />
          </Col>

          {scope === 'canvas' && (
            <>
              <Col sm={5}>
                <div>
                  {canvasTags.length > 0 ? (
                    <Tags
                      isAddToProjectChecked={selectedMembers.has(user?.id)}
                      canvasTags={canvasTags}
                      handleAddTags={handleAddTags}
                      selectedUser={selectedUser}
                    />
                  ) : (
                    <EmptyTagsContainer isAddMembers>
                      <span>No tags. Use tags for easier assignment</span>
                      <InfoTextBox notActive={false} width="30.625rem" top="1.875rem" left="-30rem">
                        Add tags to steps in your template, for example “Customer”. When selecting the tag
                        here, the user will be added to all steps with that tag.
                      </InfoTextBox>
                    </EmptyTagsContainer>
                  )}

                  <div className="d-flex align-items-center all-steps">
                    <CircleCheck
                      checkboxIcon
                      circleIcon={false}
                      selected={selectedAddToAllStepsMembers.has(user?.id)}
                      onClick={(e) =>
                        toggleMember(e, selectedAddToAllStepsMembers, setSelectedAddToAllStepsMembers, user)
                      }
                    />
                    <div className="ml-3 txt-all-steps">Add to all steps</div>
                  </div>
                </div>
              </Col>
            </>
          )}
        </TableRow>
      );
    });
  }

  return (
    <Modal show={show} onHide={onHide} scrollable size="lg">
      <Modal.Header>
        <h1>{scope === 'canvas' ? 'Add' : 'Assign'} Project Members</h1>
        <div>
          <Button disabled={saving} variant="light" onClick={showInviteNewMember} className="mr-2-5">
            Invite New Contact
          </Button>
          <Button variant="outline-light" onClick={onHide} className="mr-2-5">
            Cancel
          </Button>
          <Button disabled={saving} variant="primary" onClick={handleSave}>
            {saving ? <LoadingInline white text="Saving..." /> : 'Save'}
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <div className="d-flex flex-column flex-sm-row mb-2 mt-1">
          <Form.Control
            placeholder="Search"
            value={search}
            onChange={handleOnChangeSearch}
            className="order-sm-0"
          />
        </div>
        {loadingUsers ? (
          <LoadingInline className="py-4" />
        ) : (
          <MembersContainer hasMarginTop>
            <TableHeader>
              <Col
                sm={scope === 'canvas' && 4}
                className="d-flex align-items-center text-break mb-1 mb-sm-0"
              />
              <Col className="d-flex align-items-center justify-content-center" sm={3}>
                Add to Project
              </Col>
              {scope === 'canvas' && (
                <>
                  <Col className="d-flex align-items-center" sm={5}>
                    Assign to Steps & Tasks
                  </Col>
                </>
              )}
            </TableHeader>
            {membersList()}
          </MembersContainer>
        )}
      </Modal.Body>
    </Modal>
  );
}
