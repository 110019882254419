import styled from 'styled-components';

export const FlatPickrContainer = styled.div`
  display: flex;
  position: relative;

  & > .flatpickr-wrapper {
    width: 100%;
  }

  .svg-inline--fa {
    position: absolute;
    pointer-events: none;
    top: 0.625rem;
    right: 0.625rem;
    color: var(--schedule-sapphire);
  }

  .input {
    cursor: pointer;
  }
`;

export const DateTimeContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;

  & > div {
    width: 50%;
  }
`;
