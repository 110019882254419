import { FIELD, SECTION, FIELDS, CUSTOM_FIELD_MODES, ERROR_MESSAGES } from 'constants';

export const onFieldSubmission = (formikBag, isLabelExist, field = FIELD) => {
  const { values, setFieldError } = formikBag;

  const { DUPLICATE_FIELD, DUPLICATE_SECTION } = ERROR_MESSAGES;

  const { fieldLabel, sectionLabel, externalField, fieldMode, previousFieldLabel, previousExternalField } =
    values;
  const externalFieldLabel = externalField?.label;
  const previousExternalFieldLabel = previousExternalField?.label;

  const isEdit = fieldMode === CUSTOM_FIELD_MODES.EDIT;
  const isFieldLabelEdited = isEdit && previousFieldLabel && fieldLabel !== previousFieldLabel;
  const isExternalFieldEdited =
    isEdit && previousExternalField && externalFieldLabel !== previousExternalFieldLabel;

  const isDuplicateField =
    (isFieldLabelEdited && isLabelExist(field, fieldLabel)) || (!isEdit && isLabelExist(field, fieldLabel));

  const isDuplicateExternalField =
    (isExternalFieldEdited && isLabelExist(field, externalFieldLabel)) ||
    (!isEdit && isLabelExist(field, externalFieldLabel));

  const hasExistentLabel =
    isDuplicateField || isDuplicateExternalField || isLabelExist(SECTION, sectionLabel);

  // Validation on submission check
  if (isDuplicateField) setFieldError(FIELDS.FIELD_LABEL, DUPLICATE_FIELD);
  if (isDuplicateExternalField) setFieldError(FIELDS.EXTERNAL_FIELD, DUPLICATE_FIELD);
  if (isLabelExist(SECTION, sectionLabel)) setFieldError(FIELDS.SECTION_LABEL, DUPLICATE_SECTION);

  return { hasExistentLabel };
};
