import React from 'react';
import { FieldArray, FormikContext } from 'formik';
import { Form } from 'react-bootstrap';
import DetailField from 'components/CustomFields/Details/DetailField';
import { PROFILE_FIELDS, OBJECT_TYPE_ENUM } from 'constants';
import { useProfile } from './hooks';
import { FieldContainer } from './Styles';

const FormFields = ({ userProfile, setSaving, setHasError }) => {
  const { formikBag } = useProfile(userProfile);
  const { values } = formikBag;

  return (
    <FormikContext.Provider value={formikBag}>
      <FieldArray
        name={PROFILE_FIELDS.DATA}
        render={() => (
          <>
            {values.profileFields.map((field, index) => {
              const name = `profileFields[${index}].formikValue`;

              return (
                <FieldContainer key={field.id ?? index}>
                  <Form.Group>
                    <DetailField
                      field={field}
                      name={name}
                      variation={OBJECT_TYPE_ENUM.PROFILE}
                      setSaving={setSaving}
                      setHasError={setHasError}
                    />
                  </Form.Group>
                </FieldContainer>
              );
            })}
          </>
        )}
      />
    </FormikContext.Provider>
  );
};

export default FormFields;
