import React from 'react';
import styled from 'styled-components';
import { components } from 'react-select';
import Select from './Select';

const InnerContainer = styled.div`
  white-space: nowrap;
`;

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    const selectedOptions = props.getValue();
    values = selectedOptions.length
      ? `${selectedOptions.length > 1 ? `(${selectedOptions.length})` : ''} ${selectedOptions[0].label} ${
          selectedOptions?.[1]?.label ? `, ${selectedOptions?.[1]?.label}` : ''
        } ${selectedOptions?.[2]?.label ? `, ${selectedOptions?.[2]?.label}` : ''}`
      : '';
  }

  return (
    <components.ValueContainer {...props}>
      <InnerContainer>
        {values}
        {input}
      </InnerContainer>
    </components.ValueContainer>
  );
};

const MultiSelect = (props) => {
  return (
    <Select {...props} lala={true} isMulti hideSelectedOptions={false} components={{ ValueContainer }} />
  );
};

export default MultiSelect;
