import React from 'react';

export default function List({ children, Divider }) {
  const DividerComponent = Divider || 'hr';
  return (
    <div className="text-break">
      {React.Children.toArray(children).reduce((prev, curr) => [
        prev,
        <DividerComponent key={'divider' + prev.key} />,
        curr,
      ])}
    </div>
  );
}
