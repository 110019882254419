import * as Yup from 'yup';
import { CUSTOM_FIELD_MODES, FIELDS } from 'constants';

export const sectionSchema = Yup.object().shape({
  sectionLabel: Yup.string().required(),

  sectionMode: Yup.string(),

  editedSectionId: Yup.string().nullable().when(FIELDS.SECTION_MODE, {
    is: CUSTOM_FIELD_MODES.EDIT,
    then: Yup.string().required(),
  }),

  previousSectionLabel: Yup.string(),
});
