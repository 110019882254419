import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import ModalConfirm from 'components/Modals/Confirm/index';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Card from './Card';
import EditCard from './EditCard';
import styled from 'styled-components';
import { Fragment } from 'react';

const DroppableWrapper = styled.div`
  min-height: 1px;
`;

const CircleWrapper = styled.div`
  background: transparent;
`;

class InnerList extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.cards !== this.props.cards;
  }
  render() {
    return this.props.cards.map((item, index) => (
      <Card
        key={item.id}
        item={item}
        index={index}
        onDelete={this.props.onDelete}
        onDuplicate={this.props.onDuplicate}
        isPreviewMode={this.props.isPreviewMode}
      />
    ));
  }
}

export default function CardColumn({
  id,
  columnItem,
  cards,
  icons,
  onAddItem,
  onDeleteItem,
  onDuplicateItem,
  onEditColumn,
  isPreviewMode,
  index,
}) {
  const [addingItem, setAddingNewItem] = useState(false);
  const [itemForDelete, setItemForDelete] = useState(null);
  const [editingTitle, setEditingTitle] = useState(false);
  const [dupColumnItem, setDupColumnItem] = useState(columnItem);

  useEffect(() => {
    setDupColumnItem(columnItem);
  }, [columnItem]);

  async function onItemAdd(name, order) {
    await onAddItem(id, name, cards.length ?? 0);
    setAddingNewItem(false);
  }

  async function onItemDelete(id) {
    await onDeleteItem(id);
    setItemForDelete(null);
  }

  async function confirmItemDelete(id) {
    setItemForDelete(id);
  }

  function handleChangeColumnTitle(e) {
    setDupColumnItem({
      ...dupColumnItem,
      name: e.target.value,
    });
  }

  function updateName() {
    if (dupColumnItem.name === null || dupColumnItem.name.length === 0) {
      dupColumnItem.name = "Untitled Phase";
    }
    onEditColumn(dupColumnItem);
    setEditingTitle(false);
  }

  const columnDetails = () => (
    <div className="column">
      <div className="card-column-header">
        <div className="title">
          {editingTitle ? (
            <Form.Control
              autoFocus
              className="no-border editable-header light"
              placeholder="Enter phase name..."
              value={dupColumnItem.name}
              onChange={handleChangeColumnTitle}
              onBlur={updateName}
              onKeyPress={(event) => event.key === 'Enter' && updateName()}
            ></Form.Control>
          ) : (
            <h2 className="title cursor-pointer" onClick={() => setEditingTitle(true)}>
              {dupColumnItem.name}
            </h2>
          )}
        </div>
        {icons && !isPreviewMode && (
          <div className="icons d-flex align-items-center">
            {icons.map((item, index) => {
              return (
                <FontAwesomeIcon
                  key={index}
                  className={index === icons.length - 1 ? '' : 'mr-3'}
                  icon={item.icon}
                  onClick={() => item.onClick(id)}
                />
              );
            })}
          </div>
        )}
      </div>
      <Droppable droppableId={`journey-${id}`} direction="vertical" type="touchpoint">
        {(provided, snapshot) => (
          <DroppableWrapper {...provided.droppableProps} ref={provided.innerRef}>
            <InnerList
              cards={cards}
              onDelete={confirmItemDelete}
              onDuplicate={onDuplicateItem}
              isPreviewMode={isPreviewMode}
            />
            {provided.placeholder}
          </DroppableWrapper>
        )}
      </Droppable>
      {addingItem && <EditCard type={'step'} onItemAdd={onItemAdd} />}
      <div className="card-wrapper">
        <CircleWrapper className="circle-icon" />
        {!isPreviewMode && (
          <Button
            onClick={() => {
              setAddingNewItem(true);
            }}
            className="card-width"
            variant="light"
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        )}
      </div>
      <ModalConfirm
        title="Delete item"
        message={
          <>
            Are you sure you want to delete this item?
            <br />
            This action cannot be undone.
          </>
        }
        show={itemForDelete !== null}
        onConfirm={() => {
          onItemDelete(itemForDelete);
        }}
        onHide={() => {
          setItemForDelete(null);
        }}
      />
    </div>
  );

  if (isPreviewMode) return <Fragment key={id}>{columnDetails()}</Fragment>;

  return (
    <Draggable key={id} draggableId={`journey-${id}`} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          {columnDetails()}
        </div>
      )}
    </Draggable>
  );
}
