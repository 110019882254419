import {
  ADMIN,
  ADMIN_LABEL,
  CREATOR,
  CREATOR_LABEL,
  FRONT_LINE,
  SUPERUSER,
  SUPERUSER_LABEL,
  FRONT_LINE_LABEL,
} from 'constants';

export const roles = [
  { value: ADMIN, label: ADMIN_LABEL, response: 8 },
  { value: CREATOR, label: CREATOR_LABEL, response: 1 },
  { value: FRONT_LINE, label: FRONT_LINE_LABEL, response: 2 },
  { value: SUPERUSER, label: SUPERUSER_LABEL, response: 16 },
];
