import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { useContext, useEffect } from 'react';
import FullContext from 'stores/Full/fullContext';
import dateChangeContext from '../context/dateChangeContext';
import { DropDown, ChevronArrow, InlineText } from '../styles';
import Amount from './Amount';
import DependentModal from './DependentModal';
import Duration from './Duration';

const DueDateDropdowns = ({ dependentStepTitle, dependentData, touchpoint }) => {
  const {
    noDueDate,
    toggleDurationAmountModal,
    durationAmount,
    isDurationAmountOpen,
    toggleDurationOptionModal,
    durationOption,
    isDurationOptionOpen,
    toggleDependentStepModal,
    updateDurationAmount,
    dependentStep,
    isDependentStepOpen,
  } = useContext(dateChangeContext);
  const { isEditMode } = useContext(FullContext);

  const isSameDay = durationAmount === 0;

  const isDay = durationOption === 'Days';

  useEffect(() => {
    if (!isDay && durationAmount === 0) {
      updateDurationAmount(1);
    }
  }, [isDay, durationAmount, updateDurationAmount]);

  if ((touchpoint?.isScheduledType && !isEditMode) || touchpoint?.isComplete) return false;

  return (
    <div className="mb-3 position-relative">
      <DropDown
        notActive={noDueDate}
        width={isSameDay ? '90px' : '75px'}
        margin="0 10px 0 0"
        onClick={() => toggleDurationAmountModal(true)}
        padding="8px 16px"
      >
        {isSameDay ? 'Same' : durationAmount}
        <ChevronArrow icon={faChevronDown} size="xs" />
      </DropDown>
      {isDurationAmountOpen && <Amount />}
      <DropDown
        notActive={noDueDate}
        width="100px"
        margin="0 10px 0 0"
        onClick={() => toggleDurationOptionModal(true)}
        padding="8px 16px"
      >
        {durationAmount === 1 || isSameDay ? durationOption.slice(0, -1) : durationOption}
        <ChevronArrow icon={faChevronDown} size="xs" right="10px" />
      </DropDown>
      {isDurationOptionOpen && <Duration />}
      <InlineText notActive={noDueDate}>{isSameDay ? 'as' : 'after'}</InlineText>
      <DropDown
        notActive={noDueDate}
        onClick={() => toggleDependentStepModal(true)}
        width={isSameDay ? '336px' : '350px'}
        margin="0 0 0 10px"
        padding="8px 32px 8px 16px"
      >
        {dependentStep && dependentStepTitle}
        {!dependentStep && 'Start of the project'}
        <ChevronArrow icon={faChevronDown} size="xs" />
      </DropDown>
      {isDependentStepOpen && <DependentModal dependentData={dependentData} />}
    </div>
  );
};

export default DueDateDropdowns;
