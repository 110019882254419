import { useState, useEffect, useCallback } from 'react';
import * as JourneyApi from '../../api/journey';
import toast from '../../components/toast';

/**
 * React hook to return template touchpoints
 * @param {number} canvasId 
 */
const useTouchpoints = (canvasId) => {
  const [touchpoints, setTouchpoints] = useState([]);
  const [touchpointsOptions, setTouchpointsOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTouchpoints = useCallback(async () => {
    setLoading(true);
    try {
      const response = await JourneyApi.fetchTouchpoints(canvasId);
      setTouchpoints(response);

      const touchpointsOptions =
        response?.items?.map((res) => ({
          label: res.name,
          options: res.touchpoints.sort((a, b) => a.order - b.order).map((point) => ({ value: point.id, label: point.title })),
        })) || [];

      setTouchpointsOptions(touchpointsOptions);
    } catch (err) {
      console.log(err);
      toast.error('There was an error fetching touchpoints list');
    } finally {
      setLoading(false);
    }
  }, [canvasId]);

  useEffect(() => {
    fetchTouchpoints();
  }, [canvasId, fetchTouchpoints]);

  return {
    touchpoints,
    touchpointsOptions,
    loading,
  };
};

export default useTouchpoints;
