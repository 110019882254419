import React from 'react';
import { Pie } from '@nivo/pie';

const Donut = ({ data }) => (
  <Pie
    data={data}
    width={120}
    height={120}
    enableArcLabels={false}
    enableArcLinkLabels={false}
    margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
    innerRadius={0.2}
    padAngle={0.7}
    cornerRadius={3}
    colors={
      data.length === 2
        ? ['var(--green-bright)', 'var(--orange-bright)']
        : ['var(--green-bright)', 'var(--orange-bright)', 'var(--playbook-pewter)']
    }
    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
    radialLabelsSkipAngle={0}
    radialLabelsTextXOffset={6}
    radialLabelsTextColor="#333333"
    radialLabelsLinkOffset={0}
    radialLabelsLinkDiagonalLength={16}
    radialLabelsLinkHorizontalLength={24}
    radialLabelsLinkStrokeWidth={1}
    radialLabelsLinkColor={{ from: 'color' }}
    slicesLabelsSkipAngle={0}
    slicesLabelsTextColor="#333333"
    sliceLabel={(item) => {
      <tspan>{item.value}</tspan>;
    }}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
    isInteractive={!data[2].value}
  />
);

export default Donut;
