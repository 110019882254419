import { useContext, useEffect, useRef, useState } from 'react';
import { Button } from './Styles';
import * as Api from '../../../api/Api';
import LoadingInline from '../../Loading/LoadingInline';
import toast from '../../toast';
import FullContext from 'stores/Full/fullContext';

export default function AddAttachment({ touchpointId, customerTouchpointId, onAdded }) {
  const fileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const { isViewAsCustomer } = useContext(FullContext);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const onFileChange = async (event) => {
    setLoading(true);
    const url = customerTouchpointId
      ? `Customer/UploadAttachment?customerTouchpointId=${customerTouchpointId}`
      : `Touchpoint/UploadAttachment?touchpointId=${touchpointId}`;
    const response = await Api.postFiles(url, event.target.files, {
      rawResponse: true,
    });
    const data = await response.json();
    if (response.status === 400) {
      toast.error(data);
    } else {
      onAdded(data);
      toast.saved('File added successfully.');
    }
    setLoading(false);
  };

  function handleOnClick() {
    if (isViewAsCustomer) {
      toast.error('Please exit customer view to do this.');
      return;
    }

    fileInput.current.click();
  }

  return (
    <>
      <input id="logo-input" hidden type="file" onChange={onFileChange} ref={fileInput} multiple />
      <Button onClick={handleOnClick} disabled={loading}>
        {loading ? <LoadingInline centered black text="Uploading..." /> : 'Upload a file'}
      </Button>
    </>
  );
}
