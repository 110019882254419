export const PLACEHOLDERS = {
  DATASOURCE: 'Choose data source...',
  SECTION: 'Choose where to place this field...',
  SECTION_LABEL: 'Name the section...',
  IS_REQUIRED: 'Require field',
  IS_MERGE_TAGS: 'Include field as email merge tag',
  FIELD_LABEL: 'Name the field...',
  FIELD_TYPE: 'Choose field type...',
  CURRENCY: 'Choose currency...',
  IS_SORTED_FIELD_VALUES: 'Order alphabetically',
  EXTERNAL_FIELD: 'Choose field...',
  PERMISSION: 'Choose permissions...',
  DATE: 'Select date...',
  TIME: 'Select time...',
  DROPDOWN: 'None',
  TEXTFIELD: 'Enter value here...',
  ADDRESS: '123 Good Way, Lehi, UT 84043',
  NUMBER: '12345',
  TEXT: 'Text',
  SINGLE_DROPDOWN: 'Item 1',
  MULTI_DROPDOWN: 'Item 1, Item 2',
  TIMEZONE: 'GMT-00:00',
  BASE_DATE: '01/01/2000',
  BASE_TIME: '8:00 AM',
  LINK: 'www.stagebase.com',
  EMAIL: 'name@email.com',
  OWNER: 'Sally Smith',
  TYPE: 'Gold Tier',
  FIELD_VALUE: 'Add a value...',
  ROLE: 'Choose Role',
  FIRST_NAME: 'Jane',
  LAST_NAME: 'Rivera',
  POSITION: 'Buyer',
  REVENUE: '1,000',
  PHONE: '(801) 123-4567',
  INPUT_TEXT: 'Text...',
  INPUT_NUMBER: '12345...',
  INPUT_CURRENCY: '1,000...',
};
