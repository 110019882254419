import { faChevronDown, faChevronUp, faPlus, faClock, faCirclePause, faCheck, faAnglesRight, faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { faFileDownload, faFileUpload, faTrash, faEllipsisH, faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useRef, useState, useContext, forwardRef } from 'react';
import { Alert, Button, Col, Dropdown, Form, Row, ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { FormikContext } from 'formik';
import { Link } from 'react-router-dom';
import * as Api from 'api/Api';
import { handleError } from 'api/ServerResponseHandler';
import LoadingInline from 'components/Loading/LoadingInline';
import ModalConfirm from 'components/Modals/Confirm/index';
import ModalAddAccount from 'components/Modals/Customers/AddAccount';
import ModalLargePadding, {
  ModalLargePaddingCancelButton,
  ModalLargePaddingConfirmButton,
  ModalLargePaddingHeader,
} from 'components/Modals/LargePadding';
import PageHeader from 'components/PageHeader';
import TableRow from 'components/TableRow';
import toast from 'components/toast';
import TrashCol from 'components/TrashCol';
import * as CommonHelper from 'helpers/CommonHelper';
import { FRONT_LINE, UNNAMED_ACCOUNT } from 'constants';
import importTemplate from 'templates/Accounts_Import_Template.csv';
import FullContext from 'stores/Full/fullContext';
import { useCustomers } from './hooks';
import {
  CheckMark,
  ChevronArrow,
  DropdownMenu,
  FilterButton,
  NoAccountsMessage,
  OptionsContainer,
  Table,
  TableHeader,
} from './Styles';
import CustomFieldsContext from 'stores/CustomFields/customFieldsContext';
import ModalAUMInformation from 'components/Modals/Customers/AUMInformation';
import { BILLING_PLANS } from 'constants';

export default function Customers() {
  const [loading, setLoading] = useState(true);
  const [filterShow, setFilterShow] = useState(false);
  const [numberOfFilters, setNumberOfFilters] = useState(() => {
    const localFilterMy = localStorage.getItem('customersFilterMy');
    const localFilterOwners = localStorage.getItem('customersFilterOwners');
    const localFilterTypes = localStorage.getItem('customersFilterTypes');
    const localFilterStatuses = localStorage.getItem('customersFilterStasuses');
    const localFilterPastDue = localStorage.getItem('customersFilterPastDue');
    const localFilterOnTime = localStorage.getItem('customersFilterOnTime');
    let sumOfFilter = 0;
    sumOfFilter += localFilterMy ? (localFilterMy === 'true' ? 1 : 0) : 0;
    sumOfFilter += localFilterOwners ? localFilterOwners.split(',').length : 0;
    sumOfFilter += localFilterStatuses ? localFilterStatuses.split(',').length : 0;
    sumOfFilter += localFilterTypes ? localFilterTypes.split(',').length : 0;
    sumOfFilter += localFilterPastDue ? (localFilterPastDue === 'true' ? 1 : 0) : 0;
    sumOfFilter += localFilterOnTime ? (localFilterOnTime === 'true' ? 1 : 0) : 0;

    return sumOfFilter;
  });
  const [customers, setCustomers] = useState(null);
  const [filteredCustomers, setFilteredCustomers] = useState(null);
  const [toDelete, setToDelete] = useState(null);
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState('name');
  const [showPastDueFilter, setShowPastDueFilter] = useState(false);
  const [showOnTimeFilter, setShowOnTimeFilter] = useState(false);
  const [ascending, setAscending] = useState(false);
  const [allOwners, setAllOwners] = useState([]);
  const [allTypes, setAllTypes] = useState([]);
  const [allStatuses, setAllStatuses] = useState([]);
  const [filterHolder, setFilterHolder] = useState(() => {
    const localFilterMy = localStorage.getItem('customersFilterMy');
    const localFilterOwners = localStorage.getItem('customersFilterOwners');
    const localFilterTypes = localStorage.getItem('customersFilterTypes');
    const localFilterStatuses = localStorage.getItem('customersFilterStasuses');
    const localFilterPastDue = localStorage.getItem('customersFilterPastDue');
    const localFilterOnTime = localStorage.getItem('customersFilterOnTime');
    return {
      myHolder: localFilterMy ? localFilterMy === 'true' : false,
      ownersHolder: localFilterOwners ? localFilterOwners.split(',') : [],
      typesHolder: localFilterTypes ? localFilterTypes.split(',') : [],
      statusesHolder: localFilterStatuses ? localFilterStatuses.split(',') : [],
      onTimeHolder: localFilterOnTime ? localFilterOnTime === 'true' : false,
      pastDueHolder: localFilterPastDue ? localFilterPastDue === 'true' : false,
    };
  });
  const [filterOwners, setFilterOwners] = useState(() => {
    let cachedFilterOwners = localStorage.getItem('customersFilterOwners');
    return cachedFilterOwners ? cachedFilterOwners.split(',') : [];
  });
  const [filterTypes, setFilterTypes] = useState(() => {
    let cachedFilterTypes = localStorage.getItem('customersFilterTypes');
    return cachedFilterTypes ? cachedFilterTypes.split(',') : [];
  });
  const [filterStatuses, setFilterStatuses] = useState(() => {
    let cachedFilterStatuses = localStorage.getItem('customersFilterStasuses');
    return cachedFilterStatuses ? cachedFilterStatuses.split(',') : [];
  });
  const [filterMy, setFilterMy] = useState(() => {
    const cachedFilterMy = localStorage.getItem('customersFilterMy');
    return cachedFilterMy === 'true';
  });
  const [filterPastDue, setFilterPastDue] = useState(() => {
    const cachedFilterPastDue = localStorage.getItem('customersFilterPastDue');
    return cachedFilterPastDue === 'true';
  });
  const [filterOnTime, setFilterOnTime] = useState(() => {
    const cachedFilterOnTime = localStorage.getItem('customersFilterOnTime');
    return cachedFilterOnTime === 'true';
  });
  const [showingImportConfirmationModal, setShowingImportConfirmationModal] = useState(false);
  const [importModel, setImportModel] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const history = useNavigate();

  const fileInput = useRef(null);
  const { userData, roleAtleast, populateTotalAccounts, appSettings } = useContext(FullContext);
  const { fetchInitialData, getOwnerTypes } = useContext(CustomFieldsContext);

  useEffect(() => {
    fetchInitialData();
    getOwnerTypes();
  }, [fetchInitialData, getOwnerTypes]);

  useEffect(() => {
    if (
      customers === null ||
      (!search.trim() &&
        filterOwners.length === 0 &&
        filterTypes.length === 0 &&
        filterStatuses.length === 0 &&
        !filterPastDue &&
        !filterOnTime)
    ) {
      setFilteredCustomers(null);
    } else {
      const searchTrimmed = search.trim().toLowerCase();
      let filtered = [];
      if (searchTrimmed) {
        filtered = customers?.filter(
          (x) =>
            (x.name || '').toLowerCase().includes(searchTrimmed) ||
            (x.type || '').toLowerCase().includes(searchTrimmed) ||
            x?.owner?.firstName.toLowerCase().includes(searchTrimmed) ||
            x?.owner?.lastName.toLowerCase().includes(searchTrimmed) ||
            ('Active'.toLowerCase().includes(searchTrimmed) && x.accountStatus) ||
            ('Inactive'.toLowerCase().includes(searchTrimmed) && !x.accountStatus) ||
            ('Past Due'.toLowerCase().includes(searchTrimmed) && x.pastDue) ||
            ('Paused'.toLowerCase().includes(searchTrimmed) && x.canvasStatus === 'Paused') ||
            ('On time'.toLowerCase().includes(searchTrimmed) && !x.pastDue && x.canvasStatus !== 'Paused')
        );
      } else {
        filtered = customers;
      }
      if (filterOwners.length) {
        filtered = filtered.filter(
          (x) =>
            filterOwners.includes(`${x?.owner?.firstName} ${x?.owner?.lastName}`) ||
            (filterOwners.includes('') && !x?.owner)
        );
      }
      if (filterTypes.length) {
        filtered = filtered.filter((x) => filterTypes.includes(x.type));
      }
      if (filterStatuses.length) {
        filtered = filtered.filter((x) => {
          const status = x.accountStatus ? 'Active' : 'Inactive';
          return filterStatuses.includes(status);
        });
      }
      if (filterPastDue && filterOnTime) {
      } else if (filterPastDue) {
        filtered = filtered.filter((x) => x.pastDue);
      } else if (filterOnTime) {
        filtered = filtered.filter((x) => !x.pastDue);
      }
      setFilteredCustomers(filtered);
    }
    setNumberOfFilters(
      (filterMy ? 1 : 0) +
      filterOwners.filter((item) => item !== userData?.fullName).length +
      filterTypes.length +
      filterStatuses.length +
      (filterPastDue ? 1 : 0) +
      (filterOnTime ? 1 : 0)
    );
    setFilterHolder({
      myHolder: filterMy,
      ownersHolder: [...filterOwners],
      typesHolder: [...filterTypes],
      statusesHolder: [...filterStatuses],
      onTimeHolder: filterOnTime,
      pastDueHolder: filterPastDue,
    });
    localStorage.setItem('customersFilterMy', filterMy);
    localStorage.setItem('customersFilterOwners', filterOwners);
    localStorage.setItem('customersFilterTypes', filterTypes);
    localStorage.setItem('customersFilterStasuses', filterStatuses);
  }, [
    search,
    customers,
    filterMy,
    filterOwners,
    filterOnTime,
    filterPastDue,
    filterStatuses,
    filterTypes,
    orderBy,
    ascending,
    userData?.fullName,
  ]);

  function doSearch(q) {
    setSearch(q);
  }

  function toggleOrderBy(orderedValue) {
    if (orderBy === orderedValue) {
      setAscending((prev) => !prev);
    } else {
      setOrderBy(orderedValue);
      setAscending(false);
    }
  }

  function toggleFilterMy() {
    const filterMyOwner = filterOwners.includes(userData?.fullName);
    setFilterMy((prev) => {
      if (!filterMyOwner) {
        setFilterOwners((prev) => {
          return [...prev, userData?.fullName];
        });
      } else {
        setFilterOwners((prev) => {
          return prev.filter((item) => item !== userData?.fullName);
        });
      }
      return !filterMyOwner;
    });
  }

  const toggleFilterOwner = (event) => {
    const elValue = event.target.innerText === '(No Owner Assigned)' ? '' : event.target.innerText;
    setFilterOwners((prev) => {
      const filteredData = prev.includes(elValue)
        ? prev.filter((item) => item !== elValue)
        : [...prev, elValue];
      return filteredData;
    });
  };

  const toggleFilterType = (event) => {
    setFilterTypes((prev) => {
      const filteredData = prev.includes(event.target.innerText)
        ? prev.filter((item) => item !== event.target.innerText)
        : [...prev, event.target.innerText];
      return filteredData;
    });
  };

  const toggleFilterStatus = (event) => {
    setFilterStatuses((prev) => {
      const filteredData = prev.includes(event.target.innerText)
        ? prev.filter((item) => item !== event.target.innerText)
        : [...prev, event.target.innerText];
      return filteredData;
    });
  };

  function toggleFilterPastDue() {
    setFilterPastDue((prev) => {
      localStorage.setItem('customersFilterPastDue', !prev);
      return !prev;
    });
  }

  function toggleFilterOnTime() {
    setFilterOnTime((prev) => {
      localStorage.setItem('customersFilterOnTime', !prev);
      return !prev;
    });
  }

  const load = useCallback(async () => {
    setCustomers(null);
    setLoading(true);
    let params = {
      orderBy: orderBy,
      ascending: ascending,
    };
    let data;
    try {
      data = await Api.get('Account/GetAllAccounts', params, { history });
      console.log('Version 240429.1');
    } catch (error) {
      return handleError(error);
    } finally {
      setLoading(false);
    }
    if (data) {
      var showOnTime = false;
      var showPastDue = false;
      setCustomers(data);
      populateTotalAccounts(data.length);

      let owners = [
        ...new Set(data.map((i) => (i?.owner ? `${i?.owner?.firstName} ${i?.owner?.lastName}` : ''))),
      ].sort((owner1, owner2) => {
        if (owner2 === '') {
          if (owner1 > owner2) {
            return -1;
          }
        } else if (owner1 === '') {
          if (owner1 < owner2) {
            return 1;
          }
        }
        return owner1 > owner2 ? 1 : -1;
      });

      setAllOwners(owners.filter((item) => item !== userData?.fullName));

      let types = new Set();
      for (const item of data) {
        if (item.type?.trim()) {
          types.add(item.type);
        }
        if (item.pastDue) {
          showPastDue = true;
        } else {
          showOnTime = true;
        }
      }
      setAllTypes([...types]);
      setShowOnTimeFilter(showOnTime);
      setShowPastDueFilter(showPastDue);
      setFilterTypes((prev) => prev.filter((item) => types.has(item)));
      let statuses = new Set();
      for (const item of data) {
        const status = item.accountStatus ? 'Active' : 'Inactive';
        statuses.add(status);
      }
      setAllStatuses([...statuses]);
      setFilterStatuses((prev) => {
        return prev.filter((item) => statuses.has(item));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ascending, history, orderBy, userData?.fullName]);

  const { formikBag, handleHide, handleShow, showAddCustomerModal, handleLoadAppSettings } = useCustomers({
    fetchCustomers: load,
  });

  useEffect(() => {
    load();
  }, [load]);

  async function deleteCustomer() {
    await Api.post('Customer/DeleteCustomer', toDelete.id, { history });
    await handleLoadAppSettings();
    setToDelete(null);
    load();
    toast.saved('Account deleted successfully.');
  }

  async function onImportFileChange(event) {
    setShowingImportConfirmationModal(true);
    setImportModel(null);
    const response = await Api.postFiles('Customer/Upload', event.target.files);
    if (response.errorMessage) {
      toast.error(response.errorMessage);
    } else {
      setImportModel(response);
    }
    fileInput.current.value = null;
  }

  function toggleImportConfirmation() {
    setShowingImportConfirmationModal((prev) => !prev);
  }

  const onClearFilters = () => {
    setFilterMy(false);
    setFilterOnTime(false);
    setFilterPastDue(false);
    setFilterOwners([]);
    setFilterStatuses([]);
    setFilterTypes([]);
  };

  const onDefaultFilter = () => {
    setFilterMy(filterHolder.myHolder);
    setFilterOnTime(filterHolder.onTimeHolder);
    setFilterPastDue(filterHolder.pastDueHolder);
    setFilterOwners(filterHolder.ownersHolder);
    setFilterStatuses(filterHolder.statusesHolder);
    setFilterTypes(filterHolder.typesHolder);
  };

  const handleSubmitAUMInformation = () => {
    setShowInfoModal(false);
    handleShow();
  };

  const displayNoAccountMessage = (type = 'accounts') =>
    type === 'filter' ? (
      <NoAccountsMessage>No matching accounts</NoAccountsMessage>
    ) : (
      <NoAccountsMessage>
        No accounts yet. Select what information you'd like to track for each account{' '}
        <Link className="font-weight-bold d-inline-flex" to="/accounts/custom-fields/edit-account-fields">
          here
        </Link>
        .
      </NoAccountsMessage>
    );

  const getProjectList = useCallback((id) => {
    const account = customers?.find((account) => account.id === id);
    return account?.projects;
  }, [customers]);

  const getProjectCount = useCallback((id) => {
    const projects = getProjectList(id);
    if (projects) {
      return projects.length;
    }
    return 0;
  }, [getProjectList]);

  const getProjectListAggregation = useCallback((id) => {
    const projects = getProjectList(id);
    const displayedId = projects && projects[0] ? projects[0].id : 0;
    let aggregation = [];
    const countOnTime = projects.reduce((acc, cur) => cur.id !== displayedId && cur.pastDue !== true && cur.status === 'Active' ? ++acc : acc, 0);
    const countPastDue = projects.reduce((acc, cur) => cur.id !== displayedId && cur.pastDue === true && cur.status === 'Active' ? ++acc : acc, 0);
    const countCompleted = projects.reduce((acc, cur) => cur.id !== displayedId && cur.status === 'Complete' ? ++acc : acc, 0);
    const countPaused = projects.reduce((acc, cur) => cur.id !== displayedId && cur.status === 'Paused' ? ++acc : acc, 0);
    // This sets the order in which the indicators are displayed
    aggregation.push({ status: 'Past Due', count: countPastDue });
    aggregation.push({ status: 'On Time', count: countOnTime });
    aggregation.push({ status: 'Paused', count: countPaused });
    aggregation.push({ status: 'Complete', count: countCompleted });
    return aggregation;
  }, [getProjectList]);

  const projectStatusMore = useCallback((id) => {
    let count = getProjectCount(id);
    if (count > 1) {
      const stats = getProjectListAggregation(id);
      return (<>
        {stats.map((item, idx) => (
          item.count > 0 ? (
            <span className="mr-2 bold small-info">+{item.count}&nbsp;<FontAwesomeIcon
              icon={item.status === 'Past Due' ? faClock : item.status === 'Paused' ? faCirclePause : item.status === 'Complete' ? faCircleCheck : faAnglesRight}
              className={item.status === "Past Due" ? "past-due" : item.status === "Paused" ? "status-paused" : item.status === "Complete" ? "status-completed" : "status-on-time"} />
            </span>) : (<span></span>)
        ))}
      </>);
    }
    return;
  }, [getProjectCount, getProjectListAggregation]);

  const getProgress = useCallback((id) => {
    const projectList = getProjectList(id);
    if (projectList
      && projectList[0]) {
      return projectList[0].progress;
    }
    return 0;
  }, [getProjectList]);

  const getIsPastDue = useCallback((id) => {
    const projectList = getProjectList(id);
    if (projectList
      && projectList[0]) {
      return projectList[0].status === 'Active' && projectList[0].pastDue;
    }
    return false;
  }, [getProjectList]);

  const getTemplateName = useCallback((id) => {
    const projectList = getProjectList(id);
    if (projectList
      && projectList[0]
      && projectList[0].templateName !== ''
      && projectList[0].templateName !== null) {
      return projectList[0].templateName;
    }
    return 'Unknown';
  }, [getProjectList]);

  const projectStatusElements = useCallback((id) =>
    getProjectCount(id) > 0 ?
      (
        <><div className="mr-2 bold small-info">
          <span>{getTemplateName(id)}</span>
        </div>
          <div className="d-flex align-items-center mr-2 bold small-info">
            <span>{getProgress(id)}%&nbsp;</span>
            <ProgressBar className='flex-fill' now={getProgress(id)} />
            {getIsPastDue(id) ? <span>&nbsp;<FontAwesomeIcon icon={faClock} className='past-due' /></span> : ''}
          </div>
          {projectStatusMore(id)}
        </>
      ) : (
        <span className="mr-2 bold small-info">No Projects</span>
      ), [getIsPastDue, getProgress, getProjectCount, getTemplateName, projectStatusMore]
  );

  const customerList = useMemo(() => {
    if (!filteredCustomers && !customers) return;

    let emptyString;

    if (filteredCustomers && filteredCustomers.length === 0) {
      emptyString = displayNoAccountMessage('filter');
    } else if (customers && customers.length === 0) {
      emptyString = displayNoAccountMessage();
    }

    if (emptyString) {
      return <div className="small-info">{emptyString}</div>;
    }

    return (
      <Table filterShow={filterShow}>
        {(filteredCustomers || customers).map((item, index) => {
          const isActive = item.accountStatus;
          const customerHref = { pathname: `/accounts/${item.id}`, state: { name: item.name } };

          const rowProps =
            customerHref && isActive
              ? {
                to: customerHref,
                key: `customer-${item.id}`,
              }
              : {
                key: `customer-${item.id}`,
                to: customerHref,
                className: 'inactive-account',
              };

          return (
            <TableRow {...rowProps}>
              <Col className="bold" md={2} id={index}>
                {item.name || UNNAMED_ACCOUNT}
              </Col>
              {<Col md={2} className='mr-2 bold small-info'>
                <div><span>External: {item?.latestExternalActivity
                  ? format(new Date(item.latestExternalActivity), 'MM/dd/yy')
                  : '--'}</span></div>
                <div><span>Internal: {item?.latestInternalActivity
                  ? format(new Date(item.latestInternalActivity), 'MM/dd/yy')
                  : '--'}</span></div>
              </Col>
              /*
              <Col>{item.pastDue ? 'Past Due' : item.canvasStatus === 'Paused' ? 'Paused' : 'On time'}</Col>  */}
              <Col md={3}>
                {projectStatusElements(item.id)}
              </Col>
              <Col md={1}>{item.accountStatus ? 'Active' : 'Inactive'}</Col>
              <Col md={1}>{item.type ?? '--'}</Col>
              <Col md={2}>{item?.owner ? `${item?.owner?.firstName} ${item?.owner?.lastName}` : '--'}</Col>
              <TrashCol>
                <FontAwesomeIcon
                  icon={faTrash}
                  spin={item.deleting}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setToDelete(item);
                  }}
                />
              </TrashCol>
            </TableRow>
          );
        })}
      </Table>
    );
  }, [customers, filteredCustomers, filterShow, projectStatusElements]);

  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <div ref={ref} onClick={onClick} className="cursor-pointer">
      {children}
    </div>
  ));

  const headerOverflow = (
    <OptionsContainer>
      <Dropdown id="options">
        <Dropdown.Toggle as={CustomToggle}>
          <FontAwesomeIcon icon={faEllipsisH} className="ml-3 mr-3" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="left">
          <Dropdown.Header className="mt-2-5 filter-header dropdown-header">FIELDS</Dropdown.Header>

          <Dropdown.Item onClick={() => history('/accounts/custom-fields/edit-account-fields')}>
            <FontAwesomeIcon fixedWidth icon={faPen} />
            Edit fields
          </Dropdown.Item>

          <Dropdown.Header className="mt-2-5 filter-header dropdown-header">IMPORT</Dropdown.Header>
          <Dropdown.Item href={importTemplate} download="Account_Import_Template.csv">
            <FontAwesomeIcon fixedWidth icon={faFileDownload} />
            Download template
          </Dropdown.Item>
          <Dropdown.Item onClick={() => fileInput.current.click()}>
            <FontAwesomeIcon fixedWidth icon={faFileUpload} />
            Upload file
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <input hidden type="file" onChange={onImportFileChange} ref={fileInput} />
    </OptionsContainer>
  );

  const handleNewAccount = () => {
    const { stripeData, activeAccountCount } = appSettings;
    const { customerCapping, plan } = stripeData;

    const allowedPlans = [BILLING_PLANS.TIER_ONE, BILLING_PLANS.TIER_TWO];
    const isPlanAllowed = allowedPlans.includes(plan);

    const isLimitPlusOne = activeAccountCount === customerCapping?.end + 1;
    const isLimitPlusTwo = activeAccountCount >= customerCapping?.end + 2;
    const isFirstAccountOverLimit = isLimitPlusOne && isPlanAllowed;
    const isAccountOverLimit = isLimitPlusTwo && isPlanAllowed;
    const isFreeOrTierThree = !isPlanAllowed && activeAccountCount === customerCapping?.end;

    if (isFirstAccountOverLimit || isAccountOverLimit || isFreeOrTierThree) {
      setShowInfoModal(true);
      return;
    }

    handleShow();
  };

  const newAccountButton = (
    <Button onClick={handleNewAccount} variant="primary" className="flex-shrink-0">
      <FontAwesomeIcon icon={faPlus} className="mr-1" /> New account
    </Button>
  );

  let rowClass = filterShow ? 'table-header z-dec' : 'table-header';

  const subRow = (
    <TableHeader className={rowClass}>
      <OrderByColumn
        columnSize={2}
        name="Company"
        orderName="name"
        ascending={ascending}
        toggleOrderBy={toggleOrderBy}
        active={orderBy === 'name'}
      />
      <OrderByColumn
        columnSize={2}
        name="Recent Activity"
        orderName="lastCustomerActivity"
        ascending={ascending}
        toggleOrderBy={toggleOrderBy}
        active={orderBy === 'lastCustomerActivity'}
      />
      <OrderByColumn
        columnSize={3}
        name="Project Status"
        orderName="pastDue"
        ascending={ascending}
        toggleOrderBy={toggleOrderBy}
        active={orderBy === 'pastDue'}
      />
      <OrderByColumn
        columnSize={1}
        name="Status"
        orderName="status"
        ascending={ascending}
        toggleOrderBy={toggleOrderBy}
        active={orderBy === 'status'}
      />
      <OrderByColumn
        columnSize={1}
        name="Type"
        orderName="type"
        ascending={ascending}
        toggleOrderBy={toggleOrderBy}
        active={orderBy === 'type'}
      />
      <OrderByColumn
        columnSize={2}
        name="Owner"
        orderName="owner"
        ascending={ascending}
        toggleOrderBy={toggleOrderBy}
        active={orderBy === 'owner'}
      />
      <TrashCol />
    </TableHeader>
  );

  return (
    <>
      <PageHeader title="Accounts" loading={loading} className="with-buttons" subRow={subRow}>
        <div className="d-flex flex-column flex-md-row mt-2-5 mt-md-0">
          {roleAtleast(FRONT_LINE) ? (
            <>
              <div className="d-flex mr-md-2-5 align-items-center">
                <Search onSearch={doSearch} />
                <DropdownPersist
                  title="Dropdown"
                  showFilterHandler={() => {
                    setFilterShow((prev) => !prev);
                  }}
                  defaultFilter={onDefaultFilter}
                >
                  <Dropdown.Toggle variant="light" className="filter">
                    Filter {numberOfFilters > 0 && <>({numberOfFilters})</>}
                  </Dropdown.Toggle>
                  <DropdownMenu align="center">
                    {allStatuses.length > 0 && (
                      <Dropdown.Header className="filter-header mt-2-5">STATUS</Dropdown.Header>
                    )}
                    {allStatuses.map((item) => (
                      <Dropdown.Item
                        className="pl-48 py-2"
                        key={item}
                        eventKey={'status' + item}
                        onClick={toggleFilterStatus}
                      >
                        <div className="d-flex align-items-center">
                          {filterStatuses.includes(item) && <CheckMark icon={faCheck} />}
                          {item}
                        </div>
                      </Dropdown.Item>
                    ))}
                    {allStatuses.length > 0 && <hr className="mt-1 mb-2-5 mx-3-5" />}
                    {allTypes.length > 0 && <Dropdown.Header className="filter-header">TYPE</Dropdown.Header>}
                    {allTypes.map((item) => (
                      <Dropdown.Item
                        className="pl-48 py-2"
                        key={item}
                        eventKey={'type' + item}
                        onClick={toggleFilterType}
                      >
                        <div className="d-flex align-items-center">
                          {filterTypes.includes(item) && <CheckMark icon={faCheck} />}
                          {item}
                        </div>
                      </Dropdown.Item>
                    ))}
                    {allTypes.length > 0 && <hr className="mt-1 mb-2-5 mx-3-5" />}
                    {(showOnTimeFilter || showPastDueFilter) && (
                      <Dropdown.Header className="filter-header">PROJECT STATUS</Dropdown.Header>
                    )}
                    {showOnTimeFilter && (
                      <Dropdown.Item
                        className="pl-48 py-2"
                        eventKey="filterOnTime"
                        onClick={toggleFilterOnTime}
                      >
                        <div className="d-flex align-items-center">
                          {filterOnTime && <CheckMark icon={faCheck} />}
                          On Time
                        </div>
                      </Dropdown.Item>
                    )}
                    {showPastDueFilter && (
                      <Dropdown.Item
                        className="pl-48 py-2"
                        eventKey="filterPastDue"
                        onClick={toggleFilterPastDue}
                      >
                        <div className="d-flex align-items-center">
                          {filterPastDue && <CheckMark icon={faCheck} />}
                          Past Due
                        </div>
                      </Dropdown.Item>
                    )}
                    {(showOnTimeFilter || showPastDueFilter) && <hr className="mt-1 mb-1 mx-3-5" />}
                    <Dropdown.Header className="mt-2-5 filter-header">OWNER</Dropdown.Header>
                    <Dropdown.Item className="pl-48 py-2" eventKey="filterMy" onClick={toggleFilterMy}>
                      <div className="d-flex align-items-center">
                        {filterMy && <CheckMark icon={faCheck} />}
                        Me
                      </div>
                    </Dropdown.Item>
                    {allOwners.map((item) => (
                      <Dropdown.Item
                        className="pl-48 py-2"
                        key={item}
                        eventKey={'owner' + item}
                        onClick={toggleFilterOwner}
                      >
                        <div className="d-flex align-items-center">
                          {filterOwners.includes(item) && <CheckMark icon={faCheck} />}
                          {item ? item : '(No Owner Assigned)'}
                        </div>
                      </Dropdown.Item>
                    ))}
                    {allOwners.length > 0 && <hr className="mt-1 mb-1 mx-3-5" />}
                    <FilterButton filterHolder={filterHolder} onClick={() => onClearFilters()}>
                      Clear All
                    </FilterButton>
                  </DropdownMenu>
                </DropdownPersist>
              </div>
              <div className="d-flex flex-shrink-0 mt-2-5 mt-md-0 align-items-center">
                {newAccountButton}
                {headerOverflow}
              </div>
            </>
          ) : (
            newAccountButton
          )}
        </div>
      </PageHeader>
      {customers && customerList}
      <ModalConfirm
        title="Delete account"
        message={
          <>
            Are you sure you want to delete {toDelete && toDelete.name}?
            <br />
            This action cannot be undone.
          </>
        }
        show={toDelete !== null}
        onConfirm={deleteCustomer}
        onHide={() => {
          setToDelete(null);
        }}
      />
      {showingImportConfirmationModal && (
        <ModalImportConfirm
          show={showingImportConfirmationModal}
          onHide={toggleImportConfirmation}
          model={importModel}
          onSuccess={load}
        />
      )}

      <FormikContext.Provider value={formikBag}>
        <ModalAddAccount show={showAddCustomerModal} onHide={handleHide} />
      </FormikContext.Provider>

      <ModalAUMInformation
        show={showInfoModal}
        onSubmit={handleSubmitAUMInformation}
        onHide={() => setShowInfoModal(false)}
      />
    </>
  );
}

function OrderByColumn({ name, orderName, columnSize = 0, ascending, toggleOrderBy, active }) {
  return (
    <Col className="cursor-pointer d-flex" onClick={() => toggleOrderBy(orderName)} md={columnSize}>
      <div className="mr-1">{name}</div>
      {active &&
        (ascending ? (
          <ChevronArrow icon={faChevronUp} className="light mt-1" />
        ) : (
          <ChevronArrow icon={faChevronDown} className="light mt-1" />
        ))}
    </Col>
  );
}

function Search({ onSearch }) {
  const [search, setSearch] = useState('');
  const debouncedSearch = useRef(CommonHelper.debounce(onSearch, 300), []);

  function handleOnChangeSearch(e) {
    setSearch(e.target.value);
    debouncedSearch.current(e.target.value);
  }

  return (
    <Form.Control placeholder="Search" value={search} onChange={handleOnChangeSearch} className="mr-2-5" />
  );
}

function ModalImportConfirm({ show, onHide, model, onSuccess }) {
  const [saving, setSaving] = useState(false);

  async function continueImport() {
    setSaving(true);
    try {
      await Api.post('Customer/CreateImportCustomers', model);
    } catch (error) {
      return handleError(error);
    }
    onSuccess();
    onHide();
    toast.saved('Import complete.');
  }

  return (
    <ModalLargePadding
      show={show}
      onHide={onHide}
      backdrop="static"
      content={
        <>
          <ModalLargePaddingHeader icon={faFileUpload} text="Import accounts" />
          {model === null ? (
            <>
              <LoadingInline />
              <Button variant="outline-light" onClick={onHide} block className="mt-4-5">
                Cancel
              </Button>
            </>
          ) : (
            <>
              {model.successCount === 0 && (
                <Alert variant="danger">No valid accounts. Please check your file and try again.</Alert>
              )}
              <Row>
                <Col>Valid accounts</Col>
                <Col className="font-weight-bold">{model.successCount}</Col>
              </Row>
              <Row>
                <Col>Errors</Col>
                <Col className="font-weight-bold">{model.errorCount}</Col>
              </Row>
              {model.successCount === 0 ? (
                <Button variant="outline-light" onClick={onHide} block className="mt-4-5">
                  OK
                </Button>
              ) : (
                <div className="buttons">
                  <ModalLargePaddingCancelButton onClick={onHide} />
                  <ModalLargePaddingConfirmButton
                    onClick={continueImport}
                    text={'Continue'}
                    loading={saving}
                  />
                </div>
              )}
            </>
          )}
        </>
      }
    />
  );
}

const DropdownPersist = (props) => {
  const { defaultFilter, showFilterHandler, ...rest } = props;

  const [open, setOpen] = useState(false);
  const onToggle = (isOpen, ev, metadata) => {
    if (metadata.source === 'click' || metadata.source === 'rootClose') {
      defaultFilter();
      showFilterHandler();
    }

    if (metadata.source === 'select') {
      setOpen(true);
      return;
    }
    setOpen(isOpen);
  };

  return <Dropdown show={open} onToggle={onToggle} alignRight {...rest}></Dropdown>;
};
